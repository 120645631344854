import { useEffect, useState } from 'react'
import { Toggle } from 'src/components/primitives/toggle'
import type { ToggleStyleProps } from 'src/components/primitives/toggle'
import * as S from './standalone-switch.styled'

export interface StandaloneSwitchStyleProps extends ToggleStyleProps {
  $direction?: 'row' | 'row-reverse'
  $variant?: 'flat' | 'ghost'
}

interface StandaloneSwitchProps extends StandaloneSwitchStyleProps {
  name: string
  label: string
  defaultChecked?: boolean
  onChange?: (value: boolean) => void
}

export const StandaloneSwitch = ({
  name,
  label,
  defaultChecked = false,
  onChange,
  $variant = 'flat',
  $direction = 'row',
  $toggleSize = 16
}: StandaloneSwitchProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState<boolean>(defaultChecked)

  useEffect(() => {
    setIsChecked(defaultChecked)
  }, [defaultChecked])

  return (
    <S.StandaloneSwitch $variant={$variant} $direction={$direction}>
      <label htmlFor={name}>{label}</label>
      <Toggle
        name={name}
        checked={isChecked}
        onChange={() => {
          const newState = !isChecked
          setIsChecked(newState)
          onChange && onChange(newState)
        }}
        $toggleSize={$toggleSize}
      />
    </S.StandaloneSwitch>
  )
}
