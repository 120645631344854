export const mergeRefs = <T>(...refs: Array<React.Ref<T> | undefined>): React.RefCallback<T> => {
  return (refInstance: T | null) => {
    for (const ref of refs) {
      if (typeof ref === 'function') {
        ref(refInstance)
      } else if (ref) {
        const mutableRef = ref as React.MutableRefObject<T | null>
        mutableRef.current = refInstance
      }
    }
  }
}
