import { useState } from 'react'
import { EmailDisconnection } from 'src/components/blocks/global-errors/email-disconnection'
import { Impersonating } from 'src/components/blocks/global-errors/impersonating'
import { When } from 'src/components/blocks/when'
import type { GlobalError } from 'src/contexts/global-error'
import { GlobalErrorContext, GlobalErrorId } from 'src/contexts/global-error'
import { useSession } from 'src/hooks/use-session'

interface GlobalErrorProviderProps {
  children: React.ReactNode
}

export const GlobalErrorProvider = ({ children }: GlobalErrorProviderProps): JSX.Element => {
  const session = useSession()

  const [globalError, setGlobalError] = useState<GlobalError | null>(null)

  const openGlobalError = <T,>(id: GlobalErrorId, payload?: T): void => {
    setGlobalError({ id, payload })
  }

  // Assuming there's can only be 1 global error at a time
  const closeGlobalError = (): void => {
    setGlobalError(null)
  }

  const isGlobalErrorOpen = globalError !== null

  return (
    <GlobalErrorContext.Provider
      value={{ globalError, openGlobalError, closeGlobalError, isGlobalErrorOpen }}
    >
      <When condition={session.impersonating === true}>
        <Impersonating />
      </When>
      <When condition={globalError?.id === GlobalErrorId.MISSING_ACCESS_TOKEN}>
        <EmailDisconnection payload={globalError?.payload} />
      </When>
      {children}
    </GlobalErrorContext.Provider>
  )
}
