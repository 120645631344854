import styled, { keyframes } from 'styled-components'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

export const MultiSelect = styled.div`
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: 0.625rem;
  font-size: ${({ theme }) => theme.fontSizes[14]};
  color: ${({ theme }) => theme.colors.fgPrimary};
`

export const AddItem = styled.button`
  height: 1.5rem;
  border-radius: 4px;
  border: none;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.25rem;
  color: ${({ theme }) => theme.colors.tintBg};
  cursor: pointer;
`

export const SelectedItems = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  margin-right: 0.25rem;
`

export const SelectedItem = styled.button`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  height: 1.5rem;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 4px;
  padding: 0.125rem 0.25rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  font-size: ${({ theme }) => theme.fontSizes[14]};
`

const slideDownAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const MenuContent = styled(DropdownMenu.Content)`
  min-width: 160px;
  background-color: white;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 4px;
  padding: 0.25rem;
  box-shadow: 0px 1px 0px rgba(17, 17, 26, 0.05), 0px 0px 8px rgba(17, 17, 26, 0.1);
  animation-duration: 100ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  /* TODO: Add zindeces to theme */
  z-index: 99;

  &[data-state='open'] {
    animation-name: ${slideDownAndFade};
  }
`

export const MenuItem = styled(DropdownMenu.DropdownMenuItem)`
  height: 1.5rem;
  border: none;
  padding: 1rem 0.625rem;
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSizes[14]};
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.tintBg};
      background-color: ${({ theme }) => theme.colors.tintFaded};
    }
  }
`
