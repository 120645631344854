import styled from 'styled-components'

export const UploadHandle = styled.label`
  width: ${({ theme }) => theme.spacing[24]};
  height: ${({ theme }) => theme.spacing[24]};
  font-size: ${({ theme }) => theme.fontSizes[12]};
  color: ${({ theme }) => theme.colors.fgPrimary};
  border-radius: 4px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.fgTranslucent10};
    }
  }
`
