import { useEffect, useState, useRef } from 'react'
import { closeNotificationAtom, CUSTOM_NOTIFICATION_MESSAGE, CUSTOM_NOTIFICATIONS } from 'src/stores/notifications'
import type { Notification as NotificationBaseProps } from 'src/stores/notifications'
import { Icon } from '../icon'
import { Button } from '../button'
import type { Color } from 'src/styles/theme/types'
import { CandidateStep, CandidateStepMultiple } from './candidate-step'
import type { ActiveCandidateSequence, CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import * as S from './notification.styled'
import { CandidateStatusSummary } from './candidate-status-summary'
import { When } from 'src/components/blocks/when'
import { FailedAddingCandidatesSummary } from './failed-adding-candidates-summary'
import { isNil } from 'lodash'
import { useSetAtom } from 'jotai'
import { Spinner } from '../spinner'

interface NotificationProps extends NotificationBaseProps {
  count: number
  stackPosition: number
}

export const NotificationsContainer = ({
  children
}: {
  children: React.ReactNode
}): JSX.Element => {
  return <S.NotificationsContainer>{children}</S.NotificationsContainer>
}

export const Notification = ({
  id,
  type = 'toast',
  position = 'bottom-right',
  variant,
  icon,
  message,
  autoClose = true,
  stackPosition,
  count,
  payload,
  action,
  iconNode,
  closeDelay,
  isLoading
}: NotificationProps): JSX.Element | null => {
  const [elHeight, setElHeight] = useState(0)
  const [disappearing, setDisappearing] = useState(false)

  const toastEl = useRef<HTMLDivElement | null>(null)
  const customBannerEl = useRef<HTMLDivElement | null>(null)

  const close = useSetAtom(closeNotificationAtom)

  const getIconColor = ($variant: NotificationProps['variant']): Color => {
    switch ($variant) {
      case 'neutral': {
        return 'fgPrimary'
      }
      case 'positive': {
        return 'positiveBg'
      }
      case 'negative': {
        return 'negativeBg'
      }
      case 'warning': {
        return 'warningBg'
      }
      case 'tint': {
        return 'tintBg'
      }
      default: {
        return 'fgPrimary'
      }
    }
  }

  useEffect(() => {
    if (autoClose) {
      let notificationCloseDelay = closeDelay
      if (isNil(notificationCloseDelay)) {
        if (CUSTOM_NOTIFICATIONS.includes(type) || CUSTOM_NOTIFICATION_MESSAGE.includes(type)) {
          notificationCloseDelay = 8000
        } else {
          notificationCloseDelay = 4000
        }
      }
      const timer = setTimeout(() => {
        setDisappearing(true)
        const closeTimer = setTimeout(() => {
          close(id)
        }, 500)
        return () => {
          clearTimeout(closeTimer)
        }
      }, notificationCloseDelay)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [autoClose, close, closeDelay, id, type])

  // Calculate the height of each toast
  // to correctly offset them once
  // there are multiple toasts
  useEffect(() => {
    if (toastEl.current) {
      const height = toastEl.current.offsetHeight
      setElHeight(height)
    }
    if (customBannerEl.current) {
      const height = customBannerEl.current.offsetHeight
      setElHeight(height)
    }
  }, [id])

  const adjustedStackPosition = count - 1 - stackPosition
  const relativeStackPosition = count - stackPosition - 1
  const translateXValue = position === 'bottom-center' ? '50%' : '0'
  const scale = 1 - (relativeStackPosition / (count - 1)) * 0.1
  const customBannerOffset = elHeight * (stackPosition * 0.2)

  if (CUSTOM_NOTIFICATIONS.includes(type) && !isNil(payload)) {
    const candidateJobs = payload as CandidateJobExpanded[]

    return (
      <S.CustomBanners>
        <When condition={type === 'candidate-added'}>
          <S.CustomBanner
            ref={customBannerEl}
            $offset={customBannerOffset}
            $width='325px'
            style={{
              zIndex: -1 * (adjustedStackPosition + 1)
            }}
          >
             <S.CustomBannerInner $scale={scale}>
              {candidateJobs.length >= 2
                ? (
                    <CandidateStepMultiple
                      type={type}
                      candidateJobs={candidateJobs}
                      closeSelf={() => {
                        close(id)
                      }}
                    />
                  )
                : candidateJobs.length === 1 && (
                    <CandidateStep
                      key={candidateJobs[0].id}
                      type={type}
                      candidateJob={candidateJobs[0]}
                      closeSelf={() => {
                        close(id)
                      }}
                    />
                )
              }
            </S.CustomBannerInner>
          </S.CustomBanner>
        </When>
        <When condition={type === 'candidate-restored'}>
          <S.CustomBanner
            ref={customBannerEl}
            $offset={customBannerOffset}
            $width='auto'
            style={{
              zIndex: -1 * (adjustedStackPosition + 1)
            }}
          >
            <S.CustomBannerInner $scale={scale}>
              <CandidateStatusSummary candidateJobs={candidateJobs} type={type} />
            </S.CustomBannerInner>
          </S.CustomBanner>
        </When>
        <When condition={type === 'candidate-rejected'}>
          <S.CustomBanner
            ref={customBannerEl}
            $offset={customBannerOffset}
            $width='254px'
            style={{
              zIndex: -1 * (adjustedStackPosition + 1)
            }}
          >
            <S.CustomBannerInner $scale={scale}>
              {candidateJobs.length >= 2
                ? (
                    <CandidateStepMultiple
                      type={type}
                      candidateJobs={candidateJobs}
                      closeSelf={() => {
                        close(id)
                      }}
                    />
                  )
                : candidateJobs.length === 1 && (
                    <CandidateStep
                      key={candidateJobs[0].id}
                      type={type}
                      candidateJob={candidateJobs[0]}
                      closeSelf={() => {
                        close(id)
                      }}
                    />
                )
              }
            </S.CustomBannerInner>
          </S.CustomBanner>
        </When>
        <When condition={type === 'candidate-moved'}>
          <S.CustomBanner
            ref={customBannerEl}
            $offset={customBannerOffset}
            $width='auto'
            style={{
              zIndex: -1 * (adjustedStackPosition + 1)
            }}
          >
            <S.CustomBannerInner $scale={scale}>
              <CandidateStatusSummary candidateJobs={candidateJobs} type={type} />
            </S.CustomBannerInner>
          </S.CustomBanner>
        </When>
      </S.CustomBanners>
    )
  }

  let messageContent: React.ReactNode = typeof message === 'string' ? <p>{message}</p> : message
  if (CUSTOM_NOTIFICATION_MESSAGE.includes(type)) {
    switch (type) {
      case 'failed-adding-candidates': {
        const activeCandidateSequences = payload as ActiveCandidateSequence[]
        messageContent = <FailedAddingCandidatesSummary activeCandidateSequences={activeCandidateSequences} />
        break
      }
      default:
        break
    }
  }

  return (
    <S.Toast
      ref={toastEl}
      style={{
        zIndex: adjustedStackPosition + 1,
        transform: `translate(${translateXValue}, -${(elHeight + 10) * adjustedStackPosition}px)`
      }}
      $variant={variant}
      $position={position}
    >
      <S.ToastContentContainer $variant={variant} $disappearing={disappearing}>
        <S.ToastInner $variant={variant} $disappearing={disappearing} $hasAction={!!action}>
          {!isLoading && icon && <Icon name={icon} color={getIconColor(variant)} />}
          {isLoading && <Spinner />}
          {iconNode}
          <S.Content $variant={variant}>
            {messageContent}
          </S.Content>
          {action
            ? <>{action}</>
            : <S.Close>
                <Button
                  ariaLabel="Close"
                  leadingIcon="x"
                  $variant="ghost"
                  $colorTheme="normal"
                  $width={24}
                  $height={24}
                  onClick={() => {
                    close(id)
                  }}
                />
              </S.Close>
          }
        </S.ToastInner>
      </S.ToastContentContainer>
    </S.Toast>
  )
}
