import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 1.5rem;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`
