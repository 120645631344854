import {
  XAxis,
  YAxis,
  CartesianGrid,
  AreaChart as RechartAreaChart,
  Area,
  ResponsiveContainer,
  Tooltip
} from 'recharts'
import { useTheme } from 'styled-components'
import { ChartHeader } from '../chart-header'
import { adjustChartDataWithMinimumPoints } from 'src/utils/adjust-chart-data'
import { getChartDataMaxValue } from 'src/utils/get-chart-data-max-value'
import { When } from 'src/components/blocks/when'
import { EmptyChart } from '../empty-chart'
import { ChartTooltip } from '../chart-tooltip'

interface DataPoint {
  label: string | number
  [key: string]: string | number
}

interface AreaChartProps {
  chartTitle: string
  data: DataPoint[]
}
export const AreaChart = ({ chartTitle, data }: AreaChartProps): JSX.Element => {
  const { colors } = useTheme()

  // const data = [
  //   // {
  //   //   label: 'Feb 29',
  //   //   Sent: 4,
  //   //   Received: 0
  //   // },
  //   // {
  //   //   label: 'Mar 1',
  //   //   Sent: 5,
  //   //   Received: 7
  //   // },
  //   // {
  //   //   label: 'Mar 2',
  //   //   Sent: 4,
  //   //   Received: 2
  //   // },
  //   // {
  //   //   label: 'Mar 3',
  //   //   Sent: 7,
  //   //   Received: 12
  //   // },
  //   // {
  //   //   label: 'Mar 4',
  //   //   Sent: 9,
  //   //   Received: 2
  //   // },
  //   // {
  //   //   label: 'Mar 5',
  //   //   Sent: 0,
  //   //   Received: 3
  //   // },
  //   // {
  //   //   label: 'Mar 6',
  //   //   Sent: 12,
  //   //   Received: 12
  //   // },
  //   // {
  //   //   label: 'Mar 7',
  //   //   Sent: 0,
  //   //   Received: 14
  //   // },
  //   // {
  //   //   label: 'Mar 8',
  //   //   Sent: 12,
  //   //   Received: 1
  //   // },
  //   // {
  //   //   label: 'Mar 9',
  //   //   Sent: 11,
  //   //   Received: 18
  //   // },
  //   // {
  //   //   label: 'Mar 10',
  //   //   Sent: 4,
  //   //   Received: 2
  //   // },
  //   {
  //     label: 'Mar 11',
  //     Sent: 0,
  //     Received: 0
  //   },
  //   {
  //     label: 'Mar 12',
  //     Sent: 2,
  //     Received: 0
  //   }
  // ]

  const preparedData = adjustChartDataWithMinimumPoints(data)

  const areaKeys = Object.keys(preparedData[0] || {}).filter((key) => key !== 'label')

  const areaColors = [
    {
      // green
      // stroke: '#52B3D0',
      stroke: colors.chart2,
      fill: '#D5EDF4'
    },
    {
      // blue
      // stroke: '#4D81EE',
      stroke: colors.chart1,
      fill: '#CCD9F5'
    }
  ]

  const legendData = areaKeys.map((key, index) => ({
    name: key,
    color: areaColors[index % areaColors.length].stroke
  }))

  return (
    <>
      <ChartHeader title={chartTitle} legend={data?.length ? legendData : undefined} />
      <div style={{ width: '100%' }}>
        <When condition={!data.length}>
          <EmptyChart $minHeight="240px" />
        </When>
        <When condition={data.length >= 1}>
          <ResponsiveContainer width="100%" height={240}>
            <RechartAreaChart
              height={240}
              data={preparedData}
              margin={{
                top: 10,
                right: 0,
                left: -28,
                bottom: 20
              }}
            >
              <defs>
                {areaColors.map((color, index) => (
                  <linearGradient key={index} id={`gradient-${index}`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={color.fill} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={color.fill} stopOpacity={0} />
                  </linearGradient>
                ))}
              </defs>
              <CartesianGrid vertical={false} stroke={colors.borderTranslucent} />
              <Tooltip content={<ChartTooltip />} />
              <XAxis
                axisLine={false}
                tickLine={false}
                tick={{ fill: colors.fgSecondary, fontSize: 12 }}
                tickMargin={20}
                dataKey="label"
                interval={'equidistantPreserveStart'}
                padding={{ left: 24, right: 24 }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fill: colors.fgSecondary, fontSize: 12 }}
                tickMargin={10}
                ticks={getChartDataMaxValue(data) <= 2 ? [0, 1, 2, 3, 4] : undefined}
                // domain={[0, (dataMax: number) => (dataMax * 1)]}
                // domain={[0, calculateTopDomain(data)]}
              />
              {areaKeys.map((key, index) => (
                <Area
                  key={key}
                  type="linear"
                  dataKey={key}
                  stroke={areaColors[index % areaColors.length].stroke}
                  strokeWidth={2}
                  fillOpacity={1}
                  fill={`url(#gradient-${index % areaColors.length})`}
                />
              ))}
            </RechartAreaChart>
          </ResponsiveContainer>
        </When>
      </div>
    </>
  )
}
