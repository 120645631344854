import { Button, Flex } from 'src/components/primitives'
import * as S from './searchbox.styled'
import { Icon } from 'src/components/primitives/icon'
import { useState } from 'react'
import type { Spacing } from 'src/styles/theme/types'
import { When } from '../when'

interface SearchBoxProps {
  onClose: () => void
  onValueChange: (value: string) => void
  searchStyles?: S.StylesProps
  $gap?: Spacing
  placeholder?: string
}

export const SearchBox = ({
  onClose,
  onValueChange,
  searchStyles,
  $gap,
  placeholder = 'Search...'
}: SearchBoxProps): JSX.Element => {
  const [value, setValue] = useState('')
  return (
    <S.SearchInputWrapper {...searchStyles}>
      <Flex $gap={$gap ?? 10} $align="center">
        <Icon name="search" />
        <input
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            const newValue = e.target.value
            setValue(newValue)
            onValueChange(newValue)
          }}
        />
      </Flex>
      <When condition={!!value}>
        <Button
          ariaLabel="Close"
          leadingIcon="x"
          $variant="ghost"
          $colorTheme="muted"
          $width={24}
          $height={24}
          onClick={() => {
            setValue('')
            onClose()
          }}
        />
      </When>
    </S.SearchInputWrapper>
  )
}
