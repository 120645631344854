import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { SessionNotLoggedInError, fetchSession } from 'src/libs/api/backend/session'
import type { Session } from 'src/libs/api/backend/session'
import { queryKeys } from 'src/libs/query-keys'

interface SessionHookProps {
  enabled?: boolean
}

export const sessionQuery = (opts?: SessionHookProps) => ({
  queryKey: [queryKeys.session],
  queryFn: async () => await fetchSession(),
  staleTime: 60 * 1000,
  retry: false,
  throwOnError: (err: any): boolean => { // eslint-disable-line @typescript-eslint/no-explicit-any
    if (err instanceof SessionNotLoggedInError) {
      return false
    }

    return true
  },
  ...opts
})

export const useSession = (opts?: SessionHookProps): UseQueryResult<Session> => {
  return useQuery(sessionQuery(opts))
}
