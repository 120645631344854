import styled from 'styled-components'
import type { TextStyleProps } from './text'

export const Text = styled.p<TextStyleProps>`
  font-size: ${({ theme, $size }) => theme.fontSizes[$size ?? 16]};
  font-weight: ${({ theme, $weight }) => theme.fontWeights[$weight ?? 400]};
  line-height: ${({ $lineHeight, theme }) => theme.lineHeights[$lineHeight ?? 1.2]};
  text-transform: ${({ $transform }) => $transform};
  color: ${({ $color, theme }) => theme.colors[$color ?? 'fgPrimary']};
  letter-spacing: ${({ $letterSpacing, theme }) => theme.letterSpacings[$letterSpacing ?? 0]};
  white-space: ${({ $whiteSpace }) => $whiteSpace};

  a {
    font-size: inherit;
    color: ${({ theme }) => theme.colors.tintFg};

    &:hover {
      text-decoration: underline;
    }
  }
`
