import { useMemo } from 'react'
import { Flex } from 'src/components/primitives'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Caption } from 'src/components/primitives/typography'
import * as S from './index.styled'
import { Tooltip } from 'src/components/primitives/tooltip'
import { Avatar } from 'src/components/primitives/avatar'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { isNil } from 'lodash'
import type { CriteriaType } from '../constants'

const getNegativeLabel = (type: CriteriaType): { negativeLabel: string, positiveLabel: string } | null => {
  if (type === 'company') {
    return {
      negativeLabel: 'Have Not Worked At',
      positiveLabel: 'Have worked at'
    }
  }
  if (type === 'school') {
    return {
      negativeLabel: 'Have Not Attended',
      positiveLabel: 'Have Attended'
    }
  }
  if (type === 'location') {
    return {
      negativeLabel: 'Not Located In',
      positiveLabel: 'Located In'
    }
  }
  return null
}

interface CriteriaPillProps {
  value: {
    label?: string
    subtitle?: string
    icon?: string
    optional?: boolean
    negative?: boolean
    required?: boolean
    radiusInMiles?: number | null
  }
  type?: CriteriaType
  onRemove: () => void
  onUpdate?: (key: string, value: boolean | number | null) => void
  useOptional?: boolean
  useNegative?: boolean
  useRange?: boolean
  tooltip?: string
}

export const CriteriaPill = ({
  value,
  onUpdate,
  onRemove,
  type,
  useOptional = true,
  useNegative = true,
  useRange = false,
  tooltip
}: CriteriaPillProps): JSX.Element => {
  const actionItems = useMemo((): MenuItemProps[] => {
    const items: MenuItemProps[] = []
    if (useOptional && onUpdate) {
      items.push({
        id: 'preferred',
        title: 'Preferred',
        onSelect: () => {
          onUpdate('optional', true)
        },
        isSelected: value.optional
      }, {
        id: 'required',
        title: 'Required',
        onSelect: () => {
          onUpdate('optional', false)
        },
        isSelected: !value.optional
      })
    }
    if (useNegative && type && onUpdate) {
      const labels = getNegativeLabel(type)
      if (labels) {
        const { negativeLabel, positiveLabel } = labels
        if (items.length > 0) {
          items.push({
            title: 'negative-separator',
            type: 'separator'
          })
        }

        items.push({
          id: 'positive-option',
          title: positiveLabel,
          onSelect: () => {
            onUpdate('negative', false)
          },
          isSelected: !value.negative
        }, {
          id: 'negative-option',
          title: negativeLabel,
          onSelect: () => {
            onUpdate('negative', true)
          },
          isSelected: value.negative
        })
      }
    }
    if (useRange && onUpdate) {
      items.push({
        id: 'range-separator',
        title: 'Range Separator',
        type: 'separator'
      }, {
        id: 'range-label',
        title: 'Range',
        type: 'label'
      }, {
        id: 'exact-location',
        title: 'Exact Location',
        type: 'item',
        onSelect: () => {
          onUpdate('radiusInMiles', null)
        },
        isSelected: isNil(value.radiusInMiles)
      }, {
        id: 'within-5-miles',
        title: 'Within 5 Miles',
        type: 'item',
        onSelect: () => {
          onUpdate('radiusInMiles', 5)
        },
        isSelected: value.radiusInMiles === 5
      }, {
        id: 'within-10-miles',
        title: 'Within 10 Miles',
        type: 'item',
        onSelect: () => {
          onUpdate('radiusInMiles', 10)
        },
        isSelected: value.radiusInMiles === 10
      }, {
        id: 'within-20-miles',
        title: 'Within 20 Miles',
        type: 'item',
        onSelect: () => {
          onUpdate('radiusInMiles', 20)
        },
        isSelected: value.radiusInMiles === 20
      }, {
        id: 'within-50-miles',
        title: 'Within 50 Miles',
        type: 'item',
        onSelect: () => {
          onUpdate('radiusInMiles', 50)
        },
        isSelected: value.radiusInMiles === 50
      })
    }
    if (!value.required) {
      if (items.length > 0) {
        items.push({
          title: 'delete-separator',
          type: 'separator'
        })
      }
      items.push({
        id: 'delete-pill',
        title: 'Delete',
        variant: 'negative',
        onSelect: () => {
          onRemove()
        }
      })
    }
    return items
  }, [
    onRemove,
    onUpdate,
    type,
    useNegative,
    useOptional,
    value.negative,
    value.optional,
    value.radiusInMiles,
    value.required
  ])

  const trigger = useMemo(() => {
    return (
      <Tooltip
        position='top'
        disabled={!tooltip}
        trigger={
          <S.CriteriaPill $minWidth={70}>
            <Flex $align='center' $gap={4} $width='auto' $flex='1 1 auto'>
              {!value.optional && useOptional && <Icon name={Icons.pin} size={12} color='fgPrimary' />}
              {value.negative && useNegative && <Icon name={Icons.ban} size={12} color='negativeBg' />}
              {value.required && <Icon name={Icons.lock} size={12} color='fgPrimary' />}
              {value.icon &&
                <Avatar
                  $size={16}
                  $innerPadding={0}
                  $border={false}
                  $type="logo"
                  initials={value.label}
                  company={{
                    name: value.label,
                    logoUrl: value.icon
                  }}
                />
              }
              <Caption size='XS' $fontWeight={400} $lineHeight={1}>{value.label}</Caption>
              {value.subtitle && (
                <Caption size="XS" $color="fgTertiary" $whiteSpace="nowrap" $ellipsis $fontWeight={400} $lineHeight={1}>
                  {value.subtitle}
                </Caption>
              )}
            </Flex>
            {actionItems.length > 0 && <Icon name={Icons.chevronDown} size={12} color='fgTertiary' />}
          </S.CriteriaPill>
        }
      >
        {tooltip}
      </Tooltip>
    )
  }, [
    actionItems.length,
    tooltip,
    useNegative,
    useOptional,
    value
  ])

  if (actionItems.length === 0) {
    return trigger
  }

  return (
    <Dropdown
      trigger={trigger}
      items={actionItems}
    />
  )
}
