import { HEADER_HEIGHT } from 'src/styles/constants'
import type { Spacing } from 'src/styles/theme/types'
import styled from 'styled-components'

export const PageHeader = styled.header<{ $marginBottom: Spacing }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme, $marginBottom }) => theme.spacing[$marginBottom ?? 24]};
  h1 {
    font-size: ${({ theme }) => theme.fontSizes[16]};
    font-weight: ${({ theme }) => theme.fontWeights[500]};
    line-height: ${HEADER_HEIGHT};
  }
  h2 {
    font-size: ${({ theme }) => theme.fontSizes[14]};
    font-weight: ${({ theme }) => theme.fontWeights[400]};
    line-height: ${({ theme }) => theme.lineHeights['1.43']};
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.75rem;
`
