import { Icons } from 'src/components/primitives/icon'
import { CriteriaKey, DEFAULT_BOOLEAN_CRITERIA, DEFAULT_CUSTOM_REQUIREMENT_CRITERIA, DEFAULT_MIN_MAX_CRITERIA } from 'src/libs/api/backend/candidate_search'
import type { CriteriaValue } from 'src/libs/api/backend/candidate_search'

export type CriteriaType = 'location' | 'company' | 'school' | 'boolean-value'

export const FUNDING_ROUND_CRITERIA = new Map<number, string>([
  [1, 'Pre-Seed'],
  [2, 'Seed'],
  [3, 'Series A'],
  [4, 'Series B'],
  [5, 'Series C'],
  [6, 'Series D'],
  [7, 'Series E'],
  [8, 'Series F+'],
  [9, 'IPO']
])

export const DEGREE_OPTIONS = new Map<number, string>([
  [0, 'No degree'],
  [1, 'Bachelors'],
  [2, 'Masters'],
  [3, 'Doctorate']
])

export const TIMEZONE_OPTIONS = new Map<string, string>([
  ['eastern_time', 'Eastern Time'],
  ['central_time', 'Central Time'],
  ['pacific_time', 'Pacific Time'],
  ['mountain_time', 'Mountain Time'],
  ['alaska_time', 'Alaska Time'],
  ['hawaii_aleutian_time', 'Hawaii Aleutian Time']
])

export const SEX_OPTIONS = new Map<string, string>([
  ['female', 'Female'],
  ['male', 'Male']
])

export const DEFAULT_CRITERIA = {
  [CriteriaKey.CURRENT_JOB_TITLES]: []
}

export const CriteriaProperties = new Map<string, {
  label: string
  defaultValue: CriteriaValue
  options?: Map<number | string, string>
  type?: CriteriaType
}>([
  [CriteriaKey.CURRENT_JOB_TITLES, {
    label: 'Current Job Titles',
    defaultValue: []
  }],
  [CriteriaKey.LOCATIONS, {
    label: 'Candidate Location',
    defaultValue: [],
    type: 'location'
  }],
  [CriteriaKey.MONTHS_IN_CURRENT_PROFESSION, {
    label: 'Years in Current Profession',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.MONTHS_AT_CURRENT_COMPANY, {
    label: 'Years at Current Company',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.CURRENT_COMPANY_HC, {
    label: "Company's Current Headcount",
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.CURRENT_COMPANY_JOINED_AT_HEADCOUNT, {
    label: "Company's Headcount at Time of Hire",
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.CURRENT_COMPANY_CURRENT_FUNDING_ROUND, {
    label: "Company's Current Funding Stage",
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    options: FUNDING_ROUND_CRITERIA
  }],
  [CriteriaKey.CURRENT_COMPANY_JOINED_AT_FUNDING_ROUND, {
    label: "Company's Funding Stage at Time of Hire",
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    options: FUNDING_ROUND_CRITERIA
  }],
  [CriteriaKey.AVERAGE_TENURE_IN_MONTHS, {
    label: 'Average Tenure at Companies',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.COMPANIES, {
    label: 'Companies Worked At',
    defaultValue: [],
    type: 'company'
  }],
  [CriteriaKey.CURRENT_COMPANY, {
    label: 'Current Company',
    defaultValue: [],
    type: 'company'
  }],
  [CriteriaKey.ANY_COMPANY_JOINED_AT_HEADCOUNT, {
    label: 'Headcount When Hired at Any Company',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.ANY_COMPANY_JOINED_AT_FUNDING_ROUND, {
    label: 'Funding Stage When Hired at Any Company',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    options: FUNDING_ROUND_CRITERIA
  }],
  [CriteriaKey.SCHOOLS, {
    label: 'Universities Attended',
    defaultValue: [],
    type: 'school'
  }],
  [CriteriaKey.ATTENDED_IVY_LEAGUE, {
    label: 'Attended Ivy League',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA
  }],
  [CriteriaKey.GRADUATION_YEAR, {
    label: 'Graduation Year',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.DEGREE, {
    label: 'Highest Degree',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA,
    options: DEGREE_OPTIONS
  }],
  [CriteriaKey.CURRENT_COMPANY_HAS_NOTABLE_INVESTOR, {
    label: 'Works at a Company with a Notable Investor',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA
  }],
  [CriteriaKey.WORKED_AT_COMPANY_WITH_NOTABLE_INVESTOR, {
    label: 'Worked at a Company with a Notable Investor',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA
  }],
  [CriteriaKey.WORKS_AT_YC_COMPANY, {
    label: 'Works at a YC-backed Company',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA
  }],
  [CriteriaKey.WORKED_AT_YC_COMPANY, {
    label: 'Worked at a YC-backed Company',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA
  }],
  [CriteriaKey.CUSTOM_REQUIREMENTS, {
    label: 'Custom',
    defaultValue: [DEFAULT_CUSTOM_REQUIREMENT_CRITERIA]
  }],
  [CriteriaKey.KNOWN_LANGUAGES, {
    label: 'Known Languages',
    defaultValue: []
  }],
  [CriteriaKey.ASSIGNED_TIMEZONES, {
    label: 'Timezones',
    defaultValue: [],
    options: TIMEZONE_OPTIONS,
    type: 'location'
  }],
  [CriteriaKey.SEX, {
    label: 'Gender',
    defaultValue: [],
    options: SEX_OPTIONS
  }],
  [CriteriaKey.HAS_VISA_SPONSORSHIP, {
    label: 'Needs Visa Sponsorship',
    defaultValue: DEFAULT_BOOLEAN_CRITERIA,
    type: 'boolean-value'
  }]
])

export const NewCriteriaList = [
  {
    id: 'common-criteria-title',
    title: 'COMMON CRITERIA',
    type: 'label'
  },
  {
    criteriaKey: CriteriaKey.LOCATIONS,
    icon: Icons.mapPin
  },
  {
    criteriaKey: CriteriaKey.MONTHS_IN_CURRENT_PROFESSION,
    icon: Icons.calendarDays
  },
  {
    title: 'Candidate Details',
    icon: Icons.userCircle,
    type: 'group',
    items: [
      {
        criteriaKey: CriteriaKey.SEX
      },
      {
        criteriaKey: CriteriaKey.ASSIGNED_TIMEZONES
      },
      {
        criteriaKey: CriteriaKey.HAS_VISA_SPONSORSHIP
      },
      {
        criteriaKey: CriteriaKey.KNOWN_LANGUAGES
      }
    ]
  },
  {
    title: 'Current Position',
    icon: Icons.briefcase,
    type: 'group',
    items: [
      {
        criteriaKey: CriteriaKey.MONTHS_AT_CURRENT_COMPANY
      },
      {
        criteriaKey: CriteriaKey.CURRENT_COMPANY_HC
      },
      {
        criteriaKey: CriteriaKey.CURRENT_COMPANY_JOINED_AT_HEADCOUNT
      },
      {
        criteriaKey: CriteriaKey.CURRENT_COMPANY_CURRENT_FUNDING_ROUND
      },
      {
        criteriaKey: CriteriaKey.CURRENT_COMPANY_JOINED_AT_FUNDING_ROUND
      }
    ]
  },
  {
    title: 'Overall Experience',
    icon: Icons.experienceClock,
    type: 'group',
    items: [
      {
        criteriaKey: CriteriaKey.CURRENT_COMPANY
      },
      {
        criteriaKey: CriteriaKey.COMPANIES
      },
      {
        criteriaKey: CriteriaKey.AVERAGE_TENURE_IN_MONTHS
      },
      {
        criteriaKey: CriteriaKey.CURRENT_COMPANY_HAS_NOTABLE_INVESTOR
      },
      {
        criteriaKey: CriteriaKey.WORKED_AT_COMPANY_WITH_NOTABLE_INVESTOR
      },
      {
        criteriaKey: CriteriaKey.WORKS_AT_YC_COMPANY
      },
      {
        criteriaKey: CriteriaKey.WORKED_AT_YC_COMPANY
      },
      {
        criteriaKey: CriteriaKey.ANY_COMPANY_JOINED_AT_HEADCOUNT
      },
      {
        criteriaKey: CriteriaKey.ANY_COMPANY_JOINED_AT_FUNDING_ROUND
      }
    ]
  },
  {
    title: 'Education',
    icon: Icons.graduationCap,
    type: 'group',
    items: [
      {
        criteriaKey: CriteriaKey.SCHOOLS
      },
      {
        criteriaKey: CriteriaKey.ATTENDED_IVY_LEAGUE
      },
      {
        criteriaKey: CriteriaKey.GRADUATION_YEAR
      },
      {
        criteriaKey: CriteriaKey.DEGREE
      }
    ]
  }
]
