import styled from 'styled-components'

export const Banner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  border-radius: ${({ theme }) => theme.spacing[6]};
  background-color: ${({ theme }) => theme.colors.tintTranslucent5};
  gap: ${({ theme }) => theme.spacing[24]};
`
