import styled from 'styled-components'
import type { StyleProps } from './typography'
import { isNil } from 'lodash'

// export const Text = styled.p<TextStyleProps>`
//   font-size: ${({ theme, $size }) => theme.fontSizes[$size ?? 16]};
//   font-weight: ${({ theme, $weight }) => theme.fontWeights[$weight ?? 400]};
//   line-height: ${({ $lineHeight, theme }) => theme.lineHeights[$lineHeight ?? 1.2]};
//   text-transform: ${({ $transform }) => $transform};
//   color: ${({ $color, theme }) => theme.colors[$color ?? 'fgPrimary']};
//   letter-spacing: ${({ $letterSpacing, theme }) => theme.letterSpacings[$letterSpacing ?? 0]};
//   white-space: ${({ $whiteSpace }) => $whiteSpace};
// `

export const Heading = styled.p<StyleProps>`
  font-size: ${({ theme, $fontSize }) => theme.fontSizes[$fontSize ?? '16']};
  font-weight: 700;
  color: ${({ theme, $color }) => theme.colors[$color ?? 'fgPrimary']};
  text-transform: ${({ $transform }) => $transform};
  white-space: ${({ $whiteSpace }) => $whiteSpace};
  text-align: ${({ $align }) => $align};
  line-height: ${({ theme, $lineHeight }) => theme.lineHeights[$lineHeight ?? '1.2']};
  ${({ $hasGradient }) =>
    $hasGradient &&
    `
    background: linear-gradient(90deg, #00A7FF 0%, #1AB484 49.64%, #8371F2 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `}
  ${({ $ellipsis }) =>
    $ellipsis &&
    `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `
  }
`

/* font-weight: ${({ theme, $fontWeight }) => theme.fontWeights[$fontWeight ?? 500]}; */
export const Caption = styled.p<StyleProps>`
  font-size: ${({ theme, $fontSize }) => theme.fontSizes[$fontSize ?? '16']};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  color: ${({ theme, $color }) => theme.colors[$color ?? 'fgPrimary']};
  text-transform: ${({ $transform }) => $transform};
  white-space: ${({ $whiteSpace }) => $whiteSpace};
  text-align: ${({ $align }) => $align};
  line-height: ${({ theme, $lineHeight }) => theme.lineHeights[$lineHeight ?? '1.2']};
  ${({ $hasGradient }) =>
    $hasGradient &&
    `
    background: linear-gradient(90deg, #00A7FF 0%, #1AB484 49.64%, #8371F2 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `}
  ${({ $ellipsis }) =>
    $ellipsis &&
    `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `
  }
`

export const Paragraph = styled.p<StyleProps>`
  font-size: ${({ theme, $fontSize }) => theme.fontSizes[$fontSize ?? '16']};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  color: ${({ theme, $color }) => theme.colors[$color ?? 'fgSecondary']};
  text-transform: ${({ $transform }) => $transform};
  white-space: ${({ $whiteSpace }) => $whiteSpace};
  text-align: ${({ $align }) => $align};
  line-height: ${({ theme, $lineHeight }) => theme.lineHeights[$lineHeight ?? '1.2']};
  ${({ $hasGradient }) =>
    $hasGradient &&
    `
      background: linear-gradient(90deg, #00A7FF 0%, #1AB484 49.64%, #8371F2 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `
  }

  * {
    font-size: inherit;
    color: inherit;
    text-decoration: inherit;
  }

  a {
    font-size: inherit;
    color: ${({ theme }) => theme.colors.tintFg};
    text-decoration: underline;
  }

  a:hover {
    color: ${({ theme }) => theme.colors.tintBg};
  }
  ${({ $ellipsis }) =>
    $ellipsis &&
    `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `
  }
`

export const Mention = styled.p<StyleProps>`
  position: relative;
  font-size: ${({ theme, $fontSize }) => theme.fontSizes[$fontSize ?? '16']};
  font-weight: ${({ theme }) => theme.fontWeights['400']};
  color: ${({ theme, $color }) => theme.colors[$color ?? 'tintBg']};
  text-transform: ${({ $transform }) => $transform};
  white-space: ${({ $whiteSpace }) => $whiteSpace};
  margin-right: 0.2rem;
  text-align: ${({ $align }) => $align};
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -0.075rem;
    left: -0.15rem;
    width: calc(100% + 0.3rem);
    height: calc(100% + 0.15rem);
    background-color: ${({ theme }) => theme.colors.tintTranslucent5};
    border-radius: 2px;
    pointer-events: none;
  }
`

export const Highlight = styled.span<StyleProps>`
  font-size: ${({ theme, $fontSize }) => theme.fontSizes[$fontSize ?? '16']};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  /* color: ${({ theme }) => theme.colors.warningFg}; */
  color: #644E3D;
  text-transform: ${({ $transform }) => $transform};
  white-space: nowrap;
  text-align: ${({ $align }) => $align};
  line-height: ${({ theme, $lineHeight }) => theme.lineHeights[$lineHeight ?? '1.2']};
  background-color: ${({ theme }) => theme.colors.warningTranslucent25};
  border-bottom: dashed 1px;
  border-color: ${({ theme }) => theme.colors.warningFg};
  padding: 0 0.15rem;
  border-radius: 4px;
`

export const Link = styled.a<StyleProps>`
  color: ${({ color }) => isNil(color) ? 'inherit !important' : color};
  font-size: ${({ theme, $fontSize }) => isNil($fontSize)
    ? 'intehirt'
    : theme.fontSizes[$fontSize ?? '16']};
  font-weight: ${({ theme }) => theme.fontWeights['400']};
  text-transform: ${({ $transform }) => $transform};
  text-align: ${({ $align }) => $align};
  white-space: ${({ $whiteSpace }) => $whiteSpace};

  &:hover {
    color: inherit;
  }
`
