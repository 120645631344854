import { Toggle } from 'src/components/primitives/toggle'
import * as S from './conditional-field-group.styled'
import { useEffect, useState } from 'react'
import { FieldLabel } from '../field-label'
import { FormElement } from '../form-element'
import { When } from 'src/components/blocks/when'
import { Button } from 'src/components/primitives/button'
import { Paragraph } from 'src/components/primitives/typography'
import { Spacer } from 'src/components/primitives/spacer'

export interface ConditionalFieldGroupStyleProps {
  $variant?: 'outline' | 'ghost'
}

interface ConditionalFieldGroupProps extends ConditionalFieldGroupStyleProps {
  defaultChecked?: boolean
  label: string
  description?: string
  tooltip?: string
  checkedFields?: React.ReactNode
  uncheckedFields?: React.ReactNode
  onStatusChange?: (status: boolean) => void
}

export const ConditionalFieldGroup = ({
  $variant = 'outline',
  defaultChecked = false,
  label,
  description,
  tooltip,
  checkedFields,
  uncheckedFields,
  onStatusChange
}: ConditionalFieldGroupProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState<boolean>(defaultChecked)

  useEffect(() => {
    setIsChecked(defaultChecked)
  }, [defaultChecked])

  return (
    <FormElement>
      <S.Wrapper $variant={$variant}>
        <S.Top>
          <FieldLabel label={label} />
          <S.ToggleWrapper>
            <Toggle
              name={label}
              checked={isChecked}
              onChange={() => {
                const newState = !isChecked
                setIsChecked(newState)
                onStatusChange?.(newState)
              }}
              $toggleSize={16}
            />
            <When condition={!!tooltip}>
              <Button
                nested
                $variant="ghost"
                $colorTheme="muted"
                $height={16}
                $width={16}
                $fontSize={12}
                leadingIcon="help-circle"
                ariaLabel="Get help"
                tooltip={{
                  text: tooltip
                }}
              />
            </When>
          </S.ToggleWrapper>
        </S.Top>
        <When condition={isChecked && !!checkedFields}>
          <S.Content>
            {description && (
              <Paragraph size="XS" $color="fgSecondary">{description}</Paragraph>
            )}
            <Spacer $size={16} />
            {checkedFields}
          </S.Content>
        </When>
        <When condition={!isChecked && !!uncheckedFields}>
          <S.Content>{uncheckedFields}</S.Content>
        </When>
      </S.Wrapper>
    </FormElement>
  )
}
