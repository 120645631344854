import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from 'src/components/primitives/button'
import { Editor, SubjectEditor } from 'src/components/blocks/editor'
import { Flex } from 'src/components/primitives/flex'
import { When } from '../when'
import { Icon } from 'src/components/primitives/icon'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { isNil } from 'lodash'
import * as S from './message-composer.styled'
import { Badge } from 'src/components/primitives/badge'
import { useSession } from 'src/hooks/queries/use-session'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { Spacer } from 'src/components/primitives/spacer'
import { EmailRecipients } from '../email-recipients'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import { getEmailAccountAuthUrl } from 'src/libs/auth-urls'
import { TagInput } from 'src/components/primitives/tag-input'
import { invalidateEmailAccounts } from 'src/hooks/invalidate-email-accounts'
import { SenderSelection } from '../sender-selection'
import type { EmailAccount, LinkedInAccount } from 'src/libs/api/backend/users'
import type { Editor as TiptapEditor } from '@tiptap/react'
import { AttachmentList, AttachmentUploadStatus } from '../attachments-list'
import type { AttachmentUpload } from '../attachments-list'
import { generateUploadUrlApi } from 'src/libs/api/backend/url'
import { UploadAttachments } from '../upload-attachments'
import axios from 'axios'
import { EmailVariableButton } from './actions'
import { MessageType, SequenceStepType } from 'src/libs/api/backend/sequences'

const MAXIMUM_ATTACHMENT_SIZE = 25 * 1024 * 1024 // 25MB

const escapeFileName = (fileName: string): string => {
  return fileName.replace(/[^a-z0-9.]/gi, '_').toLowerCase()
}

// export type EmailmessageType = 'email' | 'linkedInMail'

export interface EmailData {
  subject?: string | null
  subjectPlaceholder?: string | null
  body?: string | null
  messageType?: MessageType
  sendingEmailAccountId?: string
  sendingUserId?: string
  sendingEmailAlias?: string | null
  sendingLinkedInAccountId: string | null
  recipients?: CandidateJobExpanded[] | null
  cc?: string[] | null
  bcc?: string[] | null
  attachmentUploads?: Array<{ s3Key: string, fileName: string }> | null
  type?: SequenceStepType
}

export interface MessageComposerProps {
  isEditable?: boolean
  // defaultMessageType?: MessageType
  isGenerating?: boolean
  initialEmailBody?: string
  onDataChanged: (updated: EmailData) => void
  currentData: EmailData
  onEditorHeightChange?: (height: number) => void
  leftActions?: React.ReactNode
  rightActions?: React.ReactNode
  trailingToolbarActions?: React.ReactNode
  useVariables?: boolean
  useCcFields?: boolean
  onCancelGeneratingEmail?: () => void
  forceEditorFocus?: boolean
  minHeight?: string
  onClose?: () => void
  onSendableStateChange?: (isSendable: boolean) => void
  disableEmailAccountSelection?: boolean
  emailSuggestionFooter?: React.ReactNode | null
  useAttachments?: boolean
  editorFooterContent?: React.ReactNode | null
  $editorHeight?: string
  $maxHeight?: string
}

export interface EmailToolbarProps {
  children: React.ReactNode
}

export const MessageComposer = ({
  isGenerating = false,
  isEditable = true,
  initialEmailBody,
  useVariables = false,
  useCcFields = true,
  onDataChanged,
  currentData,
  onEditorHeightChange,
  forceEditorFocus,
  minHeight,
  // onCancelGeneratingEmail,
  leftActions,
  rightActions,
  trailingToolbarActions,
  onClose,
  onSendableStateChange,
  disableEmailAccountSelection = false,
  emailSuggestionFooter,
  useAttachments,
  editorFooterContent,
  $editorHeight,
  $maxHeight
}: MessageComposerProps): JSX.Element => {
  const { data: sessionData } = useSession()
  // ignore the first update since the editor will modify the content on mount
  const [initializedBody, setInitializedBody] = useState(false)
  const [initializedSubject, setInitializedSubject] = useState(false)
  const { data: orgUsers, refetch: refetchOrgUsers } = useOrgUsersQuery()
  const [bodyEditor, setBodyEditor] = useState<TiptapEditor | null>(null)
  const [subjectEditor, setSubjectEditor] = useState<TiptapEditor | null>(null)
  const [lastFocusedEditor, setLastFocusedEditor] = useState<TiptapEditor | null>(null)
  const [attachments, setAttachments] = useState<AttachmentUpload[]>([])
  const [attachmentError, setAttachmentError] = useState<string | null>(null)
  const isSubjectEditable = useMemo(() => isNil(currentData.subjectPlaceholder), [currentData.subjectPlaceholder])
  const [titleFocused, setTitleFocused] = useState(false)

  const selectedMessageType = useMemo(() => {
    if (!isNil(currentData.sendingLinkedInAccountId)) {
      return MessageType.LINKEDIN_MAIL
    }
    return MessageType.EMAIL
  }, [currentData.sendingLinkedInAccountId])

  useEffect(() => {
    if (!currentData.subject && isSubjectEditable && !!forceEditorFocus) {
      setTitleFocused(true)
    } else if (!forceEditorFocus) {
      // Set title focused to false when step is out of focused is not set
      setTitleFocused(false)
    }
  }, [forceEditorFocus, isSubjectEditable, currentData.subject])

  useEffect(() => {
    setInitializedBody(false)
  }, [initialEmailBody])

  const userEmailAccounts = useMemo(() => {
    return orgUsers?.flatMap((user) => {
      return user.emailAccounts.map((emailAccount) => ({
        ...emailAccount,
        profilePhotoUrl: user.profilePhotoUrl
      }))
    }) ?? []
  }, [orgUsers])

  const linkedInAccounts = useMemo(() => {
    return orgUsers?.flatMap((user) => user.linkedInAccounts) ?? []
  }, [orgUsers])

  useEffect(() => {
    const currentAttachmentKeys = (currentData.attachmentUploads ?? []).map((attachment) => attachment.s3Key)
    if (attachments.some((attachment) => !currentAttachmentKeys.includes(attachment.s3Key))) {
      const updatedData = {
        ...currentData,
        attachmentUploads: attachments.map((attachment) => ({
          s3Key: attachment.s3Key,
          fileName: attachment.fileName
        }))
      }
      onDataChanged(updatedData)
    }
  }, [attachments, currentData, onDataChanged])

  const uploadAttachments = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    setAttachmentError('')
    const files = e?.target?.files
    if (!files) {
      return
    }
    const uploadedFiles = Array.from(files)
    const totalFilesSize = uploadedFiles.reduce((acc, file) => acc + file.size, 0) + attachments.reduce((acc, attachment) => acc + attachment.size, 0)
    if (totalFilesSize > MAXIMUM_ATTACHMENT_SIZE) {
      setAttachmentError('Attachments size exceeds 25MB')
      return
    }
    setAttachments([
      ...attachments,
      ...uploadedFiles.map((file: File) => ({
        fileName: escapeFileName(file.name),
        contentType: file.type,
        size: file.size,
        s3Key: '',
        id: escapeFileName(file.name),
        status: AttachmentUploadStatus.PENDING
      }))
    ])
    const uploadLinks = await Promise.all(
      uploadedFiles.map(async (file: File) => {
        const response = await generateUploadUrlApi(file.name)

        setAttachments((prevAttachments) => {
          return prevAttachments.map((attachment) => {
            if (attachment.fileName === escapeFileName(file.name)) {
              return {
                ...attachment,
                s3Key: response.key,
                status: AttachmentUploadStatus.UPLOADING
              }
            }
            return attachment
          })
        })
        return { ...response, file }
      })
    )
    console.log('upload links: ', uploadLinks)

    await Promise.all(
      uploadLinks.map(async ({ uploadSignedUrl, key, file }) => {
        try {
          await axios.put(uploadSignedUrl, file, {
            headers: {
              'Content-Type': file.type
            },
            onUploadProgress: (progressEvent) => {
              setAttachments((prevAttachments) => {
                return prevAttachments.map((attachment) => {
                  if (attachment.s3Key === key) {
                    const progress = Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? file.size))
                    return {
                      ...attachment,
                      status: AttachmentUploadStatus.UPLOADING,
                      progress
                    }
                  }
                  return attachment
                })
              })
            }
          }).then(() => {
            setAttachments((prevAttachments) => {
              return prevAttachments.map((attachment) => {
                if (attachment.s3Key === key) {
                  return {
                    ...attachment,
                    status: AttachmentUploadStatus.UPLOADED
                  }
                }
                return attachment
              })
            })
          })
        } catch (error) {
          setAttachments((prevAttachments) => {
            return prevAttachments.map((attachment) => {
              if (attachment.s3Key === key) {
                return {
                  ...attachment,
                  status: AttachmentUploadStatus.ERROR
                }
              }
              return attachment
            })
          })
        }
      })
    )
  }, [attachments])

  const removeAttachment = useCallback((s3Key: string) => {
    setAttachmentError('')
    setAttachments((prevAttachments) => {
      return prevAttachments.filter((attachment) => attachment.s3Key !== s3Key)
    })
  }, [])

  const [showCC, setShowCC] = useState(false)
  const [showBCC, setShowBCC] = useState(false)

  const selectedEmailAccount = useMemo(() => {
    // fall back to session user if sendingEmailAccountId is not found
    if (userEmailAccounts.length === 0) {
      return
    }
    const match = userEmailAccounts.find((account) => account.id === currentData.sendingEmailAccountId)
    if (isNil(match)) {
      const fallback = userEmailAccounts.find((account) => account.userId === sessionData?.user.id)
      console.log(`[email-composer] unable to find sendingEmailAccount ${currentData.sendingEmailAccountId}, falling back to session user ${fallback?.email}`)
      onDataChanged({
        ...currentData,
        sendingEmailAccountId: fallback?.id,
        sendingUserId: fallback?.userId,
        sendingEmailAlias: null
      })
      return fallback
    }
    return match
  }, [userEmailAccounts, currentData, onDataChanged, sessionData?.user.id])

  useEffect(() => {
    onSendableStateChange?.(
      !!selectedEmailAccount?.hasAccessToken &&
      !!selectedEmailAccount?.currentUserHasSendAsPermission
    )
  }, [selectedEmailAccount, onSendableStateChange])

  const handleSubjectChange = useCallback(
    (data: string): void => {
      if (!initializedSubject) {
        setInitializedSubject(true)
        return
      }
      const updatedEmailData = {
        ...currentData,
        subject: data
      }
      onDataChanged(updatedEmailData)
    },
    [currentData, initializedSubject, onDataChanged]
  )

  const handleBodyChange = useCallback(
    (data: string): void => {
      if (!initializedBody) {
        setInitializedBody(true)
        return
      }
      const updatedContent = {
        ...currentData,
        body: data
      }
      onDataChanged(updatedContent)
    },
    [currentData, initializedBody, onDataChanged]
  )

  const handleSenderChange = useCallback(
    (sender: EmailAccount, sendingEmailAlias: string | null, linkedInAccount: LinkedInAccount | null, messageType: MessageType): void => {
      const updatedContent = {
        ...currentData,
        sendingEmailAccountId: sender.id,
        sendingUserId: sender.userId,
        sendingEmailAlias,
        sendingLinkedInAccountId: linkedInAccount?.id ?? null,
        messageType,
        type: linkedInAccount
          ? SequenceStepType.AUTOMATED_LINKEDIN_INMAIL
          : SequenceStepType.AUTOMATED_EMAIL
      }
      onDataChanged(updatedContent)
      onSendableStateChange?.(sender.hasAccessToken && sender.currentUserHasSendAsPermission)
    },
    [currentData, onDataChanged, onSendableStateChange]
  )

  const handleCCChange = useCallback(
    (cc: string[]): void => {
      const updatedContent = {
        ...currentData,
        cc
      }
      onDataChanged(updatedContent)
    },
    [currentData, onDataChanged]
  )

  const handleBCCChange = useCallback(
    (bcc: string[]): void => {
      const updatedContent = {
        ...currentData,
        bcc
      }
      onDataChanged(updatedContent)
    },
    [currentData, onDataChanged]
  )

  const reconnect = useCallback((): void => {
    if (!isNil(selectedEmailAccount)) {
      const redirectUrl = `${window.location.origin}/login/redirect/close`
      const authUrl = getEmailAccountAuthUrl({ type: selectedEmailAccount.type, email: selectedEmailAccount.email }, redirectUrl)
      const loginWindow = window.open(authUrl, '_blank', 'popup=1,height=600,width=600')
      const timer = setInterval(() => {
        if (loginWindow?.closed) {
          void invalidateEmailAccounts()
          void refetchOrgUsers()
          clearInterval(timer)
        }
      }, 500)
    }
  }, [refetchOrgUsers, selectedEmailAccount])

  useEffect(() => {
    // Clean up editor state when switching message types
    setBodyEditor(null)
    setLastFocusedEditor(null)
  }, [selectedMessageType])

  // We want to find the appropriate linkedin account for the current sending user
  // when the sequence step sending type changes
  useEffect(() => {
    if (!currentData.type) return

    const isLinkedInSendingType = currentData.type === SequenceStepType.AUTOMATED_LINKEDIN_INMAIL || currentData.type === SequenceStepType.MANUAL_LINKEDIN_INMAIL

    if (isLinkedInSendingType) {
      const linkedInAccount = linkedInAccounts.find(account => account.userId === currentData.sendingUserId)

      if (linkedInAccount) {
        const updatedData = {
          ...currentData,
          sendingLinkedInAccountId: linkedInAccount.id
        }
        onDataChanged(updatedData)
      }
    } else {
      if (currentData.sendingLinkedInAccountId) {
        const updatedData = {
          ...currentData,
          sendingLinkedInAccountId: null
        }
        onDataChanged(updatedData)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData.type, currentData.sendingUserId, linkedInAccounts])

  return (
    <S.EmailComposer>
      <S.EmailFields>
        <S.Addressbar>
          <S.AddressRow>
            <Paragraph size="XS" $color="fgTertiary">
              From
            </Paragraph>
            <Flex $align="center" $justify="space-between">
              <SenderSelection
                handleSenderChange={handleSenderChange}
                selectedEmailAccount={selectedEmailAccount}
                selectedEmailAlias={currentData.sendingEmailAlias ?? null}
                selectedLinkedInAccountId={currentData.sendingLinkedInAccountId ?? null}
                userEmailAccounts={userEmailAccounts}
                linkedInAccounts={linkedInAccounts}
                disableEmailAccountSelection={disableEmailAccountSelection}
              />
              <Flex $align="center" $justify="flex-end" $gap={8}>
                <When condition={!selectedEmailAccount?.hasAccessToken}>
                  <Badge
                    $variant="negativeLight"
                    leadingIcon="alert-triangle"
                    $transform="none"
                    $fontSize={12}
                    $height={24}
                    $padding="casual"
                  >
                    Email account not connected
                  </Badge>
                  <Button
                    $height={24}
                    $variant="fill"
                    $colorTheme="tint"
                    leadingIcon="refresh-cw"
                    $fontSize={12}
                    ariaLabel='Reconnect email account'
                    onClick={reconnect}
                    disabled={selectedEmailAccount?.userId !== sessionData?.user.id}
                    tooltip={{
                      text: selectedEmailAccount?.userId === sessionData?.user.id ? undefined : 'Have your teammate reconnect this email account or switch to a different sender',
                      position: 'top'
                    }}
                  >
                    Reconnect
                  </Button>
                </When>
                <When condition={!isNil(onClose)}>
                  <Button
                    ariaLabel="Close"
                    $variant="ghost"
                    leadingIcon="x"
                    $height={16}
                    $width={16}
                    $fontSize={12}
                    onClick={() => {
                      if (onClose) {
                        onClose()
                      }
                    }}
                  />
                </When>
              </Flex>
            </Flex>
            {/* <When condition={isGenerating}>
              <LoadingSkeleton $variant='SenderSelection' />
            </When>
            <When condition={!isGenerating}>
            </When> */}
          </S.AddressRow>
          <When condition={selectedMessageType === MessageType.EMAIL}>
            {currentData.recipients && (
              <>
                <Spacer $size={2} />
                <S.AddressRow>
                  <Paragraph size="XS" $color="fgTertiary">
                    To
                  </Paragraph>
                  <Flex>
                    <Flex $flex="1" $align="center">
                      <EmailRecipients candidateJobs={currentData.recipients} />
                    </Flex>
                    {useCcFields && !showCC &&
                      <Button
                        $variant="ghost"
                        $colorTheme="muted"
                        $align="center"
                        $height={20}
                        $fontSize={12}
                        onClick={() => {
                          setShowCC(!showCC)
                        }}
                      >
                        Cc
                      </Button>
                    }
                    {useCcFields && !showBCC &&
                      <Button
                        $variant="ghost"
                        $colorTheme="muted"
                        $align="center"
                        $height={20}
                        $fontSize={12}
                        onClick={() => {
                          setShowBCC(!showBCC)
                        }}
                      >
                        Bcc
                      </Button>
                    }
                  </Flex>
                </S.AddressRow>
              </>
            )}
            {showCC && (
              <>
                <Spacer $size={2} />
                <S.AddressRow>
                  <Paragraph size="XS" $color="fgTertiary">
                    Cc
                  </Paragraph>
                  <TagInput onTagsChange={handleCCChange} />
                </S.AddressRow>
              </>
            )}
            {showBCC && (
              <>
                <Spacer $size={2} />
                <S.AddressRow>
                  <Paragraph size="XS" $color="fgTertiary">
                    Bcc
                  </Paragraph>
                  <TagInput onTagsChange={handleBCCChange} />
                </S.AddressRow>
              </>
            )}
          </When>
        </S.Addressbar>
        <When condition={selectedMessageType === MessageType.EMAIL}>
          <S.Subjectline>
            <Paragraph size="XS" $color="fgTertiary">
              Subject
            </Paragraph>
            <SubjectEditor
              setEditor={setSubjectEditor}
              initialContent={isSubjectEditable ? currentData.subject : currentData.subjectPlaceholder}
              onDataChanged={(data: string) => {
                if (isSubjectEditable) {
                  handleSubjectChange(data)
                }
              }}
              forceEditorFocus={titleFocused}
              isEditable={isSubjectEditable}
              content={currentData.subject}
              onFocusChange={() => { setLastFocusedEditor(subjectEditor) }}
            />
          </S.Subjectline>
        </When>
        <Editor
          key={`editor-${selectedMessageType}`}
          isEditable={isEditable}
          plainText={selectedMessageType === MessageType.LINKEDIN_MAIL}
          initialContent={initialEmailBody}
          customActions={
            <Flex $direction='column' $gap={8}>
              {emailSuggestionFooter}
              {selectedMessageType === MessageType.EMAIL && useAttachments && (
                <AttachmentList
                  attachments={attachments}
                  onDelete={removeAttachment}
                  error={attachmentError}
                />
              )}
            </Flex>
          }
          setEditor={setBodyEditor}
          content={isGenerating ? '' : currentData.body ?? null}
          placeholder="Compose a message"
          onDataChanged={(data) => {
            handleBodyChange(data)
          }}
          forceEditorFocus={titleFocused ? false : forceEditorFocus}
          onEditorHeightChange={onEditorHeightChange}
          onFocusChange={() => { setLastFocusedEditor(bodyEditor) }}
          $minHeight={minHeight}
          $maxHeight={$maxHeight}
          $editorHeight={$editorHeight}
          toolbar={
            isGenerating
              ? <S.Toolbar>
                  <S.Generating>
                    <Flex $gap={12} $align="center">
                      <Icon name="sparkles-sm" color="tintBg" size={12} />
                      <Caption size="XS" $hasGradient>
                        Generating {selectedMessageType === MessageType.EMAIL ? 'email' : 'message'}&hellip;
                      </Caption>
                    </Flex>
                  </S.Generating>
                </S.Toolbar>
              : <S.Toolbar>
                  <S.ToolbarActions>
                    <Flex $align='center' $gap={16} $width='fit-content'>
                      {leftActions}
                      {selectedMessageType === MessageType.EMAIL && useAttachments && (
                        <UploadAttachments
                          handleUpload={uploadAttachments}
                          multiple={true}
                        />
                      )}
                    </Flex>
                    <Flex $align='center' $gap={16} $width='fit-content'>
                      {rightActions}
                      <Flex $gap={12} $align="center" $justify="flex-end" $width='fit-content'>
                        {useVariables && (<EmailVariableButton editor={lastFocusedEditor} />)}
                        {trailingToolbarActions}
                      </Flex>
                    </Flex>
                  </S.ToolbarActions>
                </S.Toolbar>
          }
          editorFooterContent={editorFooterContent}
        />
      </S.EmailFields>
    </S.EmailComposer>
  )
}
