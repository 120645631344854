import { Icons } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'

export const getFileIcon = (mimetype: string): IconName => {
  switch (mimetype) {
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/gif':
    case 'image/webp':
      return Icons.image
    case 'application/pdf':
      return Icons.file
    default:
      return Icons.file
  }
}
