import styled from 'styled-components'

export const FieldError = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[14]};
  font-weight: 500;
  line-height: ${({ theme }) => theme.lineHeights['1.43']};
  color: ${({ theme }) => theme.colors.negativeBg};
  margin-top: ${({ theme }) => theme.spacing[8]};
  white-space: nowrap;
  /* Fix for optical alignment */
  padding-left: 1px;
`
