import styled from 'styled-components'

export const ConnectedAccount = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  /* box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02); */
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
`

export const Inner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  gap: 0.5rem;
`

export const LinkedInAvatar = styled.div`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  /* overflow: hidden; */
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: 999px;
    border: solid 2px;
    border-color: ${({ theme }) => theme.colors.positiveFg};
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    border-radius: 999px;
  }
`

export const AccountGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  row-gap: 0.75rem;
  column-gap: 1rem;
  align-items: center;
`
