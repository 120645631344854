import { Outlet, useLocation } from 'react-router-dom'
import { When } from 'src/components/blocks/when'
import * as PageWithSidebar from 'src/components/layouts/page-with-sidebar'
import * as S from './settings.styled'

export const SettingsLayout = (): JSX.Element => {
  const { pathname } = useLocation()
  const IS_EMAIL_SEQUENCE_PAGE = !pathname.includes('email-sequence')

  return (
    <PageWithSidebar.Wrapper>
      <PageWithSidebar.Content hasSidebar={false}>
        <When condition={IS_EMAIL_SEQUENCE_PAGE}>
          <S.ContentWrapper>
            <Outlet />
          </S.ContentWrapper>
        </When>
        <When condition={!IS_EMAIL_SEQUENCE_PAGE}>
          <Outlet />
        </When>
      </PageWithSidebar.Content>
    </PageWithSidebar.Wrapper>
  )
}
