import type { Variant, ColorTheme } from './button'
import type { Color, Theme } from 'src/styles/theme/types'

const generateRaisedStyle = (baseStyles: string, colorTheme: ColorTheme, theme: Theme): string => {
  switch (colorTheme) {
    case 'tint': {
      return `
        ${baseStyles}
        color: ${theme.colors.tintBg};
      `
    }
    case 'positive': {
      return `
        ${baseStyles}
        color: ${theme.colors.positiveBg};
      `
    }
    case 'negative': {
      return `
        ${baseStyles}
        color: ${theme.colors.negativeBg};
      `
    }
    case 'muted': {
      return `
        ${baseStyles}
        color: ${theme.colors.fgSecondary};
      `
    }
    default: {
      return `
        ${baseStyles}
        color: ${theme.colors.fgPrimary};
      `
    }
  }
}

const getRaisedStyles = (colorTheme: ColorTheme, theme: Theme): string => {
  const BASE_STYLES = `
    background-color: ${theme.colors.bgPrimary};
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
    @media (hover: hover) {
      &:hover {
        background-color: ${theme.colors.bgSecondary};
      }
    }
  `
  return generateRaisedStyle(BASE_STYLES, colorTheme, theme)
}

const getRaisedLightStyles = (colorTheme: ColorTheme, theme: Theme): string => {
  const BASE_STYLES = `
    background-color: ${theme.colors.bgPrimary};
    box-shadow: ${theme.boxShadows.sm};
    @media (hover: hover) {
      &:hover {
        background-color: ${theme.colors.bgSecondary};
      }
    }
  `

  return generateRaisedStyle(BASE_STYLES, colorTheme, theme)
}

const getFillStyles = (colorTheme: ColorTheme, theme: Theme): string => {
  const BASE_STYLES = `
    border: 1px solid;
    color: ${theme.colors.bgPrimary};
  `
  switch (colorTheme) {
    case 'tint': {
      return `
        ${BASE_STYLES}
        background-color: ${theme.colors.tintBg};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.tintFg};
          }
        }
      `
    }
    case 'positive': {
      return `
        ${BASE_STYLES}
        background-color: ${theme.colors.positiveBg};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.positiveFg};
          }
        }
      `
    }
    case 'negative': {
      return `
        ${BASE_STYLES}
        background-color: ${theme.colors.negativeBg};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.negativeFg};
          }
        }
      `
    }
    case 'muted': {
      return `
        ${BASE_STYLES}
        background-color: ${theme.colors.fgSecondary};
      `
    }
    default: {
      return `
        ${BASE_STYLES}
        background-color: ${theme.colors.fgPrimary};
      `
    }
  }
}

const getGhostStyles = (colorTheme: ColorTheme, theme: Theme): string => {
  const BASE_STYLES = `
    background-color: transparent;
  `
  switch (colorTheme) {
    case 'tint': {
      return `
        ${BASE_STYLES}
        color: ${theme.colors.tintBg};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.tintTranslucent5};
          }
        }
      `
    }
    case 'positive': {
      return `
        ${BASE_STYLES}
        color: ${theme.colors.positiveBg};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.positiveTranslucent5};
          }
        }
      `
    }
    case 'negative': {
      return `
        ${BASE_STYLES}
        color: ${theme.colors.negativeBg};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.negativeTranslucent5};
          }
        }
      `
    }
    case 'warning': {
      return `
        ${BASE_STYLES}
        color: ${theme.colors.warningBg};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.warningTranslucent10};
          }
        }
      `
    }
    case 'muted': {
      return `
        ${BASE_STYLES}
        color: ${theme.colors.fgSecondary};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.fgTranslucent10};
          }
        }
      `
    }
    default: {
      return `
        ${BASE_STYLES}
        color: ${theme.colors.fgPrimary};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.fgTranslucent10};
          }
        }
      `
    }
  }
}

const getFlatStyles = (colorTheme: ColorTheme, theme: Theme): string => {
  const BASE_STYLES = `
    // border: solid 1px;
    border: none;
  `

  switch (colorTheme) {
    case 'tint': {
      return `
        ${BASE_STYLES}
        background-color: ${theme.colors.tintTranslucent5};
        border-color: ${theme.colors.tintTranslucent5};
        color: ${theme.colors.tintFg};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.tintTranslucent25};
         }
        }
      `
    }
    case 'positive': {
      return `
        ${BASE_STYLES}
        background-color: ${theme.colors.positiveTranslucent5};
        border-color: ${theme.colors.positiveTranslucent5};
        color: ${theme.colors.positiveFg};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.positiveTranslucent25};
         }
        }
      `
    }
    case 'warning': {
      return `
        ${BASE_STYLES}
        background-color: ${theme.colors.warningTranslucent10};
        border-color: ${theme.colors.warningTranslucent10};
        color: ${theme.colors.warningFg};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.warningTranslucent25};
         }
        }
      `
    }
    case 'negative': {
      return `
        ${BASE_STYLES}
        background-color: ${theme.colors.negativeTranslucent5};
        border-color: ${theme.colors.negativeTranslucent5};
        color: ${theme.colors.negativeFg};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.negativeTranslucent25};
         }
        }
      `
    }
    case 'muted': {
      return `
        ${BASE_STYLES}
        background-color: ${theme.colors.bgTertiary};
        border-color: ${theme.colors.bgTertiary};
        color: ${theme.colors.fgSecondary};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.bgTranslucent10};
         }
        }
      `
    }
    default: {
      return `
        ${BASE_STYLES}
        background-color: ${theme.colors.bgTertiary};
        border-color: ${theme.colors.bgTertiary};
        color: ${theme.colors.fgPrimary};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.fgTranslucent10};
         }
        }
      `
    }
  }
}

const getOutlineStyles = (colorTheme: ColorTheme, theme: Theme): string => {
  const BASE_STYLES = `
    border: solid 1px;
    background-color: transparent;
  `
  switch (colorTheme) {
    case 'tint': {
      return `
        ${BASE_STYLES}
        border-color: ${theme.colors.tintTranslucent5};
        color: ${theme.colors.tintBg};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.tintTranslucent5};
          }
        }
      `
    }
    case 'positive': {
      return `
        ${BASE_STYLES}
        border-color: ${theme.colors.positiveTranslucent5};
        color: ${theme.colors.positiveBg};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.positiveTranslucent5};
          }
        }
      `
    }
    case 'negative': {
      return `
        ${BASE_STYLES}
        border-color: ${theme.colors.negativeTranslucent5};
        color: ${theme.colors.negativeBg};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.negativeTranslucent5};
          }
        }
      `
    }
    case 'warning': {
      return `
        ${BASE_STYLES}
        border-color: ${theme.colors.warningTranslucent25};
        color: ${theme.colors.warningBg};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.warningTranslucent10};
          }
        }
      `
    }
    case 'muted': {
      return `
        ${BASE_STYLES}
        border-color: ${theme.colors.borderTranslucent};
        color: ${theme.colors.fgSecondary};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.borderTranslucent};
          }
        }
      `
    }
    default: {
      return `
        ${BASE_STYLES}
        border-color: ${theme.colors.borderTranslucent};
        color: ${theme.colors.fgPrimary};
        background-color: ${theme.colors.bgPrimary};
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.fgTranslucent10};
          }
        }
      `
    }
  }
}

export const getVariantStyles = (
  variant: Variant,
  colorTheme: ColorTheme,
  theme: Theme
): string => {
  switch (variant) {
    case 'raised': {
      return getRaisedStyles(colorTheme, theme)
    }
    case 'raised-light': {
      return getRaisedLightStyles(colorTheme, theme)
    }
    case 'fill': {
      return getFillStyles(colorTheme, theme)
    }
    case 'ghost': {
      return getGhostStyles(colorTheme, theme)
    }
    case 'flat': {
      return getFlatStyles(colorTheme, theme)
    }
    case 'outline': {
      return getOutlineStyles(colorTheme, theme)
    }
  }
}

const DEFAULT_ICON_COLORS = {
  normal: 'fgPrimary' as Color,
  tint: 'tintBg' as Color,
  positive: 'positiveBg' as Color,
  negative: 'negativeBg' as Color,
  warning: 'warningBg' as Color,
  muted: 'fgSecondary' as Color
}

const ICON_COLOR_MAP = {
  raised: DEFAULT_ICON_COLORS,
  'raised-light': DEFAULT_ICON_COLORS,
  fill: {
    normal: 'bgPrimary' as Color,
    tint: 'bgPrimary' as Color,
    positive: 'bgPrimary' as Color,
    negative: 'bgPrimary' as Color,
    warning: 'warningBg' as Color,
    muted: 'bgPrimary' as Color
  },
  ghost: {
    ...DEFAULT_ICON_COLORS
  },
  flat: {
    ...DEFAULT_ICON_COLORS
  },
  outline: {
    ...DEFAULT_ICON_COLORS
  }
}

export const getIconColor = (variant: Variant, color: ColorTheme): Color => {
  return ICON_COLOR_MAP[variant]?.[color]
}
