import { useQuery, queryOptions } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { listJobSearchRefinements } from 'src/libs/api/backend/jobs'
import type { JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'

export const listJobSearchRefinementsQuery = (jobId: string) => queryOptions({
  queryKey: [queryKeys.jobSearchRefinements, jobId],
  queryFn: async () => await listJobSearchRefinements(jobId),
  staleTime: 60 * 1000 * 15, // 15 minutes
  enabled: !!jobId
})

export const useListJobSearchRefinementsQuery = (jobId?: string): UseQueryResult<JobSearchRefinement[]> => {
  const { jobId: jobIdParam } = useParams()
  return useQuery(listJobSearchRefinementsQuery(jobId ?? jobIdParam ?? ''))
}
