import { z } from 'zod'
import Api from '..'

export async function downloadAttachment (attachmentId: string): Promise<string> {
  const response = await Api.get(`/attachments/${attachmentId}/download`)
  const { downloadUrl } = z.object({
    downloadUrl: z.string()
  }).parse(response.data)
  return downloadUrl
}
