import { Spacer } from 'src/components/primitives/spacer'
import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { Caption } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import * as S from './sidebar.styled'
import { When } from '../when'
import { Button } from 'src/components/primitives/button'
import { Tooltip } from 'src/components/primitives/tooltip'
import { useQueryParams } from 'src/hooks/use-query-params'

export interface MenuGroup {
  heading?: string
  items: GroupItem[]
}

interface GroupItem {
  heading?: string
  links: MenuLink[]
}

interface MenuLink {
  title: string
  href: string
  icon?: IconName
  context?: React.ReactNode
}

interface SidebarProps {
  title?: string
  collapsable?: boolean
  isCollapsed?: boolean
  onCollapseChange?: (value: boolean) => void
  groups: MenuGroup[]
}

export const Sidebar = ({
  title,
  collapsable = true,
  groups,
  isCollapsed = false,
  onCollapseChange
}: SidebarProps): JSX.Element => {
  const { getParam } = useQueryParams()

  return (
    <S.Sidebar $isCollapsed={isCollapsed}>
      <S.Topbar $isCollapsed={isCollapsed}>
        {title && (
          <When condition={!isCollapsed}>
            <Caption size="MD">{title}</Caption>
          </When>
        )}
        {collapsable && (
          <Button
            ariaLabel="Collapse navigation bar"
            leadingIcon={isCollapsed ? 'chevrons-right' : 'chevrons-left'}
            $variant="ghost"
            $colorTheme="muted"
            $height={32}
            $width={32}
            onClick={() => {
              onCollapseChange?.(!isCollapsed)
            }}
          />
        )}
      </S.Topbar>
      {(title ?? collapsable) && <Spacer $size={isCollapsed ? 16 : 8} />}
      <S.Menu $isCollapsed={isCollapsed}>
        {groups?.map((group, index) => (
          <S.MenuGroup key={`${group.heading}-${index}`} $isCollapsed={isCollapsed}>
            {!isCollapsed && group.heading && (
              <S.HeadingContainer>
                <Caption as="h1" size="MD">
                  {group.heading}
                </Caption>
                <Spacer $size={24} />
              </S.HeadingContainer>
            )}
            {group.items.map((item, index) => (
              <S.GroupItem key={`${item.heading}-${index}`} $isCollapsed={isCollapsed}>
                {!isCollapsed && item.heading && (
                  <S.GroupItemHeading>
                    <Caption
                      as="h3"
                      size="2XS"
                      $transform="uppercase"
                      $color="fgTertiary"
                      $ellipsis
                    >
                      {item.heading}
                    </Caption>
                  </S.GroupItemHeading>
                )}
                <S.MenuLinks>
                  {item.links.map((link, index) => (
                    <Tooltip
                      key={`${link.href}-${index}`}
                      disabled={!isCollapsed}
                      position="right"
                      trigger={
                        <S.MenuLink
                          to={link.href}
                          $isCollapsed={isCollapsed}
                          $isActive={getParam('settings') === new URLSearchParams(link.href.split('?')[1]).get('settings')}
                        >
                          <Flex
                            $align="center"
                            $justify={isCollapsed ? 'center' : 'flex-start'}
                            $gap={8}
                          >
                            {link.icon && <Icon name={link.icon} size={14} color="fgPrimary" />}
                            {!isCollapsed && link.title}
                          </Flex>
                          {!isCollapsed && link.context}
                        </S.MenuLink>
                      }
                    >
                      {link.title}
                    </Tooltip>
                  ))}
                </S.MenuLinks>
              </S.GroupItem>
            ))}
          </S.MenuGroup>
        ))}
      </S.Menu>
    </S.Sidebar>
  )
}
