import styled, { keyframes } from 'styled-components'

const progressAnimation = keyframes`
  0% {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
`

export const ProgressBar = styled.div`
  width: 180px;
  height: 20px;
  padding: 1px 1px 2px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.fgTranslucent10};
`

export const Inner = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 20px;
  padding: 1px;
`

export const Bar = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
`

export const Progress = styled.div<{ $progress: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $progress }) => `${$progress}%`};
  height: 15px;
  border-radius: 20px;
  transition: all 0.25s ease-in-out;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.tintBg};
  background-image: linear-gradient(
    135deg,
    #5d8bf0 10%,
    #2563eb 10%,
    #2563eb 50%,
    #5d8bf0 50%,
    #5d8bf0 60%,
    #2563eb 60%,
    #2563eb 100%
  );
  background-size: 7.07px 7.07px;
  animation: ${progressAnimation} 3s linear infinite;
`
