import { updateEmailAccountSignature } from 'src/libs/api/backend/me'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSetSessionQueryCache } from '../use-session'
import { notifyErrorAtom } from 'src/stores/notifications'
import { useSetAtom } from 'jotai'
import { queryKeys } from 'src/libs/query-keys'

interface MutateArgs {
  emailAccountId: string
  signature: string
}

interface Res {
  updateEmailSignature: ({ emailAccountId, signature }: MutateArgs) => void
}

export const useUpdateEmailSignature = (): Res => {
  const setSessionQueryCache = useSetSessionQueryCache()
  const notifyError = useSetAtom(notifyErrorAtom)
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async ({ emailAccountId, signature }: MutateArgs) => {
      return await updateEmailAccountSignature({ emailAccountId, signature })
    },
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: [queryKeys.session] })

      setSessionQueryCache((old) => {
        const emailAccountAccessTokens = old.emailAccountAccessTokens.map((emailAccount) => {
          if (emailAccount.emailAccountId === data.emailAccountId) {
            return {
              ...emailAccount,
              signature: data.signature
            }
          }
          return emailAccount
        })
        return {
          ...old,
          emailAccountAccessTokens
        }
      })
    },
    // onSuccess: (data) => {
    //   setSessionQueryCache((old) => {
    //     const emailAccountAccessTokens = old.emailAccountAccessTokens.map((emailAccount) => {
    //       if (emailAccount.emailAccountId === data.emailAccountId) {
    //         return {
    //           ...emailAccount,
    //           signature: data.signature
    //         }
    //       }
    //       return emailAccount
    //     })
    //     return {
    //       ...old,
    //       emailAccountAccessTokens
    //     }
    //   })
    // },
    onError: (error) => {
      console.error(error)
      notifyError({
        message: 'Error updating email signature. Please try again.'
      })
    }
  })

  const updateEmailSignature = ({ emailAccountId, signature }: MutateArgs): void => {
    mutation.mutate({ emailAccountId, signature })
  }

  return { updateEmailSignature }
}
