import * as Dialog from 'src/components/primitives/dialog'
import * as S from './new-job.styled'
import { CreateNewJobForm } from 'src/components/blocks/create-new-job-form'
import { useQueryParams } from 'src/hooks/use-query-params'
import { useAtomValue, useSetAtom } from 'jotai'
import { controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useMemo } from 'react'

export const CreateNewJobDialog = (): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.CREATE_NEW_JOB), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const { resetParam } = useQueryParams()

  return (
    <Dialog.Root
      id={DialogId.CREATE_NEW_JOB}
      isOpen={isDialogOpen}
      modal
      onOpenChange={(value) => {
        if (!value) {
          resetParam('createNewJob')
        }
        controlDialog({ id: DialogId.CREATE_NEW_JOB, newState: value })
      }}
      $width="almostFull"
      $height="almostFull"
      $innerPadding={{ top: 0, right: 0, bottom: 0, left: 0 }}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="New Job Position"
          onClose={() => {
            resetParam('createNewJob')
            controlDialog({ id: DialogId.CREATE_NEW_JOB, newState: false })
          }}
        />
        <Dialog.Content>
          <S.NewJobWrapper>
            <S.ImageWrapper>
              <S.ImageWrapperInner>
                <img src="/onboarding/company-profile.jpg" />
              </S.ImageWrapperInner>
            </S.ImageWrapper>
            <S.FormWrapper>
              <CreateNewJobForm />
            </S.FormWrapper>
          </S.NewJobWrapper>
              {/*
              <CreateJobForm
                redirectType="jobs"
                onCreateJob={() => {
                  controlDialog(DialogId.CREATE_NEW_JOB, false)
                  openDialog(DialogId.CREATE_SEQUENCE, 'OPTIONS')
                }}
                onMergeLinkVisibility={(isVisible: boolean) => {
                  setIsModal(!isVisible)
                }}
              />
              */}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
