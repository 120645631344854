import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateUserNotificationPreference } from 'src/libs/api/backend/notifications'
import type { NotificationType, UserNotificationPreferences } from 'src/libs/api/backend/notifications'
import { queryKeys } from 'src/libs/query-keys'
import { isNil } from 'lodash'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom } from 'src/stores/notifications'

interface Args {
  notificationType: NotificationType
  isChecked: boolean
}

interface Res {
  toggleUserNotificationPreference: (args: Args) => void
}

export const useToggleUserNotificationPreference = (): Res => {
  const queryClient = useQueryClient()

  const notifyError = useSetAtom(notifyErrorAtom)

  const mutation = useMutation({
    mutationFn: async ({ notificationType, isChecked }: Args) => {
      return await updateUserNotificationPreference(notificationType, isChecked)
    },
    onError: (err) => {
      console.error(err)
      notifyError({
        message: `An error occurred when updating your notification preference: ${err.message}`,
        autoClose: false
      })
    },
    onSuccess: async (data) => {
      queryClient.setQueriesData<UserNotificationPreferences>({ queryKey: [queryKeys.notificationsPreferences] }, (oldPreferences) => {
        if (isNil(oldPreferences)) {
          return oldPreferences
        }
        const updated = {
          ...oldPreferences,
          ...data
        }
        return updated
      })
    }
  })

  const toggleUserNotificationPreference = (args: Args): void => {
    mutation.mutate(args)
  }

  return {
    toggleUserNotificationPreference
  }
}
