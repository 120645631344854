import styled from 'styled-components'
import type { AvatarsListProps } from './avatars-list'

export const Avatars = styled.div`
  display: flex;
  align-items: center;
`

export const Avatar = styled.div<Pick<AvatarsListProps, '$overlap'>>`
  &:not(:first-child) {
    margin-left: -${({ $overlap }) => $overlap}px;
    z-index: 2;
  }
`

export const AvatarMore = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 999px;
  background-color: ${({ theme }) => theme.colors.borderOpaque};
  transform: translateX(-0.25rem);
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px;
  border-color: ${({ theme }) => theme.colors.bgPrimary};
  z-index: 2;
`
