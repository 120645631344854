import * as S from './text.styled'
import type { Color, FontSize, FontWeight, LineHeight, LetterSpacing } from 'src/styles/theme/types'

type Variant = 'HeadingLg' | 'SubHeading' | 'Label' | 'ParagraphPrimary' | 'ParagraphSecondary'

export interface TextStyleProps {
  $weight?: FontWeight
  $size?: FontSize
  $letterSpacing?: LetterSpacing
  $lineHeight?: LineHeight
  $transform?: 'none' | 'uppercase' | 'capitalize'
  $color?: Color
  $whiteSpace?: 'normal' | 'nowrap'
}

interface TextProps extends TextStyleProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'label' | 'caption'
  variant?: Variant
  ariaLabel?: string
  children: React.ReactNode | string
}

/**
 * A `Text` component can either be fully customized
 * or take in various pre-defined variants for Headings or similar
 * that do the heavy lifting.
 * @param {Variant} variant – Pre-defined styling variants
 */
export const Text = ({
  as = 'p',
  $size = 16,
  $weight = 400,
  $letterSpacing = 0,
  $lineHeight = 1.2,
  $transform = 'none',
  $color = 'fgPrimary',
  $whiteSpace = 'normal',
  variant,
  children,
  ariaLabel
}: TextProps): JSX.Element => {
  if (variant === 'HeadingLg') {
    return (
      <S.Text
        as="h1"
        $size={20}
        $weight={500}
        $letterSpacing={0}
        $whiteSpace={$whiteSpace}
        aria-label={ariaLabel}
      >
        {children}
      </S.Text>
    )
  }

  if (variant === 'ParagraphSecondary') {
    return (
      <S.Text
        as="p"
        $size={14}
        $weight={400}
        $letterSpacing={0}
        $lineHeight={1.43}
        $color="fgSecondary"
        $whiteSpace={$whiteSpace}
        aria-label={ariaLabel}
      >
        {children}
      </S.Text>
    )
  }

  if (variant === 'Label') {
    return (
      <S.Text
        as={as || 'label'}
        $size={16}
        $weight={500}
        $letterSpacing={0}
        $color={$color}
        $whiteSpace={$whiteSpace}
        aria-label={ariaLabel}
      >
        {children}
      </S.Text>
    )
  }

  return (
    <S.Text
      as={as}
      $weight={$weight}
      $size={$size}
      $letterSpacing={$letterSpacing}
      $lineHeight={$lineHeight}
      $transform={$transform}
      $color={$color}
      aria-label={ariaLabel}
      $whiteSpace={$whiteSpace}
    >
      {children}
    </S.Text>
  )
}
