import styled from 'styled-components'

export const FeedbackBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[40]};
  padding: ${({ theme }) => theme.spacing[40]};
  overflow-y: auto;
  scrollbar-width: thin;
  overflow-x: hidden;
  border-radius: ${({ theme }) => theme.spacing[8]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  width: 100%;
`

export const Icon = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing[2]};
`

export const WarningBlock = styled.div`
  margin-top: ${({ theme }) => theme.spacing[16]};
  margin-bottom: ${({ theme }) => theme.spacing[16]};
  display: flex;
  gap: ${({ theme }) => theme.spacing[12]};
  padding: ${({ theme }) => theme.spacing[12]};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.warningTranslucent5};
  border-radius: ${({ theme }) => theme.spacing[4]};
  ul {
    margin-top: ${({ theme }) => theme.spacing[4]};
    li {
      margin-left: ${({ theme }) => theme.spacing[24]};
      font-size: ${({ theme }) => theme.fontSizes[14]};
    }
  }
`
