import { Avatar } from 'src/components/primitives/avatar'
import { Flex } from 'src/components/primitives/flex'
import { Caption } from 'src/components/primitives/typography'
import type { Department } from 'src/libs/api/backend/departments'
import type { Org } from 'src/libs/api/backend/orgs'
import { DepartmentLogo } from '../department-logo'

interface GroupLabelProps {
  org: Org
  orgLogoUrl?: string | null
  department?: Department | null
}

export const GroupLabel = ({
  org,
  orgLogoUrl,
  department
}: GroupLabelProps): JSX.Element => {
  return (
    <Flex $gap={8} $align="center">
      {department
        ? <DepartmentLogo $size={16} departmentId={department.id} />
        : <Avatar
            $type="logo"
            $border={false}
            $size={16}
            photoUrl={orgLogoUrl}
            initials={org.name}
          />
      }
      <Caption size="SM" $color="fgPrimary" $lineHeight={1}>
        {department ? department.name : org.name}
      </Caption>
    </Flex>
  )
}
