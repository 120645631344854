import styled from 'styled-components'
import * as RadixSlider from '@radix-ui/react-slider'
import type { BarVariant } from './salary-range-picker'

export const SalaryRangePicker = styled.div`
  width: 100%;
  display: block;
  padding-bottom: 14px;
`

export const Graph = styled.div`
  display: flex;
  gap: 0.25rem;
  height: 64px;
  align-items: flex-end;
  margin-top: 0.5rem;
`

export const Bar = styled.div<{ $variant: BarVariant }>`
  width: 100%;
  height: 64px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: ${({ theme, $variant }) =>
    theme.colors[$variant === 'highlighted' ? 'tintBg' : 'tintTranslucent25']};
`

export const SliderRoot = styled(RadixSlider.Root)`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  height: 20px;
`

export const SliderTrack = styled(RadixSlider.Track)`
  background-color: ${({ theme }) => theme.colors.borderTranslucent};
  position: relative;
  flex-grow: 1;
  border-radius: 4px;
  height: 4px;
`

export const SliderRange = styled(RadixSlider.Range)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.tintBg};
  border-radius: 9999px;
  height: 100%;
`

export const SliderThumb = styled(RadixSlider.Thumb)<{
  $value: number
  $valueAlign: 'left' | 'right'
}>`
  position: relative;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  border: solid 2px;
  border-color: ${({ theme }) => theme.colors.tintBg};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  cursor: grab;
  &:hover {
    background-color: ${({ theme }) => theme.colors.tintBg};
  }
  &:focus {
    outline: none;
  }
  &:before {
    content: '';
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    top: -4px;
    left: -4px;
    border: solid 2px;
    border-color: ${({ theme }) => theme.colors.bgPrimary};
    border-radius: 9999px;
    user-select: none;
    pointer-events: none;
    z-index: 2;
  }
  &:after {
    content: '${({ $value }) => `${$value}k`}';
    position: absolute;
    z-index: 3;
    top: 12px;
    left: ${({ $valueAlign }) => ($valueAlign === 'left' ? '0px' : 'unset')};
    right: ${({ $valueAlign }) => ($valueAlign === 'right' ? '0px' : 'unset')};
    font-size: ${({ theme }) => theme.fontSizes[14]};
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
`
