import { Avatar } from 'src/components/primitives/avatar'
import * as S from './join-team-card.styled'
import { pluralize } from 'src/libs/pluralize'
import { Button } from 'src/components/primitives/button'
import { Box } from 'src/components/primitives/box'
import type { ExistingOrg } from 'src/libs/api/backend/orgs'

export interface JoinTeamCardProps {
  org: ExistingOrg
  onJoin: (orgId: string) => void
  onAskToJoin: (orgId: string) => void
}

export const JoinTeamCard = ({
  org,
  onJoin,
  onAskToJoin
}: JoinTeamCardProps): JSX.Element => {
  return (
    <S.JoinTeamCard>
      <S.Inner>
        <Avatar $shape="soft" $size={40} initials={org.name} photoUrl={org.logoUrl} />
        <Box $flex='1'>
          <h3>{org.name}</h3>
          <p>{org.domain}</p>
        </Box>
        {org.allowDomainJoin
          ? <Button
              $variant="fill"
              $colorTheme="tint"
              $height={32}
              onClick={() => { onJoin(org.id) }}
            >
              Join team
            </Button>
          : <Button
              $variant="fill"
              $colorTheme="tint"
              $height={32}
              onClick={() => { onAskToJoin(org.id) }}
            >
              Ask to join team
            </Button>
        }
      </S.Inner>
      <S.Bottom>
        <p>Created by {org.firstOrgUser.name}.</p>
        <p>{pluralize(org.orgUserCount, 'team member')}.</p>
      </S.Bottom>
    </S.JoinTeamCard>
  )
}
