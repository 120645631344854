import { submitFeedbackApi } from 'src/libs/api/backend/feedback'
import type { SubmitFeedback } from 'src/libs/api/backend/feedback'
import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'
import { Icons } from 'src/components/primitives/icon'

interface Args {
  feedback: SubmitFeedback
  onSuccess?: () => void
}

interface Res {
  submitFeedback: (args: Args) => void
}

export const useSubmitFeedback = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)

  const mutation = useMutation({
    mutationFn: async ({ feedback }: Args) => {
      return await submitFeedbackApi(feedback)
    },
    onError: (err) => {
      notifyError({
        message: `An error occurred when submitting feedback: ${err.message}`
      })
    },
    onSuccess: async (_, variables) => {
      if (variables.onSuccess) {
        variables.onSuccess()
      } else {
        notifySuccess({
          icon: Icons.mails,
          message: 'Successfully submitted feedback'
        })
      }
    }
  })

  const submitFeedback = (args: Args): void => {
    mutation.mutate(args)
  }

  return { submitFeedback }
}
