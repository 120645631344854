import { EmptyState } from 'src/components/blocks/empty-state'
import { Button, Flex } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import { candidateSequenceStepMessageReviewsQuery } from 'src/hooks/queries/use-candidate-sequence-step-message-review'
import RouteBuilder from 'src/libs/route-builder'
import * as S from './ready-to-send-page.styled'
import { ReadyToSend } from 'src/components/blocks/ready-to-send'
import { orgUsersQuery } from 'src/hooks/queries/use-org-users'
import { isNil, keyBy } from 'lodash'
import { Suspense, useCallback, useEffect, useMemo, useRef } from 'react'
import { useToggleCandidateFavoriteStatus } from 'src/hooks/mutations/use-toggle-candidate-favorite-status'
import { useSendCandidateSequenceStepMessage } from 'src/hooks/mutations/use-send-candidate-sequence-step-message'
import { When } from 'src/components/blocks/when'
import { Banner } from 'src/components/blocks/banner'
import { useUpsertJobSequence } from 'src/hooks/mutations/use-upsert-job-sequence'
import { jobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { getEmailAccountAuthUrl } from 'src/libs/auth-urls'
import type { EmailAccount } from 'src/libs/api/backend/users'
import { SEO } from 'src/components/primitives/seo'
import { invalidateEmailAccounts } from 'src/hooks/invalidate-email-accounts'
import { Paragraph } from 'src/components/primitives/typography'
import { useRefreshManualEmailCopy } from 'src/hooks/mutations/use-refresh-manual-email-copy'
import { useVirtualizer } from '@tanstack/react-virtual'
import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING } from 'src/styles/constants'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { useSetAtom } from 'jotai'
import { openAlertAtom, DialogId, openDialogAtom } from 'src/stores/dialogs'
import { Await, useLoaderData } from 'react-router-dom'
import type { ReadyToSendLoaderData } from 'src/libs/loaders/in-outreach'
import { useSuspenseInfiniteQuery, useSuspenseQuery } from '@tanstack/react-query'
import { sessionQuery } from 'src/hooks/queries/use-session'

interface ReadyToSendBannerParams {
  jobId: string
  emailSequenceActive?: boolean
  dirtyCandidateSequenceStepMessageReviewIds: string[]
}

const ReadyToSendBanner = ({ jobId, emailSequenceActive, dirtyCandidateSequenceStepMessageReviewIds }: ReadyToSendBannerParams): JSX.Element => {
  const { upsertJobSequence } = useUpsertJobSequence()
  const { refreshManualEmailCopy } = useRefreshManualEmailCopy()
  const openAlert = useSetAtom(openAlertAtom)

  return (
    <S.ReadyToSendBanner $maxWidth={CANDIDATES_PAGES_MAX_WIDTH}>
      <Flex $align='center' $justify='space-between'>
        <S.ReadyToSendHeader as="h1" size="MD" $whiteSpace="nowrap">
          Outreach &middot; Ready to send
        </S.ReadyToSendHeader>
        <Flex $gap={8} $justify="flex-end" $width="auto" $align="center">
          <Button
            leadingIcon={Icons.settings2}
            href={RouteBuilder.build('SETTINGS_JOB_EMAIL_SEQUENCE', { jobId })}
            $variant='ghost'
            $colorTheme='muted'
            $fontSize={12}
            $height={24}
          >
            Edit Outreach
          </Button>
        </Flex>
      </Flex>
      <When condition={!emailSequenceActive && dirtyCandidateSequenceStepMessageReviewIds.length === 0}>
        <Flex $direction='column'>
          <Banner
            icon="pause-circle"
            $variant="muted"
            actions={
              <Button
                $variant="fill"
                $colorTheme="tint"
                $height={24}
                $fontSize={12}
                leadingIcon="play-circle"
                onClick={() => {
                  const newState = !emailSequenceActive
                  upsertJobSequence({
                    jobId,
                    active: newState,
                    toastMessage: newState ? 'Enabled sending outreach emails' : 'Paused sending outreach emails'
                  })
                }}
              >
                Enable outreach
              </Button>
            }
          >
            Outreach is paused. Enable it to start sending emails.
          </Banner>
        </Flex>
      </When>
      <When condition={dirtyCandidateSequenceStepMessageReviewIds.length >= 1}>
        <Banner $variant='warning' >
          <Flex $direction='row' $align='center' $justify='space-between'>
            <Paragraph> The sequence's template has changed since these emails were generated. Rebuilding will use the current sequence template. </Paragraph> <Button
              leadingIcon={Icons.refreshCw}
              $height={24}
              $fontSize={12}
              onClick={() => {
                openAlert({
                  message: 'Are you sure you want to refresh?',
                  description: 'Any unsent edits in the queue that will be discarded',
                  onConfirm: () => {
                    refreshManualEmailCopy({ jobId, dirtyCandidateSequenceStepMessageReviewIds })
                  }
                })
              }}
            >
              Rebuild
            </Button>
          </Flex>
        </Banner>
      </When>
    </S.ReadyToSendBanner>
  )
}

interface ReadyToSendPageProps {
  jobId: string
}

const ReadyToSendWrapper = ({ jobId }: ReadyToSendPageProps): JSX.Element => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  } = useSuspenseInfiniteQuery(candidateSequenceStepMessageReviewsQuery(jobId))

  const { data: users, refetch: refetchOrgUsers } = useSuspenseQuery(orgUsersQuery())
  const { data: emailSequence } = useSuspenseQuery(jobSequenceQuery(jobId))
  const { data: { user } } = useSuspenseQuery(sessionQuery())
  const { toggleFavoriteStatus } = useToggleCandidateFavoriteStatus()
  const { sendCandidateSequenceStepMessage } = useSendCandidateSequenceStepMessage()
  const openDialog = useSetAtom(openDialogAtom)
  const parentRef = useRef<HTMLDivElement>(null)
  const candidateSequenceStepMessageReviews = useMemo(() => {
    return data?.pages?.flatMap(page => page) ?? []
  }, [data?.pages])

  const virtualizer = useVirtualizer({
    count: candidateSequenceStepMessageReviews.length ?? 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 100
  })

  const dirtyCandidateSequenceStepMessageReviewIds = useMemo(() => {
    return candidateSequenceStepMessageReviews.filter((cssmr) => cssmr.dirty).map((cssmr) => cssmr.id)
  }, [candidateSequenceStepMessageReviews])

  const handleToggleFavoriteStatus = useCallback((candidateJobId: string, newStatus: boolean): void => {
    toggleFavoriteStatus({
      candidateJobId,
      newFavoriteStatus: newStatus
    })
  }, [toggleFavoriteStatus])

  const handleSendEmail = useCallback((candidateSequenceStepMessageReviewId: string, subject: string, body: string): void => {
    sendCandidateSequenceStepMessage({
      jobId,
      candidateSequenceStepMessageReviewId,
      subject,
      body
    })
  }, [jobId, sendCandidateSequenceStepMessage])

  const reconnect = useCallback((selectedEmailAccount: EmailAccount | undefined): void => {
    if (!isNil(selectedEmailAccount)) {
      const redirectUrl = `${window.location.origin}/login/redirect/close`
      const authUrl = getEmailAccountAuthUrl(selectedEmailAccount, redirectUrl)
      const loginWindow = window.open(authUrl, '_blank', 'popup=1,height=600,width=600')
      const timer = setInterval(() => {
        if (loginWindow?.closed) {
          void invalidateEmailAccounts()
          void refetchOrgUsers()
          clearInterval(timer)
        }
      }, 500)
    }
  }, [refetchOrgUsers])

  const usersByUserId = useMemo(() => {
    if (isNil(users)) {
      return {}
    }
    return keyBy(users, 'id')
  }, [users])

  const openCandidateDialog = useCallback((candidateJobId: string): void => {
    openDialog({ id: DialogId.CANDIDATE_DETAILS, payload: candidateJobId })
  }, [openDialog])

  const fetchMoreOnBottomReached = useCallback(() => {
    if (parentRef?.current) {
      const { scrollHeight, scrollTop, clientHeight } = parentRef.current
      if (
        scrollHeight - scrollTop - clientHeight < 1000 &&
        !isFetching &&
        !isFetchingNextPage &&
        hasNextPage
      ) {
        void fetchNextPage()
      }
    }
  }, [fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, parentRef])

  useEffect(() => {
    const ref = parentRef?.current
    if (!ref) {
      return
    }
    // attachment on scroll
    ref.addEventListener('scroll', fetchMoreOnBottomReached, {
      passive: true
    })
    return () => {
      ref.removeEventListener('scroll', fetchMoreOnBottomReached)
    }
  }, [fetchMoreOnBottomReached, parentRef])

  if (candidateSequenceStepMessageReviews.length === 0) {
    return (
      <S.ReadyToSendPageInner>
        <SEO title='Ready To Send' />
        <S.ReadyToSendWrapper ref={parentRef}>
          <ReadyToSendBanner
            jobId={jobId}
            emailSequenceActive={emailSequence?.active}
            dirtyCandidateSequenceStepMessageReviewIds={dirtyCandidateSequenceStepMessageReviewIds}
          />
          <div
            style={{
              width: '100%',
              maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
              height: '100%'
            }}
          >
            <EmptyState
              heading="No one at this step"
              description="Looks like you don’t have any prospects to reach out yet, start sourcing candidates."
              svg="profileCard"
              $padding={{
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
              }}
              actions={[
                {
                  href: `/jobs/${jobId}/candidates/sourcing`,
                  children: 'Go to sourcing'
                }
              ]}
              />
          </div>
        </S.ReadyToSendWrapper>
      </S.ReadyToSendPageInner>
    )
  }

  return (
    <S.ReadyToSendPageInner>
      <SEO title='Ready To Send' />
      <S.ReadyToSendWrapper ref={parentRef}>
        <ReadyToSendBanner
          jobId={jobId}
          emailSequenceActive={emailSequence?.active}
          dirtyCandidateSequenceStepMessageReviewIds={dirtyCandidateSequenceStepMessageReviewIds}
        />
        <S.ReadyToSendListInner $minHeight={virtualizer.getTotalSize()}>
          {virtualizer.getVirtualItems().map((virtualRow) => {
            const candidateSequenceStepMessageReview = candidateSequenceStepMessageReviews[virtualRow.index]
            if (!candidateSequenceStepMessageReview) {
              return null
            }
            return (
              <div
                style={{
                  width: '100%',
                  position: 'absolute',
                  top: virtualRow.start ?? 0,
                  maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
                  paddingLeft: 2,
                  paddingRight: 4
                }}
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={virtualizer.measureElement}
              >
                <ReadyToSend
                  key={candidateSequenceStepMessageReview.id}
                  usersByUserId={usersByUserId}
                  handleToggleFavoriteStatus={handleToggleFavoriteStatus}
                  handleSendEmail={handleSendEmail}
                  candidateSequenceStepMessageReview={candidateSequenceStepMessageReview}
                  sendDisabled={!emailSequence?.active}
                  currentUserId={user?.id}
                  reconnect={reconnect}
                  openCandidateDialog={openCandidateDialog}
                />
              </div>
            )
          })}
        </S.ReadyToSendListInner>
      </S.ReadyToSendWrapper>
    </S.ReadyToSendPageInner>
  )
}

const ReadyToSendPage = (): JSX.Element => {
  const { jobId, orgUsers, emailSequence, candidateJobs, session } = useLoaderData() as ReadyToSendLoaderData
  return (
    <Suspense
      fallback={
        <S.ReadyToSendPageInner>
          <SEO title='Ready To Send' />
          <div
            style={{
              width: '100%',
              maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
              padding: `${CONTENT_PADDING}`
            }}
          >
            <LoadingSkeleton $variant="CandidateDetailsCard" />
          </div>
        </S.ReadyToSendPageInner>
      }
    >
      <Await resolve={Promise.all([orgUsers, emailSequence, candidateJobs, session])}>
        <ReadyToSendWrapper jobId={jobId} />
      </Await>
    </Suspense>
  )
}

export default ReadyToSendPage
