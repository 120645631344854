import { useCallback, useEffect, useMemo, useState } from 'react'
import { AvatarsList } from 'src/components/blocks/avatars-list'
import { IfElse } from 'src/components/blocks/if-else'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { SearchBox } from 'src/components/blocks/searchbox'
import { Button, Flex } from 'src/components/primitives'
import * as Dialog from 'src/components/primitives/dialog'
import { Caption } from 'src/components/primitives/typography'
import { inboxRejectionReasons, CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import type {
  CandidateJobExpanded,
  CandidateJobRejectionReason
} from 'src/libs/api/backend/candidate_jobs'
import * as S from './move-to-another-job-dialog.styled'
import { useJobByDepartmentsQuery } from 'src/hooks/queries/use-jobs-by-department'
import { useMoveCandidatesToAnotherJob } from 'src/hooks/mutations/use-move-candidates-to-another-job'
import { useParams } from 'react-router-dom'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Icons } from 'src/components/primitives/icon'
import { map } from 'lodash'
import { When } from 'src/components/blocks/when'
import { CandidateProfile } from 'src/components/blocks/candidate-details'
import { JobTile } from '../../blocks/job-tile/job-tile'
import { CandidateStageSelectionOptions } from 'src/components/blocks/candidate-stage-selection-options'
import { closeDialogAtom, controlDialogAtom, DialogId, isDialogOpenAtom, openDialogAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'

interface MoveToAnotherJobDialogProps {
  candidateJobs: CandidateJobExpanded[]
  requiredReason?: boolean
}

export const MoveToAnotherJobDialog = ({
  candidateJobs,
  requiredReason = false
}: MoveToAnotherJobDialogProps): JSX.Element => {
  const { jobId } = useParams()
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.MOVE_TO_ANOTHER_JOB), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const openDialog = useSetAtom(openDialogAtom)
  const closeDialog = useSetAtom(closeDialogAtom)
  const { isLoading, data: jobs } = useJobByDepartmentsQuery()
  const { setMoveCandidatesToAnotherJob } = useMoveCandidatesToAnotherJob()

  const [selectedJobId, setSelectedJobId] = useState<string | null>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [rejectionReason, setRejectionReason] = useState<CandidateJobRejectionReason | undefined>(
    undefined
  )
  const [destinationStage, setDestinationStage] = useState<CandidateJobStage>(CandidateJobStage.SOURCED)

  const flattenJobs = useMemo(() => {
    if (!jobs) {
      return []
    }
    const flattenedMap = Object.values(jobs).reduce((acc, curr) => {
      return [...acc, ...curr]
    }, [])

    return flattenedMap.filter((job) => job.id !== jobId) // Remove current job from the list
  }, [jobId, jobs])

  const filteredJobs = useMemo(() => {
    return flattenJobs.filter((job) => {
      return (
        job.title.toLowerCase().includes(searchValue) ||
        job.department?.name.toLowerCase().includes(searchValue)
      )
    })
  }, [flattenJobs, searchValue])

  useEffect(() => {
    if (
      filteredJobs.length &&
      (!selectedJobId || !filteredJobs.find((job) => job.id === selectedJobId))
    ) {
      setSelectedJobId(filteredJobs[0].id)
    }
  }, [filteredJobs, selectedJobId])

  const handleMoveCandidates = useCallback(() => {
    if (selectedJobId) {
      setMoveCandidatesToAnotherJob({
        candidateJobIds: candidateJobs.map((job) => job.id),
        destinationJobId: selectedJobId,
        rejectionReason,
        destinationStage
      })
      closeDialog(DialogId.MOVE_TO_ANOTHER_JOB)
    }
  }, [selectedJobId, setMoveCandidatesToAnotherJob, candidateJobs, rejectionReason, destinationStage, closeDialog])

  return (
    <Dialog.Root
      id={DialogId.MOVE_TO_ANOTHER_JOB}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.MOVE_TO_ANOTHER_JOB, newState: value })
      }}
      $maxWidth="640px"
      $height="640px"
      $innerPadding={{
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Move to Another Job"
          onClose={() => {
            closeDialog(DialogId.MOVE_TO_ANOTHER_JOB)
          }}
        />
        <Dialog.Content>
          <Flex $direction="column" $height="full" $width="100%">
            <S.Content>
              <Flex $gap={8} $align="center">
                {candidateJobs.length === 1
                  ? <CandidateProfile candidate={candidateJobs[0].candidate}/>
                  : <>
                      <AvatarsList
                        avatars={candidateJobs.map(candidateJob => ({
                          id: candidateJob.candidate.id,
                          name: candidateJob.candidate.name,
                          profilePhotoUrl: candidateJob.candidate.profilePhotoUrl
                        }))}
                        avatarDisplay={{ showPlus: false, count: candidateJobs.length }}
                      />
                      <Caption size="XS" $color="fgSecondary">
                        {candidateJobs.length} candidate{candidateJobs.length > 1 ? 's' : ''} selected
                      </Caption>
                    </>
                }
              </Flex>
              <Flex $direction="column" $gap={16}>
                <Caption size="SM" $color="fgSecondary">
                  Which stage do you want to move this {candidateJobs?.length >= 2 ? 'candidates' : 'candidate'} to?
                </Caption>
                <CandidateStageSelectionOptions
                  onStageChange={(newStage) => { setDestinationStage(newStage) }}
                />
              </Flex>
              <When condition={flattenJobs.length === 0}>
                <Flex $gap={8} $direction="column" $align='center' $width='100%'>
                  <Caption size="SM" $color="fgSecondary">
                    No jobs available to move candidates to
                  </Caption>
                  <Button
                    $variant="outline"
                    $colorTheme="tint"
                    onClick={async () => {
                      openDialog({ id: DialogId.CREATE_JOB_POSITION })
                      closeDialog(DialogId.MOVE_TO_ANOTHER_JOB)
                    }}
                    leadingIcon={Icons.plusCircle}
                  >
                    Create new job
                  </Button>
                </Flex>
              </When>
              <When condition={requiredReason && flattenJobs.length > 0}>
                <Flex $gap={8} $direction="column">
                  <Caption size="SM" $color="fgSecondary">
                    Why are you moving these candidates?
                  </Caption>
                  <Dropdown
                    trigger={
                      <Button
                        $width="auto"
                        $variant="outline"
                        $colorTheme="negative"
                        $height={24}
                        $fontSize={14}
                        leadingIcon={Icons.xOctagon}
                        trailingIcon={Icons.chevronDown}
                      >
                        {rejectionReason
                          ? inboxRejectionReasons[rejectionReason]
                          : `Move candidate${Object.keys(candidateJobs).length >= 2 ? 's' : ''}`}
                      </Button>
                    }
                    items={map(inboxRejectionReasons, (reason, key) => ({
                      id: key,
                      title: reason,
                      onSelect: () => {
                        setRejectionReason(key as CandidateJobRejectionReason)
                      }
                    }))}
                    selectedValue={rejectionReason}
                  />
                </Flex>
              </When>
              <When condition={flattenJobs.length > 0}>
                <Flex $gap={16} $direction="column">
                  <Caption size="SM" $color="fgSecondary">
                    Which job position do you want to move {candidateJobs.length > 1 ? 'these candidates' : 'this candidate'} to?
                  </Caption>
                  {flattenJobs.length >= 10 &&
                    <SearchBox
                      placeholder="Search for job by title"
                      onClose={() => {
                        setSearchValue('')
                      }}
                      onValueChange={(value) => {
                        setSearchValue(value.toLowerCase())
                      }}
                      $gap={8}
                      searchStyles={{
                        $width: '100%',
                        $border: 'solid 1px',
                        $borderColor: 'borderTranslucent',
                        $borderRadius: 4,
                        $padding: '0.625rem',
                        $height: 40,
                        $inputHeight: 20,
                        $fontSize: 14
                      }}
                    />
                  }
                  <IfElse
                    condition={isLoading}
                    ifNode={<LoadingSkeleton $variant="JobsList" />}
                    elseNode={
                      <Flex $gap={6} $direction="column">
                        {filteredJobs.map((job) => {
                          const isActive = job.id === selectedJobId
                          return (
                            <JobTile
                              key={job.id}
                              id={job.id}
                              isActive={isActive}
                              title={job.title}
                              job={job}
                              onClick={(id) => {
                                setSelectedJobId(id)
                              }}
                            />
                          )
                        })}
                      </Flex>
                    }
                  />
                </Flex>
              </When>
            </S.Content>
            <S.Actions>
              <Button
                $variant="outline"
                $colorTheme="muted"
                onClick={() => {
                  closeDialog(DialogId.MOVE_TO_ANOTHER_JOB)
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                $variant="fill"
                $colorTheme="tint"
                $height={40}
                onClick={handleMoveCandidates}
                disabled={!selectedJobId || (requiredReason && !rejectionReason)}
              >
                {candidateJobs.length === 1 ? 'Move candidate' : `Move ${candidateJobs.length} candidates`}
              </Button>
            </S.Actions>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
