import { CompanyProfileForm } from 'src/components/blocks/company-profile-form'
import type { CompanyProfileFormData } from 'src/components/blocks/company-profile-form'
import { useSetSessionQueryCache } from 'src/hooks/use-session'
import type { Session } from 'src/libs/api/backend/session'
import { useUpdateOrg } from 'src/hooks/mutations/use-update-org'
import { MAX_PROFILE_FILE_SIZE, PROFILE_MAX_WIDTH, PNG_MIME_TYPE, compressAndGenerateUrl } from 'src/utils/compress-image'
import { useCallback } from 'react'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom } from 'src/stores/notifications'

export const OrgDetailsSettings = (): JSX.Element => {
  const notifyError = useSetAtom(notifyErrorAtom)
  const { updateOrg } = useUpdateOrg()
  const setSessionQueryCache = useSetSessionQueryCache()

  const handleSubmit = useCallback(async (data: CompanyProfileFormData): Promise<void> => {
    const { companyLogo, companyName, companyUrl } = data
    try {
      let newLogo // undefined means unchanged
      if (companyLogo instanceof File) {
        if (companyLogo.size > MAX_PROFILE_FILE_SIZE) {
          throw new Error('File is too large, please choose an image smaller than 1MB')
        }

        const uploadedTempProfilePhoto = await compressAndGenerateUrl({ file: companyLogo, fileType: PNG_MIME_TYPE, maxWidth: PROFILE_MAX_WIDTH })
        if (uploadedTempProfilePhoto?.key) {
          newLogo = {
            sourceKey: uploadedTempProfilePhoto.key
          }
        }
      } else if (companyLogo === null) {
        newLogo = null // Wipe current logo
      }
      updateOrg({
        orgUpdate: {
          name: companyName,
          domain: companyUrl,
          newLogo
        }
      })

      setSessionQueryCache((prev: Session) => {
        if (companyName !== '' && prev?.org) {
          return { ...prev, org: { ...prev.org } }
        } else {
          return prev
        }
      })
    } catch (e) {
      console.error('[org-details-settings] Error updating org', e)
      notifyError({
        message: (e as Error)?.message
      })
    }
  }, [notifyError, setSessionQueryCache, updateOrg])

  return (
    <CompanyProfileForm
      onSubmit={(data) => {
        void handleSubmit(data)
      }}
      submitButtonText="Save changes"
    />
  )
}
