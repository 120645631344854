import { z } from 'zod'
import Api from '..'

const externalJobListingParser = z.object({
  id: z.string(),
  url: z.string(),
  location: z.string(),
  content: z.string(),
  title: z.string(),
  source: z.string(),
  name: z.string().nullish(),
  domain: z.string().nullish()
})

export type JobBoardListing = z.infer<typeof externalJobListingParser>

export interface FetchExternalJobListingsInput {
  url?: string | null
  domain?: string | null
}

export const fetchExternalJobListings = async ({ url, domain }: FetchExternalJobListingsInput): Promise<JobBoardListing[]> => {
  const { data } = await Api.get('/external_job_listings', {
    url,
    domain
  })

  return z.array(externalJobListingParser).parse(data)
}
