import { CANDIDATES_PAGES_MAX_WIDTH } from 'src/styles/constants'
import styled from 'styled-components'

export const ApplicantsATSContainer = styled.div`
  width: 100%;
  max-width: ${CANDIDATES_PAGES_MAX_WIDTH};
  padding: ${({ theme }) => `${theme.spacing[80]} ${theme.spacing[40]}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  border-radius: ${({ theme }) => theme.spacing[6]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  flex: 1;
`

export const ATSInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[40]};
  max-width: 40rem;
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing[2]};
`

export const FilterInput = styled.input`
  height: 20px;
  padding: 0 ${({ theme }) => theme.spacing[4]};
  font-size: ${({ theme }) => theme.fontSizes[14]};
  line-height: 20px;
  flex: 1;
  outline: none;
  border: none;
  &:focus {
    border: none;
  }
`

export const ATSJobSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[40]};
  max-width: 30rem;
  width: 100%;
`

export const ATSSelect = styled.div`
  width: 100%;
  position: relative;
`

export const TitleNotMatched = styled.div`
  margin-top: ${({ theme }) => theme.spacing[4]};
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing[4]};
  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[14]}`};
  background-color: ${({ theme }) => theme.colors.negativeTranslucent5};
`

export const JobSelectionLoading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
  padding-left: ${({ theme }) => theme.spacing[2]};
  height: 40px;
`
