import { QueryClient } from '@tanstack/react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity
    }
  }
})
export default queryClient
