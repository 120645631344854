import styled, { keyframes } from 'styled-components'
import * as RadixTooltip from '@radix-ui/react-tooltip'

export interface TooltipStyleProps {
  $variant?: 'light' | 'dark'
  position?: 'top' | 'right' | 'bottom' | 'left'
  align?: 'start' | 'center' | 'end'
}

const showFromBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const showFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-2px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

const showFromTop = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const showFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(2px) scale(0.96);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
`

export const Trigger = styled(RadixTooltip.Trigger)`
  border: none;
  outline: none;
  background-color: transparent;
`

export const Content = styled(RadixTooltip.Content)<TooltipStyleProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 4px;
  background-color: ${({ theme, $variant }) =>
    theme.colors[$variant === 'dark' ? 'fgPrimary' : 'bgPrimary']};
  padding: 0.25rem 0.375rem;
  font-size: ${({ theme }) => theme.fontSizes[10]};
  font-weight: 500;
  line-height: ${({ theme }) => theme.lineHeights[1.33]};
  letter-spacing: ${({ theme }) => theme.letterSpacings[0.12]};
  color: ${({ theme, $variant }) => theme.colors[$variant === 'dark' ? 'bgPrimary' : 'fgPrimary']};
  box-shadow: ${({ $variant }) =>
    $variant === 'light'
      ? '0px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 1px 0px 0px rgba(0, 0, 0, 0.02)'
      : 'none'};
  z-index: ${({ theme }) => theme.zindeces.dropdown};
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  &[data-state='delayed-open'] {
    &[data-side='top'] {
      animation-name: ${showFromTop};
    }
    &[data-side='right'] {
      transform-origin: left center;
      animation-name: ${showFromRight};
    }
    &[data-side='bottom'] {
      animation-name: ${showFromBottom};
    }
    &[data-side='left'] {
      animation-name: ${showFromLeft};
    }
  }
`
