import { useBillingCheckoutQuery } from 'src/hooks/queries/use-billing-checkout'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
    }
  }
}

export const StripePricingTable = (): JSX.Element => {
  const { data: billingCheckout } = useBillingCheckoutQuery()

  if (!billingCheckout) {
    return <></>
  }

  return (
    <>
      <stripe-pricing-table
        pricing-table-id={billingCheckout.pricingTableId}
        publishable-key={billingCheckout.stripePublishableKey}
        customer-session-client-secret={billingCheckout.customerSessionClientSecret}
      />
    </>
  )
}
