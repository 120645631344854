import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteCalendarEvent } from 'src/libs/api/backend/calendar_events'
import type { CalendarEvent } from 'src/libs/api/backend/calendar_events'
import { queryKeys } from 'src/libs/query-keys'
import { useSession } from '../queries/use-session'
import { useQueryParams } from '../use-query-params'
import { useParams } from 'react-router-dom'
import { useCandidateJobQuery } from '../queries/use-candidate-job'
import type { CandidateActivity } from 'src/libs/api/backend/candidate_activities'
import { CandidateActivityType } from 'src/libs/api/backend/candidate_activities'
import { addWeeks, format, startOfWeek, subWeeks } from 'date-fns'

interface Args {
  id: string
}

interface UseDeleteCalendarEventReturnType {
  deleteEvent: (args: Args) => void
}

export const useDeleteCalendarEvent = (): UseDeleteCalendarEventReturnType => {
  const { data: sessionData } = useSession()
  const queryClient = useQueryClient()
  const { candidateJobId } = useParams()
  const { data: candidateJob } = useCandidateJobQuery({ candidateJobId })

  const { getParam } = useQueryParams()
  const startDateTimeFromParams = getParam('startDateTime')
  const endDateTimeFromParams = getParam('endDateTime')
  // We are using the currently rendered weeks as query key,
  // but when the calendar dialog is closed we don't have these params available
  // but we can use the default opening timespan as fallback (current week minus 2 / plus 4 weeks)
  const currentWeek = startOfWeek(new Date())
  const startDateTime = startDateTimeFromParams ?? format(subWeeks(currentWeek, 2), "yyyy-MM-dd'T'HH:mm:ssXXX")
  const endDateTime = endDateTimeFromParams ?? format(addWeeks(currentWeek, 4), "yyyy-MM-dd'T'HH:mm:ssXXX")

  const mutation = useMutation({
    mutationFn: async ({ id }: Args) => {
      await deleteCalendarEvent(id)
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: [queryKeys.calendar, sessionData?.user?.id, { startDateTime, endDateTime }]
      })

      const oldCalendarEvents =
        queryClient.getQueryData<CalendarEvent[]>([
          queryKeys.calendar,
          sessionData?.user?.id,
          { startDateTime, endDateTime }
        ]) ?? []

      queryClient.setQueryData<CalendarEvent[]>(
        [queryKeys.calendar, sessionData?.user?.id, { startDateTime, endDateTime }],
        (old) => old?.filter((event) => event.id !== variables.id) ?? []
      )

      await queryClient.cancelQueries({
        queryKey: [queryKeys.candidateActivities, candidateJob?.candidate.id]
      })

      const oldInboxActivities = queryClient.getQueryData<CandidateActivity[]>([
        queryKeys.candidateActivities,
        candidateJob?.candidate.id
      ])

      queryClient.setQueryData<CandidateActivity[]>(
        [queryKeys.candidateActivities, candidateJob?.candidate.id],
        (old) => {
          if (!old) return []
          return old.filter((activity) => {
            if (
              activity.type === CandidateActivityType.CALENDAR_EVENT &&
              activity.calendarEvent?.id === variables.id
            ) {
              return false
            }
            return true
          })
        }
      )

      return { oldCalendarEvents, oldInboxActivities }
    },
    onError: (error, _, context) => {
      console.log('onError: ', error)
      if (context?.oldCalendarEvents) {
        queryClient.setQueryData(
          [queryKeys.calendar, sessionData?.user?.id, { startDateTime, endDateTime }],
          context.oldCalendarEvents
        )
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.calendar, sessionData?.user?.id, { startDateTime, endDateTime }]
      })
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateActivities, candidateJob?.candidate.id]
      })
    }
  })

  const deleteEvent = (args: Args): void => {
    mutation.mutate(args)
  }

  return { deleteEvent }
}
