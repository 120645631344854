import { CANDIDATES_PAGES_MAX_WIDTH } from 'src/styles/constants'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: ${CANDIDATES_PAGES_MAX_WIDTH};
  padding: ${({ theme }) => theme.spacing[40]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.spacing[6]};
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  flex: 1;
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[24]};
`

export const BlankLoadingState = styled.div`
  width: 100%;
  height: 100%;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[12]};
`

export const EmptyWrapper = styled.div`
  max-width: 30rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[40]};
`
