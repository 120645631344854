import React, { createContext } from 'react'
import type { DialogStyleProps } from './dialog'

interface DialogContextProps extends DialogStyleProps {
  id?: string
}

interface DialogProviderProps extends DialogStyleProps {
  id: string
  children: React.ReactNode
}

export const DialogContext = createContext<DialogContextProps>({
  id: undefined,
  $position: 'center',
  $width: 'half',
  $maxWidth: '480px',
  $height: 'auto',
  $minHeight: 'auto',
  $innerPadding: { top: 24, right: 24, bottom: 24, left: 24 }
})

export const DialogProvider = ({
  id,
  $position,
  $width,
  $maxWidth,
  $height,
  $minHeight,
  $innerPadding,
  children
}: DialogProviderProps): JSX.Element => {
  return (
    <DialogContext.Provider value={{ id, $position, $width, $maxWidth, $height, $minHeight, $innerPadding }}>
      {children}
    </DialogContext.Provider>
  )
}
