import styled from 'styled-components'

export const SequenceOptions = styled.div`
  display: flex;
  height: 100%;
`

export const Content = styled.div`
  flex: 0 0 68%;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 2.5rem;
  overflow-y: scroll;
`

export const ContentInner = styled.div`
  width: 100%;
  max-width: 600px;
`

export const Sidebar = styled.div`
  flex: 0 0 32%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: linear-gradient(
      164deg,
      rgba(0, 167, 255, 0.02) 0%,
      rgba(26, 180, 132, 0.02) 49.64%,
      rgba(131, 113, 242, 0.02) 100%
    ),
    #fcfcfd;
`

export const ExistingJob = styled.div`
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 6px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    visibility: hidden;
  }
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.bgSecondary};
      button {
        visibility: visible;
      }
    }
  }
`
