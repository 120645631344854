import { JoinRequests } from 'src/components/blocks/join-requests'
import { OrgUsers } from 'src/components/team-members-list'

export const TeamMembersSettings = (): JSX.Element => {
  return (
    <>
      <JoinRequests />
      <OrgUsers />
    </>
  )
}
