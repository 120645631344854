import { Button } from 'src/components/primitives/button'
import { Dropdown } from 'src/components/primitives/dropdown'
import { EmailVariables } from '../editor/types'
import type { EmailVariableType } from '../editor/types'
import { isNil } from 'lodash'
import type { Editor } from '@tiptap/react'
import { useCallback } from 'react'

interface EmailVariableButtonProps {
  editor: Editor | null
}

export const EmailVariableButton = ({ editor }: EmailVariableButtonProps): JSX.Element => {
  const formatVariableName = (variable: EmailVariableType): string => {
    return variable.replace(/_/g, ' ').replace(/\b\w/g, (character) => character.toUpperCase())
  }

  const formatDateExample = (variable: EmailVariableType): string => {
    const DATE_FORMAT_MAP: Record<string, string> = {
      full_date: new Date().toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' }),
      short_date: new Date().toLocaleDateString('en-us', { month: 'long', day: 'numeric' }),
      numerical_date: new Date().toLocaleDateString('en-us'),
      month: new Date().toLocaleDateString('en-us', { month: 'long' }),
      weekday: new Date().toLocaleDateString('en-us', { weekday: 'long' })
    }

    if (isNil(DATE_FORMAT_MAP[variable])) {
      return ''
    }

    return ` (${DATE_FORMAT_MAP[variable]})`
  }
  const addVariableToContent = useCallback((variable: EmailVariableType): void => {
    if (editor) {
      editor
        .chain()
        .focus()
        .insertContent(`{{${variable}}}`)
        .run()
    }
  }, [editor])

  const candidateVariables = EmailVariables
    .filter((variable: EmailVariableType) => variable.includes('candidate_'))
    .map((variable: EmailVariableType) => ({
      id: variable,
      title: formatVariableName(variable),
      onSelect: () => {
        addVariableToContent(variable)
      }
    }))

  const senderVariables = EmailVariables
    .filter((variable: EmailVariableType) => variable.includes('sender_'))
    .map((variable: EmailVariableType) => ({
      id: variable,
      title: formatVariableName(variable),
      onSelect: () => {
        addVariableToContent(variable)
      }
    }))

  const dateVariables = EmailVariables
    .filter((variable: EmailVariableType) => variable.includes('_date') || variable.includes('day') || variable.includes('month'))
    .map((variable: EmailVariableType) => ({
      id: variable,
      title: formatVariableName(variable) + formatDateExample(variable),
      onSelect: () => {
        addVariableToContent(variable)
      }
    }))

  const customVariables = EmailVariables
    .filter((variable: EmailVariableType) => variable.includes('ai_'))
    .map((variable: EmailVariableType) => ({
      id: variable,
      title: formatVariableName(variable) + formatDateExample(variable),
      onSelect: () => {
        addVariableToContent(variable)
      }
    }))

  const emailVariableMenuList = [
    { type: 'label', title: 'Candidate variables' },
    ...candidateVariables,
    { type: 'seperator', title: '' },
    { type: 'label', title: 'Sender variables' },
    ...senderVariables,
    { type: 'seperator', title: '' },
    { type: 'label', title: 'Date variables' },
    ...dateVariables,
    { type: 'seperator', title: '' },
    { type: 'label', title: 'AI variables' },
    ...customVariables
  ]

  return (
    <Dropdown
      trigger={
        <Button
          nested
          $variant="ghost"
          $colorTheme="muted"
          $height={24}
          leadingIcon="variable-add"
          trailingIcon="chevron-down-small"
          $fontSize={12}
        >
          Add variable
        </Button>
      }
      menuPosition="start"
      size="small"
      items={emailVariableMenuList}
      onCloseAutoFocus={(event: Event) => {
        event.preventDefault()
      }}
    />
  )
}
