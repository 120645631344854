import { Avatar } from 'src/components/primitives/avatar'
import type { AvatarStyleProps } from 'src/components/primitives/avatar'
import { useGetDepartmentQuery } from 'src/hooks/queries/use-departments'

interface DepartmentLogoProps extends Pick<AvatarStyleProps, '$size' | '$innerPadding'> {
  departmentId: string
}

export const DepartmentLogo = ({
  departmentId,
  $size = 16,
  $innerPadding = 0
}: DepartmentLogoProps): JSX.Element => {
  const {
    departments
  } = useGetDepartmentQuery()

  const department = departments?.find(d => d.id === departmentId)

  return (
    <Avatar
      $type="logo"
      initials={department?.name}
      $size={$size}
      $border={false}
      $innerPadding={$innerPadding}
      company={{ name: department?.name, url: department?.domain }}
    />
  )
}
