import * as S from './signal.styled'
import type { Color, Spacing } from 'src/styles/theme/types'

export interface SignalStyleProps {
  $color: Color
  $size?: Spacing
  $offset?: {
    x?: Spacing
    y?: Spacing
  }
}

interface SignalProps extends SignalStyleProps {}

export const Signal = ({ $color, $size = 6, $offset }: SignalProps): JSX.Element => {
  return <S.Signal $color={$color} $size={$size} $offset={$offset} />
}
