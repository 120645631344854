import React from 'react'
import type { Spacing } from 'src/styles/theme/types'
import * as S from './flex.styled'

type Direction = 'row' | 'column' | 'row-reverse' | 'column-reverse'
type Align = 'flex-start' | 'center' | 'flex-end'
type Justify = 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-evenly'

export interface FlexStyleProps {
  $direction?: Direction
  $align?: Align
  $justify?: Justify
  $gap?: Spacing
  $flex?: string
  $width?: 'auto' | string
  $maxWidth?: string
  $height?: 'auto' | 'full'
  $wrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
}

interface FlexProps extends FlexStyleProps {
  children?: React.ReactNode
  as?: 'div' | 'span' | 'ul' | 'nav' | 'header'
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void | Promise<void>
}

export const Flex = React.forwardRef<HTMLDivElement, FlexProps>(({
  children,
  $direction = 'row',
  $align = 'flex-start',
  $justify = 'flex-start',
  $flex,
  $gap = 0,
  $width = '100%',
  $maxWidth,
  $height = 'auto',
  $wrap,
  as = 'div',
  onClick
}, forwardedRef): JSX.Element => {
  return (
    <S.Flex
      ref={forwardedRef}
      as={as}
      $direction={$direction}
      $align={$align}
      $justify={$justify}
      $gap={$gap}
      $flex={$flex}
      $width={$width}
      $maxWidth={$maxWidth}
      $height={$height}
      $wrap={$wrap}
      onClick={onClick}
    >
      {children}
    </S.Flex>
  )
})

Flex.displayName = 'Flex'
