import styled from 'styled-components'

export const FoundInAtsButton = styled.div`
  a, span {
    display: flex;
    overflow: hidden;
    img {
      mix-blend-mode: multiply;
    }
  }
`
