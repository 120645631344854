interface PinProps {
  width?: number
  height?: number
  color?: string
}

export const SadPin = ({ width = 40, height = 43 }: PinProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        data-name="SadPinBodyShadow"
        cx="19"
        cy="41"
        rx="12"
        ry="2"
        fill="#18181B"
        fillOpacity="0.1"
      />
      <path
        data-name="SadPinBody"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8206 17.6606C37.8206 27.4142 29.9139 35.3211 20.1604 35.3211V39.0911C20.1604 39.694 19.5932 40.1368 19.0165 39.9612C10.0375 37.2276 3.35657 29.2199 2.58182 19.5464C2.57747 19.4922 2.5781 19.4388 2.58329 19.3868C2.52819 18.8188 2.5 18.243 2.5 17.6606C2.5 7.9069 10.4068 0 20.1603 0C29.9138 0 37.8206 7.9069 37.8206 17.6606Z"
        fill="#27272A"
      />
      <path
        data-name="SadPinBody"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1601 29.735C26.8291 29.735 32.2354 24.3286 32.2354 17.6595C32.2354 10.9904 26.8291 5.58398 20.1601 5.58398C13.491 5.58398 8.08472 10.9904 8.08472 17.6595C8.08472 24.3286 13.491 29.735 20.1601 29.735Z"
        fill="white"
      />
      <path
        data-name="SadPinRightEye"
        d="M22.221 15.2416C21.6875 15.0987 21.1392 15.4153 20.9962 15.9487L20.9074 16.2801C20.4778 17.8837 21.4294 19.5319 23.0329 19.9616C24.6364 20.3912 26.2846 19.4396 26.7143 17.8361L26.8031 17.5047C26.946 16.9712 26.6294 16.4229 26.096 16.2799L22.221 15.2416Z"
        fill="#27272A"
      />
      <path
        data-name="SadPinLeftEye"
        d="M13.5055 16.2806C12.972 16.4236 12.6554 16.9719 12.7984 17.5054L12.8872 17.8368C13.3168 19.4403 14.9651 20.3919 16.5686 19.9623C18.1721 19.5326 19.1237 17.8844 18.694 16.2808L18.6052 15.9494C18.4623 15.416 17.914 15.0994 17.3805 15.2423L13.5055 16.2806Z"
        fill="#27272A"
      />
    </svg>
  )
}

export const HappyPin = ({ width = 40, height = 43 }: PinProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        data-name="HappyPinShadow"
        cx="19"
        cy="41"
        rx="12"
        ry="2"
        fill="#18181B"
        fillOpacity="0.1"
      />
      <path
        data-name="HappyPinBody"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8206 17.6606C37.8206 27.4142 29.9139 35.3211 20.1604 35.3211V39.0911C20.1604 39.694 19.5932 40.1368 19.0165 39.9612C10.0375 37.2276 3.35657 29.2199 2.58182 19.5464C2.57747 19.4922 2.5781 19.4388 2.58329 19.3868C2.52819 18.8188 2.5 18.243 2.5 17.6606C2.5 7.9069 10.4068 0 20.1603 0C29.9138 0 37.8206 7.9069 37.8206 17.6606Z"
        fill="#27272A"
      />
      <path
        data-name="HappyPinBody"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1601 29.735C26.8291 29.735 32.2354 24.3286 32.2354 17.6595C32.2354 10.9904 26.8291 5.58398 20.1601 5.58398C13.491 5.58398 8.08472 10.9904 8.08472 17.6595C8.08472 24.3286 13.491 29.735 20.1601 29.735Z"
        fill="white"
      />
      <path
        data-name="HappyPinBody"
        d="M12.5452 19.2474V15.9043C12.5452 14.2442 13.891 12.8984 15.5511 12.8984C17.2112 12.8984 18.5569 14.2442 18.5569 15.9043V19.2474H12.5452Z"
        fill="#27272A"
      />
      <path
        data-name="HappyPinBody"
        d="M21.7637 19.2474V15.9043C21.7637 14.2442 23.1094 12.8984 24.7695 12.8984C26.4296 12.8984 27.7754 14.2442 27.7754 15.9043V19.2474H21.7637Z"
        fill="#27272A"
      />
      <rect data-name="HappyPinBody" x="12" y="21" width="16" height="2" fill="white" />
    </svg>
  )
}

export const NormalPin = ({ width = 21, height = 20, color = '#27272A' }: PinProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        data-name="PinHead"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4103 8.83028C19.4103 13.7071 15.4569 17.6605 10.5802 17.6606V19.5455C10.5802 19.847 10.2966 20.0684 10.0082 19.9806C5.51877 18.6138 2.17828 14.61 1.79091 9.77322C1.78873 9.74609 1.78905 9.71942 1.79165 9.69341C1.7641 9.40942 1.75 9.1215 1.75 8.83028C1.75 3.95345 5.70339 0 10.5801 0C15.4569 0 19.4103 3.95345 19.4103 8.83028ZM10.58 14.8677C13.9145 14.8677 16.6177 12.1645 16.6177 8.82997C16.6177 5.49541 13.9145 2.79221 10.58 2.79221C7.24552 2.79221 4.54237 5.49541 4.54237 8.82997C4.54237 12.1645 7.24552 14.8677 10.58 14.8677Z"
        fill={color}
      />
      <path
        data-name="LeftEye"
        d="M6.77263 9.62409V7.95255C6.77263 7.1225 7.44551 6.4496 8.27555 6.4496C9.10559 6.4496 9.77848 7.1225 9.77848 7.95255V9.62409H6.77263Z"
        fill={color}
      />
      <path
        data-name="RightEye"
        d="M11.3818 9.62409V7.95255C11.3818 7.1225 12.0547 6.4496 12.8848 6.4496C13.7148 6.4496 14.3877 7.1225 14.3877 7.95255V9.62409H11.3818Z"
        fill={color}
      />
    </svg>
  )
}

export const SearchingPin = ({ width = 40, height = 43 }: PinProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse data-name="SearchingPinShadow" cx="19" cy="41" rx="12" ry="2" fill="#18181B" fillOpacity="0.1" />
      <path
        data-name="SearchingPinBody"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8206 17.6606C37.8206 27.4142 29.9139 35.3211 20.1604 35.3211V39.0911C20.1604 39.694 19.5932 40.1368 19.0165 39.9612C10.0375 37.2276 3.35657 29.2199 2.58182 19.5464C2.57747 19.4922 2.5781 19.4388 2.58329 19.3868C2.52819 18.8188 2.5 18.243 2.5 17.6606C2.5 7.9069 10.4068 0 20.1603 0C29.9138 0 37.8206 7.9069 37.8206 17.6606Z"
        fill="#27272A"
      />
      <path
        data-name="SearchingPinInner"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1601 29.735C26.8291 29.735 32.2354 24.3286 32.2354 17.6595C32.2354 10.9904 26.8291 5.58398 20.1601 5.58398C13.491 5.58398 8.08472 10.9904 8.08472 17.6595C8.08472 24.3286 13.491 29.735 20.1601 29.735Z"
        fill="white"
      />
      <path
        data-name="SearchingPinEye"
        d="M12.5452 19.2474V15.9043C12.5452 14.2442 13.891 12.8984 15.5511 12.8984C17.2112 12.8984 18.5569 14.2442 18.5569 15.9043V19.2474H12.5452Z"
        fill="#27272A"
      />
      <path
        data-name="SearchingPinEye"
        d="M21.7637 19.2474V15.9043C21.7637 14.2442 23.1094 12.8984 24.7695 12.8984C26.4296 12.8984 27.7754 14.2442 27.7754 15.9043V19.2474H21.7637Z"
        fill="#27272A"
      />
      {/*
      <rect x="12" y="10" width="16" height="2" fill="white" />
      */}
    </svg>
  )
}
