import { createContext } from 'react'

export enum GlobalErrorId {
  MISSING_ACCESS_TOKEN = 'missing-access-token'
}

export interface GlobalError<T = any> {
  id: GlobalErrorId
  payload?: T
}

export interface GlobalErrorContextProps {
  globalError: GlobalError<any> | null
  openGlobalError: <T>(id: GlobalErrorId, payload?: T) => void
  closeGlobalError: () => void
  isGlobalErrorOpen: boolean
}

export const GlobalErrorContext = createContext<GlobalErrorContextProps>({
  globalError: null,
  openGlobalError: () => {},
  closeGlobalError: () => {},
  isGlobalErrorOpen: false
})
