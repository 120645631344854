import { EmptyState } from 'src/components/blocks/empty-state'
import RouteBuilder from 'src/libs/route-builder'
import { IN_OUTREACH_STEPS } from 'src/libs/api/backend/candidate_jobs'
import { CandidatesTable } from '../candidates-table'
import {
  ExportToAtsButton,
  MoveToAnotherJobButton,
  PauseResumeSequenceButton,
  RejectButton,
  WriteEmailButton
} from '../candidate-table-actions'
import { COLUMN } from '../candidate-table-cells/table-builder'
import type { Job } from 'src/libs/api/backend/jobs'
import { useMemo } from 'react'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'

interface CandidatesInSequenceTableProps {
  pageHeaderHeight: number
  jobId: string
  job: Job | undefined
  step: IN_OUTREACH_STEPS
}

const getEmptyStateCopy = (step: IN_OUTREACH_STEPS): { heading: string, description: string } => {
  switch (step) {
    case IN_OUTREACH_STEPS.IN_PROGRESS:
      return {
        heading: 'No active candidates in sequence',
        description: 'There are no candidates currently active in the outreach sequence.'
      }
    case IN_OUTREACH_STEPS.NOT_YET_CONTACTED:
      return {
        heading: 'No candidates contacted',
        description: 'No candidates have been contacted through the outreach sequence yet.'
      }
    case IN_OUTREACH_STEPS.NO_RESPONSE:
      return {
        heading: 'No responses received',
        description: 'None of the candidates in sequence have responded to the outreach emails yet.'
      }
    case IN_OUTREACH_STEPS.REPLIED:
      return {
        heading: 'No replies received',
        description: 'None of the candidates in sequence have replied yet.'
      }
    case IN_OUTREACH_STEPS.OPENED:
      return {
        heading: 'No emails opened',
        description: 'None of the candidates have opened the outreach emails yet.'
      }
    case IN_OUTREACH_STEPS.SCHEDULED:
      return {
        heading: 'No interviews scheduled',
        description: 'Looks like you have no candidates scheduled for meetings yet, you’ll start seeing them here once you start setting up a call with them.'
      }
    case IN_OUTREACH_STEPS.MESSAGED:
      return {
        heading: 'No candidates contacted',
        description: 'No candidates have been contacted through the outreach sequence yet.'
      }
    default:
      return {
        heading: 'No one in sequence',
        description: "Looks like you don't have any prospects yet, start sourcing candidates and all of them will show up right here"
      }
  }
}

export const CandidatesInSequenceTable = ({
  pageHeaderHeight = 0,
  jobId,
  job,
  step
}: CandidatesInSequenceTableProps): JSX.Element => {
  const { data, isPending } = useCandidateJobsQuery({ inOutreachStep: step })

  const emptyStateRenderedCopy = useMemo(() => getEmptyStateCopy(step), [step])

  const emptyState = useMemo(() => {
    if (step !== IN_OUTREACH_STEPS.ALL_CONTACTS) {
      return (
        <EmptyState
          heading={emptyStateRenderedCopy.heading}
          description={emptyStateRenderedCopy.description}
          svg="profileCard"
          $padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
          actions={[
            {
              href: RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE', { jobId }),
              children: 'Go to All Contacts'
            }
          ]}
        />
      )
    }
    return (
      <EmptyState
        heading={emptyStateRenderedCopy.heading}
        description={emptyStateRenderedCopy.description}
        svg="profileCard"
        $padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
        actions={[
          {
            href: `/jobs/${jobId}/candidates/sourcing`,
            children: 'Go to sourcing'
          }
        ]}
      />
    )
  }, [emptyStateRenderedCopy.description, emptyStateRenderedCopy.heading, jobId, step])

  const candidateJobs = data ?? []
  return (
    <CandidatesTable
      pageHeaderHeight={pageHeaderHeight}
      isLoading={isPending}
      candidateJobs={candidateJobs}
      emptyState={emptyState}
      visibleColumns={[COLUMN.FAVORITE, COLUMN.NAME, COLUMN.STATUS, COLUMN.SEQUENCE_STEP, COLUMN.CREATED_AT, COLUMN.LAST_CONTACT, COLUMN.NEXT_CONTACT]}
      selectedRowsActions={
        <>
          <PauseResumeSequenceButton />
          <RejectButton />
          <WriteEmailButton candidateJobs={candidateJobs} />
          <MoveToAnotherJobButton candidateJobs={candidateJobs} />
          <ExportToAtsButton candidateJobs={candidateJobs} job={job}/>
        </>
      }
    />
  )
}
