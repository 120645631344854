import styled from 'styled-components'
import type { CardProps } from './card'

export const Card = styled.div<Pick<CardProps, '$padding'>>`
  width: 100%;
  padding-top: ${({ $padding, theme }) => theme.spacing[$padding?.top ?? 16]};
  padding-right: ${({ $padding, theme }) => theme.spacing[$padding?.right ?? 16]};
  padding-bottom: ${({ $padding, theme }) => theme.spacing[$padding?.bottom ?? 16]};
  padding-left: ${({ $padding, theme }) => theme.spacing[$padding?.left ?? 16]};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  overflow: hidden;
`
