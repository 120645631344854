import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { CalendarEvent } from 'src/libs/api/backend/calendar_events'
import { updateCalendarEvent } from 'src/libs/api/backend/calendar_events'
import { queryKeys } from 'src/libs/query-keys'
import { useSession } from '../queries/use-session'
import { useQueryParams } from '../use-query-params'

interface Args {
  updatedEvent: CalendarEvent
}

interface UseUpdateCalendarEventReturnType {
  updateEvent: (args: Args) => void
}

export const useUpdateCalendarEvent = (): UseUpdateCalendarEventReturnType => {
  const queryClient = useQueryClient()
  const { data: sessionData } = useSession()

  const { getParam } = useQueryParams()
  const startDateTime = getParam('startDateTime')
  const endDateTime = getParam('endDateTime')

  const mutation = useMutation({
    mutationFn: async ({ updatedEvent }: Args) => {
      return await updateCalendarEvent(updatedEvent)
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: [queryKeys.calendar, sessionData?.user?.id, { startDateTime, endDateTime }]
      })

      const snapshot =
        queryClient.getQueryData<CalendarEvent[]>([
          queryKeys.calendar,
          sessionData?.user?.id,
          { startDateTime, endDateTime }
        ]) ?? []

      queryClient.setQueryData<CalendarEvent[]>(
        [queryKeys.calendar, sessionData?.user?.id, { startDateTime, endDateTime }],
        (old) =>
          old?.map((event) =>
            event.eventId === variables.updatedEvent.eventId ? { ...event, ...variables.updatedEvent } : event
          ) ?? []
      )

      return { snapshot }
    },
    onError: (error, _, context) => {
      console.log('onError: ', error)
      if (context?.snapshot) {
        queryClient.setQueryData([queryKeys.calendar, sessionData?.user?.id, { startDateTime, endDateTime }], context.snapshot)
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [queryKeys.calendar, sessionData?.user?.id, { startDateTime, endDateTime }] })
    }
  })

  const updateEvent = (args: Args): void => {
    mutation.mutate(args)
  }

  return { updateEvent }
}
