import { useDropzone } from 'react-dropzone'
import * as S from './file-drop-upload.styled'
import { Flex } from 'src/components/primitives/flex'
import { Icon } from 'src/components/primitives/icon'
import { Caption, Paragraph, Span } from 'src/components/primitives/typography'
import { FieldError } from '../field-error'
import { When } from 'src/components/blocks/when'
import { useCallback, useState } from 'react'
import type { AttachmentUpload } from 'src/components/blocks/attachments-list'
import { AttachmentUploadStatus } from 'src/components/blocks/attachments-list'
import { generateUploadUrlApi } from 'src/libs/api/backend/url'
import axios from 'axios'

type AcceptedFile = 'csv' | 'pdf'

interface FileDropUploadProps {
  heading?: string
  accept: AcceptedFile[]
  maxFileSize?: number
  multiple?: boolean
  onUpload: (urls: string[]) => void
}

const MAX_FILE_SIZE = 5 * 1024 * 1024 // 5MB

export const FileDropUpload = ({
  heading = 'Upload file',
  multiple = false,
  maxFileSize = MAX_FILE_SIZE,
  onUpload,
  accept
}: FileDropUploadProps): JSX.Element => {
  const [uploadedFiles, setUploadedFiles] = useState<AttachmentUpload[]>([])
  const [uploadedFilesError, setUploadedFilesError] = useState<string | null>(null)

  const mapFileTypesToMIME = (extensions: string[]): Record<string, string[]> => {
    const mimeTypes: Record<string, string[]> = {
      csv: ['text/csv'],
      pdf: ['application/pdf']
    }

    const prepared: Record<string, string[]> = {}
    extensions.forEach((ext) => {
      if (mimeTypes[ext]) {
        mimeTypes[ext].forEach((mimeType) => {
          prepared[mimeType] = prepared[mimeType] || []
          prepared[mimeType].push(`.${ext}`)
        })
      }
    })
    return prepared
  }

  const preparedAcceptFileTypes = mapFileTypesToMIME(accept)

  const uploadAttachments = useCallback(
    async (files: File[]) => {
      setUploadedFilesError('')
      if (!files.length) {
        return
      }
      const uploadedFiles = Array.from(files)
      const totalFilesSize =
        uploadedFiles.reduce((acc, file) => acc + file.size, 0) +
        uploadedFiles.reduce((acc, uploads) => acc + uploads.size, 0)
      if (totalFilesSize > maxFileSize) {
        setUploadedFilesError('uploadedFiles size exceeds the allowed limit')
        return
      }

      const uploadLinks = await Promise.all(
        uploadedFiles.map(async (file: File) => {
          const response = await generateUploadUrlApi(file.name)

          setUploadedFiles((prevUploadedFiles) => {
            return [
              ...prevUploadedFiles,
              {
                id: file.name,
                fileName: file.name,
                contentType: file.type,
                size: file.size,
                s3Key: response.key,
                status: AttachmentUploadStatus.UPLOADING
              }
            ]
          })
          return { ...response, file }
        })
      )

      const uploadedUrls = await Promise.all(
        uploadLinks.map(async ({ uploadSignedUrl, key, file }) => {
          try {
            await axios.put(uploadSignedUrl, file, {
              headers: {
                'Content-Type': file.type
              }
            })
            setUploadedFiles((prevUploadedFiles) =>
              prevUploadedFiles.map((uploads) => {
                if (uploads.s3Key === key) {
                  return { ...uploads, status: AttachmentUploadStatus.UPLOADED }
                }
                return uploads
              })
            )
            return key
          } catch (error) {
            setUploadedFiles((prevUploadedFiles) =>
              prevUploadedFiles.map((uploads) => {
                if (uploads.s3Key === key) {
                  return { ...uploads, status: AttachmentUploadStatus.ERROR }
                }
                return uploads
              })
            )
            return null
          }
        })
      )

      onUpload(uploadedUrls.filter((url) => url !== null))
    },
    [uploadedFiles, maxFileSize, onUpload]
  )

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, fileRejections } = useDropzone({
    onDropAccepted: (files) => {
      void uploadAttachments(files)
    },
    accept: preparedAcceptFileTypes,
    multiple,
    maxSize: maxFileSize
  })

  return (
    <>
      <S.Wrapper {...getRootProps({ className: 'dropzone' })} $isDragActive={isDragActive}>
        <Flex $gap={12} $direction="column" $align="center">
          <input {...getInputProps()} />
          <Icon name="upload" size={24} color="fgSecondary" />
          <When condition={!acceptedFiles.length}>
            <Flex $gap={4} $direction="column" $align="center">
              <Caption size="SM" $color="fgSecondary">
                {heading}
              </Caption>
              <Paragraph size="XS" $color="fgTertiary">
                <Span size="XS" $color="tintBg">
                  Choose files
                </Span>{' '}
                or drag and drop
              </Paragraph>
            </Flex>
          </When>
          <When condition={acceptedFiles?.length >= 1}>
            <Flex $gap={4} $align="center" $justify="center">
              {acceptedFiles?.map((file) => (
                <S.AcceptedFile>
                  <Icon name="file" color="fgTertiary" size={12} />
                  <Span>{file.name}</Span>
                </S.AcceptedFile>
              ))}
            </Flex>
          </When>
        </Flex>
      </S.Wrapper>
      {fileRejections?.length >= 1 && <FieldError>File type not allowed</FieldError>}
      {uploadedFilesError && <FieldError>There was an error uploading</FieldError>}
    </>
  )
}
