import type { OrgJoinRequest } from 'src/libs/api/backend/orgs'
import { getOrgJoinRequests } from 'src/libs/api/backend/orgs'
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'

interface UseGetOrgJoinRequests {
  joinRequests: OrgJoinRequest[]
  loading: boolean
  error: Error | null
  refetch: () => unknown
}

export const orgJoinRequestsQuery = () => ({
  queryKey: [queryKeys.orgJoinRequests],
  queryFn: async () => await getOrgJoinRequests(),
  staleTime: 60 * 1000
})

export const useGetOrgJoinRequests = (): UseGetOrgJoinRequests => {
  const { data, isLoading, error, refetch } = useQuery(orgJoinRequestsQuery())
  return {
    joinRequests: data ?? [],
    loading: isLoading,
    error,
    refetch
  }
}
