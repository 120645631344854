import { CandidatesPageHeader } from 'src/components/blocks/candidates-page-header'
import { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCreateJobSearchRefinement } from 'src/hooks/mutations/use-create-job-search-refinement'
import RouteBuilder from 'src/libs/route-builder'
import { RefinementActionsBar } from 'src/components/blocks/refinement-actions-bar'
import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING } from 'src/styles/constants'
import type { Criteria } from 'src/libs/api/backend/candidate_search'
import { useJobQuery } from 'src/hooks/queries/use-job'

const NewJobSearchRefinementPage = (): JSX.Element => {
  const [isRecommending, setIsRecommending] = useState(false)
  const { createJobSearchRefinement } = useCreateJobSearchRefinement()
  const navigate = useNavigate()
  const { data: job } = useJobQuery()

  const onSubmit = useCallback((searchCriteria: Criteria) => {
    setIsRecommending(true)
    if (job?.id) {
      createJobSearchRefinement({
        jobId: job.id,
        searchCriteria,
        onSuccess: (jobSearchRefinement) => {
          navigate(RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId: job.id, jobSearchRefinementId: jobSearchRefinement.id }))
        }
      })
    }
  }, [createJobSearchRefinement, job, navigate])
  return (
    <div
      style={{
        padding: `0 ${CONTENT_PADDING}`,
        paddingBottom: CONTENT_PADDING,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxWidth: `calc(${CANDIDATES_PAGES_MAX_WIDTH} + 2rem)`,
        position: 'relative'
      }}
    >
      <CandidatesPageHeader heading="New Pin Search" $padding={{ bottom: 10 }} />
      <RefinementActionsBar
        autoApproveIsEnabled={false}
        isRecommending={isRecommending}
        setIsRecommending={setIsRecommending}
        maxWidth={CANDIDATES_PAGES_MAX_WIDTH}
        newSearchRefinement={true}
        onSubmit={onSubmit}
      />
    </div>
  )
}

export default NewJobSearchRefinementPage
