import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { When } from '../when'
import { Flex } from 'src/components/primitives/flex'
import { isNil } from 'lodash'
import { CandidateJobRejectionReason, CandidateJobStage, getCandidateJobSourceDisplay, getCandidateRejectionReasonDisplay } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateExpanded } from 'src/libs/api/backend/candidate_jobs'
import { Comment, OutboundEmail, Description, ErrorDescription, ManualEmail, CalendarEntry, PositiveDescription, EmailHeader, LinkedInMessageActivity, LinkedInMessageHeader } from './elements'
import * as S from './timeline-activity.styled'
import { CandidateActivityType, EmailMessageType, LinkedInMessageType } from 'src/libs/api/backend/candidate_activities'
import type { CandidateActivity, LinkedInMessage } from 'src/libs/api/backend/candidate_activities'
import { formatDate } from 'src/utils/format-date'
import { format } from 'date-fns'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import type { OrgUser } from 'src/libs/api/backend/users'
import type { Color } from 'src/styles/theme/types'
import { useSession } from 'src/hooks/use-session'
import { useMemo, useState } from 'react'
import { Span } from 'src/components/primitives/typography'
import { NavLink, useParams } from 'react-router-dom'
import RouteBuilder from 'src/libs/route-builder'
import { EmailStatistics } from '../email-statistics'
import { CandidateActivityViewMode } from 'src/constants'

export interface TimelineActivityProps {
  activity: CandidateActivity
  candidate: CandidateExpanded
  onReply?: (emailMessageId: string) => void
  onReplyLinkedInMessage?: (linkedInMessage: LinkedInMessage) => void
  onCommentUpdate: (noteId: string, body: string) => void
  activityViewMode: CandidateActivityViewMode
}

interface ActivityIcon {
  name: IconName
  color: Color
}

function getIconForActivity (activity: CandidateActivity): ActivityIcon | undefined {
  switch (activity.type) {
    case CandidateActivityType.CANDIDATE_JOB_CREATED:
      return { name: 'binoculars', color: 'fgSecondary' }
    case CandidateActivityType.EMAIL_BOUNCED:
      return { name: 'alert-triangle', color: 'negativeFg' }
    case CandidateActivityType.CANDIDATE_JOB_STAGE_TRANSITION:
      switch (activity.candidateJobStageTransition?.toStage) {
        case CandidateJobStage.PROSPECTING:
          return { name: 'mails', color: 'fgSecondary' }
        case CandidateJobStage.COMMUNICATING:
          return { name: 'mail', color: 'fgSecondary' }
        case CandidateJobStage.REJECTED:
          return { name: 'archive', color: 'negativeFg' }
      }
      break
    case CandidateActivityType.PRIOR_CONTACT: {
      return { name: 'messages-square', color: 'fgSecondary' }
    }
    case CandidateActivityType.LINKEDIN_MESSAGE:
      return { name: 'linkedin-outline', color: 'positiveFg' }
    case CandidateActivityType.EMAIL:
    case CandidateActivityType.MANUAL_EMAIL:
      return { name: 'mail', color: 'positiveFg' }
    case CandidateActivityType.NOTE:
      return { name: 'message-circle', color: 'fgSecondary' }
    case CandidateActivityType.CALENDAR_EVENT:
      return { name: 'calendar', color: 'fgSecondary' }
  }
}

interface ActivityAvatar {
  initials?: string
  photoUrl?: string | null
}

function getAvatarForActivity (activity: CandidateActivity, candidate: CandidateExpanded, orgUsers: OrgUser[]): ActivityAvatar | undefined {
  const candidateAvatar = { initials: candidate.name, photoUrl: candidate.profilePhotoUrl }
  const orgUser = orgUsers.find((orgUser) => orgUser.id === activity.userId)
  const orgUserAvatar = { initials: orgUser?.name ?? 'Unknown', photoUrl: orgUser?.profilePhotoUrl }

  switch (activity.type) {
    case CandidateActivityType.EMAIL:
      switch (activity.emailMessage?.type) {
        case EmailMessageType.SENT:
          return orgUserAvatar
        case EmailMessageType.RECEIVED:
          return candidateAvatar
      }
      break
    case CandidateActivityType.PRIOR_CONTACT:
    case CandidateActivityType.MANUAL_EMAIL:
    case CandidateActivityType.NOTE:
    case CandidateActivityType.CANDIDATE_JOB_STAGE_TRANSITION:
    case CandidateActivityType.CANDIDATE_JOB_CREATED:
      if (!isNil(activity.userId)) {
        return orgUserAvatar
      }
      break
  }
}

function getCandidateJobSource (activity: CandidateActivity, orgUsers: OrgUser[]): string {
  const source = activity.candidateJob?.source
  if (isNil(source) && !isNil(activity.userId)) {
    const orgUser = orgUsers.find((orgUser) => orgUser.id === activity.userId)
    return orgUser?.name ? `Manually added by ${orgUser.name}` : 'Manually added'
  }
  return `Sourced from ${getCandidateJobSourceDisplay(source ?? undefined)}`
}

function getIndicator (isDescription: boolean, icon: ActivityIcon | undefined): JSX.Element | undefined {
  if (isDescription && !isNil(icon)) {
    return <Icon {...icon} size={12} />
  }
  if (!isNil(icon)) {
    return <Icon {...icon} size={12} />
  }

  // TODO: maybe return default icon?
}

export const TimelineActivity = ({
  activity,
  candidate,
  onReply,
  onReplyLinkedInMessage,
  onCommentUpdate,
  activityViewMode
}: TimelineActivityProps): JSX.Element => {
  const { data: orgUsers } = useOrgUsersQuery()
  const { jobId } = useParams()
  const { user } = useSession()
  const [emailDetailsExpanded, setEmailDetailsExpanded] = useState(false)

  const getOrgUser = (userId: string | undefined | null): OrgUser | null => {
    if (isNil(userId)) {
      return null
    }
    return orgUsers?.find((orgUser) => orgUser.id === userId) ?? null
  }

  const icon = getIconForActivity(activity)
  const avatar = getAvatarForActivity(activity, candidate, orgUsers ?? [])
  // const indicatorOffset = getIndicatorOffset(activity)
  const isDescription =
    activity.type === CandidateActivityType.CANDIDATE_JOB_CREATED ||
    activity.type === CandidateActivityType.CANDIDATE_JOB_STAGE_TRANSITION ||
    activity.type === CandidateActivityType.EMAIL_BOUNCED ||
    activity.type === CandidateActivityType.PRIOR_CONTACT
  const CONTENT_WIDTH = !isDescription ? '2 / span 2' : 'auto'

  // const secondaryIndicator = getSecondaryIndicator(activity)

  const activityHasCollidingContent =
    activity.type === CandidateActivityType.EMAIL ||
    activity.type === CandidateActivityType.MANUAL_EMAIL ||
    activity.type === CandidateActivityType.CALENDAR_EVENT ||
    activity.type === CandidateActivityType.NOTE
  const activityHasShadow =
    activity.type === CandidateActivityType.EMAIL ||
    activity.type === CandidateActivityType.MANUAL_EMAIL ||
    activity.type === CandidateActivityType.CALENDAR_EVENT

  const isInternalActivity = useMemo(() => {
    return activity.emailMessage?.type ===
      EmailMessageType.DRAFT ||
      activity.emailMessage?.type === EmailMessageType.SENT ||
      activity.linkedInMessage?.type === LinkedInMessageType.SENT ||
      activity.type === CandidateActivityType.NOTE ||
      activity.type === CandidateActivityType.CANDIDATE_JOB_CREATED ||
      activity.type === CandidateActivityType.CANDIDATE_JOB_STAGE_TRANSITION ||
      activity.candidateJobStageTransition?.toStage === CandidateJobStage.COMMUNICATING ||
      activity.type === CandidateActivityType.EMAIL_BOUNCED ||
      activity.type === CandidateActivityType.PRIOR_CONTACT ||
      activity.type === CandidateActivityType.CALENDAR_EVENT
  }, [activity])

  const getActivityColorTheme = (activity: CandidateActivity): { bg: Color, fg: Color } => {
    if (activity.candidateJobStageTransition?.toStage === CandidateJobStage.REJECTED) {
      return { bg: 'negativeTranslucent5', fg: 'negativeFg' }
    }

    switch (activity.type) {
      case CandidateActivityType.EMAIL:
        return { bg: 'positiveTranslucent5', fg: 'fgPrimary' }
      case CandidateActivityType.NOTE:
        return { bg: 'warningTranslucent5', fg: 'fgPrimary' }
      case CandidateActivityType.CALENDAR_EVENT:
        return { bg: 'negativeTranslucent5', fg: 'fgPrimary' }
      default:
        return { bg: 'fgTranslucent5', fg: 'fgSecondary' }
    }
  }

  const activityColorTheme = getActivityColorTheme(activity)

  return (
    <S.TimelineActivity
      data-component="TimelineActivity"
      $isInternalActivity={isInternalActivity}
      $activityViewMode={activityViewMode}
    >
      <S.TimelineActivityInner
        data-component="TimelineActivityInner"
        $hasCollidingContent={activityHasCollidingContent}
        $hasShadow={activityHasShadow}
      >
        <S.TimelineActivityHeader
          data-component="TimelineActivityHeader"
          $bg={activityColorTheme.bg}
          $hasCollidingContent={activityHasCollidingContent}
        >
          <S.Indicator>
            <S.IndicatorPrimary>
              {getIndicator(isDescription, icon)}
            </S.IndicatorPrimary>
          </S.Indicator>
          <Flex $align="center" $justify="space-between">
            <When condition={activity.type === CandidateActivityType.PRIOR_CONTACT}>
              <Description>{avatar?.initials} communicating with candidate</Description>
            </When>
            <When condition={activity.type === CandidateActivityType.CANDIDATE_JOB_CREATED}>
            <Description>
              {(() => {
                const candidateJobSource = getCandidateJobSource(activity, orgUsers ?? [])
                const jobTitle = activity.job?.title ?? ''
                const fullText = `${candidateJobSource} for ${jobTitle}`
                const treshold = activityViewMode === CandidateActivityViewMode.DETAIL ? 70 : 60

                const truncatedText = fullText.length > treshold ? fullText.substring(0, treshold) + '…' : fullText

                if (fullText.length <= treshold) {
                  return (
                    <>
                     {candidateJobSource} for <NavLink to={RouteBuilder.build('JOBS_CANDIDATES', { jobId: activity.job?.id ?? jobId })}>{jobTitle}</NavLink>
                    </>
                  )
                }
                return truncatedText
              })()}
            </Description>
            </When>
            <When condition={activity.type === CandidateActivityType.CANDIDATE_JOB_STAGE_TRANSITION}>
              <When condition={activity.candidateJobStageTransition?.toStage === CandidateJobStage.PROSPECTING}>
                <Description><Span size="XS"><Span size="XS" $fontWeight={500}>{avatar?.initials} </Span>added candidate to outreach sequence</Span></Description>
              </When>
              <When condition={activity.candidateJobStageTransition?.toStage === CandidateJobStage.COMMUNICATING}>
                <Description>Candidate responded to outreach sequence</Description>
              </When>
              <When condition={activity.candidateJobStageTransition?.toStage === CandidateJobStage.REJECTED}>
                {activity.candidateJobStageTransition?.rejectionReason === CandidateJobRejectionReason.AUTO_UNRESPONSIVE
                  ? <Description $color="negativeFg">
                      Candidate did not respond to sequence and was {activityViewMode === CandidateActivityViewMode.COMPACT ? 'auto-archived' : 'automatically archived'}
                    </Description>
                  : <Description $color="negativeFg">
                      {avatar?.initials} archived the candidate: {getCandidateRejectionReasonDisplay(activity.candidateJobStageTransition?.rejectionReason)}
                    </Description>
                }
              </When>
              <When condition={activity.candidateJobStageTransition?.toStage === CandidateJobStage.HIRED}>
                <PositiveDescription>
                  {avatar?.initials} marked the candidate as hired
                </PositiveDescription>
              </When>
            </When>
            <When condition={activity.type === CandidateActivityType.EMAIL_BOUNCED}>
              <ErrorDescription>Email to {activity.emailMessage?.to} could not be delivered</ErrorDescription>
            </When>
            <When condition={activity.type === CandidateActivityType.EMAIL}>
              <Description>
                <EmailHeader
                  isInbound={activity.emailMessage?.type === EmailMessageType.RECEIVED}
                  isSequenceEmail={!isNil(activity.emailMessage?.candidateSequenceStep)}
                  manualEmailMessage={activity.manualEmailMessage}
                  emailDetailsExpanded={emailDetailsExpanded}
                  onEmailDetailsExpand={setEmailDetailsExpanded}
                  {...activity.emailMessage}
                  />
              </Description>
              {/* {!isNil(activity.emailMessage) && <OutboundEmail emailMessage={activity.emailMessage} attachments={activity.emailMessage.attachments} onReply={onReply} />} */}
            </When>
            <When condition={activity.type === CandidateActivityType.LINKEDIN_MESSAGE}>
              {!isNil(activity.linkedInMessage) && (
                <Description>
                  <LinkedInMessageHeader message={activity.linkedInMessage} candidateName={candidate.name} />
                </Description>
              )}
            </When>
            <When condition={activity.type === CandidateActivityType.NOTE}>
              <Description><Span size="XS"><Span size="XS" $fontWeight={500}>{avatar?.initials} </Span>added a comment</Span></Description>
            </When>
            <When condition={activity.type === CandidateActivityType.CALENDAR_EVENT}>
              <Description $color={activityColorTheme.fg}><Span size="XS">Meeting scheduled by<Span size="XS" $fontWeight={500} $color={activityColorTheme.fg}> {getOrgUser(activity.calendarEvent?.userId)?.name}</Span></Span></Description>
            </When>
            <S.Timestamp>
              <Description $color={activityColorTheme.fg}>
                <When condition={activityViewMode === CandidateActivityViewMode.DETAIL}>
                  {format(activity.date, 'eee, MMM d yyyy, h:mmaaa')}{' '}
                  ({formatDate(activity.date ?? '', {
                    format: 'DaysPassed'
                  })})
                </When>
                <When condition={activityViewMode === CandidateActivityViewMode.COMPACT}>
                  {formatDate(activity.date ?? '', {
                    format: 'DaysPassed'
                  })}
                </When>
              </Description>
            </S.Timestamp>
          </Flex>
        </S.TimelineActivityHeader>
        <S.Content data-component="TimelineActivityContent" $columns={CONTENT_WIDTH}>
          <When condition={activity.type === CandidateActivityType.LINKEDIN_MESSAGE}>
            {!isNil(activity.linkedInMessage) && (
              <LinkedInMessageActivity
                linkedInMessage={activity.linkedInMessage}
                onReply={onReplyLinkedInMessage}
              />
            )}
          </When>
          <When condition={activity.type === CandidateActivityType.EMAIL}>
            {!isNil(activity.emailMessage) && (
              <OutboundEmail
                emailMessage={activity.emailMessage}
                attachments={activity.emailMessage.attachments}
                onReply={onReply}
                emailDetailsExpanded={emailDetailsExpanded}
              />
            )}
          </When>
          <When condition={activity.type === CandidateActivityType.MANUAL_EMAIL}>
            {!isNil(activity.manualEmailMessage) &&
              <ManualEmail
                emailDetailsExpanded={emailDetailsExpanded}
                manualEmailMessage={activity.manualEmailMessage}
              />
            }
          </When>
          <When condition={activity.type === CandidateActivityType.NOTE}>
            {!isNil(activity.candidateNote) && (
              <Comment
                isEditable={user?.id === activity.candidateNote.userId}
                candidateNote={activity.candidateNote}
                onCommentUpdate={onCommentUpdate}
              />
            )}
          </When>
          <When condition={activity.type === CandidateActivityType.CALENDAR_EVENT}>
            {activity.calendarEvent && <CalendarEntry calendarEvent={activity.calendarEvent} />}
          </When>
        </S.Content>
      </S.TimelineActivityInner>
      <When condition={activity.type === CandidateActivityType.EMAIL}>
        {!isNil(activity.emailMessage) && <EmailStatistics emailMessage={activity.emailMessage} />}
      </When>
    </S.TimelineActivity>
  )
}
