import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { Flex } from '../flex'
import { Caption } from '../typography'
import { AvatarsList } from 'src/components/blocks/avatars-list'
import type { Color } from 'src/styles/theme/types'
import type { NotificationType } from 'src/stores/notifications'

interface CandidateStatusSummaryProps {
  candidateJobs: CandidateJobExpanded[]
  type: NotificationType
}

const getContent = (type: NotificationType): { textColor: Color, stepDescription: string } => {
  switch (type) {
    case 'candidate-moved': {
      return {
        textColor: 'positiveBg',
        stepDescription: 'moved'
      }
    }
    case 'candidate-restored': {
      return {
        textColor: 'positiveBg',
        stepDescription: 'restored'
      }
    }
    default: {
      return {
        textColor: 'inherit',
        stepDescription: ''
      }
    }
  }
}

export const CandidateStatusSummary = ({ candidateJobs, type }: CandidateStatusSummaryProps): JSX.Element => {
  const content = getContent(type)
  return (
    <Flex $align="center" $gap={12}>
      <AvatarsList
        avatars={candidateJobs.map(candidateJob => ({
          id: candidateJob.id,
          name: candidateJob.candidate.name,
          profilePhotoUrl: candidateJob.candidate.profilePhotoUrl
        }))}
        avatarDisplay={{ showPlus: candidateJobs.length > 3, count: 3 }}
      />
      <Caption size="XS" $whiteSpace="nowrap" $color={content.textColor}>
        {candidateJobs.length} candidate{candidateJobs.length > 1 ? 's' : ''} {content.stepDescription}
      </Caption>
    </Flex>
  )
}
