import { useMutation } from '@tanstack/react-query'
import { sendTestEmail as sendTestEmailFn } from 'src/libs/api/backend/sequences'
import type { SendTestEmailArgs } from 'src/libs/api/backend/sequences'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'
import { Icons } from 'src/components/primitives/icon'

interface Args extends SendTestEmailArgs {
  onSuccess?: () => void
}

interface Res {
  sendTestEmail: (args: Args) => void
}

export const useSendTestEmail = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)

  const mutation = useMutation({
    mutationFn: async ({ sequenceStep, emails }: Args) => {
      await sendTestEmailFn({ emails, sequenceStep })
    },
    onError: (err) => {
      notifyError({
        message: `An error occurred when sending the preview: ${err.message}`
      })
    },
    onSuccess: async (_, variables) => {
      if (variables.onSuccess) {
        variables.onSuccess()
      }
      notifySuccess({
        icon: Icons.mails,
        message: 'Successfully sent test email'
      })
    }
  })

  const sendTestEmail = (args: Args): void => {
    mutation.mutate(args)
  }

  return { sendTestEmail }
}
