import { useEffect, useState } from 'react'
import type { RefObject } from 'react'

type KeyCombination = string | string[]

interface KeyboardEventOptions {
  // The method defines if all the provided keys need to be
  // hit at the same time ("AND") or if the action is called
  // for every key ("OR").
  method?: 'AND' | 'OR'
  isEnabled?: boolean
  ignoreInput?: boolean
  target?: RefObject<HTMLElement>
}

const DEFAULT_OPTIONS: KeyboardEventOptions = {
  method: 'OR',
  isEnabled: true,
  ignoreInput: true
}

export const useKeyboardEvent = (
  keyCombinations: KeyCombination[],
  action: () => void,
  options: KeyboardEventOptions
): void => {
  const merged = { ...DEFAULT_OPTIONS, ...options }
  const { method, isEnabled, ignoreInput, target } = merged
  const [pressedKeys, setPressedKeys] = useState<Set<string>>(new Set())

  useEffect(() => {
    if (!isEnabled) {
      return
    }

    const downHandler = (event: KeyboardEvent): void => {
      if (ignoreInput) {
        if (
          event.target instanceof HTMLInputElement ||
          event.target instanceof HTMLTextAreaElement
        ) {
          return
        }
      }

      setPressedKeys((prevKeys) => {
        const newKeys = new Set(prevKeys)
        if (keyCombinations.flat().includes(event.key)) {
          newKeys.add(event.key)
        }
        return newKeys
      })

      keyCombinations.forEach((combination) => {
        const keys = Array.isArray(combination) ? combination : [combination]
        if (method === 'OR') {
          if (keys.includes(event.key)) {
            if (target?.current && target.current === document.activeElement) {
              event.preventDefault()
              action()
            }
          }
        }
        if (method === 'AND') {
          if (keys.length === pressedKeys.size && keys.every((key) => pressedKeys.has(key))) {
            if (!target || (target.current && target.current === document.activeElement)) {
              event.preventDefault()
              action()
            }
          }
        }
      })
    }

    const upHandler = (event: KeyboardEvent): void => {
      setPressedKeys((prev) => {
        const newKeys = new Set(prev)
        newKeys.delete(event.key)
        return newKeys
      })
    }

    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [keyCombinations, action, isEnabled, target])
}
