import 'src/utils/sentry'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import router from 'src/router'
import { initializeTracking } from './libs/initialize-tracking'
import { StrictMode } from 'react'

initializeTracking()

ReactDOM
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  .createRoot(document.querySelector('slot')!)
  .render(
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  )
