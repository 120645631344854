import type { Spacing } from 'src/styles/theme/types'
import * as S from './card.styled'

export interface CardProps {
  children: React.ReactNode
  $padding?: {
    top?: Spacing
    right?: Spacing
    bottom?: Spacing
    left?: Spacing
  }
}

export const Card = ({
  $padding = { top: 24, right: 24, bottom: 24, left: 24 },
  children
}: CardProps): JSX.Element => {
  return <S.Card $padding={$padding}>{children}</S.Card>
}
