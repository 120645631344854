import { Button } from 'src/components/primitives/button'
import { TimeFilter } from './constants'

import { Dropdown } from 'src/components/primitives/dropdown/dropdown'

interface TimeFilterDropdownProps {
  currentFilter: TimeFilter
  onSetTimeFilter: (timeFilter: TimeFilter) => void
}

export const TimeFilterDropdown = ({
  currentFilter,
  onSetTimeFilter
}: TimeFilterDropdownProps): JSX.Element => {
  return (
    <Dropdown
      trigger={
        <Button
          nested
          $height={24}
          $variant="raised"
          $colorTheme="muted"
          $fontSize={12}
          trailingIcon="chevrons-up-down"
        >
          {currentFilter}
        </Button>
      }
      items={Object.values(TimeFilter).map((filter) => ({
        title: filter,
        value: filter,
        onSelect: () => {
          onSetTimeFilter(filter)
        }
      }))}
      size="small"
      menuPosition="end"
      selectedValue={currentFilter}
    />
  )
}
