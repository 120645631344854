import type { Spacing } from 'src/styles/theme/types'
import { Flex } from 'src/components/primitives'
import * as S from './page-header.styled'

interface PageHeaderProps {
  heading: string
  subheading?: string
  marginBottom?: Spacing
  marginRight?: Spacing
  actions?: React.ReactNode
}

export const PageHeader = ({
  heading,
  subheading,
  marginBottom = 18,
  actions
}: PageHeaderProps): JSX.Element => {
  return (
    <S.PageHeader $marginBottom={marginBottom}>
      <Flex $direction='column' $gap={4}>
        <h1>{heading}</h1>
        {subheading && <h2>{subheading}</h2>}
      </Flex>
      {actions ? <S.Actions>{actions}</S.Actions> : null}
    </S.PageHeader>
  )
}
