import { queryOptions, useQuery, useQueryClient } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { queryKeys } from 'src/libs/query-keys'
import { fetchSequenceByJobId } from 'src/libs/api/backend/jobs'
import type { SequenceWithSteps } from 'src/libs/api/backend/jobs'
import { isNil } from 'lodash'
import { useEffect } from 'react'

export const jobSequenceQuery = (jobId?: string) => queryOptions({
  queryKey: [queryKeys.jobSequence, jobId],
  queryFn: async () => await fetchSequenceByJobId(jobId ?? ''),
  staleTime: 60 * 1000,
  enabled: !isNil(jobId)
})

export const useJobSequenceQuery = (providedJobId?: string): UseQueryResult<SequenceWithSteps | null> => {
  const { jobId } = useParams()
  const query = useQuery(jobSequenceQuery(providedJobId ?? jobId))
  const queryClient = useQueryClient()

  useEffect(() => {
    const refetch = async (): Promise<void> => {
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateSequenceStepMessageReviews, jobId]
      })
    }
    return () => { void refetch() }
  }, [jobId, queryClient])

  return query
}
