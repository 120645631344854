import styled from 'styled-components'
import { SIZES } from './constants'

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${SIZES.HEADER.LG}px;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  z-index: 3;
`

export const Titlebar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
  padding: 0.5rem 0.75rem;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.fgTranslucent5};
`

export const Addressbar = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  height: 2.75rem;
  padding: 0.25rem 0.75rem;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.fgTranslucent5};
  span {
    align-self: center;
  }
`

export const CalendarNavbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  height: 2.75rem;
  padding: 0.75rem;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.fgTranslucent5};
  button {
    flex-grow: 0;
    flex-shrink: 0;
  }
`
