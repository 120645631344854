import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const JobLink = styled(NavLink)`
  color: ${({ theme }) => theme.colors.negativeBg};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  text-decoration: underline;
  pointer-events: auto;
`
