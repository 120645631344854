import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteOrRestoreProject } from 'src/libs/api/backend/projects'
import type { Project } from 'src/libs/api/backend/projects'
import { queryKeys } from 'src/libs/query-keys'
import { isNil } from 'lodash'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface Args {
  id: string
  deleted: boolean
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  deleteProject: (args: Args) => void
}

export const useDeleteProject = (): Res => {
  const queryClient = useQueryClient()

  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)

  const mutation = useMutation({
    mutationFn: async ({ id, deleted }: Args) => await deleteOrRestoreProject(id, deleted),
    onError: (err, variables) => {
      console.error(err)
      notifyError({
        message: `An error occurred while ${variables.deleted ? 'deleting' : 'restoring'} your project: ${err.message}`
      })

      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (deletedProject, variables) => {
      queryClient.setQueryData<Project[]>([queryKeys.projects], (oldProjects) => {
        if (isNil(oldProjects)) {
          return
        }

        return oldProjects.map((proj) => {
          if (proj.id === deletedProject.id) {
            return deletedProject
          }
          return proj
        }).sort((a, b) => a.deleted === b.deleted ? 0 : a.deleted ? 1 : -1)
      })

      notifySuccess({
        message: `Project successfully ${variables.deleted ? 'deleted' : 'restored'}`
      })
      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })
  const deleteProject = (args: Args): void => {
    mutation.mutate(args)
  }

  return { deleteProject }
}
