import { useAtomValue, useSetAtom } from 'jotai'
import { useMemo } from 'react'
import * as Dialog from 'src/components/primitives/dialog'
import { controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'

export const JobSettingsDialog = (): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.JOB_SETTINGS), []))
  const controlDialog = useSetAtom(controlDialogAtom)

  return (
    <Dialog.Root
      id={DialogId.JOB_SETTINGS}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.JOB_SETTINGS, newState: value })
      }}
      $width="almostFull"
      $height="almostFull"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Settings"
          onClose={() => {
            controlDialog({ id: DialogId.JOB_SETTINGS, newState: false })
          }}
        />
        <Dialog.Content>
          <p>Job settings</p>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
