import { useMemo, useState } from 'react'
import { ChartHeader } from '../chart-header'
import * as S from './tile-chart.styled'
import { useTheme } from 'styled-components'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'

interface DataPoint {
  dayOfWeek: number
  hour: number
  friendlyDayOfWeek: string
  friendlyHour: string
  count: number
  percentage: number
}

interface TileChartProps {
  chartTitle: string
  data?: DataPoint[]
}

interface GetTyleStyles {
  backgroundColor: string
  opacity: number
}

interface FloatingLegendArgs {
  show: boolean
  value: number | null
  x: number
  y: number
}

export const TileChart = ({ chartTitle, data }: TileChartProps): JSX.Element => {
  const { colors } = useTheme()
  const [floatingLegend, setFloatingLegend] = useState<FloatingLegendArgs>({
    show: false,
    value: null,
    x: 0,
    y: 0
  })

  const legendY = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  const legendX = [
    { value: 0, title: '12am' },
    { value: 1, title: '' },
    { value: 2, title: '' },
    { value: 3, title: '3am' },
    { value: 4, title: '' },
    { value: 5, title: '' },
    { value: 6, title: '6am' },
    { value: 7, title: '' },
    { value: 8, title: '' },
    { value: 9, title: '9am' },
    { value: 10, title: '' },
    { value: 11, title: '' },
    { value: 12, title: '12pm' },
    { value: 13, title: '' },
    { value: 14, title: '' },
    { value: 15, title: '3pm' },
    { value: 16, title: '' },
    { value: 17, title: '' },
    { value: 18, title: '6pm' },
    { value: 19, title: '' },
    { value: 20, title: '' },
    { value: 21, title: '9pm' },
    { value: 22, title: '' },
    { value: 23, title: '' },
    { value: 24, title: '' }
  ]

  const preparedData = useMemo(() => {
    const dataToUse = data ?? []
    const result = Array.from({ length: 7 }, () => Array(24).fill({
      percentage: 0,
      value: 0
    }))
    dataToUse.forEach((datapoint) => {
      result[datapoint.dayOfWeek][datapoint.hour] = {
        percentage: Math.round(datapoint.percentage * 1000) / 1000,
        count: datapoint.count
      }
    })
    return result
  }, [data])

  const getTileStyles = (value: number): GetTyleStyles => {
    return {
      backgroundColor: value <= 0 ? colors.bgTertiary : colors.chart1,
      opacity: value <= 0 ? 1 : (parseFloat(value.toFixed(1)) * 0.8) + 0.2
    }
  }

  const generateTiles = (): JSX.Element[] => {
    const tiles = []
    for (let day = 0; day < 7; day++) {
      for (let hour = 0; hour < 24; hour++) {
        const value: number = preparedData[day][hour]?.percentage ?? 0
        const count: number = preparedData[day][hour]?.count ?? 0
        tiles.push(
          <S.Tile
            key={`${day}-${hour}`}
            style={getTileStyles(value)}
            data-value={value}
            onMouseEnter={() => {
              setFloatingLegend((prev) => ({
                ...prev,
                value: count
              }))
            }}
          />
        )
      }
    }
    return tiles
  }

  return (
    <>
      <ChartHeader
        title={chartTitle}
        customLegend={
          <Flex $align="center" $justify="flex-end" $gap={8}>
            <S.LegendIndicator style={{ backgroundColor: colors.bgTertiary, opacity: 1 }} />
            <S.LegendIndicator style={{ backgroundColor: colors.chart1, opacity: 0.2 }} />
            <S.LegendIndicator style={{ backgroundColor: colors.chart1, opacity: 0.4 }} />
            <S.LegendIndicator style={{ backgroundColor: colors.chart1, opacity: 0.6 }} />
            <S.LegendIndicator style={{ backgroundColor: colors.chart1, opacity: 0.8 }} />
            <S.LegendIndicator style={{ backgroundColor: colors.chart1, opacity: 1 }} />
            <Paragraph size="XS" $color="fgPrimary">
              Best
            </Paragraph>
          </Flex>
        }
      />
      <S.Chart
        onMouseEnter={() => {
          setFloatingLegend((prev) => ({
            ...prev,
            show: true
          }))
        }}
        onMouseMove={(e) => {
          setFloatingLegend((prev) => ({
            ...prev,
            x: e.clientX,
            y: e.clientY
          }))
        }}
        onMouseLeave={() => {
          setFloatingLegend((prev) => ({
            ...prev,
            show: false,
            value: null
          }))
        }}
      >
        <S.FloatingLegend
          style={{
            opacity: floatingLegend.show ? 1 : 0,
            transform: `translate3d(${floatingLegend.x + 30}px,${floatingLegend.y - 30}px,0)`
          }}
        >
          <Caption size="XS" $color="fgSecondary">
            Opens
          </Caption>
          <Caption size="LG">{floatingLegend?.value ?? 0}</Caption>
        </S.FloatingLegend>
        <S.LegendX>
          {legendX.map((l) => (
            <S.LegendTile key={l.value}>{l.title}</S.LegendTile>
          ))}
        </S.LegendX>
        <S.LegendY>
          {legendY.map((l) => (
            <S.LegendTile key={l}>{l.slice(0, 1)}</S.LegendTile>
          ))}
        </S.LegendY>
        <S.Inner>{generateTiles()}</S.Inner>
      </S.Chart>
    </>
  )
}
