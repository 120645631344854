import { Caption } from 'src/components/primitives/typography'
import * as S from './automate-info-banner.styled'
import { Icon, Icons } from 'src/components/primitives/icon'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useQueryParams } from 'src/hooks/use-query-params'
import { useUpdateJobSearchRefinement } from 'src/hooks/mutations/use-update-job-search-refinement'
import { Button } from 'src/components/primitives'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { useSetAtom } from 'jotai'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'

interface AutomateInfoBannerProps {
  autoApproveIsEnabled: boolean
  disabled?: boolean
}

export const AutomateInfoBanner = ({ autoApproveIsEnabled, disabled = false }: AutomateInfoBannerProps): JSX.Element => {
  const { jobId } = useParams()
  const { data: jobData } = useJobQuery()
  const { getParam } = useQueryParams()
  const jobSearchRefinementId = getParam('jobSearchRefinementId')
  const { updateJobSearchRefinement } = useUpdateJobSearchRefinement()
  const openDialog = useSetAtom(openDialogAtom)

  const handleUpdateJobRefinement = useCallback((isEnabled: boolean): void => {
    updateJobSearchRefinement({
      jobId: jobId ?? '',
      jobSearchRefinementId: jobSearchRefinementId ?? '',
      automateAutoApproveCandidates: isEnabled
    })
  }, [updateJobSearchRefinement, jobId, jobSearchRefinementId])

  if (jobData?.deleted) {
    return <></>
  }

  if (!autoApproveIsEnabled) {
    return (
      <Button
        $variant="outline"
        $colorTheme="muted"
        $height={24}
        $fontSize={12}
        leadingIcon={Icons.sparkles}
        onClick={() => {
          openDialog({ id: DialogId.ENABLE_AUTO_OUTREACH })
        }}
        disabled={disabled}
      >
        Auto-outreach
      </Button>
    )
  }

  return (
    <S.ToggleWrapper
      onClick={() => {
        handleUpdateJobRefinement(!autoApproveIsEnabled)
      }}
    >
      <Icon name={Icons.sparkles} size={12} />
      <Caption size="XS">Auto-outreach</Caption>
      <S.ToggleButton $isChecked={autoApproveIsEnabled}>
        <span>{autoApproveIsEnabled ? 'ON' : 'OFF'}</span>
      </S.ToggleButton>
    </S.ToggleWrapper>
  )
}
