import styled from 'styled-components'

export const InboxCommentEditor = styled.div<{ $isFocused: boolean }>`
  border: ${({ $isFocused }) => $isFocused ? 'solid 1px' : 'dashed 1px'};
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 6px;
  margin-top: 0.5rem;
  overflow: hidden;
  box-shadow: ${({ $isFocused, theme }) => $isFocused ? theme.boxShadows.md : 'none'};
`

export const EditorWrapper = styled.div`
  position: relative;
  min-height: 3rem;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
`
