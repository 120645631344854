import { useMutation } from '@tanstack/react-query'
import { fetchDisconnectMergeIntegration } from 'src/libs/api/backend/merge'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface Args {
  onSuccess?: () => void
}

interface Res {
  disconnectAtsIntegration: ({ onSuccess }: Args) => void
}

export const useDisconnectMergeIntegration = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)

  const mutation = useMutation({
    mutationFn: async () => {
      await fetchDisconnectMergeIntegration()
    },
    onError: (err: Error) => {
      notifyError({
        message: `An error occurred when disconnecting from ATS - ${err.message}`
      })
    },
    onSuccess: (_data, variables: Args) => {
      notifySuccess({
        message: 'Successfully disconnected from ATS'
      })
      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })

  const disconnectAtsIntegration = ({ onSuccess }: Args): void => {
    mutation.mutate({ onSuccess })
  }

  return { disconnectAtsIntegration }
}
