import { Paragraph } from 'src/components/primitives/typography'
import * as S from './empty-chart.styled'
import { Icon } from 'src/components/primitives/icon'

export interface EmptyChartStyleProps {
  $minHeight?: string
}

interface EmptyChartProps extends EmptyChartStyleProps {}

export const EmptyChart = ({ $minHeight = '300px' }: EmptyChartProps): JSX.Element => {
  return (
    <S.EmptyChart $minHeight={$minHeight}>
      <Icon name="bar-chart-2" color="fgTertiary" />
      <Paragraph size="SM">Not enough data yet. Try again later.</Paragraph>
    </S.EmptyChart>
  )
}
