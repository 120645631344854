import { candidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import queryClient from 'src/hooks/query-client'
import { CandidateJobStage } from '../api/backend/candidate_jobs'
import type { CandidateJobExpanded } from '../api/backend/candidate_jobs'
import { jobQuery } from 'src/hooks/queries/use-job'
import { listJobSearchRefinementsQuery } from 'src/hooks/queries/use-job-search-refinements'
import { jobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { defer, redirect } from 'react-router-dom'
import type { Job, JobSearchRefinement, SequenceWithSteps } from '../api/backend/jobs'
import type { DeferredData } from '@remix-run/router/dist/utils'
import RouteBuilder from '../route-builder'

export interface SourceRefinementLoaderData {
  jobId: string
  job: Job
  jobSearchRefinements: JobSearchRefinement[]
  sequence: SequenceWithSteps | null
  candidateJobs: CandidateJobExpanded[]
  jobSearchRefinementId: string | null
}
export const sourceRefinementLoader = async ({ params: { jobId, jobSearchRefinementId } }: { params: { jobId: string, jobSearchRefinementId: string } }): Promise<DeferredData | Response> => {
  return defer({
    jobId,
    job: queryClient.ensureQueryData(jobQuery(jobId)),
    jobSearchRefinements: queryClient.ensureQueryData(listJobSearchRefinementsQuery(jobId)),
    jobSearchRefinementId,
    sequence: queryClient.ensureQueryData(jobSequenceQuery(jobId)),
    candidateJobs: queryClient.ensureQueryData(candidateJobsQuery(jobId, { stage: CandidateJobStage.SOURCED, jobSearchRefinementId, source: undefined }))
  })
}

export const manualSourcingLoader = async ({ params: { jobId } }: { params: { jobId: string } }): Promise<DeferredData> => {
  return defer({
    jobId,
    job: queryClient.ensureQueryData(jobQuery(jobId)),
    jobSearchRefinements: [],
    jobSearchRefinementId: null,
    sequence: queryClient.ensureQueryData(jobSequenceQuery(jobId)),
    candidateJobs: queryClient.ensureQueryData(candidateJobsQuery(jobId, { stage: CandidateJobStage.SOURCED, source: null }))
  })
}

export const sourcingLoader = async ({ params: { jobId, jobSearchRefinementId }, request }: { params: { jobId: string, jobSearchRefinementId?: string }, request: Request }): Promise<Response | null> => {
  const searchParams = new URL(request.url).searchParams
  const jobSearchRefinementIdParam = searchParams.get('jobSearchRefinementId')
  if (jobSearchRefinementIdParam) {
    return redirect(RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId, jobSearchRefinementId: jobSearchRefinementIdParam }))
  }
  const jobSearchRefinements = await queryClient.ensureQueryData(listJobSearchRefinementsQuery(jobId))
  if (!jobSearchRefinementId) {
    if (jobSearchRefinements.length > 0) {
      return redirect(RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId, jobSearchRefinementId: jobSearchRefinements[0].id }))
    } else {
      return redirect(RouteBuilder.build('JOBS_CANDIDATES_SOURCING_SEARCH', { jobId }))
    }
  }
  return null
}
