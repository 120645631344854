import { useCallback, useEffect, useState } from 'react'
import { EditorContent, useEditor, BubbleMenu } from '@tiptap/react'
import type { Editor as TiptapEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import { mergeAttributes } from '@tiptap/core'
import * as S from './email-signature-editor.styled'
import { useTheme } from 'styled-components'
import { When } from '../when'
import { Icon } from 'src/components/primitives/icon'

export interface EmailSignatureEditorProps {
  isEditable?: boolean
  initialContent?: string | null
  onDataChanged: (data: string) => void
  forceEditorFocus?: boolean
  setEditor?: (editor: TiptapEditor | null) => void
  onBlur?: () => void
  content: string | null | undefined
  onFocusChange?: (isFocused: boolean) => void
}

export const EmailSignatureEditor = ({
  isEditable = true,
  initialContent,
  onDataChanged,
  forceEditorFocus = false,
  setEditor,
  onBlur,
  content,
  onFocusChange
}: EmailSignatureEditorProps): JSX.Element => {
  const { colors } = useTheme()
  const [initialized, setInitialized] = useState(false)

  const editor = useEditor(
    {
      onUpdate: ({ editor }) => {
        const output = editor.getHTML()
        onDataChanged(output)
      },
      onBlur: () => {
        onBlur?.()
        onFocusChange?.(false)
      },
      onFocus: () => {
        onFocusChange?.(true)
      },
      extensions: [
        StarterKit.configure({
          paragraph: false,
          dropcursor: {
            width: 3,
            color: colors.tintBg
          }
        }),
        // This converts using <div> instead of <p> for paragraphs
        Paragraph.extend({
          parseHTML () {
            return [{ tag: 'p' }, { tag: 'div' }]
          },
          renderHTML ({ HTMLAttributes }) {
            return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0] // eslint-disable-line
          }
        }),
        Link.configure({
          openOnClick: false,
          linkOnPaste: true,
          HTMLAttributes: {
            class: 'editor-link'
          }
        }),
        Placeholder.configure({
          placeholder: 'John Doe'
        })
      ],
      editable: isEditable,
      content
    },
    [isEditable]
  )

  useEffect(() => {
    if (!editor) {
      return
    }

    if (
      ((isEditable && !initialized) || !isEditable) &&
      initialContent &&
      initialContent?.length >= 1
    ) {
      setInitialized(true)
      editor.commands.setContent(initialContent)
    }
  }, [editor, initialContent, initialized, isEditable])

  useEffect(() => {
    if (forceEditorFocus) {
      setTimeout(() => {
        editor?.commands.focus('end')
      }, 100)
    }
  }, [forceEditorFocus, editor])

  useEffect(() => {
    setEditor?.(editor)
    return () => {
      setEditor?.(null)
      editor?.destroy()
    }
  }, [editor, setEditor])

  const handleSetLink = useCallback(() => {
    if (!editor) {
      return
    }
    const previousUrl: string = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    if (url === null) {
      return
    }

    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()
    } else {
      editor.chain().focus().extendMarkRange('link').setLink({ href: url, target: '_blank' }).run()
    }
  }, [editor])

  return (
    <S.Editor $isEditable={isEditable}>
      <S.EditorContent
        $padding="6px 0px"
        $editorHeight="100%"
        $isEditable={isEditable}
        $isEmpty={!!editor?.isEmpty}
      >
        <EditorContent editor={editor} />
        <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
          <S.BubbleMenu>
            <When condition={Boolean(editor?.isActive('link'))}>
              <S.EditorButton onClick={() => editor?.chain().focus().unsetLink().run()}>
                <Icon name="unlink" color="bgPrimary" size={12} />
                <span>Unlink</span>
              </S.EditorButton>
            </When>
            <When condition={!editor?.isActive('link')}>
              <S.EditorButton onClick={handleSetLink}>
                <Icon name="link" color="bgPrimary" size={12} />
                <span>Add link</span>
              </S.EditorButton>
            </When>
          </S.BubbleMenu>
        </BubbleMenu>
      </S.EditorContent>
    </S.Editor>
  )
}
