import { Icon } from '../icon'
import type { IconName } from '../icon'
import type { Color, FontSize, Spacing } from 'src/styles/theme/types'
import type { TypographyProps } from '../typography'
import * as S from './badge.styled'

export interface BadgeStyleProps {
  $height?: Spacing
  $shape?: 'squared' | 'rounded' | 'dot'
  $variant?: Variant
  $transform?: TypographyProps['$transform']
  $fontSize?: FontSize
  $padding?: 'normal' | 'casual'
}

export type Variant =
  | 'plainTint'
  | 'plain'
  | 'ghost'
  | 'fgPrimary'
  | 'fgSecondary'
  | 'fgTertiary'
  | 'bgTertiary'
  | 'bgSecondary'
  | 'bgPrimary'
  | 'tint'
  | 'tintLight'
  | 'positive'
  | 'positiveLight'
  | 'negative'
  | 'negativeLight'
  | 'aiSolidFg'

interface BadgeProps extends BadgeStyleProps {
  children?: string | React.ReactNode
  leadingIcon?: IconName
  trailingIcon?: IconName
}

export const Badge = ({
  children,
  leadingIcon,
  $shape = 'squared',
  $variant = 'fgSecondary',
  $height = 16,
  $fontSize = 10,
  $transform = 'uppercase',
  $padding = 'normal'
}: BadgeProps): JSX.Element => {
  const getIconColor = ($variant: Variant): Color => {
    switch ($variant) {
      case 'positiveLight': {
        return 'positiveBg'
      }
      case 'negativeLight': {
        return 'negativeBg'
      }
      case 'plainTint': {
        return 'tintBg'
      }
      case 'tintLight': {
        return 'tintBg'
      }
      case 'aiSolidFg': {
        return 'aiSolidFg'
      }
      default: {
        return 'bgPrimary'
      }
    }
  }

  return (
    <S.Badge
      $shape={$shape}
      $variant={$variant}
      $height={$height}
      $transform={$transform}
      $fontSize={$fontSize}
      $padding={$padding}
    >
      {leadingIcon
        ? (
          <S.Icon>
            <Icon name={leadingIcon} size={12} color={getIconColor($variant)} />
          </S.Icon>
          )
        : null}
      {$shape !== 'dot' && children}
    </S.Badge>
  )
}
