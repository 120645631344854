import * as S from './field-error.styled'

interface FieldErrorProps {
  children: React.ReactNode
  id?: string
}

export const FieldError = ({ children, id }: FieldErrorProps): JSX.Element => {
  return <S.FieldError id={id}>{children}</S.FieldError>
}
