import { COLUMN } from 'src/components/tables/candidate-table-cells'
import type { TableColumnDef } from 'src/components/tables/candidate-table-cells'
import * as S from './table.styled'
import { Fragment, useCallback, useMemo } from 'react'
import { isNil } from 'lodash'
import { Button } from '../button'
import { IndeterminateCheckbox } from './IndeterminateCheckbox'
import { Flex } from '../flex'
import { Caption } from '../typography'
import { useAtomValue, useSetAtom } from 'jotai'
import { clearSelectedRowsAtom, selectAllRowsAtom, selectedRowsAtom, setTableSortAtom, sortAtom } from 'src/stores/table'

interface TableHeaderProps<T> {
  columns: Array<TableColumnDef<T>>
  setTableRows: React.Dispatch<React.SetStateAction<T[]>>
  tableRows: T[]
  originalTableData: T[]
  onToggleExpandableColumn?: (columnId: string) => void
  setColumns?: React.Dispatch<React.SetStateAction<Array<TableColumnDef<T>>>>
  selectionActions?: React.ReactNode
}

export const TableHeader = <T extends { id: string }>({
  columns,
  setTableRows,
  tableRows,
  originalTableData,
  onToggleExpandableColumn,
  setColumns,
  selectionActions
}: TableHeaderProps<T>): JSX.Element => {
  const sort = useAtomValue(sortAtom)
  const setTableSort = useSetAtom(setTableSortAtom)
  const selectedRows = useAtomValue(selectedRowsAtom)
  const clearSelectedRows = useSetAtom(clearSelectedRowsAtom)
  const selectAllRows = useSetAtom(selectAllRowsAtom)

  const sortTableRows = useCallback((sortFn: ((rowA: T, rowB: T) => number) | undefined, direction: 'asc' | 'desc' | undefined) => {
    if (!sortFn) {
      return
    }
    const sortedTableRows = [...originalTableData]
    if (direction === 'asc') {
      sortedTableRows.sort(sortFn)
    } else if (direction === 'desc') {
      sortedTableRows.sort((rowA, rowB) => sortFn(rowB, rowA))
    }
    setTableRows(sortedTableRows)
  }, [originalTableData, setTableRows])

  const { selectedRowsCount, allRowsSelected } = useMemo(() => {
    const selectedRowsCount = selectedRows.size
    return {
      selectedRowsCount,
      allRowsSelected: selectedRowsCount === tableRows.length
    }
  }, [selectedRows.size, tableRows.length])

  const handleExpandableColumnVisibility = useCallback((columnId: string) => {
    onToggleExpandableColumn?.(columnId)
    setColumns?.(prev => prev.map(column => {
      if (column.columnId === columnId) {
        return { ...column, visible: !column.visible }
      }
      return column
    }))
  }, [onToggleExpandableColumn, setColumns])

  const isActionSelected = useMemo(() => {
    return selectedRowsCount >= 1 && selectionActions
  }, [selectedRowsCount, selectionActions])

  const selectionColumn = useMemo(() => (
    <IndeterminateCheckbox
      checked={allRowsSelected}
      indeterminate={selectedRowsCount > 0}
      name={COLUMN.SELECTION_COLUMN_ID}
      onChange={() => {
        if (allRowsSelected) {
          // If all rows are selected, we want to clear the selection
          clearSelectedRows()
        } else {
          // If some rows are selected or none are selected, we want to select all rows
          selectAllRows(tableRows.map(row => row.id))
        }
      }}
    />
  ), [allRowsSelected, selectedRowsCount, clearSelectedRows, selectAllRows, tableRows])

  if (isActionSelected) {
    return (
      <S.Head>
        <tr>
          <S.ColumnHead>
            <S.ColumnHeadInner>
              {selectionColumn}
            </S.ColumnHeadInner>
          </S.ColumnHead>
          <S.HeadActions
            style={{
              gridColumn: `span ${columns.filter(c => c.visible).length - 1}`
            }}
          >
            <Caption size="XS" $whiteSpace="nowrap">
              {selectedRowsCount} selected
            </Caption>
            {selectionActions}
          </S.HeadActions>
        </tr>
      </S.Head>
    )
  }

  return (
    <S.Head>
      <tr>
        {columns.map((column) => {
          if (!column.visible) return null
          if (column.customizedHeader) {
            return <Fragment key={column.columnId}>{column.header}</Fragment>
          }
          let header = column.header
          if (column.columnId === COLUMN.SELECTION_COLUMN_ID) {
            header = selectionColumn
          }
          let sortButton = null
          if (!isNil(column.sortingFn)) {
            if (sort?.id === column.columnId) {
              if (sort.direction === 'asc') {
                sortButton = (
                  <Button
                    $variant="ghost"
                    $colorTheme="muted"
                    leadingIcon="arrow-up-narrow-wide"
                    $width={12}
                    $height={12}
                    iconSize={10}
                    onClick={() => {
                      setTableSort(undefined)
                      sortTableRows(column.sortingFn, undefined)
                    }}
                  />
                )
              } else {
                sortButton = (
                  <Button
                    $variant="ghost"
                    $colorTheme="muted"
                    leadingIcon="arrow-down-wide-narrow"
                    $width={12}
                    $height={12}
                    iconSize={10}
                    onClick={() => {
                      setTableSort({ id: column.columnId, direction: 'asc' })
                      sortTableRows(column.sortingFn, 'asc')
                    }}
                  />
                )
              }
            } else {
              sortButton = (
                <Button
                  $variant="ghost"
                  $colorTheme="muted"
                  leadingIcon="arrow-up-down"
                  $width={12}
                  $height={12}
                  iconSize={10}
                  onClick={() => {
                    setTableSort({ id: column.columnId, direction: 'desc' })
                    sortTableRows(column.sortingFn, 'desc')
                  }}
                />
              )
            }
          }
          let expandButton = null
          if (column.dependentExpandableColumnId) {
            const expandableColumn = columns.find(c => c.columnId === column.dependentExpandableColumnId)
            expandButton = (
              <Button
                $variant="flat"
                $colorTheme="muted"
                leadingIcon={expandableColumn?.visible ? 'chevrons-left' : 'chevrons-right'}
                $width={12}
                $height={12}
                iconSize={10}
                onClick={() => {
                  if (column.dependentExpandableColumnId) {
                    handleExpandableColumnVisibility(column.dependentExpandableColumnId)
                  }
                }}
              />
            )
          }
          return (
            <S.ColumnHead
              key={column.columnId}
              colSpan={column.colSpan ?? 1}
              style={{
                cursor: column.sortingFn ? 'pointer' : 'default'
              }}
              $hasDependentColumn={!!column.dependentExpandableColumnId}
            >
              <S.ColumnHeadInner>
                {header}
                {(!!sortButton || !!expandButton) && (
                  <Flex $align="center" $justify="space-between" $gap={8}>
                    {sortButton}
                    {expandButton}
                  </Flex>
                )}
              </S.ColumnHeadInner>
            </S.ColumnHead>
          )
        })}
      </tr>
    </S.Head>
  )
}
