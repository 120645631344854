import { Button } from 'src/components/primitives'
import { ButtonGroup } from 'src/components/primitives/button-group'
import { LocalStorageKey, ViewMode } from 'src/constants'
import { useTheme } from 'styled-components'
import { useLocalStorage } from 'usehooks-ts'

interface ToggleCandidateViewProps {
  disabled?: boolean
}

export const ToggleCandidateView = ({ disabled = false }: ToggleCandidateViewProps): JSX.Element => {
  const [currViewMode, setViewMode] = useLocalStorage(LocalStorageKey.VIEW_MODE, ViewMode.DEFAULT)
  const { colors } = useTheme()
  return (
    <ButtonGroup
      $variant="ghost"
      $gap={2}
      $direction="horizontal"
      $padding={2}
      $backgroundColor={colors.bgTertiary}
      $borderRadius={4}
    >
      <Button
        ariaLabel="Enable compact mode for candidate cards"
        $variant={currViewMode === ViewMode.COMPACT ? 'raised-light' : 'ghost'}
        // $colorTheme="muted"
        $height={24}
        $fontSize={12}
        leadingIcon="layout-list"
        disabled={disabled}
        onClick={() => {
          setViewMode?.(ViewMode.COMPACT)
        }}
        tooltip={{
          text: 'Compact View'
        }}
      />
      <Button
        ariaLabel="Enable details mode for candidate cards"
        $variant={currViewMode === ViewMode.DEFAULT ? 'raised-light' : 'ghost'}
        // $colorTheme="muted"
        $height={24}
        $fontSize={12}
        leadingIcon="layout-details"
        disabled={disabled}
        onClick={() => {
          setViewMode?.(ViewMode.DEFAULT)
        }}
        tooltip={{
          text: 'Detailed View'
        }}
      />
      <Button
        ariaLabel="Enable table mode for candidate cards"
        $variant={currViewMode === ViewMode.TABLE ? 'raised-light' : 'ghost'}
        // $colorTheme="muted"
        $height={24}
        $fontSize={12}
        leadingIcon="table-2"
        disabled={disabled}
        onClick={() => {
          setViewMode?.(ViewMode.TABLE)
        }}
        tooltip={{
          text: 'Table View'
        }}
      />
    </ButtonGroup>
  )
}
