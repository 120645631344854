import styled from 'styled-components'

export const Editor = styled.div`
  [contenteditable] ~ grammarly-extension {
    display: none;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  * {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    line-height: ${({ theme }) => theme.lineHeights['1.43']};
    letter-spacing: ${({ theme }) => theme.letterSpacings['0.12']};
    outline: none;
    .editor-link {
      color: ${({ theme }) => theme.colors.tintFg};
      text-decoration: underline;
    }
    ul {
      list-style-type: none;
      li {
        position: relative;
        padding-left: 1rem;
        &:after {
          content: '';
          position: absolute;
          top: 0.4rem;
          left: 0;
          width: 0.25rem;
          height: 0.25rem;
          border-radius: 999px;
          background-color: ${({ theme }) => theme.colors.fgSecondary};
        }
      }
    }
    ol {
      li {
        margin-left: 2rem;
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
    .editor-placeholder {
      position: relative;
      background-color: transparent;
      font-size: ${({ theme }) => theme.fontSizes[12]};
      font-weight: ${({ theme }) => theme.fontWeights[400]};
      color: ${({ theme }) => theme.colors.fgSecondary};
      margin: 0 0.35rem 0 0.25rem;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: -1px;
        left: -4px;
        width: calc(100% + 6px);
        height: calc(100% + 2px);
        border-radius: 4px;
        border: solid 1px;
        border-color: ${({ theme }) => theme.colors.fgTranslucent10};
        pointer-events: none;
      }
    }
    div.is-editor-empty:first-child::before {
      font-size: ${({ theme }) => theme.fontSizes[12]};
      font-weight: ${({ theme }) => theme.fontWeights[400]};
      color: ${({ theme }) => theme.colors.fgTertiary};
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
`

export const BubbleMenu = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.fgPrimary};
  border-radius: 6px;
  padding: 2px;
  gap: 2px;
`

export const EditorButton = styled.button<{ $isActive?: boolean }>`
  border: none;
  background-color: ${({ theme, $isActive }) =>
    theme.colors[$isActive ? 'fgSecondary' : 'fgPrimary']};
  color: ${({ theme }) => theme.colors.bgPrimary};
  font-size: ${({ theme }) => theme.fontSizes[12]};
  border-radius: 4px;
  padding: 0.35rem 0.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
`

export const EditorContent = styled.div<{ $editorHeight?: string, $minHeight?: string, $maxHeight?: string }>`
  padding: 0.75rem;
  overflow-y: auto;
  flex-grow: 1;
  min-height: inherit;
  max-height: inherit;
  height: ${({ $editorHeight }) => $editorHeight ?? '0'};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  min-height: ${({ $minHeight }) => $minHeight};
  max-height: ${({ $maxHeight }) => $maxHeight};
`

export const Toolbar = styled.div`
  width: 100%;
  // height: 2.5rem;
  display: flex;
  align-items: center;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  z-index: 2;
`

export const ToolbarActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.75rem;
`

export const EditorFooterContent = styled.div`
  padding: 0.75rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
`
