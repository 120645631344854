import { useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import type { User } from 'src/libs/api/backend/me'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSetMeQueryCache = () => {
  const queryClient = useQueryClient()

  return (fn: ((old: User) => User)) => {
    queryClient.setQueryData([queryKeys.me], fn)
  }
}
