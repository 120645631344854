import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import * as S from './calendar.styled'
import { addWeeks, startOfWeek, subWeeks } from 'date-fns'
import { Days } from './days'
import { Header } from './header'
import { Timezones } from './timezones'
import { useSession } from 'src/hooks/queries/use-session'
import { SIZES } from './constants'
import { Toolbar } from './toolbar'
import { useDetectScroll } from 'src/hooks/use-detect-scroll'
import type { CalendarEvent, NewCalendarEvent } from 'src/libs/api/backend/calendar_events'
import { useDeleteCalendarEvent } from 'src/hooks/mutations/use-delete-calendar-event'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { useCreateCalendarEvent } from 'src/hooks/mutations/use-create-calendar-event'
import type { EmailAccount } from 'src/libs/api/backend/users'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import { useQueryParams } from 'src/hooks/use-query-params'
import { isNil } from 'lodash'
import { useSetAtom } from 'jotai'
import { closeDialogAtom, DialogId, openAlertAtom } from 'src/stores/dialogs'

const minutesPerDay = 24 * 60

export interface CalendarProps {
  scheduleNewEvent?: {
    start: NewCalendarEvent['start']
    end: NewCalendarEvent['end']
  }
  candidateJob: CandidateJobExpanded
}

export const Calendar = ({ candidateJob, scheduleNewEvent }: CalendarProps): JSX.Element => {
  const { data: sessionData } = useSession()
  const { data: orgUsers } = useOrgUsersQuery()
  const sessionEmailAccount = useMemo(() => {
    if (isNil(sessionData) || isNil(orgUsers)) {
      return null
    }

    const emailAccount = sessionData.emailAccountAccessTokens.find(token => token.isPrimary) ?? sessionData.emailAccountAccessTokens[0]
    const orgUser = orgUsers.find(user => user.emailAccounts.some(account => account.email === emailAccount.email))
    return orgUser?.emailAccounts?.find(account => account.email === emailAccount.email)
  }, [orgUsers, sessionData])

  const [renderedWeek, setRenderedWeek] = useState(startOfWeek(new Date()))
  const [currentTimeIndicator, setCurrentTimeIndicator] = useState(0)
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null)
  const [selectedEmailAccount, setSelectedEmailAccount] = useState<EmailAccount | null>(sessionEmailAccount ?? null)
  const [newEvent, setNewEvent] = useState<NewCalendarEvent | null>(null)
  const { resetParam } = useQueryParams()

  const daysRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const toolbarRef = useRef<HTMLDivElement>(null)
  const calendarMainRef = useRef<HTMLDivElement>(null)
  const [daysHeight] = useState(SIZES.HOUR.LG * 24)

  const times = Array.from({ length: 24 }, (_, i) => i)
  const { data: session } = useSession()
  const userTimezone = session?.user?.timezone ?? ''
  const candidateTimezone = 'EST'

  const { hasScrolled } = useDetectScroll(calendarMainRef)
  const openAlert = useSetAtom(openAlertAtom)
  const closeDialog = useSetAtom(closeDialogAtom)
  const { deleteEvent } = useDeleteCalendarEvent()
  const { createEvent } = useCreateCalendarEvent()

  const calculateIndicatorPosition = (): void => {
    const now = new Date()
    const currentMinutes = now.getHours() * 60 + now.getMinutes()
    const minuteHeight = daysHeight / minutesPerDay
    const top = currentMinutes * minuteHeight
    setCurrentTimeIndicator(top + SIZES.SUBHEADER.LG)
  }

  const handleCreateEvent = (): void => {
    if (!newEvent) {
      return
    }
    const newEventData: NewCalendarEvent = {
      ...newEvent,
      isPinEvent: true
    }
    createEvent({ newEvent: newEventData })
    setNewEvent(null)
    closeDialog(DialogId.CALENDAR)
    resetParam('startDateTime')
    resetParam('endDateTime')
  }

  useEffect(() => {
    calculateIndicatorPosition()
    const intervalId = setInterval(calculateIndicatorPosition, 60000)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useLayoutEffect(() => {
    if (calendarMainRef.current && !hasScrolled) {
      calendarMainRef.current.scrollTop = currentTimeIndicator - window.innerHeight / 2
    }
  }, [currentTimeIndicator, hasScrolled])

  useEffect(() => {
    const down = (event: KeyboardEvent): void => {
      if (!selectedEvent?.id) {
        return
      }
      if (event.key === 'Backspace' && selectedEvent?.id) {
        event.preventDefault()
        openAlert({
          message: 'Are you sure you want to delete this event?',
          description: 'This action can not be reversed',
          cancelText: 'Cancel',
          confirmText: 'Delete',
          onConfirm: () => {
            deleteEvent({ id: selectedEvent?.id ?? '' })
            setSelectedEvent(null)
          }
        })
      }
    }

    document.addEventListener('keydown', down)
    return () => {
      document.removeEventListener('keydown', down)
    }
  }, [selectedEvent])

  useEffect(() => {
    if (scheduleNewEvent?.start && scheduleNewEvent?.end && calendarMainRef.current) {
      const weekStart = startOfWeek(scheduleNewEvent.start, { weekStartsOn: 1 })
      setRenderedWeek(weekStart)

      const startMinutes = scheduleNewEvent.start.getHours() * 60 + scheduleNewEvent.start.getMinutes()
      const endMinutes = scheduleNewEvent.end.getHours() * 60 + scheduleNewEvent.end.getMinutes()

      const minuteHeight = daysHeight / (24 * 60)
      calendarMainRef.current.scrollTop = startMinutes * minuteHeight

      const newEventData = {
        id: crypto.randomUUID(),
        isPinEvent: true,
        title: `${candidateJob.candidate.name} / ${sessionData?.user?.name} (${sessionData?.org?.name})`,
        startPosition: startMinutes,
        endPosition: endMinutes,
        start: scheduleNewEvent.start,
        end: scheduleNewEvent.end,
        candidateJobId: candidateJob?.id,
        attendees: [
          {
            address: selectedEmailAccount?.email ?? '',
            name: selectedEmailAccount?.email ?? ''
          },
          {
            address: candidateJob.candidate.emails?.[0] ?? '',
            name: candidateJob?.candidate?.name
          }
        ]
      }

      setNewEvent(newEventData)
      setSelectedEvent(newEventData as unknown as CalendarEvent)
    }
  }, [scheduleNewEvent, candidateJob, selectedEmailAccount, daysHeight, sessionData])

  return (
    <S.Calendar>
      <Header
        ref={headerRef}
        selectedEvent={selectedEvent}
        renderedWeek={renderedWeek}
        onGoToPrevWeek={() => {
          setRenderedWeek((prev) => subWeeks(prev, 1))
        }}
        onGoToNextWeek={() => {
          setRenderedWeek((prev) => addWeeks(prev, 1))
        }}
        onGoToToday={() => {
          setRenderedWeek(startOfWeek(new Date()))
        }}
        selectedEmailAccount={selectedEmailAccount}
        onSelectEmailAccount={(emailAccount) => {
          setSelectedEmailAccount(emailAccount)
        }}
        onAttendeesChange={(attendees) => {
          const updated = {
            ...newEvent,
            isPinEvent: true,
            attendees
          }
          setNewEvent(updated as NewCalendarEvent)
          setSelectedEvent(updated as unknown as CalendarEvent)
        }}
        candidateJob={candidateJob}
      />
      <S.Inner ref={calendarMainRef}>
        <S.CurrentTimeIndicator
          data-name="current-time"
          style={{ top: `${currentTimeIndicator}px` }}
        />
        <Timezones timezone={userTimezone} comparisonTimezone={candidateTimezone} times={times} />
        <Days
          ref={daysRef}
          candidateJob={candidateJob}
          renderedWeek={renderedWeek}
          times={times}
          daysHeight={daysHeight}
          newEvent={newEvent}
          onSetNewEvent={(newEvent) => { setNewEvent(newEvent) }}
          selectedEvent={selectedEvent}
          selectedEmailAccount={selectedEmailAccount}
          onSelectEvent={(e) => {
            setSelectedEvent(e)
          }}
          onResetSelectedEvent={() => {
            setSelectedEvent(null)
          }}
          headerRef={headerRef}
          toolbarRef={toolbarRef}
        />
      </S.Inner>
      <Toolbar
        newEvent={newEvent}
        selectedEvent={selectedEvent}
        onResetSelectedEvent={() => {
          setNewEvent(null)
          setSelectedEvent(null)
        }}
        onSendInvite={handleCreateEvent}
        ref={toolbarRef}
        candidateJob={candidateJob}
      />
    </S.Calendar>
  )
}
