import type { Color, Spacing } from 'src/styles/theme/types'
import * as S from './divider.styled'
import { Caption } from '../typography'

export interface DividerStyleProps {
  $orientation?: 'vertical' | 'horizontal'
  $color?: Color
  $spacing?: { before?: Spacing, after?: Spacing }
}

export interface DividerProps extends DividerStyleProps {
  children?: string
}

export const Divider = ({
  $color = 'borderTranslucent',
  $orientation = 'horizontal',
  $spacing = { before: 0, after: 0 },
  children
}: DividerProps): JSX.Element => {
  if (children) {
    return (
      <S.DividerWithText
        aria-orientation={$orientation}
        $orientation={$orientation}
        $spacing={$spacing}
      >
        <S.Line />
        {children && (
          <S.Inner>
            <Caption size="2XS" $color="fgTertiary">
              {children}
            </Caption>
          </S.Inner>
        )}
        <S.Line />
      </S.DividerWithText>
    )
  }

  return (
    <S.Divider
      aria-orientation={$orientation}
      $color={$color}
      $orientation={$orientation}
      $spacing={$spacing}
    />
  )
}
