import { useQuery } from '@tanstack/react-query'
import { fetchJobStats } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'

interface Opts {
  enabled?: boolean
}

export const jobStatsQuery = ({ enabled = true }: Opts = {}) => ({
  queryKey: [queryKeys.jobStats],
  queryFn: fetchJobStats,
  staleTime: 60 * 1000,
  enabled
})

export const useJobStatsQuery = (opts = {}) => {
  return useQuery(jobStatsQuery(opts))
}
