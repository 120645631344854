import * as S from './form.styled'
import type { FormErrors } from 'src/hooks/use-form'

type SubmitHandler = (event: React.FormEvent<HTMLFormElement>) => void

interface FormProps {
  children: React.ReactNode
  onSubmit?: SubmitHandler | (() => SubmitHandler)
  errors?: FormErrors
}

export const Form = ({ children, onSubmit }: FormProps): JSX.Element => {
  return <S.Form onSubmit={onSubmit}>{children}</S.Form>
}
