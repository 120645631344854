export const queryKeys = {
  avatars: 'avatars',
  billingCheckout: 'billingCheckout',
  billingPricing: 'billingPricing',
  calendar: 'calendar',
  calendarAvailabilityPreference: 'calendarAvailabilityPreference',
  candidate: 'candidate',
  candidateActivities: 'candidateActivities',
  candidateJob: 'candidateJob',
  candidateJobCounts: 'candidateJobCounts',
  candidateJobs: 'candidates',
  candidatesByLinkedinUrls: 'candidatesByLinkedinUrls',
  candidateSequencePause: 'candidateSequencePause',
  candidateSequenceStepMessageReviews: 'candidateSequenceStepMessageReviews',
  companyLogo: 'companyLogo',
  companyPreferences: 'companyPreferences',
  companySuggestions: 'companySuggestions',
  createJobSequence: 'createJobSequence',
  customerSubscriptions: 'customerSubscriptions',
  customOutreach: 'customOutreach',
  departments: 'departments',
  projects: 'projects',
  existingOrgs: 'existingOrgs',
  job: 'orgJob',
  jobByDepartments: 'jobByDepartments',
  jobListings: 'jobListings',
  jobs: 'orgJobs',
  jobSearchRefinements: 'jobSearchRefinements',
  jobSequence: 'jobSequence',
  jobStats: 'jobStats',
  locationSuggestions: 'locationSuggestions',
  me: 'me',
  linkedInAccounts: 'meLinkedInAccounts',
  mentionedTimesAvailability: 'mentionedTimesAvailability',
  mergeIntegrations: 'mergeIntegrations',
  mergeJobs: 'mergeJobs',
  mergeLinkJobs: 'mergeLinkJobs',
  notifications: 'notifications',
  notificationsCount: 'notificationsCount',
  notificationsPreferences: 'notificationsPreferences',
  orgInviteDirectory: 'orgInviteDirectory',
  orgInvites: 'orgInvites',
  orgJoinRequests: 'orgJoinRequests',
  orgTrialSummary: 'orgTrialSummary',
  orgUsers: 'orgUsers',
  schoolSuggestions: 'schoolSuggestions',
  session: 'session',
  suggestedResponse: 'suggestedResponse',
  teamMembersToBeInvited: 'orgInvitees',
  jobSequenceState: 'jobSequenceState',
  atsIntegrationDetails: 'atsIntegrationDetails'
} as const
