import { useMutation, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { createOrg as createOrgApi } from 'src/libs/api/backend/orgs'
import type { CreateOrg } from 'src/libs/api/backend/orgs'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom } from 'src/stores/notifications'

interface Args {
  org: CreateOrg
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  createOrg: ({ org, onSuccess, onError }: Args) => void
}

export const useCreateOrg = (): Res => {
  const queryClient = useQueryClient()

  const notifyError = useSetAtom(notifyErrorAtom)

  const mutation = useMutation({
    mutationFn: async ({ org }: Args) => await createOrgApi(org),
    onError: (err, variables) => {
      notifyError({
        message: `An error occurred when creating your organization: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.session]
      })
      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })

  const createOrg = (args: Args): void => {
    mutation.mutate(args)
  }

  return { createOrg }
}
