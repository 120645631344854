import { OnboardingNavigation } from 'src/components/blocks/onboarding-navigation'
import { Outlet, useLocation } from 'react-router-dom'
import * as S from './onboarding.styled'
import { getCurrentOnboardingStep } from 'src/utils/get-current-onboarding-step'

export const OnboardingLayout = (): JSX.Element => {
  const { pathname } = useLocation()
  return (
    <S.OnboardingLayout>
      <OnboardingNavigation />
      <S.Inner>
        <S.Content>
          <S.MediaColumn>
            <img
              src={`/${getCurrentOnboardingStep(pathname)?.image}`}
              alt={`Illustration showing off ${getCurrentOnboardingStep(pathname)?.title}`}
            />
          </S.MediaColumn>
          <S.FormColumn>
            <S.FormColumnInner>
              <Outlet />
            </S.FormColumnInner>
          </S.FormColumn>
        </S.Content>
      </S.Inner>
    </S.OnboardingLayout>
  )
}
