import { useEffect, useState } from 'react'
import { FieldLabel } from '../field-label'
import { Text } from 'src/components/primitives/text'
import type { RegisterField } from 'src/hooks/use-form'
import { Flex } from 'src/components/primitives/flex'
import { Toggle } from 'src/components/primitives/toggle'
import { Spacer } from 'src/components/primitives/spacer'
import { Button } from 'src/components/primitives/button'
import { When } from 'src/components/blocks/when'
import * as S from './switch.styled'

type Variant = 'plain' | 'outline' | 'raised'

export interface SwitchProps {
  $variant?: Variant
  defaultChecked?: boolean
  name: string
  label: string
  labelColor?: string
  description?: string
  register: (name: string) => RegisterField
  tooltip?: string
}

export const Switch = ({
  $variant = 'plain',
  defaultChecked = false,
  name,
  label,
  labelColor,
  description,
  register,
  tooltip
}: SwitchProps): JSX.Element => {
  const [checked, setChecked] = useState(defaultChecked)
  const { onSelect } = register(name)

  useEffect(() => {
    setChecked(defaultChecked)
  }, [defaultChecked])

  const handleChecked = (): void => {
    const updated = !checked
    setChecked(updated)
    onSelect(updated)
  }
  return (
    <S.Switch $variant={$variant}>
      <When condition={Boolean(tooltip)}>
        <>
          <Button
            nested
            $variant="ghost"
            $colorTheme="muted"
            $height={16}
            $width={16}
            $fontSize={12}
            leadingIcon="help-circle"
            ariaLabel="Get help"
            tooltip={{
              text: tooltip
            }}
          />
          <Spacer $size={20} $direction="row" />
        </>
      </When>
      <Toggle name={name} checked={checked} onChange={() => { handleChecked() }} />
      <Flex $direction="column">
        <FieldLabel
          htmlFor={name}
          label={label}
          $color={labelColor}
          $fontSize={14}
          $lineHeight={1.33}
          marginBottom={0}
        />
        {description
          ? (
            <>
              <Spacer $size={4} />
              <Text $size={12} $color="fgSecondary">
                {description}
              </Text>
            </>
            )
          : null}
      </Flex>
      <S.Input
        type="checkbox"
        aria-hidden="true"
        tabIndex={-1}
        value={checked ? 'on' : 'off'}
        checked={checked}
        readOnly
      />
    </S.Switch>
  )
}
