import styled from 'styled-components'

export const OnboardingNavigation = styled.nav`
  width: 100%;
  height: 56px;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  padding: 0.625rem 2.5rem 0.625rem 2.5rem;
`

export const Inner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4.5rem;
`

export const CurrentStep = styled.div`
  flex: 1 1 80%;
  display: flex;
  align-items: center;
  gap: 0.625rem;
`

export const CurrentStepDivider = styled.div`
  width: 2px;
  height: 2px;
  border-radius: 99px;
  background-color: ${({ theme }) => theme.colors.fgTertiary};
`
