import styled from 'styled-components'

export const NotificationTile = styled.div<{ $isUnread: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[8]};
  background-color: ${({ theme, $isUnread }) =>
    $isUnread ? theme.colors.warningTranslucent5 : 'inherit'};
  width: 100%;
`

export const NotificationTileHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[10]};
  padding: ${({ theme }) => theme.spacing[8]};
  justify-content: space-between;
  width: 100%;
`

export const NotificationTileTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[10]};
  flex: 1;
  overflow: hidden;
`

export const NotificationTileActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
  padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[8]} ${theme.spacing[4]} ${theme.spacing[48]}`};
`

export const LinkAction = styled.a`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
  padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[6]}`};
  border-radius: ${({ theme }) => theme.spacing[4]};
  background-color: ${({ theme }) => theme.colors.fgTranslucent5};
  cursor: default;
  height: 28px;
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.fgTranslucent10};
    }
  }
`
