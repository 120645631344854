import styled from 'styled-components'

export const InputArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.fgPrimary};
  resize: none;
  outline: none;
  transition: border-color 0.2s ease;
  overflow-y: auto;
  scrollbar-width: thin;
  &:focus {
    border-color: ${({ theme }) => theme.colors.borderOpaque};
  }
`
