import styled from 'styled-components'
import type { InputHTMLAttributes, TextareaHTMLAttributes } from 'react'

type CommonFieldProps = InputHTMLAttributes<HTMLInputElement> & TextareaHTMLAttributes<HTMLTextAreaElement>

export const CommonFieldStyles = styled.input.attrs<CommonFieldProps>(() => ({}))<CommonFieldProps>`
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: 0.625rem;
  font-size: ${({ theme }) => theme.fontSizes[14]};
  color: ${({ theme }) => theme.colors.fgPrimary};
  &:placeholder {
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
`
