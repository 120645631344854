import { ConnectToLinkedInSteps } from 'src/components/blocks/connect-to-linkedin-steps'
import type { ConnectToLinkedInStepsProps } from 'src/components/blocks/connect-to-linkedin-steps'

interface ConnectToLinkedInPageProps extends ConnectToLinkedInStepsProps {}

const ConnectToLinkedInPage = ({ step }: ConnectToLinkedInPageProps): JSX.Element => {
  return <ConnectToLinkedInSteps step={step} />
}

export default ConnectToLinkedInPage
