import { useQueryClient } from '@tanstack/react-query'
import { isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { acceptInvite } from 'src/libs/api/backend/invites'
import { queryKeys } from 'src/libs/query-keys'

export const useAcceptInvite = (inviteId: string, token: string): { isLoading: boolean, data: boolean | null, error: string | null } => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [data, setData] = useState<boolean | null>(null)
  const [error, setError] = useState<string | null>(null)
  const queryClient = useQueryClient()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (isNil(inviteId) || isNil(token)) {
        return
      }

      try {
        await acceptInvite(inviteId, token)
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.session]
        })
        setIsLoading(false)
        setError(null)
        setData(true)
      } catch (e) {
        setIsLoading(false)
        setData(null)
        setError('Invalid invite')
      }
    }

    void fetchData()
  }, [inviteId, queryClient, token])

  return { isLoading, data, error }
}
