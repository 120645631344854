import { Outlet } from 'react-router-dom'
import GlobalProvider from 'src/providers/global'

const Root = (): JSX.Element => {
  return (
    <GlobalProvider globalState={{}}>
      <Outlet />
    </GlobalProvider>
  )
}

export default Root
