import { useQuery } from '@tanstack/react-query'
import { fetchUserNotificationsCount } from 'src/libs/api/backend/notifications'
import { queryKeys } from 'src/libs/query-keys'

export const userNotificationsCountQuery = () => ({
  queryKey: [queryKeys.notificationsCount],
  queryFn: async () => await fetchUserNotificationsCount(),
  staleTime: 60 * 1000
})

export const useUserNotificationsCountQuery = () => {
  return useQuery(userNotificationsCountQuery())
}
