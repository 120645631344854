import * as S from './applicants-ats.styled'
import { Suspense } from 'react'
import type { ApplicantsLoaderData } from 'src/libs/loaders'
import { Await, useLoaderData } from 'react-router-dom'
import { ATSJobMerge } from './ats-job-merge'
import { LoadingSkeleton } from '../loading-skeleton'

export const ApplicantsATS = (): JSX.Element => {
  const { mergeIntegrations, mergeJobs, atsJobIntegrationDetails } = useLoaderData() as ApplicantsLoaderData
  return (
    <S.ApplicantsATSContainer>
      <Suspense fallback={<LoadingSkeleton $variant='ATSApplicants' />}>
        <Await resolve={
          Promise.all([mergeIntegrations, mergeJobs, atsJobIntegrationDetails])
        }>
          {([mergeIntegrations]) => {
            return (
              <ATSJobMerge
                mergeIntegrations={mergeIntegrations}
              />
            )
          }}
        </Await>
      </Suspense>
    </S.ApplicantsATSContainer>
  )
}
