import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

export const SettingsCard = styled.div<{ $isActive: boolean }>`
  width: 100%;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 6px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  /* grid-column-gap: 4rem; */
  border: solid 1px;
  ${({ $isActive, theme }) =>
    $isActive &&
    `
    border-color: ${theme.colors.positiveTranslucent25};
  `}
  ${({ $isActive, theme }) =>
    !$isActive &&
    `
    /* This is required to avoid visual element "jumps" between */
    /* active states */
    border-color: transparent;
    box-shadow: ${theme.boxShadows.sm};
  `}
`

export const CardMain = styled.div`
  grid-column: 1 / 6;
  display: flex;
  gap: 1rem;
`

export const Action = styled.div`
  grid-column: 6;
  justify-self: flex-end;
  align-self: center;
`

export const Details = styled.div`
  grid-column: 1 / 7;
  grid-row: 2;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  padding: 1rem;
  margin-top: 1.25rem;
  border-radius: 8px;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
`

export const DetailsBlock = styled.div<{ $columnSpan: number }>`
  grid-column: ${({ $columnSpan }) => `span ${$columnSpan}`};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
