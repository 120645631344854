import { Caption } from 'src/components/primitives/typography'
import * as S from './day-availability-picker.styled'
import { Toggle } from 'src/components/primitives/toggle'
import { When } from '../when'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isNil } from 'lodash'
// import { useOnClickOutside } from 'usehooks-ts'

type Availability = number[]
type Hours = number[]

interface AvailabilityPickerProps {
  day: number
  defaultAvailability?: Availability
  onAvailabilityChange: (availability: Availability) => void
}

interface RangeHandleArgs {
  range: number
  handler: 'start' | 'end'
}

interface AvailabilityRange {
  start: number
  end: number
}

const HandlerIcon = (): JSX.Element => {
  return (
    <svg
      width="3"
      height="16"
      viewBox="0 0 3 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.25">
        <path d="M2 0H3V16H2V0Z" fill="#2563EB" />
        <path d="M0 0H1V16H0V0Z" fill="#2563EB" />
      </g>
    </svg>
  )
}

export const DayAvailabilityPicker = ({
  day,
  defaultAvailability,
  onAvailabilityChange
}: AvailabilityPickerProps): JSX.Element => {
  const [selectedHours, setSelectedHours] = useState<Hours>([0])
  const [ranges, setRanges] = useState<AvailabilityRange[]>([{ start: 0, end: 0 }])
  const [isDragging, setIsDragging] = useState(false)
  const [selectedRange, setSelectedRange] = useState<number | null>(null)
  const [newRange, setNewRange] = useState({
    isCreating: false,
    start: null as number | null,
    end: null as number | null
  })

  const rootEl = useRef<HTMLDivElement>(null)
  const stepsEl = useRef<HTMLDivElement>(null)
  const rangeRefs = useRef<Array<HTMLDivElement | null>>([])

  // useOnClickOutside(rootEl, () => { setSelectedRange(null) })

  const dayIsEnabled = useMemo(() => selectedHours.length >= 2, [selectedHours])

  // Calculate ranges out of hours
  useEffect(() => {
    if (!isNil(defaultAvailability) && defaultAvailability.length > 0 && selectedHours[0] === 0) {
      const hours = defaultAvailability.map(hour => hour % 24)
      hours.sort((a, b) => a - b)

      const newSelectedHours = []
      let start = hours[0]

      for (let i = 0; i < hours.length; i++) {
        if (i === hours.length - 1 || hours[i] + 1 !== hours[i + 1]) {
          newSelectedHours.push(start)
          newSelectedHours.push(hours[i] + 1)
          start = hours[i + 1]
        }
      }
      setSelectedHours(newSelectedHours)
    }
  }, [defaultAvailability, day])

  useEffect(() => {
    const updatedAvailability = []

    for (let i = 0; i < selectedHours.length; i += 2) {
      const startHour = selectedHours[i]
      const endHour = selectedHours[i + 1]
      for (let hour = startHour; hour < endHour; hour++) {
        updatedAvailability.push(day * 24 + hour)
      }
    }
    if (!isDragging) {
      onAvailabilityChange(updatedAvailability)
    }
  }, [selectedHours, day, isDragging])

  const getWeekDay = (): string => {
    switch (day) {
      case 1:
        return 'Mon'
      case 2:
        return 'Tue'
      case 3:
        return 'Wed'
      case 4:
        return 'Thu'
      case 5:
        return 'Fri'
      case 6:
        return 'Sat'
      default:
        return 'Sun'
    }
  }

  const calculateRanges = (hours: Hours): AvailabilityRange[] => {
    const trackWidth = stepsEl.current?.getBoundingClientRect().width ?? 0
    const hourWidth = trackWidth / 24
    const ranges = []
    for (let i = 0; i < hours.length; i += 2) {
      ranges.push({
        start: hours[i] * hourWidth,
        end: hours[i + 1] * hourWidth || (hours[i] + 1) * hourWidth
      })
    }
    return ranges
  }

  useEffect(() => {
    const calculated = calculateRanges(selectedHours)
    setRanges(calculated)
  }, [selectedHours])

  const handleUpdateStartAndEndTimes = ({ range, handler }: RangeHandleArgs) => (event: React.MouseEvent) => {
    event.preventDefault()
    setIsDragging(true)
    const initialX = event.clientX
    const initialHours = [...selectedHours]
    const trackWidth = stepsEl.current?.getBoundingClientRect().width ?? 0
    const hourWidth = trackWidth / 24
    const hourIndex = handler === 'start' ? range * 2 : range * 2 + 1

    const onMouseMove = (moveEvent: MouseEvent): void => {
      const deltaX = moveEvent.clientX - initialX
      const deltaHours = Math.round(deltaX / hourWidth)

      const updatedHours = [...initialHours]
      if (handler === 'start') {
        updatedHours[hourIndex] = Math.min(Math.max(0, initialHours[hourIndex] + deltaHours), initialHours[hourIndex + 1] - 1)
        // Remove the range if the user makes it smaller than 1 hour
        if (updatedHours[hourIndex + 1] - updatedHours[hourIndex] < 1) {
          updatedHours.splice(hourIndex, 2)
        }
      } else {
        updatedHours[hourIndex] = Math.max(Math.min(24, initialHours[hourIndex] + deltaHours), initialHours[hourIndex - 1] + 1)
        // Remove the range if the user makes it smaller than 1 hour
        if (updatedHours[hourIndex] - updatedHours[hourIndex - 1] < 1) {
          updatedHours.splice(hourIndex - 1, 2)
        }
      }

      // Check for overlapping ranges and merge if they overlap
      if (range > 0 && handler === 'start') {
        const prevEndIndex = (range - 1) * 2 + 1
        if (updatedHours[hourIndex] <= updatedHours[prevEndIndex]) {
          updatedHours[prevEndIndex] = updatedHours[hourIndex + 1]
          updatedHours.splice(range * 2, 2)
        }
      } else if (range < selectedHours.length / 2 - 1 && handler === 'end') {
        const nextStartIndex = (range + 1) * 2
        if (updatedHours[hourIndex] >= updatedHours[nextStartIndex]) {
          updatedHours[hourIndex] = updatedHours[nextStartIndex + 1]
          updatedHours.splice(nextStartIndex, 2)
        }
      }

      if (updatedHours[hourIndex] !== initialHours[hourIndex]) {
        setSelectedHours(updatedHours)
      }
    }

    const onMouseUp = (): void => {
      setIsDragging(false)
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
  }

  const handleMoveHourRange = (rangeIndex: number) => (event: React.MouseEvent) => {
    event.preventDefault()
    setIsDragging(true)
    setSelectedRange(rangeIndex)
    const initialX = event.clientX
    const initialHours = [...selectedHours]
    const trackWidth = stepsEl.current?.getBoundingClientRect().width ?? 0
    const hourWidth = trackWidth / 24

    const startHourIndex = rangeIndex * 2
    const endHourIndex = rangeIndex * 2 + 1
    const rangeDuration = initialHours[endHourIndex] - initialHours[startHourIndex]

    const onMouseMove = (moveEvent: MouseEvent): void => {
      const deltaX = moveEvent.clientX - initialX
      const deltaHours = Math.round(deltaX / hourWidth)

      const updatedHours = [...initialHours]
      updatedHours[startHourIndex] = Math.max(0, initialHours[startHourIndex] + deltaHours)
      updatedHours[endHourIndex] = Math.min(24, updatedHours[startHourIndex] + rangeDuration)

      setSelectedHours(updatedHours)
    }

    const onMouseUp = (): void => {
      setSelectedHours(prevSelectedHours => {
        const updatedHours = [...prevSelectedHours]

        // Merge with previous range when they overlap
        if (rangeIndex > 0) {
          const prevEndIndex = (rangeIndex - 1) * 2 + 1
          const prevStartIndex = (rangeIndex - 1) * 2
          if (updatedHours[startHourIndex] <= updatedHours[prevEndIndex] && updatedHours[endHourIndex] >= updatedHours[prevStartIndex]) {
            updatedHours[prevEndIndex] = updatedHours[endHourIndex]
            updatedHours.splice(startHourIndex, 2)
          }
        }

        // Merge with next range when they overlap
        if (rangeIndex < updatedHours.length / 2 - 1) {
          const nextStartIndex = (rangeIndex + 1) * 2
          const nextEndIndex = (rangeIndex + 1) * 2 + 1
          if (updatedHours[endHourIndex] >= updatedHours[nextStartIndex] && updatedHours[startHourIndex] <= updatedHours[nextEndIndex]) {
            updatedHours[endHourIndex] = updatedHours[nextEndIndex]
            updatedHours.splice(nextStartIndex, 2)
          }
        }

        return updatedHours
      })

      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
      setIsDragging(false)
      setSelectedRange(null)
    }

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
  }

  const handleEnableDay = useCallback(() => {
    const shouldEnable = !dayIsEnabled
    if (shouldEnable) {
      setSelectedHours([10, 17])
    } else {
      setSelectedHours([0])
    }
  }, [dayIsEnabled])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Backspace' && !isNil(selectedRange)) {
        event.preventDefault()

        setSelectedHours(prevSelectedHours => {
          const updatedHours = [...prevSelectedHours]
          const startHourIndex = selectedRange * 2
          updatedHours.splice(startHourIndex, 2)
          return updatedHours
        })

        setSelectedRange(null)
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedRange])

  const handleStartCreatingRange = (start: number) => (event: React.MouseEvent) => {
    event.preventDefault()
    setNewRange({
      isCreating: true,
      start,
      end: start
    })
  }

  const handleCreatingRange = (end: number): void => {
    setNewRange(prev => ({
      ...prev,
      end: prev.isCreating ? Math.min(end + 1, 24) : prev.end
    }))
  }

  const handleStopCreatingRange = (): void => {
    if (newRange.isCreating && !isNil(newRange.start) && !isNil(newRange.end)) {
      let updatedHours = [...selectedHours]

      // Find overlapping ranges and merge if they overlap
      let mergedStart = newRange.start
      let mergedEnd = newRange.end

      updatedHours = updatedHours.reduce<number[]>((acc, _, i) => {
        if (i % 2 === 0) {
          const currentStart = updatedHours[i]
          const currentEnd = updatedHours[i + 1]

          if (
            // Possibility 1: New start overlaps with existing range
            (mergedStart >= currentStart && mergedStart <= currentEnd) ||
            // Possibility 2: New end overlaps with existing range
            (mergedEnd >= currentStart && mergedEnd <= currentEnd) ||
            // Possibility 3: New range is longer than an existing range
            (mergedStart <= currentStart && mergedEnd >= currentEnd)
          ) {
            // Merge the ranges
            mergedStart = Math.min(mergedStart, currentStart)
            mergedEnd = Math.max(mergedEnd, currentEnd)
          } else {
            // If there's no overlap, keep the existing range
            acc.push(currentStart, currentEnd)
          }
        }
        return acc
      }, [])

      // Add the merged range and sort
      updatedHours.push(mergedStart, mergedEnd)
      updatedHours.sort((a, b) => a - b)
      setSelectedHours(updatedHours)
    }

    setNewRange({
      isCreating: false,
      start: null,
      end: null
    })
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleStopCreatingRange)
    return () => {
      document.removeEventListener('mouseup', handleStopCreatingRange)
    }
  }, [newRange])

  // Reset the selected range when clicking anywhere
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        selectedRange !== null &&
        rangeRefs.current[selectedRange] &&
        !rangeRefs.current[selectedRange]?.contains(event.target as Node)
      ) {
        setSelectedRange(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [selectedRange])

  return (
    <S.Wrapper>
      <Caption size="XS" $color="fgSecondary">
        {getWeekDay()}
      </Caption>
      <S.SliderRoot ref={rootEl}>
        <S.SliderTrack>
          <When condition={dayIsEnabled}>
            <S.AddRangeTrack>
              {newRange.isCreating && !isNil(newRange.start) && !isNil(newRange.end) && (
                <S.NewRange
                  $start={`${newRange.start * (100 / 24)}%`}
                  $width={`calc(${(newRange.end - newRange.start) * (100 / 24)}% + 1px)`}
                />
              )}
              {Array.from({ length: 24 }, (_, index) => (
                <>
                  {!isDragging && (
                    <S.AddRangeButton
                      key={index}
                      onMouseDown={handleStartCreatingRange(index)}
                      onMouseMove={() => { handleCreatingRange(index) }}
                      $isVisible={!newRange.isCreating}
                    />
                  )}
                </>
              ))}
            </S.AddRangeTrack>
            <S.SliderSteps ref={stepsEl}>
              {Array.from({ length: 24 }, (_, index) => (
                <S.SliderStep key={index} $isHidden={index === selectedHours[1]} />
              ))}
            </S.SliderSteps>
            {ranges.map((range, index) => {
              const isColliding = (selectedHours[index * 2 + 1] - selectedHours[index * 2]) <= 2
              if (range.start === 0 && range.end === 0) {
                return <></>
              }
              return (
                <S.Range
                  key={index}
                  ref={el => (rangeRefs.current[index] = el)}
                  $start={range.start}
                  $width={range.end - range.start}
                  $isGrabbing={index === selectedRange}
                  onClick={() => { setSelectedRange(index) }}
                >
                  <S.RangeInner onMouseDown={handleMoveHourRange(index)} />
                  <S.RangeHandle
                    $handle="left"
                    $hour={selectedHours[index * 2]}
                    $isColliding={isColliding}
                    onMouseDown={handleUpdateStartAndEndTimes({ range: index, handler: 'start' })}
                  >
                    <HandlerIcon />
                  </S.RangeHandle>
                  <S.RangeHandle
                    $handle="right"
                    $hour={selectedHours[index * 2 + 1]}
                    $isColliding={isColliding}
                    onMouseDown={handleUpdateStartAndEndTimes({ range: index, handler: 'end' })}
                  >
                    <HandlerIcon />
                  </S.RangeHandle>
                </S.Range>
              )
            })}
          </When>
        </S.SliderTrack>
      </S.SliderRoot>
      <Toggle name="enabled" checked={dayIsEnabled} onChange={handleEnableDay} />
    </S.Wrapper>
  )
}
