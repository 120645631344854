import { useMutation } from '@tanstack/react-query'
import { createOrgInvite } from 'src/libs/api/backend/invites'
import type { NewInvite } from 'src/libs/api/backend/invites'

interface Args {
  invitees: NewInvite[]
}

interface Res {
  inviteUsers: ({ invitees }: Args) => void
}

export const useOrgInviteUsers = (): Res => {
  const mutation = useMutation({
    mutationFn: async ({ invitees }: Args) => {
      await createOrgInvite(invitees)
    },
    onError: () => {

    },
    onSuccess: () => {

    }
  })

  const inviteUsers = ({ invitees }: Args): void => {
    mutation.mutate({ invitees })
  }

  return { inviteUsers }
}
