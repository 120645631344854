import styled from 'styled-components'

export const CalendarIcon = styled.div`
  width: 2.25rem;
  height: 2.25rem;
  flex: 0 0 2.25rem;
  border-radius: 4px;
  overflow: hidden;
`

export const Month = styled.div`
  height: 1rem;
  background-color: ${({ theme }) => theme.colors.negativeBg};
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: ${({ theme }) => theme.fontSizes[10]};
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #fff;
  }
`

export const Day = styled.div`
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  border-top: none;
  span {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.12px;
    color: ${({ theme }) => theme.colors.fgPrimary};
  }
`
