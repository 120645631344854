import type { FieldCommonStyleProps } from '../common'
import * as S from './form-element.styled'

interface FormElementProps extends FieldCommonStyleProps {
  children: React.ReactNode
}

export const FormElement = ({ children, $marginBottom = 16, $width, $flex = '1 1 100%' }: FormElementProps): JSX.Element => {
  return <S.FormElement $marginBottom={$marginBottom} $width={$width} $flex={$flex}>{children}</S.FormElement>
}
