import { z } from 'zod'
import Api from '..'

const billingCheckoutParser = z.object({
  pricingTableId: z.string(),
  stripePublishableKey: z.string(),
  customerSessionClientSecret: z.string()
})

export type BillingCheckout = z.infer<typeof billingCheckoutParser>

export async function fetchBillingCheckout (): Promise<BillingCheckout> {
  const { data } = await Api.get('/billing/checkout')
  return billingCheckoutParser.parse(data)
}

export enum PriceInterval {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year'
}

const billingProductPriceParser = z.object({
  id: z.string().nullable(),
  interval: z.nativeEnum(PriceInterval).default(PriceInterval.month),
  unitAmount: z.number().nullable()
})

export type BillingProductPrice = z.infer<typeof billingProductPriceParser>

const billingProductParser = z.object({
  id: z.string().nullable(),
  name: z.string(),
  prices: z.array(billingProductPriceParser),
  description: z.string(),
  features: z.array(z.string())
})

export type BillingProduct = z.infer<typeof billingProductParser>

export async function fetchPricing (): Promise<BillingProduct[]> {
  const { data } = await Api.get('/billing/pricing')
  return z.array(billingProductParser).parse(data)
}

const customerSubscriptionsParser = z.object({
  priceId: z.string(),
  productId: z.string(),
  interval: z.nativeEnum(PriceInterval),
  intervalCount: z.number(),
  amount: z.number().nullable(),
  quantity: z.number().optional()
})

export type CustomerSubscription = z.infer<typeof customerSubscriptionsParser>

export async function fetchCustomerSubscriptions (): Promise<CustomerSubscription[]> {
  const { data } = await Api.get('/billing/customer_subscriptions')
  return z.array(customerSubscriptionsParser).parse(data)
}
