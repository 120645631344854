import type { ButtonHTMLAttributes, AnchorHTMLAttributes } from 'react'
import styled, { css, keyframes } from 'styled-components'
import type { ButtonStyleProps } from './button'
import { getVariantStyles } from './utils'
import type { Spacing } from 'src/styles/theme/types'

interface ButtonProps extends ButtonStyleProps {
  $iconOnlyButton: boolean
  $padding: string
}

type ButtonOrAnchorProps = ButtonHTMLAttributes<HTMLButtonElement> &
AnchorHTMLAttributes<HTMLAnchorElement>

export const Button = styled.button.attrs<{ as?: React.ElementType }>({})<
ButtonProps & ButtonOrAnchorProps
>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: ${({ $align }) => $align};
  gap: 0.375rem;
  padding: ${({ $padding }) => $padding};
  border: ${({ $borderless }) => ($borderless ? 'none !important' : 'none')};
  width: ${({ $width }) => {
    if (typeof $width === 'string' && $width.endsWith('px')) {
      return `${$width} !important`
    } else {
      switch ($width) {
        case 'auto': {
          return 'auto'
        }
        case 'fit-content': {
          return 'fit-content'
        }
        case 'full': {
          return '100%'
        }
        default: {
          if (typeof $width === 'number') {
            /* We want to convert to rem as all our units are rem */
            return `${$width / 16}rem`
          }
          return 'auto'
        }
      }
    }
  }};
  min-width: ${({ $minWidth }) => $minWidth};
  height: ${({ theme, $height }) =>
    $height !== 'normal' ? theme.spacing[$height ?? 40] : '2.5rem'};
  overflow: hidden;
  border-radius: ${({ theme, $borderRadius }) => theme.spacing[$borderRadius ?? 4]};
  ${({ $disabled, $loading }) => Boolean($disabled) || Boolean($loading)
    ? css`
        pointer-events: none;
        cursor: auto;
        opacity: 0.5;
      `
    : css`
      background-color: inherit;
      cursor: pointer;
        opacity: 1;
      `
  }
  ${({ theme, $colorTheme, $variant }) =>
    getVariantStyles($variant ?? 'raised', $colorTheme ?? 'normal', theme)}
`

export const Inner = styled.span<ButtonStyleProps>`
  flex: 1 1 100%;
  display: inline-flex;
  align-items: center;
  justify-content: ${({ $align }) => $align};
  gap: 0.375rem;
  opacity: ${({ $loading }) => ($loading ? 0 : 1)};
  pointer-events: ${({ $loading, $disabled }) => (!!$loading || !!$disabled ? 'none' : 'all')};
  min-width: ${({ $innerMinWidth }) => $innerMinWidth};
`

export const Content = styled.span<ButtonStyleProps>`
  display: inline-flex;
  align-items: center;
  gap: ${({ $gap, theme }) => theme.spacing[$gap ?? 6]};
  font-size: ${({ theme, $fontSize }) => theme.fontSizes[$fontSize ?? 14]};
  font-weight: ${({ theme, $fontWeight }) => theme.fontWeights[$fontWeight ?? 500]};
  line-height: 1.2;
  white-space: nowrap;
  min-width: 0;
  border-radius: ${({ theme, $borderRadius }) => theme.spacing[$borderRadius ?? 4]};
`

const spinning = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const Loading = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  pointer-events: none;
  svg {
    animation: ${spinning} 0.5s linear infinite;
  }
`

export const Icon = styled.span<{ $size: Spacing }>`
  /* width: ${({ theme, $size }) => theme.spacing[$size ?? 16]}; */
  height: ${({ theme, $size }) => theme.spacing[$size ?? 16]};
  display: flex;
  align-items: center;
  justify-content: center;
`
