import { isNil } from 'lodash'
import { useMemo } from 'react'
import { useSession } from 'src/hooks/use-session'
import type { ActiveCandidateSequence } from 'src/libs/api/backend/candidate_jobs'
import { Flex } from '../flex'
import { Caption } from '../typography'
import * as S from './failed-adding-candidates-sumnmary.styled'
import RouteBuilder from 'src/libs/route-builder'

interface JobInfo {
  name: string
  id: string
  candidateCount: number
  departmentName?: string
}

interface FailedAddingCandidatesSummaryProps {
  activeCandidateSequences: ActiveCandidateSequence[]
}

export const FailedAddingCandidatesSummary = ({ activeCandidateSequences }: FailedAddingCandidatesSummaryProps): JSX.Element => {
  const { org } = useSession()
  const activeSequencesByJobId: Record<string, JobInfo> = useMemo(() => {
    return activeCandidateSequences.reduce((acc: Record<string, JobInfo>, candidateSequence: ActiveCandidateSequence) => {
      const jobId = candidateSequence.sequence.jobId
      if (!isNil(acc[jobId])) {
        const jobInfo = acc[jobId]
        return {
          ...acc,
          [jobId]: {
            ...jobInfo,
            candidateCount: (jobInfo?.candidateCount ?? 0) + 1
          }
        }
      } else {
        return {
          ...acc,
          [jobId]: {
            name: candidateSequence.sequence.job.title,
            id: jobId,
            candidateCount: 1,
            departmentName: candidateSequence.sequence.job.department?.name ?? org?.name ?? ''
          }
        }
      }
    }, {})
  }, [activeCandidateSequences, org?.name])

  return (
    <Flex $direction='column' $gap={10}>
      {Object.entries(activeSequencesByJobId).map(([key, jobInfo]) => (
        <Flex key={key} $direction='column' $gap={2}>
          <Caption $color='negativeBg' size='XS'>{jobInfo.candidateCount === 1 ? 'Candidate' : `${jobInfo.candidateCount} candidates`} already in sequence.</Caption>
          <S.JobLink to={RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE', { jobId: key })} end={false} target='_blank' rel='noopener noreferrer'>{jobInfo.departmentName} - {jobInfo.name}</S.JobLink>
        </Flex>
      ))}
    </Flex>
  )
}
