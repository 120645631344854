import { isNil } from 'lodash'
import type { EmailSequence, EmailSequenceStep } from 'src/models/sequence'

export const validateJobId = (jobId: string | undefined | null): void => {
  if (isNil(jobId) || jobId === '') {
    throw new Error('No job id provided')
  }
}

export const validateSteps = (data: EmailSequence): boolean => {
  return (
    Array.isArray(data.sequenceSteps) &&
    data.sequenceSteps.every((step) => typeof step === 'object' && step !== null)
  )
}

export const validateStepPositions = (steps: EmailSequenceStep[], userId?: string | null): EmailSequenceStep[] => {
  const sortedSteps = [...steps].sort((a, b) => a.position - b.position)

  const updatedSteps = sortedSteps.map((step, index) => {
    const newPosition = index

    return {
      ...step,
      position: newPosition,
      sendingUserId: userId ?? step.sendingUserId
    }
  })

  return updatedSteps
}

export const validateLinkedInUrl = (url: string): string | null => {
  // Remove any leading/trailing whitespace
  url = url.trim()

  // Extract the username using regex
  const match = url.match(/linkedin\.com\/in\/([^/\s]+)/)
  if (!match) {
    return null
  }

  const username = match[1]

  // Construct the normalized URL
  const normalizedUrl = `https://www.linkedin.com/in/${username}`

  return normalizedUrl
}

export const validateCompanyLogoUrl = (url: string): string | null => {
  try {
    const hostname = new URL(
      url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`
    ).hostname

    const domainRegex = /(?:[\w-]+\.)?([\w-]+\.[\w-]+)/
    const match = hostname.match(domainRegex)
    return match !== null ? match[1] : null
  } catch (e) {
    console.log(e)
    return null
  }
}
