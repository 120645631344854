import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { CONTENT_PADDING, HEADER_HEIGHT, SIDEBAR_PADDING } from 'src/styles/constants'

export const Sidebar = styled.div<{ $isCollapsed: boolean }>`
  /* padding: ${CONTENT_PADDING} ${SIDEBAR_PADDING}; */
  // padding: 1rem;
`

export const Topbar = styled.div<{ $isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${({ $isCollapsed }) => ($isCollapsed ? 0 : '0.5rem')};
`

export const Menu = styled.menu<{ $isCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $isCollapsed }) => ($isCollapsed ? 'center' : 'flex-start')};
`

export const MenuGroup = styled.div<{ $isCollapsed: boolean }>`
  padding-top: 0rem;
  padding-bottom: 1.5rem;
  margin-bottom: ${({ $isCollapsed }) => ($isCollapsed ? '0' : '1.5rem')};
  width: ${({ $isCollapsed }) => ($isCollapsed ? 'auto' : '100%')};
  &:not(:first-child) {
    padding-top: 1.5rem;
  }
  &:not(:last-child) {
    border-bottom: solid 1px;
    border-color: ${({ theme }) => theme.colors.bgTertiary};
  }
`

export const GroupItem = styled.div<{ $isCollapsed: boolean }>`
  margin-bottom: ${({ $isCollapsed }) => ($isCollapsed ? '0' : '1rem')};
`

export const GroupItemHeading = styled.div`
  padding: 0.5rem;
`

export const MenuLinks = styled.ul`
  list-style-type: none;
`

interface MenuLinkProps {
  $isCollapsed: boolean
  $isActive: boolean
}

export const MenuLink = styled(NavLink)<MenuLinkProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: ${({ $isCollapsed }) => ($isCollapsed ? 'center' : 'space-between')};
  gap: 0.5rem;
  height: 2rem;
  width: ${({ $isCollapsed }) => ($isCollapsed ? '2rem' : 'auto')};
  padding: 0.375rem;
  border-radius: 4px;
  background-color: ${({ theme, $isActive }) =>
    theme.colors[$isActive ? 'bgTertiary' : 'bgPrimary']};
  font-size: ${({ theme }) => theme.fontSizes[14]};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  line-height: ${({ theme }) => theme.lineHeights['1.43']};
  color: ${({ theme }) => theme.colors.fgPrimary};
  margin-bottom: 2px;
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.bgTertiary};
    }
  }
`

export const HeadingContainer = styled.div`
  padding: 0 0.375rem;

  h1 {
    line-height: ${HEADER_HEIGHT};
  }
`
