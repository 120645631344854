import { useEffect, useState } from 'react'
import { StatusView } from '../status-view'
import { ProgressBar } from 'src/components/primitives/progress-bar'
import type { Color } from 'src/styles/theme/types'
import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import * as S from './applicant-candidates-ats.styled'
import { Flex } from 'src/components/primitives'
import { Spinner } from 'src/components/primitives/spinner'
import { Caption } from 'src/components/primitives/typography'
interface ProgressStep {
  text: string
  percentage: number
}

const STEPS: ProgressStep[] = [
  {
    text: 'Importing applicants',
    percentage: 50
  },
  {
    text: 'Importing resumes',
    percentage: 60
  },
  {
    text: 'Ranking expertise and fit',
    percentage: 70
  },
  {
    text: 'Highlighting strengths',
    percentage: 80
  },
  {
    text: 'Showing results',
    percentage: 101
  }
]

const getStatus = (progress: number, currentStep: ProgressStep, previousStep?: ProgressStep): { icon: IconName, color: Color } => {
  const currentStepProgress = currentStep.percentage
  if (progress > currentStepProgress) {
    return {
      icon: 'check-circle-2',
      color: 'positiveFg'
    }
  }
  const previousStepProgress = previousStep?.percentage ?? 0
  if (progress > previousStepProgress) {
    return {
      icon: 'loader',
      color: 'fgSecondary'
    }
  }

  return {
    icon: 'circle-dashed',
    color: 'fgSecondary'
  }
}

interface ProgressStepsListProps {
  progress: number
}

export const ProgressStepsList = ({ progress }: ProgressStepsListProps): JSX.Element => {
  return (
    <S.List>
      {STEPS.map((step, index) => {
        const { icon, color } = getStatus(progress, step, STEPS[index - 1])
        return (
          <Flex key={step.text} $align="center" $gap={8}>
            {icon === 'loader'
              ? (<Spinner color="fgSecondary" />)
              : (<Icon name={icon} color={color} />)
            }
            <Caption size="XS" $color={color}>
              {step.text}
            </Caption>
          </Flex>
        )
      })}
    </S.List>
  )
}

const TOTAL_TIME = 15 * 60 // Takes around 15 minutes to sync all candidates unfortunately
const TICK_TIME = 2 // Ticks every 2 seconds

interface LoadingCandidatesProps {
  updatedAt: Date
  isAttachmentsSyncing: boolean
  isCandidateSyncing: boolean
}

export const LoadingCandidates = ({ updatedAt, isAttachmentsSyncing, isCandidateSyncing }: LoadingCandidatesProps): JSX.Element => {
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        const newProgress = prev + TICK_TIME / TOTAL_TIME
        return newProgress
      })
    }, TICK_TIME * 1000)
    return () => {
      clearInterval(interval)
    }
  }, [updatedAt])

  useEffect(() => {
    if (progress < 50 && !isCandidateSyncing && isAttachmentsSyncing) {
      setProgress(51)
    }
  }, [isAttachmentsSyncing, isCandidateSyncing, progress])

  return (
    <StatusView
      pin="searching"
      animatePin
    >
      <Flex $direction="column" $gap={24} $align="center">
        <ProgressBar progress={progress} />
        <ProgressStepsList progress={progress} />
      </Flex>
    </StatusView>

  )
}
