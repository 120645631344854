import { useQuery } from '@tanstack/react-query'
import { fetchDepartments } from 'src/libs/api/backend/departments'
import type { Department } from 'src/libs/api/backend/departments'

import { queryKeys } from 'src/libs/query-keys'

interface UseGetDepartmentsByOrg {
  departments: Department[]
  loading: boolean
  error: Error | null
  refetch: () => unknown
}

export const getDepartmentsByOrgQuery = () => ({
  queryKey: [queryKeys.departments],
  queryFn: async () => await fetchDepartments(),
  staleTime: 60 * 1000
})

export const useGetDepartmentQuery = (): UseGetDepartmentsByOrg => {
  const { data, isLoading, error, refetch } = useQuery(getDepartmentsByOrgQuery())
  return {
    departments: data ?? [],
    loading: isLoading,
    error,
    refetch
  }
}
