import { isNil } from 'lodash'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAcceptInvite } from 'src/hooks/use-accept-invite'
import { useSession } from 'src/hooks/use-session'
import RouteBuilder from 'src/libs/route-builder'

const InviteRedirect = (): JSX.Element | null => {
  const navigate = useNavigate()
  const { inviteId, token } = useParams()
  if (isNil(inviteId) || isNil(token)) {
    throw new Error('expected inviteId and token to be defined')
  }
  const { data: accepted, error } = useAcceptInvite(inviteId, token)
  const { emailAccountAccessTokens } = useSession()

  useEffect(() => {
    const hasConnectedAccounts = !isNil(emailAccountAccessTokens) &&
      emailAccountAccessTokens.length > 0 &&
      emailAccountAccessTokens.some((token) => token.hasAccessToken)
    if (accepted) {
      if (hasConnectedAccounts) {
        navigate(RouteBuilder.build('ROOT'))
      } else {
        navigate(`${RouteBuilder.build('ONBOARDING_CONNECTED_ACCOUNTS')}?skip_invite=1`)
      }
    }
  }, [accepted, emailAccountAccessTokens, navigate])

  useEffect(() => {
    if (error) {
      navigate(`${RouteBuilder.build('INVITE/INVITEID/TOKEN', { inviteId, token })}?error=${error}`)
    }
  }, [error, inviteId, navigate, token])

  return null
}

export default InviteRedirect
