import { Caption } from 'src/components/primitives/typography'
import { CONTENT_PADDING, HEADER_HEIGHT, HEADER_PADDING } from 'src/styles/constants'
import styled from 'styled-components'

export const ReadyToSendHeader = styled(Caption)`
  line-height: ${HEADER_HEIGHT};
`

export const ReadyToSendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${HEADER_PADDING} 0;
  padding-bottom: 32px;
  scroll-behavior: smooth;
  overflow-y: auto;
  position: relative;
  contain: strict;
`

export const ReadyToSendPageInner = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 ${CONTENT_PADDING};
  padding-right: 0;
  contain: strict;
  display: flex;
  flex-direction: column;
`

export const ReadyToSendList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[18]};
  width: 100%;
  height: 100%;
`

export const ReadyToSendListInner = styled.div<{ $minHeight: number }>`
  position: relative;
  min-height: ${({ $minHeight }) => $minHeight}px;
`

export const ReadyToSendBanner = styled.div<{ $maxWidth: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[18]};
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth};
  margin-bottom: 18px;
  padding-right: 2px;
`
