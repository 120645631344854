import { fetchTimezones } from '../libs/api/backend/configuration'
import type { Timezone } from '../libs/api/backend/configuration'
import { useState, useEffect } from 'react'

export const useTimezones = (sortByPriority = true): Timezone[] => {
  const [tzList, setTzList] = useState<Timezone[]>([])
  useEffect(() => {
    fetchTimezones().then((tzs) => {
      tzs = sortByPriority ? prioritySort(tzs) : tzs
      setTzList(tzs)
    }).catch((e) => {
      console.error(e)
    })
  }, [sortByPriority])
  return tzList
}

export const prioritySort = (timezones: Timezone[]): Timezone[] => {
  const priority = [
    'US/Eastern',
    'US/Central',
    'US/Mountain',
    'US/Arizona',
    'US/Pacific',
    'US/Michigan',
    'US/Indiana-Starke',
    'US/East-Indiana',
    'US/Alaska',
    'US/Hawaii',
    'US/Aleutian',
    'US/Samoa'
  ]
  timezones.sort((a, b) => {
    // sort by negative first, then zero, then positive
    // so that the negative offsets come first
    return a.offset > b.offset ? 1 : -1
  })
  timezones.sort((a, b) => {
    const indexA = priority.indexOf(a.name)
    const indexB = priority.indexOf(b.name)

    // Both are in priority, sort them based on their priority
    if (indexA !== -1 && indexB !== -1) {
      return 0
    // Only a is in priority, it should come first
    } else if (indexA !== -1) {
      return -1
    // Only b is in priority, it should come first
    } else if (indexB !== -1) {
      return 1
    }
    // Neither is in priority, maintain their original order
    return 0
  })
  return timezones
}
