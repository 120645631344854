import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { createCandidateNote as createCandidateNoteApi } from 'src/libs/api/backend/candidate_activities'
import { notifyErrorAtom } from 'src/stores/notifications'

interface Args {
  candidateId: string
  body: string
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  createCandidateNote: (args: Args) => void
}

export const useCreateCandidateNote = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)

  const mutation = useMutation({
    mutationFn: async ({ candidateId, body }: Args) => await createCandidateNoteApi(candidateId, body),
    onError: (err, variables) => {
      notifyError({
        message: `An error occurred while creating a note on your candidate: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (_, variables) => {
      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })

  const createCandidateNote = (args: Args): void => {
    mutation.mutate(args)
  }

  return { createCandidateNote }
}
