import { useEffect, useState } from 'react'
import * as RRG from '@radix-ui/react-radio-group'
import type { FieldCommonProps } from '../common'
import { FieldLabel } from '../field-label'
import { FormElement } from '../form-element'
import { FieldError } from '../field-error'
import * as S from './radio-group.styled'
import { When } from 'src/components/blocks/when'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'

export interface RadioGroupStyleProps {
  $span?: 'auto' | 'full'
}

interface RadioGroupProps extends RadioGroupStyleProps, FieldCommonProps {
  variant?: 'tabs' | 'cards' | 'bullets'
  defaultValue?: string
  options: Array<{ title: string, value: string, description?: string }>
}

export const RadioGroup = ({
  name,
  label,
  hiddenLabel = false,
  register,
  options,
  defaultValue,
  variant = 'tabs',
  $span = 'auto',
  $marginBottom
}: RadioGroupProps): JSX.Element => {
  const [defaultSelected, setDefaultSelected] = useState(defaultValue)
  const { onSelect, error, value } = register(name)

  useEffect(() => {
    if (defaultValue) {
      setDefaultSelected(defaultValue)
      onSelect(defaultValue)
    }
  }, [defaultValue])

  return (
    <FormElement $marginBottom={$marginBottom}>
      <RRG.Root
        onValueChange={(value) => {
          onSelect(value)
        }}
        defaultValue={defaultSelected}
        value={value as string}
      >
        <FieldLabel label={label} htmlFor={name} hiddenLabel={hiddenLabel} marginBottom={variant === 'cards' ? 24 : 0} />
        <When condition={variant === 'tabs'}>
          <S.RadioGroupTabs $span={$span}>
            {options?.map((option) => (
              <S.TabsItem key={option.value} value={option.value} $span={$span}>
                {option.title}
              </S.TabsItem>
            ))}
          </S.RadioGroupTabs>
        </When>
        <When condition={variant === 'cards'}>
          <S.RadioGroupCards>
            {options?.map((option) => (
              <S.CardItem key={option.value} value={option.value}>
                <S.CardItemIndicator data-name="X" />
                <Flex $gap={4} $direction="column">
                  <Caption size="SM">{option.title}</Caption>
                  {option.description && (
                    <Paragraph $color="fgSecondary">{option.description}</Paragraph>
                  )}
                </Flex>
              </S.CardItem>
            ))}
          </S.RadioGroupCards>
        </When>
        <When condition={variant === 'bullets'}>
          <S.RadioGroupBullets>
            {options?.map((option) => (
              <S.BulletItem key={option.value} value={option.value}>
                <S.BulletItemIndicator />
                <Paragraph size="SM" $color="fgPrimary">{option.title}</Paragraph>
              </S.BulletItem>
            ))}
          </S.RadioGroupBullets>
        </When>
      </RRG.Root>
      {error ? <FieldError id={`${name}-error`}>{error}</FieldError> : null}
    </FormElement>
  )
}
