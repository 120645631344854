import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import type { CandidateJob } from 'src/libs/api/backend/candidate_jobs'
import { buildCustomOutreach } from 'src/libs/api/backend/custom_outreach'
import type { BuildCustomOutreach } from 'src/libs/api/backend/custom_outreach'

interface Args {
  candidateJobId: CandidateJob['id']
}

export const useBuildCustomOutreach = ({ candidateJobId }: Args): UseQueryResult<BuildCustomOutreach> => {
  return useQuery({
    queryKey: [queryKeys.customOutreach, candidateJobId],
    queryFn: async () => await buildCustomOutreach({ candidateJobId }),
    refetchOnWindowFocus: false,
    staleTime: Infinity
  })
}
