import styled from 'styled-components'

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const FormColumn = styled.div`
  flex: 1 1 65%;
  height: 100%;
  padding: 2.5rem 1.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
`

export const MediaColumn = styled.div`
  flex: 1 1 35%;
  max-width: 34vw;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
