import styled from 'styled-components'

export const CandidateStep = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  button {
    overflow: visible;
  }
`
