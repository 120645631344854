import styled from 'styled-components'
import * as RRG from '@radix-ui/react-radio-group'
import type { RadioGroupStyleProps } from './radio-group'

export const RadioGroupTabs = styled.div<RadioGroupStyleProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ $span }) => ($span === 'auto' ? 'start' : 'space-evenly')};
  gap: 0.375rem;
`

export const TabsItem = styled(RRG.Item)<RadioGroupStyleProps>`
  width: ${({ $span }) => ($span === 'auto' ? 'auto' : '100%')};
  height: ${({ $span }) => ($span === 'auto' ? '2rem' : '2.5rem')};
  border: solid 1px;
  border-radius: 4px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  font-size: ${({ theme }) => theme.fontSizes[14]};
  color: ${({ theme }) => theme.colors.fgSecondary};
  font-weight: 500;
  padding: 0.375rem;
  cursor: pointer;
  &[data-state='checked'] {
    background-color: ${({ theme }) => theme.colors.tintFaded};
    color: ${({ theme }) => theme.colors.tintBg};
    border-color: ${({ theme }) => theme.colors.tintTranslucent25};
  }
`

export const RadioGroupCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const CardItemIndicator = styled.span`
  flex: 0 0 1rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 999px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    transform: scale(0.32);
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    border-radius: 999px;
    z-index: 2;
  }
`

export const CardItem = styled(RRG.Item)`
  width: 100%;
  padding: 1rem;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border: solid 1px;
  border-color: transparent;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  &[data-state='checked'] {
    border: solid 1px;
    border-color: ${({ theme }) => theme.colors.tintBg};
    box-shadow: none;
    ${CardItemIndicator} {
      background-color: ${({ theme }) => theme.colors.tintBg};
      border-color: ${({ theme }) => theme.colors.tintBg};
    }
  }
`

export const RadioGroupBullets = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const BulletItemIndicator = styled.span`
  width: 1rem;
  height: 1rem;
  border-radius: 999px;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 99px;
    background-color: ${({ theme }) => theme.colors.bgPrimary};
  }
`

export const BulletItem = styled(RRG.Item)`
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  &:focus {
    outline: none;
    ${BulletItemIndicator} {
      box-shadow: ${({ theme }) => theme.boxShadows.focused};
    }
  }
  &[data-state='checked'] {
    ${BulletItemIndicator} {
      background-color: ${({ theme }) => theme.colors.tintBg};
      border-color: ${({ theme }) => theme.colors.tintBg};
    }
  }
`
