import { useParams } from 'react-router-dom'
import { CandidatesPageHeader } from 'src/components/blocks/candidates-page-header'
import { CandidatesArchivedTable } from 'src/components/tables/candidates-archived-table'
import { isNil } from 'lodash'
import { CandidatesTablePagesContentInner } from './candidates.styled'
import { SEO } from '../../../components/primitives/seo'

const JobCandidatesArchivedPage = (): JSX.Element => {
  const { jobId } = useParams()

  if (isNil(jobId)) {
    return <></>
  }

  return (
    <>
      <SEO title="Archived Candidates" />

      <CandidatesTablePagesContentInner>
        <CandidatesPageHeader heading="Archived" />
        <CandidatesArchivedTable />
      </CandidatesTablePagesContentInner>
    </>
  )
}

export default JobCandidatesArchivedPage
