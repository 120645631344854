import { Button, Flex } from 'src/components/primitives'
import * as S from './close-pin-account-feedback.styled'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Box } from 'src/components/primitives/box'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Form, RadioGroup, Textarea } from 'src/components/forms'
import { useForm } from 'src/hooks/use-form'
import { useCallback } from 'react'
import { Checkbox } from 'src/components/forms/checkbox'
import { closePinAccountFeedbackParser } from 'src/libs/api/backend/orgs'
import type { ClosePinAccountFeedbackArgs } from 'src/libs/api/backend/orgs'
import { useDeleteOrg } from 'src/hooks/mutations/use-delete-org'
import { isNil } from 'lodash'

enum ClosePinAccountFeedbackReason {
  ThePriceAintRight = 'Price isn’t right for me or my company',
  NotEnoughCandidates = 'Couldn’t find candidates that matched my criteria',
  SimilarApp = 'I’m using or will be using another similar app',
  Other = 'Other reason'
}

interface ClosePinAccountFeedbackForm extends ClosePinAccountFeedbackArgs {}

interface ClosePinAccountFeedbackProps {
  onClose: () => void
}
export const ClosePinAccountFeedback = ({ onClose }: ClosePinAccountFeedbackProps): JSX.Element => {
  const { deleteOrg } = useDeleteOrg()
  const { register, formData, submit } = useForm<ClosePinAccountFeedbackForm>({
    schema: closePinAccountFeedbackParser,
    initialValues: {
      reason: ClosePinAccountFeedbackReason.ThePriceAintRight,
      feedbackCall: true,
      reasonDetails: null
    }
  })

  const handleSubmit = useCallback(() => {
    if (!formData.reason || isNil(formData.feedbackCall)) {
      return
    }
    deleteOrg({
      reason: formData.reason as string,
      feedbackCall: Boolean(formData.feedbackCall),
      reasonDetails: formData.reasonDetails ? formData.reasonDetails as string : null
    })
  }, [formData, deleteOrg])

  return (
    <Flex $direction='column' $gap={24}>
      <Button
        $variant='outline'
        $colorTheme='muted'
        $height={40}
        leadingIcon={Icons.chevronLeft}
        onClick={onClose}
      >
        Continue using Pin
      </Button>
      <S.FeedbackBlock>
        <Box $display='flex' $flexDirection='column' $gap={8} $padding={{ top: 0, bottom: 0, left: 4, right: 4 }} $flex='0 0 auto'>
          <Caption size='MD'>Close Pin account</Caption>
          <Paragraph>We're sorry if Pin hasn't lived to your expectations.<br/>Please help us by describing why you won't be using Pin for your recruiting needs.</Paragraph>
        </Box>
        <Form onSubmit={submit(handleSubmit)}>
          <Flex $direction='column' $gap={24} $width='100%'>
            <RadioGroup
              name='reason'
              register={register}
              hiddenLabel
              variant='bullets'
              options={[
                { title: ClosePinAccountFeedbackReason.ThePriceAintRight, value: ClosePinAccountFeedbackReason.ThePriceAintRight },
                { title: ClosePinAccountFeedbackReason.NotEnoughCandidates, value: ClosePinAccountFeedbackReason.NotEnoughCandidates },
                { title: ClosePinAccountFeedbackReason.SimilarApp, value: ClosePinAccountFeedbackReason.SimilarApp },
                { title: ClosePinAccountFeedbackReason.Other, value: ClosePinAccountFeedbackReason.Other }
              ]}
              $marginBottom={0}
            />
            {formData.reason === ClosePinAccountFeedbackReason.Other && (
              <Textarea
                name='reasonDetails'
                register={register}
                hiddenLabel
                placeholder='Please specify a reason or provide feedback.'
                $marginBottom={0}
                rows={5}
                autoFocus
                $width='100%'
              />
            )}
            <Checkbox
              name='feedbackCall'
              register={register}
              label='I’m open to a 15 minute feedback call with the Pin team.'
              $marginBottom={0}
              defaultChecked={true}
            />
            <S.WarningBlock>
              <S.Icon>
                <Icon name={Icons.alertTriangle} color='warningFg' />
              </S.Icon>
              <Caption as='span' size='SM' $fontWeight={400}>
                When you close your account:
                <ul>
                  <li>Your Pin data will be lost.</li>
                  <li>You won’t be eligible for a new trial in a new account.</li>
                </ul>
              </Caption>
            </S.WarningBlock>
            <Button
              $variant='outline'
              $colorTheme='negative'
              $height={40}
              type='submit'
            >
              Send feedback and close account
            </Button>
          </Flex>
        </Form>
      </S.FeedbackBlock>
    </Flex>
  )
}
