import type { Color } from 'src/styles/theme/types'
import { Icon } from '../icon'
import * as S from './spinner.styled'

interface SpinnerProps {
  color?: Color
  size?: number
}

export const Spinner = ({ color = 'fgTertiary', size = 16 }: SpinnerProps): JSX.Element => {
  return (
    <S.Spinner>
      <Icon name="loader" color={color} size={size} />
    </S.Spinner>
  )
}
