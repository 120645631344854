import styled, { keyframes, css } from 'styled-components'
import type { LoadingBoxStyleProps } from './loading-box'
import type { Theme } from 'src/styles/theme/types'
const getBorderStyle = (variant: LoadingBoxStyleProps['$variant']): string => {
  switch (variant) {
    case 'raised':
    case 'solid': {
      return '1px solid'
    }
    case 'dashed': {
      return '1px dashed'
    }
    default: {
      return '1px solid'
    }
  }
}

const getBorderColor = (variant: LoadingBoxStyleProps['$variant'], theme: Theme): string => {
  switch (variant) {
    case 'raised': {
      return theme.colors.bgPrimary
    }
    case 'dashed':
    case 'solid': {
      return theme.colors.borderTranslucent
    }
    default: {
      return theme.colors.bgPrimary
    }
  }
}

export const LoadingBox = styled.div<LoadingBoxStyleProps>`
  width: 100%;
  height: ${({ $height }) => ($height === 'full' ? '100%' : $height)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  border-radius: 8px;
  border: ${({ $variant }) => getBorderStyle($variant)};
  border-color: ${({ $variant, theme }) => getBorderColor($variant, theme)};
  padding: 4rem 10%;
  margin-bottom: ${({ $height }) => ($height === 'full' ? '0' : '1rem')};
  box-shadow: ${({ $variant }) =>
    $variant === 'raised'
      ? '0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 2px 8px 0px rgba(0, 0, 0, 0.05)'
      : 'none'};
  p {
    span {
      display: block;
      text-align: center;
    }
  }
`

const animateIcon = keyframes`
  0% { transform: scale(1.1); opacity: 1; fill: hsla(161, 75%, 40%, 1); }
  50% { transform: scale(0.8); opacity: 1; fill: hsla(248, 84%, 70%, 1); }
  100% { transform: scale(1.1); opacity: 1; fill: hsla(161, 75%, 40%, 1); }
`

export const Icon = styled.div<{ $animated: boolean }>`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $animated }) =>
    $animated &&
    css`
      svg {
        path {
          animation: ${animateIcon} 1.8s ease-in-out infinite;
          transform-origin: center;
          &:nth-child(1) {
            animation-delay: 0s;
          }
          &:nth-child(2) {
            animation-delay: 0.2s;
          }
          &:nth-child(3) {
            animation-delay: 0.4s;
          }
        }
      }
    `}
`
