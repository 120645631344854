import type { CriteriaKey, JobTitleCriteria } from 'src/libs/api/backend/candidate_search'
import * as S from './index.styled'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CriteriaPill } from './criteria-pill'
import { RemoveCriteria } from './remove-criteria'
import { Icon, Icons } from 'src/components/primitives/icon'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { isDuplicatedCriteria } from 'src/utils/refinement-criteria'

interface JobRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: JobTitleCriteria[]
  onCriteriaUpdate: (criteriaKey: CriteriaKey, criteriaValue: JobTitleCriteria[]) => void
  onCriteriaRemove?: (criteriaKey: CriteriaKey) => void
  disabled: boolean
}

export const JobRow = ({
  criteriaKey,
  criteriaValue,
  onCriteriaUpdate,
  onCriteriaRemove,
  disabled
}: JobRowProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const { data: jobData } = useJobQuery()

  const criteriaValueWithCurrentJobTitle = useMemo(() => (
    [{
      name: jobData?.title ?? '',
      optional: false,
      negative: false,
      required: true
    }, ...criteriaValue]
  ), [criteriaValue, jobData?.title])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Enter' && inputValue && document.activeElement === inputRef.current) {
        const newJobTitle = {
          name: inputValue,
          optional: false,
          negative: false
        }
        const isJobDuplicated = isDuplicatedCriteria(newJobTitle, criteriaValueWithCurrentJobTitle, 'name')
        if (!isJobDuplicated) {
          onCriteriaUpdate(criteriaKey, [...criteriaValue, newJobTitle])
        }
        setInputValue('')
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [criteriaKey, criteriaValue, criteriaValueWithCurrentJobTitle, inputValue, onCriteriaUpdate])

  return (
    <S.RowContainer>
      <S.CriteriaMultiValuesField $disabled={disabled}>
        {criteriaValueWithCurrentJobTitle.map((jobTitle, index) => {
          return (
            <CriteriaPill
              key={index}
              value={{
                label: jobTitle.name,
                optional: false,
                negative: false,
                required: 'required' in jobTitle ? jobTitle.required : false
              }}
              tooltip={index === 0 ? 'This title cannot be removed, update it in the job settings' : undefined}
              onRemove={() => {
                const updatedCriteriaValue = criteriaValue.filter((_, i) => i !== index - 1) // -1 because we have current job title at index 0
                onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
              }}
              useOptional={false}
            />
          )
        })}
        <S.SearchInput as='div'>
          <S.InputField
            ref={inputRef}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value)
            }}
            disabled={disabled}
          />
        </S.SearchInput>
      </S.CriteriaMultiValuesField>
      {onCriteriaRemove
        ? <RemoveCriteria
            onRemove={() => {
              onCriteriaRemove(criteriaKey)
            }}
            disabled={disabled}
          />
        : <S.IconContainer>
            <Icon name={Icons.lock} size={12} color='fgTertiary' />
          </S.IconContainer>
      }
    </S.RowContainer>
  )
}
