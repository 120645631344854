export const SIZES = {
  HEADER: {
    LG: 164
  },
  SUBHEADER: {
    LG: 40
  },
  HOUR: {
    LG: 78
  },
  FOOTER: {
    LG: 72
  }
}
