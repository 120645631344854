import styled from 'styled-components'

export const CriteriaExpandedHeader = styled.th<{ $height: string }>`
  z-index: 99;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1.5rem;
  width: 100%;
  padding-left: 1.75rem;
  padding-right: 5rem;
  height: ${({ $height }) => $height};
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  border-right: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
`

export const CriteriaExpandedHeaderEmpty = styled.th`
  z-index: 99;
  position: sticky;
  top: 0;
  gap: 1.5rem;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  border-right: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
`

export const RotatedCaption = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  padding: 5px 10px;
  white-space: nowrap;
`

export const RotatedCaptionInner = styled.div`
  transform: rotate(-55deg);
  transform-origin: left center;
`
