import { colorsLight } from './colors'
import { spacing } from './spacing'
import { zindeces } from './zindeces'
import { fontSizes, fontWeights, lineHeights, letterSpacings } from './font'
import type { Theme } from './types'
import { boxShadows } from './box-shadows'
import { backgrounds } from './backgrounds'

const baseTheme = {
  spacing,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  zindeces,
  boxShadows,
  backgrounds
}

export const lightTheme: Theme = {
  colors: colorsLight,
  ...baseTheme
}

export const darkTheme: Theme = {
  colors: colorsLight,
  ...baseTheme
}
