import { Avatar } from 'src/components/primitives/avatar'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { NavLink, useLocation } from 'react-router-dom'
import { getCurrentOnboardingStep } from 'src/utils/get-current-onboarding-step'
import * as S from './onboarding-navigation.styled'
import { useSession } from 'src/hooks/queries/use-session'
import { Dropdown } from 'src/components/primitives/dropdown'
import CONFIG from 'src/config'
import { Logo } from 'src/components/primitives/logo'

interface OnboardingNavigationProps {
  showCurrentStep?: boolean
  showPinLogo?: boolean
}

export const OnboardingNavigation = ({ showCurrentStep = true, showPinLogo = false }: OnboardingNavigationProps): JSX.Element => {
  const { pathname } = useLocation()
  const { data: sessionData } = useSession()
  const currentUser = sessionData?.user
  const primaryEmail = sessionData?.emailAccountAccessTokens?.[0]

  return (
    <S.OnboardingNavigation>
      <S.Inner>
        {
          showCurrentStep && (
            <>
              <Avatar $size={24} initials="R" />
              <S.CurrentStep>
                <Paragraph size="XS">Step {getCurrentOnboardingStep(pathname)?.position}/5</Paragraph>
                <S.CurrentStepDivider />
                <Caption size="SM">{getCurrentOnboardingStep(pathname)?.title}</Caption>
              </S.CurrentStep>
            </>
          )
        }
        {
          showPinLogo && (
            <NavLink to="/">
              <Logo variant="dark" size={54} />
            </NavLink>
          )
        }
        <Dropdown
          trigger={
            <Avatar
              $shape="circle"
              $type="photo"
              $size={32}
              initials={currentUser?.name}
              photoUrl={currentUser?.profilePhoto}
              ariaLabel="Toggle account menu"
            />
          }
          menuPosition="end"
          items={[
            {
              id: 'user',
              title: currentUser?.name ?? '',
              subTitle: primaryEmail?.email ?? '',
              isSelectable: false
            },
            {
              id: 'settings-separator',
              title: 'SettingsSeparator',
              type: 'separator'
            },
            {
              id: 'logout',
              title: 'Log out',
              variant: 'negative',
              icon: 'log-out',
              href: `${CONFIG.API_DOMAIN}/auth/logout?redirect=${CONFIG.APP_DOMAIN}`
            }
          ]}
        />
      </S.Inner>
    </S.OnboardingNavigation>
  )
}
