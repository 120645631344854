import styled from 'styled-components'
import type { EntryStyleProps } from './timeline-entry'
import type { MoreButttonStyleProps } from '.'
import type { FlexStyleProps } from 'src/components/primitives'
import { TimelineEntrySocials } from './timeline-entry-title.styled'

export const Timeline = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`

export const Entry = styled.li<EntryStyleProps>`
  position: relative;
  border: solid 1px;
  border-color: transparent;
  z-index: 2;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: ${({ theme, $padding }) => theme.spacing[$padding?.left ?? 24]};
  padding-right: ${({ theme, $padding }) => theme.spacing[$padding?.right ?? 24]};

  // &:nth-child(even) {
  //   background-color: ${({ theme }) => theme.colors.bgSecondary};
  // }
  &:hover, &[aria-expanded="true"] {
    ${TimelineEntrySocials} {
      display: flex;
    }
  }
  @media print {
    break-inside: avoid;
  }
`

export const EntryHeader = styled.div<Pick<FlexStyleProps, '$align'>>`
  position: relative;
  display: flex;
  align-items: ${({ $align }) => $align};
  gap: 0.5rem;
`

export const EntryContent = styled.div<{ $isCollapsed: boolean, $isCollapsible: boolean, $contentWidth: string }>`
  position: relative;
  display: flex;
  ${({ $contentWidth }) =>
    $contentWidth
    ? `
        min-width: ${$contentWidth};
        max-width: ${$contentWidth};
      `
    : 'flex: 1 1 auto;'
  }
  flex-direction: column;
  // overflow-x: visible;
  &:after {
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    width: 100%;
    height: calc(100% + 8px);
    border-radius: 8px;
    ${({ $isCollapsed, theme }) => !$isCollapsed && `
      background-color: ${theme.colors.bgSecondary};
      border: 1px solid ${theme.colors.borderOpaque};
    `}
    // opacity: 0.4;
    // transition: all 0.1s ease-in-out;
    z-index: -1;
  }
  ${({ $isCollapsible, $isCollapsed, theme }) => $isCollapsible && `
    @media (hover: hover) {
      &:hover {
        &:after {
            background-color: ${theme.colors[$isCollapsed ? 'bgTertiary' : 'bgTertiary']};
          }
        }
      }
    }
  `}
`

export const MoreButton = styled.div<MoreButttonStyleProps>`
  padding-left: ${({ theme, $padding }) => theme.spacing[$padding?.left ?? 24]};
  padding-right: ${({ theme, $padding }) => theme.spacing[$padding?.right ?? 0]};
  margin: 0.5rem 0 1rem 0;
`

export const Avatar = styled.div`
  position: relative;
  margin-top: 0.125rem;
  z-index: 4;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
`
