import { useMemo } from 'react'
import { Button } from 'src/components/primitives'
import { BrandIcon } from 'src/components/primitives/brand-icon'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Icons } from 'src/components/primitives/icon'
import { useSession } from 'src/hooks/use-session'
import { SequenceStepType } from 'src/libs/api/backend/sequences'
import { FeatureFlags } from 'src/libs/api/backend/session'
import * as S from './email-sequence-step-type-selector.styled'

interface EmailSequenceStepTypeProps {
  type: SequenceStepType
  onSequenceStepTypeUpdate: (update: SequenceStepType) => void
  isGenerating: boolean
}

const getTitle = (type: SequenceStepType): string => {
  switch (type) {
    case SequenceStepType.AUTOMATED_EMAIL:
      return 'Send email automatically'
    case SequenceStepType.MANUAL_EMAIL:
      return 'Send email manually from queue'
    case SequenceStepType.AUTOMATED_LINKEDIN_INMAIL:
      return 'Send LinkedIn InMail automatically'
    case SequenceStepType.MANUAL_LINKEDIN_INMAIL:
      return 'Send LinkedIn InMail manually from queue'
    default:
      return ''
  }
}

export const EmailSequenceStepTypeSelector = (
  { type, onSequenceStepTypeUpdate, isGenerating }: EmailSequenceStepTypeProps
): JSX.Element | null => {
  const { featureFlags } = useSession()

  const selectableSequenceStepTypes = useMemo(() => {
    const baseTypes = [SequenceStepType.AUTOMATED_EMAIL, SequenceStepType.MANUAL_EMAIL]
    if (featureFlags?.includes(FeatureFlags.LINKEDIN_CONNECTION)) {
      return [...baseTypes, SequenceStepType.AUTOMATED_LINKEDIN_INMAIL, SequenceStepType.MANUAL_LINKEDIN_INMAIL]
    }
    return baseTypes
  }, [featureFlags])

  const DEFAULT_SEQUENCE_STEP_TYPES_OPTIONS = useMemo(() => (
    selectableSequenceStepTypes.map((type) => ({
      id: type,
      type: 'item',
      icon: type === SequenceStepType.AUTOMATED_LINKEDIN_INMAIL || type === SequenceStepType.MANUAL_LINKEDIN_INMAIL ? <S.BrandIconWrapper><BrandIcon name='linkedinOriginal' color="original" size={12} /></S.BrandIconWrapper> : Icons.mail,
      title: getTitle(type),
      onSelect: () => { onSequenceStepTypeUpdate(type) }
    }))
  ), [onSequenceStepTypeUpdate, selectableSequenceStepTypes])

  if (!featureFlags?.includes(FeatureFlags.MANUAL_OUTREACH)) {
    return null
  }

  return (
    <Dropdown
      disabled={isGenerating}
      trigger={
        <Button
          nested
          disabled={isGenerating}
          $disabled={isGenerating}
          $variant='raised'
          $colorTheme='normal'
          trailingIcon={Icons.chevronsUpDownSmall}
          $height={24}
          $fontSize={12}
        >
          {getTitle(type)}
        </Button>
      }
      menuPosition='end'
      size='small'
      selectedValue={type}
      items={DEFAULT_SEQUENCE_STEP_TYPES_OPTIONS}
    />
  )
}
