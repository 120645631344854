import styled from 'styled-components'

export const Event = styled.div<{ $isSelectable: boolean }>`
  position: absolute;
  width: 100%;
  padding: 0 2px;
  z-index: 2;
  pointer-events: ${({ $isSelectable }) => $isSelectable ? 'auto' : 'none'};
`

export const EventInner = styled.div<{ $isSelected: boolean, $isDragging: boolean, $isNewEvent: boolean, $isSelectable: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ theme, $isNewEvent }) => theme.colors[$isNewEvent ? 'tintFaded' : 'fgFaded10']};
  border-radius: 6px;
  padding: 0.5rem;
  span {
    font-size: 10px;
    line-height: 1;
  }
  cursor: pointer;
  user-select: none;
  // opacity: ${({ $isSelectable }) => $isSelectable ? 1 : 0.75};
  overflow: hidden;

  ${({ $isSelected, $isNewEvent, theme }) =>
    !$isSelected && !$isNewEvent &&
    `
  @media (hover: hover) {
  &:hover {
      background-color: ${theme.colors.fgTranslucent10};
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border: solid 2px;
      border-color: ${theme.colors.fgTranslucent10};
          border-radius: inherit;
        }
      }
    }
  `}
  ${({ $isSelected, theme }) =>
    $isSelected &&
    `
    background-color: ${theme.colors.tintFaded};
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border: solid 2px;
        border-color: ${theme.colors.tintBg};
        opacity: 0.25;
        border-radius: inherit;
      }
      `}
  ${({ $isNewEvent, theme }) =>
    $isNewEvent &&
    `
    background-color: ${theme.colors.tintFaded};
      &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border: solid 2px;
    border-color: ${theme.colors.tintBg};
    border-radius: inherit;
      }
      `}
`
