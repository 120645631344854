import { useQuery } from '@tanstack/react-query'
import type { UseQueryOptions } from '@tanstack/react-query'
import { isFuture } from 'date-fns'
import { getMentionedTimesAvailability } from 'src/libs/api/backend/me'
import type { StartEndTime } from 'src/libs/api/backend/me'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  startEndTimes: StartEndTime[]
}

export const mentionedTimesAvailabilityQuery = ({ startEndTimes }: Args): UseQueryOptions<StartEndTime | null> => {
  return {
    queryKey: [queryKeys.mentionedTimesAvailability, startEndTimes],
    queryFn: async () => await getMentionedTimesAvailability({ startEndTimes }),
    staleTime: 60 * 1000,
    enabled: startEndTimes.length > 0 && startEndTimes.some(({ start, end }) => isFuture(start) && isFuture(end)),
    throwOnError: false // probably need to catch instead
  }
}

export const useGetMentionedTimesAvailability = (args: Args) => {
  return useQuery(mentionedTimesAvailabilityQuery(args))
}
