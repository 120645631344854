import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import * as S from './editor.styled'

interface MenuButtonProps {
  isActive: boolean
  icon: IconName
  onClick: () => void
}

export const MenuButton = ({ isActive, icon, onClick }: MenuButtonProps): JSX.Element => {
  return (
    <S.EditorButton
      $isActive={isActive}
      onClick={() => { onClick() }}
    >
      <Icon name={icon} color="bgPrimary" size={12} />
    </S.EditorButton>
  )
}
