import { z } from 'zod'
import Api from '../'

const timezonesParser = z.object({
  timezones: z.array(z.object({
    name: z.string(),
    offset: z.string()
  }))
})

type Timezones = z.infer<typeof timezonesParser>

export type Timezone = Timezones['timezones'][number]

export async function fetchTimezones (): Promise<Timezone[]> {
  const res = await Api.get<string[]>('/configuration/timezones')
  const timezones = timezonesParser.parse(res.data)
  return timezones.timezones
}
