export enum SettingParams {
  PROFILE = 'profile',
  CONNECTED_ACCOUNTS = 'connected_accounts',
  NOTIFICATION_PREFERENCES = 'notification_preferences',
  HIRING_FOR = 'hiring_for',
  ORG_DETAILS = 'org_details',
  TEAM_MEMBERS = 'team_members',
  SUBSCRIPTION = 'subscription',
  ATS = 'ats',
  PROJECTS = 'projects'
}
