import { Flex } from 'src/components/primitives'
import * as S from './avatars-list.styled'
import { Avatar } from 'src/components/primitives/avatar'
import type { AvatarProps } from 'src/components/primitives/avatar'
import { Icon } from 'src/components/primitives/icon/icon'
import { Tooltip } from 'src/components/primitives/tooltip'
import { Caption } from 'src/components/primitives/typography'

export interface AvatarsListProps extends Pick<AvatarProps, '$size' | '$border'> {
  avatars: Array<{
    id: string
    name: string
    profilePhotoUrl?: string | null
    href?: string
  }>
  avatarDisplay: {
    showPlus?: boolean
    showCount?: boolean
    count: number
  }
  $overlap?: number
}

export const AvatarsList = ({
  avatars,
  avatarDisplay,
  $size = 20,
  $overlap = 4,
  $border
}: AvatarsListProps): JSX.Element => {
  return (
    <Flex $gap={4} $align="center" $direction="row" $width="auto">
      <S.Avatars>
        {avatars.slice(0, avatarDisplay.count).map((a) => (
          <S.Avatar $overlap={$overlap} key={a.id}>
            <Tooltip
              trigger={
                <div>
                  <Avatar
                    $type="photo"
                    $shape="circle"
                    $size={$size}
                    $stacked
                    $border={$border}
                    $overflow='visible'
                    initials={a.name}
                    photoUrl={a.profilePhotoUrl}
                    fallbackAvatar="random"
                    href={a.href}
                  />
                </div>
              }>
                {a.name}
              </Tooltip>
          </S.Avatar>
        ))}
        {avatarDisplay.showPlus && (
          <S.AvatarMore>
            <Icon name="plus" size={10} color="fgTertiary" />
          </S.AvatarMore>
        )}
      </S.Avatars>
      {avatarDisplay.showCount && avatars.length > avatarDisplay.count && (
        <Caption size="2XS" $color="fgSecondary">
          +{avatars.length - avatarDisplay.count}
        </Caption>
      )}
    </Flex>
  )
}
