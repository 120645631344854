import { CalendarSettings } from 'src/components/blocks/calendar-settings'
import { PageHeader } from 'src/components/blocks/page-header'
import { SEO } from 'src/components/primitives/seo'

const CalendarPreferencesPage = (): JSX.Element => {
  return (
    <>
      <PageHeader heading="Calendar Preferences" />
      <SEO title="Calendar Preferences" />
      <CalendarSettings />
    </>
  )
}

export default CalendarPreferencesPage
