import { Outlet } from 'react-router-dom'
import * as S from './default.styled'

export const DefaultLayout = (): JSX.Element => {
  return (
    <S.ContentInner>
      <S.MaxWidth>
        <Outlet />
      </S.MaxWidth>
    </S.ContentInner>
  )
}
