import { OnboardingNavigation } from 'src/components/blocks/onboarding-navigation'
import * as S from '../onboarding/onboarding.styled'

export const IntegrationsLayout = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <S.OnboardingLayout>
      <OnboardingNavigation showCurrentStep={false} showPinLogo={true} />
      <S.Inner>
        <S.Content>
          <S.MediaColumn>
            <img
              src="/onboarding/connect-linkedin.jpg"
              alt="Illustration showing the chrome with arrows pointing at the LinkedIn icon and the Pin logo"
            />
          </S.MediaColumn>
          <S.FormColumn>
            <S.FormColumnInner>
              {children}
            </S.FormColumnInner>
          </S.FormColumn>
        </S.Content>
      </S.Inner>
    </S.OnboardingLayout>
  )
}
