import { isNil, sortBy } from 'lodash'
import { useMemo } from 'react'
import { Button } from 'src/components/primitives'
import { Avatar } from 'src/components/primitives/avatar'
import { BrandIcon } from 'src/components/primitives/brand-icon'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { useSendableAddresses } from 'src/hooks/queries/use-sendable-addresses'
import { useSession } from 'src/hooks/use-session'
import { MessageType } from 'src/libs/api/backend/sequences'
import { FeatureFlags } from 'src/libs/api/backend/session'
import type { EmailAccount, LinkedInAccount } from 'src/libs/api/backend/users'

export interface EmailAccountWithProfilePhoto extends EmailAccount {
  profilePhotoUrl?: string | null
}

interface SenderSelectionProps {
  handleSenderChange: (emailAccount: EmailAccountWithProfilePhoto, emailAlias: string | null, linkedInAccount: LinkedInAccount | null, messageType: MessageType) => void
  userEmailAccounts: EmailAccountWithProfilePhoto[]
  linkedInAccounts: LinkedInAccount[]
  selectedEmailAccount?: EmailAccountWithProfilePhoto
  selectedEmailAlias: string | null
  selectedLinkedInAccountId: string | null
  disableEmailAccountSelection?: boolean
}

export const SenderSelection = ({
  handleSenderChange,
  userEmailAccounts,
  linkedInAccounts,
  selectedEmailAccount,
  selectedEmailAlias,
  selectedLinkedInAccountId,
  disableEmailAccountSelection = false
}: SenderSelectionProps): JSX.Element => {
  const sendableAddresses = useSendableAddresses()
  const { featureFlags } = useSession()

  const messageSenderDropdownItems = useMemo(() => {
    let items: MenuItemProps[] = userEmailAccounts.flatMap((emailAccount) => {
      const sendable = sendableAddresses.find((sendableAddress) => sendableAddress?.id === emailAccount.id)
      const hasAccessToken = emailAccount.hasAccessToken

      let trailingIcon
      let trailingTooltip
      if (!sendable) {
        trailingIcon = 'lock'
        trailingTooltip = {
          text: "The current user doesn't have permission to send emails as this account. Please request permission and try again."
        }
      } else if (!hasAccessToken) {
        trailingIcon = 'negativeBg'
        trailingTooltip = {
          text: 'Email account not connected.'
        }
      } else {
        trailingIcon = undefined
        trailingTooltip = undefined
      }

      const items = [
        {
          id: emailAccount.id,
          title: emailAccount.email,
          value: emailAccount.id,
          isDisabled: !sendable,
          icon: 'mail',
          trailingTooltip,
          trailingIcon,
          onSelect: () => {
            handleSenderChange(emailAccount, null, null, MessageType.EMAIL)
          }
        }
      ]
      if (!isNil(emailAccount.emailAliases) && emailAccount.emailAliases.length > 0) {
        emailAccount.emailAliases.forEach((emailAlias: { sendAsEmail: string }) => {
          items.push({
            id: `${emailAccount.id}_${emailAlias.sendAsEmail}`,
            title: emailAlias.sendAsEmail,
            value: emailAlias.sendAsEmail,
            isDisabled: !sendable,
            icon: 'mail',
            trailingTooltip,
            trailingIcon,
            onSelect: () => {
              handleSenderChange(emailAccount, emailAlias.sendAsEmail, null, MessageType.EMAIL)
            }
          })
        })
      }
      return items
    })
    // .concat(linkedInAccounts.map((linkedInAccount) => ({
    //   id: linkedInAccount.id,
    //   title: linkedInAccount.unipileUserName + ' (LinkedIn)',
    //   value: linkedInAccount.id,
    //   isDisabled: false,
    //   trailingTooltip: undefined,
    //   trailingIcon: undefined,
    //   onSelect: () => {
    //     const user = linkedInAccount.userId
    //     const emailAccount = userEmailAccounts.find((emailAccount) => emailAccount.userId === user)
    //     if (emailAccount) {
    //       handleSenderChange(emailAccount, null, linkedInAccount)
    //     }
    //   }
    //     })
      // )
      .sort((a, b) => {
        // sort alphabetically
        return a.title.localeCompare(b.title)
      })

    items = sortBy(items, (item) => {
      if (item.isDisabled) {
        return 2
      }
      if (item.trailingIcon === 'negativeBg') {
        return 1
      }
      return 0
    })

    if (featureFlags?.includes(FeatureFlags.LINKEDIN_CONNECTION) && linkedInAccounts?.length > 0) {
      items.push({
        id: 'linkedin-separator',
        title: 'LinkedIn',
        type: 'separator'
      },
      {
        id: 'linkedin-label',
        title: 'LinkedIn',
        type: 'label'
      })

      const linkedInItems = linkedInAccounts.map((linkedInAccount) => ({
        id: linkedInAccount.id,
        title: linkedInAccount.unipileUserName,
        value: linkedInAccount.id,
        icon: <BrandIcon name="linkedinOriginal" color="original" size={12} />,
        isDisabled: false,
        isSelectable: true,
        trailingTooltip: undefined,
        trailingIcon: undefined,
        onSelect: () => {
          const user = linkedInAccount.userId
          const emailAccount = userEmailAccounts.find((emailAccount) => emailAccount.userId === user)
          if (emailAccount) {
            handleSenderChange(emailAccount, null, linkedInAccount, MessageType.LINKEDIN_MAIL)
          }
        }
      }))

      items = items.concat(linkedInItems)
    }

    return items
  }, [userEmailAccounts, sendableAddresses, handleSenderChange, linkedInAccounts])

  const senderLabel = useMemo(() => {
    if (selectedEmailAlias) {
      return selectedEmailAlias
    }
    if (selectedLinkedInAccountId) {
      const unipileUserName = linkedInAccounts
        .find((linkedInAccount) => linkedInAccount.id === selectedLinkedInAccountId)
        ?.unipileUserName
      return !isNil(unipileUserName) ? unipileUserName : ''
    }
    return selectedEmailAccount?.email
  }, [selectedEmailAlias, selectedEmailAccount?.email, selectedLinkedInAccountId, linkedInAccounts])

  return (
    <Dropdown
      trigger={
        <Button
          disabled={disableEmailAccountSelection}
          nested
          $variant="outline"
          $height={24}
          $fontSize={12}
          leadingIcon={
            <>
              {selectedLinkedInAccountId
                ? <BrandIcon name="linkedinOriginal" color="original" size={12} />
                : (
                  <Avatar
                    $shape="circle"
                    $border={false}
                    $size={12}
                    photoUrl={selectedEmailAccount?.profilePhotoUrl}
                    initials={senderLabel?.[0]}
                  />
                  )
              }
            </>
          }
          trailingIcon="chevrons-up-down-small"
        >
          {senderLabel}
        </Button>
      }
      selectedValue={selectedLinkedInAccountId ?? selectedEmailAccount?.id}
      disabled={disableEmailAccountSelection}
      items={messageSenderDropdownItems}
      size="small"
    />
  )
}
