import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { searchSchools } from 'src/libs/api/backend/typeaheads'
import type { SchoolSuggestion } from 'src/libs/api/backend/typeaheads'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  query?: string
}

export const useSchoolSuggestion = ({ query }: Args): UseQueryResult<SchoolSuggestion[]> => {
  return useQuery({
    queryKey: [queryKeys.schoolSuggestions, query],
    queryFn: async () => await searchSchools({ query }),
    staleTime: 60 * 1000,
    enabled: !!query
  })
}
