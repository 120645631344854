import styled from 'styled-components'

export const EmailStatistics = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  margin-top: 0.35rem;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${({ theme, $isOpen }) => $isOpen ? theme.colors.bgTertiary : 'transparent'};
`

export const Trigger = styled.div`
  width: 100%;
`

export const EmailStatisticsDetails = styled.div`
  width: 100%;
`

export const Row = styled.div<{ $isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 26px;
  padding: 0 6px;
  gap: 0.75rem;
  ${({ $isOpen, theme }) => $isOpen && `
    &:nth-child(odd) {
      background-color: ${theme.colors.bgTertiary};
    }
    &:nth-child(even) {
      background-color: ${theme.colors.bgSecondary};
    }
  `}
  p, span {
    line-height: 1;
    user-select: none;
  }
  svg {
    transform: translateY(-1px);
  }
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.borderTranslucent};
    }
  }
`
