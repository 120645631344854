import { useQuery, useMutation } from '@tanstack/react-query'
import { getJobSearchRefinementSuggestedAdditions, getNewJobSuggestedAdditions } from '../../libs/api/backend/jobs'
import { isNil } from 'lodash'
import { useEffect, useRef } from 'react'

interface JobSearchRefinementSuggestedAdditions {
  jobId?: string | null
  jobSearchRefinementId?: string | null
}
export function useJobSearchRefinementSuggestedAdditions ({
  jobId,
  jobSearchRefinementId
}: JobSearchRefinementSuggestedAdditions): Array<{ tag: string, query: string }> | null {
  const { data } = useQuery({
    queryKey: ['getJobSearchRefinementSuggestedAdditions', {
      jobId,
      jobSearchRefinementId
    }] as const,
    queryFn: async (ctx) => {
      const { jobId, jobSearchRefinementId } = ctx.queryKey[1]
      return await getJobSearchRefinementSuggestedAdditions({
        jobId: jobId ?? '',
        jobSearchRefinementId: jobSearchRefinementId ?? ''
      })
    },
    enabled: !isNil(jobId) && !isNil(jobSearchRefinementId)
  })

  const additions = data?.additions.map((addition) => ({
    tag: addition,
    query: addition
  }))
  return additions && additions.length > 0 ? additions : null
}

export function useNewJobSuggestedAdditions ({ jobTitle, jobDescription }: {
  jobTitle: string
  jobDescription: string
}): { additions: Array<{ tag: string, query: string }> | null, refetch: () => void } {
  const paramsRef = useRef({ jobTitle, jobDescription })
  paramsRef.current = { jobTitle, jobDescription }

  const { data, mutate, status } = useMutation({
    mutationFn: async () => {
      const { jobTitle, jobDescription } = paramsRef.current
      return await getNewJobSuggestedAdditions({
        jobTitle,
        jobDescription
      })
    }
  })

  const currentStatusRef = useRef(status)
  currentStatusRef.current = status

  // Debounce refetching by 5s when jobTitle or jobDescription changes
  useEffect(() => {
    if (!jobTitle.trim() || !jobDescription.trim()) {
      return
    }

    const timeout = setTimeout(() => {
      if (currentStatusRef.current !== 'pending') {
        mutate()
      }
    }, 1e3)

    return () => {
      clearTimeout(timeout)
    }
  }, [jobTitle, jobDescription, mutate])

  const additions = data?.additions.map((addition) => ({
    tag: addition,
    query: addition
  }))

  const staleAdditionsRef = useRef(additions)
  staleAdditionsRef.current = additions ?? staleAdditionsRef.current

  return {
    additions: staleAdditionsRef.current && staleAdditionsRef.current.length > 0 ? staleAdditionsRef.current : null,
    refetch: () => { mutate() }
  }
}
