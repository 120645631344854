import { ChartLegend } from '../chart-legend'
import type { ChartLegendProps } from '../chart-legend'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import * as S from './chart-header.styled'
import { Icon } from 'src/components/primitives/icon'

interface ChartHeaderProps {
  title: string
  subtitle?: string
  context?: string
  legend?: ChartLegendProps['data']
  customLegend?: React.ReactNode
  actions?: React.ReactNode
}

export const ChartHeader = ({
  title,
  subtitle,
  legend,
  customLegend,
  context,
  actions
}: ChartHeaderProps): JSX.Element => {
  return (
    <S.ChartHeader>
      <Flex $direction="column" $gap={8}>
        <Caption $color="fgPrimary" size="SM" as="h4">
          {title}
        </Caption>
        {subtitle && (
          <Caption size="LG" as="h5">
            {subtitle}
          </Caption>
        )}
      </Flex>
      <Flex $align="center" $justify="flex-end" $gap={8}>
        {legend && <ChartLegend data={legend} />}
        {customLegend && customLegend}
        {context && (
          <S.Context>
            <Icon name="info" size={16} color="fgTertiary" />
            <Paragraph size="XS" $color="fgSecondary">
              {context}
            </Paragraph>
          </S.Context>
        )}
        {
          actions && (
            <S.Actions>
              {actions}
            </S.Actions>
          )
        }
      </Flex>
    </S.ChartHeader>
  )
}
