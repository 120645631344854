import styled from 'styled-components'

export const JobTile = styled.div<{ $isActive: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing[16]};
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[16]}`};
  border-radius: ${({ theme }) => theme.spacing[6]};
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  box-shadow: ${({ theme, $isActive }) => ($isActive ? theme.boxShadows.focused : 'none')};
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgTertiary};
  }
`
