import { Suspense } from 'react'
import type { ApplicantsCandidatesLoaderData } from 'src/libs/loaders'
import { Await, useLoaderData } from 'react-router-dom'
import * as S from './applicant-candidates-ats.styled'
import { Candidates } from './candidates'

export const ApplicantCandidatesATS = (): JSX.Element => {
  const { atsCandidateIntegrationDetails } = useLoaderData() as ApplicantsCandidatesLoaderData
  return (
    <S.Container>
      <Suspense fallback={<S.BlankLoadingState />}>
        <Await resolve={
          Promise.all([atsCandidateIntegrationDetails])
        }>
          {() => <Candidates />}
        </Await>
      </Suspense>
    </S.Container>
  )
}
