import { Button } from 'src/components/primitives/button'
import { Flex } from 'src/components/primitives/flex'
import type { FlexStyleProps } from 'src/components/primitives/flex'
import { Spacer } from 'src/components/primitives/spacer'
import type { Spacing } from 'src/styles/theme/types'

interface FormActionButtonsProps {
  topSpacing?: Spacing
  saveText?: string
  isSaving?: boolean
  cancelText?: string
  onCancel?: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  $justify?: FlexStyleProps['$justify']
}

export const FormActionButtons = ({
  topSpacing = 36,
  saveText = 'Save',
  isSaving = false,
  cancelText,
  onCancel,
  disabled = false,
  $justify = 'flex-start'
}: FormActionButtonsProps): JSX.Element => {
  return (
    <>
      <Spacer $size={topSpacing} />
      <Flex $justify={cancelText ? 'space-between' : $justify}>
        {cancelText && (
          <Button
            $variant="outline"
            $colorTheme="muted"
            onClick={(event) => {
              if (onCancel) {
                onCancel(event)
              }
            }}
          >
            {cancelText}
          </Button>
        )}
        <Button
          type="submit"
          $variant="fill"
          $colorTheme="tint"
          $height={40}
          loading={isSaving}
          disabled={disabled}
        >
          {saveText}
        </Button>
      </Flex>
    </>
  )
}
