import { BrandIcon } from 'src/components/primitives/brand-icon'
import * as S from './hero-elements.styled'
import { Icon } from 'src/components/primitives/icon'
import { LogoSymbol } from 'src/components/primitives/logo'

export const HeroChromeExtension = (): JSX.Element => {
  return (
    <S.HeroChromeExtension>
      <BrandIcon name="chrome" color="original" size={40} />
    </S.HeroChromeExtension>
  )
}

export const HeroLinkedInPin = (): JSX.Element => {
  return (
    <S.HeroLinkedInPin>
      <S.LinkedInPinIconBox><BrandIcon name="linkedinOriginal" color="original" size={24} /></S.LinkedInPinIconBox>
      <Icon name="arrow-right-left" size={24} color="fgSecondary" />
      <S.LinkedInPinIconBox><LogoSymbol /></S.LinkedInPinIconBox>
    </S.HeroLinkedInPin>
  )
}
