import * as S from './page-with-sidebar.styled'

interface BaseProps {
  children: React.ReactNode
}

interface SidebarProps extends BaseProps {
  isExpanded: boolean
}

interface ContentProps extends BaseProps {
  hasSidebar?: boolean
  isExpanded?: boolean
}

export const Wrapper = ({ children }: BaseProps): JSX.Element => {
  return <S.Wrapper>{children}</S.Wrapper>
}

export const Sidebar = ({ children, isExpanded = true }: SidebarProps): JSX.Element => {
  return <S.Sidebar $isExpanded={isExpanded}>{children}</S.Sidebar>
}

export const Content = ({
  hasSidebar = true,
  children,
  isExpanded = true
}: ContentProps): JSX.Element => {
  return (
    <S.Content $isExpanded={isExpanded} $hasSidebar={hasSidebar}>
      <S.ContentInner $isExpanded={isExpanded} $hasSidebar={hasSidebar}>{children}</S.ContentInner>
    </S.Content>
  )
}
