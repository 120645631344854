import { useMutation } from '@tanstack/react-query'
import { generateLocationCriteriaApi } from 'src/libs/api/backend/candidate_search'
import type { GenerateLocationCriteriaParams } from 'src/libs/api/backend/candidate_search'
import type { LocationSuggestion } from 'src/libs/api/backend/typeaheads'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom } from 'src/stores/notifications'

interface Args extends GenerateLocationCriteriaParams {
  onSuccess?: (data: LocationSuggestion[]) => void
  onError?: () => void
}

interface Res {
  generateLocationCriteria: (args: Args) => void
  isPending: boolean
}

export const useGenerateLocationCriteria = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)
  const mutation = useMutation({
    mutationFn: async ({ onSuccess: _onSuccess, onError: _onError, ...args }: Args) => await generateLocationCriteriaApi(args),
    onError: (err, variables) => {
      notifyError({
        message: `An error occurred when generating location criteria: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (data, variables) => {
      if (variables.onSuccess && data.locations) {
        const parseLocationCriteriaToSuggestions = data.locations.map((criteria) => ({
          name: [criteria.city, criteria.metro, criteria.state].filter(Boolean).join(', '),
          city: criteria.city,
          state: criteria.state,
          metro: criteria.metro,
          optional: criteria.optional,
          negative: criteria.negative
        }))
        variables.onSuccess(parseLocationCriteriaToSuggestions)
      }
    }
  })

  const generateLocationCriteria = (args: Args): void => {
    mutation.mutate(args)
  }

  return { generateLocationCriteria, isPending: mutation.isPending }
}
