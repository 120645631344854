import styled from 'styled-components'

export const CandidateEmailsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[2]};
  border-radius: ${({ theme }) => theme.spacing[6]};
  border: 1px solid ${({ theme }) => theme.colors.bgTertiary};
  width: 100%;

  & > * + * {
    border-top: 1px solid ${({ theme }) => theme.colors.bgTertiary};
  }
`

export const ContactTitle = styled.div`
  flex: 1 1 100%;
  width: 100%;
  height: 0.875rem;
  display: flex;
  align-items: center;
`

export const EmailAddress = styled.li`
  border-radius: 0.25rem;
  padding: ${({ theme }) =>
    `${theme.spacing[6]} ${theme.spacing[8]} ${theme.spacing[6]} ${theme.spacing[12]}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing[8]};
  width: 100%;
`

export const EditEmail = styled.div`
  display: inline-flex;
  width: 100%;
  height: 14px;
  form {
    display: inline-flex;
    flex: 1 1 1px;
    gap: 0.375rem;
    align-items: center;
    width: auto;
    height: 100%;
    div {
      margin: 0 !important;
      flex: 1 1 1px;
    }
    input {
      border: none;
      outline: none;
      background-color: transparent;
      font-size: ${({ theme }) => theme.fontSizes[12]};
      font-weight: ${({ theme }) => theme.fontWeights[500]};
      line-height: ${({ theme }) => theme.lineHeights['1.2']};
      padding: 0;
      margin: 0;
      height: auto;
    }
    p {
      display: none !important;
    }
  }
`
