import { useQueryClient } from '@tanstack/react-query'
import { useGetOrgJoinRequests } from 'src/hooks/queries/use-get-org-join-requests'
import type { OrgJoinRequest } from 'src/libs/api/backend/orgs'
import { InviteeRole, putOrgJoinRequest } from 'src/libs/api/backend/orgs'
import * as OL from 'src/components/forms/options-list/options-list.styled'
import { Avatar } from 'src/components/primitives/avatar'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Button } from 'src/components/primitives/button'
import { Flex } from 'src/components/primitives/flex'
import { Spacer } from 'src/components/primitives/spacer'
import { PageHeader } from 'src/components/blocks/page-header'
import { queryKeys } from 'src/libs/query-keys'
import { useSetAtom } from 'jotai'
import { notifySuccessAtom, notifyErrorAtom } from 'src/stores/notifications'

export const JoinRequests = (): JSX.Element | null => {
  const { joinRequests, refetch: refetchJoinRequests } = useGetOrgJoinRequests()
  const dropdownOptions = [
    { title: 'Admin', value: InviteeRole.ADMIN },
    { title: 'Viewer', value: InviteeRole.VIEWER }
  ]
  const notifySuccess = useSetAtom(notifySuccessAtom)
  const notifyError = useSetAtom(notifyErrorAtom)

  const queryClient = useQueryClient()
  const refetchOrgUsers = (): void => {
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.orgUsers]
    })
  }

  const onAccept = (joinRequest: OrgJoinRequest): void => {
    putOrgJoinRequest(joinRequest.id, { status: 'ACCEPTED' }).then(() => {
      notifySuccess({
        message: 'Join request accepted'
      })
    }).catch((error) => {
      console.error('Error accepting join request', error)
      notifyError({
        message: 'Error accepting join request'
      })
    }).finally(() => {
      refetchJoinRequests()
      refetchOrgUsers()
    })
  }

  const onReject = (joinRequest: OrgJoinRequest): void => {
    console.log('Rejecting join request', joinRequest)
    putOrgJoinRequest(joinRequest.id, { status: 'REJECTED' }).then(() => {
      notifySuccess({
        message: 'Join request rejected'
      })
    }).catch((error) => {
      console.error('Error rejecting join request', error)
      notifyError({
        message: 'Error rejecting join request'
      })
    }).finally(() => {
      refetchJoinRequests()
    })
  }

  const body = <>
    <PageHeader heading="Join requests" />
    {joinRequests.length === 0 ? <p>No join requests</p> : null}
    <OL.OptionsList>
      {joinRequests.map((joinRequest) => (
        <OL.Option key={joinRequest.id}>
          <OL.OptionAvatar>
            <Avatar $type="photo" initials={joinRequest.user.name} photoUrl={joinRequest.user.profilePhoto} />
          </OL.OptionAvatar>
          <OL.OptionLabel>
            {joinRequest.user.emailAccounts.length ? <p>{joinRequest.user.emailAccounts[0].email}</p> : null}
            <p>{joinRequest.user.name}</p>
          </OL.OptionLabel>
          <Flex $align="center" $justify="flex-end" $gap={12}>
            <Dropdown
              trigger={
                <Button
                  $variant="raised"
                  $colorTheme="muted"
                  $height={24}
                  $fontSize={12}
                  nested
                  trailingIcon="chevrons-up-down-small"
                >
                  Approve
                </Button>
              }
              items={dropdownOptions.map((option) => ({
                title: option.title,
                value: option.value,
                onSelect: () => { onAccept(joinRequest) }
              }))}
              size="small"
            />
            <Button
              type="submit"
              $height={24}
              $fontSize={12}
              onClick={() => { onReject(joinRequest) }}
            >
              Reject
            </Button>
          </Flex>
        </OL.Option>
      ))}
    </OL.OptionsList>
    <Spacer $size={40} />
  </>
  if (joinRequests.length === 0) {
    return null
  } else {
    return body
  }
}
