import styled from 'styled-components'

export const NotificationSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
  margin-top: ${({ theme }) => theme.spacing[12]};
  width: 100%;
`

export const NotificationPreferenceTile = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[24]};
  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[16]}`};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: ${({ theme }) => theme.spacing[4]};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  justify-content: space-between;
  align-items: center;
`
