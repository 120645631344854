import { useCallback, useEffect, useState } from 'react'
import { z } from 'zod'

type FieldName = string
type BaseValueTypes =
  | string
  | number
  | string[]
  | Array<{ title: string, value: string }>
  | File
  | boolean
  | undefined
  | null
export type FieldValue<T = unknown> = T extends BaseValueTypes ? T : T | BaseValueTypes
export type FormData<T> = Record<FieldName, FieldValue<T>>
export type FormErrors = Record<FieldName, string>
export interface RegisterField {
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onSelect: (value: FieldValue) => void
  onBlur: () => void
  value: FieldValue
  error: string
}
interface FieldArgs {
  isRequired?: {
    message: string
  }
  isValid?: {
    pattern: string | RegExp
    message: string
  }
}
type ValidationSchema = Record<FieldName, FieldArgs>

interface Args<T> {
  initialValues?: FormData<T> | undefined
  validationSchema?: ValidationSchema
  schema?: z.Schema<T>
}

interface UseFormReturn<T> {
  setValue: (name: FieldName, value: FieldValue<T>) => void
  setValues: (newValues: Partial<FormData<T>>, validateFields?: boolean) => void
  formData: FormData<T>
  submit: (cb?: (formData: T) => void | Promise<void>) => (event: React.FormEvent<HTMLFormElement>) => void
  register: (name: FieldName) => RegisterField
  errors: FormErrors
  formError: string | null
  reset: (values?: FormData<T>) => void
  isValid: boolean
}

export const useForm = <T>({ initialValues, validationSchema, schema }: Args<T>): UseFormReturn<T> => {
  const [blurredField, setBlurredField] = useState<FieldName | null>(null)
  const [formData, setFormData] = useState<FormData<T>>(initialValues ?? ({} satisfies FormData<T>))
  const [errors, setErrors] = useState<FormErrors>({})
  const [formError, setFormError] = useState<string | null>(null)
  const [isValid, setIsValid] = useState<boolean>(true)

  useEffect(() => {
    if (Object.values(errors).every((error) => error.length === 0)) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [errors])

  // Set a single form field value
  const setValue = useCallback((name: FieldName, value: FieldValue<T>): void => {
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const validateField = useCallback((name: FieldName, value: FieldValue<T>): string | undefined => {
    // First check, if there is a zod schema provided,
    // if not, check for a custom `validationSchema`.
    if (schema) {
      try {
        schema.parse({ ...formData, [name]: value })
      } catch (e) {
        if (e instanceof z.ZodError) {
          // Extract error for the specific field
          const fieldError = e.errors.find((error) => error.path[0] === name)
          if (fieldError) {
            return fieldError.message
          }
          // Handle cross-field validation errors
          if (e.errors.some((error) => error.path.length === 0)) {
            return e.errors.find((error) => error.path.length === 0)?.message ?? 'Validation error'
          }
        }
      }
      return ''
    } else {
      const fieldSchema = validationSchema?.[name]

      // Don't check if there was no schema provided
      if (!fieldSchema) {
        return
      }

      // Check first, if a field is required
      if (fieldSchema.isRequired && (!value || value === '')) {
        return fieldSchema.isRequired.message
      }

      // Check if the input is actually valid
      if (fieldSchema.isValid) {
        const pattern = new RegExp(fieldSchema.isValid.pattern)
        if (!pattern.test(String(value))) {
          return fieldSchema.isValid.message
        }
      }
      return ''
    }
  }, [formData, schema, validationSchema])

  // Set multiple form values at once
  const setValues = useCallback((newValues: Partial<FormData<T>>, validateFields?: boolean): void => {
    setFormData((prevFormData) => {
      const updatedFormData = Object.entries(newValues).reduce<FormData<T>>(
        (acc, [key, value]) => {
          if (value !== undefined) {
            acc[key] = value
          }
          return acc
        },
        { ...prevFormData }
      )

      return updatedFormData
    })

    if (validateFields) {
      const newErrors = { ...errors }
      for (const name in newValues) {
        if (newValues[name] !== undefined) {
          const error = validateField(name, newValues[name] as FieldValue<T>)
          if (error) {
            newErrors[name] = error
          } else {
            Reflect.deleteProperty(newErrors, name)
          }
        }
      }
      setErrors(newErrors)
    }
  }, [errors, setErrors, validateField])

  // Register the boilerplate code for every field,
  // e.g. event handlers
  const register = useCallback((name: FieldName): RegisterField => ({
    // For traditional `onChange` events on form fields
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value: FieldValue<T> = event.target.value as FieldValue<T>
      if (event.target.type === 'number' && value) {
        value = parseInt(event.target.value, 10) as FieldValue<T>
      }
      setValue(name, value)
    },
    // A more custom approach, e.g. for multi select or dropdown
    // or other fields that might require a custom handler
    // This currently also handles file uploads, we might
    // want to refactor this later to a custom `onFileChange` handler.
    onSelect: (value: FieldValue) => {
      setValue(name, value as FieldValue<T>)
      if (errors[name]) {
        const error = validateField(name, value as FieldValue<T>)
        setErrors((prev) => ({
          ...prev,
          [name]: error ?? ''
        }))
      }
    },
    onBlur: () => {
      // We need to set the recently blurred field
      // so we can run the validation again
      // in a side effect, which is required to get
      // access to the latest state.
      setBlurredField(name)
      const error = validateField(name, formData[name])
      if (error) {
        setErrors((prev) => ({
          ...prev,
          [name]: error || ''
        }))
      }
    },
    value: formData[name] || '',
    error: errors[name] || ''
  }), [errors, formData, setValue, validateField])

  // On blur we want to revalidate a field again,
  // to avoid using stale date, we're running a side
  // effect to use the latest state.
  useEffect(() => {
    if (blurredField !== null) {
      const error = validateField(blurredField, formData[blurredField])
      setErrors((prev) => ({
        ...prev,
        [blurredField]: error ?? ''
      }))
      setBlurredField(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blurredField, formData])

  const reset = useCallback((values?: FormData<T>) => {
    setFormData(values ?? initialValues ?? ({} satisfies FormData<T>))
    setErrors({})
    setBlurredField(null)
  }, [initialValues])

  const submit = useCallback((cb?: (formData: T) => void | Promise<void>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      setFormError(null)

      // First check for a zod schema, only then for
      // a `validationSchema`. If neither is provided,
      // we won't run a validation on the form data.
      if (schema) {
        try {
          schema.parse(formData)
        } catch (e: any) {
          // Please leave this console.log for debugging purposes
          // otherwise zod errors will be swallowed by the void
          console.log('[useForm] onSubmit error', e)
          const newErrors: FormErrors = {}
          e.errors.forEach((e: any) => {
            const fieldName = e.path?.[0]
            if (fieldName) {
              newErrors[fieldName] = e.message
            } else {
              // If no field name could be found, it's a "global" error for the whole form
              // This is especially useful for using `.refine()` on a zod schema which is
              // validating the whole form data.
              setFormError(e.message)
            }
          })
          setErrors(newErrors)
          return
        }
      }

      if (validationSchema) {
        const newErrors: Record<FieldName, string> = {}
        for (const name of Object.keys(validationSchema)) {
          const value = formData[name]
          const fieldError = validateField(name, value)
          if (fieldError) {
            newErrors[name] = fieldError
          }
        }
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors)
          return
        }
      }

      if (cb) {
        void cb(formData as T)
      }
    }
  }, [formData, schema, validateField, validationSchema])

  return {
    setValue,
    setValues,
    formData,
    submit,
    register,
    errors,
    formError,
    reset,
    isValid
  }
}
