import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import type { NodeViewWrapperProps } from '@tiptap/react'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import * as S from './refinement-component.styled'
import React from 'react'

export const RefinementComponent = React.memo((props: NodeViewWrapperProps): JSX.Element => {
  const { data } = props.node.attrs
  try {
    const { supported, value } = JSON.parse(data as string)
    return (
      <NodeViewWrapper className='editor-refinement-block'>
        <RadixTooltip.Provider delayDuration={100}>
          <RadixTooltip.Root>
            <S.Trigger asChild={false}>
              <NodeViewContent
                contentEditable={false}
                className={
                  supported
                    ? 'editor-refinement-highlight'
                    : 'editor-refinement-unmatched-highlight'
                }
              />
            </S.Trigger>
            {supported
              ? value && (
                  <RadixTooltip.Portal>
                    <S.Content align='center' sideOffset={2} $variant='dark'>
                      {value}
                    </S.Content>
                  </RadixTooltip.Portal>
              )
              : (
                  <RadixTooltip.Portal>
                    <S.Content align='center' sideOffset={4} $variant='dark'>
                      Pin cannot search for this yet
                    </S.Content>
                  </RadixTooltip.Portal>
                )
            }
          </RadixTooltip.Root>
        </RadixTooltip.Provider>
      </NodeViewWrapper>
    )
  } catch (error) {
    return (
      <NodeViewWrapper className='editor-refinement-block'>
        <NodeViewContent contentEditable={false} className='editor-refinement-unmatched-highlight' />
      </NodeViewWrapper>
    )
  }
})
