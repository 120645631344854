const lowercaseWords = new Set([
  'at', 'by', 'in', 'of', 'off', 'on', 'per', 'to', 'up', 'via',
  'and', 'if', 'nor', 'yet', 'and', 'as', 'but', 'for', 'nor',
  'or', 'so', 'the'
])

export const titleCase = (str: string): string => {
  return str
    .toLowerCase()
    .trim() // trim whitespace on the edge
    .split(' ') // split by word
    .map((word, index) => { // correctly captialize words in the title
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      } else if (lowercaseWords.has(word)) {
        return word.toLowerCase()
      } else if (word.includes('-')) {
        const phrase = word.split('-')
        return phrase
          .map((subword) => {
            return subword.charAt(0).toUpperCase() + subword.slice(1)
          })
          .join('-')
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1)
      }
    })
    .join(' ') // concatenate the words back together
}

export const kebabCase = (str: string): string => {
  return str
    .trim() // trim whitespace on the edge
    .split(' ') // split by word
    .map((word) => word.toLocaleLowerCase()) // lowercase all words
    .join('-') // delimit with `-` character
}
