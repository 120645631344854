import { useMutation } from '@tanstack/react-query'
import { updateCandidateNote as updateCandidateNoteApi } from 'src/libs/api/backend/candidate_activities'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom } from 'src/stores/notifications'

interface Args {
  candidateId: string
  noteId: string
  body: string
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  updateCandidateNote: (args: Args) => void
}

export const useUpdateCandidateNote = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)

  const mutation = useMutation({
    mutationFn: async ({ candidateId, noteId, body }: Args) => await updateCandidateNoteApi(candidateId, noteId, body),
    onError: (err, variables) => {
      notifyError({
        message: `An error occurred while updating a note on your candidate: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (_, variables) => {
      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })

  const updateCandidateNote = (args: Args): void => {
    mutation.mutate(args)
  }

  return { updateCandidateNote }
}
