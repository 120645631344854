import styled from 'styled-components'

export const FilterInput = styled.input`
  height: 20px;
  padding: 0 ${({ theme }) => theme.spacing[4]};
  font-size: ${({ theme }) => theme.fontSizes[14]};
  line-height: 20px;
  flex: 1;
  outline: none;
  border: none;
  &:focus {
    border: none;
  }
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing[2]};
`
