import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { Caption } from 'src/components/primitives/typography'
import type { Color } from 'src/styles/theme/types'
import { Flex } from 'src/components/primitives/flex'
import * as S from './stats-card.styled'

interface StatsCardProps {
  icon: IconName
  description: string
  count: number
  change: string
}

interface ChangeVariant {
  fgColor: Color
}

export const StatsCard = ({ icon, description, count, change }: StatsCardProps): JSX.Element => {
  const getChangeColor = (): ChangeVariant => {
    if (change.startsWith('+')) {
      return {
        fgColor: 'positiveBg'
      }
    }
    if (change.startsWith('-')) {
      return {
        fgColor: 'negativeBg'
      }
    }
    return {
      fgColor: 'fgTertiary'
    }
  }
  return (
    <S.StatsCard>
      <Flex $gap={12}>
        <Icon name={icon} size={12} color="fgTertiary" />
        <S.Content>
          <Caption size="XS" $color="fgSecondary">
            {description}
          </Caption>
          <Caption size="XS" $color={getChangeColor().fgColor}>
            {change}
          </Caption>
        </S.Content>
      </Flex>
      <S.Count>{count}</S.Count>
    </S.StatsCard>
  )
}
