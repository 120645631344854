import styled from 'styled-components'
import * as RadixCheckbox from '@radix-ui/react-checkbox'

export const Checkbox = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const CheckboxRoot = styled(RadixCheckbox.Root)`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  width: 1rem;
  height: 1rem;
  border-radius: 4px;
  border: solid 0.5px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
  &:focus {
    border-color: ${({ theme }) => theme.colors.tintBg};
  }
`

export const CheckboxIndicator = styled(RadixCheckbox.CheckboxIndicator)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes[14]};
  line-height: ${({ theme }) => theme.lineHeights['1.43']};
  white-space: nowrap;
`
