import { NavLink } from 'react-router-dom'
import { HEADER_HEIGHT, HEADER_PADDING } from 'src/styles/constants'
import styled from 'styled-components'

interface InboxSidebarProps {
  $isHovered: boolean
  $isPinned: boolean
}

export const InboxSidebar = styled.nav<InboxSidebarProps>`
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: ${({ theme, $isHovered, $isPinned }) =>
    $isHovered && !$isPinned ? theme.boxShadows.lg : theme.boxShadows.sm};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 6px;

  h1 {
    line-height: ${HEADER_HEIGHT};
    margin-bottom: ${HEADER_PADDING};
  }
`

export const Items = styled.div<{ $isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  height: 100%;
  contain: strict;
  padding: 0.5rem 0.75rem;
  flex: 1;
`

export const ItemsInner = styled.div`
  position: relative;
  height: auto;
  /* We need to account for padding and height of the header */
  min-height: calc(100% - (0.5rem + 0.5rem + 0.625rem));
  overflow: auto;
`

export const Item = styled(NavLink)`
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: 0.25rem;
  border: solid 1px;
  border-color: transparent;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  @media (hover: hover) {
    &:hover {
      border-color: ${({ theme }) => theme.colors.tintTranslucent25};
      background-color: ${({ theme }) => theme.colors.bgSecondary};
    }
  }
  &.active {
    border-color: ${({ theme }) => theme.colors.tintTranslucent25};
    background-color: ${({ theme }) => theme.colors.bgSecondary};
  }
`

export const Empty = styled.div`
  height: 100%;
  border: dashed 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
`

export const ItemTile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1 1 auto;
  overflow: hidden;
`

export const TileTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
`

export const Favorite = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
`
