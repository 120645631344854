import styled from 'styled-components'
import { SIZES } from './constants'

export const Wrapper = styled.div`
  position: relative;
  width: calc(100% - 40px);
  flex: 1 1 10%;
  height: 100%;
`

export const Days = styled.div<{ $isDisabled: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  pointer-events: ${({ $isDisabled }) => $isDisabled ? 'none' : 'auto'};
`

export const Header = styled.header`
  position: sticky;
  top: 0;
  height: ${SIZES.SUBHEADER.LG}px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  z-index: 2;
`

export const HeaderCell = styled.div<{ $isToday: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  overflow: hidden;
  border-right: solid 1px;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.fgTranslucent10};
  span {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.fgSecondary};
    line-height: 1;
    font-weight: 500;
    text-transform: uppercase;
    &:nth-child(2) {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme, $isToday }) =>
        theme.colors[$isToday ? 'negativeBg' : 'bgPrimary']};
      color: ${({ theme, $isToday }) => theme.colors[$isToday ? 'bgPrimary' : 'fgSecondary']};
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 999px;
    }
  }
`

export const Day = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(24, 1fr);
  border-right: solid 1px;
  border-color: ${({ theme }) => theme.colors.fgTranslucent10};
  position: relative;
`
