import { useState } from 'react'
import { When } from '../when'
import type { EntryProps } from './timeline-entry'
import { Entry } from './timeline-entry'
import { Button } from 'src/components/primitives'
import * as S from './timeline.styled'
import type { Spacing } from 'src/styles/theme/types'

export interface TimelineProps extends Pick<EntryProps, 'avatarFallbackIcon' | '$padding'> {
  entries: EntryProps[]
  max?: number
  isCollapsible?: boolean
  isPrintView?: boolean
}

export interface MoreButttonStyleProps {
  $padding?: {
    left: Spacing
    right: Spacing
  }
}

export const Timeline = ({
  isCollapsible = false,
  entries,
  max = 0,
  avatarFallbackIcon,
  $padding,
  isPrintView = false
}: TimelineProps): JSX.Element => {
  // We want to avoid having a "1 more" notice at the end,
  // so instead we'll display all if there is only one more item
  // left and ignore the max specification.
  const shouldIgnoreMax = max !== 0 && entries.length - max === 1
  const cappedEntries = max === 0 || shouldIgnoreMax ? entries : entries.slice(0, max)

  const [displayedItems, setDisplayedItems] = useState(cappedEntries)
  const [showMore, setShowMore] = useState(
    // !shouldIgnoreMax && entries.length > cappedEntries.length
    !shouldIgnoreMax && entries.length > cappedEntries.length && entries.length - max > 0
  )

  return (
    <S.Timeline>
      {displayedItems?.map((entry, index) => (
        <Entry
          key={`${entry.heading.title}-${entry.heading.description}-${index}`}
          {...entry}
          avatarFallbackIcon={avatarFallbackIcon}
          $padding={$padding}
          isCollapsible={isCollapsible}
          isPrintView={isPrintView}
        />
      ))}
      <When condition={showMore && entries.length - cappedEntries.length > 0}>
        <S.MoreButton $padding={$padding}>
          <Button
            $variant="outline"
            $colorTheme="muted"
            $height={24}
            $width="auto"
            $fontSize={12}
            onClick={() => {
              setDisplayedItems(entries)
              setShowMore(false)
            }}
          >
            Show {entries.length - cappedEntries.length} other positions
          </Button>
        </S.MoreButton>
      </When>
    </S.Timeline>
  )
}
