import { Flex } from 'src/components/primitives/flex'
import * as S from './connect-to-linkedin-steps.styled'
import { BenefitsList } from '../benefits-list'
import { Caption, Paragraph } from '../../primitives/typography'
import { Button } from 'src/components/primitives/button'
import { IntegrationsLayout } from 'src/components/layouts/integrations'
import { Card } from 'src/components/primitives/card'
import { HeroLinkedInPin } from './hero-elements'
import { OnboardingNavigation } from '../onboarding-navigation'
import { useEffect, useMemo, useState } from 'react'
import { useExtensionHasPermissions, useLinkedInSignedInStatus } from 'src/libs/extension'
import type { ResponseSchemas } from 'src/libs/extension'
import { useMeLinkedInAccounts } from 'src/hooks/use-me-linked-in-accounts'
import { fetchLinkedInAccessTokenAndProfileData } from 'src/libs/api/backend/me_linkedin_accounts'
import { isNil } from 'lodash'
import { Icon } from 'src/components/primitives/icon'
import { Signal } from 'src/components/primitives/signal'
import { When } from '../when'
import RouteBuilder from 'src/libs/route-builder'
import { PinExtensionChromeStoreUrl } from 'src/constants'
import { usePinExtensionVersion, getPinExtensionVersion } from 'src/hooks/use-pin-extension'

const BUTTON_WIDTH = '96px'

type Steps = 'CHROME_EXTENSION' | 'CONNECT_TO_LINKEDIN' | 'CONNECTED'
export interface ConnectToLinkedInStepsProps {
  step: Steps
}

interface ConnectToLinkedInStepProps {
  isLoggedInOnLinkedIn: boolean
  userHasConnectedLinkedInAccount: boolean
  action: React.ReactNode
}

const ConnectAccountStep = ({ action, isLoggedInOnLinkedIn, userHasConnectedLinkedInAccount }: ConnectToLinkedInStepProps): JSX.Element => {
  const [data, setData] = useState<ResponseSchemas<'RPC_EVENT_FETCH_LINKEDIN_ACCESS_TOKEN_AND_PROFILE_DATA'> | null>(null)
  const [_isLoading, setIsLoading] = useState(false)
  const [_error, setError] = useState<Error | null>(null)

  useEffect(() => {
    setIsLoading(true)
    fetchLinkedInAccessTokenAndProfileData()
      .then((response) => {
        setData(response)
      })
      .catch((error: Error) => {
        console.error('[error]', error)
        setError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [isLoggedInOnLinkedIn])

  return (
    <S.Step>
      <Flex $align="center" $gap={16} $width="auto">
        <S.StepIcon>
          {!isNil(data) && !isNil(data?.profileData?.profilePhotoUrl)
            ? (
              <S.LinkedInAvatar $isConnected={userHasConnectedLinkedInAccount}>
                <img src={data?.profileData?.profilePhotoUrl} alt="LinkedIn profile picture" />
              </S.LinkedInAvatar>
              )
            : (
                <Icon name="circle-dashed" size={24} color="fgTertiary" />
              )
        }
        </S.StepIcon>
        <Paragraph size="MD" $color="fgPrimary" $whiteSpace="nowrap">
          {!isNil(data) && !isNil(data?.profileData?.firstName)
            ? <>{`${data?.profileData?.firstName} ${data?.profileData?.lastName}`} on LinkedIn</>
            : 'Connect your LinkedIn account'
          }
        </Paragraph>
      </Flex>
        {action}
    </S.Step>
  )
}

const ChromeExtensionStep = (): JSX.Element => {
  const extensionVersion = usePinExtensionVersion()
  const [hasClickedExtensionStoreLink, setHasClickedExtensionStoreLink] = useState(false)
  const [hasExtensionInstalled, setHasExtensionInstalled] = useState(false)

  useEffect(() => {
    setHasExtensionInstalled(!isNil(extensionVersion))
  }, [extensionVersion])

  const handleVerifyInstallation = (): void => {
    // This is a quite dirty, but sometimes we do not get back the version from the extension after installing.
    const version = getPinExtensionVersion()
    if (isNil(version)) {
      window.location.reload()
    } else {
      setHasExtensionInstalled(true)
    }
  }

  return (
    <IntegrationsLayout>
      <Flex $direction="column" $gap={40}>
        <Flex $direction="column" $gap={24}>
          <Flex $direction="column" $align="flex-start" $gap={4}>
            <Caption as="h1" size="LG" $align="center">
              Chrome Extension
            </Caption>
            <Paragraph $align="center" size="SM" $color="fgSecondary">
              Supercharge your LinkedIn workflow with Pin
            </Paragraph>
          </Flex>
        </Flex>
        <BenefitsList
          $itemSize="small"
          benefits={[
            {
              title: 'Connect your LinkedIn account to Pin',
              description: 'Send InMail through Pin, view candidate connections and more…',
              icon: 'linkedin'
            },
            {
              title: 'Easily add candidates from LinkedIn to Pin',
              description: "Get Pin's full AI analysis and add them to an outreach sequence.",
              icon: 'mouse-pointer-click'
            },
            {
              title: 'Show InMail messages on your candidate communication history',
              description: 'View both email and InMail messages in the same place.',
              icon: 'inbox'
            }
          ]}
        />
        <Flex $direction='column' $gap={12} $align="center">
          <S.ChromeExtensionActions>
            <Button
              $variant="fill"
              $colorTheme="tint"
              $height={40}
              $width="full"
              $align="center"
              trailingIcon={!hasExtensionInstalled ? 'square-arrow-out-up-right' : undefined}
              href={!hasExtensionInstalled ? PinExtensionChromeStoreUrl : RouteBuilder.build('INTEGRATIONS_LINKEDIN_CONNECT')}
              onClick={() => { setHasClickedExtensionStoreLink(true) }}
            >
              {!hasExtensionInstalled ? 'Install Chrome Extension' : 'Continue'}
            </Button>
            <Button
              $variant="outline"
              $colorTheme="muted"
              $height={40}
              $width="full"
              $align="center"
              href={RouteBuilder.build('ROOT')}
            >
              Cancel without installing
            </Button>
          </S.ChromeExtensionActions>
          <When condition={hasClickedExtensionStoreLink && !hasExtensionInstalled}>
            <Button
              $variant="ghost"
              $colorTheme="muted"
              $height={40}
              $width="auto"
              $align="center"
              onClick={handleVerifyInstallation}
            >
              Verify installation
            </Button>
          </When>
        </Flex>
      </Flex>
    </IntegrationsLayout>
  )
}

interface GrantPermissionsStepProps {
  extensionHasPermissions: boolean
}

const GrantPermissionsStep = ({ extensionHasPermissions }: GrantPermissionsStepProps): JSX.Element => {
  return (
    <S.Step>
      <Flex $align="center" $gap={16}>
        <S.StepIcon>
          <Icon name={extensionHasPermissions ? 'check-circle-2' : 'circle-dashed'} size={24} color={extensionHasPermissions ? 'positiveBg' : 'fgTertiary'} />
        </S.StepIcon>
        <Paragraph size="MD" $color="fgPrimary" $align="center">
          Allow Pin to access cookies
        </Paragraph>
      </Flex>
      {
        !extensionHasPermissions && (
          <Button
            $variant="fill"
            $colorTheme="tint"
            $height={32}
            $width={BUTTON_WIDTH}
            $align="center"
            id="extension-grant-permission"
          >
            Allow
          </Button>
        )
      }
    </S.Step>
  )
}

export const ConnectToLinkedInSteps = ({ step }: ConnectToLinkedInStepsProps): JSX.Element => {
  const [isConnecting, setIsConnecting] = useState(false)
  const isLoggedInOnLinkedIn = useLinkedInSignedInStatus()
  const extensionPermissions = useExtensionHasPermissions()

  const extensionHasPermissions = useMemo(() => extensionPermissions?.hasCookiesPermission, [extensionPermissions?.hasCookiesPermission])

  const { linkedInAccounts, createAccount, deleteAccount } = useMeLinkedInAccounts()
  const userHasConnectedLinkedInAccount = linkedInAccounts.length > 0

  if (step === 'CHROME_EXTENSION') {
    return <ChromeExtensionStep />
  }

  return (
    <>
      <OnboardingNavigation showCurrentStep={false} showPinLogo={true} />
      <S.ConnectToLinkedInWrapper>
        <HeroLinkedInPin />
        <S.ConnectToLinkedInContent>
          <Caption as="h1" size="LG" $align="center">
            Connect your LinkedIn to Pin
          </Caption>
          <Card $padding={{ top: 0, bottom: 0, left: 0, right: 0 }}>
            <GrantPermissionsStep
              extensionHasPermissions={extensionHasPermissions}
            />
            <ConnectAccountStep
              isLoggedInOnLinkedIn={Boolean(isLoggedInOnLinkedIn)}
              userHasConnectedLinkedInAccount={userHasConnectedLinkedInAccount}
              action={
                extensionHasPermissions && !isLoggedInOnLinkedIn
                  ? (
                      <Button
                        $variant="fill"
                        $colorTheme="tint"
                        $height={32}
                        $width="auto"
                        $align="center"
                        href="https://www.linkedin.com/login/"
                      >
                        Log in on LinkedIn
                      </Button>
                    )
                  : extensionHasPermissions && isLoggedInOnLinkedIn && !userHasConnectedLinkedInAccount
                    ? (
                        <Button
                          $variant="fill"
                          $colorTheme="tint"
                          $height={32}
                          $width="auto"
                          $align="center"
                          id="extension-connect-linkedin"
                          onClick={async () => {
                            setIsConnecting(true)
                            await createAccount()
                            setIsConnecting(false)
                          }}
                          loading={isConnecting}
                        >
                          Connect
                        </Button>
                      )
                    : extensionHasPermissions && isLoggedInOnLinkedIn && userHasConnectedLinkedInAccount
                      ? (
                          <Flex $align="center" $gap={12} $width="auto"><Signal $color="positiveFg" /><Caption size="SM" $color="positiveFg">Connected</Caption></Flex>
                        )
                      : (
                          <Button
                            $variant="outline"
                            $colorTheme="muted"
                            $height={32}
                            $width="auto"
                            $align="center"
                            // id="extension-connect-linkedin"
                            disabled
                          >
                            Connect
                          </Button>
                        )
                  }
            />
            <When condition={Boolean(isLoggedInOnLinkedIn) && !userHasConnectedLinkedInAccount}>
              <S.WrongAccountBanner>
                <Paragraph size="XS" $color="fgSecondary">
                  Wrong account? Log in to the correct account on LinkedIn and refresh this page.{' '}
                  <a href={window.location.href}>Refresh</a>
                </Paragraph>
              </S.WrongAccountBanner>
            </When>
          </Card>
          <When condition={userHasConnectedLinkedInAccount}>
            <Flex $direction="column" $align="center" $gap={40}>
              <Caption size="MD" $align="center" $color="positiveFg">Successfully connected. You may close this tab.</Caption>
              <Flex $align="center" $gap={12} $justify="center">
                <Button
                  $variant="outline"
                  $colorTheme="negative"
                  $height={40}
                  $width="auto"
                  $align="center"
                  id="extension-disconnect-linkedin"
                  onClick={async () => { await deleteAccount() }}
                >
                  Disconnect
                </Button>
                <Button
                  $variant="raised"
                  $colorTheme="muted"
                  $height={40}
                  $width="auto"
                  $align="center"
                  leadingIcon="house"
                  href={RouteBuilder.build('ROOT')}
                >
                  Back to Pin.com
                </Button>
              </Flex>
            </Flex>
          </When>
          <When condition={!userHasConnectedLinkedInAccount}>
            <BenefitsList
              $itemSize="small"
              benefits={[
                {
                  title: 'Send and automate InMail through Pin',
                  description: 'Make InMail part of your outreach sequence.',
                  icon: 'messages-square'
                },
                {
                  title: 'Get more information on candidates',
                  description: 'View candidate connections and more without leaving Pin.',
                  icon: 'user-circle'
                },
                {
                  title: 'Show InMail messages on your candidate communication history',
                  description: 'View both email and InMail messages in the same place.',
                  icon: 'inbox'
                }
              ]}
            />
          </When>
        </S.ConnectToLinkedInContent>
      </S.ConnectToLinkedInWrapper>
    </>
  )
}
