import type { JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { sanitizeRefinementCriteria } from 'src/utils/refinement-criteria'
import { useMemo } from 'react'
import { Caption } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives'
import { CriteriaRow } from '../refinement-filter-criteria/criteria-row'
import type { CriteriaKey } from 'src/libs/api/backend/candidate_search'
import * as S from './refinement-filter.styled'
interface RefinementFilterSummaryProps {
  jobSearchRefinement: JobSearchRefinement
}

export const RefinementFilterSummary = ({ jobSearchRefinement }: RefinementFilterSummaryProps): JSX.Element => {
  const initialCriteria = useMemo(() => {
    return sanitizeRefinementCriteria(jobSearchRefinement.searchCriteria)
  }, [jobSearchRefinement])
  const criteriaOrder = useMemo(() => Object.keys(initialCriteria) as CriteriaKey[], [initialCriteria])
  return (
    <S.SummaryWrapper>
      <Caption size='SM'>Criteria</Caption>
      <Flex $direction='column' $gap={4}>
        {initialCriteria && criteriaOrder?.map((criteriaKey) => {
          return (
            <CriteriaRow
              key={criteriaKey}
              criteriaKey={criteriaKey}
              criteriaValue={initialCriteria[criteriaKey]}
              onCriteriaUpdate={() => {}}
              onCriteriaRemove={() => {}}
              updated={false}
              disabled={false}
            />
          )
        })}
      </Flex>
    </S.SummaryWrapper>
  )
}
