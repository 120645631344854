import { useEffect, useRef } from 'react'
import type { HTMLProps } from 'react'
import { Icon } from '../icon'
import * as S from './table.styled'

interface IndeterminateCheckboxProps extends HTMLProps<HTMLInputElement> {
  indeterminate?: boolean
}

export const IndeterminateCheckbox = ({
  indeterminate, onClick, name, checked, onChange, ...rest
}: IndeterminateCheckboxProps): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      if (ref.current) {
        ref.current.indeterminate = !checked && indeterminate
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, indeterminate])

  return (
    <S.Checkbox onClick={onClick}>
      <input
        type="checkbox"
        ref={ref}
        id={name}
        onChange={onChange}
        checked={checked ?? false}
        {...rest}
      />
      <label htmlFor={name} />
      <S.CheckboxIcon>
        <Icon name="check" color="bgPrimary" size={12} />
        <Icon name="minus" color="bgPrimary" size={12} />
      </S.CheckboxIcon>
    </S.Checkbox>
  )
}
