import type { Spacing } from 'src/styles/theme/types'
import * as S from './toggle.styled'

export interface ToggleStyleProps {
  $toggleSize?: Spacing
  $isDisabled?: boolean
}

interface ToogleProps extends ToggleStyleProps {
  name: string
  checked: boolean
  onChange: () => void
  disabled?: boolean
}

export const Toggle = ({
  name,
  checked,
  onChange,
  $toggleSize = 16,
  disabled
}: ToogleProps): JSX.Element => {
  return (
    <S.ToggleButton
      type="button"
      role="switch"
      id={name}
      aria-checked={checked ? 'true' : 'false'}
      value={checked ? 'on' : 'off'}
      $isChecked={checked}
      $toggleSize={$toggleSize}
      onClick={() => {
        onChange()
      }}
      disabled={disabled}
      $isDisabled={disabled}
    >
      <S.ToggleHandle $isChecked={checked} $toggleSize={$toggleSize} />
    </S.ToggleButton>
  )
}
