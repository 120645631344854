import { useMutation, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import {
  deleteEmailAccount as deletEmailAccountByEmailId
} from 'src/libs/api/backend/me'
import type { EmailAccount } from 'src/libs/api/backend/session'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface Args {
  emailAccountId: string
  onSuccess?: (emailAccounts: EmailAccount[]) => void
  onError?: () => void
}

interface Res {
  deleteEmailAccount: ({ emailAccountId, onSuccess, onError }: Args) => void
}

export const useDeleteEmailAccount = (): Res => {
  const queryClient = useQueryClient()

  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)

  const mutation = useMutation({
    mutationFn: async ({ emailAccountId }: Args) => {
      return await deletEmailAccountByEmailId(emailAccountId)
    },
    onError: (error, variables) => {
      console.error(error)
      notifyError({
        message: `An error occurred while deleting your email account: ${error.message}`
      })

      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (emailAccounts, variables) => {
      notifySuccess({
        message: 'Successfully deleted email account.'
      })

      if (variables.onSuccess) {
        variables.onSuccess(emailAccounts)
      }

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.session]
      })
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.orgUsers]
      })
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.jobStats]
      })
    }
  })

  const deleteEmailAccount = (args: Args): void => {
    mutation.mutate(args)
  }

  return { deleteEmailAccount }
}
