import styled from 'styled-components'

export const BannerText = styled.div`
  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[24]}`};
  background-color: ${({ theme }) => theme.colors.tintFaded};
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`

export const DialogInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[12]}`};
  padding-top: 0;
  flex: 1 1 auto;
`

export const FeedbackTitle = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 0;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  height: 48px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.bgTertiary}`};

  input {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: 500;
    color: ${({ theme }) => theme.colors.fgSecondary};
    border: none;
    outline: none;
    flex: 1 1 auto;
  }
`

export const FeedbackContent = styled.div`
  flex-grow: 1;
`

export const FeedbackLabel = styled.span`
  padding: 0 4px;
  font-size: ${({ theme }) => theme.fontSizes[12]};
`

export const FeedbackUploading = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.spacing[24]};
  gap: 8px;
`

export const Toolbar = styled.div`
  width: 100%;
  // height: 2.5rem;
  display: flex;
  align-items: center;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  z-index: 2;
  gap: ${({ theme }) => theme.spacing[24]};
  padding: 0.75rem;
`
