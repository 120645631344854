import * as S from './grid.styled.ts'
import type { Spacing } from 'src/styles/theme/types'

export interface GridBaseProps {
  $columns?: 12 | 8 | 6 | 3 | 2 | 1
  $columnGap?: Spacing
  $rowGap?: Spacing
  $width?: string
}

interface GridProps extends GridBaseProps {
  children: React.ReactNode
}

export const Grid = ({ children, $columns = 12, $columnGap = 0, $rowGap = 0, $width = 'auto' }: GridProps): JSX.Element => {
  return (
    <S.Grid $columns={$columns} $columnGap={$columnGap} $rowGap={$rowGap} $width={$width}>
      {children}
    </S.Grid>
  )
}
