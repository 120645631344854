import { useEffect, useState } from 'react'
import { fetchOrgInvite } from 'src/libs/api/backend/invites'
import type { OrgInvite } from 'src/libs/api/backend/invites'

export const useGetOrgInvite = (inviteId: string, tokenId: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [data, setData] = useState<OrgInvite | undefined>(undefined)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const orgInvite = await fetchOrgInvite(inviteId, tokenId)
        setIsLoading(false)
        setError(null)
        setData(orgInvite)
      } catch (e) {
        setIsLoading(false)
        setError('Error getting org invite')
      }
    }
    void fetchData()
  }, [inviteId, tokenId])

  return { isLoading, data, error }
}
