import * as Dialog from 'src/components/primitives/dialog'
import { Flex } from 'src/components/primitives/flex'
import { Button } from 'src/components/primitives/button'
import type { ButtonStyleProps } from 'src/components/primitives/button'
import { Caption } from 'src/components/primitives/typography'
import * as S from './alert-dialog.styled'
import { Spacer } from 'src/components/primitives/spacer'
import { useTheme } from 'styled-components'
import { controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import type { AlertArgs } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'
import { useMemo } from 'react'

interface AlertDialogProps extends AlertArgs {}

export const AlertDialog = ({
  variant = 'negative',
  message,
  description,
  confirmText = 'Continue',
  cancelText = 'Cancel',
  isModal = true,
  onConfirm,
  onCancel
}: AlertDialogProps): JSX.Element => {
  const theme = useTheme()
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.ALERT), []))
  const controlDialog = useSetAtom(controlDialogAtom)

  const getButtonColorTheme = (): ButtonStyleProps['$colorTheme'] => {
    switch (variant) {
      case 'negative': {
        return 'negative'
      }
      case 'tint': {
        return 'tint'
      }
      default: {
        return 'tint'
      }
    }
  }

  return (
    <Dialog.Root
      id={DialogId.ALERT}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.ALERT, newState: value })
      }}
      $width="480px"
      modal={isModal}
    >
      <Dialog.Portal onCancel={onCancel} zIndex={theme.zindeces.alert as number}>
        <Dialog.Content>
          <S.AlertDialogInner>
            <Caption size="MD" $color="fgPrimary">
              {message}
            </Caption>
            {description && (
              <Caption size="SM" $color="fgSecondary" $align="center" $fontWeight={400}>
                {description}
              </Caption>
            )}
            <Spacer $size={12} />
            <Flex $gap={12}>
              <Button
                $variant="raised"
                $colorTheme="muted"
                $width="full"
                $align="center"
                onClick={() => {
                  controlDialog({ id: DialogId.ALERT, newState: false })
                  if (onCancel) {
                    onCancel()
                  }
                }}
              >
                {cancelText}
              </Button>
              <Button
                $variant="raised"
                $colorTheme={getButtonColorTheme()}
                $width="full"
                $align="center"
                onClick={() => {
                  controlDialog({ id: DialogId.ALERT, newState: false })
                  if (onConfirm) {
                    onConfirm()
                  }
                }}
              >
                {confirmText}
              </Button>
            </Flex>
          </S.AlertDialogInner>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
