import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { getProjects } from 'src/libs/api/backend/projects'
import type { Project } from 'src/libs/api/backend/projects'
import { queryKeys } from 'src/libs/query-keys'

export const useProjectsQuery = (): UseQueryResult<Project[]> => {
  return useQuery({
    queryKey: [queryKeys.projects],
    queryFn: async () => await getProjects(),
    staleTime: 60 * 1000
  })
}
