import { useMutation, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { CandidateJobStage, rejectCandidateJobs } from 'src/libs/api/backend/candidate_jobs'
import type {
  CandidateJobExpanded,
  CandidateJobRejectionReason
} from 'src/libs/api/backend/candidate_jobs'
import { isNil, keyBy } from 'lodash'
import { useSetAtom } from 'jotai'
import { notifyAtom, notifyErrorAtom } from 'src/stores/notifications'
import type { CandidateSequenceStepMessageReviewQueryResult } from '../queries/use-candidate-sequence-step-message-review'
import { useParams } from 'react-router-dom'

interface Args {
  candidateJobIds: string[]
  rejectionReason?: CandidateJobRejectionReason
}

interface Res {
  rejectCandidate: (args: Args) => void
}

export const useRejectCandidate = (): Res => {
  const queryClient = useQueryClient()
  const { jobId } = useParams()

  const notifyError = useSetAtom(notifyErrorAtom)
  const notify = useSetAtom(notifyAtom)

  const mutation = useMutation({
    mutationFn: async ({ candidateJobIds, rejectionReason }: Args) => {
      return await rejectCandidateJobs(candidateJobIds, rejectionReason)
    },
    onMutate: ({ candidateJobIds, rejectionReason }) => {
      if (!isNil(jobId)) {
        queryClient.setQueriesData<CandidateJobExpanded[]>(
          { queryKey: [queryKeys.candidateJobs, jobId] },
          (oldCandidateJobs) => {
            if (isNil(oldCandidateJobs)) {
              return oldCandidateJobs
            }

            return oldCandidateJobs.map((o) => {
              if (candidateJobIds.includes(o.id)) {
                return { ...o, stage: CandidateJobStage.REJECTED, rejectionReason: rejectionReason ?? null }
              }
              return o
            })
          }
        )
      }
    },
    onError: (err) => {
      notifyError({
        message: `An error occurred when rejecting your candidate: ${err.message}`
      })
    },
    onSuccess: async (updatedCandidateJobs) => {
      const jobIds = new Set(updatedCandidateJobs.map((o) => o.jobId))
      const updatedCandidateJobsById = keyBy(updatedCandidateJobs, 'id')
      for (const jobId of jobIds) {
        queryClient.setQueriesData<CandidateJobExpanded[]>(
          { queryKey: [queryKeys.candidateJobs, jobId] },
          (oldCandidateJobs) => {
            if (isNil(oldCandidateJobs)) {
              return oldCandidateJobs
            }

            return oldCandidateJobs.map((o) => {
              if (updatedCandidateJobsById[o.id]) {
                return {
                  ...o,
                  ...updatedCandidateJobsById[o.id]
                }
              }

              return o
            })
          }
        )

        const archivedCandidateJobIds = Object.keys(updatedCandidateJobsById)
        queryClient.setQueriesData<CandidateSequenceStepMessageReviewQueryResult>({
          queryKey: [queryKeys.candidateSequenceStepMessageReviews, jobId]
        }, (oldCandidateSequenceStepMessageReviews) => {
          if (isNil(oldCandidateSequenceStepMessageReviews)) {
            return oldCandidateSequenceStepMessageReviews
          }

          return {
            ...oldCandidateSequenceStepMessageReviews,
            pages: oldCandidateSequenceStepMessageReviews.pages.map((page) => {
              return page.filter((o) => {
                return !archivedCandidateJobIds.includes(o.candidateJob.id)
              })
            })
          }
        })
      }

      for (const updatedCandidateJob of updatedCandidateJobs) {
        queryClient.setQueriesData<CandidateJobExpanded[]>(
          { queryKey: [queryKeys.candidateJob, updatedCandidateJob.id] },
          (oldCandidateJob) => {
            if (isNil(oldCandidateJob)) {
              return oldCandidateJob
            }

            return {
              ...oldCandidateJob,
              ...updatedCandidateJob
            }
          }
        )
      }

      notify({
        type: 'candidate-rejected',
        variant: 'neutral',
        payload: updatedCandidateJobs
        // position: 'bottom-right',
        // icon: 'check-check',
        // message: 'Candidate rejected'
      })
    },
    onSettled: async (updatedCandidateJobs) => {
      if (updatedCandidateJobs) {
        const jobIds = new Set(updatedCandidateJobs.map((o) => o.jobId))
        await Promise.all([
          ...Array.from(jobIds).map(async (jobId: string) => {
            await queryClient.invalidateQueries({
              queryKey: [queryKeys.candidateJobCounts, jobId]
            })
          }),
          ...Array.from(jobIds).map(async (jobId: string) => {
            await queryClient.invalidateQueries({
              queryKey: [queryKeys.candidateJobs, jobId]
            })
          })
        ])
      }
    }
  })

  const rejectCandidate = (args: Args): void => {
    mutation.mutate(args)
  }

  return { rejectCandidate }
}
