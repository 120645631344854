import { useEffect, useState } from 'react'
import { z } from 'zod'
import { Form, Input } from 'src/components/forms'
import { useForm } from 'src/hooks/use-form'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { useUpdateCandidate } from 'src/hooks/mutations/use-update-candidate'
import { InputGroup } from 'src/components/forms/input-group/input-group'
import type { InputGroupItem } from 'src/components/forms/input-group/input-group'
import { Badge } from 'src/components/primitives/badge'
import type { IconName } from 'src/components/primitives/icon'
import { FormActionButtons } from '../form-action-buttons'
import { closeDialogAtom, DialogId } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'

const candidateSchema = z.object({
  name: z.string(),
  location: z.string().nullable(),
  emailAddresses: z.string().nullable()
})

export type CandidateSchema = z.infer<typeof candidateSchema>

interface EditCandidateFormProps {
  candidateJob: CandidateJobExpanded
}

export const EditCandidateForm = ({ candidateJob }: EditCandidateFormProps): JSX.Element => {
  const [initialEmailAddresses, setInitialEmailAddresses] = useState<InputGroupItem[]>()
  const [emailAddresses, setEmailAddresses] = useState<InputGroupItem[]>([])
  const closeDialog = useSetAtom(closeDialogAtom)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { updateCandidateJob } = useUpdateCandidate(candidateJob.id)

  const { submit, errors, register, setValue } = useForm<CandidateSchema>({
    schema: candidateSchema
  })

  const handleSubmit = async (formData: CandidateSchema): Promise<void> => {
    setIsSubmitting(true)
    const emails: string[] = emailAddresses.map(item => item.value)
    // const emails =
    //   formData?.emailAddresses
    //     ?.split(',')
    //     .map((e) => e.trim())
    //     .filter((e) => e !== '') ?? []
    try {
      updateCandidateJob({
        updatedCandidate: {
          id: candidateJob.candidate.id,
          emails,
          ...formData
        }
      })
    } catch (e) {
      console.error(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (candidateJob) {
      setValue('name', candidateJob.candidate.name)
      setValue('emailAddresses', candidateJob.candidate.emails?.join(', '))
      setValue('location', candidateJob.candidate.location)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateJob])

  useEffect(() => {
    const emails = candidateJob.candidate.emails?.map((email) => {
      return {
        id: crypto.randomUUID(),
        value: `${email}`,
        context: <Badge $variant="plain">Primary</Badge>
      }
    })
    setInitialEmailAddresses(emails)
  }, [candidateJob])

  return (
    <>
      <Form onSubmit={submit(handleSubmit)} errors={errors}>
        <Input
          name="name"
          placeholder="Name"
          label="Name"
          register={register}
        />
        <InputGroup
          initialValues={initialEmailAddresses}
          label="Email addresses"
          name="emailAddresses"
          register={register}
          addValueFieldType="email"
          addValuePlaceholder="Add email"
          onDataChange={(values) => {
            setEmailAddresses(values)
          }}
          itemActions={(items, index, cb) =>
            index >= 1
              ? [
                  {
                    id: 'make-primary',
                    title: 'Make primary',
                    icon: 'mail' as IconName,
                    onSelect: () => {
                      const item = items.splice(index, 1)[0]
                      items.unshift(item)
                      if (cb) {
                        cb(items)
                      }
                    }
                  }
                ]
              : []
          }
          itemContext={(_, index) => (index === 0 ? <Badge $variant="plain" $transform="capitalize">Primary</Badge> : null)}
        />
        <Input
          name="location"
          placeholder="Candidate location"
          label="Location"
          register={register}
          $marginBottom={0}
        />
        <FormActionButtons
          saveText="Save changes"
          isSaving={isSubmitting}
          cancelText="Cancel"
          onCancel={() => {
            closeDialog(DialogId.EDIT_CANDIDATE)
          }}
        />
        {/*
        <Spacer $size={24} />
        <Flex $justify="space-between">
          <Button
            $variant="outline"
            $colorTheme="muted"
            onClick={() => {
              closeDialog(DialogId.EDIT_CANDIDATE)
            }}
          >
            Cancel
          </Button>
          <Button $variant="fill" $colorTheme="tint" type="submit" loading={isSubmitting}>
            Save changes
          </Button>
        </Flex>
        */}
      </Form>
    </>
  )
}
