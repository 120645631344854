import styled from 'styled-components'

export const StatsCard = styled.div`
  flex: 1 1 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.75rem 1rem 0.625rem 0.75rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  svg {
    margin-top: 1px;
  }
`

export const Count = styled.p`
  font-size: 1.625rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.fgSecondary};
  font-weight: 700;
  text-align: right;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`
