import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SequenceEditor } from './editor'
import type { SequenceEditorProps } from './editor'
import { When } from '../when'
import { SequenceInstructions } from './instructions'
import type { SequenceInstructionsForm } from './instructions'

interface EmailSequenceEditorProps extends SequenceEditorProps {}

enum VIEW {
  INSTRUCTIONS = 'instructions',
  EDITOR = 'editor'
}

export const EmailSequenceEditor = ({
  initialState,
  shouldAutoGenerateEmails = true,
  isEditable = true,
  actions = {},
  ...editorProps
}: EmailSequenceEditorProps): JSX.Element => {
  const { pathname } = useLocation()
  const [view, setView] = useState<VIEW>(
    pathname.includes('jobs/new') ? VIEW.INSTRUCTIONS : VIEW.EDITOR
  )
  const [sequenceInstructions, setSequenceInstructions] = useState<SequenceInstructionsForm>({
    contactAttempts: '3',
    emailTone: 'Casual',
    emailLength: 'Short'
  })

  return (
    <>
      <When condition={view === VIEW.INSTRUCTIONS}>
        <SequenceInstructions
          onContinue={({ instructions }) => {
            setView(VIEW.EDITOR)
            if (instructions) {
              setSequenceInstructions(instructions)
            }
          }}
        />
      </When>
      <When condition={view === VIEW.EDITOR}>
        <SequenceEditor
          sequenceInstructions={sequenceInstructions}
          shouldAutoGenerateEmails={shouldAutoGenerateEmails}
          initialState={initialState}
          isEditable={isEditable}
          actions={actions}
          {...editorProps}
        />
      </When>
    </>
  )
}
