import { EmailSettings } from 'src/components/blocks/email-settings'
import { PageHeader } from 'src/components/blocks/page-header'
import { SEO } from 'src/components/primitives/seo'

const EmailPreferencesPage = (): JSX.Element => {
  return (
    <>
      <PageHeader heading="Email Preferences" />
      <SEO title="Email Preferences" />
      <EmailSettings />
    </>
  )
}

export default EmailPreferencesPage
