import styled, { css, keyframes } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.spacing[4]};
  background: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  gap: ${({ theme }) => theme.spacing[8]};
  padding: ${({ theme }) => theme.spacing[8]};
  margin-bottom: 20px;
`

export const TopStatsBlock = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[12]};
  align-items: center;
  width: 100%;
`

export const BottomStatsBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.1; }
`
export const TopStatFilterButton = styled.div<{ $isActive: boolean, $isLoading: boolean }>`
  display: flex;
  padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[12]}`};
  gap: ${({ theme }) => theme.spacing[12]};
  justify-content: space-between;
  height: 40px;
  border-radius: ${({ theme }) => theme.spacing[4]};
  border: 1px solid transparent;
  ${({ $isActive, theme }) => $isActive && css`
    border: 1px solid ${theme.colors.tintTranslucent25};
    background: ${theme.colors.tintTranslucent5};
  `}
  ${({ $isLoading, theme }) => $isLoading && css`
    background: ${theme.colors.bgTertiary};
    animation: ${blink} 1s linear infinite;
  `}
  @media (hover: hover) {
    ${({ $isActive, theme }) => !$isActive && css`
      &:hover {
        background: ${theme.colors.bgTertiary};
        border-color: ${theme.colors.borderTranslucent};
      }
    `}
  }
`

export const Suffix = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[2]};
`

export const Separator = styled.div`
  width: 1px;
  height: 15px;
  background: ${({ theme }) => theme.colors.borderTranslucent};
`

// Seems redundant but it's needed to mimic the border for clip-path
export const ArrowFilterButton = styled.div<{
  $firstFilter: boolean
  $lastFilter: boolean
  $isActive: boolean
  $isLoading: boolean
}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  height: 40px;
  ${({ $firstFilter, $lastFilter }) => $firstFilter
    ? css`
        border-top-left-radius: ${({ theme }) => theme.spacing[4]};
        border-bottom-left-radius: ${({ theme }) => theme.spacing[4]};
        clip-path: polygon(
          0 0,
          calc(100% - 12px) 0%,
          100% 50%,
          calc(100% - 12px) 100%,
          0 100%,
          0% 0%
        );
      `
    : $lastFilter
      ? css`
          border-top-right-radius: ${({ theme }) => theme.spacing[4]};
          border-bottom-right-radius: ${({ theme }) => theme.spacing[4]};
          clip-path: polygon(
            100% 0%,
            100% 100%,
            0% 100%,
            12px 50%,
            0% 0%
          );
        `
      : css`
          clip-path: polygon(
            0 0,
            calc(100% - 12px) 0%,
            100% 50%,
            calc(100% - 12px) 100%,
            0 100%,
            12px 50%,
            0 0
          );
        `
  }
  ${({ $isActive, theme }) => $isActive && css`
    background: ${theme.colors.tintTranslucent25};
  `}
  ${({ $isLoading }) => $isLoading && css`
    animation: ${blink} 1s linear infinite;
  `}
  @media (hover: hover) {
    ${({ $isActive, theme }) => !$isActive && css`
      &:hover {
        background: ${theme.colors.borderTranslucent};
      }
    `}
  }
`

export const ArrowFilterButtonContent = styled.div<{
  $firstFilter: boolean
  $lastFilter: boolean
  $isActive: boolean
}>`
  position: absolute;
  top: 1px;
  left: 2px;
  height: calc(100% - 2px); // leave room for border
  width: calc(100% - 3px); // leave room for border
  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
  align-items: center;
   ${({ $firstFilter, $lastFilter }) => $firstFilter
    ? css`
        padding-left: ${({ theme }) => theme.spacing[12]};
        padding-right: ${({ theme }) => theme.spacing[24]};
        border-top-left-radius: ${({ theme }) => theme.spacing[4]};
        border-bottom-left-radius: ${({ theme }) => theme.spacing[4]};
        left: 1px;
        width: calc(100% - 2px); // leave room for border
        clip-path: polygon(
          0 0,
          calc(100% - 12px) 0%,
          100% 50%,
          calc(100% - 12px) 100%,
          0 100%,
          0% 0%
        );
      `
    : $lastFilter
      ? css`
          padding-left: ${({ theme }) => theme.spacing[24]};
          padding-right: ${({ theme }) => theme.spacing[12]};
          border-top-right-radius: ${({ theme }) => theme.spacing[4]};
          border-bottom-right-radius: ${({ theme }) => theme.spacing[4]};
          clip-path: polygon(
            100% 0%,
            100% 100%,
            0% 100%,
            12px 50%,
            0% 0%
          );
        `
      : css`
          padding-left: ${({ theme }) => theme.spacing[24]};
          padding-right: ${({ theme }) => theme.spacing[24]};
          clip-path: polygon(
            0 0,
            calc(100% - 12px) 0%,
            100% 50%,
            calc(100% - 12px) 100%,
            0 100%,
            12px 50%,
            0 0
          );
        `
  }
  ${({ $isActive }) => $isActive && css`
    background: hsl(222,83%,98%); // Need to be a solid color to avoid leaking through the clip path
  `}
  @media (hover: hover) {
    ${({ $isActive, theme }) => !$isActive && css`
      &:hover {
        background: ${theme.colors.bgTertiary};
      }
    `}
  }
`
