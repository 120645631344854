import { useParams } from 'react-router-dom'
import { PageHeader } from 'src/components/blocks/page-header'
import { EmailSequenceEditor } from 'src/components/blocks/email-sequence-editor'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { useUpsertJobSequence } from 'src/hooks/mutations/use-upsert-job-sequence'
import { Button } from 'src/components/primitives/button'
import { isNil } from 'lodash'
import { EmptyState } from 'src/components/blocks/empty-state'
import type { EmailSequence } from 'src/models/sequence'
import { SequenceContentWrapper, SequenceWrapper } from 'src/components/layouts/settings/settings.styled'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { SEO } from '../../../components/primitives/seo'
import { useSetAtom } from 'jotai'
import { openDialogAtom, DialogId } from 'src/stores/dialogs'

const JobSettingsSequencePage = (): JSX.Element => {
  const { jobId } = useParams()
  const { isPending, data: emailSequence } = useJobSequenceQuery(jobId)
  const { upsertJobSequence } = useUpsertJobSequence()
  const openDialog = useSetAtom(openDialogAtom)

  if (isPending || isNil(jobId)) {
    return <></>
  }

  if (isSequenceStepsEmpty(emailSequence)) {
    return (
      <>
        <SEO title="Outreach sequence" />
        <SequenceContentWrapper>
          <EmptyState
            heading="Outreach sequence"
            description="Compose multiple outreach emails that will be send to the candidates automatically, to confirm their interest with the position."
            svg="emailSequence"
            $padding={{ top: 0, right: 0, bottom: 0, left: 0 }}
            actions={[
              {
                children: 'Create outreach sequence',
                $variant: 'fill',
                $colorTheme: 'tint',
                onClick: () => {
                  openDialog({ id: DialogId.CREATE_SEQUENCE, payload: 'OPTIONS' })
                }
              }
            ]}
          />
        </SequenceContentWrapper>
      </>
    )
  }

  return (
    <>
      <SEO title="Outreach sequence" />
      <SequenceContentWrapper>
        <PageHeader
          heading="Outreach sequence"
          actions={
            <Button
              $variant="outline"
              $colorTheme="tint"
              $height={24}
              $fontSize={12}
              leadingIcon={emailSequence?.active ? 'pause-circle' : 'play-circle'}
              onClick={() => {
                const newState = !emailSequence?.active
                upsertJobSequence({
                  jobId,
                  active: newState,
                  toastMessage: newState ? 'Enabled sending outreach emails' : 'Paused sending outreach emails'
                })
              }}
              disabled={isSequenceStepsEmpty(emailSequence)}
            >
              {emailSequence?.active ? 'Pause' : 'Resume'} outreach
            </Button>
          }
        />
        <SequenceWrapper>
          <EmailSequenceEditor
            key={jobId}
            initialState={emailSequence as EmailSequence}
            shouldAutoGenerateEmails={true}
            // isEditable={!emailSequence.active}
            actions={{
              save: {
                children: 'Save outreach sequence'
              }
            }}
          />
        </SequenceWrapper>
      </SequenceContentWrapper>
    </>
  )
}

export default JobSettingsSequencePage
