import axios from 'axios'
import { isNil } from 'lodash'
import { generateUploadUrlApi } from 'src/libs/api/backend/url'
import type { GenerateUploadUrl } from 'src/libs/api/backend/url'

export const PNG_MIME_TYPE = 'image/png'
export const PROFILE_MAX_WIDTH = 500

const validFileTypes: Record<string, string> = {
  'image/png': 'png',
  'image/jpg': 'jpg',
  'image/jpeg': 'jpeg',
  'image/gif': 'gif',
  'image/webp': 'webp'
} as const

export const getFileExt = (mimetype: string): string => {
  const fileType = validFileTypes[mimetype]
  if (isNil(fileType)) {
    console.error(`Mimetype of [${mimetype}] must conform to ${Object.keys(validFileTypes).join(', ')}`)
    return mimetype
  }
  return fileType
}

export const MAX_PROFILE_FILE_SIZE = 1 * 1024 * 1024

export const getCompressedQuality = (imageSize: number, maxSize: number): number => {
  return imageSize < maxSize ? 1 : Math.round(maxSize / imageSize * 10) / 10
}

const uploadFile = async (url: string, data: Blob, fileType: string): Promise<void> => {
  try {
    await axios.put(url, data, {
      headers: {
        'Content-Type': fileType
      }
    })
  } catch (error) {
    console.error('Failed to upload image', error)
  }
}

interface CompressAndGenerateUrlOptions {
  file: File | null
  fileType?: string
  maxWidth?: number
  quality?: number
}

export const compressAndGenerateUrl = async ({ file, fileType, maxWidth, quality }: CompressAndGenerateUrlOptions): Promise<GenerateUploadUrl | null> => {
  if (isNil(file)) {
    return null
  }
  // Get as image data
  const imageBitmap = await createImageBitmap(file)

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  let newWidth = imageBitmap.width
  let newHeight = imageBitmap.height
  if (maxWidth && imageBitmap.width > maxWidth) {
    const aspectRatio = imageBitmap.width / imageBitmap.height
    // Calculate new dimensions
    newWidth = maxWidth
    newHeight = maxWidth / aspectRatio
  }
  canvas.height = newHeight
  canvas.width = newWidth
  ctx?.drawImage(imageBitmap, 0, 0, newWidth, newHeight)

  const mimeType = fileType ?? file.type
  const blob = await new Promise<Blob | null>((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob)
    }, mimeType, quality ?? 1)
  })
  if (isNil(blob)) {
    return null
  }
  const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.')
  const newName = `${fileNameWithoutExtension}.${getFileExt(mimeType)}`
  const response = await generateUploadUrlApi(newName)
  await uploadFile(response.uploadSignedUrl, blob, mimeType)
  return response
}
