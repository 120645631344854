import { z } from 'zod'
import Api from '..'

export const domainAllowListForm = z.object({
  allowDomainJoin: z.boolean()
})

export type DomainAllowListForm = z.infer<typeof domainAllowListForm>

export enum InviteeRole {
  ADMIN = 'admin',
  VIEWER = 'viewer',
}

export const orgParser = z.object({
  id: z.string().uuid(),
  name: z.string(),
  domain: z.string(),
  allowDomainJoin: z.boolean(),
  logoUrl: z.string().nullish(),
  mergeAtsIntegration: z.string().nullish(),
  stripeSubscriptionActiveUntil: z.coerce.date().nullish(),
  trialActiveUntil: z.coerce.date().nullish(),
  createdAt: z.coerce.date()
})

export type Org = z.infer<typeof orgParser>

const existingOrgParser = orgParser.extend({
  firstOrgUser: z.object({
    name: z.string()
  }),
  orgUserCount: z.number()
})

export type ExistingOrg = z.infer<typeof existingOrgParser>

export async function fetchExistingOrgs (): Promise<ExistingOrg[]> {
  const response = await Api.get('/org/find_existing')
  return z.array(existingOrgParser).parse(response.data)
}

export interface UpdateOrg extends Org {
  newLogo: {
    sourceKey: string
  } | null
}

export async function updateOrg (fields: Partial<UpdateOrg>): Promise<Org> {
  const { data } = await Api.patch('/org', null, {
    org: fields
  })

  return orgParser.parse(data)
}

export interface CreateOrg {
  name: string
  domain: string
  mission?: string | null
  newLogo?: {
    sourceKey: string
  } | null
  country: string
  city: string
  industries: string[]
}

export async function createOrg (fields: CreateOrg): Promise<Org> {
  const { data } = await Api.post('/org', null, {
    org: fields
  })

  return orgParser.parse(data)
}

export async function joinOrg (orgId: string): Promise<void> {
  await Api.post('/org/join', null, { orgId })
}

export const orgJoinParser = z.object({
  id: z.string().uuid(),
  orgId: z.string().uuid(),
  userId: z.string().uuid(),
  user: z.object({
    id: z.string().uuid(),
    name: z.string(),
    profilePhoto: z.string().nullish(),
    emailAccounts: z.array(z.object({
      email: z.string()
    }))
  }),
  status: z.enum(['PENDING', 'ACCEPTED', 'REJECTED'])
})

export type OrgJoinRequest = z.infer<typeof orgJoinParser>

export async function getOrgJoinRequests (): Promise<OrgJoinRequest[]> {
  const response = await Api.get('/org/join_requests')
  return z.array(orgJoinParser).parse(response.data)
}

export async function putOrgJoinRequest (orgJoinRequestId: string, data: Record<string, unknown>): Promise<void> {
  await Api.put(`/org/join_requests/${orgJoinRequestId}`, null, data)
}

export const closePinAccountFeedbackParser = z.object({
  reason: z.string(),
  reasonDetails: z.string().nullish(),
  feedbackCall: z.boolean()
})

export type ClosePinAccountFeedbackArgs = z.infer<typeof closePinAccountFeedbackParser>

export async function closePinAccount (args: ClosePinAccountFeedbackArgs): Promise<void> {
  const { status } = await Api.delete('/org', args)
  if (status === 204) {
    window.location.href = '/'
  } else {
    throw new Error('Failed to close account')
  }
}
