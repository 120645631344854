import styled from 'styled-components'

export interface TooltipRowProps {
  $color?: string
}

export const TooltipContainer = styled.div`
  min-width: 150px;
  display: flex;
  flex-direction: column;
  padding: 0.8rem 1rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05), 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
  gap: ${({ theme }) => theme.spacing[4]};
`

export const TooltipTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[14]};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.fgPrimary};
`

export const TooltipRow = styled.div<TooltipRowProps>`
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 0.5rem; */
  color: ${({ $color }) => $color};
  span {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: 500;
    &:last-child {
      font-variant-numeric: tabular-nums;
    }
  }
`
