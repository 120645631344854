import { useQuery } from '@tanstack/react-query'
import { fetchOrgInviteDirectory } from 'src/libs/api/backend/invites'
import { queryKeys } from 'src/libs/query-keys'

export const useOrgInviteDirectory = () => {
  return useQuery({
    queryKey: [queryKeys.orgInviteDirectory],
    queryFn: fetchOrgInviteDirectory,
    staleTime: 60 * 1000
  })
}
