import type { ZIndex } from './types'

export const zindeces: Record<ZIndex, number> = {
  outerspace: 999999,
  alert: 99999,
  toast: 9999,
  dropdown: 9999,
  dialog: 999,
  navigation: 99,
  menu: 99
}
