import { Button, Flex } from 'src/components/primitives'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { When } from '../when'
import { Badge } from 'src/components/primitives/badge'
import * as S from './timeline-entry-title.styled'
import type { EntryProps } from '.'
import { isNil } from 'lodash'
import { formatLatestFundingStage } from 'src/libs/api/backend/candidate_jobs'
import { useRateCompanyPreference } from 'src/hooks/mutations/use-rate-company-preference'
import { Fragment, useCallback } from 'react'
import { CompanyPreferenceType } from 'src/libs/api/backend/company_preferences'
import { useParams } from 'react-router-dom'
import { useCompaniesPreferences } from 'src/hooks/use-companies-preferences'
import { useDeleteCompanyPreference } from 'src/hooks/mutations/use-delete-company-preference'
import { useSession } from 'src/hooks/use-session'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { AvatarsList } from '../avatars-list'
import { Icon } from 'src/components/primitives/icon'
import { Tooltip } from 'src/components/primitives/tooltip'
import { useCurrentDepartment } from 'src/hooks/queries/use-current-department'

interface TimelineEntryTitleProps extends Pick<EntryProps, 'heading'> {
  isCollapsible?: boolean
  isCollapsed: boolean
  isPrintView?: boolean
  onSetIsCollapsed?: () => void
}

export const TimelineEntryTitle = ({
  heading,
  isCollapsible = true,
  isCollapsed,
  isPrintView = false
  // onSetIsCollapsed
}: TimelineEntryTitleProps): JSX.Element => {
  const { jobId } = useParams()
  const { companyPreferencesByLinkedin } = useCompaniesPreferences()
  const { featureFlags } = useSession()
  const { department } = useCurrentDepartment()
  const { title, description, website, linkedin, tags, companyInformation, overlappingEmployees } = heading
  const { setRateCompanyPreference } = useRateCompanyPreference()
  const { setDeleteCompanyPreference } = useDeleteCompanyPreference()
  const showQuickFacts =
    !isNil(companyInformation?.founded) ||
    !isNil(companyInformation?.headCount) ||
    !isNil(companyInformation?.latestFundingStage)

  const capitalizedTitle = title.charAt(0).toUpperCase() + title.slice(1)

  const fundingStage = formatLatestFundingStage(companyInformation?.latestFundingStage)

  const hiddenContentVariants = {
    closed: { opacity: 0, height: 0, transition: { duration: 0.1 } },
    open: { opacity: 1, height: 'auto', transition: { duration: 0.1 } }
  }

  const companyPreferenceType = linkedin ? companyPreferencesByLinkedin[linkedin]?.type : undefined

  const rateCompany = useCallback((type: CompanyPreferenceType) => {
    if (linkedin && jobId) {
      const preferenceId = companyPreferencesByLinkedin[linkedin]?.id
      if (companyPreferenceType === type && preferenceId) {
        setDeleteCompanyPreference({ id: preferenceId })
      } else {
        setRateCompanyPreference({
          id: preferenceId,
          jobId,
          type,
          name: companyInformation?.name ?? title,
          linkedin
        })
      }
    }
  }, [
    companyInformation?.name,
    companyPreferenceType,
    companyPreferencesByLinkedin,
    jobId,
    linkedin,
    setDeleteCompanyPreference,
    setRateCompanyPreference,
    title
  ])

  const preferenceButtonIsDisabled = !companyInformation || !jobId || !linkedin || linkedin?.startsWith('/school')

  const hasOverlappingEmployees = overlappingEmployees && overlappingEmployees.length > 0

  return (
    <S.TimelineEntryTitle data-name="EntryTitle" $isCollapsed={isCollapsed} $isCollapsible={isCollapsible}>
      <S.Inner>
        <S.VisibleContent>
          <S.EntryTitle>
            <Caption size="XS" $whiteSpace="nowrap" $ellipsis={true}>
              {capitalizedTitle}
            </Caption>
          </S.EntryTitle>
          <S.EntryAttributes>
            {companyPreferenceType && (
              <Flex $flex="0 0 16px" $align="center" $direction="column">
                <Icon
                  name={
                    companyPreferenceType === CompanyPreferenceType.LIKED
                      ? 'thumbs-up-fill'
                      : 'thumbs-down-fill'
                  }
                  color={
                    companyPreferenceType === CompanyPreferenceType.LIKED
                      ? 'positiveFg'
                      : 'negativeFg'
                  }
                  size={12}
                />
              </Flex>
            )}
            <Flex $gap={4} $align="center" $justify="flex-start" $width='auto'>
              {featureFlags?.includes(FeatureFlags.SKILL_TAGS) && tags?.slice(0, 1).map((tag) => (
                <Fragment key={tag}>
                  {tag.toLowerCase().includes('layoff')
                    ? <Button
                        $height={16}
                        $variant="outline"
                        $colorTheme="warning"
                        leadingIcon="alert-triangle"
                        $fontSize={10}
                        tooltip={{
                          text: 'This company had a Layoff during this period'
                        }}
                      >
                        Possible Layoff
                      </Button>
                    : <Badge $height={16} $variant="aiSolidFg" $transform="none">
                        {tag}
                      </Badge>
                  }
                </Fragment>
              ))}
              {hasOverlappingEmployees && (
                <S.OverlappingEmployees>
                  <S.OverlappingEmployeesAvatars>
                    <AvatarsList
                      avatars={overlappingEmployees.map((employee) => ({
                        id: employee.linkedin,
                        name: employee.name ?? '',
                        profilePhotoUrl: employee.profilePhotoUrl,
                        href: employee.linkedin
                      }))}
                      avatarDisplay={{ showCount: true, count: 3 }}
                      $size={20}
                      $overlap={2}
                      $border
                    />
                  </S.OverlappingEmployeesAvatars>
                  <Tooltip
                    trigger={
                      <span>worked here</span>
                    }
                  >
                    {department?.name ? `${department.name} employees ` : 'Employees from this department '} worked here
                  </Tooltip>
                </S.OverlappingEmployees>
              )}
            </Flex>
            <When condition={!isPrintView}>
              <S.TimelineEntrySocials>
                <Flex $gap={4} $align="center">
                  {website && (
                    <Button
                      href={`https://${website}`}
                      ariaLabel="Open website"
                      $variant="ghost"
                      $colorTheme="muted"
                      $fontSize={12}
                      $width={20}
                      $height={20}
                      leadingIcon="globe"
                    />
                  )}
                  {linkedin && (
                    <Button
                      href={`https://linkedin.com${linkedin}`}
                      ariaLabel="Open linkedin profile"
                      $variant="ghost"
                      $colorTheme="muted"
                      $fontSize={12}
                      $width={20}
                      $height={20}
                      leadingIcon="linkedin"
                    />
                  )}
                </Flex>
              </S.TimelineEntrySocials>
            </When>
          </S.EntryAttributes>
        </S.VisibleContent>

        <When condition={!isCollapsed}>
          <S.HiddenContent
            variants={hiddenContentVariants}
            animate={isCollapsed ? 'closed' : 'open'}
            initial="closed"
          >
            {showQuickFacts && (
              <S.QuickFacts>
                {companyInformation?.founded && (
                  <Paragraph size="XS" $color="fgSecondary">
                    Founded {companyInformation.founded}
                  </Paragraph>
                )}
                {companyInformation?.headCount && (
                  <Paragraph size="XS" $color="fgSecondary">
                    {companyInformation?.headCount.replace('-', ' - ')} Employees
                  </Paragraph>
                )}
                {fundingStage && (
                  <Paragraph size="XS" $color="fgSecondary">
                    {fundingStage} company
                  </Paragraph>
                )}
              </S.QuickFacts>
            )}

            {isPrintView // hide description in print view to save space
              ? null
              : description
                ? <Paragraph size="XS" $color="fgSecondary" $lineHeight={1.33} $ellipsis={false}>
                    {description}
                  </Paragraph>
                : <Paragraph size="XS" $color="fgSecondary" $lineHeight={1.33}>
                    (no data available)
                  </Paragraph>
            }

            {linkedin && featureFlags?.includes(FeatureFlags.COMPANY_PREFERENCES) && !isPrintView && (
              <S.HiddenContentFooter>
                {/* <Spacer $size={0} /> */}
                <Flex $gap={12} $align="center">
                  <Button
                    $variant={
                      companyPreferenceType === CompanyPreferenceType.LIKED
                        ? 'flat'
                        : 'outline'
                    }
                    $colorTheme={
                      companyPreferenceType === CompanyPreferenceType.LIKED
                        ? 'positive'
                        : 'muted'
                    }
                    $height={24}
                    leadingIcon="thumbs-up"
                    $fontSize={12}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      rateCompany(CompanyPreferenceType.LIKED)
                    }}
                    disabled={preferenceButtonIsDisabled}
                    tooltip={{
                      text: preferenceButtonIsDisabled ? 'Not enough data' : undefined
                    }}
                  >
                    More from this company
                  </Button>
                  <Button
                    $variant={
                      companyPreferenceType === CompanyPreferenceType.DISLIKED
                        ? 'flat'
                        : 'outline'
                    }
                    $colorTheme={
                      companyPreferenceType === CompanyPreferenceType.DISLIKED
                        ? 'negative'
                        : 'muted'
                    }
                    $height={24}
                    leadingIcon="thumbs-down"
                    $fontSize={12}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      rateCompany(CompanyPreferenceType.DISLIKED)
                    }}
                    disabled={preferenceButtonIsDisabled}
                    tooltip={{
                      text: preferenceButtonIsDisabled ? 'Not enough data' : undefined
                    }}
                  >
                    Fewer from this company
                  </Button>
                </Flex>
                {/* <Spacer $size={0} /> */}
              </S.HiddenContentFooter>
            )}
          </S.HiddenContent>
        </When>
      </S.Inner>
    </S.TimelineEntryTitle>
  )
}
