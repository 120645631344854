import { isEmpty } from 'lodash'
import type { SequenceWithSteps } from './api/backend/jobs'
import type { SequenceStep } from './api/backend/sequences'

export function sortAndFilterSequenceSteps (sequence?: SequenceWithSteps | null): SequenceStep[] {
  return (sequence?.sequenceSteps ?? [])
    .filter((step) => !step.deleted)
    .sort((a, b) => a.position - b.position)
}

export function isSequenceStepsEmpty (sequence?: SequenceWithSteps | null): boolean {
  const steps = sortAndFilterSequenceSteps(sequence)

  return steps.some(s => s.generationState) ||
    steps.every(s => isEmpty(s.subject) && isEmpty(s.body)) ||
    steps.length === 0
}

export function isSequenceStepsGenerating (sequence?: SequenceWithSteps | null): boolean {
  return sortAndFilterSequenceSteps(sequence).some(s => s.generationState)
}
