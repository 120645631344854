import styled from 'styled-components'
import type { BenefitsListStyleProps } from './benefits-list'

export const BenefitsList = styled.ul<BenefitsListStyleProps>`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
  list-style-type: none;
  padding: ${({ $itemSize }) => $itemSize === 'small' ? '1.25rem' : '2rem'};
  border-radius: 4px;
  border: 1px dashed;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
`

export const Benefit = styled.li<BenefitsListStyleProps>`
  display: flex;
  align-items: center;
  gap: ${({ $itemSize }) => $itemSize === 'small' ? '1rem' : '1.5rem'};
`

export const Icon = styled.div<BenefitsListStyleProps>`
  flex: ${({ $itemSize }) => ($itemSize === 'small' ? '0 0 2.5rem' : '0 0 3.25rem')};
  width: ${({ $itemSize }) => ($itemSize === 'small' ? '2.5rem' : '3.25rem')};
  height: ${({ $itemSize }) => ($itemSize === 'small' ? '2.5rem' : '3.25rem')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  background-color: ${({ theme, $colorTheme }) =>
    theme.colors[$colorTheme === 'light' ? 'bgTranslucent10' : 'bgTertiary']};
`
