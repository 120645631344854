import { useEffect, useState } from 'react'
import { FieldLabel } from 'src/components/forms/field-label'
import * as S from './salary-range-picker.styled'
import { isNil } from 'lodash'

export type BarVariant = 'normal' | 'highlighted'

export interface SalaryRangeArgs {
  defaultSelectedMin?: number
  defaultSelectedMax?: number
  idealMin?: number
  idealMax?: number
}

interface SalaryRangePickerProps extends SalaryRangeArgs {
  stepSize?: number
  onValueChange?: (value: number[]) => void
}

export const SalaryRangePicker = ({
  stepSize = 5,
  defaultSelectedMin = 85,
  defaultSelectedMax = 120,
  idealMin = 80,
  idealMax = 160,
  onValueChange
}: SalaryRangePickerProps): JSX.Element => {
  const [bars, setBars] = useState<Array<{ height: number, value: number }>>([])
  const [selectedValues, setSelectedValues] = useState([defaultSelectedMin, defaultSelectedMax])
  const rangeMin = idealMin - stepSize * 4
  const rangeMax = idealMax + stepSize * 8

  useEffect(() => {
    handleValueChange([defaultSelectedMin, defaultSelectedMax])
    // setSelectedValues([defaultSelectedMin, defaultSelectedMax])
  }, [defaultSelectedMin, defaultSelectedMax])

  const handleValueChange = (value: number[]): void => {
    setSelectedValues(value)
    if (!isNil(onValueChange)) {
      onValueChange(value)
    }
  }

  // Based on:
  // https://github.com/errcw/gaussian/blob/master/lib/gaussian.js
  // https://codepen.io/guigaoliveira/pen/owEGxx

  const gaussian = (x: number, mean: number, sigma: number): number => {
    return Math.exp(-0.5 * Math.pow((x - mean) / sigma, 2))
  }

  const generateBars = (): Array<{ value: number, height: number }> => {
    const mean = (idealMin + idealMax) / 2
    const sigma = (idealMax - idealMin) / 4
    let bars = []

    // Following the Figma design we want around 32 bars, but this differs based on the stepSize and defined range.
    // Sometimes we will need 32 bars, sometimes we need more bars.
    const totalSteps = (rangeMax - rangeMin) / stepSize
    const preferredNumberOfBars = 32
    const optimalStep = Math.max(
      Math.floor(totalSteps / preferredNumberOfBars) * stepSize,
      stepSize
    )
    const maxBars = 38

    for (let x = rangeMin; x <= rangeMax && bars.length < maxBars; x += optimalStep) {
      const height = gaussian(x, mean, sigma)
      bars.push({ value: x, height })
    }

    // The lowest bars should have at least a height of 5%
    const maxRawHeight = Math.max(...bars.map((bar) => bar.height))
    bars = bars.map((bar) => {
      const normalizedHeight = (bar.height / maxRawHeight) * 95 + 5
      return { ...bar, height: normalizedHeight }
    })

    return bars
  }

  useEffect(() => {
    const newBars = generateBars()
    setBars(newBars)
  }, [idealMin, idealMax, defaultSelectedMin, defaultSelectedMax])

  return (
    <S.SalaryRangePicker>
      <FieldLabel
        label="Salary range"
        description="Only used for matching candidates, not shared publicly."
      />
      <S.Graph>
        {bars.map((bar, index) => (
          <S.Bar
            key={index}
            $variant={
              bar.value >= selectedValues[0] && bar.value <= selectedValues[1]
                ? 'highlighted'
                : 'normal'
            }
            style={{ height: `${bar.height}%` }}
          />
        ))}
      </S.Graph>
      <S.SliderRoot
        // defaultValue={selectedValues}
        value={selectedValues}
        onValueChange={(value) => { handleValueChange(value) }}
        min={rangeMin}
        max={rangeMax}
        step={stepSize}
      >
        <S.SliderTrack>
          <S.SliderRange />
        </S.SliderTrack>
        <S.SliderThumb aria-label="Volume" $value={selectedValues[0]} $valueAlign="left" />
        <S.SliderThumb aria-label="Volume" $value={selectedValues[1]} $valueAlign="right" />
      </S.SliderRoot>
    </S.SalaryRangePicker>
  )
}
