import type { EmailAccount } from './api/backend/users'
import type { Org } from './api/backend/orgs'
import type { TrackingEventType } from 'src/types/track'
import posthog from 'posthog-js'

export const trackEvent = (
  event: TrackingEventType,
  properties?: Record<string, string | number | boolean | string[]>
): void => {
  console.debug('[TRACK]', {
    event
  })
  if (process.env.NODE_ENV === 'development') {
    return
  }
  // Google Tag Manager
  if (window.gtag) {
    window.gtag('event', event)
  }
  // Google Analytics
  if (window.dataLayer) {
    window.dataLayer.push({
      event
    })
  }
  // PostHog
  posthog.capture(event, properties)
}

interface UserProperties {
  emailAccount?: Partial<EmailAccount>
  name: string
  org?: Org | null
}

export const identifyTrackedUser = (userId: string, userProperties: UserProperties): void => {
  const baseProperties = {
    emailAccountId: userProperties?.emailAccount?.id,
    emailAccountType: userProperties?.emailAccount?.type,
    orgId: userProperties?.org?.id,
    orgName: userProperties?.org?.name,
    orgDomain: userProperties?.org?.domain
  }
  console.debug('[IdentifyTrackedUser]', baseProperties)
  if (process.env.NODE_ENV === 'development') {
    return
  }

  posthog.identify(userId, {
    name: userProperties?.name,
    email: userProperties?.emailAccount?.email,
    ...baseProperties
  })
  if (userProperties?.org != null) {
    posthog.group('company', userProperties.org.id, {
      name: userProperties.org.name,
      domain: userProperties.org.domain,
      createdAt: userProperties.org.createdAt
    })
  }

  if (window.gtag) {
    window.gtag('set', { user_id: userId })
  }

  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'identifyUser',
      userId
    })
  }
}
