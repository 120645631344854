import { CandidateProfile } from 'src/components/blocks/candidate-details'
import { EditCandidateForm } from 'src/components/blocks/edit-candidate-form'
import { Spacer } from 'src/components/primitives/spacer'
import * as Dialog from 'src/components/primitives/dialog'
import { useCandidateJobQuery } from 'src/hooks/queries/use-candidate-job'
import { controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'
import { useMemo } from 'react'

interface EditCandidateDialogProps {
  candidateJobId: string
}

export const EditCandidateDialog = ({ candidateJobId }: EditCandidateDialogProps): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.EDIT_CANDIDATE), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const { data: candidateJob } = useCandidateJobQuery({ candidateJobId })

  if (!candidateJob) {
    return <></>
  }

  return (
    <Dialog.Root
      id={DialogId.EDIT_CANDIDATE}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.EDIT_CANDIDATE, newState: value })
      }}
      $width="half"
      $maxWidth="640px"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Edit Candidate"
          onClose={() => {
            controlDialog({ id: DialogId.EDIT_CANDIDATE, newState: false })
          }}
        />
        <Dialog.Content>
          <CandidateProfile
            candidate={candidateJob.candidate}
            avatarSize={40}
          />
          <Spacer $size={24} />
          <EditCandidateForm candidateJob={candidateJob} />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
