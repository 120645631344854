import styled from 'styled-components'
import * as RadixSelect from '@radix-ui/react-select'
import type { FontSize } from 'src/styles/theme/types'

export const Trigger = styled(RadixSelect.Trigger)<{ $isLoading: boolean, $fontSize?: FontSize }>`
  width: 100%;
  height: ${({ $fontSize }) => ($fontSize ?? 0) <= 12 ? '2.25rem' : '2.5rem'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.25rem;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: 0.625rem;
  cursor: pointer;
  span {
    font-size: ${({ theme, $fontSize }) => theme.fontSizes[$fontSize ?? 14]};
    line-height: 1;
    color: ${({ theme }) => theme.colors.fgPrimary};
  }
  &:placeholder {
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
  ${({ $isLoading }) => $isLoading && `
    pointer-events: none;
  `}
`

export const TriggerIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
`

export const Content = styled(RadixSelect.Content)`
  overflow: hidden;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  width: var(--radix-select-trigger-width) !important;
  max-height: 50vh;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  z-index: ${({ theme }) => theme.zindeces.dropdown};
`

export const Viewport = styled(RadixSelect.Viewport)<{ $maxHeight?: number, $hasStickyItem: boolean }>`
  width: 100%;
  padding: ${({ $hasStickyItem }) => $hasStickyItem ? '0' : '0.375rem'};
  max-height: ${({ $maxHeight }) => $maxHeight ? `${$maxHeight}px` : 'auto'};
  overflow-y: auto;
`

export const ItemText = styled.span<{ $fontSize?: FontSize }>`
  font-size: ${({ theme, $fontSize }) => theme.fontSizes[$fontSize ?? 14]};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  color: ${({ theme }) => theme.colors.fgPrimary};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Item = styled(RadixSelect.Item)<{ $hasStickyItem: boolean, $fontSize?: FontSize }>`
  padding: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  border-radius: 3px;
  min-height: ${({ $fontSize }) => ($fontSize ?? 0) <= 12 ? '2.25rem' : '2.5rem'};
  cursor: pointer;
  padding-right: 0.75rem;
  &[data-highlighted] {
    background-color: ${({ theme }) => theme.colors.bgTertiary};
  }
  &[data-disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
  &[data-state='checked'] {
    background-color: ${({ theme }) => theme.colors.tintTranslucent5};
    ${ItemText} {
      color: ${({ theme }) => theme.colors.tintBg};
    }
  }
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.bgTertiary};
    }
  }
  ${({ $hasStickyItem }) => $hasStickyItem && `
    margin: 0 0.375rem;
    &:first-of-type {
      margin: 0 0.375rem;
      margin-top: 0.375rem;
    }
  `}
`

export const ItemMain = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`

export const CreateItemSection = styled(RadixSelect.Group)`
  margin-top: 0.375rem;
  padding-top: 0.375rem;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
`

export const CreateItemButton = styled.button<{ $fontSize?: FontSize }>`
  all: unset;
  width: calc(100% - (0.375rem * 2));
  padding: 0.375rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: ${({ theme, $fontSize }) => theme.fontSizes[$fontSize ?? 14]};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  color: ${({ theme }) => theme.colors.tintBg};
  /* background-color: ${({ theme }) => theme.colors.tintTranslucent5}; */
  border-radius: 3px;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.tintTranslucent15};
    }
  }
`

export const CreateItemButtonIcon = styled.span`
  display: inline-flex;
  width: 1.25rem;
  height: 1.25rem;
  align-items: center;
  justify-content: center;
`

export const StickyItem = styled(RadixSelect.Group)`
  display: block;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: calc(36px + (0.375rem * 3));
  margin-top: 0.5rem;
  padding: 0.375rem;
  opacity: 1;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  /* background-color: lightcoral; */
`

export const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0 0 0;
  div {
    width: 75%;
  }
`

export const ResetButton = styled.button`
  all: unset;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  color: ${({ theme }) => theme.colors.fgTertiary};
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 28px;
`
