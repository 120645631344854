import styled from 'styled-components'

export const Banner = styled.div`
  padding: ${({ theme }) => theme.spacing[12]};
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing[4]};
  background-color: ${({ theme }) => theme.colors.tintFaded};
  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
  align-items: center;
`

export const ActionButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
  padding: 1rem 1.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  justify-content: justify-between;
  width: 100%;
`
