import styled, { css } from 'styled-components'
import type { ChartWrapperStyleProps } from './chart-wrapper'

export const ChartWrapper = styled.div<ChartWrapperStyleProps>`
  width: 100%;
  ${({ $variant, $padding, theme }) =>
    $variant === 'raised' &&
    css`
      padding-top: ${theme.spacing[$padding?.top ?? 24]};
      padding-right: ${theme.spacing[$padding?.right ?? 24]};
      padding-bottom: ${theme.spacing[$padding?.bottom ?? 24]};
      padding-left: ${theme.spacing[$padding?.left ?? 24]};
      border-radius: 6px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
      background-color: ${({ theme }) => theme.colors.bgPrimary};
    `}
`
