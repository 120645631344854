import { useEffect, useState } from 'react'
import { StatusView } from '../status-view'
import { ProgressBar } from 'src/components/primitives/progress-bar'
import { SourceEnd } from '../source-end'
import { ViewMode } from 'src/constants'
import { RecommendingView } from './recommending-view'

interface SourcingViewProps {
  isRecommending: boolean
  isEmpty: boolean
  lastRequestedAt: Date
  viewMode: ViewMode
}

export const SourcingView = ({
  isRecommending,
  isEmpty,
  lastRequestedAt,
  viewMode
}: SourcingViewProps): JSX.Element | null => {
  const [showEmpty, setShowEmpty] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowEmpty(isEmpty)
    }, 1000)
    return () => {
      setShowEmpty(false)
    }
  }, [isEmpty])

  if (isRecommending) {
    return <RecommendingView lastRequestedAt={lastRequestedAt} />
  }

  if (showEmpty) {
    return (
      <StatusView
        pin="happy"
        animatePin
        heading="Loading candidates…"
      >
        <ProgressBar progress={100} />
      </StatusView>
    )
  }
  if (!isEmpty && viewMode !== ViewMode.TABLE) {
    return <SourceEnd />
  }
  return null
}
