import CONFIG from 'src/config'
import type { EmailAccount } from './api/backend/session'

interface EmailAccountAuthUrlParams {
  type: EmailAccount['type']
  email: EmailAccount['email']
}

export function getEmailAccountAuthUrl (emailAccount: EmailAccountAuthUrlParams, redirect?: string): string {
  const redirectPath = redirect ?? window.location.href
  const queryString = `?redirect=${redirectPath}&errorRedirect=${redirectPath}&loginHint=${emailAccount.email}`
  const googleUrl = `${CONFIG.API_DOMAIN}/auth/google/consent${queryString}`
  const microsoftUrl = `${CONFIG.API_DOMAIN}/auth/microsoft/consent${queryString}`

  switch (emailAccount.type) {
    case 'google':
      return googleUrl
    case 'microsoft':
      return microsoftUrl
  }
}
