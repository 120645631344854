import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Flex, Grid } from 'src/components/primitives'
import { Box } from 'src/components/primitives/box'
import { Icon, Icons } from 'src/components/primitives/icon'
import { AtsIntegrationType } from 'src/libs/api/backend/merge'
import type { MergeAtsIntegrationSyncStatus, MergeIntegration } from 'src/libs/api/backend/merge'
import * as S from './applicants-ats.styled'
import { useSession } from 'src/hooks/use-session'
import { useMergeLink } from '@mergeapi/react-merge-link'
import { useMergeLinkToken } from 'src/hooks/use-merge-link-token'
import { submitMergePublicToken } from 'src/libs/api/backend/merge_link_token'
import { useChannel } from 'ably/react'
import { useAtomValue } from 'jotai'
import { atsChannelAtom } from 'src/stores/websocket-channels'
import { getAtsIntegrationDetails } from 'src/hooks/queries/use-get-integration-details'
import { useSuspenseQuery } from '@tanstack/react-query'
import { EVENT_TYPE } from 'src/libs/api/backend/websockets'
import { Caption } from 'src/components/primitives/typography'
import { ATSJobSelection } from './ats-job-selection'
import { isNil } from 'lodash'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'
import { isAtsSyncing } from 'src/utils/merge'
interface ATSGridProps {
  mergeIntegrations: MergeIntegration[]
}

export const ATSJobMerge = ({ mergeIntegrations }: ATSGridProps): JSX.Element => {
  const { data: atsJobIntegrationDetails } = useSuspenseQuery(getAtsIntegrationDetails(AtsIntegrationType.Job))
  const { org, refetchSession } = useSession()
  const [searchAts, setSearchAts] = useState<string>('')
  const [syncingJobs, setSyncingJobs] = useState<boolean>(
    !!org?.mergeAtsIntegration &&
    isAtsSyncing(atsJobIntegrationDetails?.syncStatus ?? null)
  )

  const [integration, setIntegration] = useState<string | undefined>(undefined)
  const [filteredIntegrations, setFilteredIntegrations] = useState<MergeIntegration[]>(mergeIntegrations)
  const atsChannel = useAtomValue(atsChannelAtom)

  useChannel({ channelName: atsChannel, skip: !atsChannel }, (message) => {
    const { name, data } = message
    if (name === EVENT_TYPE.SYNCING_JOBS) {
      if (data.status && isAtsSyncing(data.status as MergeAtsIntegrationSyncStatus)) {
        setSyncingJobs(true)
      } else {
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.atsIntegrationDetails, AtsIntegrationType.Job]
        })
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.mergeJobs]
        })
        setSyncingJobs(false)
      }
    }
  })

  const submitAndFetch = useCallback(async (publicToken: string): Promise<void> => {
    setSyncingJobs(true)
    await submitMergePublicToken(publicToken)
    refetchSession()
  }, [refetchSession])

  useEffect(() => {
    setFilteredIntegrations(mergeIntegrations.filter((integration) => {
      return integration.name.toLowerCase().includes(searchAts)
    }))
  }, [searchAts, mergeIntegrations])

  const { data: linkToken } = useMergeLinkToken(integration)
  const { open: openMergeLink } = useMergeLink({
    linkToken: integration ? linkToken : undefined,
    onSuccess: (publicToken): void => {
      void submitAndFetch(publicToken)
    },
    onExit: (): void => {
      // setIntegration(undefined)
    }
  })

  const isAtsConnected = useMemo(() => {
    return !isNil(org?.mergeAtsIntegration)
  }, [org?.mergeAtsIntegration])

  useEffect(() => {
    if (integration && linkToken) {
      openMergeLink()
    }
  }, [integration, linkToken, openMergeLink])

  if (syncingJobs || isAtsConnected) {
    return <ATSJobSelection isSyncing={syncingJobs} mergeIntegrations={mergeIntegrations} />
  }

  return (
    <S.ATSInnerContainer>
      <Flex $direction="column" $gap={4}>
        <Caption size="MD">Import applicants from ATS</Caption>
        <Caption size="SM" $fontWeight={400}>
          Connect your ATS and Pin will review and categorize applicants for you.
        </Caption>
      </Flex>
      <Box $padding={{ top: 6, right: 6, bottom: 6, left: 6 }} $border='borderTranslucent' $background='bgPrimary' $width='100%' $gap={2} $height={32} $align='center' $display='flex' $flex='0'>
        <S.Icon>
          <Icon name={Icons.search} size={16} />
        </S.Icon>
        <S.FilterInput
          placeholder='Filter list'
          value={searchAts}
          onChange={(e) => {
            setSearchAts(e.target.value.toLowerCase())
          }}
        />
      </Box>
      <Grid $columns={2} $columnGap={16} $rowGap={16} $width='100%'>
        {filteredIntegrations.map((integration) => (
          <Button
            key={integration.slug}
            $variant="raised"
            $width='full'
            $colorTheme="normal"
            leadingIcon={<img src={integration.square_image} width={32} height={32} />}
            onClick={() => {
              setIntegration(integration.slug)
            }}
          >
            {integration.name}
          </Button>
        ))}
      </Grid>
    </S.ATSInnerContainer>
  )
}
