import styled from 'styled-components'

export const ImpersonatingBar = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.warningBg};
  padding: ${({ theme }) => theme.spacing[2]};
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  color: ${({ theme }) => theme.colors.fgPrimary};
  position: fixed;
  z-index: 1000;
`