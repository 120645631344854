// import { CreateJobForm } from 'src/components/blocks/create-job-form'
import { CreateNewJobForm } from 'src/components/blocks/create-new-job-form'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'

const OnboardingJobPosition = (): JSX.Element => {
  return (
    <CreateNewJobForm
      redirectType='onboarding'
      onCreateJob={() => {
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.session]
        })
      }}
    />
  )
}

export default OnboardingJobPosition
