import styled from 'styled-components'
import type { SwitchProps } from './switch'

export const Switch = styled.div<Pick<SwitchProps, '$variant'>>`
  display: flex;
  align-items: center;

  ${({ $variant }) =>
    $variant === 'plain' &&
    `
      gap: 1rem;
    `}

  ${({ $variant, theme }) =>
    $variant === 'outline' &&
    `
      flex-direction: row-reverse;
      border: solid 1px;
      border-color: ${theme.colors.borderTranslucent};
      border-radius: 4px;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 3rem;
    `}

    ${({ $variant, theme }) =>
    $variant === 'raised' &&
    `
      flex-direction: row-reverse;
      border-radius: 4px;
      padding: 0.75rem 1rem 0.75rem 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 3rem;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
      background-color: ${theme.colors.bgPrimary};
    `}
`

export const Input = styled.input`
  transform: translateX(-100%);
  position: absolute;
  pointer-events: none;
  opacity: 0;
  margin: 0px;
  width: 42px;
  height: 25px;
`
