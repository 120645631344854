import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Layout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
`

export const Logo = styled(NavLink)`
  position: fixed;
  top: 1.25rem;
  left: 26px;
  width: 200px;
  height: 28px;
  z-index: 2;
`
