import { useMutation, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { isNil } from 'lodash'
import { updateProjectDetails } from 'src/libs/api/backend/projects'
import type { Project } from 'src/libs/api/backend/projects'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface Args {
  id: Project['id']
  name: Project['id']
  onSuccess?: (updatedProject: Project) => void
  onError?: () => void
}

interface Res {
  updateProject: (args: Args) => void
}

export const useUpdateProject = (): Res => {
  const queryClient = useQueryClient()

  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)

  const mutation = useMutation({
    mutationFn: async ({ id, name }: Args) => await updateProjectDetails({ id, name }),
    onError: (err, variables) => {
      console.error(err)
      notifyError({
        message: `An error occurred while updating this project: ${err.message}`
      })

      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (updatedProject: Project, variables) => {
      queryClient.setQueryData<Project[]>([queryKeys.projects], (oldProjects) => {
        if (isNil(oldProjects)) {
          return
        }

        return oldProjects.map((dept) => {
          if (dept.id === updatedProject.id) {
            return updatedProject
          }
          return dept
        })
      })

      notifySuccess({
        message: 'Successfully updated project'
      })
      if (variables.onSuccess) {
        variables.onSuccess(updatedProject)
      }
    }
  })
  const updateProject = (args: Args): void => {
    mutation.mutate(args)
  }
  return { updateProject }
}
