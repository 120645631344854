import type { DeferredData } from '@remix-run/router/dist/utils'
import { defer, redirect } from 'react-router-dom'
import { jobQuery } from 'src/hooks/queries/use-job'
import queryClient from 'src/hooks/query-client'
import type { Job } from '../api/backend/jobs'
import RouteBuilder from '../route-builder'
import { jobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { sessionQuery } from 'src/hooks/queries/use-session'
import { orgUsersQuery } from 'src/hooks/queries/use-org-users'
import { candidateSequenceStepMessageReviewsQuery } from 'src/hooks/queries/use-candidate-sequence-step-message-review'
import type { OrgUser } from '../api/backend/users'
import type { EmailSequence } from 'src/models/sequence'
import type { CandidateSequenceStepMessageReviewExtended } from '../api/backend/candidate_sequence_step_message_review'
import type { Session } from '../api/backend/session'
import { candidateJobCountsQuery } from 'src/hooks/queries/use-candidate-job-counts'
import { candidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { IN_OUTREACH_STEPS } from '../api/backend/candidate_jobs'
import type { CandidateJobExpanded } from '../api/backend/candidate_jobs'
import type { InfiniteData } from '@tanstack/react-query'

interface InOutreachLoader {
  params: {
    jobId: string
  }
  request: Request
}

export interface InOutreachLoaderData {
  step: IN_OUTREACH_STEPS
  jobId: string
}

export const inOutreachLoader = ({ params: { jobId }, request }: InOutreachLoader): InOutreachLoaderData | Response => {
  const url = new URL(request.url)
  const searchParamStep = url.searchParams.get('step')
  const readyToSend = url.searchParams.get('readyToSend')
  // Redirect in case people bookmarked the old URL
  if (readyToSend) {
    return redirect(RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE_READY_TO_SEND', { jobId }))
  }
  if (!searchParamStep) {
    return redirect(RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE', { jobId }, { step: IN_OUTREACH_STEPS.ALL_CONTACTS }))
  }

  // Purposedly not deferring this loader to avoid unnecessary flashing when params change
  return {
    step: searchParamStep as IN_OUTREACH_STEPS,
    jobId
  }
}

export interface ReadyToSendLoaderData {
  jobId: string
  orgUsers: OrgUser[]
  emailSequence: EmailSequence
  candidateJobs: InfiniteData<CandidateSequenceStepMessageReviewExtended[]>
  session: Session
}

export const readyToSendLoader = async ({ params: { jobId } }: InOutreachLoader): Promise<DeferredData | Response> => {
  const jobCounts = await queryClient.ensureQueryData(candidateJobCountsQuery(jobId))
  if (jobCounts && jobCounts.readyToSend === 0) {
    return redirect(RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE', { jobId }))
  }
  return defer({
    jobId,
    session: queryClient.ensureQueryData(sessionQuery()),
    orgUsers: queryClient.ensureQueryData(orgUsersQuery()),
    emailSequence: queryClient.ensureQueryData(jobSequenceQuery(jobId)),
    candidateJobs: queryClient.ensureInfiniteQueryData(candidateSequenceStepMessageReviewsQuery(jobId))
  })
}

export interface InOutreachErrorsLoaderData {
  jobId: string
  candidateJobs: CandidateJobExpanded[]
  job: Job
}

export const inOutreachErrorsLoader = async ({ params: { jobId } }: InOutreachLoader): Promise<DeferredData | Response> => {
  const jobCounts = await queryClient.ensureQueryData(candidateJobCountsQuery(jobId))
  if (jobCounts?.prospectingErrors === 0) {
    return redirect(RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE', { jobId }))
  }
  return defer({
    jobId,
    candidateJobs: queryClient.ensureQueryData(candidateJobsQuery(jobId, { errored: true })),
    job: queryClient.ensureQueryData(jobQuery(jobId))
  })
}
