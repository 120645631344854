import type { LinkedInAccount } from 'src/libs/api/backend/users'
import * as S from './connected-account-card.styled'
import { Button } from 'src/components/primitives/button'
import { Caption } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import { useMeLinkedInAccounts } from 'src/hooks/use-me-linked-in-accounts'
import { BrandIcon } from 'src/components/primitives/brand-icon'

interface ConnectedAccountCardProps {
  account: LinkedInAccount
}

export const ConnectedAccountCard = ({ account }: ConnectedAccountCardProps): JSX.Element => {
  const { deleteAccount } = useMeLinkedInAccounts()

  return (
    <S.ConnectedAccount>
      <S.Inner>
        <S.AccountGrid>
          {account.linkedInProfilePictureUrl
            ? (
                <S.LinkedInAvatar>
                  <img src={account.linkedInProfilePictureUrl} alt="LinkedIn profile picture" />
                </S.LinkedInAvatar>
              )
            : (
                <BrandIcon name="linkedinOriginal" color="original" />
              )}
          <Flex $gap={16} $align="center">
            <Caption size="SM">{account.unipileUserName}</Caption>
          </Flex>
          <Flex $gap={12} $align="center" $justify="flex-end">
            <Flex $flex="1" $justify="flex-end">
              <Button
                $variant="ghost"
                $colorTheme="muted"
                ariaLabel="Disconnect LinkedIn account"
                $height={24}
                $fontSize={12}
                onClick={async () => {
                  await deleteAccount()
                }}
              >
                Disconnect
              </Button>
            </Flex>
          </Flex>
        </S.AccountGrid>
      </S.Inner>
    </S.ConnectedAccount>
  )
}
