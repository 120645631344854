import { useAtomValue } from 'jotai'
import { NotificationsContainer, Notification } from 'src/components/primitives/notification'
import { notificationsAtom } from 'src/stores/notifications'

export const Notifications = (): JSX.Element => {
  const notifications = useAtomValue(notificationsAtom)
  return (
    <NotificationsContainer>
      {notifications?.map((notification, index) => (
        <Notification
          count={notifications.length}
          stackPosition={index}
          key={notification.id}
          {...notification}
        />
      ))}
    </NotificationsContainer>
  )
}
