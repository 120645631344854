import type React from 'react'
import { BrandIcons } from 'src/components/primitives/brand-icon'
import { Colors } from 'src/styles/theme/types'
import type { Color } from 'src/styles/theme/types'
import type { BrandIconName } from 'src/components/primitives/brand-icon'
import { Icons } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'

type ReturnIcon = 'Icon' | 'BrandIcon' | 'Color' | 'ReactNode'

export const detectIconType = (icon: IconName | BrandIconName | Color | React.ReactNode): ReturnIcon => {
  const allIcons = Object.values(Icons)
  const allBrandIcons = Object.values(BrandIcons)
  const allColors = Object.values(Colors)

  if (typeof icon === 'string') {
    if (allIcons.includes(icon as any)) {
      return 'Icon'
    }
    if (allBrandIcons.includes(icon as any)) {
      return 'BrandIcon'
    }
    if (allColors.includes(icon as any)) {
      return 'Color'
    }
  }
  return 'ReactNode'
}
