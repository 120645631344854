import type { Keyframes } from 'styled-components/dist/types'
import styled, { keyframes } from 'styled-components'
import * as RadixDialog from '@radix-ui/react-dialog'
import { ERROR_BLOCK_HEIGHT, NAVIGATION_WIDTH } from 'src/styles/constants'

const dialogShow = (): Keyframes => keyframes`
  0% { opacity: 0; transform: translate3d(0, 0, 0) scale(0.98); }
  100% { opacity: 1; transform: translate3d(0, 0, 0) scale(1); }
`

export const DialogContent = styled(RadixDialog.Content)<{ $isErrored: boolean, $navigationIsCollapsed: boolean }>`
  position: fixed;
  top: ${({ $isErrored }) => `calc(${$isErrored ? ERROR_BLOCK_HEIGHT : '0px'} + 8px)`};
  left: ${({ $navigationIsCollapsed }) => `calc(${NAVIGATION_WIDTH.LG[$navigationIsCollapsed ? 'CLOSED' : 'OPEN']} + 8px)`};
  height: ${({ $isErrored }) => `calc(100vh - (8px * 2 + ${$isErrored ? ERROR_BLOCK_HEIGHT : '0px'}))`};
  width: 384px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.lg};
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 99;
  animation: ${dialogShow} 0.25s cubic-bezier(0.16, 1, 0.3, 1);
  display: flex;
  flex-direction: column;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.75rem 0 1rem;
  height: 48px;
  gap: ${({ theme }) => theme.spacing[8]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgTertiary};
`
