import { useEffect, useState } from 'react'
import { fetchMergeLinkToken } from 'src/libs/api/backend/merge_link_token'

export const useMergeLinkToken = (integration?: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [data, setData] = useState<string>('')
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const linkToken = await fetchMergeLinkToken(integration)
        setIsLoading(false)
        setError(null)
        setData(linkToken)
      } catch (e) {
        setIsLoading(false)
        setError('Error getting Merge Link token')
        setData('')
      }
    }
    void fetchData()
  }, [integration])

  return { isLoading, data, error }
}
