interface DataPoint {
  label: string | number
  [key: string]: string | number
}

export const getChartDataMaxValue = (data: DataPoint[]): number => {
  return data.reduce((max, point) => {
    const values = Object.keys(point)
      .filter((key) => key !== 'label')
      .map((key) => point[key])
      .filter((value): value is number => typeof value === 'number')

    const localMax = values.length > 0 ? Math.max(...values) : 0
    return Math.max(max, localMax)
  }, 0)
}
