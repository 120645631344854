import { Caption, Paragraph } from 'src/components/primitives/typography'
import * as S from './toolbar.styled'
import { Button } from 'src/components/primitives/button'
import { When } from '../when'
import { CalendarIcon } from '../calendar-icon'
import { Flex } from 'src/components/primitives/flex'
import { format } from 'date-fns'
import { useDeleteCalendarEvent } from 'src/hooks/mutations/use-delete-calendar-event'
import { forwardRef } from 'react'
import type { CalendarEvent, NewCalendarEvent } from 'src/libs/api/backend/calendar_events'
import { isNil } from 'lodash'
import { useSession } from 'src/hooks/queries/use-session'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { useSetAtom } from 'jotai'
import { openAlertAtom } from 'src/stores/dialogs'

interface ToolbarProps {
  candidateJob?: CandidateJobExpanded
  selectedEvent?: CalendarEvent | null
  newEvent?: NewCalendarEvent | null
  onResetSelectedEvent: () => void
  onSendInvite: () => void
}

export const Toolbar = forwardRef<HTMLDivElement, ToolbarProps>(
  ({ selectedEvent, newEvent, onResetSelectedEvent, onSendInvite, candidateJob }, forwardedRef): JSX.Element => {
    const openAlert = useSetAtom(openAlertAtom)
    const { deleteEvent } = useDeleteCalendarEvent()
    const { data: sessionData } = useSession()
    const emailAccount = sessionData?.emailAccountAccessTokens?.[0]

    return (
      <S.Toolbar ref={forwardedRef}>
        <When condition={!selectedEvent}>
          <Paragraph size="XS" $color="fgSecondary" $whiteSpace="nowrap">
            {emailAccount?.hasAccessToken ? 'Select a time on the calendar to schedule a meeting' : 'Reconnect your email account to schedule events'}
          </Paragraph>
        </When>
        {selectedEvent && (
          <S.SelectedEvent>
            <CalendarIcon timestamp={selectedEvent?.start.toDateString() ?? ''} />
            <Flex $direction="column" $gap={4}>
              <Caption size="MD" $color="fgPrimary" $whiteSpace="nowrap">
                {(selectedEvent?.title?.length ?? 0) >= 1 ? selectedEvent?.title : 'New event'}
              </Caption>
              <Flex $align="center" $gap={12}>
                <Paragraph size="XS" $color="fgPrimary">
                  {format(selectedEvent?.start, 'EEEE')}
                </Paragraph>
                <Paragraph size="XS" $color="fgSecondary" $whiteSpace="nowrap">
                  {format(selectedEvent?.start, 'h:mma').toLowerCase()} -{' '}
                  {format(selectedEvent?.end, 'h:mma').toLowerCase()}
                  {' '}{selectedEvent?.start.toLocaleString('en-US', { timeZoneName: 'short' }).split(' ').pop()}
                </Paragraph>
              </Flex>
            </Flex>
          </S.SelectedEvent>
        )}
        {selectedEvent && (
          <Flex $align="center" $justify="flex-end" $gap={8}>
            {
              isNil(newEvent) && (
                <Button
                  $variant="ghost"
                  $colorTheme="muted"
                  $height={32}
                  $fontSize={12}
                  leadingIcon="trash"
                  disabled={!selectedEvent?.isPinEvent}
                  onClick={() => {
                    openAlert({
                      message: 'Are you sure you want to delete this event?',
                      description: 'This action can not be reversed',
                      cancelText: 'Cancel',
                      confirmText: 'Delete',
                      onConfirm: () => {
                        if (selectedEvent?.id) {
                          deleteEvent({ id: selectedEvent?.id })
                        }
                        onResetSelectedEvent()
                      }
                    })
                  }}
                >
                  Delete event
                </Button>
              )
            }
            {
              !isNil(newEvent) && (
                <Button
                  $variant="raised"
                  $colorTheme="muted"
                  $height={40}
                  $fontSize={14}
                  onClick={onResetSelectedEvent}
                >
                  Cancel
                </Button>
              )
            }
            {
              (!!newEvent && newEvent?.id === selectedEvent?.id) && (
                <Button
                  $variant="fill"
                  $colorTheme="tint"
                  $height={40}
                  $fontSize={14}
                  onClick={onSendInvite}
                  disabled={!candidateJob?.candidate?.emails?.length}
                  tooltip={{
                    text: !candidateJob?.candidate?.emails?.length ? 'No email address found' : undefined
                  }}
                >
                  Send invite
                </Button>
              )
            }
          </Flex>
        )}
      </S.Toolbar>
    )
  }
)

Toolbar.displayName = 'Toolbar'
