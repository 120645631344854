import { Logo } from 'src/components/primitives/logo'
import * as S from './auth-layout.styled'

interface Profile {
  imageSrc: string
  skills: Array<{ title: string, context: string }>
  bg1: string
  bg2: string
  fg1: string
}

export const AuthLayout = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const PROFILES: Profile[] = [
    {
      imageSrc: 'onboarding-1.jpg',
      skills: [
        { title: 'Python', context: '10y' },
        { title: 'Data Analysis', context: '3y' },
        { title: 'AI Researcher', context: '2y' }
      ],
      bg1: '#F9E1C6',
      bg2: '#A16840',
      fg1: '#A16840'
    },
    {
      imageSrc: 'onboarding-2.jpg',
      skills: [
        { title: 'JavaScript', context: '8y' },
        { title: 'Ruby on Rails', context: '3y' },
        { title: 'Team lead', context: '2y' }
      ],
      bg1: '#F9E1C6',
      bg2: '#A16840',
      fg1: '#A16840'
    },
    {
      imageSrc: 'onboarding-3.jpg',
      skills: [
        { title: 'Figma', context: '3y' },
        { title: 'User Research', context: '3y' },
        { title: 'Team lead', context: '1y' }
      ],
      bg1: '#36567D',
      bg2: '#293442',
      fg1: '#A8C0D6'
    },
    {
      imageSrc: 'onboarding-4.jpg',
      skills: [
        { title: 'Python', context: '10y' },
        { title: 'Data Analysis', context: '3y' },
        { title: 'AI Researcher', context: '2y' }
      ],
      bg1: '#B8A8C7',
      bg2: '#3E3446',
      fg1: '#564B60'
    },
    {
      imageSrc: 'onboarding-5.jpg',
      skills: [
        { title: 'Java', context: '8y' },
        { title: 'DevOps', context: '3y' },
        { title: 'Team lead', context: '2y' }
      ],
      bg1: '#CFDCE2',
      bg2: '#517485',
      fg1: '#6E8D9D'
    },
    {
      imageSrc: 'onboarding-6.jpg',
      skills: [
        { title: 'JavaScript', context: '6y' },
        { title: 'Data Analysis', context: '3y' },
        { title: 'React.js', context: '3y' }
      ],
      bg1: '#77645F',
      bg2: '#483833',
      fg1: '#FFFFFF'
    },
    {
      imageSrc: 'onboarding-7.jpg',
      skills: [
        { title: 'Project management', context: '6y' },
        { title: 'Strategist', context: '4y' },
        { title: 'User Research', context: '2y' }
      ],
      bg1: '#96DF9F',
      bg2: '#3B5443',
      fg1: '#2E4E3B'
    }
  ]

  const getRandomProfile = (): number => {
    return Math.floor(Math.random() * PROFILES.length)
  }

  const profile = getRandomProfile()

  return (
    <S.AuthLayout>
      <S.AuthInner>
        <S.Marketing>
          <S.MarketingInner>
            <Logo />
            <S.Profiles>
              {PROFILES[profile].skills.map((skill) => (
                <S.ProfileSkill key={skill.title} $fg={PROFILES[profile].fg1} $bg={PROFILES[profile].bg1}>
                  {skill.title}
                  <S.ProfileSkillContext $bg={PROFILES[profile].bg2}>
                    {skill.context}
                  </S.ProfileSkillContext>
                </S.ProfileSkill>
              ))}
            </S.Profiles>
          </S.MarketingInner>
          <S.Media>
            <img src={`/onboarding/${PROFILES[profile].imageSrc}`} />
          </S.Media>
        </S.Marketing>
        <S.AuthForm>
          <S.AuthFormInner>{children}</S.AuthFormInner>
        </S.AuthForm>
      </S.AuthInner>
    </S.AuthLayout>
  )
}
