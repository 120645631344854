import { CONTENT_PADDING, INBOX_MAX_WIDTH } from 'src/styles/constants'
import styled from 'styled-components'

export const ContentInner = styled.div`
  max-width: ${INBOX_MAX_WIDTH};
  padding: ${CONTENT_PADDING};
  padding-bottom: 0;
`

export const MaxWidthContent = styled.div`
  // max-width: ${INBOX_MAX_WIDTH};
  padding: 0.75rem ${CONTENT_PADDING};
  padding-bottom: 0;
`
