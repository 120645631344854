import { useEffect, useRef } from 'react'
import htmlReactParser, { Element } from 'html-react-parser'
import type { DOMNode } from 'html-react-parser'
import { MentionedTimes } from 'src/components/blocks/inbox-timeline/elements'

interface UnsafeHTMLProps {
  html: string
}

export const UnsafeHTML = ({
  html
}: UnsafeHTMLProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)

  const parseOptions = {
    replace: (domNode: DOMNode) => {
      if (domNode instanceof Element && domNode.name === 'mentionedtimes') {
        const { text, mentionedtimes } = domNode.attribs
        return (
          <MentionedTimes text={text} mentionedTimes={JSON.parse(decodeURIComponent(mentionedtimes)).mentionedTimes}></MentionedTimes>
        )
      }
    }
  }

  useEffect(() => {
    if (ref.current) {
      const links = ref.current.getElementsByTagName('a')
      Array.from(links).forEach((link) => {
        link.target = '_blank'
        link.rel = 'noopener noreferrer'
      })
    }
  }, [html])

  return (
    <div ref={ref}>
      {htmlReactParser(html, parseOptions)}
    </div>
  )
}
