import { useSuspenseQuery } from '@tanstack/react-query'
import { useChannel } from 'ably/react'
import { useAtomValue } from 'jotai'
import { useState } from 'react'
import { getAtsIntegrationDetails } from 'src/hooks/queries/use-get-integration-details'
import queryClient from 'src/hooks/query-client'
import { AtsIntegrationType } from 'src/libs/api/backend/merge'
import type { MergeAtsIntegrationSyncStatus } from 'src/libs/api/backend/merge'
import { EVENT_TYPE } from 'src/libs/api/backend/websockets'
import { queryKeys } from 'src/libs/query-keys'
import { atsChannelAtom } from 'src/stores/websocket-channels'
import { isAtsSyncing } from 'src/utils/merge'
import { LoadingCandidates } from './loading-candidates'
import { Flex } from 'src/components/primitives'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import * as S from './applicant-candidates-ats.styled'
import { useSession } from 'src/hooks/use-session'

export const Candidates = (): JSX.Element => {
  const { data: candidateIntegrationDetails } = useSuspenseQuery(getAtsIntegrationDetails(AtsIntegrationType.Candidate))
  const { data: attachmentIntegrationDetails } = useSuspenseQuery(getAtsIntegrationDetails(AtsIntegrationType.Attachment))
  const { org } = useSession()
  const atsChannel = useAtomValue(atsChannelAtom)
  const [syncingCandidates, setSyncingCandidates] = useState<boolean>(
    !!org?.mergeAtsIntegration &&
    (isAtsSyncing(candidateIntegrationDetails?.syncStatus ?? null) ||
    isAtsSyncing(attachmentIntegrationDetails?.syncStatus ?? null))
  )

  useChannel({ channelName: atsChannel, skip: !atsChannel }, (message) => {
    const { name, data } = message
    if (name === EVENT_TYPE.SYNCING_CANDIDATES || name === EVENT_TYPE.SYNCING_ATTACHMENTS) {
      if (data.status && isAtsSyncing(data.status as MergeAtsIntegrationSyncStatus)) {
        setSyncingCandidates(true)
      } else {
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.atsIntegrationDetails, AtsIntegrationType.Candidate]
        })
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.atsIntegrationDetails, AtsIntegrationType.Attachment]
        })
        setSyncingCandidates(false)
      }
    }
  })

  if (syncingCandidates) {
    return (
      <LoadingCandidates
        updatedAt={candidateIntegrationDetails?.updatedAt ?? new Date()}
        isCandidateSyncing={isAtsSyncing(candidateIntegrationDetails?.syncStatus ?? null)}
        isAttachmentsSyncing={isAtsSyncing(attachmentIntegrationDetails?.syncStatus ?? null)}
      />
    )
  }

  return (
    <S.EmptyWrapper>
      <Icon name={Icons.emptyApplicant} size={120} />
      <Flex $direction="column" $gap={16} $flex='1'>
        <Caption size="LG">No applicants yet</Caption>
        <Flex $direction="column">
          <Paragraph size="SM">Applicants from your ATS will</Paragraph>
          <Paragraph size="SM">appear here automatically.</Paragraph>
        </Flex>
      </Flex>
    </S.EmptyWrapper>
  )
}
