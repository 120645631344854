import { isNil } from 'lodash'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLoginRedirectPath } from 'src/hooks/use-login-redirect-path'
import { sendLocalTimezone } from 'src/libs/api/backend/me'

const LoginRedirect = (): null => {
  const navigate = useNavigate()
  const { isLoading, redirectPath } = useLoginRedirectPath()

  console.debug('[LoginRedirect]', { redirectPath, isLoading })

  useEffect(() => {
    sendLocalTimezone().catch((error) => {
      console.error('Error sending local timezone', error)
    })
  }, [])

  useEffect(() => {
    if (!isLoading && !isNil(redirectPath)) {
      navigate(redirectPath)
    }
  }, [navigate, isLoading, redirectPath])

  return null
}

export default LoginRedirect
