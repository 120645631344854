import { sessionQuery } from 'src/hooks/queries/use-session'
import queryClient from 'src/hooks/query-client'
import type { DeferredData } from '@remix-run/router/dist/utils'
import { defer, redirect } from 'react-router-dom'
import { mergeIntegrationsQuery } from 'src/hooks/queries/use-merge-integrations-list'
import { AtsIntegrationType } from '../api/backend/merge'
import type { AtsIntegration, MergeAtsJob, MergeIntegration } from '../api/backend/merge'
import { getMergeJobs } from 'src/hooks/queries/use-get-merge-jobs'
import { getAtsIntegrationDetails } from 'src/hooks/queries/use-get-integration-details'
import { jobQuery } from 'src/hooks/queries/use-job'
import type { Job } from '../api/backend/jobs'
import RouteBuilder from '../route-builder'

export interface ApplicantsLoaderData {
  mergeIntegrations: MergeIntegration[]
  mergeJobs: MergeAtsJob[]
  atsJobIntegrationDetails: AtsIntegration
  job: Job
}
export const applicantsLoader = async ({ params: { jobId } }: { params: { jobId: string } }): Promise<DeferredData | Response> => {
  const { org } = await queryClient.ensureQueryData(sessionQuery())
  const job = await queryClient.ensureQueryData(jobQuery(jobId))
  if (job.mergeAtsJobId && org?.mergeAtsIntegration) {
    // ATS JobID exists so we just redirect to the candidates page
    return redirect(RouteBuilder.build('JOBS_APPLICANTS_CANDIDATES', { jobId }))
  }
  return defer({
    mergeIntegrations: queryClient.ensureQueryData(mergeIntegrationsQuery()),
    mergeJobs: queryClient.ensureQueryData(getMergeJobs()),
    atsJobIntegrationDetails: queryClient.ensureQueryData(getAtsIntegrationDetails(AtsIntegrationType.Job)),
    job
  })
}

export interface ApplicantsCandidatesLoaderData {
  job: Job
  atsCandidateIntegrationDetails: AtsIntegration
  atsAttachmentIntegrationDetails: AtsIntegration
}
export const applicantsCandidatesLoader = async ({ params: { jobId } }: { params: { jobId: string } }): Promise<DeferredData | Response> => {
  const { org } = await queryClient.ensureQueryData(sessionQuery())
  const job = await queryClient.ensureQueryData(jobQuery(jobId))
  if (!job.mergeAtsJobId || !org?.mergeAtsIntegration) {
    // ATS JobID exists so we just redirect to the candidates page
    return redirect(RouteBuilder.build('JOBS_APPLICANTS', { jobId }))
  }
  return defer({
    job,
    atsCandidateIntegrationDetails: queryClient.ensureQueryData(getAtsIntegrationDetails(AtsIntegrationType.Candidate)),
    atsAttachmentIntegrationDetails: queryClient.ensureQueryData(getAtsIntegrationDetails(AtsIntegrationType.Attachment))
  })
}
