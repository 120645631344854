import type { Spacing } from 'src/styles/theme/types'
import styled from 'styled-components'

type Direction = 'row' | 'column' | 'row-reverse' | 'column-reverse'
type Align = 'flex-start' | 'center' | 'flex-end'
type Justify = 'flex-start' | 'center' | 'flex-end' | 'space-between'

interface StackStyleProps {
  $direction?: Direction
  $align?: Align
  $justify?: Justify
  $gap?: Spacing
  $pd?: string
  $mg?: string
}

interface StackProps extends StackStyleProps {
  children?: React.ReactNode
}

export const Stack = ({
  children,
  $direction = 'row',
  $align = 'flex-start',
  $justify = 'flex-start',
  $pd = '',
  $mg = ''
}: StackProps): JSX.Element => {
  return (
    <StyledStack $direction={$direction} $justify={$justify} $align={$align} $pd={$pd} $mg={$mg}>
      {children}
    </StyledStack>
  )
}

const StyledStack = styled.div<StackStyleProps>`
  padding: ${({ $pd }) => $pd ?? '0'};
  margin: ${({ $mg }) => $mg ?? '0'};

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  justify-content: ${({ $justify }) => $justify}};

  align-items: ${({ $align }) => $align}};

  gap: ${({ $gap, theme }) => theme.spacing[$gap ?? 0]};
`
