import { useCallback } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  getLinkedInAccounts,
  deleteLinkedInAccount,
  createLinkedInAccount
} from '../libs/api/backend/me_linkedin_accounts'
import type { LinkedInAccount } from '../libs/api/backend/me_linkedin_accounts'
import { queryKeys } from '../libs/query-keys'

interface UseMeLinkedInAccountsResult {
  linkedInAccounts: LinkedInAccount[]
  isLoading: boolean
  error: Error | null
  createAccount: () => Promise<LinkedInAccount>
  deleteAccount: () => Promise<{ success: boolean }>
  isCreating: boolean
  isDeleting: boolean
}

export function useMeLinkedInAccounts (): UseMeLinkedInAccountsResult {
  const queryClient = useQueryClient()

  const {
    data,
    isLoading,
    error
  } = useQuery({
    queryKey: [queryKeys.linkedInAccounts],
    queryFn: getLinkedInAccounts
  })

  const createMutation = useMutation({
    mutationFn: createLinkedInAccount,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.linkedInAccounts] })
    }
  })

  const deleteMutation = useMutation({
    mutationFn: deleteLinkedInAccount,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.linkedInAccounts] })
    }
  })

  const createAccount = useCallback(async () => {
    return await createMutation.mutateAsync()
  }, [createMutation])

  const deleteAccount = useCallback(async () => {
    return await deleteMutation.mutateAsync()
  }, [deleteMutation])

  return {
    linkedInAccounts: data?.linkedInAccounts ?? [],
    isLoading,
    error,
    createAccount,
    deleteAccount,
    isCreating: createMutation.isPending,
    isDeleting: deleteMutation.isPending
  }
}
