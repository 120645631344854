import Api from '..'
import { z } from 'zod'

export enum FeedbackType {
  FEATURE_REQUEST = 'Feature request',
  CUSTOMER_REQUEST = 'Customer request',
  IDEA = 'Idea',
  BUG_REPORT = 'Bug report',
  OTHER = 'Other'
}

const submitFeedbackParser = z.object({
  body: z.string(),
  type: z.nativeEnum(FeedbackType),
  jobSearchRefinementId: z.string().optional()
})

export type SubmitFeedback = z.infer<typeof submitFeedbackParser>

export async function submitFeedbackApi (feedback: SubmitFeedback): Promise<boolean> {
  await Api.post('/feedback/submit', null, feedback)

  return true
}
