import { format } from 'date-fns'

interface DataPoint {
  label: string | number
  [key: string]: string | number
}

interface ChartOptions {
  friendlyDate?: boolean
  minimumDataPoints?: number
}

export const adjustChartDataWithMinimumPoints = (
  data: DataPoint[],
  options: ChartOptions = {}
): DataPoint[] => {
  const { minimumDataPoints = 7, friendlyDate } = options

  if (data.length === 0) {
    const sampleData = {
      label: '',
      placeholder1: 0,
      placeholder2: 0
    }
    return Array(minimumDataPoints).fill(null).map(() => ({ ...sampleData }))
  }

  const emptyDataPointTemplate: DataPoint = Object.keys(data[0]).reduce<DataPoint>(
    (template, key) => {
      if (key !== 'label') {
        template[key] = 0
      }
      return template
    },
    { label: '' }
  )

  if (data.length >= minimumDataPoints) {
    return friendlyDate
      ? data.map(point => ({
        ...point,
        label: point.label ? format(new Date(point.label), 'MMM d') : ''
      }))
      : data
  }

  const emptyDataPoints: DataPoint[] = []
  for (let i = 0; i < minimumDataPoints - data.length; i++) {
    const emptyDataPoint: DataPoint = { ...emptyDataPointTemplate, label: '' }
    emptyDataPoints.push(emptyDataPoint)
  }

  const result = [...data, ...emptyDataPoints]

  return friendlyDate
    ? result.map(point => ({
      ...point,
      label: point.label ? format(new Date(point.label), 'MMM d') : ''
    }))
    : result
}
