import type { Color } from '../theme/types'

export const colorsLight: Record<Color, string> = {
  // Background
  bgPrimary: 'hsla(0, 0%, 100%, 1)',
  bgSecondary: 'hsla(240, 20%, 99%, 1)',
  bgTertiary: 'hsla(240, 10%, 97%, 1)',
  bgTranslucent25: 'hsla(0, 0%, 100%, 0.25)',
  bgTranslucent10: 'hsla(0, 0%, 100%, 0.1)',

  // Foreground
  fgPrimary: 'hsla(240, 4%, 16%, 1)',
  fgSecondary: 'hsla(240, 4%, 42%, 1)',
  fgTertiary: 'hsla(240, 5%, 65%, 1)',
  fgFaded10: 'hsla(0, 0%, 91%, 1)',
  fgTranslucent25: 'hsla(240, 6%, 10%, 0.25)',
  fgTranslucent10: 'hsla(240, 6%, 10%, 0.1)',
  fgTranslucent5: 'hsla(240, 6%, 10%, 0.05)',

  // Borders
  borderOpaque: 'hsla(0, 0%, 93%, 1)',
  borderTranslucent: 'hsla(240, 5%, 26%, 0.1)',

  // Tint (blue)
  tintBg: 'hsla(221, 83%, 53%, 1)',
  tintFg: 'hsla(221, 85%, 45%, 1)',
  tintFaded: 'hsla(226, 100%, 97%, 1)',
  tintTranslucent25: 'hsla(221, 83%, 53%, 0.25)',
  tintTranslucent15: 'hsla(221, 83%, 53%, 0.15)',
  tintTranslucent5: 'hsla(221, 83%, 53%, 0.05)',
  tintContrast: '#fff',

  // Negative (danger)
  negativeBg: 'hsla(0, 72%, 51%, 1)',
  negativeFg: 'hsla(0, 83%, 43%, 1)',
  negativeFaded: 'hsla(0, 86%, 97%, 1)',
  negativeTranslucent25: 'hsla(0, 72%, 51%, 0.25)',
  negativeTranslucent10: 'hsla(0, 72%, 51%, 0.10)',
  negativeTranslucent5: 'hsla(0, 72%, 51%, 0.05)',
  negativeContrast: '#fff',

  // Positive (success)
  positiveBg: 'hsla(142, 76%, 36%, 1)',
  positiveFg: 'hsla(142, 97%, 28%, 1)',
  positiveFaded: 'hsla(138, 76%, 97%, 1)',
  positiveTranslucent25: 'hsla(142, 76%, 36%, 0.25)',
  positiveTranslucent10: 'hsla(142, 76%, 36%, 0.1)',
  positiveTranslucent5: 'hsla(142, 76%, 36%, 0.05)',
  positiveContrast: '#fff',

  // Warning
  warningBg: 'hsla(33, 90%, 58%, 1)',
  warningFg: 'hsla(33, 80%, 47%, 1)',
  warningFaded: 'hsla(32, 90%, 96%, 1)',
  warningTranslucent25: 'hsla(33, 90%, 58%, 0.25)',
  warningTranslucent10: 'hsla(33, 90%, 58%, 0.1)',
  warningTranslucent5: 'hsla(33, 90%, 58%, 0.05)',

  // Charts
  chart1: '#3B60A0',
  chart2: '#9FDBD0',
  chart3: '#EFCA70',
  chart4: '#3B82A1',
  chart5: '#A9D674',
  chart6: '#6A4F79',
  chart7: '#F2A464',

  // Static
  staticTransparent: 'hsla(0, 0%, 100%, 0)',

  // AI color
  aiTranslucent10: 'hsla(275, 73%, 76%, 0.1)',
  aiSolidBg: 'hsla(252, 75%, 76%, 1)',
  aiSolidFg: 'hsla(256, 77%, 60%, 1)',
  aiSolidTranslucent50: 'hsla(256, 77%, 60%, 0.5)',
  aiSolidTranslucent10: 'hsla(256, 77%, 60%, 0.1)',
  aiSolidTranslucent5: 'hsla(256, 77%, 60%, 0.05)'
}

export const colorsDark: Record<Color, string> = {
  // Background
  bgPrimary: 'hsla(240, 4%, 16%, 1)',
  bgSecondary: 'hsla(240, 8%, 23%, 1)',
  bgTertiary: 'hsla(240, 6%, 27%, 1)',
  bgTranslucent25: 'hsla(240, 6%, 10%, 0.25)',
  bgTranslucent10: 'hsla(240, 6%, 10%, 0.1)',

  // Foreground
  fgPrimary: 'hsla(0, 0%, 100%, 1)',
  fgSecondary: 'hsla(240, 1%, 68%, 1)',
  fgTertiary: 'hsla(240, 2%, 42%, 1)',
  fgFaded10: 'hsla(0, 0%, 91%, 1)',
  fgTranslucent25: 'hsla(0, 0%, 100%, 0.25)',
  fgTranslucent10: 'hsla(0, 0%, 100%, 0.1)',

  // Borders
  borderOpaque: 'hsla(0, 0%, 26%, 1)',
  borderTranslucent: 'hsla(240, 5%, 26%, 0.1)',

  // Tint (blue)
  tintBg: 'hsla(221, 83%, 53%, 1)',
  tintFg: 'hsla(221, 100%, 61%, 1)',
  tintFaded: 'hsla(223, 20%, 21%, 1)',
  tintTranslucent25: 'hsla(221, 83%, 53%, 0.25)',
  tintTranslucent15: 'hsla(221, 83%, 53%, 0.15)',
  tintTranslucent5: 'hsla(221, 83%, 53%, 0.05)',
  tintContrast: '#fff',

  // Negative (danger)
  negativeBg: 'hsla(0, 72%, 51%, 1)',
  negativeFg: 'hsla(0, 86%, 58%, 1)',
  negativeFaded: 'hsla(350, 19%, 19%, 1)',
  negativeTranslucent25: 'hsla(0, 72%, 51%, 0.25)',
  negativeTranslucent5: 'hsla(0, 72%, 51%, 0.05)',

  // Positive (success)
  positiveBg: 'hsla(142, 76%, 36%, 1)',
  positiveFg: 'hsla(142, 58%, 52%, 1)',
  positiveFaded: 'hsla(154, 16%, 17%, 1)',
  positiveTranslucent25: 'hsla(142, 76%, 36%, 0.25)',
  positiveTranslucent5: 'hsla(142, 76%, 36%, 0.05)',

  // Warning
  warningBg: 'hsla(33, 90%, 58%, 1)',
  warningFg: 'hsla(33, 99%, 62%, 1)',
  warningFaded: 'hsla(28, 17%, 20%, 1)',
  warningTranslucent25: 'hsla(33, 90%, 58%, 0.25)',
  warningTranslucent10: 'hsla(33, 90%, 58%, 0.1)',

  // Charts
  chart1: '#3B60A0',
  chart2: '#9FDBD0',
  chart3: '#EFCA70',
  chart4: '#3B82A1',
  chart5: '#A9D674',
  chart6: '#6A4F79',
  chart7: '#F2A464',

  // Static
  staticTransparent: 'hsla(0, 0%, 100%, 0)'
}
