import { queryOptions, useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { fetchMergeJobs } from 'src/libs/api/backend/merge'
import type { MergeAtsJob } from 'src/libs/api/backend/merge'

export const getMergeJobs = () => queryOptions({
  queryKey: [queryKeys.mergeJobs],
  queryFn: async () => await fetchMergeJobs(),
  staleTime: 15 * 1000
})

export const useGetMergeJobs = (): UseQueryResult<MergeAtsJob[]> => {
  return useQuery(getMergeJobs())
}
