import { atom } from 'jotai'

export interface TableState {
  sort: { id: string, direction: 'asc' | 'desc' | undefined } | undefined
  selectedRows: Set<string>
}

export const tableStateAtom = atom<TableState>({
  sort: undefined,
  selectedRows: new Set<string>()
})

export const selectedRowsAtom = atom((get) => get(tableStateAtom).selectedRows)
export const sortAtom = atom((get) => get(tableStateAtom).sort)

export const addSelectedRowsAtom = atom(null, (get, set, rows: string[]) => {
  set(tableStateAtom, {
    ...get(tableStateAtom),
    selectedRows: new Set([...get(tableStateAtom).selectedRows, ...rows])
  })
})
export const toggleSelectedRowAtom = atom(null, (get, set, row: string) => {
  const currentSelectedRows = get(tableStateAtom).selectedRows
  const newSelectedRows = new Set(currentSelectedRows)
  newSelectedRows.has(row) ? newSelectedRows.delete(row) : newSelectedRows.add(row)
  set(tableStateAtom, {
    ...get(tableStateAtom),
    selectedRows: newSelectedRows
  })
})
export const clearSelectedRowsAtom = atom(null, (get, set) => {
  set(tableStateAtom, {
    ...get(tableStateAtom),
    selectedRows: new Set<string>()
  })
})
export const selectAllRowsAtom = atom(null, (get, set, rows: string[]) => {
  set(tableStateAtom, {
    ...get(tableStateAtom),
    selectedRows: new Set(rows)
  })
})

export const setTableSortAtom = atom(null, (get, set, sort: TableState['sort']) => {
  set(tableStateAtom, {
    ...get(tableStateAtom),
    sort
  })
})
