import { Caption } from 'src/components/primitives/typography'
import * as S from './report-feedback-banner.styled'
import { Button, Flex } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import { useLocalStorage } from 'usehooks-ts'
import { LocalStorageKey } from 'src/constants'
import { FeedbackType } from 'src/libs/api/backend/feedback'
import { useSetAtom } from 'jotai'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'

export const ReportFeedbackBanner = (): JSX.Element | null => {
  const [reportFeedbackBannerClosed, setReportFeedbackBannerClosed] = useLocalStorage(LocalStorageKey.REPORT_FEEDBACK_BANNER_CLOSED, false)

  const openDialog = useSetAtom(openDialogAtom)
  if (reportFeedbackBannerClosed) {
    return null
  }
  return (
    <S.Banner>
      <Caption $color='fgSecondary' size="XS">
        We're building more reports. Let us know which stats are most important to you.
      </Caption>
      <Flex $gap={20} $width="auto" $align='center'>
        <Button
          leadingIcon={Icons.megaphone}
          $height={24}
          $variant='raised-light'
          $fontSize={12}
          onClick={() => {
            openDialog({
              id: DialogId.SEND_FEEDBACK,
              payload: {
                type: FeedbackType.IDEA,
                defaultContent: 'I have a suggestion for reports:<br>'
              }
            })
          }}
        >
          Suggest a report
        </Button>
        <Button
          leadingIcon={Icons.x}
          $variant='ghost'
          $height={24}
          $width={24}
          $colorTheme='muted'
          onClick={() => {
            setReportFeedbackBannerClosed(true)
          }}
        />
      </Flex>
    </S.Banner>
  )
}
