import { Button } from 'src/components/primitives/button'
import { Caption, Link, Paragraph } from 'src/components/primitives/typography'
import { useSession } from 'src/hooks/use-session'
import LoginError from '../login-error'
import { isNil } from 'lodash'
import { useMergeLink } from '@mergeapi/react-merge-link'
import { submitMergePublicToken } from 'src/libs/api/backend/merge_link_token'
import { useMergeLinkToken } from 'src/hooks/use-merge-link-token'
import { useDisconnectMergeIntegration } from 'src/hooks/mutations/use-disconnect-merge-integration'
import { useMergeIntegrationsListQuery } from 'src/hooks/queries/use-merge-integrations-list'
import { useEffect, useMemo, useState } from 'react'
import { Card, Flex, Grid } from 'src/components/primitives'
import type { GridBaseProps } from 'src/components/primitives'
import { PageHeader } from '../page-header'
import { When } from '../when'
import { titleCase } from 'src/libs/string'
import { Avatar } from 'src/components/primitives/avatar'
import { Box } from 'src/components/primitives/box'
import { Icon, Icons } from 'src/components/primitives/icon'
import * as S from './ats-settings.styled'
import { useLocation, useParams } from 'react-router-dom'
import { Banner } from '../banner'
import { Spinner } from 'src/components/primitives/spinner'

interface AtsSettingsProps {
  integrationsColumnsCount?: GridBaseProps['$columns']
}

export const AtsSettings = ({ integrationsColumnsCount = 3 }: AtsSettingsProps): JSX.Element => {
  const { search } = useLocation()
  const { jobId } = useParams()
  const params = new URLSearchParams(search)
  const error = params.get('error')
  const [searchAts, setSearchAts] = useState<string>('')
  const [integration, setIntegration] = useState<string | undefined>(undefined)
  const [isImporting, _] = useState<boolean>(false)

  const { org, refetchSession } = useSession()

  const { disconnectAtsIntegration } = useDisconnectMergeIntegration()
  const { data: mergeIntegrations = [] } = useMergeIntegrationsListQuery()

  const { data: linkToken } = useMergeLinkToken(integration)
  const { open: openMergeLink } = useMergeLink({
    linkToken: integration ? linkToken : undefined,
    onSuccess: (publicToken): void => {
      async function submitAndFetch (): Promise<void> {
        await submitMergePublicToken(publicToken)
        refetchSession()
      }
      void submitAndFetch()
    },
    onExit: (): void => {
      // setIntegration(undefined)
    }
  })

  useEffect(() => {
    if (integration && linkToken) {
      openMergeLink()
    }
  }, [integration, linkToken, openMergeLink])

  const isAtsConnected = useMemo(() => {
    return !isNil(org?.mergeAtsIntegration)
  }, [org?.mergeAtsIntegration])

  const { integrationName, integrationImage } = useMemo(() => {
    let integrationName: string | null = null
    let integrationImage = null
    if (!isNil(org) && !isNil(org?.mergeAtsIntegration)) {
      integrationName = titleCase(org.mergeAtsIntegration)
      integrationImage = mergeIntegrations.find(integration => integration.name === integrationName)?.square_image
    }
    return { integrationName, integrationImage }
  }, [mergeIntegrations, org])

  const filteredIntegrations = useMemo(() => {
    const searchAtsLowerCase = searchAts.toLowerCase()
    return mergeIntegrations.filter(integration => integration.name.toLowerCase().includes(searchAtsLowerCase))
  }, [mergeIntegrations, searchAts])

  return (
    <Flex $direction='column' $gap={24}>
      <PageHeader heading="ATS Integration" subheading="Connect Pin to your ATS" marginBottom={0} />
      {error && (
        <LoginError error={error} />
      )}
      {
        isImporting && (
          <Banner $padding={{ top: 16, bottom: 16, left: 16, right: 16 }} $borderRadius={8}>
            <Spinner size={20} />
            <Caption size="SM" $color="fgSecondary" as="h6" $lineHeight={1}>We&rsquo;re still importing your ATS data… We&rsquo;ll email you when it&rsquo;s complete.</Caption>
          </Banner>
        )
      }

      <When condition={isAtsConnected}>
        <Flex $direction='column' $gap={20}>
          <Card>
            <Flex $direction='row' $justify='space-between' $align='center' $gap={16}>
              <Flex $direction='row' $justify='flex-start' $align='center' $gap={16} $width='auto'>
                <Avatar
                  $type='logo'
                  company={{
                    name: integrationName ?? '',
                    externalLogoUrl: integrationImage
                  }}
                />
                <Flex $direction="column" $gap={4}>
                  <Caption>{integrationName}</Caption>
                  <Paragraph>Connected</Paragraph>
                </Flex>
              </Flex>
              <Button
                $width='100%'
                $innerMinWidth='100%'
                $variant="raised"
                $colorTheme="negative"
                $minWidth='none'
                onClick={() => {
                  disconnectAtsIntegration({
                    onSuccess: () => {
                      refetchSession()
                    }
                  })
                }}
              >
                Disconnect
              </Button>
            </Flex>
          </Card>
          <Paragraph >Manage ATS syncing for this job position in <Link href={`/jobs/${jobId}/automation/`}>Pin automations</Link></Paragraph>
        </Flex>
      </When>
      <When condition={!isAtsConnected}>
        <Box $padding={{ top: 16, right: 16, bottom: 16, left: 16 }} $border='borderTranslucent' $borderRadius='4px' $background='bgSecondary' $width='100%'>
          <Paragraph size='SM'>No ATS connected.</Paragraph>
        </Box>
        <Box $padding={{ top: 6, right: 6, bottom: 6, left: 6 }} $border='borderTranslucent' $background='bgPrimary' $width='100%' $gap={2} $height={32} $align='center' $display='flex'>
          <S.Icon>
            <Icon name={Icons.search} size={16} />
          </S.Icon>
          <S.FilterInput
            placeholder='Filter list'
            value={searchAts}
            onChange={(e) => {
              setSearchAts(e.target.value)
            }}
          />
        </Box>
        <Grid $columns={integrationsColumnsCount} $columnGap={16} $rowGap={16} $width='100%'>
          {filteredIntegrations.map((integration) => (
            <Button
              key={integration.slug}
              $variant="raised"
              $width='full'
              $colorTheme="normal"
              leadingIcon={<img src={integration.square_image} width={32} height={32} />}
              onClick={() => {
                setIntegration(integration.slug)
              }}
            >
              {integration.name}
            </Button>
          ))}
        </Grid>
      </When>
    </Flex>
  )
}
