import React from 'react'

import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'

import { z } from 'zod'
import Api, { ApiError } from 'src/libs/api'

const parserThatGeneratesError = z.object({
  thisFieldDoesNotExist: z.string()
})

type ParserThatGeneratesError = z.infer<typeof parserThatGeneratesError>

async function fetchRandomEndpoint (): Promise<ParserThatGeneratesError[]> {
  const { data, status } = await Api.get('/departments')
  if (status !== 200) {
    throw new ApiError('Failed to get departments from org')
  }

  return parserThatGeneratesError.array().parse(data)
}

const useGenerateErrorQuery = (): UseQueryResult<ParserThatGeneratesError[]> => {
  return useQuery({
    queryKey: ['generateError'],
    queryFn: async () => await fetchRandomEndpoint(),
    staleTime: 60 * 1000
  })
}

const GenerateErrorPage: React.FC = () => {
  useGenerateErrorQuery()

  return (
    <>
      <button
        type="button"
        onClick={() => {
          throw new Error('Sentry Test Error')
        }}
      >
        Break the world
      </button>
    </>
  )
}

export default GenerateErrorPage
