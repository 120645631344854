import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { searchLocations } from 'src/libs/api/backend/typeaheads'
import type { LocationSuggestion } from 'src/libs/api/backend/typeaheads'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  query?: string
}

export const useLocationSuggestions = ({ query }: Args): UseQueryResult<LocationSuggestion[]> => {
  return useQuery({
    queryKey: [queryKeys.locationSuggestions, query],
    queryFn: async () => await searchLocations({ query }),
    staleTime: 60 * 1000,
    enabled: !!query
  })
}
