import { useCallback, useEffect, useState } from 'react'

interface InboxSidebar {
  setIsHovered: (value: boolean) => void
  toggleSidebarPinned: () => void
  sidebarLooksExpanded: boolean
  isPinned: boolean
  isHovered: boolean
}

export const useInboxSidebar = (): InboxSidebar => {
  const [isExpanded, setIsExpanded] = useState(true)
  const [isHovered, setIsHovered] = useState(false)
  const [isPinned, setIsPinned] = useState(true)
  const [sidebarLooksExpanded, setSidebarLooksExpanded] = useState(true)

  useEffect(() => {
    setSidebarLooksExpanded(isHovered || isPinned || isExpanded)
  }, [isExpanded, isHovered, isPinned])

  const toggleSidebarPinned = useCallback((): void => {
    setIsPinned((isPinned) => !isPinned)

    if (isPinned) {
      setIsExpanded(false)
      setIsHovered(false)
    }
  }, [isPinned])

  return {
    setIsHovered,
    toggleSidebarPinned,
    sidebarLooksExpanded,
    isPinned,
    isHovered
  }
}
