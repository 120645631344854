import { useCallback, useEffect, useState } from 'react'
import type { Dispatch } from 'react'
import { isNil, isEqual } from 'lodash'
import Api from 'src/libs/api'
import type { EmailSequence, EmailSequenceStep, OutreachPreferencesData } from 'src/models/sequence'
import { useOrgUsersQuery } from './queries/use-org-users'
import { useSession } from 'src/hooks/use-session'
import { useParams } from 'react-router-dom'
import type { EmailAccount } from 'src/libs/api/backend/users'
import { SequenceReply, SequenceStepType } from 'src/libs/api/backend/sequences'
import type { SequenceReplyType } from 'src/libs/api/backend/sequences'
import { notifyErrorAtom } from 'src/stores/notifications'
import { useSetAtom } from 'jotai'

interface Args {
  initialState: EmailSequence
  autoGenerateEmails?: boolean
  sequenceSize?: number
}

interface UseEmailSequenceEditorReturn {
  isLoading: boolean
  sequenceState: EmailSequence
  generatedEmails: EmailSequence['sequenceSteps']
  emailsBeingGenerated: number[] | undefined
  setEmailsBeingGenerated: Dispatch<React.SetStateAction<number[] | undefined>>
  isReadyForNextStep: () => boolean
  handleGenerateStepSuggestion: (stepIndex?: number) => Promise<null | undefined>
  handleConfirmStepSuggestion: (confirm: boolean, stepIndex?: number) => void
  handleAddStep: () => void
  handleDataChange: (updatedStep: EmailSequenceStep) => void
  handleRemoveStep: (stepToRemove: EmailSequenceStep) => void
  handleUpdateReplyType: (currentStep: EmailSequenceStep, replyType: SequenceReplyType) => void
  handleUpdateSequencePreferences: (updatedPreferences: OutreachPreferencesData) => void
  hasEmptySequenceStep: () => boolean
  getSendingEmailAccount: ({
    sendingEmailAccountId,
    currentUserId
  }: {
    sendingEmailAccountId?: string | undefined
    currentUserId?: string | undefined
  }) => EmailAccount
  getStepSubjectAndPlaceholder: (stepPosition: number) => {
    subject: string | null
    placeholder: string | null
  }
  hasUnsavedChanges: boolean
  userEmailAccounts: EmailAccount[]
  setHasUnsavedChanges: Dispatch<React.SetStateAction<boolean>>
  validateSequenceState: (sequence: EmailSequence) => Promise<EmailSequence>
  getNewPosition: () => number
}

export const generateInitialSequence = (sequenceSize: number): EmailSequence => {
  return {
    id: null,
    sequenceSteps: Array.from({ length: sequenceSize }, (_, index) => ({
      position: index,
      type: SequenceStepType.AUTOMATED_EMAIL,
      waitDays: 0,
      subject: `Email ${index + 1}`,
      body: '',
      sendingEmailAlias: null,
      sendingLinkedInAccountId: null
    }))
  }
}

export const useEmailSequenceEditor = ({
  initialState,
  autoGenerateEmails = true,
  sequenceSize = 3
}: Args): UseEmailSequenceEditorReturn => {
  const { jobId } = useParams()
  const { user } = useSession()
  const [isLoading, setIsLoading] = useState(true)
  const [sequenceState, setSequenceState] = useState<EmailSequence>(initialState)
  const [generatedEmails, setGeneratedEmails] = useState<EmailSequence['sequenceSteps']>()
  const [emailsBeingGenerated, setEmailsBeingGenerated] = useState<number[]>()
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [userEmailAccounts, setUserEmailAccounts] = useState<EmailAccount[]>([])

  const notifyError = useSetAtom(notifyErrorAtom)

  const { data: orgUsers } = useOrgUsersQuery()

  useEffect(() => {
    setUserEmailAccounts(orgUsers?.flatMap((user) => user.emailAccounts) ?? [])
  }, [orgUsers])

  const sortSteps = (steps: EmailSequence['sequenceSteps']): EmailSequence['sequenceSteps'] => {
    if (isNil(steps)) {
      return []
    }

    const sortedSteps = steps.sort((a, b) => {
      if (a.deleted && !b.deleted) return 1
      if (!a.deleted && b.deleted) return -1
      return 0
    })

    return sortedSteps.map((step, index) => ({
      ...step,
      position: index
    }))
  }

  const getNewPosition = useCallback((): number => {
    return (
      sequenceState?.sequenceSteps?.reduce((max, current) => {
        return current.position > max ? current.position + 1 : max + 1
      }, 0) ?? 1
    )
  }, [sequenceState?.sequenceSteps])

  const getSendingEmailAccount = useCallback(({
    sendingEmailAccountId,
    currentUserId
  }: {
    sendingEmailAccountId?: string | undefined
    currentUserId?: string | undefined
  }): EmailAccount => {
    if (currentUserId) {
      return (
        userEmailAccounts.find((account) => account.userId === currentUserId) ??
        userEmailAccounts.find(account => account.isPrimary) ??
        userEmailAccounts[0]
      )
    }
    return (
      userEmailAccounts.find((account) => account.id === sendingEmailAccountId) ??
      userEmailAccounts.find((account) => account.userId === user?.id) ??
      userEmailAccounts.find(account => account.isPrimary) ??
      userEmailAccounts[0]
    )
  }, [user?.id, userEmailAccounts])

  const getStepSubjectAndPlaceholder = useCallback((stepPosition: number): { subject: string | null, placeholder: string | null } => {
    if (stepPosition < 0) {
      stepPosition = 0
    }

    const step = sequenceState.sequenceSteps?.at(stepPosition)
    if (stepPosition === 0) {
      return {
        subject: step?.subject ?? 'Intro Email',
        placeholder: null
      }
    }

    if (!isNil(step?.subject)) {
      return {
        subject: step.subject,
        placeholder: null
      }
    }

    for (let i = stepPosition - 1; i >= 0; i--) {
      const prevStep = sequenceState.sequenceSteps?.at(i)
      if (!isNil(prevStep?.subject)) {
        return {
          subject: null,
          placeholder: prevStep.subject
        }
      }
    }

    return {
      subject: `New email ${stepPosition}`,
      placeholder: null
    }
  }, [sequenceState.sequenceSteps])

  useEffect(() => {
    if (initialState && !generatedEmails) {
      const sortedSteps = initialState.sequenceSteps
        ?.filter((step) => !step.deleted)
        .sort((a, b) => a.position - b.position)

      const stateWithSortedSteps = {
        ...initialState,
        sequenceSteps: sortedSteps
      }
      setSequenceState(stateWithSortedSteps)
      setGeneratedEmails(sortedSteps)
      setIsLoading(false)
    }
  }, [generatedEmails, initialState])

  useEffect(() => {
    const generateEmails = async (): Promise<void> => {
      if (isNil(initialState) && autoGenerateEmails) {
        if (isNil(jobId)) {
          return
        }
        try {
          const cachedSequence = sequenceState
          setIsLoading(false)
          for (const i of Array.from({ length: sequenceSize }).keys()) {
            try {
              setEmailsBeingGenerated([i])
              const email = await generateEmail({
                jobId,
                position: i
              })
              if (email) {
                const foundStep = findStepByPosition({
                  sequenceSteps: cachedSequence.sequenceSteps,
                  position: i
                })
                const updatedStep = {
                  ...foundStep,
                  position: i,
                  body: email ?? '<p>New email</p>',
                  sendingUserId: getSendingEmailAccount({ currentUserId: user?.id })?.userId,
                  sendingEmailAccountId: getSendingEmailAccount({ currentUserId: user?.id })?.id,
                  sendingLinkedInAccountId: null,
                  sendingEmailAlias: null
                }

                if (!isNil(cachedSequence.sequenceSteps)) {
                  cachedSequence.sequenceSteps[i] = updatedStep
                }

                setEmailsBeingGenerated((prev) => prev?.filter((prev) => prev !== i))
                setGeneratedEmails(cachedSequence.sequenceSteps)
                setSequenceState(cachedSequence)
              } else {
                console.error(`Failed to generate email for step ${i}`)
                break
              }
            } catch (e) {
              break
            }
          }
        } catch (err) {
          notifyError({
            message: 'An error occured while generating, try again in a moment'
          })
          setEmailsBeingGenerated([])
        }
      }
      if (isNil(initialState) && !autoGenerateEmails && user && userEmailAccounts.length) {
        setSequenceState({
          ...sequenceState,
          sequenceSteps: sequenceState.sequenceSteps?.map((step) => ({
            ...step,
            sendingUserId: getSendingEmailAccount({ currentUserId: user?.id })?.userId,
            sendingEmailAccountId: getSendingEmailAccount({ currentUserId: user?.id })?.id
          }))
        })
      }
    }
    void generateEmails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoGenerateEmails, jobId, user?.id])

  const findStepByPosition = ({
    sequenceSteps,
    position
  }: {
    sequenceSteps: EmailSequence['sequenceSteps']
    position: number
  }): EmailSequenceStep | undefined => {
    return sequenceSteps?.find((step) => step.position === position)
  }

  const generateEmail = useCallback(async ({
    jobId,
    position
  }: {
    jobId: string
    position: number
  }): Promise<string> => {
    if (isNil(jobId)) {
      throw new Error('jobId missing')
    }

    const getPreviousSequenceSteps = (currentStep: number): Array<string | null> => {
      if (!Array.isArray(sequenceState.sequenceSteps)) {
        console.warn('Couldn\'t access previous steps to generate the next email')
        return []
      }

      if (currentStep === 0) {
        return []
      }

      const result: Array<string | null> = []
      for (let i = 0; i < currentStep; i++) {
        const step = sequenceState.sequenceSteps[i].body
        const isEmptyBody = isNil(step) || step === '' || step === '<div></div>'
        if (isEmptyBody) {
          result.push(null)
        } else {
          result.push(step)
        }
      }

      return result
    }

    const response = await Api.post('/sequences/gpt/generate_step', null, {
      jobId,
      previousSequenceSteps: getPreviousSequenceSteps(position),
      stepPosition: position
    })

    if (response.status !== 200) {
      throw new Error('Failed to generate email suggestion')
    }

    const generatedStep = response?.data?.step as string
    return generatedStep?.replace(/\n/g, '<br />')
  }, [sequenceState.sequenceSteps])

  const replaceStep = ({
    sequenceSteps,
    updatedStep
  }: {
    sequenceSteps: EmailSequence['sequenceSteps']
    updatedStep: EmailSequenceStep
  }): EmailSequence['sequenceSteps'] => {
    const updatedSteps = sequenceSteps?.map((step) => {
      if (step.position === updatedStep.position) {
        return updatedStep
      }
      return step
    })

    return updatedSteps
  }

  const handleAddStep = useCallback(() => {
    let cachedState = sequenceState
    if (isNil(sequenceState.sequenceSteps)) {
      return null
    }

    const newPosition = getNewPosition()
    const previousStep = sequenceState.sequenceSteps?.[newPosition - 1]

    const newStepPartial: EmailSequenceStep = {
      position: newPosition,
      type: SequenceStepType.AUTOMATED_EMAIL,
      waitDays: newPosition > 2 ? 5 : [0, 3, 3][newPosition],
      subject: null,
      body: '',
      sendingUserId:
        previousStep?.sendingUserId ?? getSendingEmailAccount({ currentUserId: user?.id }).userId,
      sendingEmailAccountId:
        previousStep?.sendingEmailAccountId ??
        getSendingEmailAccount({ currentUserId: user?.id }).id,
      sendingEmailAlias: previousStep?.sendingEmailAlias,
      sendingLinkedInAccountId: previousStep?.sendingLinkedInAccountId
    }

    const updatedSteps = [...sequenceState.sequenceSteps, newStepPartial]
    cachedState = {
      ...cachedState,
      sequenceSteps: updatedSteps
    }
    setSequenceState(cachedState)
  }, [getNewPosition, getSendingEmailAccount, sequenceState, user?.id])

  const isReadyForNextStep = useCallback((): boolean => {
    if (isNil(sequenceState.sequenceSteps)) {
      return false
    }

    if (!Array.isArray(sequenceState.sequenceSteps) || sequenceState.sequenceSteps.length === 0) {
      return false
    }

    return true
  }, [sequenceState])

  const hasEmptySequenceStep = useCallback((): boolean => {
    if (isNil(sequenceState.sequenceSteps)) {
      return true
    }

    const hasEmptyStep = sequenceState.sequenceSteps.some(step => isNil(step.body) || step.body === '' || step.body === '<div></div>')
    return hasEmptyStep
  }, [sequenceState])
  /**
   * @param stepIndex: number | undefined
   *  - number: generate an email suggestion for the step at the specified index
   *  - undefined: generate an email suggestion for a new step
   */
  const handleGenerateStepSuggestion = useCallback(async (stepIndex?: number) => {
    if (isNil(sequenceState.sequenceSteps)) {
      return null
    }

    const isAddingNewStep = isNil(stepIndex)
    if (!isAddingNewStep) {
      const focusedStep = sequenceState.sequenceSteps[stepIndex]
      if (!isNil(focusedStep.emailBodySuggestion)) {
        return
      }

      // UPDATE STATE TO SHOW WE ARE GENERATING A NEW EMAIL
      setEmailsBeingGenerated([stepIndex])

      // START GENERATING A NEW EMAIL SUGGESTION
      let emailBodySuggestion
      try {
        emailBodySuggestion = await generateEmail({
          jobId: jobId ?? '',
          position: stepIndex
        })
      } catch (err) {
        setEmailsBeingGenerated([])
        notifyError({
          message: 'An error occured while generating, try again in a moment'
        })

        return
      }

      // UPDATE STATE WITH GENERATE EMAIL SUGGESTION
      const updatedSteps = replaceStep({
        sequenceSteps: sequenceState.sequenceSteps,
        updatedStep: {
          ...focusedStep,
          emailBodySuggestion
        }
      })

      setSequenceState({ ...sequenceState, sequenceSteps: updatedSteps })
      setEmailsBeingGenerated([])
    } else {
      // CREATE A NEW EMPTY EMAIL SEQUENCE STEP OBJECT AND SET DEFAULT VALUES
      const newPosition = getNewPosition()
      // const previousStep = sequenceState.sequenceSteps?.[newPosition - 1]
      const sendingUserId = /* previousStep?.sendingUserId ?? */ getSendingEmailAccount({ currentUserId: user?.id }).userId
      const sendingEmailAccountId = /* previousStep?.sendingEmailAccountId ?? */ getSendingEmailAccount({ currentUserId: user?.id }).id
      const newStepPartial: EmailSequenceStep = {
        position: newPosition,
        type: SequenceStepType.AUTOMATED_EMAIL,
        waitDays: newPosition > 2 ? 5 : [0, 3, 3][newPosition],
        subject: null,
        body: '',
        sendingUserId,
        sendingEmailAccountId,
        sendingEmailAlias: null,
        sendingLinkedInAccountId: null
      }

      // RENDER THE NEW STEP THAT WAS JUST ADDED
      // WE WANT TO TRIGGER THIS RE-RENDER SO IT SHOWS AN EMPTY STEP WAS JUST ADDED WHILE THE USER WAITS FOR THE EMAIL GENERATION TO FINISH
      setSequenceState({ ...sequenceState, sequenceSteps: [...sequenceState.sequenceSteps, newStepPartial] })

      // GENERATE A NEW EMAIL SUGGESTION
      setEmailsBeingGenerated([newPosition])
      try {
        newStepPartial.emailBodySuggestion = await generateEmail({
          jobId: jobId ?? '',
          position: newPosition
        })
      } catch (err) {
        setEmailsBeingGenerated([])
        notifyError({
          message: 'An error occured while generating, try again in a moment'
        })
      }

      // RENDER THE EMAIL SUGGESTION
      const updatedSteps = [...sequenceState.sequenceSteps, newStepPartial]
      setSequenceState({ ...sequenceState, sequenceSteps: updatedSteps })
      setGeneratedEmails(updatedSteps)
      setEmailsBeingGenerated([])
    }
  }, [sequenceState, generateEmail, jobId, notifyError, getNewPosition, getSendingEmailAccount, user?.id])

  const handleConfirmStepSuggestion = (confirm: boolean, stepIndex?: number): void => {
    if (isNil(sequenceState.sequenceSteps)) {
      return
    }

    const cachedSequenceSteps = sequenceState.sequenceSteps
    if (!Array.isArray(cachedSequenceSteps) || cachedSequenceSteps.length === 0) {
      return
    }

    const focusedStepIndex = stepIndex ?? sequenceState.sequenceSteps.length - 1
    const focusedStep = sequenceState.sequenceSteps.at(focusedStepIndex)
    if (isNil(focusedStep)) {
      return
    }

    // set the body equal to the email body suggestion
    const suggestion = confirm
      ? (focusedStep?.body ?? '') + focusedStep.emailBodySuggestion
      : (focusedStep?.body ?? '')

    cachedSequenceSteps[focusedStepIndex].body = suggestion
    cachedSequenceSteps[focusedStepIndex].emailBodySuggestion = undefined

    // update the state
    setSequenceState({
      ...sequenceState,
      sequenceSteps: cachedSequenceSteps
    })
    setGeneratedEmails(cachedSequenceSteps)
  }

  const removeStep = ({
    sequenceSteps,
    stepToRemove
  }: {
    sequenceSteps: EmailSequence['sequenceSteps']
    stepToRemove: EmailSequenceStep
  }): EmailSequence['sequenceSteps'] => {
    return sequenceSteps
      ?.filter((step: EmailSequenceStep) => {
        if (stepToRemove.id && step.id) {
          return step.id !== stepToRemove.id
        } else {
          return step.position !== stepToRemove.position
        }
      })
      .map((step: EmailSequenceStep, index: number) => {
        return {
          ...step,
          position: index
        }
      })
  }

  const handleRemoveStep = (stepToRemove: EmailSequenceStep): void => {
    const updatedSteps = removeStep({
      sequenceSteps: sequenceState.sequenceSteps,
      stepToRemove
    })
    setHasUnsavedChanges(true)
    setSequenceState((prevState) => ({
      ...prevState,
      sequenceSteps: updatedSteps
    }))
  }

  const handleDataChange = (updatedStep: EmailSequenceStep): void => {
    const stepIndex = sequenceState.sequenceSteps?.findIndex(
      (step) => step.position === updatedStep.position
    )
    if (isNil(stepIndex)) {
      return
    }

    let newSequenceSteps = [...(sequenceState.sequenceSteps ?? [])]
    if (stepIndex !== -1) {
      newSequenceSteps[stepIndex] = updatedStep
    }
    const currentStep = sequenceState?.sequenceSteps?.[stepIndex]
    if (currentStep) {
      if (!isEqual(currentStep, updatedStep)) {
        setHasUnsavedChanges(true)
      }

      // if we update the first step's from address,
      // and if the other steps haven't had their from address updated,
      // then set their from address to the updated from address
      const modifyingFirstStep = newSequenceSteps.length > 0 && stepIndex === 0
      const allStepsShareCommonSendingAddress = sequenceState.sequenceSteps?.every((step) => step.sendingEmailAccountId === currentStep.sendingEmailAccountId)
      const allStepsShareCommonSendingLinkedInAccount = sequenceState.sequenceSteps?.every((step) => step.sendingLinkedInAccountId === currentStep.sendingLinkedInAccountId)
      const modifyingSendingAddress = updatedStep.sendingEmailAccountId !== currentStep.sendingEmailAccountId || updatedStep.sendingUserId !== currentStep.sendingUserId || updatedStep.sendingEmailAlias !== currentStep.sendingEmailAlias
      const modifyingSendingLinkedInAccount = updatedStep.sendingLinkedInAccountId !== currentStep.sendingLinkedInAccountId

      if (modifyingFirstStep && allStepsShareCommonSendingLinkedInAccount && modifyingSendingLinkedInAccount) {
        const updatedRemainingSteps = newSequenceSteps.slice(stepIndex + 1).map((step) => ({
          ...step,
          sendingLinkedInAccountId: updatedStep.sendingLinkedInAccountId
        }))
        newSequenceSteps = [updatedStep, ...updatedRemainingSteps]
      } else if (modifyingFirstStep && allStepsShareCommonSendingAddress && modifyingSendingAddress) {
        const updatedRemainingSteps = newSequenceSteps.slice(stepIndex + 1).map((step) => ({
          ...step,
          sendingEmailAccountId: updatedStep.sendingEmailAccountId,
          sendingUserId: updatedStep.sendingUserId,
          sendingEmailAlias: updatedStep.sendingEmailAlias
        }))
        newSequenceSteps = [updatedStep, ...updatedRemainingSteps]
      }
    }

    setSequenceState({
      ...sequenceState,
      sequenceSteps: newSequenceSteps
    })
  }

  const handleUpdateReplyType = (
    currentStep: EmailSequenceStep,
    replyType: SequenceReplyType
  ): void => {
    if (replyType === SequenceReply.NEW_THREAD) {
      handleDataChange({
        ...currentStep,
        subject: ''
      })
    }
    if (replyType === SequenceReply.REPLY_TO_PREVIOUS_THREAD) {
      handleDataChange({
        ...currentStep,
        subject: null
      })
    }
  }

  const handleUpdateSequencePreferences = (updatedPreferences: OutreachPreferencesData): void => {
    const currentPreferences: OutreachPreferencesData = {
      autoArchiveAfterDays: sequenceState.autoArchiveAfterDays ?? 15,
      dailyEmailLimit: sequenceState.dailyEmailLimit ?? 100
    }
    if (!isEqual(currentPreferences, updatedPreferences)) {
      setHasUnsavedChanges(true)
    }
    setSequenceState((prevState) => ({
      ...prevState,
      ...updatedPreferences
    }))
  }

  const validateSequenceState = async (sequence: EmailSequence): Promise<EmailSequence> => {
    const defaultSendingAccount = getSendingEmailAccount({ currentUserId: user?.id })
    const stepsWithSender = sequence.sequenceSteps?.map((step) => {
      if (!step.sendingUserId || !step.sendingEmailAccountId) {
        return {
          ...step,
          sendingUserId: step.sendingUserId ?? defaultSendingAccount.userId,
          sendingEmailAccountId: step.sendingEmailAccountId ?? defaultSendingAccount.id
        }
      } else {
        return step
      }
    })
    const validatedSteps = sortSteps(stepsWithSender)
    return {
      ...sequenceState,
      sequenceSteps: validatedSteps
    }
  }

  return {
    isLoading,
    sequenceState,
    generatedEmails,
    emailsBeingGenerated,
    setEmailsBeingGenerated,
    handleAddStep,
    isReadyForNextStep,
    handleGenerateStepSuggestion,
    handleConfirmStepSuggestion,
    handleDataChange,
    handleRemoveStep,
    handleUpdateReplyType,
    handleUpdateSequencePreferences,
    hasEmptySequenceStep,
    getSendingEmailAccount,
    getStepSubjectAndPlaceholder,
    getNewPosition,
    userEmailAccounts,
    validateSequenceState,
    hasUnsavedChanges,
    setHasUnsavedChanges
  }
}
