import { useDraggable } from '@dnd-kit/core'
import { useRef } from 'react'
import { useSession } from 'src/hooks/queries/use-session'
// import { useOnClickOutsideIgnore } from 'src/hooks/use-on-click-outside-ignore'
import type { CalendarEvent } from 'src/libs/api/backend/calendar_events'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import type { Session } from 'src/libs/api/backend/session'
import * as S from './event.styled'
import { When } from '../when'
import { Flex } from 'src/components/primitives/flex'
import { Avatar } from 'src/components/primitives/avatar'
import { Spacer } from 'src/components/primitives/spacer'
import { Caption } from 'src/components/primitives/typography'

interface EventProps {
  isNewEvent: boolean
  event: CalendarEvent
  candidateJob?: CandidateJobExpanded
  top: number
  left?: string
  height: number
  width?: string
  onSelect?: () => void
  onResetSelectedEvent: () => void
  isSelected: boolean
  toolbarRef: React.RefObject<HTMLDivElement>
  headerRef: React.RefObject<HTMLDivElement>
}

interface CandidateAttendeeReturnType {
  name: string
  profilePhotoUrl?: string
}

export const Event = ({
  isNewEvent,
  event,
  top,
  left = '0%',
  height,
  width = '100%',
  onSelect,
  // onResetSelectedEvent,
  isSelected = false,
  // headerRef,
  // toolbarRef,
  candidateJob
}: EventProps): JSX.Element => {
  const isPinEvent = event?.isPinEvent

  const getAttendeeEmailAddress = (attendee: string): string | undefined => {
    if (typeof attendee !== 'string') {
      return undefined
    }
    const emailRegex = /<([^>]+)>|([^<\s]+@[^\s>]+)/
    const match = attendee?.match(emailRegex)
    return match ? match[1] || match[2] : ''
  }

  const attendees = event?.attendees as string[]
  const emailAddresses = attendees?.map((attendee) => getAttendeeEmailAddress(attendee))

  const { data: sessionData } = useSession()

  const getTeamMember = (): Session | undefined => {
    if (emailAddresses && emailAddresses.length >= 1) {
      const isEmailMatched = sessionData?.emailAccountAccessTokens?.some((account) =>
        emailAddresses.includes(account.email)
      )
      if (isEmailMatched) {
        return sessionData
      }
    }
    return undefined
  }

  const getCandidate = (): CandidateAttendeeReturnType => {
    if (emailAddresses && emailAddresses.length >= 1) {
      const isEmailMatched = candidateJob?.candidate?.emails?.some((candidateEmail) =>
        emailAddresses.includes(candidateEmail)
      )
      if (isEmailMatched) {
        return {
          name: candidateJob?.candidate?.name ?? '',
          profilePhotoUrl: candidateJob?.candidate?.profilePhotoUrl ?? ''
        }
      }

      // If we can't find a candidateJob for one of the emails, we want to
      // use a non-team-member email address as fallback
      // This is especially useful during creation of an event where we don't
      // have avatars ready (yet) or when viewing an event from another candidate
      // in the current candidate's inbox.
      // TODO: Always check for avatars
      for (const attendee of emailAddresses) {
        const attendeeEmail = getAttendeeEmailAddress(attendee ?? '')
        if (attendeeEmail) {
          const isTeamMemberEmailMatched = sessionData?.emailAccountAccessTokens?.some(
            (account) => account.email === attendeeEmail
          )
          if (!isTeamMemberEmailMatched) {
            return {
              name: attendeeEmail,
              profilePhotoUrl: ''
            }
          }
        }
      }
    }

    return {
      name: '',
      profilePhotoUrl: ''
    }
  }

  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: event?.id ?? '',
    disabled: !isPinEvent
  })

  const eventRef = useRef<HTMLDivElement>(null)
  // useOnClickOutsideIgnore({
  //   ref: eventRef,
  //   handler: onResetSelectedEvent,
  //   ignore: [headerRef, toolbarRef]
  // })

  const style = {
    top: `${top}px`,
    // We want to avoid events from visually colliding
    // so we're adding a very subtle safe space between events
    height: `${height - 2}px`,
    left,
    width,
    transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : undefined,
    cursor: isDragging ? 'grab' : 'pointer',
    opacity: isDragging ? 0.5 : 1
  }

  const hasAvatars = getTeamMember() ?? getCandidate()

  return (
    <S.Event
      ref={setNodeRef}
      data-event-id={event.id}
      style={style}
      onClick={onSelect}
      $isSelectable={Boolean(isPinEvent)}
      {...listeners}
      {...attributes}
    >
      <S.EventInner
        ref={eventRef}
        $isSelected={isSelected}
        $isDragging={isDragging}
        $isNewEvent={isNewEvent}
        $isSelectable={Boolean(isPinEvent)}
      >
        <When condition={!isNewEvent}>
          {!hasAvatars && isPinEvent && (
            <>
              <Flex $gap={4} $align="center">
                {event?.attendees?.map((attendee) =>
                  typeof attendee === 'object' && 'name' in attendee
                    ? (
                        <Avatar
                          key={attendee.name}
                          $size={16}
                          initials={attendee.name}
                          photoUrl=""
                          $shape="circle"
                        />
                      )
                    : (
                        <></>
                      )
                )}
              </Flex>
              <Spacer $size={8} />
            </>
          )}
          {hasAvatars && isPinEvent && (
            <>
              <Flex $gap={4} $align="center">
                {getTeamMember() && (
                  <Avatar
                    $size={16}
                    initials={getTeamMember()?.user?.name}
                    photoUrl={getTeamMember()?.user?.profilePhoto}
                    $shape="circle"
                  />
                )}
                {getCandidate() && (
                  <Avatar
                    $size={16}
                    initials={getCandidate()?.name}
                    photoUrl={getCandidate()?.profilePhotoUrl}
                    $shape="circle"
                  />
                )}
              </Flex>
              <Spacer $size={8} />
            </>
          )}
          <Caption size="2XS" $color={isPinEvent ? 'fgPrimary' : 'fgSecondary'} $ellipsis>
            {event.title}
          </Caption>
        </When>
      </S.EventInner>
    </S.Event>
  )
}
