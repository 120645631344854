import axios, { AxiosError } from 'axios'
import { isNil } from 'lodash'
import CONFIG from 'src/config'
import { z } from 'zod'

const ONE_SECOND = 1000

const axiosInstance = axios.create({
  baseURL: CONFIG.API_DOMAIN,
  timeout: 60 * ONE_SECOND,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

type DefaultData = Record<string | symbol, string | object | number | null | undefined>

export interface ApiResponse<T = DefaultData> {
  status: number
  data: T
}

const apiErrorResponseParser = z.object({
  error: z.string()
})

export class ApiError extends Error {
  axiosError?: AxiosError
  status: number = -1

  constructor (error: unknown) {
    super(error instanceof Error ? error.message : 'An unknown API error occurred')

    if (error instanceof AxiosError) {
      try {
        const { error: apiErrorMessage } = apiErrorResponseParser.parse(error.response?.data)
        this.message = apiErrorMessage
      } catch (e) {}
      this.axiosError = error
      this.status = error.response?.status ?? -1
    }

    const consoleErrorParts = [
      '[API_ERROR]'
    ]
    if (!isNil(this.axiosError)) {
      if (!isNil(this.axiosError?.config?.method)) {
        consoleErrorParts.push(`[${this.axiosError.config?.method?.toUpperCase()}]`)
      }
      if (!isNil(this.axiosError?.config?.url)) {
        consoleErrorParts.push(`[${this.axiosError.config?.url}]`)
      }
    }
    consoleErrorParts.push(`[${this.status}]`)
    console.warn(consoleErrorParts.join(' '), this.message)
  }
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class Api {
  public static async get<T = DefaultData>(
    route: string,
    params?: object | null
  ): Promise<ApiResponse<T>> {
    params ??= {}

    return await axiosInstance
      .get<T>(route, { params })
      .then(({ data, status }) => ({ data, status }))
      .catch((error) => {
        throw new ApiError(error)
      })
  }

  public static async post<T = DefaultData>(
    route: string,
    params: object | null | undefined = {},
    body: object | null | undefined = {}
  ): Promise<ApiResponse<T>> {
    params ??= {}
    body ??= {}

    return await axiosInstance
      .post<T>(route, body, { params })
      .then(({ data, status }) => ({ data, status }))
      .catch((error) => {
        throw new ApiError(error)
      })
  }

  public static async put<T = DefaultData>(
    route: string,
    params: object | null | undefined = {},
    body: object | null | undefined = {}
  ): Promise<ApiResponse<T>> {
    params ??= {}
    body ??= {}

    return await axiosInstance
      .put<T>(route, body, { params })
      .then(({ data, status }) => ({ data, status }))
      .catch((error) => {
        throw new ApiError(error)
      })
  }

  public static async patch<T = DefaultData>(
    route: string,
    params: object | null | undefined = {},
    body: object | null | undefined = {}
  ): Promise<ApiResponse<T>> {
    params ??= {}
    body ??= {}

    return await axiosInstance
      .patch<T>(route, body, { params })
      .then(({ data, status }) => ({ data, status }))
      .catch((error) => {
        throw new ApiError(error)
      })
  }

  public static async delete<T = DefaultData>(
    route: string,
    params: object | null | undefined = {}
  ): Promise<ApiResponse<T>> {
    params ??= {}

    return await axiosInstance
      .delete<T>(route, { params })
      .then(({ data, status }) => ({ data, status }))
      .catch((error) => {
        throw new ApiError(error)
      })
  }
}
