import { useEffect, useState } from 'react'
import { Icon } from 'src/components/primitives/icon'
import type { FieldCommonProps } from '../common'
import { FormElement } from '../form-element'
import * as S from './checkbox.styled'
import { FieldError } from '../field-error'

interface CheckboxProps extends FieldCommonProps {
  defaultChecked?: boolean
}

export const Checkbox = ({
  defaultChecked = false,
  label,
  name,
  register,
  $marginBottom
}: CheckboxProps): JSX.Element => {
  const [isDefaultChecked, setIsDefaultChecked] = useState(defaultChecked)
  const { value, onSelect, error } = register(name)
  useEffect(() => {
    setIsDefaultChecked(defaultChecked)
  }, [defaultChecked])

  return (
    <FormElement $marginBottom={$marginBottom}>
      <S.Checkbox>
        <S.CheckboxRoot
          id={name}
          defaultChecked={isDefaultChecked}
          checked={Boolean(value)}
          onCheckedChange={onSelect}
        >
          <S.CheckboxIndicator>
            <Icon name="check" color="tintBg" size={12} />
          </S.CheckboxIndicator>
        </S.CheckboxRoot>
        <S.Label htmlFor={name}>{label}</S.Label>
      </S.Checkbox>
      {error ? <FieldError id={`${name}-error`}>{error}</FieldError> : null}
    </FormElement>
  )
}
