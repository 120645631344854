import { useEffect, useState } from 'react'

export const useIsChrome = (): boolean => {
  const [isChrome, setIsChrome] = useState(true)
  useEffect(() => {
    setIsChrome(navigator.userAgent.includes('Chrome') && !navigator.userAgent.includes('Op'))
  }, [])
  return isChrome
}

export const useIsSafari = (): boolean => {
  const [isSafari, setIsSafari] = useState(false)
  useEffect(() => {
    setIsSafari(navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome'))
  }, [])
  return isSafari
}
