import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import * as S from './days.styled'
import {
  addMinutes,
  eachDayOfInterval,
  endOfWeek,
  format,
  isToday,
  startOfWeek,
  differenceInMinutes,
  setMinutes,
  setHours
} from 'date-fns'
import { isNil } from 'lodash'
import { mergeRefs } from 'src/utils/merge-refs'
import { Hour } from './hour'
import { DndContext, MouseSensor, useDroppable, useSensor, useSensors } from '@dnd-kit/core'
import { useGetCalendarEvents } from 'src/hooks/queries/use-calendar-events'
import { useUpdateCalendarEvent } from 'src/hooks/mutations/use-update-calendar-event'
import type { CalendarEvent, NewCalendarEvent } from 'src/libs/api/backend/calendar_events'
import { useSession } from 'src/hooks/queries/use-session'
import { useParams } from 'react-router-dom'
import { useCandidateJobQuery } from 'src/hooks/queries/use-candidate-job'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { Event } from './event'
import type { EmailAccount } from 'src/libs/api/backend/users'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'

interface DayProps {
  day: Date
  children: React.ReactNode
  onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void
}

const Day = ({ children, day, onMouseDown }: DayProps): JSX.Element => {
  const { setNodeRef } = useDroppable({ id: day.toDateString() })

  return (
    <S.Day ref={setNodeRef} data-day={day} onMouseDown={onMouseDown}>
      {children}
    </S.Day>
  )
}

const minutesPerDay = 24 * 60

interface DaysProps {
  renderedWeek: Date
  times: number[]
  daysHeight: number
  selectedEvent?: CalendarEvent | null
  selectedEmailAccount: EmailAccount | null
  newEvent?: NewCalendarEvent | null
  onSelectEvent?: (event: CalendarEvent) => void
  onResetSelectedEvent: () => void
  onSetNewEvent: (newEvent: NewCalendarEvent | null) => void
  headerRef: React.RefObject<HTMLDivElement>
  toolbarRef: React.RefObject<HTMLDivElement>
  candidateJob: CandidateJobExpanded
}

interface CalculateEventPositionReturnType {
  top: number
  height: number
}

export const Days = forwardRef<HTMLDivElement, DaysProps>(
  (
    {
      renderedWeek,
      times,
      daysHeight,
      newEvent,
      selectedEvent,
      selectedEmailAccount,
      onSelectEvent,
      onResetSelectedEvent,
      onSetNewEvent,
      headerRef,
      toolbarRef
    },
    forwardedRef
  ): JSX.Element => {
    const daysRef = useRef<HTMLDivElement>(null)
    const mergedRef = mergeRefs(daysRef, forwardedRef)

    const mouseSensor = useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10
        // delay: 100,
        // tolerance: 5
      }
    })

    const sensors = useSensors(mouseSensor)

    const daysOfWeek = eachDayOfInterval({
      start: startOfWeek(renderedWeek),
      end: endOfWeek(renderedWeek)
    })

    const { candidateJobId } = useParams()
    const { data: candidateJob } = useCandidateJobQuery({
      candidateJobId
    })
    const requestedCalendarEmail = selectedEmailAccount?.email
    const { data: calendarEvents } = useGetCalendarEvents({
      requestedCalendars: requestedCalendarEmail ? [requestedCalendarEmail] : undefined
    })
    const events = calendarEvents?.events
    const { updateEvent } = useUpdateCalendarEvent()
    const { data: sessionData } = useSession()
    const emailAccount = sessionData?.emailAccountAccessTokens?.[0]

    const { data: orgUsers } = useOrgUsersQuery()
    const selectedOrgUser = useMemo(() => {
      return orgUsers?.find(user => user.emailAccounts.some(account => account.id === selectedEmailAccount?.id))
    }, [orgUsers, selectedEmailAccount])

    const [initialMousePosition, setInitialMousePosition] = useState<number | null>(null)
    const [isCreatingEvent, setIsCreatingEvent] = useState<boolean>(false)

    const calculateEventPosition = (start: Date, end: Date): CalculateEventPositionReturnType => {
      const startMinutes = start.getHours() * 60 + start.getMinutes()
      const endMinutes = end.getHours() * 60 + end.getMinutes()
      const minuteHeight = daysHeight / minutesPerDay
      const top = startMinutes * minuteHeight
      const height = (endMinutes - startMinutes) * minuteHeight
      return { top, height }
    }

    const snapToInterval = (minutes: number, interval: number = 15): number => {
      return Math.round(minutes / interval) * interval
    }

    const handleDragStart = (event: any): void => {
      const { active } = event

      const draggedEvent = events?.find((e) => e.id === active.id)
      if (!draggedEvent?.id && isNil(newEvent)) {
        event.preventDefault()
        event.stopPropagation()
      }
      if (!draggedEvent && !isNil(newEvent)) {
        onSelectEvent?.(newEvent)
      }
      if (draggedEvent) {
        onSetNewEvent(null)
        onSelectEvent?.(draggedEvent)
      }
    }

    const handleDragEnd = (event: any): void => {
      const { active, over, delta } = event
      if (!over) return

      const draggedEvent = events?.find((e) => e.id === active.id)

      if (!draggedEvent && isNil(newEvent)) {
        return
      }

      const dayOfDrop = new Date(over.id as unknown as Date)
      const calculateNewStart = (dayOfDrop: Date, snappedStartMinutes: number): Date => {
        return setMinutes(
          setHours(dayOfDrop, Math.floor(snappedStartMinutes / 60)),
          snappedStartMinutes % 60
        )
      }

      if (!draggedEvent && !isNil(newEvent)) {
        const startMinutes = newEvent.startPosition + delta.y / (daysHeight / minutesPerDay)
        const snappedStartMinutes = snapToInterval(startMinutes)
        const newStart = calculateNewStart(dayOfDrop, snappedStartMinutes)
        const duration = newEvent.endPosition - newEvent.startPosition
        const newEnd = addMinutes(newStart, duration)

        const newStartPosition = snappedStartMinutes
        const newEndPosition = newStartPosition + duration

        const updatedEvent = {
          ...newEvent,
          start: newStart,
          end: newEnd,
          startPosition: newStartPosition,
          endPosition: newEndPosition
        }
        onSetNewEvent(updatedEvent)
        onSelectEvent?.(updatedEvent)
      }

      if (draggedEvent) {
        const startMinutes =
          draggedEvent.start.getHours() * 60 +
          draggedEvent.start.getMinutes() +
          delta.y / (daysHeight / minutesPerDay)
        const snappedStartMinutes = snapToInterval(startMinutes)
        const newStart = calculateNewStart(dayOfDrop, snappedStartMinutes)
        const duration = differenceInMinutes(draggedEvent.end, draggedEvent.start)
        const newEnd = addMinutes(newStart, duration)

        const updatedEvent = { ...draggedEvent, start: newStart, end: newEnd }
        updateEvent({ updatedEvent })
        onSelectEvent?.(updatedEvent)
      }
    }

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, day: Date): void => {
      // if (selectedEvent) {
      //   return
      // }
      setIsCreatingEvent(true)
      const rect = daysRef?.current?.getBoundingClientRect()
      if (!sessionData?.org || !candidateJob) {
        return
      }
      if (rect) {
        const topOffset = e.clientY - rect.top
        const minuteHeight = daysHeight / (24 * 60)
        const start = snapToInterval(Math.floor(topOffset / minuteHeight))
        const startTime = addMinutes(day, start)
        setInitialMousePosition(e.clientY)
        onSetNewEvent({
          id: crypto.randomUUID(),
          isPinEvent: true,
          // title: `${candidateJob.candidate.name} / ${sessionData?.user?.name} (${sessionData.org.name})`,
          title: `${candidateJob.candidate.name} / ${selectedOrgUser?.name} (${sessionData.org.name})`,
          startPosition: start,
          endPosition: start,
          start: startTime,
          end: startTime,
          candidateJobId: candidateJob?.id,
          attendees: [
            {
              // address: sessionData?.emailAccountAccessTokens?.[0]?.email,
              // name: sessionData?.user?.name
              address: selectedEmailAccount?.email ?? '',
              name: selectedOrgUser?.name ?? ''
            },
            {
              address: candidateJob.candidate.emails?.[0] ?? '',
              name: candidateJob?.candidate?.name
            }
          ]
        })
      }
    }

    const handleMouseMove = (e: MouseEvent): void => {
      if (!newEvent || !isCreatingEvent) return
      const rect = daysRef?.current?.getBoundingClientRect()
      if (rect) {
        const topOffset = e.clientY - rect.top
        const minuteHeight = daysHeight / (24 * 60)
        const end = snapToInterval(Math.floor(topOffset / minuteHeight))
        if (Math.abs(e.clientY - (initialMousePosition ?? 0)) >= minuteHeight * 15) {
          onSetNewEvent({ ...newEvent, endPosition: end })
        }
      }
    }

    const handleMouseUp = (): void => {
      setIsCreatingEvent(false)
      if (!newEvent) {
        onSetNewEvent(null)
        return
      }

      if (newEvent.startPosition === newEvent.endPosition) {
        newEvent.endPosition += 30
      }

      const startTime = addMinutes(
        newEvent.start,
        newEvent.startPosition - (newEvent.start.getHours() * 60 + newEvent.start.getMinutes())
      )
      const endTime = addMinutes(
        newEvent.start,
        newEvent.endPosition - (newEvent.start.getHours() * 60 + newEvent.start.getMinutes())
      )
      const newEventData: NewCalendarEvent = {
        ...newEvent,
        start: startTime,
        end: endTime
      }
      onSetNewEvent?.(newEventData)
      onSelectEvent?.(newEventData as unknown as CalendarEvent)
      setInitialMousePosition(null)
    }

    const areEventsOverlapping = (event1: CalendarEvent, event2: CalendarEvent): boolean => {
      return event1.start < event2.end && event1.end > event2.start
    }

    const calculateOverlappingEventsStyles = (
      event: CalendarEvent,
      overlappingEvents: CalendarEvent[]
    ): { width: string, left: string } => {
      let width = '100%'
      let left = '0'

      if (overlappingEvents.length > 1) {
        const index = overlappingEvents.findIndex((e) => e.id === event.id)
        width = '50%'
        left = `${index * 50}%`
      }
      return { width, left }
    }

    const getOverlappingEvents = (events: CalendarEvent[], day: Date): CalendarEvent[][] => {
      const dayEvents = events.filter(
        (e) => format(e.start, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')
      )
      const groups: CalendarEvent[][] = []

      dayEvents.forEach((event) => {
        const overlappingGroup = groups.find((group) =>
          group.some((e) => areEventsOverlapping(e, event))
        )
        if (overlappingGroup) {
          overlappingGroup.push(event)
        } else {
          groups.push([event])
        }
      })
      return groups
    }

    useEffect(() => {
      if (isCreatingEvent) {
        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)
      } else {
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
      }

      return () => {
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
      }
    }, [isCreatingEvent, newEvent])

    useEffect(() => {
      const handleKeydown = (event: KeyboardEvent): void => {
        if (event.key === 'Escape') {
          onSetNewEvent(null)
        }
      }
      if (!isNil(newEvent)) {
        document.addEventListener('keydown', handleKeydown)
      }
      return () => {
        document.removeEventListener('keydown', handleKeydown)
      }
    }, [newEvent])

    const hasNewEvent = (day: Date): boolean => {
      return (
        (newEvent &&
          format(newEvent.start, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd') &&
          newEvent.startPosition !== newEvent.endPosition) ??
        false
      )
    }

    return (
      <S.Wrapper>
        <S.Header>
          {daysOfWeek.map((day) => (
            <S.HeaderCell $isToday={isToday(day)}>
              <span>{format(day, 'EEE')}</span>
              <span>{format(day, 'd')}</span>
            </S.HeaderCell>
          ))}
        </S.Header>
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors}>
          <S.Days data-name="days" ref={mergedRef} $isDisabled={!emailAccount?.hasAccessToken}>
            {daysOfWeek.map((day, index) => {
              const overlappingGroups = getOverlappingEvents(events ?? [], day)
              return (
                <Day
                  key={index}
                  day={day}
                  onMouseDown={(e) => {
                    handleMouseDown(e, day)
                  }}
                >
                  <>
                    {times.map((time) => (
                      <Hour key={time} hour={time} />
                    ))}
                    {overlappingGroups.map((group) =>
                      group.map((e) => {
                        const { top, height } = calculateEventPosition(e.start, e.end)
                        return (
                          <Event
                            key={e.id ?? e.eventId}
                            isNewEvent={false}
                            event={e}
                            top={top}
                            height={height}
                            width={calculateOverlappingEventsStyles(e, group).width}
                            left={calculateOverlappingEventsStyles(e, group).left}
                            onSelect={() => {
                              onSelectEvent?.(e)
                            }}
                            onResetSelectedEvent={onResetSelectedEvent}
                            isSelected={!!(e.id && e.id === selectedEvent?.id)}
                            headerRef={headerRef}
                            toolbarRef={toolbarRef}
                            candidateJob={candidateJob}
                          />
                        )
                      })
                    )}

                    {hasNewEvent(day) && newEvent && (
                      <Event
                        isNewEvent
                        event={newEvent}
                        isSelected={false}
                        top={newEvent.startPosition * (daysHeight / minutesPerDay)}
                        height={
                          (newEvent.endPosition - newEvent.startPosition) *
                          (daysHeight / minutesPerDay)
                        }
                        onResetSelectedEvent={() => {}}
                        headerRef={headerRef}
                        toolbarRef={toolbarRef}
                        candidateJob={candidateJob}
                      />
                    )}
                  </>
                </Day>
              )
            })}
          </S.Days>
        </DndContext>
      </S.Wrapper>
    )
  }
)

Days.displayName = 'Days'
