import styled from 'styled-components'

export const JoinTeamCard = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
`

export const Inner = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  h3 {
    font-size: ${({ theme }) => theme.fontSizes[16]};
    line-height: ${({ theme }) => theme.lineHeights['1.2']};
    font-weight: ${({ theme }) => theme.fontWeights['500']};
  }
  p {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    line-height: ${({ theme }) => theme.lineHeights['1.33']};
    color: ${({ theme }) => theme.colors.fgSecondary};
`

export const Bottom = styled.div`
  padding: 0.75rem;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  display: flex;
  align-items: center;
  gap: 0.25rem;
  p {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    letter-spacing: ${({ theme }) => theme.letterSpacings['0.12']};
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
`
