import styled from 'styled-components'
import type { TabbarStyleProps } from './tabbar'

export const Tabbar = styled.div<TabbarStyleProps>`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem;
  border-radius: 6px;
  background-color: ${({ theme, $variant }) => {
    switch ($variant) {
      case 'selectTint': {
        return theme.colors.bgTertiary
      }
      case 'selectNormal': {
        return theme.colors.bgTertiary
      }
      case 'pillsTint':
      case 'pillsNormal':
      case 'line': {
        return theme.colors.bgPrimary
      }
      default: {
        return theme.colors.bgPrimary
      }
    }
  }};
`
