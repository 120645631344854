import styled from 'styled-components'

export const Trigger = styled.span`
  display: block;
  width: 100%;
`

export const Text = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  font-size: ${({ theme }) => theme.fontSizes[14]};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
`
