import { Outlet } from 'react-router-dom'
import * as S from './subscription-return-page.styled'
import { Logo } from 'src/components/primitives/logo'

export const SubscriptionReturnPage = (): JSX.Element => {
  return (
    <S.Layout>
      <S.Logo to="/">
        <Logo variant="dark" size={64} />
      </S.Logo>
      <Outlet />
    </S.Layout>
  )
}
