const Colors = {
  gray00: '#F0F0F0',
  gray10: '#939394',
  gray20: '#27272A',
  blue00: '#2563eb80',
  blue10: '#2563EB',
  white00: '#FFFFFF',
  white10: '#FCFCFD',
  black00: '#27272A'
}

export default Colors
