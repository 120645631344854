import { fetchOrgInvites } from 'src/libs/api/backend/invites'
import { queryKeys } from 'src/libs/query-keys'
import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import type { OrgInvites } from 'src/libs/api/backend/invites'

export const orgInvitesQuery = () => ({
  queryKey: [queryKeys.orgInvites],
  queryFn: async () => await fetchOrgInvites(),
  staleTime: 60 * 1000
})

export const useOrgInvitesQuery = (): UseQueryResult<OrgInvites> => {
  return useQuery(orgInvitesQuery())
}
