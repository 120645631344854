import { ACCOUNT_ONBOARDING_STEPS } from 'src/libs/data'
import RouteBuilder from 'src/libs/route-builder'

export interface OnboardingStep {
  position: number
  title: string
  image: string
}

export const getCurrentOnboardingStep = (pathname: string): OnboardingStep | undefined => {
  pathname = pathname.replace(/\/$/, '')
  const current = ACCOUNT_ONBOARDING_STEPS.find((step) => {
    return RouteBuilder.build(step.pathname).includes(pathname)
  })
  if (current) {
    return {
      position: current.id + 1,
      title: current.title,
      image: current.image
    }
  }
}
