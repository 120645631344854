import { useAtomValue, useSetAtom } from 'jotai'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { COLUMN } from 'src/components/tables/candidate-table-cells'
import { LocalStorageKey, ViewMode } from 'src/constants'
import { useAddCandidateToSequence } from 'src/hooks/mutations/use-add-candidate-to-sequence'
import { useUpdateJobSearchRefinement } from 'src/hooks/mutations/use-update-job-search-refinement'
import queryClient from 'src/hooks/query-client'
import { usePrintView } from 'src/hooks/use-print-view'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import type { Criteria } from 'src/libs/api/backend/candidate_search'
import { isSourcing } from 'src/libs/api/backend/jobs'
import type { JobSearchRefinement, JobSourcingState } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'
import { CompaniesPreferencesProvider } from 'src/providers/companies-preferences'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING } from 'src/styles/constants'
import { useDebounceCallback, useLocalStorage } from 'usehooks-ts'
import { SourcingPageHeader, SourcingStatus } from 'src/components/blocks/sourcing'
import { Logo } from 'src/components/primitives/logo'
import RouteBuilder from 'src/libs/route-builder'
import { isNil } from 'lodash'
import { RefinementActionsBar } from '../refinement-actions-bar'
import { IfElse } from '../if-else'
import { CandidateDetailsCard } from '../candidate-details-card'
import { CandidatesTablePagesContentInner } from 'src/pages/job/candidates/candidates.styled'
import { CandidatesSourcedTable } from 'src/components/tables/candidates-sourced-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { SEO } from 'src/components/primitives/seo'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { ToggleCandidateView } from '../toggle-candidate-view'
import { candidateDetailsChannelAtom, candidateSearchChannelAtom } from 'src/stores/websocket-channels'
import { useChannel } from 'ably/react'
import { EVENT_TYPE } from 'src/libs/api/backend/websockets'
import { CandidateActions } from '../candidate-actions'
import { When } from '../when'
import { useSuspenseQuery } from '@tanstack/react-query'
import { jobQuery } from 'src/hooks/queries/use-job'
import { listJobSearchRefinementsQuery } from 'src/hooks/queries/use-job-search-refinements'
import { candidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { jobSequenceQuery } from 'src/hooks/queries/use-job-sequence'

interface SourcingProps {
  isManualSourceView: boolean
  jobSearchRefinementId: string | null
  jobId: string
}

export const Sourcing = ({
  isManualSourceView,
  jobId,
  jobSearchRefinementId
}: SourcingProps): JSX.Element => {
  const { data: job } = useSuspenseQuery(jobQuery(jobId))
  const { data: jobSearchRefinements } = useSuspenseQuery(listJobSearchRefinementsQuery(jobId))
  const { data: candidateJobs } = useSuspenseQuery(candidateJobsQuery(
    jobId,
    {
      stage: CandidateJobStage.SOURCED,
      jobSearchRefinementId,
      source: isManualSourceView ? null : undefined
    }
  ))
  const { data: sequence } = useSuspenseQuery(jobSequenceQuery(jobId))

  const jobSearchRefinement = useMemo(() => {
    return jobSearchRefinements.find((refinement) => refinement.id === jobSearchRefinementId)
  }, [jobSearchRefinements, jobSearchRefinementId])

  const openDialog = useSetAtom(openDialogAtom)
  const isPrintView = usePrintView()
  const { updateJobSearchRefinement } = useUpdateJobSearchRefinement()
  const { addCandidateToSequence } = useAddCandidateToSequence()
  const [currViewMode] = useLocalStorage(LocalStorageKey.VIEW_MODE, ViewMode.DEFAULT)

  const [renderedCandidates, setRenderedCandidates] = useState<CandidateJobExpanded[]>([])
  const parentRef = useRef<HTMLDivElement>(null)

  const [lastRequestedAt, setLastRequestedAt] = useState<Date | null>(null)
  const [isRecommending, setIsRecommending] = useState(false)

  const virtualizer = useVirtualizer({
    count: renderedCandidates.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 300,
    overscan: 5
  })

  useEffect(() => {
    setRenderedCandidates([])
    virtualizer.scrollToOffset(-100000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobSearchRefinementId])

  useEffect(() => {
    setIsRecommending(isSourcing(jobSearchRefinement?.sourcingState))
  }, [jobSearchRefinement?.lastSourcingRequestedAt, jobSearchRefinement?.sourcingState])

  const autoApproveIsEnabled = useMemo(() => {
    return jobSearchRefinement?.automateAutoApproveCandidates ?? false
  }, [jobSearchRefinement?.automateAutoApproveCandidates])

  const invalidateCandidateJobs = useCallback(() => {
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.job, job.id]
    })
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.candidateJobs, job.id]
    })
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.candidateJobCounts, job.id]
    })
  }, [job.id])

  const debouncedInvalidateCandidateJobs = useDebounceCallback(invalidateCandidateJobs, 2000)

  const updateCachedJobSearchRefinement = useCallback((data: Partial<JobSearchRefinement>) => {
    void queryClient.setQueriesData<JobSearchRefinement[]>({
      queryKey: [queryKeys.jobSearchRefinements, job.id]
    }, (oldJobSearchRefinements) => {
      if (!oldJobSearchRefinements) {
        return oldJobSearchRefinements
      }
      // Update the title and subtitle for the job search refinement
      return oldJobSearchRefinements.map((refinement) => {
        if (refinement.id === jobSearchRefinementId) {
          return {
            ...refinement,
            ...data
          }
        }
        return refinement
      })
    })
  }, [job.id, jobSearchRefinementId])

  const candidateSearchChannel = useAtomValue(candidateSearchChannelAtom)
  useChannel({ channelName: candidateSearchChannel, skip: !candidateSearchChannel }, (message) => {
    const { name: eventName } = message
    if (eventName === jobSearchRefinementId) {
      const {
        generatedTitle,
        generatedSubtitle,
        sourcingState,
        candidateJobId
      } = message.data ?? {}
      if (generatedTitle || generatedSubtitle) {
        updateCachedJobSearchRefinement({
          title: generatedTitle,
          subtitle: generatedSubtitle
        })
      } else {
        if (sourcingState) {
          const isSourcingState = isSourcing(sourcingState as JobSourcingState)
          setIsRecommending(isSourcingState)
          if (isSourcingState) {
            if (renderedCandidates.length > 0) {
              setRenderedCandidates([])
            }
          } else {
            invalidateCandidateJobs()
          }
          updateCachedJobSearchRefinement({
            sourcingState
          })
        } else if (candidateJobId) {
          debouncedInvalidateCandidateJobs()
        }
      }
    }
  })

  const candidateDetailsChannel = useAtomValue(candidateDetailsChannelAtom)
  useChannel({ channelName: candidateDetailsChannel, skip: !candidateDetailsChannel }, EVENT_TYPE.CANDIDATES_UPDATE, (message) => {
    const idsSet = new Set((message.data.ids as string[]) ?? [])
    renderedCandidates.forEach((c) => {
      if (idsSet.has(c.candidateId)) {
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateActivities, c.candidateId]
        })
      }
    })
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.candidateJobs, job.id, {
        stage: CandidateJobStage.SOURCED,
        jobSearchRefinementId,
        source: null
      }]
    })
  })

  useEffect(() => {
    if (candidateJobs) {
      const sourcedCandidates = candidateJobs?.filter((c) => c.stage === 'SOURCED')
      setRenderedCandidates(sourcedCandidates)
    }
  }, [candidateJobs])

  const maxWidth = CANDIDATES_PAGES_MAX_WIDTH

  // TODO: This is a temporary fix to prevent jitter when opening up an
  // experience item. Ideally, this should be implemented when the item is
  // opened then reset, rather than just displaying it entirely here.
  virtualizer.shouldAdjustScrollPositionOnItemSizeChange = () => false

  const pageTitle = useMemo(() => {
    if (isManualSourceView) {
      return 'Manually Added'
    }

    if (jobSearchRefinement?.title) {
      return `Sourcing: "${jobSearchRefinement.title}"`
    }

    return 'Sourcing'
  }, [isManualSourceView, jobSearchRefinement?.title])

  const isEmpty = useMemo((): boolean => renderedCandidates.length === 0, [renderedCandidates])

  const isSourcingView = useMemo(
    () => !!jobSearchRefinement,
    [jobSearchRefinement]
  )

  const handleUpdateSearchRefinementTitle = (updatedTitle: string): void => {
    if (jobSearchRefinementId) {
      updateJobSearchRefinement({
        jobId: job.id,
        jobSearchRefinementId,
        title: updatedTitle,
        automateAutoApproveCandidates: autoApproveIsEnabled
      })
    }
  }

  const isArchived = useMemo((): boolean => Boolean(job.deleted), [job.deleted])

  const title = useMemo(() => {
    if (isManualSourceView) {
      return 'Sourcing · Manually Added'
    }
    if (jobSearchRefinement) {
      return jobSearchRefinement.title ?? 'Sourcing · New Search'
    }
    return 'Sourcing · All sourced'
  }, [isManualSourceView, jobSearchRefinement])

  const updateJobRefinementOnClick = useCallback((searchCriteria: Criteria) => {
    if (jobSearchRefinementId) {
      setIsRecommending(true)
      setLastRequestedAt(null) // Set this to current Date to avoid using old lastRequestedAt
      updateJobSearchRefinement({
        jobId: job.id,
        jobSearchRefinementId,
        automateAutoApproveCandidates: autoApproveIsEnabled,
        searchCriteria,
        onSuccess: (data) => {
          void queryClient.invalidateQueries({
            queryKey: [
              queryKeys.candidateJobs,
              job.id,
              {
                stage: CandidateJobStage.SOURCED,
                source: null,
                jobSearchRefinementId
              }
            ]
          })
          setRenderedCandidates([])
          setLastRequestedAt(data.lastSourcingRequestedAt)
        }
      })
    }
  }, [
    setIsRecommending,
    updateJobSearchRefinement,
    job.id,
    jobSearchRefinementId,
    autoApproveIsEnabled,
    setRenderedCandidates
  ])

  const visibleColumns: COLUMN[] = useMemo(() => {
    const columns: COLUMN[] = [COLUMN.FAVORITE, COLUMN.NAME, COLUMN.JOB_TITLE]
    if (!isManualSourceView) {
      columns.push(COLUMN.CRITERIA, COLUMN.CRITERIA_EXPANDED)
    }
    columns.push(COLUMN.CANDIDATE_STAGE_ACTIONS)
    return columns
  }, [isManualSourceView])

  return (
    <>
      <SEO title={pageTitle} />
      <CompaniesPreferencesProvider key={`${job.id}-${jobSearchRefinementId}`}>
        <div
          ref={parentRef}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'relative',
            overflow: 'auto',
            ...(!isPrintView
              ? {
                  contain: 'strict',
                  padding: `0 ${CONTENT_PADDING}`,
                  scrollBehavior: 'smooth'
                }
              : {}
            )
          }}
        >
          {isPrintView && <Logo variant="dark" size={64} />}
          <SourcingPageHeader
            headingLoading={jobSearchRefinement && !jobSearchRefinement.title}
            showActions={!isArchived && !jobSearchRefinement}
            title={title}
            isEditable={(jobSearchRefinement?.title?.length ?? 0) >= 1}
            onEdit={(updatedTitle) => {
              handleUpdateSearchRefinementTitle(updatedTitle)
            }}
            jobId={job.id}
            jobSearchRefinement={jobSearchRefinement}
            candidateJobIds={renderedCandidates.map((c) => c.id) ?? []}
            isSequenceEmpty={isSequenceStepsEmpty(sequence)}
            customActions={isManualSourceView && !job.deleted ? [<ToggleCandidateView />] : []}
            printUrl={
              isManualSourceView
                ? RouteBuilder.build('JOBS_CANDIDATES_SOURCING_MANUAL', { jobId: job.id }, { print: true })
                : jobSearchRefinement
                  ? RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId: job.id, jobSearchRefinementId: jobSearchRefinement.id }, { print: true })
                  : undefined
              }
            hasCandidateJobs={renderedCandidates.length > 0}
          />
          {!isNil(job) && !isNil(jobSearchRefinement) && !job.deleted && (
            <RefinementActionsBar
              jobSearchRefinement={jobSearchRefinement}
              autoApproveIsEnabled={autoApproveIsEnabled}
              setIsRecommending={setIsRecommending}
              isRecommending={isRecommending}
              maxWidth={maxWidth}
              onSubmit={updateJobRefinementOnClick}
            />
          )}
          <IfElse
            condition={isPrintView}
            ifNode={
              <>
                {renderedCandidates.map((candidateJob, index) => (
                  <div
                    key={candidateJob.id}
                    style={{
                      width: '100%',
                      maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
                      breakBefore: index === 0 ? 'avoid-page' : 'page',
                      breakInside: 'avoid',
                      breakAfter: 'avoid-page'
                    }}
                  >
                    <CandidateDetailsCard
                      isPrintView={isPrintView}
                      stage={candidateJob.stage}
                      candidate={candidateJob.candidate}
                      candidateJob={candidateJob}
                      viewMode={ViewMode.DEFAULT}
                    />
                  </div>
                ))}
              </>
            }
            elseNode={
              <IfElse
                condition={currViewMode === ViewMode.TABLE}
                ifNode={
                  renderedCandidates.length > 0
                    ? <CandidatesTablePagesContentInner
                        key={`${job.id}-${jobSearchRefinementId}`}
                        data-component="CandidatesTablePagesContentInner"
                        $padding={0}
                        $maxWidth='auto'
                      >
                        <CandidatesSourcedTable
                          candidateJobs={renderedCandidates}
                          visibleColumns={visibleColumns}
                          pageHeaderHeight={jobSearchRefinement?.subtitle ? 132 : (jobSearchRefinement ? 116 : 64)}
                        />
                      </CandidatesTablePagesContentInner>
                    : null
                }
                elseNode={
                  <div
                    style={{
                      minHeight: virtualizer.getTotalSize(),
                      position: 'relative'
                    }}
                  >
                    {virtualizer.getVirtualItems().map((virtualRow) => {
                      const candidateJob = renderedCandidates[virtualRow.index]
                      if (isNil(candidateJob)) {
                        return <Fragment key={virtualRow.key} />
                      }
                      return (
                        <div
                          style={{
                            width: '100%',
                            maxWidth,
                            position: 'absolute',
                            top: virtualRow?.start ?? 0
                          }}
                          key={virtualRow.key}
                          data-index={virtualRow.index}
                          ref={virtualizer.measureElement}
                        >
                          <CandidateDetailsCard
                            key={candidateJob.id}
                            stage={candidateJob.stage}
                            candidate={candidateJob.candidate}
                            candidateJob={candidateJob}
                            actions={
                              <CandidateActions
                                candidateJob={candidateJob}
                                onAddToSequenceClick={() => {
                                  if (isSequenceStepsEmpty(sequence)) {
                                    openDialog({ id: DialogId.CREATE_SEQUENCE })
                                  } else {
                                    addCandidateToSequence([candidateJob.id])
                                  }
                                }}
                              />
                            }
                            viewMode={currViewMode}
                          />
                        </div>
                      )
                    })}
                  </div>
                }
              />
            }
          />
          {/*
          <UpgradeCtaBanner
            heading="10k other candidates found"
            benefits={[
              'Unlock this premium feature for unlimited results',
              'Auto-outreach to new candidates daily',
              'Get more meetings with high-quality candidates'
            ]}
            addOn="AI Sourcing"
            addOnDescription="Add AI sourcing to your open job positions."
            addOnPrice={99}
          />
          */}
          <When condition={!isPrintView && (!!jobSearchRefinement || isManualSourceView)}>
            <SourcingStatus
              isRecommending={isRecommending}
              isEmpty={isEmpty}
              lastRequestedAt={lastRequestedAt ?? new Date()}
              isSourcingView={isSourcingView}
              isManualSourceView={isManualSourceView}
              sourcingState={jobSearchRefinement?.sourcingState}
              isArchived={isArchived}
              viewMode={currViewMode}
            />
          </When>
        </div>
      </CompaniesPreferencesProvider>
    </>
  )
}
