import * as S from './hour.styled'

interface HourProps {
  hour: number
  children?: React.ReactNode
}

export const Hour = ({ hour, children }: HourProps): JSX.Element => {
  return <S.Hour data-hour={hour}>{children}</S.Hour>
}
