import { useState, useEffect, useRef } from 'react'

const THRESHOLD = 0

type ScrollDirection = 'up' | 'down' | null

interface UseDetectScrollReturnType {
  scrollDirection: ScrollDirection
  scrollPosition: number
  isScrolling: boolean
  scrolledPages: number
  hasScrolled: boolean
}

export const useDetectScroll = (
  scrollContainerRef: React.RefObject<HTMLElement>
): UseDetectScrollReturnType => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(null)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isScrolling, setIsScrolling] = useState(false)
  const [scrolledPages, setScrolledPages] = useState(0)
  const [hasScrolled, setHasScrolled] = useState(false)

  const blocking = useRef(false)
  const prevScrollY = useRef(0)
  const scrollTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current
    if (!scrollContainer) return

    prevScrollY.current = scrollContainer.scrollTop

    const updateScrollDirection = (): void => {
      const scrollY = scrollContainer.scrollTop
      setScrollPosition(scrollY)

      if (Math.abs(scrollY - prevScrollY.current) >= THRESHOLD) {
        const newScrollDirection = scrollY > prevScrollY.current ? 'down' : 'up'

        setScrollDirection(newScrollDirection)
        setIsScrolling(true)

        if (scrollTimeout.current) {
          clearTimeout(scrollTimeout.current)
        }

        scrollTimeout.current = setTimeout(() => {
          setIsScrolling(false)
        }, 150)

        prevScrollY.current = scrollY > 0 ? scrollY : 0

        const containerHeight = scrollContainer.clientHeight
        const multiples = Math.floor(scrollY / containerHeight)
        setScrolledPages(multiples)
      }

      blocking.current = false
    }

    const onScroll = (): void => {
      setHasScrolled(true)
      if (!blocking.current) {
        blocking.current = true
        window.requestAnimationFrame(updateScrollDirection)
      }
    }

    scrollContainer.addEventListener('scroll', onScroll)

    return () => {
      scrollContainer.removeEventListener('scroll', onScroll)
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current)
      }
    }
  }, [scrollDirection, scrollContainerRef])

  return {
    scrollDirection,
    scrollPosition,
    isScrolling,
    scrolledPages,
    hasScrolled
  }
}
