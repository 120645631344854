import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import * as S from './banner.styled'
import type { Color, Spacing } from 'src/styles/theme/types'
import { isNil } from 'lodash'
import { Flex } from 'src/components/primitives/flex'

type Variant = 'neutral' | 'muted' | 'positive' | 'tint' | 'negative' | 'warning' | 'ghost'

export interface BannerStyleProps {
  $variant?: Variant
  $padding?: { top?: Spacing, bottom?: Spacing, left?: Spacing, right?: Spacing }
  $borderRadius?: number
}

interface BannerProps extends BannerStyleProps {
  icon?: IconName | React.ReactNode
  children: React.ReactNode | string
  actions?: React.ReactNode
}

export const Banner = ({
  $variant = 'neutral',
  icon,
  children,
  actions,
  $padding = { top: 8, bottom: 8, left: 16, right: 8 },
  $borderRadius = 6
}: BannerProps): JSX.Element => {
  const getIconColor = ($variant: Variant): Color => {
    if ($variant === 'ghost') {
      return 'fgPrimary'
    }
    switch ($variant) {
      case 'neutral': {
        return 'fgPrimary'
      }
      case 'muted': {
        return 'fgSecondary'
      }
      case 'positive': {
        return 'positiveBg'
      }
      case 'tint': {
        return 'tintBg'
      }
      case 'negative': {
        return 'negativeBg'
      }
      case 'warning': {
        return 'warningFg'
      }
    }
  }

  const isIcon = (icon: IconName | React.ReactNode): icon is IconName => {
    return typeof icon === 'string'
  }

  return (
    <S.Banner $variant={$variant} $padding={$padding} $borderRadius={$borderRadius}>
      <Flex $gap={16} $align="center">
        {!isNil(icon) && isIcon(icon) ? <Icon name={icon} color={getIconColor($variant)} size={14} /> : icon}
        {typeof children === 'string' ? <p>{children}</p> : children}
      </Flex>
      {actions && (
        <Flex $gap={8} $align="center" $justify="flex-end" $width="auto">
          {actions}
        </Flex>
      )}
    </S.Banner>
  )
}
