import { sendMessageToExtension, storeUserId } from 'src/libs/extension'
import type { ResponseSchemas } from 'src/libs/extension'
import Api from '..'

export interface LinkedInAccount {
  id: string
  unipileId: string
  orgId: string
  userId: string
}

export interface GetLinkedInAccountsResponse {
  linkedInAccounts: LinkedInAccount[]
}

export async function getLinkedInAccounts (): Promise<GetLinkedInAccountsResponse> {
  const { data } = await Api.get<GetLinkedInAccountsResponse>('/me/linkedin_accounts')
  return data
}

export async function createLinkedInAccount (): Promise<LinkedInAccount> {
  const { response: { accessToken, profileData } } = await sendMessageToExtension('RPC_EVENT_FETCH_LINKEDIN_ACCESS_TOKEN_AND_PROFILE_DATA')

  if (accessToken.liAt === null) {
    throw new Error('No access token found. Make sure the user is logged in to LinkedIn and has granted our extension the necessary permissions.')
  }
  const userAgent = window.navigator.userAgent
  const { data } = await Api.post<{ linkedInAccount: LinkedInAccount }>('/me/linkedin_accounts', null, {
    data: {
      accessToken: accessToken.liAt,
      premiumToken: accessToken.liA,
      userAgent,
      profileData
    }
  })
  // tell the extension to store the user id so that it can be used in the background script that sends fresh access tokens to the API
  const { success } = await storeUserId(data.linkedInAccount.userId)
  if (!success) {
    console.error('[createLinkedInAccount] Failed to store user id')
  }
  return data.linkedInAccount
}

export async function deleteLinkedInAccount (): Promise<{ success: boolean }> {
  const { status } = await Api.delete('/me/linkedin_accounts')
  return { success: status === 200 }
}

export async function fetchLinkedInAccessTokenAndProfileData (): Promise<ResponseSchemas<'RPC_EVENT_FETCH_LINKEDIN_ACCESS_TOKEN_AND_PROFILE_DATA'>> {
  const { response } = await sendMessageToExtension('RPC_EVENT_FETCH_LINKEDIN_ACCESS_TOKEN_AND_PROFILE_DATA')
  return response
}
