import { CommonFieldStyles } from 'src/components/forms/common'
import styled from 'styled-components'

export const CurrentEmailCard = styled.div`
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 6px;
`

export const EmailCardHeader = styled.header`
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CardGroup = styled.div`
  padding: 0.65rem 0.65rem 0.65rem 2.75rem;
  &:not(:last-child) {
    border-bottom: solid 1px;
    border-color: ${({ theme }) => theme.colors.bgTertiary};
  }
`

export const JobsListTrigger = styled.button`
  all: unset;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
`

export const ReplaceForm = styled.div``

export const DummyInput = styled(CommonFieldStyles)`
  background-color: ${({ theme }) => theme.colors.bgSecondary};
`

export const ArrowIcon = styled.div`
  width: 1rem;
  height: 100%;
  display: flex;
  flex: 0 0 1rem;
  align-items: center;
  justify-content: center;
`

export const JobsList = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`
