import { isNil } from 'lodash'

export function cleanLinkedInUrl (linkedinUrl: string | null | undefined): string | null {
  if (isNil(linkedinUrl)) {
    return null
  }

  const m = linkedinUrl.match(/(?:https?:\/\/.*\.?linkedin\.com)?\/(company|in|edu|school)\/([^/]+)/)
  if (isNil(m)) {
    return null
  }

  return `/${m[1]}/${m[2]}`.toLowerCase().trim()
}
