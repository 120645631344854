import { CONTENT_PADDING, INBOX_MAX_WIDTH } from 'src/styles/constants'
import styled from 'styled-components'

export const Action = styled.div`
  width: 100%;
  padding: ${CONTENT_PADDING} 0;
  padding-right: 0;
  padding-top: 0;
  max-width: ${INBOX_MAX_WIDTH};
`

export const Content = styled.div`
`

export const Indicator = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
