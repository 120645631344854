import { isNil } from 'lodash'
import styled from 'styled-components'

export const Attachments = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing[8]};
  padding: 4px 12px 12px 12px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
`

export const Attachment = styled.div<{ $error?: boolean, $progress?: number }>`
  position: relative;
  display: flex;
  gap: ${({ theme }) => theme.spacing[4]};
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing[16]};
  ${({ theme, $progress, $error }) =>
    !isNil($progress)
    ? `
        background-color: ${theme.colors.staticTransparent};
        border: 1px solid ${theme.colors.fgTranslucent10};
      `
    : `
        background-color: ${$error ? theme.colors.negativeTranslucent5 : theme.colors.fgTranslucent5};
      `
  }
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.fgTranslucent10};
  }
`

export const Progress = styled.div<{ $progress: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ $progress }) => $progress}%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.tintTranslucent5};
  border-radius: ${({ theme }) => `${theme.spacing[16]} 0 0 ${theme.spacing[16]}`};
`
