import { Flex } from 'src/components/primitives'
import * as S from './candidates.styled'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { isNil } from 'lodash'
import { ArrowRightSvg } from 'src/components/primitives/svgs'

export const DefaultTableHeader = ({ title, $justify = 'center' }: { title: string, $justify?: 'center' | 'flex-start' }): JSX.Element => (
  <S.ReportTabularHeader $justify={$justify}>
    {title}
  </S.ReportTabularHeader>
)

export const TableReportNumberCell = ({ value, percentage }: { value: number, percentage?: number }): JSX.Element => {
  return (
    <Flex $gap={4} $align="center" $justify='center'>
      <Caption size="XS" $color="fgSecondary" $fontWeight={400}>
        {value}
      </Caption>
      {!isNil(percentage) && (
        <Paragraph size="XS" $color="fgTertiary">
          ({Math.round(percentage * 100)}%)
        </Paragraph>
      )}
    </Flex>
  )
}

// TxtWithArrow
//  pct should be between 0 and 1
interface TxtWithArrowProps {
  txt: string
  theme: 'positive' | 'warning' | 'neutral'
}
export const TxtWithArrow = ({ txt, theme }: TxtWithArrowProps): JSX.Element => {
  const themes = {
    positive: ['positiveFaded', 'positiveFg'],
    warning: ['warningFaded', 'warningFg'],
    neutral: ['bgTertiary', 'fgTertiary']
  }
  const [bg, fg] = themes[theme]
  return (
    <Flex
      $gap={40} $justify="center">
      <S.Relative>
        <S.AbsoluteAndCentered>
          <ArrowRightSvg width={88} />
        </S.AbsoluteAndCentered>
        <S.ChangeBadge $bgThemeColor={bg}>
          <Caption size="2XS" $color={fg} $whiteSpace="nowrap">
            {txt}
          </Caption>
        </S.ChangeBadge>
      </S.Relative>
    </Flex>
  )
}

export const TableReportTextCell = ({ value }: { value: string }): JSX.Element => {
  return (
    <Caption size="XS" $fontWeight={400}>
      {value}
    </Caption>
  )
}
