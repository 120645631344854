import type { ReactElement } from 'react'
import styled from 'styled-components'
import Colors from 'src/styles/colors'
import type { Spacing } from 'src/styles/theme/types'

interface VisualDividerProps {
  children?: ReactElement | ReactElement[] | string | undefined
  $fill?: boolean
  $spacingTop?: Spacing
  $spacingBottom?: Spacing
}

export const VisualDivider = ({
  children,
  $fill = false,
  $spacingTop = 0,
  $spacingBottom = 0
}: VisualDividerProps): JSX.Element => {
  return (
    <StyledDiv $fill={$fill} $spacingTop={$spacingTop} $spacingBottom={$spacingBottom}>
      <StyledSpan />
      {children}
      <StyledSpan />
    </StyledDiv>
  )
}

const StyledDiv = styled.div<{
  $fill: boolean
  $spacingTop?: Spacing
  $spacingBottom?: Spacing
}>`
  width: ${({ $fill }) => ($fill ? '100%' : 'auto')};

  font-size: 0.75rem;
  letter-spacing: 1px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  margin-top: ${({ $spacingTop, theme }) => theme.spacing[$spacingTop ?? 0]};
  margin-bottom: ${({ $spacingBottom, theme }) => theme.spacing[$spacingBottom ?? 0]};
  text-transform: uppercase;
  color: ${Colors.gray10};
`

const StyledSpan = styled.span`
  display: block;
  height: 1px;
  width: 100%;
  background-color: ${Colors.gray00};
`
