import styled from 'styled-components'
import { SIZES } from './constants'

export const Calendar = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
`

export const CurrentTimeIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.negativeBg};
  pointer-events: none;
  user-select: none;
  z-index: 3;
`

export const Inner = styled.div`
  position: relative;
  display: flex;
  top: ${SIZES.HEADER.LG}px;
  width: 100%;
  height: calc(100% - ${SIZES.HEADER.LG}px - ${SIZES.FOOTER.LG}px);
  overflow-y: scroll;
`
