import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { fetchPricing } from 'src/libs/api/backend/billing'
import type { BillingProduct } from 'src/libs/api/backend/billing'
import { queryKeys } from 'src/libs/query-keys'

export const useBillingPricingQuery = (): UseQueryResult<BillingProduct[]> => {
  return useQuery({
    queryKey: [queryKeys.billingPricing],
    queryFn: async () => await fetchPricing()
  })
}
