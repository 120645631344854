import { Button } from 'src/components/primitives/button'
import { usePinExtensionVersion } from 'src/hooks/use-pin-extension'
import { isNil } from 'lodash'
import RouteBuilder from 'src/libs/route-builder'
import { closeDialogAtom, DialogId } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import * as S from './connected-linkedin-account.styled'
import { useMeLinkedInAccounts } from 'src/hooks/use-me-linked-in-accounts'
import { When } from '../when'
import { ConnectedAccountCard } from './connected-account-card'
import type { LinkedInAccount } from 'src/libs/api/backend/users'

export const ConnectedLinkedInAccount = (): JSX.Element => {
  const extensionVersion = usePinExtensionVersion()
  const closeDialog = useSetAtom(closeDialogAtom)

  const { linkedInAccounts } = useMeLinkedInAccounts()

  return (
    <>
      <When condition={linkedInAccounts.length === 0}>
        <S.Wrapper>
          <Button
            $variant="raised"
            $colorTheme="normal"
            $align="center"
            leadingIcon={isNil(extensionVersion) ? 'chrome' : 'linkedinOriginal'}
            $width="full"
            $height={40}
            href={RouteBuilder.build(
              isNil(extensionVersion)
                ? 'INTEGRATIONS_LINKEDIN_INSTALL_CHROME_EXTENSION'
                : 'INTEGRATIONS_LINKEDIN_CONNECT'
            )}
            onClick={() => {
              closeDialog(DialogId.DEFAULT_SETTINGS)
            }}
          >
            {isNil(extensionVersion) ? 'Install Chrome Extension' : 'Connect LinkedIn Account'}
          </Button>
        </S.Wrapper>
      </When>
      <When condition={linkedInAccounts.length > 0}>
        {linkedInAccounts.map((account) => (
          <ConnectedAccountCard
            key={account.id}
            account={account as unknown as LinkedInAccount}
          />
        ))}
      </When>
    </>
  )
}
