import { useQuery } from '@tanstack/react-query'
import { fetchUserNotificationPreferences } from 'src/libs/api/backend/notifications'
import { queryKeys } from 'src/libs/query-keys'

export const userNotificationPreferences = () => ({
  queryKey: [queryKeys.notificationsPreferences],
  queryFn: async () => await fetchUserNotificationPreferences(),
  staleTime: 60 * 1000
})

export const useUserNotificationPreferencesQuery = () => {
  return useQuery(userNotificationPreferences())
}
