import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { getMe } from 'src/libs/api/backend/me'
import type { User } from 'src/libs/api/backend/me'
import { queryKeys } from 'src/libs/query-keys'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const meQuery = () => ({
  queryKey: [queryKeys.me],
  queryFn: async () => await getMe(),
  retry: false
})

export const useMe = (): UseQueryResult<User> => {
  return useQuery(meQuery())
}
