import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import axios from 'axios'
import { z } from 'zod'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  name?: string
  url?: string | null
}

interface Response {
  success: boolean
  companyName: string
  companyUrl: string
  logoUrl: string
  alt: string
}

class LogoNotFoundError extends Error {
  constructor (message: string) {
    super(message)
    this.name = 'LogoNotFoundError'
  }
}

class LogoFetchError extends Error {
  constructor (message: string) {
    super(message)
    this.name = 'LogoFetchError'
  }
}

const clearBitSchema = z.object({
  name: z.string(),
  domain: z.string(),
  logo: z.string()
}).array()

const fetchLogo = async ({ name, url }: Args): Promise<Response> => {
  if (!name) {
    throw new LogoNotFoundError('Missing company name')
  }

  if (url) {
    return {
      success: true,
      companyName: name,
      companyUrl: url,
      logoUrl: `https://logo.clearbit.com/${url}`,
      alt: `Logo of company ${name}`
    }
  }

  const companyName = name.toLowerCase()
  const response = await axios.get(
    `https://autocomplete.clearbit.com/v1/companies/suggest?query=${companyName}`
  )

  if (response.status !== 200) {
    throw new LogoFetchError(`Failed to fetch logo for company ${companyName}`)
  }

  const logos = clearBitSchema.parse(response.data)

  for (const { name, domain, logo } of logos) {
    if (name.toLocaleLowerCase() === companyName) {
      return {
        success: true,
        companyName,
        companyUrl: domain,
        logoUrl: logo,
        alt: `Logo of company ${companyName}`
      }
    }
  }

  throw new LogoNotFoundError(`Logo not found for company ${companyName}`)
}

export const useCompanyLogoQuery = ({ name, url }: Args): UseQueryResult<Response> => {
  return useQuery({
    queryKey: [queryKeys.companyLogo, name, url],
    queryFn: async () => await fetchLogo({ name, url }),
    staleTime: 1 * 60 * 60 * 60 * 1000, // 1 day
    enabled: !!name,
    retry: false,
    throwOnError: false
  })
}
