import { queryKeys } from 'src/libs/query-keys'
import queryClient from './query-client'

export const invalidateEmailAccounts = async () => {
  await queryClient.invalidateQueries({
    queryKey: [queryKeys.session]
  })
  await queryClient.invalidateQueries({
    queryKey: [queryKeys.orgUsers]
  })
  await queryClient.invalidateQueries({
    queryKey: [queryKeys.jobStats]
  })
}
