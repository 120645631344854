import { useCallback, useEffect, useRef, useState } from 'react'
import type { CompanySuggestion } from 'src/libs/api/backend/company_preferences'
import { useOnClickOutside } from 'usehooks-ts'
import * as S from './index.styled'
import { Flex } from 'src/components/primitives'
import { Avatar } from 'src/components/primitives/avatar'
import { Caption } from 'src/components/primitives/typography'
import type { LocationSuggestion, SchoolSuggestion } from 'src/libs/api/backend/typeaheads'
import { titleCase } from 'src/libs/string'
import * as RadixPopover from '@radix-ui/react-popover'
import { ComboboxProvider } from '@ariakit/react'
import { usePrintView } from 'src/hooks/use-print-view'
import { getSubtitle } from 'src/utils/refinement-criteria'

interface SuggestionInputProps {
  triggerSearch: (query: string | undefined) => void
  suggestions: Array<SchoolSuggestion | CompanySuggestion | LocationSuggestion | { name: string }>
  onSelect: (suggestion: SchoolSuggestion | CompanySuggestion | LocationSuggestion) => void
  isLoading: boolean
  items: React.ReactNode
  disabled: boolean
  placeholder?: string
  onKeyUp?: (input: string, callback: () => void) => void
  loadingNode?: React.ReactNode
}

export const SuggestionInput = ({
  triggerSearch,
  suggestions,
  onSelect,
  isLoading,
  items,
  disabled,
  placeholder,
  onKeyUp,
  loadingNode
}: SuggestionInputProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState<string | undefined>(undefined)
  const comboboxRef = useRef<HTMLInputElement>(null)
  const listboxRef = useRef<HTMLDivElement>(null)
  const fieldRef = useRef<HTMLDivElement>(null)
  const isPrintView = usePrintView()

  const handleResetSearch = useCallback(() => {
    triggerSearch(undefined)
    setInputValue(undefined)
  }, [triggerSearch])

  useEffect(() => {
    setOpen(!isLoading && suggestions.length > 0 && !!inputValue)
  }, [inputValue, isLoading, suggestions.length])

  useOnClickOutside(comboboxRef, () => {
    if (!open) {
      handleResetSearch()
    }
  })

  useOnClickOutside(listboxRef, () => {
    if (open) {
      handleResetSearch()
    }
  })

  useEffect(() => {
    if (!disabled && !loadingNode) {
      comboboxRef.current?.focus()
    }
  }, [disabled, loadingNode, open])

  return (
    <RadixPopover.Root
      open={open}
      onOpenChange={setOpen}
    >
      <ComboboxProvider
        defaultValue=''
        open={open}
      >
        <RadixPopover.Anchor asChild>
          <S.CriteriaMultiValuesField ref={fieldRef} $disabled={disabled}>
            {items}
            {loadingNode}
            <S.Combobox
              ref={comboboxRef}
              tabIndex={0}
              value={inputValue ?? ''}
              onChange={(e) => {
                const newValue = e.target.value
                setInputValue(newValue)
                triggerSearch(newValue)
              }}
              autoFocus={true}
              autoSelect="always"
              $isLoading={isLoading}
              disabled={disabled || !!loadingNode}
              placeholder={isPrintView ? undefined : placeholder}
              onKeyUp={(e) => {
                if (onKeyUp && e.key === 'Enter' && inputValue) {
                  e.preventDefault()
                  setInputValue('') // Clear the input but not the query YET
                  onKeyUp(inputValue, () => {
                    triggerSearch(undefined)
                  })
                }
              }}
            />
          </S.CriteriaMultiValuesField>
        </RadixPopover.Anchor>
        <RadixPopover.Content
          asChild
          sideOffset={8}
          onOpenAutoFocus={(event) => {
            event.preventDefault()
          }}
          onInteractOutside={(event) => {
            const target = event.target as Element | null
            const isCombobox = target === comboboxRef.current
            const inListbox = target && listboxRef.current?.contains(target)
            if (isCombobox || inListbox) {
              event.preventDefault()
            }
          }}
        >
          <S.Results $width={fieldRef.current?.offsetWidth} ref={listboxRef}>
            <S.ResultInner>
              {suggestions.map((s, index) => {
                let title = s.name ?? ''
                const subtitle = getSubtitle(s)
                if ('city' in s) {
                  title = [s.city, s.state, s.metro].filter(Boolean).join(', ')
                }
                return (
                  <S.ResultItem
                    key={`${title}-${subtitle}-${index}`}
                    focusOnHover
                    value={s.name}
                    onClick={() => {
                      onSelect(s)
                      setInputValue('')
                      triggerSearch(undefined)
                      comboboxRef.current?.focus()
                    }}
                  >
                    <Flex $gap={8} $align="center">
                      {'logoUrl' in s &&
                        <Avatar
                          $size={24}
                          $type="logo"
                          initials={s.name}
                          company={{
                            name: s.name,
                            logoUrl: s.logoUrl
                          }}
                        />
                      }
                      <Caption size="XS" $color="fgPrimary" $whiteSpace="nowrap">
                        {titleCase(title)}
                      </Caption>
                      {subtitle && (
                        <Caption size="XS" $color="fgTertiary" $whiteSpace="nowrap" $fontWeight={400}>
                          {subtitle}
                        </Caption>
                      )}
                    </Flex>
                  </S.ResultItem>
                )
              })}
            </S.ResultInner>
          </S.Results>
        </RadixPopover.Content>
      </ComboboxProvider>
    </RadixPopover.Root>
  )
}
