import { CriteriaKey, DEFAULT_CUSTOM_REQUIREMENT_CRITERIA } from 'src/libs/api/backend/candidate_search'
import type { Criteria, CriteriaValue } from 'src/libs/api/backend/candidate_search'
import * as S from './refinement-filter-criteria-styled'
import { Caption } from 'src/components/primitives/typography'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CriteriaRow } from './criteria-row'
import { isEmpty, isNil } from 'lodash'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Button, Flex } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import { CriteriaProperties, NewCriteriaList } from './constants'
import { removeUpdatedCriteriaAtom } from 'src/stores/job-refinement'
import { useSetAtom } from 'jotai'
import { trackEvent } from 'src/libs/track'
import { TrackingEventType } from 'src/types/track'

interface RefinementFilterCriteriaProps {
  criteria: Criteria | undefined
  setCriteria: (criteria: Criteria) => void
  updatedGeneratedCriteria: CriteriaKey[]
  criteriaOrder: CriteriaKey[] | undefined
  setCriteriaOrder: (criteriaOrder: CriteriaKey[]) => void
  disabled: boolean
  clearCriteria: () => void
}

export const RefinementFilterCriteria = ({
  criteria,
  setCriteria,
  criteriaOrder = [],
  setCriteriaOrder,
  updatedGeneratedCriteria,
  disabled,
  clearCriteria
}: RefinementFilterCriteriaProps): JSX.Element => {
  const [isClearing, setIsClearing] = useState(false)
  const [scrollToBottom, setScrollToBottom] = useState(false)
  const refActionsBar = useRef<HTMLDivElement>(null)
  const handleUpdate = useCallback((criteriaKey: CriteriaKey, criteriaValue: CriteriaValue): void => {
    if (!isNil(criteriaValue)) {
      setCriteria({ ...(criteria ?? {}), [criteriaKey]: criteriaValue })
    }
  }, [criteria, setCriteria])

  const removeUpdatedCriteria = useSetAtom(removeUpdatedCriteriaAtom)

  useEffect(() => {
    if (scrollToBottom) {
      refActionsBar.current?.scrollIntoView({ behavior: 'smooth' })
      setScrollToBottom(false)
    }
  }, [scrollToBottom])

  const onRowClick = useCallback((criteriaKey: CriteriaKey) => {
    removeUpdatedCriteria(criteriaKey)
  }, [removeUpdatedCriteria])

  const makeCriteriaItem = useCallback((criteriaKey: CriteriaKey, icon: string | undefined): MenuItemProps | null => {
    const criteriaProperties = CriteriaProperties.get(criteriaKey)
    if (isNil(criteriaProperties)) {
      return null
    }
    const isDisabled = criteriaKey in (criteria ?? {})
    const { label, defaultValue } = criteriaProperties
    return {
      id: criteriaKey,
      title: label,
      icon,
      isDisabled,
      type: 'item',
      itemTooltip: isDisabled
        ? {
            text: 'This criteria is already set',
            position: 'right'
          }
        : undefined,
      onSelect: () => {
        handleUpdate(criteriaKey, defaultValue)
        setCriteriaOrder([...criteriaOrder, criteriaKey])
        setScrollToBottom(true)
      }
    }
  }, [criteria, criteriaOrder, handleUpdate, setCriteriaOrder, setScrollToBottom])

  const addCriteriaItems = useMemo((): MenuItemProps[] => {
    const items: MenuItemProps[] = []

    items.push({
      id: 'ai-custom-requirement-title',
      title: 'Pin can interpret resumes and answer advanced questions about candidates.',
      type: 'description'
    }, {
      id: 'custom-ai-question',
      icon: Icons.customRequirement,
      variant: 'aiSolid',
      type: 'item',
      title: 'Custom AI Question',
      onSelect: () => {
        if (criteria && CriteriaKey.CUSTOM_REQUIREMENTS in criteria) {
          handleUpdate(
            CriteriaKey.CUSTOM_REQUIREMENTS,
            [...(criteria[CriteriaKey.CUSTOM_REQUIREMENTS] ?? []), DEFAULT_CUSTOM_REQUIREMENT_CRITERIA]
          )
        } else {
          handleUpdate(
            CriteriaKey.CUSTOM_REQUIREMENTS,
            [DEFAULT_CUSTOM_REQUIREMENT_CRITERIA]
          )
          setCriteriaOrder([...(criteriaOrder ?? []), CriteriaKey.CUSTOM_REQUIREMENTS])
        }
        trackEvent(TrackingEventType.ADD_CUSTOM_AI_QUESTION)
      }
    }, {
      id: 'custom-criteria-separator',
      title: 'CustomCriteriaSeparator',
      type: 'separator'
    })

    NewCriteriaList.forEach((newCriteria) => {
      if (newCriteria.type === 'label' || newCriteria.type === 'separator') {
        items.push(newCriteria)
      } else if (newCriteria.criteriaKey) {
        const criteriaItem = makeCriteriaItem(newCriteria.criteriaKey, newCriteria.icon)
        if (criteriaItem) {
          items.push(criteriaItem)
        }
      } else if (newCriteria.type === 'group') {
        const { title, icon, items: groupItems = [] } = newCriteria
        const subItems: MenuItemProps[] = []
        groupItems.forEach((item) => {
          if (item.criteriaKey) {
            const criteriaItem = makeCriteriaItem(item.criteriaKey as CriteriaKey, undefined)
            if (criteriaItem) {
              subItems.push(criteriaItem)
            }
          }
        })
        items.push({
          id: title,
          title,
          icon,
          trailingIcon: Icons.chevronRight,
          subitems: subItems
        })
      }
    })
    return items
  }, [criteria, criteriaOrder, handleUpdate, makeCriteriaItem, setCriteriaOrder])

  const handleRemove = useCallback((criteriaKey: CriteriaKey, index?: number): void => {
    if (isNil(criteriaOrder) || isNil(criteria)) {
      return
    }
    const updatedCriteriaOrder = [...criteriaOrder]
    let currentCriteria = { ...criteria }
    const value = currentCriteria[criteriaKey]
    let deletingCriteria = true
    if (Array.isArray(value) && !isNil(index)) {
      const updatedCriteriaValue = value.filter((_, i) => i !== index)
      if (updatedCriteriaValue.length === 0) {
        const { [criteriaKey]: _removedCriteria, ...updatedCriteria } = currentCriteria
        currentCriteria = updatedCriteria
      } else {
        deletingCriteria = false
        currentCriteria = {
          ...currentCriteria,
          [criteriaKey]: updatedCriteriaValue
        }
      }
    } else {
      const { [criteriaKey]: _removedCriteria, ...updatedCriteria } = currentCriteria
      currentCriteria = updatedCriteria
    }
    setCriteria(currentCriteria)
    if (deletingCriteria) {
      updatedCriteriaOrder.splice(updatedCriteriaOrder.indexOf(criteriaKey), 1)
      setCriteriaOrder(updatedCriteriaOrder)
    }
  }, [criteria, criteriaOrder, setCriteria, setCriteriaOrder])

  return (
    <S.Wrapper>
      <Caption size='SM'>Criteria</Caption>
      <Flex $direction='column' $gap={4}>
        {criteria && criteriaOrder?.map((criteriaKey) => {
          return (
            <CriteriaRow
              key={criteriaKey}
              criteriaKey={criteriaKey}
              criteriaValue={criteria[criteriaKey]}
              onCriteriaUpdate={handleUpdate}
              onCriteriaRemove={handleRemove}
              updated={updatedGeneratedCriteria.includes(criteriaKey)}
              disabled={disabled}
              onRowClick={onRowClick}
            />
          )
        })}
      </Flex>
      <S.ActionsBar>
        <Dropdown
          $minWidth='15rem'
          $maxHeight='21.5rem'
          $maxWidth='20rem'
          disabled={disabled}
          side='right'
          alignOffset={-62}
          trigger={
            <Button
              nested
              $variant='outline'
              $height={24}
              $fontSize={12}
              trailingIcon={Icons.chevronDownSmall}
              disabled={disabled}
            >
              Add Criteria
            </Button>
          }
          items={addCriteriaItems}
          onCloseAutoFocus={(event: Event) => {
            event.preventDefault()
          }}
        />
        {isClearing
          ? <Flex $align='center' $gap={16} $width='auto'>
              <Caption size='SM' $fontWeight={400}>Clear all criterias?</Caption>
              <Flex $align='center' $gap={8} $width='auto'>
                <Button
                  $variant='fill'
                  $colorTheme='negative'
                  $height={24}
                  $fontSize={12}
                  onClick={() => {
                    clearCriteria()
                    setIsClearing(false)
                  }}
                >
                  Clear
                </Button>
                <Button
                  $variant='outline'
                  $height={24}
                  $fontSize={12}
                  onClick={() => {
                    setIsClearing(false)
                  }}
                >
                  Cancel
                </Button>
              </Flex>
            </Flex>
          : <Button
              $variant='ghost'
              $height={24}
              $fontSize={12}
              trailingIcon={Icons.trash}
              disabled={isEmpty(criteria) || disabled}
              onClick={() => {
                setIsClearing(true)
              }}
            >
              Clear all
            </Button>
        }
      </S.ActionsBar>
    </S.Wrapper>
  )
}
