import styled from 'styled-components'

export const HeroChromeExtension = styled.div`
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  box-shadow: ${({ theme }) => theme.boxShadows.md};
  svg {
    width: 40px;
    height: 40px;
  }
`

export const HeroLinkedInPin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`

export const LinkedInPinIconBox = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.boxShadows.md};
  svg {
    width: 24px;
    height: 24px;
  }
`
