export const generateUrlWithParams = (
  pathname: string,
  params: Record<string, string | string[] | null>
): string => {
  const urlSearchParams = new URLSearchParams()
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        urlSearchParams.append(key, v)
      })
    } else if (value !== null) {
      urlSearchParams.append(key, value)
    }
  })
  return `${pathname}?${urlSearchParams.toString()}`
}
