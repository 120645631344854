import styled from 'styled-components'
import type { DividerStyleProps } from './divider'

const Base = styled.div<DividerStyleProps>`
  margin-top: ${({ $spacing, $orientation, theme }) =>
    $orientation === 'horizontal' ? theme.spacing[$spacing?.before ?? 0] : 0};
  margin-right: ${({ $spacing, $orientation, theme }) =>
    $orientation === 'horizontal' ? 0 : theme.spacing[$spacing?.before ?? 0]};
  margin-bottom: ${({ $spacing, $orientation, theme }) =>
    $orientation === 'horizontal' ? theme.spacing[$spacing?.after ?? 0] : 0};
  margin-left: ${({ $spacing, $orientation, theme }) =>
    $orientation === 'horizontal' ? 0 : theme.spacing[$spacing?.before ?? 0]};
`

export const Divider = styled(Base)<DividerStyleProps>`
  width: ${({ $orientation }) => ($orientation === 'horizontal' ? '100%' : '1px')};
  height: ${({ $orientation }) => ($orientation === 'horizontal' ? '1px' : '100%')};
  background-color: ${({ theme, $color }) => theme.colors[$color ?? 'borderTranslucent']};
`

export const DividerWithText = styled(Base)<DividerStyleProps>`
  width: 100%;
  height: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  background-color: transparent;
`

export const Line = styled.div`
  display: block;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.borderTranslucent};
`

export const Inner = styled.div`
  p {
    text-transform: uppercase;
    white-space: nowrap;
  }
`
