import styled, { css } from 'styled-components'
import type { RuleSet } from 'styled-components'
import {
  Combobox as AriaKitCombobox,
  ComboboxItem as AriaKitComboboxItem,
  ComboboxList as AriaKitComboboxList
} from '@ariakit/react'

export const CriteriaRowWrapper = styled.div<{ $updated?: boolean }>`
  display: grid;
  grid-template-columns: 160px 1fr;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing[8]};
  border-radius: ${({ theme }) => theme.spacing[6]};
  width: 100%;
  padding: ${({ theme }) => theme.spacing[4]};
  padding-left: ${({ theme }) => theme.spacing[12]};
  ${({ $updated }) => $updated
  ? css`
      background-color: ${({ theme }) => theme.colors.warningTranslucent10};
    `
  : css`
      background-color: ${({ theme }) => theme.colors.bgTertiary};
    `
  }
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 2rem;
`

export const CriteriaPill = styled.div<{ $negative?: boolean, $optional?: boolean, $minWidth?: number }>`
  position: relative;
  display: flex;
  min-width: ${({ $minWidth }) => $minWidth ? `${$minWidth}px` : 'fit-content'};
  height: 22px;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
  padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};
  border-radius: ${({ theme }) => theme.spacing[4]};
  border: 1px solid ${({ theme, $negative, $optional }) =>
    !$optional
      ? theme.colors.fgTertiary
      : $negative
        ? theme.colors.negativeTranslucent25
        : theme.colors.borderTranslucent
  };
  background-color: ${({ theme }) => theme.colors.bgPrimary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgTertiary};
  }
`

export const CriteriaPillActions = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 1px;
  height: 18px;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: -4px 0px 4px 0px #FFF;
`

export const DropdownContainer = styled.div`
  flex: 1 0 0;
`

const CommonInputStyles = css`
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: ${({ theme }) => theme.fontWeights[400]};
  color: ${({ theme }) => theme.colors.fgPrimary};
  border: none;
  outline: none;
  width: 100%;
  background-color: transparent;
  &:placeholder {
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
`

const CommonFieldStyles = (disabled?: boolean): RuleSet<object> => css`
  flex: 1 1 0;
  min-height: 2rem;
  padding: ${({ theme }) => theme.spacing[8]};
  border: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: ${({ theme }) => theme.spacing[4]};
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  &:focus-within {
    outline: none;
    box-shadow: ${({ theme }) => theme.boxShadows['light-active']};
  }
  ${disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
`

export const CriteriaMultiValuesField = styled.div<{ $disabled?: boolean }>`
  ${({ $disabled }) => CommonFieldStyles($disabled)}
  padding: 4px 6px;
  gap: ${({ theme }) => theme.spacing[4]};
  flex-wrap: wrap;
  input {
    ${CommonInputStyles}
  }
`
export const CustomRequirementField = styled.div<{ $disabled?: boolean }>`
   ${({ $disabled }) => CommonFieldStyles($disabled)}
  input {
    ${CommonInputStyles}
  }
`

export const MinMaxNumberField = styled.div<{ $disabled?: boolean }>`
  ${({ $disabled }) => CommonFieldStyles($disabled)}
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing[8]};
  padding-right: ${({ theme }) => theme.spacing[12]};
  input {
    ${CommonInputStyles}
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`

export const SearchInput = styled.div`
  height: 100%;
  flex: 1;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  color: ${({ theme }) => theme.colors.fgPrimary};
  &:placeholder {
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    border: none !important;
    outline: none !important;
    background-color: transparent !important;
    padding: 0 !important;
    flex: 1;
    &:focus {
      outline: none;
    }
  }
`

export const InputField = styled.input`
  ${CommonInputStyles}
`

export const Combobox = styled(AriaKitCombobox)<{ $isLoading: boolean }>`
  ${CommonInputStyles}
  height: 1rem;
  flex: 1;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  color: ${({ theme }) => theme.colors.fgPrimary};
  &:placeholder {
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Results = styled(AriaKitComboboxList)<{ $width?: number }>`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 200px;
  width: ${({ $width }) => $width ? `${$width}px` : 'calc(100% + 10px)'};
  scrollbar-width: thin;
  box-shadow: ${({ theme }) => theme.boxShadows.md};
  border-radius: ${({ theme }) => theme.spacing[4]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.md};
  z-index: ${({ theme }) => theme.zindeces.dropdown};
  overflow-y: auto;
  gap: 1px;
`

export const ResultInner = styled.div`
  padding: ${({ theme }) => theme.spacing[6]};
`

export const ResultItem = styled(AriaKitComboboxItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing[6]};
  padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[10]}`};
  border-radius: ${({ theme }) => theme.spacing[4]};
  cursor: pointer;
  overflow: hidden;
  &[data-active-item] {
    background-color: ${({ theme }) => theme.colors.bgTertiary};
  }
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.bgTertiary};
    }
  }
`

export const RowContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
  align-items: center;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`

export const MultiSelectWrapper = styled.div`
  flex: 1 1 0;
`

export const MultiSelectInner = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
  border-radius: ${({ theme }) => theme.spacing[4]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  min-height: 32px;
  padding-left: ${({ theme }) => theme.spacing[6]};
`
