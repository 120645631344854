import { queryOptions, useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { fetchAtsIntegrationDetailsByType } from 'src/libs/api/backend/merge'
import type { AtsIntegration, AtsIntegrationType } from 'src/libs/api/backend/merge'
import { queryKeys } from 'src/libs/query-keys'

export const getAtsIntegrationDetails = (type: AtsIntegrationType) => queryOptions({
  queryKey: [queryKeys.atsIntegrationDetails, type],
  queryFn: async () => await fetchAtsIntegrationDetailsByType(type),
  staleTime: 15 * 1000
})

export const useGetAtsIntegrationDetails = (type: AtsIntegrationType): UseQueryResult<AtsIntegration | null> => {
  return useQuery(getAtsIntegrationDetails(type))
}
