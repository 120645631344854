import { useBillingPricingQuery } from 'src/hooks/queries/use-billing-pricing'
import * as S from './subscription-settings.styled'
import { useSession } from 'src/hooks/use-session'
import { Button, Flex } from 'src/components/primitives'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Icon, Icons } from 'src/components/primitives/icon'
import { NavLink } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { PriceInterval } from 'src/libs/api/backend/billing'
import type { BillingProductPrice, CustomerSubscription } from 'src/libs/api/backend/billing'
import { ButtonGroup } from 'src/components/primitives/button-group'
import { useTheme } from 'styled-components'
import CONFIG from 'src/config'
import { generateUrlWithParams } from 'src/utils/format-url'
import { useCustomerSubscriptionsQuery } from 'src/hooks/queries/use-customer-subscriptions'
import { isNil } from 'lodash'

const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(amount / 100)
}

interface SubscriptionSettingsProps {
  notificationBanner?: React.ReactNode | null
  onCloseAccount: (closeAccount: boolean) => void
}

export const SubscriptionSettings = ({ notificationBanner, onCloseAccount }: SubscriptionSettingsProps): JSX.Element => {
  const { data: pricing = [] } = useBillingPricingQuery()
  const { subscribed } = useSession()
  const { colors } = useTheme()
  const [interval, setInterval] = useState<PriceInterval>(PriceInterval.month)
  const [priceIds, setPriceIds] = useState<Set<string>>(new Set())
  const { data: customerSubscriptions = [] } = useCustomerSubscriptionsQuery({ enabled: subscribed ?? false })
  useEffect(() => {
    const currentPriceId = pricing
      .find(product => product.prices.find(price => price.interval === interval))
      ?.prices.find(price => price.interval === interval)?.id
    if (currentPriceId) {
      setPriceIds(new Set([currentPriceId]))
    }
  }, [interval, pricing])
  const subscribeUrl = useMemo(() => {
    const priceId = Array.from(priceIds).join(',')
    return generateUrlWithParams(`${CONFIG.API_DOMAIN}/billing/customer_checkout`, { priceId })
  }, [priceIds])
  const customersSubscriptionsByProductId = useMemo(() => {
    return customerSubscriptions.reduce((acc: Record<string, CustomerSubscription>, subscription) => {
      return {
        ...acc,
        [subscription.productId]: subscription
      }
    }, {})
  }, [customerSubscriptions])

  const userCurrentlyOnAnnualPlan = useMemo(() => {
    return customerSubscriptions.some(subscription => subscription.interval === PriceInterval.year)
  }, [customerSubscriptions])

  return (
    <Flex $direction='column' $gap={40}>
      {notificationBanner && (
        <S.NotificationBanner>
          {notificationBanner}
        </S.NotificationBanner>
      )}
      <S.Settings count={pricing?.length}>
        <S.Plan>
          <Caption size='MD'>Plan</Caption>
          {subscribed
            ? <Flex $align='center' $width='auto' $gap={8}>
                {!userCurrentlyOnAnnualPlan &&
                  <Button $variant='outline' $colorTheme='muted' $height={24} href='mailto:hi@pin.com'>
                    <Flex $align='center' $gap={2} $width='fit-content'>
                      <S.SwitchToAnnualPayment>Switch to Annual payment</S.SwitchToAnnualPayment>
                      <S.Discount>10% OFF</S.Discount>
                    </Flex>
                  </Button>
                }
                <Button $variant='outline' $height={24} href={`${CONFIG.API_DOMAIN}/billing/portal`} trailingIcon={Icons.externalLink} $fontSize={12}>
                  Customer portal
                </Button>
              </Flex>
            : <ButtonGroup $variant='ghost' $gap={4} $padding={4} $direction='horizontal' $backgroundColor={colors.bgTertiary} $groupBorderRadius={6} $borderRadius={4}>
                <Button
                  $height={24}
                  $variant={interval === PriceInterval.month ? 'raised-light' : 'ghost'}
                  $fontSize={12}
                  onClick={() => {
                    setInterval(PriceInterval.month)
                  }}
                >
                  Monthly
                </Button>
                <Button
                  $height={24}
                  $variant={interval === PriceInterval.year ? 'raised-light' : 'ghost'}
                  $fontSize={12}
                  onClick={() => {
                    setInterval(PriceInterval.year)
                  }}
                >
                  Annual
                </Button>
              </ButtonGroup>
          }
        </S.Plan>
        <Flex $direction='column' $gap={16}>
          {pricing.map((product) => {
            const productSubscribed = customersSubscriptionsByProductId[product.id ?? '']
            const productPrice = product.prices.find((price) => price.interval === interval) as BillingProductPrice | null
            const monthlyPrice = product.prices.find((price) => price.interval === PriceInterval.month) as BillingProductPrice | null
            if (isNil(productPrice)) return null
            let productCost = productPrice?.unitAmount ?? 0
            let productInterval = PriceInterval.month // Always display monthly for now
            if (!isNil(productSubscribed)) {
              productCost = productSubscribed.amount ?? 0
              productInterval = productSubscribed.interval
            } else if (interval === PriceInterval.year) {
              productCost = productCost / 12
            }

            return (
              <S.PlanCard key={product.id}>
                <Flex $direction='column' $flex='1 1 auto' $width='fit-content' $gap={4}>
                  <Caption size='MD'>{product.name}</Caption>
                  <NavLink to='https://www.pin.com/#pricing' target='_blank' end={true}>
                    <S.Link>
                      Plan details
                      <Icon name={Icons.externalLink} color='fgTertiary' />
                    </S.Link>
                  </NavLink>
                </Flex>
                {interval === PriceInterval.year && !productSubscribed && (
                  <S.DiscountedTag>
                    <Caption size='2XS' $color='positiveFg'>10% OFF</Caption>
                  </S.DiscountedTag>
                )}
                <Flex $gap={12} $align='center' $width='fit-content'>
                  {interval === PriceInterval.year && !productSubscribed && (
                    <S.OriginalPrice>
                      <Caption size='XL' $fontWeight={400} $color='fgTertiary'>{formatCurrency(monthlyPrice?.unitAmount ?? 0)}</Caption>
                    </S.OriginalPrice>
                  )}
                  <Caption size='XL'>{formatCurrency(productCost)}</Caption>
                  <Flex $direction='column' $width='auto' $align='flex-start'>
                    <Paragraph>per user</Paragraph>
                    <Paragraph>per {productInterval}</Paragraph>
                  </Flex>
                </Flex>
                {!isNil(productSubscribed) && (
                  <S.CurrentTag>Current</S.CurrentTag>
                )}
              </S.PlanCard>
            )
          })}
        </Flex>
        <S.ContactSales>
          <Paragraph size='XS'>Get a custom quote from our team for your unique needs.</Paragraph>
          <Button $colorTheme='tint' $variant='ghost' $width={101} $height={24} $fontSize={12} href='mailto:hi@pin.com' $align='center'>
            Contact sales
          </Button>
        </S.ContactSales>
      </S.Settings>
      {!subscribed &&
        <Flex $justify='space-between'>
          <Button
            $variant='fill'
            $colorTheme='tint'
            $height={40}
            $fontSize={14}
            href={subscribeUrl}
          >
            Subscribe to Pin
          </Button>
          <Button
            $variant='outline'
            $colorTheme='muted'
            $height={40}
            $fontSize={14}
            onClick={() => {
              onCloseAccount(true)
            }}
          >
            Close Pin account
          </Button>
        </Flex>
      }
    </Flex>
  )
}
