import type { Color, Spacing } from 'src/styles/theme/types'
import type { Variant } from '../button'
import * as S from './button-group.styled'

export interface ButtonGroupStyleProps {
  $variant?: Variant
  $direction?: 'horizontal' | 'vertical'
  $gap?: 0 | 1 | 2 | 4
  $padding?: Spacing
  $backgroundColor?: Color
  $borderRadius?: Spacing
  $groupBorderRadius?: Spacing
}

interface ButtonGroupProps extends ButtonGroupStyleProps {
  children: React.ReactNode
}

export const ButtonGroup = ({
  $variant = 'ghost',
  $direction = 'horizontal',
  $gap = 1,
  $padding,
  $backgroundColor,
  $borderRadius,
  $groupBorderRadius = 4,
  children
}: ButtonGroupProps): JSX.Element => {
  return (
    <S.ButtonGroup
      $variant={$variant}
      $direction={$direction}
      $gap={$gap}
      $padding={$padding}
      $backgroundColor={$backgroundColor}
      $borderRadius={$borderRadius}
      $groupBorderRadius={$groupBorderRadius}
    >
      {children}
    </S.ButtonGroup>
  )
}
