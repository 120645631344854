import styled from 'styled-components'
import type { SignalStyleProps } from './signal'

export const Signal = styled.span<SignalStyleProps>`
  position: relative;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  width: ${({ theme, $size }) => theme.spacing[$size ?? 6]};
  height: ${({ theme, $size }) => theme.spacing[$size ?? 6]};
  border-radius: 999px;
  transform: ${({ $offset, theme }) =>
    `translate(${theme.spacing[$offset?.x ?? 0]}, ${theme.spacing[$offset?.y ?? 0]})`};
  background-color: ${({ theme, $color }) => theme.colors[$color ?? 'fgSecondary']};
`
