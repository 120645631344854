import { ERROR_BLOCK_HEIGHT, NAVIGATION_WIDTH } from 'src/styles/constants'
import styled from 'styled-components'

interface LayoutProps {
  $isCollapsed?: boolean
  $isErrored: boolean
}

export const DashboardLayout = styled.div<LayoutProps>`
  display: flex;
  min-height: ${({ $isErrored }) => ($isErrored ? `calc(100vh - ${ERROR_BLOCK_HEIGHT})` : '100vh')};
  background-color: ${({ theme }) => theme.colors.bgSecondary};
`

export const NavigationWrapper = styled.div<LayoutProps>`
  height: ${({ $isErrored }) => ($isErrored ? `calc(100vh - ${ERROR_BLOCK_HEIGHT})` : '100vh')};
  width: ${({ $isCollapsed }) => ($isCollapsed ? NAVIGATION_WIDTH.LG.CLOSED : NAVIGATION_WIDTH.LG.OPEN)};
  transition: width 0.1s;
  @media screen and (max-height: 800px) {
    width: ${({ $isCollapsed }) => ($isCollapsed ? NAVIGATION_WIDTH.MD.CLOSED : NAVIGATION_WIDTH.MD.OPEN)};
  }
`

export const Content = styled.div<LayoutProps>`
  height: ${({ $isErrored }) => ($isErrored ? `calc(100vh - ${ERROR_BLOCK_HEIGHT})` : '100vh')};
  width: ${({ $isCollapsed }) => `calc(100% - ${$isCollapsed ? NAVIGATION_WIDTH.LG.CLOSED : NAVIGATION_WIDTH.LG.OPEN})`};
  transition: width 0.1s;
  @media screen and (max-height: 800px) {
    width: ${({ $isCollapsed }) => `calc(100% - ${$isCollapsed ? NAVIGATION_WIDTH.MD.CLOSED : NAVIGATION_WIDTH.MD.OPEN})`};
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  gap: ${({ theme }) => theme.spacing[2]};
`
