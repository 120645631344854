import styled from 'styled-components'
import type { Spacing } from 'src/styles/theme/types'

interface SpacerProps {
  $direction?: 'column' | 'row'
  $size: Spacing
}

export const Spacer = ({ $size, $direction = 'column' }: SpacerProps): JSX.Element => (
  <StyledSpacer $size={$size} $direction={$direction} />
)

const StyledSpacer = styled.div<SpacerProps>`
  width: ${({ $size, $direction, theme }) =>
    $direction === 'column' ? '100%' : theme.spacing[$size]};
  height: ${({ $size, $direction, theme }) =>
    $direction === 'column' ? theme.spacing[$size] : '1px'};
`
