import * as Dialog from 'src/components/primitives/dialog'
import { MessageComposer } from 'src/components/blocks/message-composer'
import type { EmailData } from 'src/components/blocks/message-composer'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import type { EmailAccount } from 'src/libs/api/backend/users'
import { useSession } from 'src/hooks/queries/use-session'
import { Flex } from 'src/components/primitives/flex'
import { Button } from 'src/components/primitives/button'
import { useEffect, useMemo, useState } from 'react'
import { CandidateJobStatus } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
// import type { OutboundEmail } from 'src/libs/api/backend/sequences'
// import { useSendOutboundEmail } from 'src/hooks/mutations/use-send-outbound-email'
import { useSendManualEmail } from 'src/hooks/mutations/use-send-manual-email'
import { When } from 'src/components/blocks/when'
import { Icon } from 'src/components/primitives/icon'
import { Spacer } from 'src/components/primitives/spacer'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { pluralize } from 'src/libs/pluralize'
import type { Spacing } from 'src/styles/theme/types'
import { useAtomValue, useSetAtom } from 'jotai'
import { isDialogOpenAtom, DialogId, controlDialogAtom, openAlertAtom } from 'src/stores/dialogs'
import { MessageType } from 'src/libs/api/backend/sequences'

interface WriteMessageDialogProps {
  candidateJobs?: CandidateJobExpanded[]
}

export const WriteMessageDialog = ({ candidateJobs }: WriteMessageDialogProps): JSX.Element => {
  const [dialogView, setDialogView] = useState<'WRITE' | 'CONFIRMATION'>('WRITE')
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.WRITE_MESSAGE), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const openAlert = useSetAtom(openAlertAtom)
  const [manualEmail, setManualEmail] = useState<EmailData & { candidateIds: string[] }>({
    subject: '',
    body: '',
    candidateIds: [],
    sendingUserId: '',
    sendingEmailAccountId: '',
    sendingEmailAlias: null,
    sendingLinkedInAccountId: null,
    recipients: candidateJobs ?? []
  })
  // const { sendEmail } = useSendOutboundEmail()
  const { sendManualEmail } = useSendManualEmail()
  const [isSendable, setIsSendable] = useState(false)

  useEffect(() => {
    const invalidStatus = [
      CandidateJobStatus.EMAIL_NOT_FOUND,
      CandidateJobStatus.BOUNCED
    ]
    const validCandidateIds = candidateJobs
      ?.filter(
        (candidateJob) =>
          candidateJob.statusDisplay?.status &&
          !invalidStatus.includes(candidateJob.statusDisplay?.status)
      )
      .map((candidateJob) => candidateJob.candidateId)
    if (validCandidateIds) {
      setManualEmail((prev) => {
        const updated = {
          ...prev,
          candidateIds: validCandidateIds
        }
        return updated
      })
    }
  }, [candidateJobs, setManualEmail])

  const { data: sessionData } = useSession()
  const { data: orgUsers } = useOrgUsersQuery()
  const userEmailAccounts = orgUsers?.flatMap((user) => user.emailAccounts) ?? []

  const getSendingAccount = (): EmailAccount => {
    return (
      userEmailAccounts.find((account) => account.userId === sessionData?.user?.id) ??
      userEmailAccounts.find(account => account.isPrimary) ??
      userEmailAccounts[0]
    )
  }

  const DIALOG_PADDING = {
    WRITE: {
      top: 0 as Spacing,
      right: 0 as Spacing,
      bottom: 0 as Spacing,
      left: 0 as Spacing
    },
    CONFIRMATION: {
      top: 24 as Spacing,
      right: 24 as Spacing,
      bottom: 24 as Spacing,
      left: 24 as Spacing
    }
  }

  const handleCloseDialog = (): void => {
    const userHasChanges = (): boolean => {
      // Ideally, we'd use something like `isEqual` here, but depending on the
      // use case and how the editor updates, it might return an empty <p> tag
      // which would return true, even if there have not been any "real" changes.
      const detectChanges = (input: string): boolean => {
        return input !== '<p></p>' && input !== '<p> </p>' && input !== ''
      }
      const bodyHasChanges = detectChanges(manualEmail.body ?? '')
      const subjectHasChanges = detectChanges(manualEmail.subject ?? '')
      return dialogView === 'WRITE' && (bodyHasChanges || subjectHasChanges)
    }

    if (userHasChanges()) {
      openAlert({
        message: 'Are you sure you want to leave this page?',
        description: 'All your changes will be lost.',
        cancelText: 'Stay on page',
        confirmText: 'Discard and leave',
        onConfirm: () => {
          controlDialog({ id: DialogId.WRITE_MESSAGE, newState: false })
        }
      })
    } else {
      controlDialog({ id: DialogId.WRITE_MESSAGE, newState: false })
    }
  }

  return (
    <Dialog.Root
      id={DialogId.WRITE_MESSAGE}
      isOpen={isDialogOpen}
      onOpenChange={() => {
        handleCloseDialog()
      }}
      $width={dialogView === 'WRITE' ? 'half' : '320px'}
      $maxWidth="640px"
      $innerPadding={DIALOG_PADDING[dialogView]}
    >
      <Dialog.Portal>
        <When condition={dialogView === 'WRITE'}>
          <Dialog.Header
            title="New Message"
            onClose={() => {
              handleCloseDialog()
            }}
          />
        </When>
        <Dialog.Content>
          <When condition={dialogView === 'WRITE'}>
            <MessageComposer
              currentData={manualEmail}
              forceEditorFocus
              onSendableStateChange={(sendable) => {
                setIsSendable(sendable)
              }}
              onDataChanged={(data) => {
                setManualEmail((prev) => {
                  return {
                    ...prev,
                    subject: data.subject ?? '',
                    body: data.body ?? '',
                    sendingUserId: data.sendingUserId ?? getSendingAccount()?.userId,
                    sendingEmailAccountId: data.sendingEmailAccountId ?? getSendingAccount()?.id,
                    sendingEmailAlias: data.sendingEmailAlias ?? null,
                    sendingLinkedInAccountId: data.sendingLinkedInAccountId ?? null,
                    messageType: data.messageType ?? MessageType.EMAIL,
                    recipients: data.recipients ?? prev.recipients ?? [],
                    cc: data.cc ?? [],
                    bcc: data.bcc ?? [],
                    attachmentUploads: data.attachmentUploads ?? []
                  }
                })
              }}
              minHeight="16rem"
              useVariables={true}
              useAttachments={true}
              leftActions={
                <Button
                  $variant="flat"
                  $colorTheme="tint"
                  $height={32}
                  disabled={!isSendable}
                  onClick={() => {
                    sendManualEmail({
                      manualEmail: {
                        ...manualEmail,
                        subject: manualEmail.subject ?? '',
                        body: manualEmail.body ?? '',
                        sendingUserId: manualEmail.sendingUserId ?? getSendingAccount()?.userId,
                        sendingEmailAccountId: manualEmail.sendingEmailAccountId ?? getSendingAccount()?.id,
                        sendingEmailAlias: manualEmail.sendingEmailAlias ?? null,
                        cc: manualEmail.cc ?? [],
                        bcc: manualEmail.bcc ?? [],
                        attachmentUploads: manualEmail.attachmentUploads ?? []
                      },
                      onSuccess: () => {
                        setDialogView('CONFIRMATION')
                      }
                    })
                  }}
                >
                  {manualEmail.sendingLinkedInAccountId ? 'Send InMail' : 'Send Email'}
                </Button>
              }
            />
          </When>
          <When condition={dialogView === 'CONFIRMATION'}>
            <>
              <Icon name="send" size={40} color="fgFaded10" />
              <Spacer $size={24} />
              <Caption size="LG">Done</Caption>
              <Spacer $size={16} />
              <Paragraph size="SM">
                {pluralize(manualEmail.candidateIds.length, 'email')}{' '}
                {manualEmail.candidateIds.length >= 2 ? 'were' : 'was'} sent successfully to
                your candidates.
              </Paragraph>
              <Spacer $size={24} />
              <Flex $gap={16}>
                <Button
                  $variant="fill"
                  $colorTheme="tint"
                  $width="full"
                  $height={40}
                  $align="center"
                  onClick={() => {
                    controlDialog({ id: DialogId.WRITE_MESSAGE, newState: false })
                  }}
                >
                  Close
                </Button>
              </Flex>
            </>
          </When>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
