import { Form, Input } from 'src/components/forms'
import * as S from './searchbar.styled'
import { useForm } from 'src/hooks/use-form'
import { z } from 'zod'
import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import type { JobBoardListing } from 'src/libs/api/backend/external_job_listings'
import { Button } from 'src/components/primitives/button'

const searchFormSchema = z.object({
  query: z.string().nullish()
})

type SearchForm = z.infer<typeof searchFormSchema>

interface SearchbarProps {
  onReset: () => void
  importedJobsByName: Record<string, JobBoardListing[]>
  onSearch: (result: Record<string, JobBoardListing[]>) => void
}

export const Searchbar = ({ onSearch, onReset, importedJobsByName }: SearchbarProps): JSX.Element => {
  const [hasSearched, setHasSearched] = useState(false)
  const { submit, register, formData, setValue } = useForm<SearchForm>({
    schema: searchFormSchema
  })

  const debouncedSearch = useMemo(() => debounce((formData: SearchForm) => {
    setHasSearched(true)
    const query = formData.query?.toLowerCase() ?? ''

    const filteredJobs = Object.entries(importedJobsByName).reduce<Record<string, JobBoardListing[]>>((acc, [name, jobs]) => {
      const matchingJobs = jobs.filter(job => job.title.toLowerCase().includes(query))
      if (matchingJobs.length > 0) {
        acc[name] = matchingJobs
      }
      return acc
    }, {})

    onSearch(filteredJobs)
  }, 300), [importedJobsByName])

  const handleSubmit = async (formData: SearchForm): Promise<void> => {
    debouncedSearch(formData)
  }

  useEffect(() => {
    if (typeof formData?.query === 'string' && (formData?.query?.length) >= 3) {
      debouncedSearch(formData)
    }

    return () => {
      debouncedSearch.cancel()
    }
  }, [formData, debouncedSearch])

  const handleReset = (): void => {
    setValue('query', undefined)
    onReset()
  }

  useEffect(() => {
    if (hasSearched && typeof formData?.query === 'string' && (formData?.query?.length) === 0) {
      handleReset()
    }
  }, [formData.query, hasSearched])

  return (
    <S.Searchbar>
      <Form onSubmit={submit(handleSubmit)}>
        <Input
          icon="search"
          register={register}
          name="query"
          label="Search existing jobs"
          placeholder="Search existing job positions"
          hiddenLabel
          $marginBottom={0}
          trailingAction={
            formData?.query
              ? (
                <Button
                  $variant="flat"
                  $colorTheme="muted"
                  $height={24}
                  $width={24}
                  $fontSize={12}
                  ariaLabel="Reset search"
                  leadingIcon="x"
                  onClick={handleReset}
                />
                )
              : undefined
          }
        />
      </Form>
    </S.Searchbar>
  )
}
