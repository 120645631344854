import { Flex } from 'src/components/primitives'
import * as S from './upload-attachments.styled'
import { Icon, Icons } from 'src/components/primitives/icon'

interface UploadAttachmentsProps {
  handleUpload: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  accept?: string
  multiple?: boolean
}

export const UploadAttachments = ({
  handleUpload,
  accept = '*',
  multiple = false
}: UploadAttachmentsProps): JSX.Element => {
  return (
    <Flex $gap={2} $width='auto' $align='center'>
      <input
        id='attachment-upload'
        type='file'
        accept={accept}
        multiple={multiple}
        hidden
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          void handleUpload(e)
        }}
      />
      <S.UploadHandle htmlFor="attachment-upload">
        <Icon name={Icons.paperClip} size={12} />
      </S.UploadHandle>
    </Flex>
  )
}
