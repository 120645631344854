import * as S from './ready-to-send.styled'
import { CandidateProfile } from '../candidate-details'
import { Button, Flex } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import { Caption } from 'src/components/primitives/typography'
import { Avatar } from 'src/components/primitives/avatar'
import { Editor } from '../editor'
import { useMemo, useState } from 'react'
import type { CandidateSequenceStepMessageReviewExtended } from 'src/libs/api/backend/candidate_sequence_step_message_review'
import type { EmailAccount, OrgUser } from 'src/libs/api/backend/users'
import { When } from '../when'
import { Badge } from 'src/components/primitives/badge'
import { SequenceStatusDropdown } from '../candidate-job-status'
import { Tooltip } from 'src/components/primitives/tooltip'
import { isNil } from 'lodash'
import { CandidateJobStatus } from 'src/libs/api/backend/candidate_jobs'
import { useSetAtom } from 'jotai'
import { openDialogAtom, DialogId } from 'src/stores/dialogs'
import { ButtonGroup } from 'src/components/primitives/button-group'
import { Dropdown } from 'src/components/primitives/dropdown'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { useSession } from 'src/hooks/use-session'

interface ReadyToSendProps {
  candidateSequenceStepMessageReview: CandidateSequenceStepMessageReviewExtended
  usersByUserId: Record<string, OrgUser>
  handleToggleFavoriteStatus: (candidateJobId: string, newStatus: boolean) => void
  handleSendEmail: (candidateSequenceStepMessageReviewId: string, subject: string, body: string) => void
  sendDisabled?: boolean
  currentUserId?: string
  reconnect: (selectedEmailAccount: EmailAccount | undefined) => void
  openCandidateDialog: (candidateJobId: string) => void
}
export const ReadyToSend = ({
  candidateSequenceStepMessageReview,
  usersByUserId,
  handleToggleFavoriteStatus,
  handleSendEmail,
  sendDisabled,
  currentUserId,
  reconnect,
  openCandidateDialog
}: ReadyToSendProps): JSX.Element => {
  const { candidateJob, sequenceStep, body, compiledBody, subject, compiledSubject } = candidateSequenceStepMessageReview
  const initialEmailBody = useMemo(() => body ?? compiledBody ?? undefined, [body, compiledBody])
  const [emailBody, setEmailBody] = useState<string | undefined>(initialEmailBody)
  const [emailSubject, setEmailSubject] = useState<string | undefined>(subject ?? compiledSubject ?? undefined)
  const sendingUser = useMemo(() => {
    return usersByUserId[sequenceStep.sendingUserId] ?? {}
  }, [usersByUserId, sequenceStep.sendingUserId])
  const openDialog = useSetAtom(openDialogAtom)
  const { featureFlags } = useSession()

  const sendingUserAccount = useMemo(() => {
    return (sendingUser.emailAccounts ?? []).find((emailAccount) => emailAccount.id === sequenceStep.sendingEmailAccountId)
  }, [sendingUser.emailAccounts, sequenceStep.sendingEmailAccountId])

  const sendNowDisabled = useMemo(() => {
    return (
      !emailSubject ||
      !emailBody ||
      !sendingUserAccount?.currentUserHasSendAsPermission ||
      !sendingUserAccount?.hasAccessToken ||
      sendDisabled
    )
  }, [emailSubject, emailBody, sendingUserAccount, sendDisabled])

  const readyToSendTooltip = useMemo(() => {
    if (!sendingUserAccount?.currentUserHasSendAsPermission) {
      return 'Your teammate needs to give you permission to this account in order to send.'
    }
    if (!sendingUserAccount?.hasAccessToken) {
      return 'This email account is not connected'
    }
    if (!emailSubject) {
      return 'Please provide a subject line'
    }
    if (!emailBody) {
      return 'Please provide an email message'
    }

    if (sendDisabled) {
      return 'Outreach is paused, please enabled outreach to start sending emails'
    }

    return undefined
  }, [emailBody, emailSubject, sendDisabled, sendingUserAccount?.currentUserHasSendAsPermission, sendingUserAccount?.hasAccessToken])

  const candidateHasEmailError = useMemo(() => {
    const status = candidateSequenceStepMessageReview.candidateJob.statusDisplay?.status
    return status === CandidateJobStatus.SEARCHING_FOR_EMAIL || status === CandidateJobStatus.EMAIL_NOT_FOUND
  }, [candidateSequenceStepMessageReview.candidateJob.statusDisplay])

  return (
    <S.ReadyToSendContainer $hasError={candidateHasEmailError}>
      <When condition={candidateHasEmailError}>
        <S.ErrorHeader>
          <Caption size="XS" $color='negativeFg'>{candidateSequenceStepMessageReview.candidateJob.statusDisplay?.title}</Caption>
        </S.ErrorHeader>
      </When>
      <S.ReadyToSendHeader>
        <S.ReadyToSendProfile onClick={() => { openCandidateDialog(candidateJob.id) }}>
          <CandidateProfile
            avatarSize={32}
            headingSize='SM'
            horizontalGap={8}
            verticalGap={2}
            candidate={candidateJob.candidate}
            includeLatestExperienceTimeline={true}
          />
        </S.ReadyToSendProfile>
        <Flex $align='center' $width='auto'>
          <Flex $align='center' $gap={8} $width='auto'>
            <Button
              ariaLabel="Toggle favorite status"
              $variant="outline"
              $colorTheme={candidateJob.favorite ? 'warning' : 'muted'}
              $fontSize={12}
              $height={24}
              leadingIcon={candidateJob.favorite ? Icons.starFill : Icons.star}
              onClick={() => {
                handleToggleFavoriteStatus(candidateJob.id, !candidateJob.favorite)
              }}
              tooltip={{
                text: candidateJob.favorite ? 'Remove candidate from shortlist' : 'Add candidate to shortlist',
                position: 'top'
              }}
            >
            </Button>
            <SequenceStatusDropdown
              candidateJob={candidateJob}
              $variant='outline'
              enabledSendEmail={false}
            />
          </Flex>
        </Flex>
      </S.ReadyToSendHeader>
      <S.ReadyToSendSender>
        <Caption size='XS' $color='fgSecondary' $fontWeight={400}>From</Caption>
        <Flex $align="center" $gap={8}>
          <Button
            disabled={true}
            $fontSize={12}
            $height={24}
            trailingIcon={Icons.chevronsUpDownSmall}
            $variant='outline'
          >
            <Avatar
              $size={16}
              $shape='circle'
              $type="photo"
              initials={sendingUser?.name}
              photoUrl={sendingUser?.profilePhotoUrl}
              fallbackAvatar="random"
            />
            {sendingUserAccount?.email ?? ''}
          </Button>
          <When condition={!isNil(sendingUserAccount) && !sendingUserAccount.hasAccessToken}>
            <Badge
              $variant="negativeLight"
              leadingIcon="alert-triangle"
              $transform="none"
              $fontSize={12}
              $height={24}
              $padding="casual"
            >
              Email account not connected
            </Badge>
            <Button
              $height={24}
              $variant="fill"
              $colorTheme="tint"
              leadingIcon="refresh-cw"
              $fontSize={12}
              ariaLabel='Reconnect email account'
              onClick={() => {
                reconnect(sendingUserAccount)
              }}
              disabled={sendingUserAccount?.userId !== currentUserId}
              tooltip={{
                text: sendingUserAccount?.userId === currentUserId ? undefined : 'Have your teammate reconnect this email account or switch to a different sender',
                position: 'top'
              }}
            >
              Reconnect
            </Button>
          </When>
        </Flex>
      </S.ReadyToSendSender>
      <S.ReadyToSendSubject>
        <Caption size='XS' $color='fgSecondary' $fontWeight={400}>Subject</Caption>
        <input
          value={emailSubject}
          placeholder='Your subject line'
          onChange={(event) => {
            setEmailSubject(event.target.value)
          }}
        />
      </S.ReadyToSendSubject>
      <S.ReadyToSendBody>
        <Editor
          isEditable={true}
          placeholder="Compose a email message..."
          $editorHeight='fit-content'
          contentType='html'
          initialContent={initialEmailBody}
          content={emailBody}
          onDataChanged={(data) => {
            setEmailBody(data)
          }}
          toolbar={
            <S.ReadyToSendToolbar>
              <Flex $align="center" $gap={12}>
                <When condition={!candidateHasEmailError}>
                  <Tooltip
                    trigger={
                      <span>
                        <Button
                          $fontSize={12}
                          $height={24}
                          $variant='flat'
                          $colorTheme='tint'
                          disabled={sendNowDisabled}
                          onClick={() => {
                            handleSendEmail(candidateSequenceStepMessageReview.id, emailSubject ?? '', emailBody ?? '')
                          }}
                        >
                          Queue send
                        </Button>
                      </span>
                    }
                    triggerDisabled={sendNowDisabled}
                    disabled={!sendNowDisabled}
                  >
                    {readyToSendTooltip}
                  </Tooltip>
                </When>
                <When condition={candidateHasEmailError}>
                  <Button
                    $fontSize={12}
                    $height={24}
                    $variant='flat'
                    $colorTheme='tint'
                    leadingIcon={Icons.mailPlus}
                    onClick={() => { openDialog({ id: DialogId.EDIT_CANDIDATE, payload: candidateJob }) }}
                  >
                    Add email address
                  </Button>
                </When>
                <When condition={featureFlags?.includes(FeatureFlags.LINKEDIN_CONNECTION)}>
                  <ButtonGroup $variant="raised" $gap={1}>
                    <Button
                      $fontSize={12}
                      $height={24}
                      $variant='flat'
                      $colorTheme='tint'
                      leadingIcon="linkedin"
                      onClick={() => { openDialog({ id: DialogId.EDIT_CANDIDATE, payload: candidateJob }) }}
                    >
                      Send InMail
                    </Button>
                    <Dropdown
                      trigger={
                        <Button
                          $fontSize={12}
                          $height={24}
                          $variant='flat'
                          $colorTheme='tint'
                          leadingIcon={Icons.chevronDown}
                        />
                      }
                      items={[
                        {
                          title: 'Send as email'
                        },
                        {
                          title: 'Send as LinkedIn InMail'
                        }
                      ]}
                      size="small"
                    />
                  </ButtonGroup>
                </When>
              </Flex>
            </S.ReadyToSendToolbar>
          }
        />
      </S.ReadyToSendBody>
    </S.ReadyToSendContainer>
  )
}
