import { useQuery } from '@tanstack/react-query'
import { fetchExistingOrgs } from 'src/libs/api/backend/orgs'
import { queryKeys } from 'src/libs/query-keys'

export const existingOrgsQuery = () => ({
  queryKey: [queryKeys.existingOrgs],
  queryFn: async () => await fetchExistingOrgs(),
  staleTime: 60 * 1000
})

export const useExistingOrgsQuery = () => {
  return useQuery(existingOrgsQuery())
}
