import { format } from 'date-fns'
import * as S from './calendar-icon.styled'

interface CalendarIconProps {
  timestamp: Date | string
}

export const CalendarIcon = ({ timestamp }: CalendarIconProps): JSX.Element => {
  return (
    <S.CalendarIcon>
      <S.Month>
        <span>{format(timestamp, 'MMM').toUpperCase()}</span>
      </S.Month>
      <S.Day>
        <span>{format(timestamp, 'dd')}</span>
      </S.Day>
    </S.CalendarIcon>
  )
}
