import styled from 'styled-components'
import { ConditionalFieldGroupStyleProps } from './conditional-field-group'

export const Wrapper = styled.div<ConditionalFieldGroupStyleProps>`
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 4px;
  padding: 1rem;
  ${({ $variant }) => $variant === 'ghost' && `
    border-color: transparent;
    background-color: lightcoral;
  `}
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  label {
    white-space: nowrap;
  }
`

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 17px;
`

export const Content = styled.div`
  p:first-of-type {
    /* Optical alignment */
    margin-left: 1px;
  }
`
