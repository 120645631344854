export const LANGUAGES = new Set<string>([
  'afrikaans',
  'akan',
  'albanian',
  'amazigh',
  'amharic',
  'arabic',
  'aramaic',
  'armenian',
  'assamese',
  'aymara',
  'azerbaijani',
  'balochi',
  'bambara',
  'banda',
  'bashkort',
  'basque',
  'belarusian',
  'bemba',
  'bengali',
  'bhojpuri',
  'bislama',
  'bosnian',
  'brahui',
  'bulgarian',
  'burmese',
  'cantonese',
  'catalan',
  'cebuano',
  'chechen',
  'cherokee',
  'chewa',
  'croatian',
  'czech',
  'dakota',
  'danish',
  'dari',
  'dholuo',
  'dinka',
  'dutch',
  'esperanto',
  'estonian',
  'ewe',
  'farsi',
  'filipino',
  'finnish',
  'fon',
  'french',
  'fula',
  'galician',
  'georgian',
  'german',
  'gikuyu',
  'greek',
  'guarani',
  'gujarati',
  'haitian creole',
  'hausa',
  'hawaiian',
  'hawaiian creole',
  'hebrew',
  'hiligaynon',
  'hindi',
  'hungarian',
  'icelandic',
  'igbo',
  'ilocano',
  'indonesian',
  'irish gaelic',
  'italian',
  'japanese',
  'jarai',
  'javanese',
  "k'iche'",
  'kabyle',
  'kannada',
  'kashmiri',
  'kazakh',
  'khmer',
  'khoekhoe',
  'kinyarwanda',
  'kongo',
  'konkani',
  'korean',
  'kurdish',
  'kyrgyz',
  'lao',
  'latin',
  'latvian',
  'lingala',
  'lithuanian',
  'macedonian',
  'maithili',
  'malagasy',
  'malay',
  'malayalam',
  'mandarin',
  'mandinka',
  'marathi',
  'mende',
  'mongolian',
  'nahuatl',
  'navajo',
  'nepali',
  'norwegian',
  'ojibwa',
  'oriya',
  'oromo',
  'pashto',
  'persian',
  'polish',
  'portuguese',
  'punjabi',
  'quechua',
  'romani',
  'romanian',
  'russian',
  'samoan',
  'sanskrit',
  'serbian',
  'shona',
  'sindhi',
  'sinhala',
  'sinhalese',
  'slovak',
  'slovene',
  'slovenian',
  'somali',
  'songhay',
  'spanish',
  'swahili',
  'swazi',
  'swedish',
  'tachelhit',
  'tagalog',
  'taiwanese',
  'tajiki',
  'tamil',
  'tatar',
  'telugu',
  'thai',
  'tigrigna',
  'tok pisin',
  'tonga',
  'tsonga',
  'tswana',
  'turkish',
  'turkmen',
  'ukrainian',
  'urdu',
  'uyghur',
  'uzbek',
  'vietnamese',
  'warlpiri',
  'welsh',
  'wolof',
  'xhosa',
  'yakut',
  'yiddish',
  'yoruba',
  'yucatec',
  'zapotec',
  'zulu'
])

export const languageTypeAheadOptions = (input: string): Array<{ name: string }> => {
  const lowerCaseInput = input.toLowerCase()
  return Array.from(LANGUAGES).filter((language) => language.includes(lowerCaseInput)).map((language) => ({ name: language }))
}
