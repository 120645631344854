import { useState } from 'react'
import * as S from './email-sequence-suggestion.styled'

import { Flex } from 'src/components/primitives'
import { Button } from 'src/components/primitives/button'
import { Icons } from 'src/components/primitives/icon'
import { LogoSymbol } from 'src/components/primitives/logo'
import { Paragraph } from 'src/components/primitives/typography'
import { isNil } from 'lodash'

interface EmailSequenceSuggestionProps {
  onApplySuggestion: () => void
  onCloseSuggestion?: () => Promise<void>
  onOpenSuggestion?: () => Promise<void>
  isGeneratingSuggestion: boolean
  startCollpased?: boolean
  children: React.ReactNode
}

export const EmailSequenceSuggestion = ({
  onApplySuggestion,
  onOpenSuggestion,
  onCloseSuggestion,
  isGeneratingSuggestion,
  startCollpased = false,
  children
}: EmailSequenceSuggestionProps): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(startCollpased)

  const renderBanner = (): JSX.Element => {
    return (
      <S.Banner>
        <Flex
          $width='100%'
          $direction='row'
          $align='center'
          $justify='space-between'
        >
          <Flex
            $direction='row'
            $align='center'
            $justify='flex-start'
            $gap={12}
          >
            <LogoSymbol height={16} width={16}/>
            <Paragraph $color="fgSecondary" $fontSize={20} $fontWeight={500}>Pin suggestion</Paragraph>
          </Flex>

          <Flex
            $direction='row'
            $align='center'
            $justify='flex-end'
            $gap={16}
          >
            <Button
              $width={14}
              $height={14}
              leadingIcon={!isCollapsed ? Icons.minus : Icons.maximize2}
              iconSize={10}
              $variant="ghost"
              $colorTheme="muted"
              onClick={async () => {
                if (isCollapsed && !isNil(onOpenSuggestion)) {
                  setIsCollapsed(!isCollapsed)
                  await onOpenSuggestion()
                } else if (!isCollapsed && !isNil(onCloseSuggestion)) {
                  setIsCollapsed(!isCollapsed)
                  await onCloseSuggestion()
                }
              }}
            />
          </Flex>
        </Flex>
      </S.Banner>
    )
  }

  const renderSuggestion = () => {
    return (
      <S.Content>
        {children}
      </S.Content>
    )
  }

  const renderFooter = () => {
    return (
      <S.Footer>
        <Flex $gap={16}>
          <Button
            disabled={isGeneratingSuggestion}
            $variant="raised"
            $colorTheme="tint"
            $fontSize={12}
            $height={24}
            leadingIcon="circle-arrow-up"
            onClick={() => {
              onApplySuggestion()
            }}
          >
            Apply suggestion
          </Button>
          {/*
          <Button
            $variant="raised"
            $colorTheme="normal"
            $fontSize={12}
            $height={24}
            leadingIcon={Icons.refreshCw}
            onClick={() => { }}
          >
            Generate again
          </Button>
          */}
        </Flex>
      </S.Footer>
    )
  }

  if (isCollapsed) {
    return (
      <S.EmailSequenceSuggestion isCollapsed={isCollapsed}>
        {renderBanner()}
      </S.EmailSequenceSuggestion>
    )
  }

  return (
    <S.EmailSequenceSuggestion isCollapsed={false}>
      <S.EmailSequenceSuggestionWrapper>
        {renderBanner()}
        {renderSuggestion()}
        {renderFooter()}
      </S.EmailSequenceSuggestionWrapper>
    </S.EmailSequenceSuggestion>
  )
}
