import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { Paragraph } from 'src/components/primitives/typography'
import { Spinner } from 'src/components/primitives/spinner'
import { When } from '../when'
import * as S from './loading-box.styled'
import { useEffect, useState } from 'react'

type Variant = 'raised' | 'solid' | 'dashed'

export interface LoadingBoxStyleProps {
  $variant?: Variant
  $hasGradient?: boolean
  $height?: 'auto' | 'full' | string
}

interface LoadingBoxProps extends LoadingBoxStyleProps {
  icon?: IconName
  animatedIcon?: boolean
  children: React.ReactNode | string
  loadingDelayMS?: number
}

export const LoadingBox = ({
  icon = 'loader',
  animatedIcon = false,
  children,
  $variant = 'raised',
  $height = 'auto',
  $hasGradient = false,
  loadingDelayMS = 1000
}: LoadingBoxProps): JSX.Element => {
  const [loadingDelay, setLoadingDelay] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoadingDelay(false)
    }, loadingDelayMS)
  })

  if (loadingDelay) {
    return <></>
  }

  return (
    <S.LoadingBox $variant={$variant} $height={$height}>
      <S.Icon $animated={animatedIcon}>
        <When condition={icon === 'loader'}>
          <Spinner />
        </When>
        <When condition={icon !== 'loader'}>
          <Icon name={icon} size={24} color={$hasGradient ? 'tintFg' : 'fgSecondary'} />
        </When>
      </S.Icon>
      <Paragraph $color="fgSecondary" $hasGradient={$hasGradient}>
        {children}
      </Paragraph>
    </S.LoadingBox>
  )
}
