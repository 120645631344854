import { SEO } from 'src/components/primitives/seo'
import * as S from './outreach-errors.styled'
import { Caption } from 'src/components/primitives/typography'
import { CandidatesTable } from 'src/components/tables/candidates-table'
import { EmptyState } from 'src/components/blocks/empty-state'
import { ExportToAtsButton, RejectButton, WriteEmailButton } from 'src/components/tables/candidate-table-actions'
import { Suspense } from 'react'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { Await, useLoaderData } from 'react-router-dom'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { Spacer } from 'src/components/primitives/spacer'
import { useChannel } from 'ably/react'
import { EVENT_TYPE } from 'src/libs/api/backend/websockets'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'
import { useAtomValue } from 'jotai'
import { candidateSearchChannelAtom } from 'src/stores/websocket-channels'
import { COLUMN } from 'src/components/tables/candidate-table-cells'
import type { InOutreachErrorsLoaderData } from 'src/libs/loaders/in-outreach'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'

const DEFAULT_TITLE = 'Outreach · Error'

const Header = (): JSX.Element => {
  return (
    <S.OutreachErrorsHeader>
      <Caption as='h1' size='MD' $whiteSpace='nowrap'>
        {DEFAULT_TITLE}
      </Caption>
    </S.OutreachErrorsHeader>
  )
}

const OutreachErrorsPageInner = ({ jobId }: { jobId: string }): JSX.Element => {
  const { data: job } = useJobQuery()
  const { isPending, data: candidateJobs } = useCandidateJobsQuery({
    errored: true
  })

  const candidateSearchChannel = useAtomValue(candidateSearchChannelAtom)
  useChannel({ channelName: candidateSearchChannel, skip: !candidateSearchChannel }, EVENT_TYPE.PROSPECTING_ERROR, (message) => {
    const { jobId: messageJobId } = message.data ?? {}
    if (messageJobId === jobId) {
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs, jobId, { errored: true }]
      })
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobCounts, jobId]
      })
    }
  })

  return (
    <S.OutreachErrorsPageInner>
      <SEO title={DEFAULT_TITLE} />
      <S.OutreachErrorsPageWrapper>
        <S.OutreachErrorsPage>
          <Header />
          <Spacer $size={4} />
          <CandidatesTable
            visibleColumns={[COLUMN.FAVORITE, COLUMN.NAME, COLUMN.ERROR, COLUMN.ERROR_ACTIONS]}
            isLoading={isPending}
            candidateJobs={candidateJobs}
            emptyState={
              <EmptyState
                heading="No candidates"
                description="Looks like you don’t have any prospects yet, start sourcing candidates and any errors will show up right here."
                svg="userCards"
              />
            }
            selectedRowsActions={
              <>
                <RejectButton />
                <WriteEmailButton candidateJobs={candidateJobs} />
                <ExportToAtsButton candidateJobs={candidateJobs} job={job} />
              </>
            }
          />
        </S.OutreachErrorsPage>
      </S.OutreachErrorsPageWrapper>
    </S.OutreachErrorsPageInner>
  )
}

const OutreachErrorsPage = (): JSX.Element => {
  const { jobId, job, candidateJobs } = useLoaderData() as InOutreachErrorsLoaderData
  return (
    <Suspense
      fallback={
        <S.OutreachErrorsPageInner>
          <SEO title={DEFAULT_TITLE} />
          <S.OutreachErrorsPage>
            <Header />
            <Spacer $size={4} />
            <LoadingSkeleton $variant="CandidatesTable" />
          </S.OutreachErrorsPage>
        </S.OutreachErrorsPageInner>
      }>
      <Await resolve={Promise.all([job, candidateJobs])}>
        <OutreachErrorsPageInner jobId={jobId} />
      </Await>
    </Suspense>
  )
}

export default OutreachErrorsPage
