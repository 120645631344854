import { ApplicantsATS } from 'src/components/blocks/applicants-ats'
import { PageHeader } from 'src/components/blocks/page-header'
import { SEO } from 'src/components/primitives/seo'
import { CONTENT_PADDING } from 'src/styles/constants'

const ApplicantsPage = (): JSX.Element => {
  return (
    <>
      <SEO title="Applicants" />
      <div
        style={{
          padding: `20px ${CONTENT_PADDING}`,
          height: '100%',
          overflow: 'auto',
          scrollbarWidth: 'thin',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <PageHeader heading="Applicants" marginBottom={20} />
        <ApplicantsATS />
      </div>
    </>
  )
}

export default ApplicantsPage
