import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import type { Criteria } from 'src/libs/api/backend/candidate_search'
import { createJobSearchRefinement as createJobSearchRefinementApi } from 'src/libs/api/backend/jobs'
import type { Job, JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'
import { notifyErrorAtom } from 'src/stores/notifications'

interface Args {
  jobId: Job['id']
  searchCriteria: Criteria
  onSuccess?: (jobSearchRefinement: JobSearchRefinement) => void
  onError?: () => void
}

interface Res {
  createJobSearchRefinement: (args: Args) => void
}

export const useCreateJobSearchRefinement = (): Res => {
  const queryClient = useQueryClient()
  const notifyError = useSetAtom(notifyErrorAtom)

  const mutation = useMutation({
    mutationFn: async ({ jobId, searchCriteria }: Args) =>
      await createJobSearchRefinementApi(jobId, searchCriteria),
    onError: (err, variables) => {
      notifyError({
        message: `An error occurred when creating your search: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (jobSearchRefinement, variables) => {
      queryClient.setQueriesData<JobSearchRefinement[]>(
        {
          queryKey: [queryKeys.jobSearchRefinements, variables.jobId]
        },
        (oldJobSearchRefinements) => {
          if (!oldJobSearchRefinements) {
            return [jobSearchRefinement]
          }
          return [...oldJobSearchRefinements, jobSearchRefinement]
        }
      )
      if (variables.onSuccess) {
        variables.onSuccess(jobSearchRefinement)
      }
    }
  })

  const createJobSearchRefinement = (args: Args): void => {
    mutation.mutate(args)
  }

  return { createJobSearchRefinement }
}
