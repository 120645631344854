import { useMemo } from 'react'
import { Button } from 'src/components/primitives'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Icons } from 'src/components/primitives/icon'
import type { CriteriaType } from '../constants'

interface CriteriaSelectionProps {
  value: boolean
  onUpdate: (key: string, value: boolean) => void
  disabled: boolean
  fullWidth?: boolean
  type?: CriteriaType
}
export const CriteriaSelection = ({ value, onUpdate, disabled, fullWidth = false, type }: CriteriaSelectionProps): JSX.Element => {
  const options = useMemo(() => {
    if (type === 'boolean-value') {
      return ([
        {
          id: 'yes',
          title: 'Yes',
          onSelect: () => {
            onUpdate('value', true)
          }
        },
        {
          id: 'no',
          title: 'No',
          onSelect: () => {
            onUpdate('value', false)
          }
        }
      ])
    }
    return ([
      {
        id: 'must-have',
        title: 'Must have',
        onSelect: () => {
          onUpdate('optional', false)
        }
      },
      {
        id: 'prefer',
        title: 'Preferred',
        onSelect: () => {
          onUpdate('optional', true)
        }
      }
    ])
  }, [onUpdate, type])
  const valueLabel = useMemo(() => {
    if (type === 'boolean-value') {
      return value ? 'Yes' : 'No'
    }
    return value ? 'Preferred' : 'Must have'
  }, [value, type])

  return (
    <Dropdown
      $minWidth='160px'
      $menuWidth={fullWidth ? 'full' : 'auto'}
      triggerFullWidth={fullWidth}
      size='small'
      disabled={disabled}
      fontWeight={400}
      trigger={
        <Button
          nested
          $variant='outline'
          $width={fullWidth ? 'full' : 160}
          $height={32}
          $fontSize={12}
          $fontWeight={400}
          trailingIcon={Icons.chevronsUpDownSmall}
          $align='space-between'
          disabled={disabled}
        >
          {valueLabel}
        </Button>
      }
      items={options}
    />
  )
}
