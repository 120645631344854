import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import type { CalendarEvents, FetchCalendarEventsRequestArgs } from 'src/libs/api/backend/calendar_events'
import { fetchCalendarEvents } from 'src/libs/api/backend/calendar_events'
import { queryKeys } from 'src/libs/query-keys'
import { useSession } from './use-session'
import { useQueryParams } from '../use-query-params'

interface Args extends Pick<FetchCalendarEventsRequestArgs, 'requestedCalendars'> {
}

export const useGetCalendarEvents = ({ requestedCalendars }: Args = {}): UseQueryResult<CalendarEvents> => {
  const { data: sessionData } = useSession()

  const { getParam } = useQueryParams()
  const startDateTime = getParam('startDateTime')
  const endDateTime = getParam('endDateTime')
  const userQueryKeyId = requestedCalendars?.[0] ?? sessionData?.user.id

  return useQuery({
    queryKey: [queryKeys.calendar, userQueryKeyId, { startDateTime, endDateTime }],
    queryFn: async () => await fetchCalendarEvents({ requestedCalendars, startDate: startDateTime, endDate: endDateTime }),
    staleTime: 60 * 1000,
    enabled: !!startDateTime && !!endDateTime && !!userQueryKeyId
  })
}
