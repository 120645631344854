import type { MergeIntegration } from 'src/libs/api/backend/merge'
import { useSession } from 'src/hooks/use-session'
import { useMergeIntegrationsListQuery } from './queries/use-merge-integrations-list'
import { useMemo } from 'react'
import { ATS_INTEGRATION_APP_URLS } from 'src/libs/data'

interface ReturnType extends MergeIntegration {
  appUrl: string | null
}

export const useConnectedMergeIntegration = (): ReturnType | null => {
  const { org } = useSession()
  const { data: mergeIntegrations = [] } = useMergeIntegrationsListQuery()

  const findConnectedIntegration = useMemo(() => {
    if (!org?.mergeAtsIntegration || !mergeIntegrations.length) return null

    const connectedIntegration = mergeIntegrations.find((integration) => integration.slug === org.mergeAtsIntegration)

    if (!connectedIntegration) return null

    const appUrlEntry = ATS_INTEGRATION_APP_URLS.find(
      (entry) => entry.mergeIntegrationSlug === connectedIntegration.slug
    )

    return {
      ...connectedIntegration,
      appUrl: appUrlEntry?.url ?? null
    }
  }, [org?.mergeAtsIntegration, mergeIntegrations])

  return findConnectedIntegration
}
