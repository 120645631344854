import { z } from 'zod'
import Api from '..'
import { emailAccountAccessTokenParser } from './session'
import type { EmailAccount } from './session'

const userParser = z.object({
  id: z.string().uuid(),
  name: z.string(),
  timezone: z.string().nullish(),
  profilePhoto: z.string().nullish()
})

export type User = z.infer<typeof userParser>

export interface ProfilePhoto {
  sourceKey: string
}

export interface MeInfo {
  name?: string | null
  timezone?: string | null
  profilePhoto?: string | null
  newProfilePhoto?: ProfilePhoto | null
}

export async function updateMe (data: MeInfo): Promise<User> {
  const res = await Api.put<User>('/me', null, data)
  const result = userParser.parse(res.data)
  return result
}

export async function getMe (): Promise<User> {
  const res = await Api.get<User>('/me')
  const result = userParser.parse(res.data)
  return result
}

export async function sendLocalTimezone (): Promise<void> {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    await Api.put('/me/timezone', null, { timezone })
  } catch (e) {
    console.warn('Failed to send local timezone', e)
  }
}

export async function deleteEmailAccount (emailAccountId: string): Promise<EmailAccount[]> {
  const res = await Api.delete(`/me/email_account/${emailAccountId}`)
  const result = z.array(emailAccountAccessTokenParser).parse(res.data)
  return result
}

interface TeamSendAsPermission {
  createdAt: string
  updatedAt: string
  orgId: string
  userId: string
  emailAccountId: string
  teamSendAsPermission: boolean
}

export async function setEmailAccountTeamSendAsPermission (emailAccountId: string, teamSendAsPermission: boolean): Promise<TeamSendAsPermission> {
  const res = await Api.put<TeamSendAsPermission>(`/me/email_account/${emailAccountId}/team_send_as_permission`, null, { teamSendAsPermission })
  return res.data
}

export interface StartEndTime {
  start: Date
  end: Date
}

interface GetMentionedTimesAvailabilityArgs {
  startEndTimes: StartEndTime[]
}

const mentionedTimeFirstAvailabilityParser = z.object({
  firstAvailability: z.object({
    start: z.coerce.date(),
    end: z.coerce.date()
  }).nullable()
})

export async function getMentionedTimesAvailability ({ startEndTimes }: GetMentionedTimesAvailabilityArgs): Promise<StartEndTime | null> {
  if (!startEndTimes.length) {
    return null
  }
  const { data } = await Api.post('/me/calendar/first_availability', null, {
    startEndTimes
  })
  const { firstAvailability } = mentionedTimeFirstAvailabilityParser.parse(data)
  return firstAvailability
}

interface UpdateEmailAccountSignatureArgs {
  emailAccountId: string
  signature: string
}

export async function updateEmailAccountSignature ({ emailAccountId, signature }: UpdateEmailAccountSignatureArgs): Promise<EmailAccount> {
  const res = await Api.put<EmailAccount>(`/me/email_account/${emailAccountId}/signature`, null, { signature })
  return res.data
}
