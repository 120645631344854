import { useQuery } from '@tanstack/react-query'
import { fetchUserNotifications } from 'src/libs/api/backend/notifications'
import { queryKeys } from 'src/libs/query-keys'

export const userNotificationsQuery = () => ({
  queryKey: [queryKeys.notifications],
  queryFn: async () => await fetchUserNotifications(),
  staleTime: 60 * 1000
})

export const useUserNotificationsQuery = () => {
  return useQuery(userNotificationsQuery())
}
