import type { CriteriaKey, CriteriaValue, StandardCriteria } from 'src/libs/api/backend/candidate_search'
import * as S from './index.styled'
import { RemoveCriteria } from './remove-criteria'
import { MultiSelect } from './multi-select-criteria'

interface StandardArrayRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: StandardCriteria[]
  onCriteriaUpdate: (criteriaKey: CriteriaKey, criteriaValue: CriteriaValue) => void
  onCriteriaRemove: (criteriaKey: CriteriaKey) => void
  disabled: boolean
}

export const StandardSelectionArrayRow = ({
  criteriaKey,
  criteriaValue,
  onCriteriaUpdate,
  onCriteriaRemove,
  disabled
}: StandardArrayRowProps): JSX.Element => {
  return (
    <S.RowContainer>
      <MultiSelect
        criteriaKey={criteriaKey}
        selectedItems={criteriaValue}
        onUpdate={(items) => {
          onCriteriaUpdate(criteriaKey, items)
        }}
      />
      <RemoveCriteria
        onRemove={() => {
          onCriteriaRemove(criteriaKey)
        }}
        disabled={disabled}
      />
    </S.RowContainer>
  )
}
