import { Caption, Paragraph } from 'src/components/primitives/typography'
import * as S from './chart-legend.styled'

interface DataPoint {
  name: string
  color: string
}

export interface ChartLegendStyleProps {
  $orientation?: 'row' | 'column'
}

export interface ChartLegendProps extends ChartLegendStyleProps {
  title?: string
  data: DataPoint[]
}

export const ChartLegend = ({
  title,
  data,
  $orientation = 'row'
}: ChartLegendProps): JSX.Element => {
  return (
    <S.Legend>
      {title && (
        <Caption size="SM" $color="fgSecondary">
          {title}
        </Caption>
      )}
      <S.LegendInner $orientation={$orientation}>
        {data.map((dataPoint) => (
          <li key={dataPoint.name}>
            <S.Icon $color={dataPoint.color} />
            <Paragraph size="XS" $color="fgPrimary">
              {dataPoint.name}
            </Paragraph>
          </li>
        ))}
      </S.LegendInner>
    </S.Legend>
  )
}
