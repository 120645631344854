import { useMutation } from '@tanstack/react-query'
import { sendCustomOutreach as sendCustomOutreachApi } from 'src/libs/api/backend/custom_outreach'
import type { SendCustomOutreachParams } from 'src/libs/api/backend/custom_outreach'
import queryClient from '../query-client'
import { queryKeys } from 'src/libs/query-keys'
import { useSetAtom } from 'jotai'
import { closeDialogAtom, DialogId } from 'src/stores/dialogs'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'
import { Icons } from 'src/components/primitives/icon'

interface Args {
  sendCustomOutreachParams: SendCustomOutreachParams
  onSuccess?: () => void
}

interface Res {
  sendCustomOutreach: ({ sendCustomOutreachParams, onSuccess }: Args) => void
}

export const useSendCustomOutreach = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)
  const closeDialog = useSetAtom(closeDialogAtom)

  const mutation = useMutation({
    mutationFn: async ({ sendCustomOutreachParams }: Args) => {
      await sendCustomOutreachApi(sendCustomOutreachParams)
    },
    onError: (err) => {
      notifyError({
        message: `An error occurred when setting 1st personalized outreach email: ${err.message}`
      })
    },
    onSuccess: async (_data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs]
      })

      if (variables.onSuccess) {
        variables.onSuccess()
      } else {
        closeDialog(DialogId.WRITE_CUSTOM_OUTREACH)
        notifySuccess({
          icon: Icons.mails,
          message: 'Successfully added candidate and set 1st personalized outreach email'
        })
      }
    }
  })

  const sendCustomOutreach = ({ sendCustomOutreachParams, onSuccess }: Args): void => {
    mutation.mutate({ sendCustomOutreachParams, onSuccess })
  }

  return { sendCustomOutreach }
}
