import { useEffect, useState } from 'react'
import { z } from 'zod'

/* This file defines an interface for communicating with the browser extension via window.postMessage. */

// This maps the message name to the response schema for that message.
const schemas = {
  RPC_EVENT_CHECK_LINKEDIN_SIGNED_IN: z.object({
    type: z.literal('RPC_EVENT_CHECK_LINKEDIN_SIGNED_IN'),
    response: z.object({
      isSignedIn: z.boolean()
    })
  }),
  RPC_EVENT_CHECK_EXTENSION_PERMISSIONS: z.object({
    type: z.literal('RPC_EVENT_CHECK_EXTENSION_PERMISSIONS'),
    response: z.object({
      hasCookiesPermission: z.boolean()
    })
  }),
  RPC_EVENT_GET_LINKEDIN_ACCESS_TOKEN: z.object({
    type: z.literal('RPC_EVENT_GET_LINKEDIN_ACCESS_TOKEN'),
    response: z.object({
      accessToken: z.object({
        liAt: z.string().nullish(),
        liA: z.string().nullish()
      })
    })
  }),
  RPC_EVENT_STORE_USER_ID: z.object({
    type: z.literal('RPC_EVENT_STORE_USER_ID'),
    response: z.object({
      success: z.boolean()
    })
  }),
  RPC_EVENT_FETCH_LINKEDIN_ACCESS_TOKEN_AND_PROFILE_DATA: z.object({
    type: z.literal('RPC_EVENT_FETCH_LINKEDIN_ACCESS_TOKEN_AND_PROFILE_DATA'),
    response: z.object({
      accessToken: z.object({
        liAt: z.string().nullish(),
        liA: z.string().nullish()
      }),
      profileData: z.object({
        firstName: z.string().nullable(),
        lastName: z.string().nullable(),
        profilePhotoUrl: z.string().nullable(),
        errors: z.array(z.string()),
        errorHtml: z.string().nullable()
      }).nullable()
    })
  })
}

export type ResponseSchemas<T extends ValidMessage> = z.infer<typeof schemas[T]>['response']

export const validMessages = Object.keys(schemas) as ValidMessage[]

type ValidMessage = keyof typeof schemas

export async function sendMessageToExtension<T extends ValidMessage> (
  message: T,
  data?: Record<string, unknown>
): Promise<z.infer<typeof schemas[T]>> {
  return await new Promise((resolve, reject) => {
    const responseChannel = crypto.randomUUID()

    function listener (event: MessageEvent): void {
      if (event.source !== window) return
      if (event.data?.source !== responseChannel) return
      const parser = schemas[message]
      try {
        const parsedResponse = parser.parse(event.data)
        resolve(parsedResponse)
      } catch (error) {
        console.error('[sendMessageToExtension] Error:', error)
        console.error('[sendMessageToExtension] Error Data:', event.data)
        reject(error)
      }
      window.removeEventListener('message', listener)
    }

    window.addEventListener('message', listener)
    window.postMessage({ msgSource: 'frontend', type: message, responseChannel, data }, '*')
  })
}

export const storeUserId = async (userId: string): Promise<{ success: boolean }> => {
  const { response: { success } } = await sendMessageToExtension('RPC_EVENT_STORE_USER_ID', { userId })
  return { success }
}

export const useLinkedInSignedInStatus = (): boolean | null => {
  const [isSignedIn, setIsSignedIn] = useState<boolean | null>(null)

  useEffect(() => {
    const checkSignedInStatus = (): void => {
      sendMessageToExtension('RPC_EVENT_CHECK_LINKEDIN_SIGNED_IN').then((response) => {
        setIsSignedIn(response.response.isSignedIn)
      }).catch((error) => {
        console.error('[useLinkedInSignedInStatus] Error:', error)
      })
    }

    // Run the check immediately when the content script is loaded
    checkSignedInStatus()

    const interval = setInterval(checkSignedInStatus, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return isSignedIn
}

// This is to check if the extension has the necessary permissions
interface ExtensionPermissions {
  hasCookiesPermission: boolean
}
export const useExtensionHasPermissions = (): ExtensionPermissions => {
  const [hasPermissions, setHasPermissions] = useState<ExtensionPermissions>({
    hasCookiesPermission: false
  })

  useEffect(() => {
    const checkPermissions = (): void => {
      sendMessageToExtension('RPC_EVENT_CHECK_EXTENSION_PERMISSIONS').then((response) => {
        setHasPermissions({
          hasCookiesPermission: response.response.hasCookiesPermission
        })
      }).catch((error) => {
        console.error('[useExtensionHasPermissions] Error:', error)
      })
    }

    // Run the check immediately when the content script is loaded
    checkPermissions()

    const interval = setInterval(checkPermissions, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return hasPermissions
}

// The content script will create a div with id 'content-script-rpc-ready'
// when it is ready to receive messages. This is to prevent a race
// condition where the frontend sends a message before the content script
// has loaded and the message is missed.
export const useContentScriptHasLoaded = (): boolean => {
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    const checkContentScriptLoaded = (): void => {
      const marker = document.getElementById('content-script-rpc-ready')
      if (marker) {
        setHasLoaded(true)
      } else {
        setTimeout(checkContentScriptLoaded, 100)
      }
    }

    checkContentScriptLoaded()
  }, [])

  return hasLoaded
}
