import styled, { keyframes, css } from 'styled-components'
import * as RadixDialog from '@radix-ui/react-dialog'
import type { DialogStyleProps } from './dialog'
import { DIALOG_HEADER_HEIGHT_LG, NAV_HEIGHT_LG } from 'src/styles/constants'

const DIALOG_SIZE = {
  WIDTH: {
    SETTINGS: '984px',
    FULL: '100vw',
    ALMOST_FULL: 'calc(100vw - 1.5rem)',
    LARGE: '82vw'
  },
  HEIGHT: {
    FULL: '100vh',
    ALMOST_FULL: 'calc(100vh - 1.5rem)',
    INNER_PAGE: `calc(100vh - ${NAV_HEIGHT_LG} - 0.75rem)`,
    HALF: '50vh',
    LARGE: '82vh',
    MEDIUM: '70vh'
  }
}

const DIALOG_TOP_OFFSET = {
  LG: '80px'
}

const getDialogWidth = ($width: DialogStyleProps['$width']): string => {
  switch ($width) {
    case 'large':
      // return DIALOG_SIZE.WIDTH.LARGE
      return '1280px'
    case 'full':
      return DIALOG_SIZE.WIDTH.FULL
    case 'almostFull':
      return DIALOG_SIZE.WIDTH.ALMOST_FULL
    default:
      if ($width && ($width?.includes('px') || $width?.includes('%'))) {
        return $width
      }
      return '50vw'
  }
}

const getDialogMaxWidth = (
  $width: DialogStyleProps['$width'],
  $maxWidth: DialogStyleProps['$maxWidth']
): string => {
  switch ($width) {
    case 'full':
      return '100%'
    case 'almostFull':
      return DIALOG_SIZE.WIDTH.ALMOST_FULL
    case 'large':
      return DIALOG_SIZE.WIDTH.LARGE
    default:
      return $maxWidth ?? '480px'
  }
}

const getDialogHeight = ($height: DialogStyleProps['$height']): string => {
  switch ($height) {
    case 'full':
      return DIALOG_SIZE.HEIGHT.FULL
    case 'almostFull':
      return DIALOG_SIZE.HEIGHT.ALMOST_FULL
    case 'innerPage':
      return DIALOG_SIZE.HEIGHT.INNER_PAGE
    case 'large':
      return DIALOG_SIZE.HEIGHT.LARGE
    case 'half':
      return DIALOG_SIZE.HEIGHT.HALF
    default:
      return $height?.includes('px') ? $height : 'auto'
  }
}

const getDialogMaxHeight = ($height: DialogStyleProps['$height']): string => {
  switch ($height) {
    case 'large':
      return '1100px'
    default:
      return 'fit-content'
  }
}

const overlayShow = keyframes`
  0% { opacity: 0; }
  100% { opacity: 0.45; }
`

const dialogShow = (x: string, y: string) => keyframes`
  0% { opacity: 0; transform: translate3d(${x}, ${y}, 0) scale(0.98); }
  100% { opacity: 1; transform: translate3d(${x}, ${y}, 0) scale(1); }
`

export const dialogShowCss = (x: string, y: string) => css`
  animation: ${dialogShow(x, y)} 0.25s cubic-bezier(0.16, 1, 0.3, 1);
`

const getDialogTransform = ($position: string) => {
  switch ($position) {
    case 'top':
      return {
        x: '-50%',
        y: '0'
      }
    case 'right':
      return {
        x: '0%',
        y: '-50%'
      }
    default:
      return {
        x: '-50%',
        y: '-50%'
      }
  }
}

export const DialogContent = styled(RadixDialog.Content)<DialogStyleProps>`
  position: fixed;
  top: ${({ $height, $position }) =>
    $position === 'top' ? DIALOG_TOP_OFFSET.LG : $height === 'innerPage' ? 'calc(50%)' : '50%'};
  left: ${({ $position }) => ($position === 'right' ? 'unset' : '50%')};
  right: ${({ $position }) => ($position === 'right' ? '0.75rem' : 'unset')};
  width: ${({ $width }) => getDialogWidth($width)};
  max-width: ${({ $width, $maxWidth }) => getDialogMaxWidth($width, $maxWidth)};
  height: ${({ $height }) => getDialogHeight($height)};
  min-height: ${({ $minHeight }) => $minHeight};
  max-height: ${({ $height }) => getDialogMaxHeight($height)};
  border-radius: ${({ $width, $height }) => $width === 'full' && $height === 'full' ? '0' : '8px'};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1), 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  transform: ${({ $position }) =>
    $position === 'top'
      ? 'translate(-50%, 0)'
      : $position === 'right'
      ? 'translate(0, -50%)'
      : 'translate(-50%, -50%)'};
  overflow: hidden;
  z-index: ${({ $zIndex }) => $zIndex};
  ${({ $position }) =>
    dialogShowCss(
      getDialogTransform($position ?? 'right').x,
      getDialogTransform($position ?? 'right').y
    )};
`

export const Trigger = styled(RadixDialog.Trigger)`
  position: relative;
  border: none;
  background-color: transparent;
`

export const DialogContentInner = styled.div`
  height: 100%;
  position: relative;
  z-index: 2;
`

interface MainProps extends Pick<DialogStyleProps, '$innerPadding'> {
  $hasHeader: boolean
}

export const Main = styled.div<MainProps>`
  position: relative;
  /* height: calc(100% - ${DIALOG_HEADER_HEIGHT_LG}); */
  height: ${({ $hasHeader }) => $hasHeader ? `calc(100% - ${DIALOG_HEADER_HEIGHT_LG})` : '100%'};
  padding-top: ${({ $innerPadding, theme }) => theme.spacing[$innerPadding?.top ?? 0]};
  padding-right: ${({ $innerPadding, theme }) => theme.spacing[$innerPadding?.right ?? 0]};
  padding-bottom: ${({ $innerPadding, theme }) => theme.spacing[$innerPadding?.bottom ?? 0]};
  padding-left: ${({ $innerPadding, theme }) => theme.spacing[$innerPadding?.left ?? 0]};
  /* overflow: auto; */
`

export const CloseTrigger = styled(RadixDialog.Close)`
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  border: none;
  background-color: transparent;
  width: 1rem;
  height: 1rem;
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 1.25rem 1.5rem 1rem 1.5rem;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  height: ${DIALOG_HEADER_HEIGHT_LG};
`

export const Actions = styled.div<{ $justify: string, divider: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ $justify }) => $justify};
  gap: 0.5rem;
  padding: 1rem 1.5rem;
  width: 100%;
  border-top: ${({ divider, theme }) => (divider)
    ? `1px solid ${theme.colors.borderTranslucent}`
    : 'none'
  };
`

export const DialogOverlay = styled.div<{ $zIndex: number }>`
  position: fixed;
  background-color: ${({ theme }) => theme.colors.fgPrimary};
  opacity: 0.45;
  inset: 0;
  z-index: ${({ $zIndex }) => $zIndex};
  animation: ${overlayShow} 0.25s cubic-bezier(0.16, 1, 0.3, 1);
  width: 100vw;
  height: 100vh;
`
