import type { Spacing } from 'src/styles/theme/types'
import * as S from './chart-wrapper.styled'

export interface ChartWrapperStyleProps {
  $padding?: {
    top?: Spacing
    right?: Spacing
    bottom?: Spacing
    left?: Spacing
  }
  $variant?: 'raised' | 'solid'
}

interface ChartWrapperProps extends ChartWrapperStyleProps {
  children: React.ReactNode
}

export const ChartWrapper = ({
  children,
  $variant = 'raised',
  $padding = { top: 24, right: 24, bottom: 24, left: 24 }
}: ChartWrapperProps): JSX.Element => {
  const innerPadding =
    $variant === 'raised'
      ? $padding
      : { top: 0 as Spacing, right: 0 as Spacing, bottom: 0 as Spacing, left: 0 as Spacing }

  return (
    <S.ChartWrapper $variant={$variant} $padding={innerPadding}>
      {children}
    </S.ChartWrapper>
  )
}
