import Image from '@tiptap/extension-image'
import { Plugin } from 'prosemirror-state'
import { MAX_INLINE_IMAGE_SIZE } from '../types'
import { compressAndGenerateUrl, getCompressedQuality } from 'src/utils/compress-image'

export default Image
  .configure({
    allowBase64: true
  })
  .extend({
    addProseMirrorPlugins () {
      return [
        new Plugin({
          props: {
            handleDOMEvents: {
              drop (view, event) {
                const hasFiles = event.dataTransfer?.files?.length

                if (!hasFiles) {
                  return
                }

                const images = Array.from(event.dataTransfer.files).filter(file =>
                  /image/i.test(file.type)
                )

                if (images.length === 0) {
                  return
                }

                event.preventDefault()

                const { schema } = view.state
                const coordinates = view.posAtCoords({
                  left: event.clientX,
                  top: event.clientY
                })
                images.map(async image => {
                  const quality = getCompressedQuality(image.size, MAX_INLINE_IMAGE_SIZE)
                  const src = await compressAndGenerateUrl({ file: image, quality })
                  if (src && coordinates) {
                    const node = schema.nodes.image.create({
                      src: src.viewSignedUrl,
                      alt: image.name
                    })
                    const transaction = view.state.tr.insert(
                      coordinates.pos,
                      node
                    )
                    view.dispatch(transaction)
                  }
                })
              },
              paste (view, event) {
                const hasFiles = event.clipboardData?.files?.length

                if (!hasFiles) {
                  return
                }

                const images = Array.from(
                  event.clipboardData.files
                ).filter(file => /image/i.test(file.type))

                if (images.length === 0) {
                  return
                }

                event.preventDefault()

                const { schema } = view.state

                images.map(async image => {
                  const quality = getCompressedQuality(image.size, MAX_INLINE_IMAGE_SIZE)
                  const src = await compressAndGenerateUrl({ file: image, quality })
                  if (src) {
                    const node = schema.nodes.image.create({
                      src: src.viewSignedUrl,
                      alt: image.name
                    })
                    const transaction = view.state.tr.replaceSelectionWith(node)
                    view.dispatch(transaction)
                  }
                })
              }
            }
          }
        })
      ]
    }
  })
