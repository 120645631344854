import { Extension } from '@tiptap/core'
import { Plugin } from '@tiptap/pm/state'
import { Decoration, DecorationSet } from '@tiptap/pm/view'
import type { Node } from '@tiptap/pm/model'
import { EmailVariable } from '../types'
import { isNil } from 'lodash'

const emailVariableValues = Object.values(EmailVariable)

const currentEmailVariableValuesSet = new Set(emailVariableValues.map((value) => `{{${value}}}`))
export const pasteRegex = new RegExp(`(${emailVariableValues.map((value) => `{{${value}}}`).join('|')})`, 'gi')

const findVariables = (doc: Node): DecorationSet => {
  const decorations: Decoration[] = []

  doc.descendants((node, position) => {
    if (!node.text) {
      return
    }

    Array.from(node.text.matchAll(pasteRegex)).forEach(match => {
      const variable = match[0]
      const index = match.index ?? 0
      const from = position + index
      const to = from + variable.length
      const decoration = Decoration.inline(from, to, {
        nodeName: 'span',
        class: 'editor-placeholder',
        'data-type': 'variable'
      })

      decorations.push(decoration)
    })
  })

  return DecorationSet.create(doc, decorations)
}

const VariableHighlighter = Extension.create({
  name: 'variableHighlighter',

  addProseMirrorPlugins () {
    return [
      new Plugin({
        state: {
          init (_, { doc }) {
            return findVariables(doc)
          },
          apply (transaction, oldState) {
            return transaction.docChanged ? findVariables(transaction.doc) : oldState
          }
        },
        props: {
          decorations (state) {
            return this.getState(state)
          }
        }
      })
    ]
  },

  addKeyboardShortcuts () {
    return {
      Backspace: () => this.editor.commands.command(({ tr, state }) => {
        let isVariable = false
        const { selection } = state
        const { empty, anchor } = selection
        if (!empty) {
          return false
        }

        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (!isNil(node.text) && currentEmailVariableValuesSet.has(node.text)) {
            isVariable = true
            tr.insertText(
              '',
              pos,
              pos + node.nodeSize
            )

            return false
          }
        })

        return isVariable
      })
    }
  }
})

export default VariableHighlighter