import * as S from './progress-bar.styled'

interface ProgressBarProps {
  progress: number
}

export const ProgressBar = ({ progress }: ProgressBarProps): JSX.Element => {
  return (
    <S.ProgressBar>
      <S.Inner>
        <S.Bar>
          <S.Progress $progress={Math.ceil(progress)} />
        </S.Bar>
      </S.Inner>
    </S.ProgressBar>
  )
}
