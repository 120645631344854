// Uses PDL metros, then uses Claude to title case the metros
// https://github.com/recruitco/ml/blob/main/src/app/common/pdl_data/metros.json

const metros = [
  'New York, New York',
  'Los Angeles, California',
  'Chicago, Illinois',
  'Houston, Texas',
  'Phoenix, Arizona',
  'Philadelphia, Pennsylvania',
  'San Antonio, Texas',
  'San Diego, California',
  'Dallas, Texas',
  'San Jose, California',
  'San Francisco, California',
  'Austin, Texas',
  'Jacksonville, Florida',
  'Columbus, Ohio',
  'Fort Worth, Texas',
  'Indianapolis, Indiana',
  'Charlotte, North Carolina',
  'Seattle, Washington',
  'Denver, Colorado',
  'Washington, District of Columbia',
  'Boston, Massachusetts',
  'El Paso, Texas',
  'Detroit, Michigan',
  'Nashville, Tennessee',
  'Portland, Oregon',
  'Memphis, Tennessee',
  'Oklahoma City, Oklahoma',
  'Las Vegas, Nevada',
  'Louisville, Kentucky',
  'Baltimore, Maryland',
  'Milwaukee, Wisconsin',
  'Albuquerque, New Mexico',
  'Tucson, Arizona',
  'Fresno, California',
  'Sacramento, California',
  'Kansas City, Missouri',
  'Long Beach, California',
  'Mesa, Arizona',
  'Atlanta, Georgia',
  'Colorado Springs, Colorado',
  'Miami, Florida',
  'Raleigh, North Carolina',
  'Omaha, Nebraska',
  'Minneapolis, Minnesota',
  'Tulsa, Oklahoma',
  'Cleveland, Ohio',
  'Wichita, Kansas',
  'New Orleans, Louisiana',
  'Tampa, Florida',
  'St. Louis, Missouri',
  'Pittsburgh, Pennsylvania',
  'Cincinnati, Ohio',
  'Orlando, Florida',
  'Salt Lake City, Utah',
  'Boise City, Idaho',
  'Richmond, Virginia',
  'Spokane, Washington',
  'Baton Rouge, Louisiana',
  'Tacoma, Washington',
  'Lubbock, Texas',
  'Anchorage, Alaska',
  'St. Paul, Minnesota',
  'Greensboro, North Carolina',
  'Lincoln, Nebraska',
  'Buffalo, New York',
  'Reno, Nevada',
  'Madison, Wisconsin',
  'Durham, North Carolina',
  'Winston, North Carolina',
  'Honolulu, Hawaii',
  'Norfolk, Virginia',
  'Chesapeake, Virginia',
  'Scottsdale, Arizona',
  'Akron, Ohio',
  'Lexington, Kentucky',
  'Des Moines, Iowa',
  'Abilene, Texas',
  'Akron, Ohio',
  'Albany, Georgia',
  'Albany, New York',
  'Albany, Oregon',
  'Alexandria, Louisiana',
  'Allentown, Pennsylvania',
  'Altoona, Pennsylvania',
  'Amarillo, Texas',
  'Ames, Iowa',
  'Ann Arbor, Michigan',
  'Anniston, Alabama',
  'Appleton, Wisconsin',
  'Asheville, North Carolina',
  'Athens, Georgia',
  'Atlantic City, New Jersey',
  'Auburn, Alabama',
  'Augusta, Georgia',
  'Bakersfield, California',
  'Bangor, Maine',
  'Barnstable Town, Massachusetts',
  'Battle Creek, Michigan',
  'Bay City, Michigan',
  'Beaumont, Texas',
  'Beckley, West Virginia',
  'Bellingham, Washington',
  'Bend, Oregon',
  'Billings, Montana',
  'Binghamton, New York',
  'Birmingham, Alabama',
  'Bismarck, North Dakota',
  'Blacksburg, Virginia',
  'Bloomington, Illinois',
  'Bloomington, Indiana',
  'Bloomsburg, Pennsylvania',
  'Boulder, Colorado',
  'Bowling Green, Kentucky',
  'Bremerton, Washington',
  'Bridgeport, Connecticut',
  'Brownsville, Texas',
  'Brunswick, Georgia',
  'Burlington, North Carolina',
  'Burlington, Vermont',
  'California, Maryland',
  'Canton, Ohio',
  'Cape Coral, Florida',
  'Cape Girardeau, Missouri',
  'Carbondale, Illinois',
  'Carson City, Nevada',
  'Casper, Wyoming',
  'Cedar Rapids, Iowa',
  'Chambersburg, Pennsylvania',
  'Champaign, Illinois',
  'Charleston, South Carolina',
  'Charleston, West Virginia',
  'Charlottesville, Virginia',
  'Chattanooga, Tennessee',
  'Cheyenne, Wyoming',
  'Chico, California',
  'Clarksville, Tennessee',
  'Cleveland, Tennessee',
  "Coeur d'Alene, Idaho",
  'College Station, Texas',
  'Columbia, Missouri',
  'Columbia, South Carolina',
  'Columbus, Georgia',
  'Columbus, Indiana',
  'Corpus Christi, Texas',
  'Corvallis, Oregon',
  'Crestview, Florida',
  'Cumberland, Maryland',
  'Dalton, Georgia',
  'Danville, Illinois',
  'Daphne, Alabama',
  'Davenport, Iowa',
  'Dayton, Ohio',
  'Decatur, Alabama',
  'Decatur, Illinois',
  'Deltona, Florida',
  'Dothan, Alabama',
  'Dover, Delaware',
  'Dubuque, Iowa',
  'Duluth, Minnesota',
  'East Stroudsburg, Pennsylvania',
  'Eau Claire, Wisconsin',
  'El Centro, California',
  'Elizabethtown, Kentucky',
  'Elkhart, Indiana',
  'Elmira, New York',
  'Enid, Oklahoma',
  'Erie, Pennsylvania',
  'Eugene, Oregon',
  'Evansville, Indiana',
  'Fairbanks, Alaska',
  'Fargo, North Dakota',
  'Farmington, New Mexico',
  'Fayetteville, Arkansas',
  'Fayetteville, North Carolina',
  'Flagstaff, Arizona',
  'Flint, Michigan',
  'Florence, Alabama',
  'Florence, South Carolina',
  'Fond du Lac, Wisconsin',
  'Fort Collins, Colorado',
  'Fort Smith, Arkansas',
  'Fort Wayne, Indiana',
  'Gadsden, Alabama',
  'Gainesville, Florida',
  'Gainesville, Georgia',
  'Gettysburg, Pennsylvania',
  'Glens Falls, New York',
  'Goldsboro, North Carolina',
  'Grand Forks, North Dakota',
  'Grand Island, Nebraska',
  'Grand Junction, Colorado',
  'Grand Rapids, Michigan',
  'Grants Pass, Oregon',
  'Great Falls, Montana',
  'Greeley, Colorado',
  'Green Bay, Wisconsin',
  'Greenville, North Carolina',
  'Greenville, South Carolina',
  'Gulfport, Mississippi',
  'Hagerstown, Maryland',
  'Hammond, Louisiana',
  'Hanford, California',
  'Harrisburg, Pennsylvania',
  'Harrisonburg, Virginia',
  'Hartford, Connecticut',
  'Hattiesburg, Mississippi',
  'Hickory, North Carolina',
  'Hilton Head Island, South Carolina',
  'Hinesville, Georgia',
  'Homosassa Springs, Florida',
  'Hot Springs, Arkansas',
  'Houma, Louisiana',
  'Huntington, West Virginia',
  'Huntsville, Alabama',
  'Idaho Falls, Idaho',
  'Iowa City, Iowa',
  'Ithaca, New York',
  'Jackson, Michigan',
  'Jackson, Mississippi',
  'Jackson, Tennessee',
  'Jacksonville, North Carolina',
  'Janesville, Wisconsin',
  'Jefferson City, Missouri',
  'Johnson City, Tennessee',
  'Johnstown, Pennsylvania',
  'Jonesboro, Arkansas',
  'Joplin, Missouri',
  'Kahului, Hawaii',
  'Kalamazoo, Michigan',
  'Kankakee, Illinois',
  'Kennewick, Washington',
  'Killeen, Texas',
  'Kingsport, Tennessee',
  'Kingston, New York',
  'Knoxville, Tennessee',
  'Kokomo, Indiana',
  'La Crosse, Wisconsin',
  'Lafayette, Indiana',
  'Lafayette, Louisiana',
  'Lake Charles, Louisiana',
  'Lake Havasu City, Arizona',
  'Lakeland, Florida',
  'Lancaster, Pennsylvania',
  'Lansing, Michigan',
  'Laredo, Texas',
  'Las Cruces, New Mexico',
  'Lawrence, Kansas',
  'Lawton, Oklahoma',
  'Lebanon, Pennsylvania',
  'Lewiston, Idaho',
  'Lewiston, Maine',
  'Lima, Ohio',
  'Little Rock, Arkansas',
  'Logan, Utah',
  'Longview, Texas',
  'Longview, Washington',
  'Lubbock, Texas',
  'Lynchburg, Virginia',
  'Macon, Georgia',
  'Madera, California',
  'Manchester, New Hampshire',
  'Manhattan, Kansas',
  'Mankato, Minnesota',
  'Mansfield, Ohio',
  'McAllen, Texas',
  'Medford, Oregon',
  'Merced, California',
  'Michigan City, Indiana',
  'Midland, Michigan',
  'Midland, Texas',
  'Missoula, Montana',
  'Mobile, Alabama',
  'Modesto, California',
  'Monroe, Louisiana',
  'Monroe, Michigan',
  'Montgomery, Alabama',
  'Morgantown, West Virginia',
  'Morristown, Tennessee',
  'Mount Vernon, Washington',
  'Muncie, Indiana',
  'Muskegon, Michigan',
  'Myrtle Beach, South Carolina',
  'Napa, California',
  'Naples, Florida',
  'New Bern, North Carolina',
  'New Haven, Connecticut',
  'Niles, Michigan',
  'North Port, Florida',
  'Norwich, Connecticut',
  'Ocala, Florida',
  'Ocean City, New Jersey',
  'Odessa, Texas',
  'Ogden, Utah',
  'Olympia, Washington',
  'Oshkosh, Wisconsin',
  'Owensboro, Kentucky',
  'Oxnard, California',
  'Palm Bay, Florida',
  'Panama City, Florida',
  'Parkersburg, West Virginia',
  'Pensacola, Florida',
  'Peoria, Illinois',
  'Pine Bluff, Arkansas',
  'Pittsfield, Massachusetts',
  'Pocatello, Idaho',
  'Port St. Lucie, Florida',
  'Portland, Maine',
  'Poughkeepsie, New York',
  'Prescott Valley, Arizona',
  'Providence, Rhode Island',
  'Provo, Utah',
  'Pueblo, Colorado',
  'Punta Gorda, Florida',
  'Racine, Wisconsin',
  'Rapid City, South Dakota',
  'Reading, Pennsylvania',
  'Redding, California',
  'Rochester, Minnesota',
  'Rochester, New York',
  'Rockford, Illinois',
  'Rocky Mount, North Carolina',
  'Rome, Georgia',
  'Saginaw, Michigan',
  'Salem, Oregon',
  'Salinas, California',
  'Salisbury, Maryland',
  'San Angelo, Texas',
  'San Luis Obispo, California',
  'Santa Cruz, California',
  'Santa Fe, New Mexico',
  'Santa Maria, California',
  'Santa Rosa, California',
  'Savannah, Georgia',
  'Scranton, Pennsylvania',
  'Sebastian, Florida',
  'Sebring, Florida',
  'Sheboygan, Wisconsin',
  'Sherman, Texas',
  'Shreveport, Louisiana',
  'Sierra Vista, Arizona',
  'Sioux City, Iowa',
  'Sioux Falls, South Dakota',
  'South Bend, Indiana',
  'Spartanburg, South Carolina',
  'Springfield, Illinois',
  'Springfield, Massachusetts',
  'Springfield, Missouri',
  'Springfield, Ohio',
  'St. Cloud, Minnesota',
  'St. George, Utah',
  'St. Joseph, Missouri',
  'State College, Pennsylvania',
  'Staunton, Virginia',
  'Stockton, California',
  'Sumter, South Carolina',
  'Syracuse, New York',
  'Tallahassee, Florida',
  'Terre Haute, Indiana',
  'Texarkana, Texas',
  'The Villages, Florida',
  'Toledo, Ohio',
  'Topeka, Kansas',
  'Trenton, New Jersey',
  'Twin Falls, Idaho',
  'Tyler, Texas',
  'Urban Honolulu, Hawaii',
  'Utica, New York',
  'Valdosta, Georgia',
  'Vallejo, California',
  'Victoria, Texas',
  'Vineland, New Jersey',
  'Virginia Beach, Virginia',
  'Visalia, California',
  'Waco, Texas',
  'Walla Walla, Washington',
  'Warner Robins, Georgia',
  'Waterloo, Iowa',
  'Watertown, New York',
  'Wausau, Wisconsin',
  'Weirton, West Virginia',
  'Wenatchee, Washington',
  'Wheeling, West Virginia',
  'Wichita Falls, Texas',
  'Williamsport, Pennsylvania',
  'Wilmington, North Carolina',
  'Winchester, Virginia',
  'Worcester, Massachusetts',
  'Yakima, Washington',
  'York, Pennsylvania',
  'Youngstown, Ohio',
  'Yuba City, California',
  'Yuma, Arizona'
]

export const locations = metros.map((metro) => ({ value: metro }))
