import { CompaniesRatings } from 'src/components/blocks/companies-ratings'
import { PageHeader } from 'src/components/blocks/page-header'
import { Spacer } from 'src/components/primitives'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { SEO } from '../../../components/primitives/seo'

const CompaniesRatingsPage = (): JSX.Element => {
  const { data: jobData } = useJobQuery()

  return (
    <>
      <PageHeader
        heading={`Companies Rating for ${jobData?.title} Job`}
        subheading="Rate companies to indicate if you'd like to see more or fewer candidates that worked at them."
      />
      <SEO title={'Company Ratings'} />

      <Spacer $size={20} />
      <CompaniesRatings />
    </>
  )
}

export default CompaniesRatingsPage
