import styled from 'styled-components'

export const Editor = styled.div<{ $isEditable: boolean }>`
  [contenteditable] ~ grammarly-extension {
    display: none;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  * {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    line-height: ${({ theme }) => theme.lineHeights['1.43']};
    letter-spacing: ${({ theme }) => theme.letterSpacings['0.12']};
    outline: none;
    .editor-placeholder {
      position: relative;
      background-color: transparent;
      font-size: ${({ theme }) => theme.fontSizes[12]};
      font-weight: ${({ theme }) => theme.fontWeights[400]};
      color: ${({ theme, $isEditable }) =>
        $isEditable ? theme.colors.fgSecondary : theme.colors.fgTertiary};
      margin: 0 0.35rem 0 0.25rem;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: -1px;
        left: -4px;
        width: calc(100% + 6px);
        height: calc(100% + 2px);
        border-radius: 4px;
        border: solid 1px;
        border-color: ${({ theme }) => theme.colors.fgTranslucent10};
        pointer-events: none;
      }
    }
    div.is-editor-empty:first-child::before {
      font-size: ${({ theme }) => theme.fontSizes[12]};
      font-weight: ${({ theme }) => theme.fontWeights[400]};
      color: ${({ theme }) => theme.colors.fgTertiary};
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
`

export const EditorContent = styled.div<{
  $editorHeight?: string
  $isEditable?: boolean
  $padding?: string
  $isEmpty?: boolean
}>`
  padding: ${({ $padding }) => $padding ?? '0.75rem'};
  overflow-y: auto;
  scrollbar-width: none;
  flex-grow: 1;
  min-height: inherit;
  max-height: inherit;
  height: ${({ $editorHeight }) => $editorHeight ?? '0'};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  color: ${({ $isEditable, $isEmpty, theme }) =>
    $isEditable
      ? $isEmpty
        ? theme.colors.fgTertiary
        : theme.colors.fgPrimary
      : theme.colors.fgTertiary};
`
