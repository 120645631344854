import { useEffect, useState } from 'react'
import { StatusView } from '../status-view'
import { ProgressStepsList } from 'src/components/primitives/progress-steps-list'
import { ProgressBar } from 'src/components/primitives/progress-bar'

const TOTAL_TIME = 50 * 1000 // search takes 60 seconds on avg, show 50s to avoid flashing progress

interface RecommendingViewProps {
  lastRequestedAt: Date
}

export const RecommendingView = ({ lastRequestedAt }: RecommendingViewProps): JSX.Element => {
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      const elapsedTime = Date.now() - lastRequestedAt.getTime()
      const progressPercentage = Math.min(Math.max((elapsedTime / TOTAL_TIME) * 100, 0), 100)
      setProgress(progressPercentage)
    }, 2000)
    return () => {
      clearInterval(interval)
    }
  }, [lastRequestedAt])

  return (
    <StatusView
      pin="searching"
      animatePin
      heading="Searching candidates…"
    >
      <ProgressBar progress={progress} />
      <ProgressStepsList progress={progress} />
    </StatusView>
  )
}
