import styled, { keyframes } from 'styled-components'

const spinning = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const Spinner = styled.span`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  svg {
    animation: ${spinning} 0.5s linear infinite;
  }
`
