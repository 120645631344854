import * as S from './progress-steps-list.styled'
import { Flex } from '../flex'
import { Caption } from '../typography'
import { Icon } from '../icon'
import type { IconName } from '../icon'
import type { Color } from 'src/styles/theme/types'
import { Spinner } from '../spinner'

interface ProgressStep {
  text: string
  percentage: number
}

const STEPS: ProgressStep[] = [
  {
    text: 'Analyzing job description',
    percentage: 10
  },
  {
    text: 'Filtering through 100M+ profiles',
    percentage: 80
  },
  {
    text: 'Ranking expertise and fit',
    percentage: 95
  },
  {
    text: 'Preparing results',
    percentage: 101
  }
]

const getStatus = (progress: number, currentStep: ProgressStep, previousStep?: ProgressStep): { icon: IconName, color: Color } => {
  const currentStepProgress = currentStep.percentage
  if (progress > currentStepProgress) {
    return {
      icon: 'check-circle-2',
      color: 'positiveFg'
    }
  }
  const previousStepProgress = previousStep?.percentage ?? 0
  if (progress > previousStepProgress) {
    return {
      icon: 'loader',
      color: 'fgSecondary'
    }
  }

  return {
    icon: 'circle-dashed',
    color: 'fgSecondary'
  }
}

interface ProgressStepsListProps {
  progress: number
}

export const ProgressStepsList = ({ progress }: ProgressStepsListProps): JSX.Element => {
  return (
    <S.Wrapper>
      <S.List>
        {STEPS.map((step, index) => {
          const { icon, color } = getStatus(progress, step, STEPS[index - 1])
          return (
            <Flex key={step.text} $align="center" $gap={8}>
              {icon === 'loader'
                ? (<Spinner color="fgSecondary" />)
                : (<Icon name={icon} color={color} />)
              }
              <Caption size="XS" $color={color}>
                {step.text}
              </Caption>
            </Flex>
          )
        })}
      </S.List>
    </S.Wrapper>
  )
}
