import styled from 'styled-components'
import type { ToggleStyleProps } from './toggle'

interface ToggleButtonProps extends ToggleStyleProps {
  $isChecked: boolean
  $isDisabled?: boolean
}

export const ToggleButton = styled.button<ToggleButtonProps>`
  all: unset;
  position: relative;
  flex: ${({ $toggleSize }) => ($toggleSize ? `0 0 ${($toggleSize / 16) * 1.75}rem` : '0 0 28px')};
  width: ${({ $toggleSize }) => ($toggleSize ? `${$toggleSize * 1.75}px` : '28px')};
  height: ${({ $toggleSize, theme }) => theme.spacing[$toggleSize ?? 16]};
  background-color: ${({ theme, $isChecked }) =>
    $isChecked ? theme.colors.tintBg : theme.colors.fgTranslucent10};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 9999px;
  border: solid 1px;
  border-color: ${({ theme, $isChecked }) => ($isChecked ? theme.colors.tintBg : 'transparent')};
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.2 : 1)};
  @media (hover: hover) {
    &:hover {
      border-color: ${({ theme, $isChecked }) =>
        $isChecked ? theme.colors.tintBg : theme.colors.borderTranslucent};
    }
  }
  &:focus {
    &:after {
      content: '';
      position: absolute;
      top: -0.25rem;
      left: -0.25rem;
      width: calc(100% + 0.25rem);
      height: calc(100% + 0.25rem);
      border: solid 2px;
      border-color: ${({ theme }) => theme.colors.tintBg};
      border-radius: 9999px;
      pointer-events: none;
      z-index: 2;
    }
  }
`

export const ToggleHandle = styled.span<ToggleButtonProps>`
  display: block;
  width: ${({ $toggleSize }) => ($toggleSize ? `${$toggleSize - 4}px` : '12px')};
  height: ${({ $toggleSize }) => ($toggleSize ? `${$toggleSize - 4}px` : '12px')};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 9999px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
  will-change: transform;
  transform: ${({ $isChecked, $toggleSize }) =>
    $isChecked
      ? `translate(${$toggleSize ? `${$toggleSize * 0.86}px` : '0.86rem'})`
      : 'translate(2px)'};
  transition: transform 100ms ease 0s;
`
