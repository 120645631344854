import { setHours } from 'date-fns'
import { Hour } from './hour'
import * as S from './timezones.styled'
import moment from 'moment-timezone'

interface TimezonesProps {
  times: number[]
  timezone: string
  comparisonTimezone?: string
}

export const Timezones = ({ timezone, comparisonTimezone, times }: TimezonesProps): JSX.Element => {
  const formatTime = (time: number, tz: string): string => {
    return moment.tz(setHours(new Date(), time), tz).format('ha').toLowerCase()
  }

  const displayTimezone = (tz: string): string => {
    return moment.tz(tz).format('z')
  }

  return (
    <>
      <S.Timezones data-name="timezones">
        <S.Header>{displayTimezone(timezone)}</S.Header>
        {times.map((time) => (
          <Hour key={time} hour={time}>
            <S.HourInner>{formatTime(time, timezone)}</S.HourInner>
          </Hour>
        ))}
      </S.Timezones>
      {comparisonTimezone && (
        <S.Timezones data-name="timezone-comparison">
          <S.Header>{comparisonTimezone}</S.Header>
          {times.map((time) => (
            <Hour key={time} hour={time}>
              <S.HourInner>{formatTime(time, comparisonTimezone)}</S.HourInner>
            </Hour>
          ))}
        </S.Timezones>
      )}
    </>
  )
}
