import styled from 'styled-components'

export const GroupedBarChart = styled.div``

export const Segments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`

export const Segment = styled.div<{ $barColor: string, $barWidth: number }>`
  position: relative;
  width: 100%;
  padding: ${({ theme }) => theme.spacing[10]} ${({ theme }) => theme.spacing[12]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  border-radius: 6px;
  overflow: hidden;
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.bgTertiary};
    }
  }
  &:after {
    position: absolute;
    content: '';
    display: block;
    width: ${({ $barWidth }) => `${$barWidth}%`};
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${({ $barColor }) => $barColor};
    z-index: 1;
  }
`

export const SegmentLabels = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
`
