import { isNil, omitBy } from 'lodash'

/*
RouteBuilder:
- A clsss to generate dynamic routes

Description:
`RouteBuilder` takes in the routing table specified in `router.tsx`,

RouteBuilder.build('ROUTE_NAME', { var1: val, var2: val }) -- builds a dynamic route
RouteBuilder.routeTable -- returns all the routes and metadata in RouteBuilder
RouteBuilder.pages -- returns a list of names for all the possible pages

Examples:
Imagine this is the routing table looked like:

  const routes = [{
    path: '/',
    children: [
      { path: 'route1', index: true },
      { path: 'route2', index: true },
      { path: 'route3', children: [ { path: ':org' }, ] },
      {
        path: 'route4',
        children: [
          {
            path: ':org',
            children: [
              {
                path: ':user',
                children: [{ path: 'test' }]
              },
            ]
          },
        ]
      },
    ],
  }]

This what each use case looks like:

  RouteBuilder.create(routes)
  console.log(RouteBuilder.routeTable)
  {
    ROOT: { name: 'ROOT', route: '/', path: '/', fields: Set(0) {} },
    ROUTE1: {
      name: 'ROUTE1',
      route: 'route1/',
      path: '/route1/',
      fields: Set(0) {}
    },
    ROUTE2: {
      name: 'ROUTE2',
      route: 'route2/',
      path: '/route2/',
      fields: Set(0) {}
    },
    ROUTE3: {
      name: 'ROUTE3',
      route: 'route3/',
      path: '/route3/',
      fields: Set(0) {}
    },
    ROUTE4: {
      name: 'ROUTE4',
      route: 'route4/',
      path: '/route4/',
      fields: Set(0) {}
    },
    TEST: {
      name: 'TEST',
      route: 'test/',
      path: '/route4/:org/:user/test/',
      fields: Set(2) { 'org', 'user' }
    }
  }

  console.log(RouteBuilder.pages)
  [ 'ROOT', 'ROUTE1', 'ROUTE2', 'ROUTE3', 'ROUTE4', 'TEST' ]

  console.log(RouteBuilder.build('TEST', { org: 100, user: 200 }))
  /route4/100/200/test/

  console.log(RouteBuilder.build('TEST', { org: 100, user: 200 }))
  Route TEST requires value for `org` -- these are `console.error`
  Route TEST requires value for `user` -- these are `console.error`
  /route4///test/

  console.log(RouteBuilder.build('ROUTE3'))
  /route3/
*/

interface ReactRouterRow {
  path: string
  children?: ReactRouterRow[]
}

interface RouteBuilderRow {
  path: string // ex: `/account/:user/settings/` - root path will be `/`
  route: string // ex: `settings/`               - root route will be `/`
  name: string // ex: `SETTINGS`                - root name will be `ROOT`
  fields: Set<string> // ex: `Set{"user"}`
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class RouteBuilder {
  private static _self: RouteBuilder | null = null
  private static _table: Record<string, RouteBuilderRow>

  public static get pages (): string[] | null {
    return Object.keys(this._table)
  }

  public static get routeTable (): Record<string, RouteBuilderRow> {
    return this._table
  }

  private constructor (routingTable: ReactRouterRow[]) {
    RouteBuilder._table = RouteBuilder.buildTable(routingTable)
  }

  public static create (routingTable: ReactRouterRow[]): RouteBuilder {
    if (isNil(this._self)) {
      this._self = new RouteBuilder(routingTable)
    }
    return this._self
  }

  private static cleanRouteName (route: string): string {
    return route.replace(/:/g, '').replace(/-/g, '_').toUpperCase()
  }

  private static isDynamicRoute (route: string): boolean {
    return route.startsWith(':')
  }

  private static isRootRoute (route: string): boolean {
    return route === '/'
  }

  private static pathJoin (parts: string[], separator = '/'): string {
    const replace = new RegExp(separator + '{1,}', 'g')
    const endReplace = new RegExp(separator + '{1,}$', 'g')
    const result = parts.join(separator).replace(replace, separator).replace(endReplace, '')
    return result
  }

  private static buildTable (
    routingTable: ReactRouterRow[],
    parentPath: string = '',
    parentName: string = '',
    parentPathVariables: string[] = []
  ): Record<string, RouteBuilderRow> {
    const outputTable: Record<string, RouteBuilderRow> = {}

    for (const route of routingTable) {
      let currentRoute = '/'
      if (!this.isRootRoute(route.path)) {
        currentRoute = route.path
      }

      let currentPath = '/'
      if (!this.isRootRoute(route.path)) {
        currentPath = RouteBuilder.pathJoin([parentPath, currentRoute])
      }

      let currentName = 'ROOT'
      if (!this.isRootRoute(route.path)) {
        currentName = this.cleanRouteName(route.path.replace('/', '').trim())
      }

      if (parentName && parentName !== 'ROOT' && !this.isDynamicRoute(route.path)) {
        currentName = RouteBuilder.pathJoin([parentName, currentName], '_')
      }

      const currentFields = [...parentPathVariables]
      if (route.path) {
        const matches = route.path.match(/:[a-zA-Z0-9_-]+/g)
        matches?.forEach((match) => {
          currentFields.push(match)
        })
      }

      const currentPathVariables = [...parentPathVariables]
      if (this.isDynamicRoute(route.path)) {
        const variableName = route.path.replace(':', '').replace('/', '').trim()
        currentPathVariables.push(variableName)
      }

      if (!this.isDynamicRoute(route.path)) {
        outputTable[currentName] = {
          name: currentName,
          route: currentRoute,
          path: currentPath,
          fields: new Set(currentFields)
        }
      }

      if (route.children) {
        const nextParentName = this.isDynamicRoute(route.path) ? parentName : currentName
        const result = this.buildTable(
          route.children,
          currentPath,
          nextParentName,
          currentPathVariables
        )
        Object.keys(result).forEach((subroute) => (outputTable[subroute] = result[subroute]))
      }
    }

    return outputTable
  }

  public static build (
    pathName: string,
    variables: Record<string | symbol, any> = {},
    query: Record<string | symbol, any> = {}
  ): string {
    const route = this._table[pathName]
    if (isNil(route)) {
      console.error(`Route ${pathName} does not exist`)
    }

    if (route.fields.size === 0) {
      return route.path
    }

    const buildDynamicRoute = route.path.split('/').map((subroute) => {
      if (this.isDynamicRoute(subroute)) {
        const variableName = subroute.replace(':', '').replace('/', '')
        const variableValue = variables[variableName]
        if (isNil(variableValue)) {
          console.error(`Route ${pathName} requires value for \`${variableName}\``)
          return null
        }
        return variableValue
      }
      return subroute
    })

    let path = RouteBuilder.pathJoin(buildDynamicRoute as string[])

    const queryString = omitBy(query, isNil)
    if (Object.keys(queryString).length > 0) {
      const queryStringParams = new URLSearchParams(queryString)
      path = `${path}?${queryStringParams.toString()}`
    }

    return path
    // return buildDynamicRoute.join('/')
  }
}
