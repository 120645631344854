import { useJobByDepartmentsQuery } from './use-jobs-by-department'
import { useJobQuery } from './use-job'
import type { JobWithDepartment } from 'src/libs/api/backend/jobs'

interface UseCurrentDepartmentReturnType {
  department: JobWithDepartment['department']
}

export const useCurrentDepartment = (): UseCurrentDepartmentReturnType => {
  const { data: jobsByDepartment } = useJobByDepartmentsQuery()
  const { data: currentJob } = useJobQuery()

  const match = jobsByDepartment?.[currentJob?.departmentId ?? 0]
  const department = match?.[0]?.department

  return {
    department
  }
}
