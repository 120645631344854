import { getActiveCandidateSequences, undoStageTransition as undoStageTransitionPut } from 'src/libs/api/backend/candidate_jobs'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { useParams } from 'react-router-dom'
import { Icons } from 'src/components/primitives/icon'
import { isNil } from 'lodash'
import { useSetAtom } from 'jotai'
import { notifyAtom, notifyErrorAtom } from 'src/stores/notifications'

interface ReturnType {
  undoStageTransition: (candidateJobIds: string[]) => void
}

export const useUndoStageTransition = ({ isRestoringCandidates }: { isRestoringCandidates?: boolean }): ReturnType => {
  const notifyError = useSetAtom(notifyErrorAtom)
  const notify = useSetAtom(notifyAtom)
  const { jobId } = useParams()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async (candidateJobIds: string[]) => {
      return await undoStageTransitionPut(candidateJobIds)
    },
    onError: async (err, candidateJobIds: string[]) => {
      if (isRestoringCandidates) {
        if (isNil(jobId)) {
          throw new Error('Missing jobId')
        }
        const activeCandidateSequences = await getActiveCandidateSequences(jobId, candidateJobIds)
        notify({
          type: 'failed-adding-candidates',
          variant: 'warning',
          position: 'bottom-right',
          icon: Icons.alertTriangle,
          payload: activeCandidateSequences
        })
        console.error('Error adding candidate to sequence', err.message)
      } else {
        notifyError({
          message: `An error occurred during undo: ${err.message}`
        })
      }
    },
    onSuccess: async (candidateJobs) => {
      // const candidateJobsById = keyBy(candidateJobs, 'id')
      // queryClient.setQueriesData<CandidateJobExpanded[]>(
      //   { queryKey: [queryKeys.candidateJobs] },
      //   (oldCandidateJobs) => {
      //     if (isNil(oldCandidateJobs)) {
      //       return oldCandidateJobs
      //     }

      //     return oldCandidateJobs.map((o) => {
      //       if (candidateJobs[o.id]) {
      //         return {
      //           ...o,
      //           ...candidateJobs[o.id]
      //         }
      //       }

      //       return o
      //     })
      //   }
      // )
      const jobIds = [...new Set(candidateJobs.map((candidateJob) => candidateJob.jobId))]
      for (const jobId of jobIds) {
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateJobs, jobId]
        })
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateJobCounts, jobId]
        })
      }
      if (isRestoringCandidates) {
        notify({
          type: 'candidate-restored',
          variant: 'neutral',
          payload: candidateJobs
        })
      }
    }
  })

  const undoStageTransition = (candidateJobIds: string[]): void => {
    mutation.mutate(candidateJobIds)
  }

  return { undoStageTransition }
}
