import styled, { css } from 'styled-components'
import type { BannerStyleProps } from './banner'

export const Banner = styled.div<BannerStyleProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: solid 1px;
  width: 100%;
  border-color: ${({ $variant, theme }) => {
    switch ($variant) {
      case 'neutral': {
        return theme.colors.bgSecondary
      }
      case 'muted': {
        return theme.colors.borderTranslucent
      }
      case 'positive': {
        return theme.colors.positiveTranslucent5
      }
      case 'tint': {
        return theme.colors.tintTranslucent5
      }
      case 'negative': {
        return theme.colors.negativeTranslucent10
      }
      case 'warning': {
        return theme.colors.warningTranslucent25
      }
    }
  }};
  border-radius: ${({ $borderRadius }) => `${$borderRadius}px`};
  background-color: ${({ $variant, theme }) => {
    switch ($variant) {
      case 'neutral': {
        return theme.colors.bgTertiary
      }
      case 'muted': {
        return theme.colors.bgSecondary
      }
      case 'positive': {
        return theme.colors.positiveFaded
      }
      case 'tint': {
        return theme.colors.tintTranslucent5
      }
      case 'negative': {
        return theme.colors.negativeTranslucent10
      }
      case 'warning': {
        return theme.colors.warningTranslucent10
      }
    }
  }};
  padding-top: ${({ $padding, theme }) => theme.spacing[$padding?.top ?? 8]};
  padding-bottom: ${({ $padding, theme }) => theme.spacing[$padding?.bottom ?? 8]};
  padding-left: ${({ $padding, theme }) => theme.spacing[$padding?.left ?? 16]};
  padding-right: ${({ $padding, theme }) => theme.spacing[$padding?.right ?? 8]};

  ${({ $variant }) => $variant === 'ghost' && css`
    background-color: transparent;
    border: none;
  `}

  p {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: ${({ theme }) => theme.fontWeights[500]};
    line-height: 1.2;
    color: ${({ $variant, theme }) => {
      switch ($variant) {
        case 'neutral': {
          return theme.colors.fgPrimary
        }
        case 'muted': {
          return theme.colors.fgSecondary
        }
        case 'positive': {
          return theme.colors.positiveBg
        }
        case 'tint': {
          return theme.colors.fgPrimary
        }
        case 'negative': {
          return theme.colors.negativeFg
        }
        case 'warning': {
          return theme.colors.warningFg
        }
      }
    }};
  }
`
