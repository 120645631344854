import * as Dialog from 'src/components/primitives/dialog'
import { StripePricingTable } from 'src/components/blocks/stripe-pricing-table'
import { useAtomValue, useSetAtom } from 'jotai'
import { controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useMemo } from 'react'

export const BillingSubscribeDialog = (): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.BILLING_SUBSCRIBE), []))
  const controlDialog = useSetAtom(controlDialogAtom)

  return (
    <Dialog.Root
      id={DialogId.BILLING_SUBSCRIBE}
      isOpen={isDialogOpen}
      onOpenChange={(value) => { controlDialog({ id: DialogId.BILLING_SUBSCRIBE, newState: value }) }}
      $width="large"
      $maxWidth="600px"
      // $innerPadding={{ top: 0, left: 0, right: 0, bottom: 0 }}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Subscribe to Pin"
          onClose={() => { controlDialog({ id: DialogId.BILLING_SUBSCRIBE, newState: false }) }}
        />
        <Dialog.Content>
          <StripePricingTable />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
