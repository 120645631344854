import * as Dialog from 'src/components/primitives/dialog'
import { Calendar } from 'src/components/blocks/calendar'
import type { CalendarProps } from 'src/components/blocks/calendar'
import { useQueryParams } from 'src/hooks/use-query-params'
import { controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'
import { useMemo } from 'react'

interface CalendarDialogProps extends CalendarProps {}

export const CalendarDialog = ({ candidateJob, scheduleNewEvent }: CalendarDialogProps): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.CALENDAR), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const { resetParam } = useQueryParams()

  return (
    <Dialog.Root
      id={DialogId.CALENDAR}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        if (!value) {
          resetParam('startDateTime')
          resetParam('endDateTime')
        }
        controlDialog({ id: DialogId.CALENDAR, newState: value })
      }}
      $width="almostFull"
      $height="almostFull"
      $innerPadding={{ top: 0, left: 0, bottom: 0, right: 0 }}
    >
      <Dialog.Portal>
        <Dialog.Content hasHeader={false}>
          <Calendar
            candidateJob={candidateJob}
            scheduleNewEvent={scheduleNewEvent}
          />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
