import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

export const QueryErrorHandler = (): JSX.Element | null => {
  const queryClient = useQueryClient()

  useEffect(() => {
    const unsubscribe = queryClient.getQueryCache().subscribe((event: any) => {
      if (event.type === 'error' && event.error instanceof Error) {
        console.error('QUERY ERROR: ', event.error.message)
        // notify({
        //   type: 'toast',
        //   variant: 'negative',
        //   position: 'bottom-right',
        //   icon: 'x-octagon',
        //   message: `An error occurred: ${event.error.message}`
        // })
      }
    })

    return () => {
      unsubscribe()
    }
  }, [queryClient])

  return null
}
