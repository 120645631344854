import { AutomateIcon } from 'src/components/primitives/automate-icon'
import * as S from './automate-info-banner.styled'
import { Flex } from 'src/components/primitives'
import { Caption, Paragraph } from 'src/components/primitives/typography'

interface AutomateInfoBannerInfoProps {
  autoApproveIsEnabled: boolean
  $maxWidth: string
}

export const AutomateInfoBannerInfo = ({ autoApproveIsEnabled, $maxWidth }: AutomateInfoBannerInfoProps): JSX.Element => {
  return (
    <S.Banner $isEnabled={autoApproveIsEnabled} $maxWidth={$maxWidth}>
      <S.Arrow>
        <S.ArrowShape />
      </S.Arrow>
      <AutomateIcon isAnimating={autoApproveIsEnabled} />
      <Flex $direction="column" $gap={4}>
        <Caption size="XS" $color={autoApproveIsEnabled ? 'fgPrimary' : 'fgSecondary'}>
          Auto-outreach {autoApproveIsEnabled ? 'enabled' : 'disabled'}
        </Caption>
        <Paragraph size="XS" $color="fgSecondary">
          Pin will automatically reach out to new candidates from this search daily.
      </Paragraph>
      </Flex>
    </S.Banner>
  )
}
