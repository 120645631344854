import styled from 'styled-components'
import type { GridBaseProps } from './grid'

export const Grid = styled.div<GridBaseProps>`
  display: grid;
  grid-template-columns: ${({ $columns }) => `repeat(${$columns}, 1fr)`};
  grid-column-gap: ${({ $columnGap, theme }) => theme.spacing[$columnGap ?? 0]};
  grid-row-gap: ${({ $rowGap, theme }) => theme.spacing[$rowGap ?? 0]};
  width: ${({ $width }) => $width};
`
