import styled from 'styled-components'
import { SIZES } from './constants'
import { Hour } from './hour.styled'

export const Timezones = styled.div`
  position: relative;
  width: 40px;
  flex: 0 0 40px;
  min-height: 100%;
  height: 100%;

  ${Hour} {
    border-right: solid 1px;
    border-color: ${({ theme }) => theme.colors.fgTranslucent10};
  }
`

export const HourInner = styled.div`
  font-family: 'SF Mono', 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New', monospace;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.2px;
  text-align: center;
  color: ${({ theme }) => theme.colors.fgTertiary};
  padding-top: 0.25rem;
`

export const Header = styled.header`
  position: sticky;
  top: 0;
  height: ${SIZES.SUBHEADER.LG}px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'SF Mono', 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New', monospace;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.fgTertiary};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding-top: 0.25rem;
  z-index: 2;
  border-bottom: solid 1px;
  border-right: solid 1px;
  border-color: ${({ theme }) => theme.colors.fgTranslucent10};
`
