import { format } from 'date-fns'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr 32px;
  grid-column-gap: 0.5rem;
  p,
  button {
    align-self: center;
  }
  button {
    justify-self: flex-end;
  }
`

export const SliderTrack = styled.div`
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  position: relative;
  flex-grow: 1;
  border-radius: 2px;
  height: 32px;
`

export const SliderRoot = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  height: 32px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.borderTranslucent};
  overflow: hidden;
`

export const AddRangeTrack = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 32px;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  overflow: hidden;
`

export const AddRangeButton = styled.div<{ $isVisible: boolean }>`
  width: 100%;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.tintTranslucent25};
  opacity: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (hover: hover) {
    &:hover {
      opacity: ${({ $isVisible }) => $isVisible ? 0.5 : 0};
    }
  }
`

export const NewRange = styled.div<{ $start: string, $width: string }>`
  position: absolute;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.tintTranslucent25};
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.tintTranslucent25};
  left: ${({ $start }) => $start};
  width: ${({ $width }) => $width};
  opacity: 0.5;
`

export const SliderSteps = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 8px 0;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  overflow: hidden;
  pointer-events: none;
`

export const SliderStep = styled.div<{ $isHidden: boolean }>`
  width: 1px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.borderTranslucent};
  pointer-events: none;
  opacity: ${({ $isHidden }) => ($isHidden ? 0.2 : 1)};
  &:first-child {
    opacity: 0;
  }
`

export const Range = styled.div<{ $start: number, $width: number, $isGrabbing: boolean }>`
  position: absolute;
  background-color: ${({ theme, $isGrabbing }) => theme.colors[$isGrabbing ? 'bgTertiary' : 'bgPrimary']};
  border-radius: 2px;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.tintTranslucent25};
  left: ${({ $start }) => `${$start}px`};
  width: ${({ $width }) => `${$width + 1}px`};
  height: 100%;
  z-index: ${({ $isGrabbing }) => $isGrabbing ? 3 : 2};
`

export const RangeInner = styled.div`
  position: absolute;
  top: 0;
  left: 20px;
  width: calc(100% - 40px);
  height: 32px;
  cursor: grab;
`

export const RangeHandle = styled.div<{ $handle: 'left' | 'right', $hour: number, $isColliding: boolean }>`
  position: absolute;
  top: 0;
  left: ${({ $handle }) => ($handle === 'left' ? 0 : 'unset')};
  right: ${({ $handle }) => ($handle === 'right' ? 0 : 'unset')};
  width: 20px;
  height: 32px;
  cursor: col-resize;
  display: flex;
  align-items: center;
  justify-content: ${({ $handle }) => $handle === 'left' ? 'flex-start' : 'flex-end'};
  padding: 0 4px;
  &:after {
    content: '${({ $hour }) => `${$hour === undefined ? ' ' : $hour === 0 ? '12' : $hour > 12 ? format(new Date(2024, 0, 1, $hour), 'h') : $hour}${$hour === undefined ? '' : $hour < 12 ? 'am' : 'pm'}`}';
    position: absolute;
    z-index: 3;
    top: 2px;
    left: ${({ $handle, $isColliding }) =>
      $handle === 'left' ? ($isColliding ? '-36px' : '16px') : 'unset'};
    right: ${({ $handle, $isColliding }) =>
      $handle === 'right' ? ($isColliding ? '-36px' : '16px') : 'unset'};
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: 500;
    color: ${({ theme }) => theme.colors.tintFg};
    transition: all 0.16s cubic-bezier(0.16, 1, 0.3, 1);
    user-select: none;
  }
`
