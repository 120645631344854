import { MergeAtsIntegrationSyncStatus } from 'src/libs/api/backend/merge'

export const isAtsSyncing = (syncStatus: MergeAtsIntegrationSyncStatus | null): boolean => {
  if (syncStatus === null) {
    // Null means it's in queue to be synced
    return true
  }
  return (
    syncStatus === MergeAtsIntegrationSyncStatus.SYNCING ||
    syncStatus === MergeAtsIntegrationSyncStatus.PARTIALLY_SYNCED
  )
}
