import CONFIG from 'src/config'

export const ABLY_AUTH_PATH = `${CONFIG.API_DOMAIN}/ably/auth`

export enum CHANNEL_TYPE {
  NOTIFICATIONS = 'notifications',
  CANDIDATE_SEARCH = 'candidateSearch',
  CANDIDATE_DETAILS = 'candidateDetails',
  ATS = 'ats'
}

export enum EVENT_TYPE {
  ORG_NOTIFICATION = 'orgNotification',
  CANDIDATES_UPDATE = 'candidatesUpdate',
  PROSPECTING_ERROR = 'prospectingError',
  MANUAL_OUTREACH = 'manualOutreach',
  INGEST_ATS = 'ingestATS',
  SYNCING_JOBS = 'syncingJobs',
  SYNCING_CANDIDATES = 'syncingCandidates',
  SYNCING_ATTACHMENTS = 'syncingAttachments'
}

export const getChannelName = (type: CHANNEL_TYPE, ids: string[] = []): string => {
  return [type, ...ids].join(':')
}
