import styled from 'styled-components'

export const SelectableChartGroup = styled.div`
  // background-color: ${({ theme }) => theme.colors.bgTertiary};
  // padding: 0.75rem;
  border-radius: 12px;
  display: flex;
  gap: 1.5rem;
`

export const GroupSelectors = styled.div``

export const GroupSelectorsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const GroupSelector = styled.div<{ $isSelected: boolean }>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: ${({ theme }) => theme.spacing[8]} ${({ theme }) => theme.spacing[12]};
  padding-right: ${({ theme }) => theme.spacing[8]};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.bgTertiary};
  // box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  ${({ $isSelected }) =>
    $isSelected &&
    `
      border-color: #1D4ED8;
    `}
  h4 {
    margin-top: 0.25rem;
    font-size: ${({ theme }) => theme.fontSizes[20]};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.fgPrimary};
  }
`

export const ChangeBadge = styled.div`
  padding: 0.25rem 0.25rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  p {
    line-height: 1;
  }
`

export const GroupWrapper = styled.div`
  position: relative;
  flex: 1 1 100%;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
  border-radius: 6px;
`
export const GroupArrow = styled.div<{ $offset: number }>`
  position: absolute;
  top: 0;
  left: -10px;
  width: 12px;
  height: 24px;
  transition: transform 0.36s cubic-bezier(0.77, 0, 0.18, 1);
  z-index: 2;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-width: 12px 10px 12px 0;
    border-style: solid;
    border-color: transparent #fff transparent transparent;
    z-index: 2;
  }
  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: -1px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 10px 12px 0;
    border-color: transparent rgba(0, 0, 0, 0.1) transparent transparent;
    filter: blur(2px);
    z-index: 1;
  }
`

export const GroupContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.bgPrimary};
  padding: 1.5rem;
  z-index: 3;
`
