import * as Dialog from 'src/components/primitives/dialog'
import { CreateProjectForm } from 'src/components/blocks/create-project-form'
import { useAtomValue, useSetAtom } from 'jotai'
import { controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useMemo } from 'react'

interface CreateProjectDialogProps {
  departmentId?: string
}

export const CreateProjectDialog = ({ departmentId }: CreateProjectDialogProps): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.CREATE_PROJECT), []))
  const controlDialog = useSetAtom(controlDialogAtom)

  return (
    <Dialog.Root
      id={DialogId.CREATE_PROJECT}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.CREATE_PROJECT, newState: value })
      }}
      $width="480px"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="New Project"
          onClose={() => {
            controlDialog({ id: DialogId.CREATE_PROJECT, newState: false })
          }}
        />
        <Dialog.Content>
          <CreateProjectForm preSelectedDepartmentId={departmentId} />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
