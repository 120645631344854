import * as Dialog from 'src/components/primitives/dialog'
import { MessageComposer } from 'src/components/blocks/message-composer'
import type { EmailData } from 'src/components/blocks/message-composer'
import { Button } from 'src/components/primitives/button'
import { useCallback, useEffect, useMemo, useState } from 'react'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import type { Spacing } from 'src/styles/theme/types'
import { useBuildCustomOutreach } from 'src/hooks/queries/use-build-custom-outreach'
import { useSendCustomOutreach } from 'src/hooks/mutations/use-send-custom-outreach'
import { isNil } from 'lodash'
import { useAtomValue, useSetAtom } from 'jotai'
import { DialogId, controlDialogAtom, isDialogOpenAtom, openAlertAtom } from 'src/stores/dialogs'

interface WriteCustomOutreachDialogProps {
  candidateJob: CandidateJobExpanded
}

export const WriteCustomOutreachDialog = ({
  candidateJob
}: WriteCustomOutreachDialogProps): JSX.Element => {
  const controlDialog = useSetAtom(controlDialogAtom)
  const openAlert = useSetAtom(openAlertAtom)
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.WRITE_CUSTOM_OUTREACH), []))
  const [customOutreachEmail, setCustomOutreachEmail] = useState<EmailData>({
    subject: '',
    body: '',
    sendingUserId: '',
    sendingEmailAccountId: '',
    sendingEmailAlias: null,
    sendingLinkedInAccountId: null
  })

  const [isSendable, setIsSendable] = useState(false)
  const [initialized, setInitialized] = useState(false)

  const { data: buildCustomOutreach, isPending: isBuildCustomOutreachLoading } = useBuildCustomOutreach({
    candidateJobId: candidateJob.id
  })

  useEffect(() => {
    if (!isNil(buildCustomOutreach) && !initialized) {
      setCustomOutreachEmail({
        ...buildCustomOutreach,
        subject: buildCustomOutreach?.emailMessageContent.subject,
        body: buildCustomOutreach?.emailMessageContent.body.html,
        sendingUserId: buildCustomOutreach?.sendingUserId,
        sendingEmailAccountId: buildCustomOutreach?.sendingEmailAccountId,
        sendingEmailAlias: buildCustomOutreach?.sendingEmailAlias ?? null,
        sendingLinkedInAccountId: buildCustomOutreach?.sendingLinkedInAccountId ?? null,
        recipients: [candidateJob]
      })
      setInitialized(true)
    }
  }, [buildCustomOutreach, candidateJob, initialized])

  const { sendCustomOutreach } = useSendCustomOutreach()

  // useEffect(() => {
  //   const invalidStatus = [
  //     CandidateJobStatus.EMAIL_NOT_FOUND,
  //     CandidateJobStatus.BOUNCED,
  //     CandidateJobStatus.SEARCHING_FOR_EMAIL
  //   ]
  //   const validCandidateIds = candidateJobs
  //     ?.filter(
  //       (candidateJob) =>
  //         candidateJob.statusDisplay?.status &&
  //         !invalidStatus.includes(candidateJob.statusDisplay?.status)
  //     )
  //     .map((candidateJob) => candidateJob.candidateId)
  //   if (validCandidateIds) {
  //     setCustomOutreachEmail((prev) => ({
  //       ...prev,
  //       candidateIds: validCandidateIds
  //     }))
  //   }
  // }, [candidateJobs])

  const handleCloseDialog = useCallback(() => {
    const userHasChanges = (): boolean => {
      // Ideally, we'd use something like `isEqual` here, but depending on the
      // use case and how the editor updates, it might return an empty <p> tag
      // which would return true, even if there have not been any "real" changes.
      const detectChanges = (input: string): boolean => {
        return input !== '<p></p>' && input !== '<p> </p>' && input !== ''
      }
      const bodyHasChanges = detectChanges(customOutreachEmail.body ?? '')
      const subjectHasChanges = detectChanges(customOutreachEmail.subject ?? '')
      return bodyHasChanges || subjectHasChanges
    }

    if (userHasChanges()) {
      openAlert({
        message: 'Are you sure you want to leave this page?',
        description: 'All your changes will be lost.',
        cancelText: 'Stay on page',
        confirmText: 'Discard and leave',
        onConfirm: () => {
          controlDialog({ id: DialogId.WRITE_CUSTOM_OUTREACH, newState: false })
        }
      })
    } else {
      controlDialog({ id: DialogId.WRITE_CUSTOM_OUTREACH, newState: false })
    }
  }, [controlDialog, customOutreachEmail.body, customOutreachEmail.subject, openAlert])

  return (
    <Dialog.Root
      id={DialogId.WRITE_CUSTOM_OUTREACH}
      isOpen={isDialogOpen}
      onOpenChange={() => {
        handleCloseDialog()
      }}
      $width='half'
      $maxWidth="640px"
      $innerPadding={{
        top: 0 as Spacing,
        right: 0 as Spacing,
        bottom: 0 as Spacing,
        left: 0 as Spacing
      }}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="1st Outreach Email Personalization"
          onClose={() => {
            handleCloseDialog()
          }}
        />
        <Dialog.Content>
          <MessageComposer
            isGenerating={isBuildCustomOutreachLoading}
            currentData={customOutreachEmail}
            initialEmailBody={buildCustomOutreach?.emailMessageContent.body.html}
            useCcFields={false}
            forceEditorFocus
            disableEmailAccountSelection
            onSendableStateChange={(sendable) => {
              setIsSendable(sendable)
            }}
            onDataChanged={(data) => {
              const isEmptyChange = data.subject === '' && (data.body === '<div></div>' || data.body === '')
              if (isEmptyChange) {
                return
              }

              const updated = {
                ...customOutreachEmail,
                subject: data.subject ?? '',
                body: data.body ?? ''
              }
              setCustomOutreachEmail(updated)
            }}
            minHeight="8rem"
            $maxHeight='calc(100vh - 40rem)'
            $editorHeight='fit-content'
            useVariables={false}
            leftActions={
              <Button
                $variant="fill"
                $colorTheme="tint"
                $height={32}
                disabled={!isSendable}
                onClick={() => {
                  sendCustomOutreach({
                    sendCustomOutreachParams: {
                      candidateJobId: candidateJob.id,
                      emailContent: {
                        subject: customOutreachEmail.subject ?? '',
                        body: customOutreachEmail.body ?? ''
                      }
                    }
                  })
                }}
              >
                Start outreach
              </Button>
            }
          />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
