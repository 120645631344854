import type { Color } from 'src/styles/theme/types'
import * as S from './logo.styled'
import { NormalPin, SadPin, HappyPin, SearchingPin } from './svgs'
import { useTheme } from 'styled-components'
import { useMemo } from 'react'

interface LogoSymbolProps {
  variant?: 'normal' | 'sad' | 'searching' | 'happy'
  animated?: boolean
  width?: number
  height?: number
  colorTheme?: 'dark' | 'tint'
}

export const LogoSymbol = ({
  variant = 'normal',
  animated = false,
  width = 21,
  height = 20,
  colorTheme
}: LogoSymbolProps): JSX.Element => {
  const color = useMemo((): string => {
    switch (colorTheme) {
      case 'tint': {
        return '#AA91F0'
      }
      case 'dark':
      default: {
        return '#27272A'
      }
    }
  }, [colorTheme])

  if (variant === 'sad') {
    if (animated) {
      return (
        <S.SadPinAnimated>
          <SadPin />
        </S.SadPinAnimated>
      )
    }
    return <SadPin width={width} height={height} />
  }

  if (variant === 'happy') {
    if (animated) {
      return (
        <S.HappyPinAnimated>
          <HappyPin width={width} height={height} />
        </S.HappyPinAnimated>
      )
    }
    return <HappyPin width={width} height={height} />
  }

  if (variant === 'searching') {
    if (animated) {
      return (
        <S.SearchingPinAnimated>
          <SearchingPin />
        </S.SearchingPinAnimated>
      )
    }
    return <SearchingPin />
  }

  return <NormalPin width={width} height={height} color={color} />
}

interface LogoProps {
  size?: number
  variant?: 'dark' | 'light'
}

export const Logo = ({ size = 84, variant = 'light' }: LogoProps): JSX.Element => {
  const { colors } = useTheme()

  const color = useMemo((): Color => {
    switch (variant) {
      case 'dark': {
        return colors.fgPrimary
      }
      default: {
        return colors.bgPrimary
      }
    }
  }, [colors.bgPrimary, colors.fgPrimary, variant])

  return (
    <svg
      width={size}
      height={size * 0.44047}
      viewBox="0 0 84 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.6631 28.3319V8.12827H54.0332C58.2182 8.12827 60.9024 10.6104 60.9024 14.4491C60.9024 18.2878 58.2182 20.77 54.0332 20.77H50.2234V28.3319H45.6631ZM50.2234 16.8736H53.5714C55.3031 16.8736 56.3422 15.95 56.3422 14.4491C56.3422 12.9483 55.3031 12.0247 53.5714 12.0247H50.2234V16.8736ZM64.5788 11.8515C63.1356 11.8515 62.0677 10.7259 62.0677 9.31162C62.0677 7.92623 63.1356 6.8006 64.5788 6.8006C66.0507 6.8006 67.1764 7.92623 67.1764 9.31162C67.1764 10.7259 66.0507 11.8515 64.5788 11.8515ZM62.4718 28.3319V13.7853H66.7146V28.3319H62.4718ZM69.4202 28.3319V13.7853H73.663V15.7191C74.5288 14.4491 76.1163 13.5544 78.1078 13.5544C81.3115 13.5544 83.4473 15.6325 83.4473 18.8362V28.3319H79.2334V19.9618C79.2334 18.3744 78.2521 17.3065 76.7224 17.3065C74.702 17.3065 73.663 18.9516 73.663 21.9533V28.3319H69.4202Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7952 16.2477C34.7952 25.221 27.521 32.4954 18.5479 32.4954V35.9638C18.5479 36.5184 18.026 36.9258 17.4954 36.7643C9.23483 34.2494 3.08834 26.8823 2.37556 17.9827C2.37157 17.9328 2.37214 17.8837 2.37692 17.8359C2.32623 17.3133 2.30029 16.7836 2.30029 16.2477C2.30029 7.27435 9.57453 0 18.5478 0C27.521 0 34.7952 7.27435 34.7952 16.2477ZM18.5476 27.3566C24.6831 27.3566 29.6569 22.3827 29.6569 16.2471C29.6569 10.1116 24.6831 5.13767 18.5476 5.13767C12.4121 5.13767 7.43825 10.1116 7.43825 16.2471C7.43825 22.3827 12.4121 27.3566 18.5476 27.3566Z"
        fill={color}
      />
      <path
        d="M11.5419 17.7083V14.6327C11.5419 13.1054 12.78 11.8673 14.3073 11.8673C15.8346 11.8673 17.0727 13.1054 17.0727 14.6327V17.7083H11.5419Z"
        fill={color}
      />
      <path
        d="M20.0229 17.7083V14.6327C20.0229 13.1054 21.261 11.8673 22.7883 11.8673C24.3155 11.8673 25.5537 13.1054 25.5537 14.6327V17.7083H20.0229Z"
        fill={color}
      />
    </svg>
  )
}
