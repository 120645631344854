import styled from 'styled-components'

export const Editor = styled.div`
  height: 100%;
  width: 100%;
  background-color: transparent;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  padding-top: ${({ theme }) => theme.spacing[10]};
  scroll-padding-top: 0;
  padding-left: ${({ theme }) => theme.spacing[4]};
  * {
    font-size: ${({ theme }) => theme.fontSizes[14]};
    padding-right: ${({ theme }) => theme.spacing[12]};
    letter-spacing: ${({ theme }) => theme.letterSpacings['0.12']};
    outline: none;
    line-height: 20px;
    font-weight: ${({ theme }) => theme.fontWeights[400]};
    .editor-paragraph {
      line-height: 20px;
      color: ${({ theme }) => theme.colors.fgPrimary};
    }
    .editor-refinement-block {
      display: inline-block;
      width: fit-content;
    }
    .editor-refinement-highlight {
      display: inline-block;
      position: relative;
      white-space: nowrap;
      word-break: keep-all;
      color: ${({ theme }) => theme.colors.tintBg};
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 1px;
        right: -1px;
        left: 0;
        border-bottom: 1px dashed;
        border-color: ${({ theme }) => theme.colors.tintBg};
        pointer-events: none;
      }
    }
    .editor-refinement-unmatched-highlight {
      display: inline-block;
      position: relative;
      white-space: nowrap;
      word-break: keep-all;
      color: ${({ theme }) => theme.colors.fgSecondary};
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 1px;
        left: 0;
        right: -1px;
        border-radius: 2px;
        border-bottom: 1px dashed;
        border-color: ${({ theme }) => theme.colors.fgSecondary};
        pointer-events: none;
      }
    }
  }
`
