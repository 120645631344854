import styled from 'styled-components'

export const Searchbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    button {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
`
