import * as OL from 'src/components/forms/options-list/options-list.styled'
import { Caption } from '../primitives/typography'
import { Button } from '../primitives'

interface ArchiveUserConfirmationProps {
  onClick: () => void
}

export const ArchiveUserConfirmation = ({ onClick }: ArchiveUserConfirmationProps): JSX.Element => {
  return (
    <OL.OptionConfirmation>
      <Caption size='XS'>Are you sure you want to disable this user?</Caption>
      <Button
        $variant='raised-light'
        $colorTheme='negative'
        $height={24}
        $fontSize={12}
        onClick={() => {
          onClick()
        }}
      >
        Disable user
      </Button>
    </OL.OptionConfirmation>
  )
}
