import { z } from 'zod'
import Api from '..'

export const userParser = z.object({
  id: z.string().uuid(),
  name: z.string()
})

export type User = z.infer<typeof userParser>

export const emailAliasesParser = z.array(z.object({
  sendAsEmail: z.string(),
  displayName: z.string().nullish()
})).nullish().transform(val => val ?? [])

/*
    id : "06c0e7fe-b51b-4eb4-b40e-e08e57e3fc6d"
    linkedInProfileId : "ACoAAADWo6EBmSyvHMDfMsba3dBFy_uWwQfTRt4"
    linkedInProfilePictureUrl : "linkedin-profile-pictures/ACoAAADWo6EBmSyvHMDfMsba3dBFy_uWwQfTRt4.jpeg"
    orgId : "4d394bbb-aa68-48ee-afd2-5100417f9807"
    unipileId : "hr0Yvby9SceLUg33SGh3Lg"
    unipileUserName : "Michael Rashkovsky"
    userId : "d5874bca-2c82-4465-995b-679f29539ccd"
*/
export const linkedInAccountParser = z.object({
  id: z.string().uuid(),
  userId: z.string().uuid(),
  linkedInProfileId: z.string(),
  linkedInProfilePictureUrl: z.string().nullish(),
  unipileId: z.string(),
  unipileUserName: z.string()
})

export type LinkedInAccount = z.infer<typeof linkedInAccountParser>

const emailAccountParser = z.object({
  type: z.enum(['google', 'microsoft']),
  email: z.string(),
  emailAliases: emailAliasesParser,
  hasAccessToken: z.boolean(),
  usedInJobIds: z.array(z.string()),
  currentUserHasSendAsPermission: z.boolean(),
  id: z.string(),
  userId: z.string(),
  isPrimary: z.boolean()
})

export type EmailAccount = z.infer<typeof emailAccountParser>

const orgUserParser = z.object({
  id: z.string(),
  active: z.boolean(),
  email: z.string(),
  name: z.string(),
  emailAccounts: z.array(emailAccountParser),
  profilePhotoUrl: z.string().nullable().optional(),
  linkedInAccounts: z.array(linkedInAccountParser)
})

export type OrgUser = z.infer<typeof orgUserParser>

export async function listOrgUsers (): Promise<OrgUser[]> {
  const res = await Api.get('/org/users')
  return z
    .array(orgUserParser)
    .parse(res.data)
}

export async function disableUser (userId: string, updatedAccountId?: string): Promise<void> {
  await Api.delete(`/org/users/${userId}`, { updatedAccountId })
}

export async function activateUser (userId: string): Promise<void> {
  await Api.post(`/org/users/${userId}/activate`)
}
