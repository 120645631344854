import styled from 'styled-components'

export const TableWrapper = styled.div<{ $maxHeight: string }>`
  width: 100%;
  height: fit-content;
  max-height: ${({ $maxHeight }) => $maxHeight};
  border-radius: 6px;
  overflow: auto;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
  scrollbar-width: thin;
`
