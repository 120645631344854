import { z } from 'zod'
import Api, { ApiError } from '..'

export const projectParser = z.object({
  id: z.string(),
  orgId: z.string(),
  departmentId: z.string().nullish(),
  name: z.string(),
  deleted: z.boolean().nullish(),
  deletedAt: z.coerce.date().nullish(),
  createdAt: z.coerce.date().nullish(),
  updatedAt: z.coerce.date().nullish()
})

export type Project = z.infer<typeof projectParser>

export const newProjectParser = z.object({
  departmentId: z.string().nullish(),
  name: z.string()
})

export type NewProject = z.infer<typeof newProjectParser>

export async function createProject ({ departmentId, name }: NewProject): Promise<Project> {
  const { data, status } = await Api.post('/projects', null, {
    departmentId,
    name
  })

  if (status !== 201) {
    throw new ApiError('Failed to create project')
  }

  const project = projectParser.parse(data)

  return project
}

export async function getProjects (): Promise<Project[]> {
  const { data, status } = await Api.get('/projects')
  if (status !== 200) {
    throw new ApiError('Failed to fetch projects')
  }
  return z.array(projectParser).parse(data)
}

interface UpdateProjectDetailsArgs {
  id: string
  name: string
}

export async function updateProjectDetails ({ id, name }: UpdateProjectDetailsArgs): Promise<Project> {
  const { data, status } = await Api.put(`/projects/${id}`, null, {
    name
  })
  if (status !== 200) {
    throw new ApiError('Failed to update project')
  }
  return projectParser.parse(data)
}

export async function deleteOrRestoreProject (projectId: string, deleted: boolean): Promise<Project> {
  const { status, data } = await Api.delete(`/projects/${projectId}`, { deleted })

  if (status !== 200) {
    throw new ApiError('Failed to delete project')
  }
  return projectParser.parse(data)
}
