import { useQuery } from '@tanstack/react-query'
import { fetchCustomerSubscriptions } from 'src/libs/api/backend/billing'
import { queryKeys } from 'src/libs/query-keys'

export const useCustomerSubscriptionsQuery = ({ enabled }: { enabled: boolean }) => {
  return useQuery({
    queryKey: [queryKeys.customerSubscriptions],
    queryFn: async () => await fetchCustomerSubscriptions(),
    staleTime: 60 * 1000,
    enabled
  })
}
