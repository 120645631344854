import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { queryKeys } from 'src/libs/query-keys'
import { isNil } from 'lodash'
import { addCandidateJobsToSequence, CandidateJobStage, getActiveCandidateSequences } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { Icons } from 'src/components/primitives/icon'
import { useSetAtom } from 'jotai'
import { notifyAtom } from 'src/stores/notifications'

interface Res {
  addCandidateToSequence: (candidateJobIds: string[]) => void
}

export const useAddCandidateToSequence = (): Res => {
  const queryClient = useQueryClient()
  const notify = useSetAtom(notifyAtom)
  const { jobId } = useParams()

  if (isNil(jobId)) {
    throw new Error('Missing jobId')
  }

  const mutation = useMutation({
    mutationFn: async (candidateJobIds: string[]) => {
      return await addCandidateJobsToSequence(jobId, candidateJobIds)
    },
    onMutate: (candidateJobIds: string[]) => {
      queryClient.setQueriesData<CandidateJobExpanded[]>({ queryKey: [queryKeys.candidateJobs, jobId] }, (old) => {
        if (isNil(old)) {
          return old
        }

        return old.map((candidateJob) => {
          if (candidateJobIds.includes(candidateJob.id)) {
            return { ...candidateJob, stage: CandidateJobStage.PROSPECTING }
          }
          return candidateJob
        })
      })
    },
    onError: async (error, candidateJobIds: string[]) => {
      const activeCandidateSequences = await getActiveCandidateSequences(jobId, candidateJobIds)
      notify({
        type: 'failed-adding-candidates',
        variant: 'warning',
        position: 'bottom-right',
        icon: Icons.alertTriangle,
        payload: activeCandidateSequences
      })
      console.error('Error adding candidate to sequence', error.message)
    },
    onSuccess: async (data) => {
      notify({
        type: 'candidate-added',
        variant: 'neutral',
        payload: data
      })
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs, jobId]
      })
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobCounts, jobId]
      })
    }
  })

  const addCandidateToSequence = (candidateJobIds: string[]): void => {
    mutation.mutate(candidateJobIds)
  }

  return { addCandidateToSequence }
}
