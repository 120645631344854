import * as Ably from 'ably'
import { ABLY_AUTH_PATH } from 'src/libs/api/backend/websockets'

const ablyClient = new Ably.Realtime({
  authUrl: ABLY_AUTH_PATH,
  authMethod: 'POST',
  closeOnUnload: true, // Set to false to avoid twichy presense when we support collaborative feature. Will need to add explicit close and switch at that point
  authHeaders: {
    'Content-Type': 'application/json',
    authorization: '-' // Stupid solution for XHR withCredentials https://github.com/ably/ably-js/issues/881
  }
})

export default ablyClient
