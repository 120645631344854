import { endOfMonth, format, startOfMonth } from 'date-fns'
import { z } from 'zod'
import Api, { ApiError } from '..'
import { isNil } from 'lodash'

export enum CalendarEventStatus {
  CONFIRMED = 'CONFIRMED',
  TENTATIVE = 'TENTATIVE',
  CANCELLED = 'CANCELLED'
}

export enum CalendarEventAttendeeStatus {
  NEEDS_ACTION = 'NEEDS_ACTION',
  DECLINED = 'DECLINED',
  TENTATIVE = 'TENTATIVE',
  ACCEPTED = 'ACCEPTED'
}

export const calendarEventBaseParser = z.object({
  id: z.string().nullish(),
  eventId: z.string().nullish(),
  isPinEvent: z.boolean().nullish(),
  orgId: z.string().uuid().nullish(),
  userId: z.string().uuid().nullish(),
  emailAccountId: z.string().uuid().nullish(),
  candidateId: z.string().uuid().nullish(),
  jobId: z.string().uuid().nullish(),
  candidateJobId: z.string().uuid().nullish(),
  title: z.string(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  location: z.string().nullish(),
  eventStatus: z.nativeEnum(CalendarEventStatus).nullish(),
  meetingLink: z.string().nullish(),
  // attendees: z.array(z.string()).nullish()
  attendees: z.union([
    z.array(z.string()),
    z.array(z.object({
      address: z.string(),
      name: z.string()
    }))
  ]).nullish()
})

export const calendarEventParser = calendarEventBaseParser.extend({
  description: z.string().nullish(),
  attendeeStatus: z.nativeEnum(CalendarEventAttendeeStatus).nullish()
  // createdAt: z.coerce.date(),
  // updatedAt: z.coerce.date()
})

export type CalendarEvent = z.infer<typeof calendarEventParser>

export const newCalendarEventParser = z.object({
  id: z.string().nullish(),
  isPinEvent: z.boolean(),
  startPosition: z.number(),
  endPosition: z.number(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  title: z.string(),
  description: z.string().nullish(),
  attendees: z.array(z.object({
    address: z.string(),
    name: z.string()
  })),
  candidateJobId: z.string()
})

export type NewCalendarEvent = z.infer<typeof newCalendarEventParser>

export interface FetchCalendarEventsRequestArgs {
  requestedCalendars?: string[]
  startDate?: string
  endDate?: string
}

export const fetchCalendarEventsResponseParser = z.object({
  calendarId: z.string(),
  accessible: z.boolean(),
  busyFreeEvents: z.array(z.object({
    internalId: z.string().nullish(),
    eventId: z.string().nullable(),
    title: z.string(),
    start: z.coerce.date(),
    end: z.coerce.date(),
    location: z.string().nullish()
  }))
})

export type FetchCalendarEventsResponse = z.infer<typeof fetchCalendarEventsResponseParser>

export interface CalendarEvents {
  accessible: boolean
  events: CalendarEvent[]
}

export async function fetchCalendarEvents ({ requestedCalendars, startDate, endDate }: FetchCalendarEventsRequestArgs): Promise<CalendarEvents> {
  const startDateToUse = startDate ?? format(startOfMonth(new Date()), "yyyy-MM-dd'T'HH:mm:ssXXX")
  const endDateToUse = endDate ?? format(endOfMonth(new Date()), "yyyy-MM-dd'T'HH:mm:ssXXX")

  try {
    const { status, data } = await Api.post<FetchCalendarEventsResponse[]>(
      '/me/calendar/busy_free',
      {
        startDateTime: startDateToUse,
        endDateTime: endDateToUse
      },
      {
        emails: requestedCalendars
      }
    )

    if (status !== 200) {
      return {
        accessible: false,
        events: []
      }
    }

    const calendarEventsForRequestedUser = (requestedCalendars?.length ?? 0) >= 1 ? data?.find(calendar => calendar.calendarId === requestedCalendars?.[0]) : data?.[0]
    const parsed = fetchCalendarEventsResponseParser.parse(calendarEventsForRequestedUser)
    const prepared: CalendarEvent[] = parsed.busyFreeEvents?.map(event => {
      return {
        ...event,
        isPinEvent: !isNil(event.internalId),
        id: event.internalId
        // attendees: event?.meta?.pinData?.attendees
      }
    })
    return {
      accessible: parsed.accessible,
      events: prepared
    }
  } catch (error) {
    console.error('Error fetching calendar events:', error)
    return {
      accessible: false,
      events: []
    }
  }
}

export async function createCalendarEvent (newEvent: NewCalendarEvent): Promise<CalendarEvent> {
  const { data, status } = await Api.post('/me/calendar/event', null, {
    event: newEvent
  })
  if (status !== 200) {
    throw new ApiError('Failed to create calendar event')
  }

  return calendarEventParser.parse(data?.event)
}

export async function updateCalendarEvent (updatedEvent: CalendarEvent): Promise<CalendarEvent> {
  const { data } = await Api.put(`/me/calendar/event/${updatedEvent.id}`, null, {
    event: {
      title: updatedEvent.title,
      description: updatedEvent.description,
      start: updatedEvent.start,
      end: updatedEvent.end
    }
  })
  return calendarEventParser.parse(data?.event)
}

export async function deleteCalendarEvent (id: string): Promise<void> {
  await Api.delete(`/me/calendar/event/${id}`)
}
