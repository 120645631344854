import styled from 'styled-components'

const BaseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-gap: 2px;
`

export const Chart = styled.div`
  display: grid;
  grid-template-columns: repeat(25, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-gap: 2px;
`

export const Inner = styled(BaseGrid)`
  grid-column: 2 / span 24;
  grid-row: 1 / span 7;
`

export const Tile = styled.div`
  position: relative;
  aspect-ratio: 1/1;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border: solid 2px;
    border-color: ${({ theme }) => theme.colors.fgTranslucent25};
    opacity: 0;
    transition: 0.15s ease-in-out;
    z-index: 2;
    }
  }
  @media (hover: hover) {
    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
`

export const LegendX = styled(BaseGrid)`
  grid-column: 2 / span 24;
  grid-row: 8;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: unset;
  grid-gap: 2px;
`

export const LegendY = styled(BaseGrid)`
  grid-column: 1;
  grid-row: 1 / span 7;
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  grid-template-columns: unset;
  grid-gap: 2px;
`

export const LegendTile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.fgSecondary};
  letter-spacing: 0.2px;
  text-transform: uppercase;
`

export const FloatingLegend = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 120px;
  height: 68px;
  padding: 0.75rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.lg};
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out;
  z-index: 3;
  p:last-child {
    font-weight: 700;
  }
`

export const LegendIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 99px;
  flex: 0 0 10px;
  background-color: ${({ theme }) => theme.colors.chart1};
`
