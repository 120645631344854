import { useMemo } from 'react'
import { PageHeader } from 'src/components/blocks/page-header'
import { ChartWrapper } from 'src/components/charts/chart-wrapper'
import { Flex } from 'src/components/primitives/flex'
import { GroupedBarChart } from 'src/components/charts/grouped-bar-chart'
import { SelectableChartGroup } from 'src/components/charts/selectable-chart-group'
import { useCandidateJobCountsQuery } from 'src/hooks/queries/use-candidate-job-counts'
import { useJobReportQuery } from 'src/hooks/queries/use-job-report'
import { groupBy } from 'lodash'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import { useParams } from 'react-router-dom'
import { EmptyState } from 'src/components/blocks/empty-state'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { CandidateJobRejectionReason, getCandidateRejectionReasonDisplay } from 'src/libs/api/backend/candidate_jobs'
import type { DataPoint as BarChartDataPoint } from 'src/components/charts/bar-chart'
import { SEO } from 'src/components/primitives/seo'
import { ReportFeedbackBanner } from 'src/components/blocks/report-feedback-banner'
import { getTeamActivityChartData, TeamActivityColumns, TeamActivitySourcedByColumns } from './tabular-chart-data'
import type { TeamActivityData, TeamActivitySourcedByData } from './tabular-chart-data'
import { Table } from 'src/components/primitives/table'
import { EmptyChart } from 'src/components/charts/empty-chart'

const ReportsCandidatesPage = (): JSX.Element => {
  const { jobId } = useParams()
  const { data: candidateJobCounts } = useCandidateJobCountsQuery()
  const { /* clickedEmails, */ total } = candidateJobCounts ?? {}

  const { data: jobReport, isLoading } = useJobReportQuery()

  const { data: orgUsers } = useOrgUsersQuery()

  const {
    // approvalStatusCounts,
    rejectionReasonCounts,
    candidateApprovalOverTimeCounts,
    openedOverTimeCounts,
    respondedOverTimeCounts,
    teamActivityEmailCounts,
    teamActivityRejectedCounts,
    teamActivitySourcedByCounts,
    teamActivitySourcedCounts,
    totalApproved,
    totalOpened,
    totalResponded,
    sourcedOverTimeCounts
  } = jobReport ?? {}

  const teamActivitySourcedByCountsWithPct = useMemo(() => {
    if (!teamActivitySourcedByCounts) {
      return []
    }
    return teamActivitySourcedByCounts.map((source): TeamActivitySourcedByData => {
      let seqTheme: 'positive' | 'warning' | 'neutral'
      let seqTxt
      if (source.sourced === 0) {
        seqTheme = 'neutral'
        seqTxt = '–'
      } else {
        const pct = Math.round(source.sequenced / source.sourced * 100)
        seqTxt = `${pct}%`
        seqTheme = pct >= 50 ? 'positive' : 'warning'
      }

      let commTheme: 'positive' | 'warning' | 'neutral'
      let commTxt
      if (source.sequenced === 0) {
        commTheme = 'neutral'
        commTxt = '–'
      } else {
        const pct = Math.round(source.communicating / source.sequenced * 100)
        commTxt = `${pct}%`
        commTheme = pct >= 20 ? 'positive' : 'warning'
      }

      return {
        ...source,
        id: source.source,
        seqTheme,
        seqTxt,
        commTheme,
        commTxt
      }
    })
  }, [teamActivitySourcedByCounts])

  const teamActivityChartData = useMemo(() => {
    return getTeamActivityChartData(
      teamActivityEmailCounts ?? [],
      teamActivityRejectedCounts ?? [],
      teamActivitySourcedCounts ?? [],
      orgUsers ?? []
    )
  }, [teamActivityEmailCounts, teamActivityRejectedCounts, teamActivitySourcedCounts, orgUsers])

  const sourcedOverTimeCountsData =
    sourcedOverTimeCounts?.map((sourced) => {
      return {
        label: sourced.date,
        Manual: sourced.manual,
        AI: sourced.ai
      }
    }) ?? []

  const candidateApprovalOverTimeData =
    candidateApprovalOverTimeCounts?.map((approval) => {
      return {
        label: approval.date,
        Approved: approval.approved,
        Archived: approval.rejected
      }
    }) ?? []

  const sourcedData = sourcedOverTimeCountsData
  const approvedOverTimeData = candidateApprovalOverTimeData
  const openedOverTimeData = openedOverTimeCounts?.map((opened) => ({
    label: opened.date,
    Opened: opened.opened
  })) ?? []
  const respondedOverTimeData = respondedOverTimeCounts?.map((responded) => ({
    label: responded.date,
    Replied: responded.responded
  })) ?? []

  const mergeSourcedData = (dataSets: BarChartDataPoint[][]): BarChartDataPoint[] => {
    const mergedData: Record<string, BarChartDataPoint> = {}

    dataSets.forEach(dataSet => {
      dataSet.forEach((data: BarChartDataPoint) => {
        const { label, ...metrics } = data
        if (!mergedData[label]) {
          mergedData[label] = { label, ...metrics }
        } else {
          Object.keys(metrics).forEach(metric => {
            if (typeof metrics[metric] === 'number') {
              mergedData[label][metric] = ((mergedData[label][metric] as number) || 0) + (metrics[metric])
            } else {
              mergedData[label][metric] = metrics[metric]
            }
          })
        }
      })
    })
    return Object.values(mergedData)
  }

  const combinedSourcedOverTimeData = mergeSourcedData([sourcedData, approvedOverTimeData, openedOverTimeData, respondedOverTimeData])

  // const approvalStatusData =
  //   approvalStatusCounts?.map((approval) => {
  //     return {
  //       name: approval.jobStageGroup,
  //       value: approval.count
  //     }
  //   }) ?? []

  const rejectionReasonsByStage = useMemo(
    () => (rejectionReasonCounts !== undefined ? groupBy(rejectionReasonCounts, 'fromStage') : {}),
    [rejectionReasonCounts]
  )

  const stageFriendlyName = (stage: string): string => {
    switch (stage) {
      case 'SOURCED':
        return 'Sourced'
      case 'PROSPECTING':
        return 'In outreach'
      case 'COMMUNICATING':
        return 'Communications'
      default:
        return 'All'
    }
  }

  const rejectionReasonFriendlyName = (reason: string): string => {
    if (reason in CandidateJobRejectionReason) {
      return getCandidateRejectionReasonDisplay(reason as CandidateJobRejectionReason) ?? 'Unknown'
    }

    return 'Unknown'
  }

  interface RejectionReason {
    rejectionReason: string
    rejectionCount: number
  }

  const topRejectionReasonsData = useMemo(() => {
    const allReasonsMap = new Map<string, RejectionReason>()

    const topRejectionReasonsData = Object.entries(rejectionReasonsByStage).map(
      ([stage, reasons]) => {
        const currentReasonsMap = new Map<string, RejectionReason>()

        const setMap = (map: Map<string, RejectionReason>, reason: RejectionReason): void => {
          const label = rejectionReasonFriendlyName(reason.rejectionReason)
          const existing = map.get(label)
          if (existing) {
            existing.rejectionCount += reason.rejectionCount
          } else {
            map.set(label, {
              rejectionReason: label,
              rejectionCount: reason.rejectionCount
            })
          }
        }

        reasons.forEach((reason) => {
          setMap(currentReasonsMap, reason)
          // Combine all reasons for all stages into a single list
          setMap(allReasonsMap, reason)
        })
        // Return the data for the current stage
        return {
          category: `${stageFriendlyName(stage)}`,
          data: Array.from(allReasonsMap.values()).map((reason) => ({
            label: reason.rejectionReason,
            value: reason.rejectionCount
          }))
        }
      }
    )

    const allReasons: RejectionReason[] = Array.from(allReasonsMap.values())

    topRejectionReasonsData.unshift({
      category: 'All',
      data: allReasons.map((reason) => ({
        label: reason.rejectionReason,
        value: reason.rejectionCount
      }))
    })

    return topRejectionReasonsData
  }, [rejectionReasonsByStage])

  const isEmpty = useMemo(
    () =>
      !totalApproved?.approved &&
      !rejectionReasonCounts?.length &&
      !totalOpened?.opened &&
      !totalResponded?.responded,
    [totalApproved, rejectionReasonCounts, totalOpened, totalResponded]
  )

  const header = useMemo(
    () => (
      <PageHeader
        heading="Reports · Candidates"
      />
    ),
    []
  )

  if (isLoading) {
    return (<LoadingSkeleton $variant="CandidateDetailsCard" />)
  }

  if (isEmpty && !isLoading) {
    return (
      <Flex $direction="column" $height="full">
        {header}
        <EmptyState
          heading="No data to report yet"
          description="As you start approving and archiving candidates we'll generate a report for you"
          svg="report"
          $borderRadius="6px"
          $padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
          actions={[
            {
              href: `/jobs/${jobId}/candidates/sourcing`,
              children: 'Go to sourcing'
            }
          ]}
        />
      </Flex>
    )
  }

  return (
    <>
      <SEO title='Candidate Reports' />
      {header}
      <Flex $direction="column" $gap={20}>
        <ReportFeedbackBanner />
        <ChartWrapper $variant="solid">
          <SelectableChartGroup
            total={total?.totalWithoutDataWarehouseSourcedCount ?? 0}
            groups={[
              {
                label: 'All activities',
                value: total?.totalWithoutDataWarehouseSourcedCount ?? 0,
                chartTitle: 'All activities over time',
                chartData: combinedSourcedOverTimeData
              },
              {
                label: 'Total sourced',
                value: total?.totalWithoutDataWarehouseSourcedCount ?? 0,
                chartTitle: 'Total sourced candidates',
                chartData: sourcedOverTimeCountsData
              },
              {
                label: 'Approved',
                value: totalApproved?.approved ?? 0,
                chartTitle: 'Candidates approval over time',
                chartData: approvedOverTimeData
              },
              {
                label: 'Opened email',
                value: totalOpened?.opened ?? 0,
                chartTitle: 'Candidates email open rate over time',
                chartData: openedOverTimeData
              },
              {
                label: 'Replied',
                value: totalResponded?.responded ?? 0,
                chartTitle: 'Replies over time',
                chartData: respondedOverTimeData
              }
            ]}
          />
        </ChartWrapper>
        <ChartWrapper>
          <GroupedBarChart
            title="Top archive reasons"
            dataKey="Candidates"
            data={topRejectionReasonsData}
          />
        </ChartWrapper>
        <ChartWrapper>
          <Table<TeamActivityData>
            tableData={teamActivityChartData}
            columns={TeamActivityColumns}
            emptyState={<EmptyChart $minHeight='80px' />}
          />
        </ChartWrapper>
        <ChartWrapper>
          <Table<TeamActivitySourcedByData>
            tableData={teamActivitySourcedByCountsWithPct}
            columns={TeamActivitySourcedByColumns}
            emptyState={<EmptyChart $minHeight='80px' />}
          />
        </ChartWrapper>
        <div></div> {/* Hack to add padding to bottom of reports */}
      </Flex>
    </>
  )
}

export default ReportsCandidatesPage
