import { z } from 'zod'
import Api from '..'

const mergeLinkTokenParser = z.object({
  linkToken: z.string()
})

export async function fetchMergeLinkToken (integration?: string): Promise<string> {
  const { data } = await Api.get('/merge', { integration })
  return mergeLinkTokenParser.parse(data).linkToken
}

export async function submitMergePublicToken (publicToken: string): Promise<void> {
  await Api.post('/merge', null, { publicToken })
}
