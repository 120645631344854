import type { Spacing } from 'src/styles/theme/types'
import { Button } from '../button'
import type { ButtonStyleProps } from '../button'
import { useTab } from 'src/hooks/use-tab'
import * as S from './tabbar.styled'

export interface Tab {
  title: string | React.ReactNode
  value: string
  onSelect?: (event: React.MouseEvent<HTMLElement>) => void
  href?: string
}

export interface TabbarStyleProps {
  $variant?: 'selectTint' | 'selectNormal' | 'pillsTint' | 'pillsNormal' | 'line'
}

export interface TabbarProps extends TabbarStyleProps {
  ariaLabel: string
  initiallySelected?: Tab['value']
  tabs: Tab[]
  trackInUrl?: boolean
  size?: Spacing
}

export const Tabbar = ({
  ariaLabel,
  tabs,
  initiallySelected,
  trackInUrl = false,
  size = 24,
  $variant = 'selectTint'
}: TabbarProps): JSX.Element => {
  const { selectedTab, setTab } = useTab({ tabs, trackInUrl, initiallySelected })

  const getButtonVariant = (isSelected: boolean): ButtonStyleProps['$variant'] => {
    switch ($variant) {
      case 'selectTint':
      case 'selectNormal':
        return isSelected ? 'raised' : 'ghost'
      case 'pillsTint':
        return 'flat'
      case 'pillsNormal':
        return isSelected ? 'flat' : 'ghost'
      default:
        return isSelected ? 'raised' : 'ghost'
    }
  }

  const getButtonColor = (isSelected: boolean): ButtonStyleProps['$colorTheme'] => {
    switch ($variant) {
      case 'selectTint':
        return isSelected ? 'tint' : 'normal'
      case 'selectNormal':
        return isSelected ? 'normal' : 'normal'
      case 'pillsTint':
        return isSelected ? 'tint' : 'normal'
      case 'pillsNormal':
        return 'normal'
      default:
        return isSelected ? 'tint' : 'muted'
    }
  }

  return (
    <S.Tabbar
      $variant={$variant}
      role="tablist"
      aria-label={ariaLabel}
      aria-orientation="horizontal"
    >
      {tabs?.map((tab) => (
        <Button
          key={tab.value}
          $variant={getButtonVariant(tab.value === selectedTab?.value)}
          $colorTheme={getButtonColor(tab.value === selectedTab?.value)}
          $height={size}
          $fontSize={12}
          onClick={(event) => {
            setTab(tab)
            if (tab.onSelect) {
              tab.onSelect(event)
            }
          }}
          href={tab.href}
        >
          {tab.title}
        </Button>
      ))}
    </S.Tabbar>
  )
}
