import styled, { keyframes } from 'styled-components'
import type { Notification } from 'src/stores/notifications'
import { NAV_HEIGHT_LG } from 'src/styles/constants'
import type { Keyframes } from 'styled-components/dist/types'

interface NotificationStyleProps {
  $variant?: Notification['variant']
  $disappearing?: boolean
  $position?: Notification['position']
  $hasAction?: boolean
}

export const NotificationsContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  z-index: ${({ theme }) => theme.zindeces.toast};
`

const fadeIn = (offsetFrom: string = '20px', offsetTo: string = '0px', offsetX: string = '0%'): Keyframes => keyframes`
  from {
    opacity: 0;
    transform: translate(${offsetX}, ${offsetFrom});
  }
  to {
    opacity: 1;
    transform: translate(${offsetX}, ${offsetTo});
  }
`

const fadeOut = (offset: string = '20px', offsetX: string = '0%'): Keyframes => keyframes`
  from {
    opacity: 1;
    transform: translate(${offsetX}, 0);
  }
  to {
    opacity: 0;
    transform: translate(${offsetX}, ${offset});
  }
`

export const Toast = styled.div<NotificationStyleProps>`
  position: absolute;
  /* adjust for Intercom widget */
  bottom: ${({ $position }) => $position === 'bottom-center' ? '1.25rem' : 'calc(2.5rem + 2.75rem)'};
  right: ${({ $position }) => ($position === 'bottom-center' ? '50vw' : '1.25rem')};
  transition: transform 0.3s cubic-bezier(0.77, 0, 0.18, 1);
`

export const ToastContentContainer = styled.div<NotificationStyleProps>`
  position: relative;
  border-radius: 8px;
  border: solid 1px;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  border-color: ${({ $variant, theme }) => {
    switch ($variant) {
      case 'neutral': {
        return theme.colors.bgSecondary
      }
      case 'warning': {
        return theme.colors.warningTranslucent25
      }
      case 'positive': {
        return theme.colors.positiveTranslucent25
      }
      case 'tint': {
        return theme.colors.tintTranslucent25
      }
      case 'negative': {
        return theme.colors.negativeTranslucent25
      }
    }
  }};
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1), 0px 1px 4px 0px rgba(0, 0, 0, 0.05);
  animation: ${({ $disappearing }) => ($disappearing ? fadeOut() : fadeIn())} 0.36s
    cubic-bezier(0.77, 0, 0.18, 1) forwards;
  overflow: hidden;
  z-index: 2;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    z-index: 1;
  }
`

export const ToastInner = styled.div<NotificationStyleProps>`
  position: relative;
  display: flex;
  align-items: ${({ $hasAction }) => $hasAction ? 'center' : 'flex-start'};
  gap: 0.8rem;
  padding: 1rem;
  background-color: ${({ $variant, theme }) => {
    switch ($variant) {
      case 'neutral': {
        return theme.colors.bgTertiary
      }
      case 'warning': {
        return theme.colors.warningTranslucent5
      }
      case 'positive': {
        return theme.colors.positiveTranslucent5
      }
      case 'tint': {
        return theme.colors.tintTranslucent5
      }
      case 'negative': {
        return theme.colors.negativeTranslucent5
      }
    }
  }};
  z-index: 2;
`

export const Content = styled.div<NotificationStyleProps>`
  max-width: 240px;
  p {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: ${({ theme }) => theme.fontWeights[500]};
    color: ${({ $variant, theme }) => {
      switch ($variant) {
        case 'neutral': {
          return theme.colors.fgPrimary
        }
        case 'positive': {
          return theme.colors.positiveBg
        }
        case 'warning': {
          return theme.colors.warningBg
        }
        case 'tint': {
          return theme.colors.tintFg
        }
        case 'negative': {
          return theme.colors.negativeBg
        }
      }
    }};
  }
`

export const Close = styled.div`
  margin-left: 2rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const CustomBanners = styled.div`
  position: absolute;
  top: calc(${NAV_HEIGHT_LG} + 0.75rem);
  left: calc(50vw - 127px);
  width: 254px;
  min-height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const CustomBanner = styled.div<{ $disappearing?: boolean, $offset: number, $width: string }>`
  /* position: absolute; */
  /* top: calc(${NAV_HEIGHT_LG} + 0.75rem); */
  /* left: 50vw; */
  width: ${({ $width }) => $width};
  transition: all 0.3s cubic-bezier(0.77, 0, 0.18, 1);
  animation: ${({ $disappearing, $offset }) => ($disappearing ? fadeOut('8px', '0%') : fadeIn(`${8 + $offset}px`, `${$offset}px`, '-0%'))} 0.36s
    cubic-bezier(0.77, 0, 0.18, 1) forwards;
`

export const CustomBannerInner = styled.div<{ $scale: number }>`
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  transition: transform 0.5s ease-in-out;
  transform: ${({ $scale }) => `scale(${$scale ?? 1})`};
`
