import styled from 'styled-components'
import type { StandaloneSwitchStyleProps } from './standalone-switch'

export const StandaloneSwitch = styled.div<StandaloneSwitchStyleProps>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  align-items: center;
  gap: 0.5rem;
  padding: ${({ $variant }) => $variant === 'ghost' ? 0 : '0.5rem'};
  border-radius: 0.5rem;
  background-color: ${({ theme, $variant }) => $variant === 'ghost' ? 'transparent' : theme.colors.bgTertiary};
  label {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: ${({ theme }) => theme.fontWeights[400]};
    letter-spacing: ${({ theme }) => theme.letterSpacings['0.12']};
  }
`
