import { useMutation } from '@tanstack/react-query'
import { moveCandidatesToAnotherJob } from 'src/libs/api/backend/candidate_jobs'
import type {
  CandidateJobExpanded,
  CandidateJobRejectionReason,
  CandidateJobStage,
  CandidateJobsMove
} from 'src/libs/api/backend/candidate_jobs'
import queryClient from '../query-client'
import { queryKeys } from 'src/libs/query-keys'
import { useSetAtom } from 'jotai'
import { notifyAtom, notifyErrorAtom } from 'src/stores/notifications'

interface Args {
  candidateJobIds: string[]
  destinationJobId: string
  rejectionReason?: CandidateJobRejectionReason
  destinationStage: CandidateJobStage
}

interface Res {
  setMoveCandidatesToAnotherJob: ({
    candidateJobIds,
    destinationJobId,
    rejectionReason,
    destinationStage
  }: Args) => void
}

export const useMoveCandidatesToAnotherJob = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)
  const notify = useSetAtom(notifyAtom)

  const mutation = useMutation({
    mutationFn: async ({ candidateJobIds, destinationJobId, rejectionReason, destinationStage }: Args) => {
      return await moveCandidatesToAnotherJob(candidateJobIds, destinationJobId, destinationStage, rejectionReason)
    },
    onError: (err) => {
      notifyError({
        message: `An error occurred when moving candidates: ${err.message}`
      })
    },
    onSuccess: async ({ deleted, rejected, added }: CandidateJobsMove) => {
      const candidateJobIdsSet = new Set([...deleted, ...rejected].map((candidateJob) => candidateJob.id))
      queryClient.setQueriesData<CandidateJobExpanded[]>(
        {
          queryKey: [queryKeys.candidateJobs]
        },
        (oldCandidateJobs) => {
          if (!oldCandidateJobs) {
            return oldCandidateJobs
          }
          return oldCandidateJobs.reduce(
            (accu: CandidateJobExpanded[], oldCandidateJob: CandidateJobExpanded) => {
              if (candidateJobIdsSet.has(oldCandidateJob.id)) {
                return accu
              }
              return [...accu, oldCandidateJob]
            },
            []
          )
        }
      )

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateJobCounts]
        })
      ])

      notify({
        type: 'candidate-moved',
        variant: 'positive',
        payload: added
      })
    }
  })

  const setMoveCandidatesToAnotherJob = (args: Args): void => {
    mutation.mutate(args)
  }

  return { setMoveCandidatesToAnotherJob }
}
