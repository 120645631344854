import { z } from 'zod'
import Api from '..'

const minMaxCriteriaParser = z.object({
  min: z.number().nullish(),
  max: z.number().nullish(),
  optional: z.boolean().nullish()
})

export type MinMaxCriteria = z.infer<typeof minMaxCriteriaParser>

const schoolCompanyCriteriaParser = z.object({
  name: z.string().optional(),
  linkedin: z.string().optional(),
  domain: z.string().optional(),
  logoUrl: z.string().url().nullish(),
  negative: z.boolean().nullish(),
  optional: z.boolean().nullish()
})

export type SchoolCompanyCriteria = z.infer<typeof schoolCompanyCriteriaParser>

const locationCriteriaParser = z.object({
  city: z.string().nullish(),
  state: z.string().nullish(),
  metro: z.string().nullish(),
  radiusInMiles: z.number().nullish(),
  negative: z.boolean().nullish(),
  optional: z.boolean().nullish()
})

export type LocationCriteria = z.infer<typeof locationCriteriaParser>

const customRequirementCriteriaParser = z.object({
  requirement: z.string(),
  optional: z.boolean().optional()
})

export type CustomRequirementCriteria = z.infer<typeof customRequirementCriteriaParser>

const jobTitleCriteriaParser = z.object({
  name: z.string(),
  optional: z.boolean().nullish(),
  negative: z.boolean().nullish()
})

export type JobTitleCriteria = z.infer<typeof jobTitleCriteriaParser>

const languageCriteriaParser = z.object({
  name: z.string(),
  optional: z.boolean().nullish()
})

export type LanguageCriteria = z.infer<typeof languageCriteriaParser>

const booleanCriteriaParser = z.object({
  value: z.boolean(),
  optional: z.boolean().nullish()
})

export type BooleanCriteria = z.infer<typeof booleanCriteriaParser>

const standardCriteriaParser = z.object({
  name: z.string(),
  negative: z.boolean().nullish(),
  optional: z.boolean().nullish()
})

export type StandardCriteria = z.infer<typeof standardCriteriaParser>

export enum CriteriaKey {
  CURRENT_JOB_TITLES = 'current_job_titles',
  GRADUATION_YEAR = 'graduation_year',
  DEGREE = 'Highest degree',
  CURRENT_COMPANY_HC = 'current_company_hc',
  CURRENT_COMPANY_JOINED_AT_HEADCOUNT = 'current_company_joined_at_headcount',
  ANY_COMPANY_JOINED_AT_HEADCOUNT = 'any_company_joined_at_headcount',
  CURRENT_COMPANY_CURRENT_FUNDING_ROUND = 'current_company_current_funding_round',
  CURRENT_COMPANY_JOINED_AT_FUNDING_ROUND = 'current_company_joined_at_funding_round',
  ANY_COMPANY_JOINED_AT_FUNDING_ROUND = 'any_company_joined_at_funding_round',
  MONTHS_IN_CURRENT_PROFESSION = 'months_in_current_profession',
  MONTHS_AT_CURRENT_COMPANY = 'months_at_current_company',
  AVERAGE_TENURE_IN_MONTHS = 'average_tenure_in_months',
  KNOWN_LANGUAGES = 'known_languages',
  LOCATIONS = 'locations',
  COMPANIES = 'companies',
  SCHOOLS = 'schools',
  ATTENDED_IVY_LEAGUE = 'attended_ivy_league',
  CURRENT_COMPANY = 'current_company',
  CURRENT_COMPANY_HAS_NOTABLE_INVESTOR = 'current_company_has_notable_investor',
  WORKED_AT_COMPANY_WITH_NOTABLE_INVESTOR = 'worked_at_company_with_notable_investor',
  WORKS_AT_YC_COMPANY = 'works_at_yc_company',
  WORKED_AT_YC_COMPANY = 'worked_at_yc_company',
  CUSTOM_REQUIREMENTS = 'custom_requirements',
  ASSIGNED_TIMEZONES = 'assigned_timezones',
  SEX = 'sex',
  HAS_VISA_SPONSORSHIP = 'has_visa_sponsorship'
}

export const DURATION_MONTHS_CRITERIA_KEYS = [
  CriteriaKey.MONTHS_IN_CURRENT_PROFESSION,
  CriteriaKey.MONTHS_AT_CURRENT_COMPANY,
  CriteriaKey.AVERAGE_TENURE_IN_MONTHS
]

export const SCHOOL_CRITERIA_KEYS = [
  CriteriaKey.SCHOOLS
]

export const COMPANY_CRITERIA_KEYS = [
  CriteriaKey.CURRENT_COMPANY,
  CriteriaKey.COMPANIES
]

export const LOCATION_CRITERIA_KEYS = [
  CriteriaKey.LOCATIONS
]

export const DEFAULT_MIN_MAX_CRITERIA = {
  min: undefined,
  max: undefined,
  optional: false
}

export const DEFAULT_CUSTOM_REQUIREMENT_CRITERIA = {
  requirement: '',
  optional: true
}

export const DEFAULT_BOOLEAN_CRITERIA = {
  value: true,
  optional: false
}

export const criteriaParser = z.object({
  [CriteriaKey.CURRENT_JOB_TITLES]: z.array(jobTitleCriteriaParser).optional(),
  [CriteriaKey.LOCATIONS]: z.array(locationCriteriaParser).optional(),
  [CriteriaKey.MONTHS_IN_CURRENT_PROFESSION]: minMaxCriteriaParser.optional(),
  [CriteriaKey.MONTHS_AT_CURRENT_COMPANY]: minMaxCriteriaParser.optional(),
  [CriteriaKey.AVERAGE_TENURE_IN_MONTHS]: minMaxCriteriaParser.optional(),
  [CriteriaKey.COMPANIES]: z.array(schoolCompanyCriteriaParser).optional(),
  [CriteriaKey.CURRENT_COMPANY_HC]: minMaxCriteriaParser.optional(),
  [CriteriaKey.CURRENT_COMPANY_JOINED_AT_HEADCOUNT]: minMaxCriteriaParser.optional(),
  [CriteriaKey.ANY_COMPANY_JOINED_AT_HEADCOUNT]: minMaxCriteriaParser.optional(),
  [CriteriaKey.CURRENT_COMPANY_CURRENT_FUNDING_ROUND]: minMaxCriteriaParser.optional(),
  [CriteriaKey.CURRENT_COMPANY_JOINED_AT_FUNDING_ROUND]: minMaxCriteriaParser.optional(),
  [CriteriaKey.ANY_COMPANY_JOINED_AT_FUNDING_ROUND]: minMaxCriteriaParser.optional(),
  [CriteriaKey.SCHOOLS]: z.array(schoolCompanyCriteriaParser).optional(),
  [CriteriaKey.ATTENDED_IVY_LEAGUE]: booleanCriteriaParser.optional(),
  [CriteriaKey.GRADUATION_YEAR]: minMaxCriteriaParser.optional(),
  [CriteriaKey.DEGREE]: minMaxCriteriaParser.optional(),
  [CriteriaKey.CURRENT_COMPANY_HAS_NOTABLE_INVESTOR]: booleanCriteriaParser.optional(),
  [CriteriaKey.WORKED_AT_COMPANY_WITH_NOTABLE_INVESTOR]: booleanCriteriaParser.optional(),
  [CriteriaKey.WORKS_AT_YC_COMPANY]: booleanCriteriaParser.optional(),
  [CriteriaKey.WORKED_AT_YC_COMPANY]: booleanCriteriaParser.optional(),
  [CriteriaKey.CURRENT_COMPANY]: z.array(schoolCompanyCriteriaParser).optional(),
  [CriteriaKey.KNOWN_LANGUAGES]: z.array(languageCriteriaParser).optional(),
  [CriteriaKey.ASSIGNED_TIMEZONES]: z.array(standardCriteriaParser).optional(),
  [CriteriaKey.SEX]: z.array(standardCriteriaParser).optional(),
  [CriteriaKey.HAS_VISA_SPONSORSHIP]: booleanCriteriaParser.optional(),
  [CriteriaKey.CUSTOM_REQUIREMENTS]: z.array(customRequirementCriteriaParser).optional()
})

export type CriteriaValue = MinMaxCriteria | SchoolCompanyCriteria[] | LocationCriteria[] | CustomRequirementCriteria[]

export type Criteria = z.infer<typeof criteriaParser>

export interface GenerateSearchCriteriaParams {
  jobId: string
  instructions: string
  previousCriteria: Criteria | undefined
}

export async function generateSearchCriteriaApi ({ jobId, instructions, previousCriteria }: GenerateSearchCriteriaParams): Promise<Criteria> {
  const { data } = await Api.post(`/jobs/${jobId}/generate_criteria`, null, { instructions, previousCriteria })
  return criteriaParser.parse(data)
}

const generateLocationCriteriaParser = z.object({
  locations: z.array(locationCriteriaParser)
})

export type GenerateLocationCriteria = z.infer<typeof generateLocationCriteriaParser>

export interface GenerateLocationCriteriaParams {
  jobId: string
  instructions: string
}

export async function generateLocationCriteriaApi ({ jobId, instructions }: GenerateLocationCriteriaParams): Promise<GenerateLocationCriteria> {
  const { data } = await Api.post(`/jobs/${jobId}/generate_criteria/locations`, null, { instructions })
  return generateLocationCriteriaParser.parse(data)
}
