import type { SVGProps } from 'react'
import { Rectangle } from 'recharts'

interface ChartCursorProps extends SVGProps<SVGElement> {
  $fill: string
}

export const ChartCursor = ({ x, y, width, height, $fill }: ChartCursorProps): JSX.Element => {
  return (
    <Rectangle
      fill={$fill}
      stroke={$fill}
      x={Number(x)}
      y={Number(y)}
      width={Number(width)}
      height={Number(height)}
    />
  )
}
