import { ReactNodeViewRenderer } from '@tiptap/react'
import { Node, mergeAttributes } from '@tiptap/core'
import { RefinementComponent } from './refinement-component'

const COMPONENT_TAG = 'refinement-component'

const RefinementComponentExtension = Node.create({
  name: 'refinementComponent',
  content: 'inline*',
  group: 'inline',
  inline: true,
  atom: true,
  selectable: false,

  addAttributes () {
    return {
      data: {
        default: '{}'
      }
    }
  },

  parseHTML () {
    return [
      {
        tag: 'refinement-component'
      }
    ]
  },

  renderHTML ({ HTMLAttributes }) {
    return [COMPONENT_TAG, mergeAttributes(HTMLAttributes), 0]
  },

  addNodeView () {
    return ReactNodeViewRenderer(RefinementComponent)
  }
})

export default RefinementComponentExtension
