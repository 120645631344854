import type { TooltipProps } from 'recharts'
import * as S from './chart-tooltip.styled'
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'

export const ChartTooltip = ({
  label,
  active,
  payload
}: TooltipProps<ValueType, NameType>): JSX.Element | null => {
  if (!active || !payload) {
    return null
  }
  return (
    <S.TooltipContainer>
      <S.TooltipTitle>{label}</S.TooltipTitle>
      {payload.map((data) => (
        <S.TooltipRow key={data.name} $color={data.color}>
          <span>{data.name}</span>
          <span>{data.value}</span>
        </S.TooltipRow>
      ))}
    </S.TooltipContainer>
  )
}
