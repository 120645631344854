import { Button } from 'src/components/primitives/button'
import { Dropdown } from 'src/components/primitives/dropdown'
import { useRejectCandidate } from 'src/hooks/mutations/use-reject-candidate'
import { CandidateJobStatus, exportCandidateJobsToCsv, inboxRejectionReasons } from 'src/libs/api/backend/candidate_jobs'
import type {
  CandidateJobExpanded,
  CandidateJobRejectionReason
} from 'src/libs/api/backend/candidate_jobs'
import { isNil, map } from 'lodash'
import { Icons } from 'src/components/primitives/icon'
import { usePauseCandidateSequence } from 'src/hooks/mutations/use-pause-candidate-job-sequence'
import { useUndoStageTransition } from 'src/hooks/mutations/use-undo-stage-transition'
import type { Job } from 'src/libs/api/backend/jobs'
import { useParams } from 'react-router-dom'
import { useSession } from 'src/hooks/use-session'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { useQueryParams } from 'src/hooks/use-query-params'
import { SettingParams } from 'src/components/dialogs/default-settings-dialog'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectedRowsAtom, clearSelectedRowsAtom } from 'src/stores/table'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface PauseResumeSequenceButtonProps {
  isPause?: boolean
}

export const PauseResumeSequenceButton = ({
  isPause = true
}: PauseResumeSequenceButtonProps): JSX.Element => {
  const selectedRows = useAtomValue(selectedRowsAtom)
  const clearSelectedRows = useSetAtom(clearSelectedRowsAtom)
  const { setCandidateSequencePause } = usePauseCandidateSequence()

  return (
    <Button
      $variant="outline"
      $colorTheme="tint"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.pauseCircle}
      onClick={() => {
        setCandidateSequencePause({
          candidateJobIds: Array.from(selectedRows),
          pause: isPause,
          onSuccess: () => {
            clearSelectedRows()
          }
        })
      }}
    >
      {isPause ? 'Pause' : 'Unpause'} outreach
    </Button>
  )
}

export const RejectButton = (): JSX.Element => {
  const selectedRows = useAtomValue(selectedRowsAtom)
  const clearSelectedRows = useSetAtom(clearSelectedRowsAtom)
  const { rejectCandidate } = useRejectCandidate()
  return (
    <Dropdown
      trigger={
        <Button
          nested
          $variant="outline"
          $colorTheme="negative"
          $height={24}
          $fontSize={12}
          leadingIcon={Icons.xOctagon}
          trailingIcon="chevron-down"
        >
          Archive candidate{selectedRows.size >= 2 && 's'}
        </Button>
      }
      items={map(inboxRejectionReasons, (reason, key) => ({
        id: key,
        title: reason,
        onSelect: () => {
          rejectCandidate({
            candidateJobIds: Array.from(selectedRows),
            rejectionReason: key as CandidateJobRejectionReason
          })
          clearSelectedRows()
          // setSelectedRows({})
        }
      }))}
      size="small"
    />
  )
}

interface WriteEmailButtonProps {
  candidateJobs?: CandidateJobExpanded[]
}

export const WriteEmailButton = ({
  candidateJobs
}: WriteEmailButtonProps): JSX.Element => {
  const openDialog = useSetAtom(openDialogAtom)

  const selectedRows = useAtomValue(selectedRowsAtom)

  const areSelectedCandidatesInvalid = (): boolean => {
    const invalidArguments = [CandidateJobStatus.EMAIL_NOT_FOUND, CandidateJobStatus.BOUNCED]
    const selectedCandidateJobs = candidateJobs?.filter((candidateJob) =>
      selectedRows.has(candidateJob.id)
    )
    return Boolean(
      selectedCandidateJobs?.every(
        (candidateJob) =>
          candidateJob.statusDisplay?.status &&
          invalidArguments.includes(candidateJob.statusDisplay.status)
      )
    )
  }

  return (
    <Button
      $variant="outline"
      $colorTheme="normal"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.mail}
      disabled={areSelectedCandidatesInvalid()}
      onClick={() => {
        const candidateJobIds = Array.from(selectedRows)
        const payload = candidateJobs?.filter((candidate) => candidateJobIds.includes(candidate.id))
        openDialog({ id: DialogId.WRITE_MESSAGE, payload })
      }}
    >
      Write message{selectedRows.size >= 2 && 's'}
    </Button>
  )
}

export const UndoRejectButton = (): JSX.Element => {
  const selectedRows = useAtomValue(selectedRowsAtom)
  const { undoStageTransition } = useUndoStageTransition({ isRestoringCandidates: true })

  return (
    <Button
      $variant="outline"
      $colorTheme="normal"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.undo}
      onClick={() => {
        undoStageTransition(Array.from(selectedRows))
      }}
    >
      Restore candidate{selectedRows.size >= 2 && 's'}
    </Button>
  )
}

interface MoveToAnotherJobProps {
  candidateJobs?: CandidateJobExpanded[]
}

export const MoveToAnotherJobButton = ({
  candidateJobs
}: MoveToAnotherJobProps): JSX.Element => {
  const openDialog = useSetAtom(openDialogAtom)
  const selectedRows = useAtomValue(selectedRowsAtom)

  return (
    <Button
      $variant="outline"
      $colorTheme="normal"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.arrowRightCircle}
      onClick={() => {
        const candidateJobIds = Array.from(selectedRows)
        const payload = {
          candidateJobs: candidateJobs?.filter((candidate) =>
            candidateJobIds.includes(candidate.id)
          ),
          requiredReason: true
        }
        openDialog({ id: DialogId.MOVE_TO_ANOTHER_JOB, payload })
      }}
    >
      Move to another job
    </Button>
  )
}

interface ExportToAtsButtonProps {
  candidateJobs?: CandidateJobExpanded[]
  job?: Job
}

export const ExportToAtsButton = ({
  candidateJobs,
  job
}: ExportToAtsButtonProps): JSX.Element => {
  const selectedRows = useAtomValue(selectedRowsAtom)
  const openDialog = useSetAtom(openDialogAtom)
  const { org, featureFlags } = useSession()
  const { setParam } = useQueryParams()

  if (!featureFlags?.includes(FeatureFlags.ATS_INTEGRATION) && !isNil(job)) {
    return <></>
  }

  return (
    <Button
      $variant="outline"
      $colorTheme="normal"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.share}
      onClick={() => {
        if (isNil(org?.mergeAtsIntegration)) {
          setParam('settings', SettingParams.ATS)
          openDialog({ id: DialogId.DEFAULT_SETTINGS })
        } else {
          const candidateJobIds = Array.from(selectedRows)
          const payload = {
            job,
            candidateJobs: candidateJobs?.filter((candidate) => candidateJobIds.includes(candidate.id)),
            requiredReason: true
          }
          openDialog({ id: DialogId.EXPORT_CANDIDATES, payload })
        }
      }}
    >
      Export to ATS
    </Button>
  )
}

export const ExportToCsvButton = (): JSX.Element => {
  const { jobId } = useParams()
  const notifySuccess = useSetAtom(notifySuccessAtom)
  const notifyError = useSetAtom(notifyErrorAtom)

  const handleDownload = async (): Promise<void> => {
    try {
      await exportCandidateJobsToCsv({ jobId: jobId ?? '' })
      notifySuccess({
        message: 'Saved CSV into your downloads folder'
      })
    } catch (error) {
      notifyError({
        message: 'Unfortunately, there was an error downloading this file. If the error persists, please get in touch.'
      })
    }
  }

  return (
    <Button
      $variant="outline"
      $colorTheme="normal"
      $height={24}
      $fontSize={12}
      leadingIcon={Icons.fileDown}
      onClick={handleDownload}
    >
      Export to CSV
    </Button>
  )
}
