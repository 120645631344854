import { Caption } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives'
import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { DivAsButton } from 'src/components/primitives/div-as-button'
import { BarChart } from '../bar-chart'
import type { BarChartProps } from '../bar-chart'
import * as S from './selectable-chart-group.styled'
import { EmptyChart } from '../empty-chart'

interface ChartGroup {
  label: string
  value: number
  chartTitle: string
  chartData?: BarChartProps['data']
}

interface SelectableChartGroupProps {
  total: number
  groups: ChartGroup[]
}

export const SelectableChartGroup = ({
  groups,
  total
}: SelectableChartGroupProps): JSX.Element => {
  const [selectedGroup, setSelectedGroup] = useState<number>(0)

  const getPercentageValue = (value: number): string => {
    if (!groups) {
      return '0%'
    }
    if (total <= 0) {
      return '0%'
    }
    return `${Math.round((value / total) * 100)}%`
  }

  const currentBarChartData = groups[selectedGroup]?.chartData

  const selectorsWrapperRef = useRef<HTMLDivElement>(null)
  const arrowRef = useRef<HTMLDivElement>(null)

  const calculateArrowOffset = useCallback(() => {
    if (selectorsWrapperRef.current && arrowRef.current) {
      const selectorSpacing = 8
      const opticalAlignment = 3
      const groupHeight = selectorsWrapperRef.current.getBoundingClientRect().height
      const totalCards = groups.length
      const selectorHeight = (groupHeight - ((totalCards - 1) * selectorSpacing)) / totalCards
      const offset =
        selectorHeight * selectedGroup + selectorSpacing * (selectedGroup - 2) + selectorHeight / 2
      arrowRef.current.style.transform = `translate3d(0, ${offset + opticalAlignment}px, 0)`
    }
  }, [selectedGroup, groups.length])

  const showBarChart = currentBarChartData && currentBarChartData.length >= 1

  useLayoutEffect(() => {
    calculateArrowOffset()
  }, [selectedGroup, calculateArrowOffset])

  return (
    <S.SelectableChartGroup>
      <S.GroupSelectors>
        <S.GroupSelectorsList ref={selectorsWrapperRef}>
          {groups.map((group, index) => (
            <DivAsButton
              key={`${group.label}-${group.value}`}
              ariaLabel="Select group"
              onClick={() => {
                setSelectedGroup(index)
              }}
            >
              <S.GroupSelector $isSelected={selectedGroup === index}>
                <Caption size="XS" $color="fgSecondary" $whiteSpace="nowrap">
                  {group.label}
                </Caption>
                <Flex $gap={40} $justify="space-between" $align="flex-end">
                  <h4>{group.value}</h4>
                  <S.ChangeBadge>
                    <Caption size="2XS" $color="fgSecondary" $whiteSpace="nowrap">
                      {getPercentageValue(group.value)}
                    </Caption>
                  </S.ChangeBadge>
                </Flex>
              </S.GroupSelector>
            </DivAsButton>
          ))}
        </S.GroupSelectorsList>
      </S.GroupSelectors>
      <S.GroupWrapper>
        <S.GroupArrow ref={arrowRef} $offset={20} />
        <S.GroupContent>
          {showBarChart && (
            <BarChart
              chartTitle={groups[selectedGroup].chartTitle}
              data={currentBarChartData}
            />
          )}
          {!showBarChart && (<EmptyChart $minHeight='100px' />)}
        </S.GroupContent>
      </S.GroupWrapper>
    </S.SelectableChartGroup>
  )
}
