import { CANDIDATES_PAGES_MAX_WIDTH } from 'src/styles/constants'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[40]};
  padding: ${({ theme }) => `${theme.spacing[64]} ${theme.spacing[20]}`};
  background: linear-gradient(
    267deg,
    rgba(170, 145, 240, 0.05) -0.11%,
    rgba(179, 193, 241, 0.05) 99.81%
  );
  max-width: ${CANDIDATES_PAGES_MAX_WIDTH};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing[20]};
`
