import { Button } from 'src/components/primitives/button'
import { Card } from 'src/components/primitives/card'
import { Flex } from 'src/components/primitives/flex'
import { Caption, Paragraph } from 'src/components/primitives/typography'

export interface DeleteOrRestoreCardProps {
  action?: 'delete' | 'restore'
  heading: string
  description?: string
  onClick: () => void
}

export const DeleteOrRestoreCard = ({
  action = 'delete',
  heading,
  description,
  onClick
}: DeleteOrRestoreCardProps): JSX.Element => {
  return (
    <Card>
      <Flex $align="center" $justify="space-between">
        <Flex $direction="column" $gap={4} $width="auto">
          <Caption size="SM" $color="fgPrimary">
            {heading}
          </Caption>
          {description && (
            <Paragraph size="XS" $color="fgSecondary">
              {description}
            </Paragraph>
          )}
        </Flex>
        <Button
          $variant="outline"
          $colorTheme={action === 'delete' ? 'negative' : 'tint'}
          leadingIcon={action === 'delete' ? 'archive' : 'archive-restore'}
          $height={24}
          $fontSize={12}
          onClick={() => {
            onClick()
          }}
        >
          {action === 'delete' ? 'Archive' : 'Restore'}
        </Button>
      </Flex>
    </Card>
  )
}
