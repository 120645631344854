import { NAV_HEIGHT_LG } from 'src/styles/constants'
import styled from 'styled-components'

export const OnboardingLayout = styled.div`
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
`

export const Inner = styled.main`
  width: 100%;
  height: calc(100% - ${NAV_HEIGHT_LG});
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  // max-height: calc(100% - ${NAV_HEIGHT_LG});
  aspect-ratio: 10/6.67;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05), 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1512px) {
    height: 100%;
    max-width: unset;
    aspect-ratio: unset;
    box-shadow: unset;
    overflow: unset;
  }
`

export const FormColumn = styled.div`
  flex: 1 1 65%;
  height: 100%;
  padding: 3rem 2.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
`

export const FormColumnInner = styled.div`
  width: 100%;
  max-width: 600px;
  form {
    width: 100%;
  }
`

export const MediaColumn = styled.div`
  flex: 1 1 35%;
  max-width: 34vw;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
