import { INBOX_MAX_WIDTH, SIDEBAR_WIDTH } from 'src/styles/constants'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

export const Sidebar = styled.div<{ $isExpanded: boolean }>`
  position: absolute;
  width: ${({ $isExpanded }) => ($isExpanded ? SIDEBAR_WIDTH.LG.OPEN : SIDEBAR_WIDTH.LG.CLOSED)};
  height: 100%;
  border-radius: ${({ theme }) => theme.spacing[6]};
  padding: ${({ theme }) => theme.spacing[12]};
  overflow-y: auto;
  overflow-x: hidden;
  z-index: ${({ theme }) => theme.zindeces.navigation};
  transition: width 0.2s;
`

interface ContentProps {
  $hasSidebar: boolean
  $isExpanded: boolean
}

export const Content = styled.div<ContentProps>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  height: 100%;
  top: 0;
  overflow: auto;
  padding: 0;
  ${({ $hasSidebar }) =>
    !$hasSidebar &&
    `
  left: 0;
  width: 100%;
  `}
  ${({ $hasSidebar, $isExpanded }) =>
    $hasSidebar &&
    `
  left: ${$isExpanded ? SIDEBAR_WIDTH.LG.OPEN : SIDEBAR_WIDTH.LG.CLOSED};
  width: ${
    $isExpanded
      ? `calc(100% - ${SIDEBAR_WIDTH.LG.OPEN} - 1px)`
      : `calc(100% - ${SIDEBAR_WIDTH.LG.CLOSED} - 1px)`
  };
  `}
`

export const ContentInner = styled.div<ContentProps>`
  height: 100%;
  ${({ $hasSidebar, $isExpanded }) =>
    $hasSidebar && `
      max-width: ${$isExpanded ? `${INBOX_MAX_WIDTH}` : `calc(${INBOX_MAX_WIDTH} + (${SIDEBAR_WIDTH.LG.OPEN} - ${SIDEBAR_WIDTH.LG.CLOSED}))`};
    `
  }
`
