import { useLayoutEffect, useState } from 'react'

interface Args {
  ref: React.RefObject<HTMLElement>
  text: string | undefined
  // The width in percentage when to cut off text.
  // Default setting is that text longer than 90% of the
  // referenced ref's width will be cut off / truncated.
  threshold?: number
}

/**
 * This hook should only be consumed when necessary, e.g. when
 * default css ellipsis styles cannot be applied for reasons.
 */
export const useTruncateText = ({ ref, text, threshold = 90 }: Args): string => {
  const [truncatedText, setTruncatedText] = useState(text ?? '')

  useLayoutEffect(() => {
    if (ref.current) {
      const refWidth = ref.current.clientWidth
      const targetWidth = (threshold / 100) * refWidth
      // I used 7 as an average character width which is only a rough estimate,
      // ideally we would calculate this, but IMO this is a bit too much overhead for our usecase.
      const averageCharacterWidth = 7
      const maxCharacters = Math.floor(targetWidth / averageCharacterWidth)

      if (text) {
        const ELLIPSIS_SAFE_AREA = 3
        const charactersThatWillBeRemoved = text.length - maxCharacters + ELLIPSIS_SAFE_AREA
        if (text.length > maxCharacters && charactersThatWillBeRemoved > 8) {
          setTruncatedText(`${text.substring(0, maxCharacters - ELLIPSIS_SAFE_AREA)}\u2026`)
        } else {
          setTruncatedText(text)
        }
      }
    }
  }, [text, ref, threshold])

  return truncatedText
}
