import styled from 'styled-components'
import type { FieldLabelStyleProps } from './field-label'

export const FieldLabel = styled.label<FieldLabelStyleProps>`
  font-size: ${({ theme, $fontSize }) => theme.fontSizes[$fontSize ?? 14]};
  font-weight: 500;
  line-height: ${({ theme, $lineHeight }) => theme.lineHeights[$lineHeight ?? '1.43']};
  color: ${({ theme, $color }) => theme.colors[$color ?? 'fgSecondary']};
  /* Fix for optical alignment */
  padding-left: 1px;
  white-space: nowrap;
`

export const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[12]};
  line-height: ${({ theme }) => theme.lineHeights['1.43']};
  color: ${({ theme }) => theme.colors.fgTertiary};
  /* Fix for optical alignment */
  padding-left: 1px;
  margin-bottom: 0.35rem;
`
