import { useMutation, useQueryClient } from '@tanstack/react-query'
import { archiveJob as archiveJobApi } from 'src/libs/api/backend/jobs'
import type { Job, JobGroupedByDepartment } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'
import { isNil } from 'lodash'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'
import { useSetAtom } from 'jotai'

interface Args {
  jobId: Job['id']
  reason: string
  onSuccess?: (job: Job) => void
}

interface Res {
  archiveJob: (args: Args) => void
}

export const useArchiveJob = (): Res => {
  const queryClient = useQueryClient()
  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)

  const mutation = useMutation({
    mutationFn: async ({ jobId, reason }: Args) => {
      return await archiveJobApi(jobId, reason)
    },
    onError: (err) => {
      console.error(err)
      notifyError({
        message: `An error occurred when archiving your job: ${err.message}`,
        autoClose: false
      })
    },
    onSuccess: async (job, { onSuccess }) => {
      // await queryClient.invalidateQueries({
      //   queryKey: [queryKeys.jobs]
      // })
      // await queryClient.invalidateQueries({
      //   queryKey: [queryKeys.jobByDepartments]
      // })

      await queryClient.invalidateQueries({ queryKey: [queryKeys.candidateJobs, job.id] })

      queryClient.setQueriesData<Job>({ queryKey: [queryKeys.job, job.id] }, (oldJob) => {
        if (isNil(oldJob)) {
          return oldJob
        }

        return {
          ...oldJob,
          ...job
        }
      })

      queryClient.setQueriesData<Job[]>({ queryKey: [queryKeys.jobs] }, (oldJobs) => {
        if (isNil(oldJobs)) {
          return oldJobs
        }

        return oldJobs.map((o) => {
          if (o.id === job.id) {
            return {
              ...o,
              ...job
            }
          }

          return o
        })
      })

      queryClient.setQueriesData<JobGroupedByDepartment>({ queryKey: [queryKeys.jobByDepartments] }, (oldJobs) => {
        if (isNil(oldJobs)) {
          return oldJobs
        }

        for (const department in oldJobs) {
          oldJobs[department] = oldJobs[department].map((o) => {
            if (o.id === job.id) {
              return {
                ...o,
                ...job
              }
            }

            return o
          })
        }
      })

      if (onSuccess) {
        onSuccess(job)
      } else {
        notifySuccess({
          message: `${job.title} archived`
        })
      }
    }
  })

  const archiveJob = (args: Args): void => {
    mutation.mutate(args)
  }

  return { archiveJob }
}
