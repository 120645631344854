import styled, { keyframes } from 'styled-components'
import type { Color, Spacing } from 'src/styles/theme/types'
import type { ProfilePhotoStyleProps } from './profile-photo'

const fadeIn = keyframes`
 0% { opacity: 0; filter: blur(10px); }
 100% { opacity: 1; filter: blur(0px); }
`

export const ProfilePhoto = styled.div<ProfilePhotoStyleProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    z-index: 1;
    // animation: 0.25s ${fadeIn} ease-in-out;
  }
  * {
    border-radius: ${({ $shape, $size }) => {
      switch ($shape) {
        case 'sharp': {
          return 0
        }
        case 'soft': {
          return '4px'
        }
        case 'rounded': {
          if ($size && $size >= 36) {
            return '8px'
          } else {
            return '6px'
          }
        }
        case 'circle': {
          return '999px'
        }
        default: {
          return '4px'
        }
      }
    }};
    position: absolute;
    top: 0;
    left: 0;
  }
`

interface NoImageProps {
  $backgroundColor?: Color
  $size?: Spacing
}

export const NoImage = styled.div<NoImageProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, $backgroundColor }) =>
    theme.colors[$backgroundColor ?? 'fgPrimary']};
  overflow: hidden;
  span {
    position: relative;
    /* transform: translateY(-1px); */
    /* font-size: inherit; */
    font-size: ${({ $size }) => `${($size ?? 36) * (($size ?? 36) >= 24 ? 0.4 : 0.5)}px`};
    color: inherit;
    font-weight: inherit;
    line-height: 1;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`
