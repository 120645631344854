import Api from '..'
import { z } from 'zod'

const generateUploadUrlParser = z.object({
  uploadSignedUrl: z.string(),
  viewSignedUrl: z.string(),
  key: z.string()
})

export type GenerateUploadUrl = z.infer<typeof generateUploadUrlParser>

export async function generateUploadUrlApi (fileName: string): Promise<GenerateUploadUrl> {
  const { data } = await Api.post('/url/uploads/generate_url', null, { fileName })

  return generateUploadUrlParser.parse(data)
}
