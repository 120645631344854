import { useParams } from 'react-router-dom'
import { useListJobSearchRefinementsQuery } from 'src/hooks/queries/use-job-search-refinements'
import { Banner } from '../banner'
import { Button, Spacer } from 'src/components/primitives'
import RouteBuilder from 'src/libs/route-builder'

interface DeprecatedBannerProps {
  children: React.ReactNode
}

export const DeprecatedBanner = ({ children }: DeprecatedBannerProps): JSX.Element => {
  const { jobId } = useParams()
  const { data: jobSearchRefinements = [] } = useListJobSearchRefinementsQuery()

  return (
    <>
      <Banner
        $variant="warning"
        icon="alert-triangle"
        actions={
          <Button
            $variant="raised"
            $colorTheme="muted"
            $height={24}
            $fontSize={12}
            leadingIcon="square-arrow-out-up-right"
            href={
              jobSearchRefinements?.length > 0
                ? RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId, jobSearchRefinementId: jobSearchRefinements[0].id })
                : `/jobs/${jobId}/candidates/sourcing/search`
            }
          >
            Go to sourcing
          </Button>
        }
      >
        {children}
      </Banner>
      <Spacer $size={16} />
    </>
  )
}
