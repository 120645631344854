import * as S from './automate-icon.styled'

interface AutomateIconProps {
  isAnimating: boolean
  $size?: number
}

export const AutomateIcon = ({ isAnimating, $size = 32 }: AutomateIconProps): JSX.Element => {
  return (
    <S.AutomateIcon $size={$size} $isAnimating={isAnimating}>
      <svg width={$size} height={$size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          data-name="automate-icon-dot"
          d="M16 0L15.9538 6.53605e-05C15.0899 0.00251186 14.3915 0.704848 14.394 1.56878C14.3964 2.43271 15.0987 3.13108 15.9627 3.12863L16 3.12858L16.0373 3.12863C16.9013 3.13108 17.6036 2.43271 17.606 1.56878C17.6085 0.704848 16.9101 0.00251176 16.0462 6.53605e-05L16 0Z"
          fill="url(#paint0_linear_1521_111105)"
        />
        <path
          data-name="automate-icon-dot"
          d="M23.9625 2.11921C23.2135 1.6887 22.2573 1.94692 21.8268 2.69595C21.3963 3.44498 21.6545 4.40118 22.4035 4.83169L22.4676 4.86877C23.2142 5.30358 24.1718 5.05088 24.6066 4.30435C25.0415 3.55781 24.7888 2.60013 24.0422 2.16532C24.0157 2.14988 23.9891 2.13451 23.9625 2.11921Z"
          fill="url(#paint1_linear_1521_111105)"
        />
        <path
          data-name="automate-icon-dot"
          d="M9.59647 4.83169C10.3455 4.40118 10.6037 3.44498 10.1732 2.69595C9.7427 1.94692 8.7865 1.68871 8.03747 2.11921C8.01086 2.13451 7.98429 2.14988 7.95778 2.16532C7.21124 2.60014 6.95854 3.55781 7.39335 4.30435C7.82817 5.05088 8.78584 5.30358 9.53238 4.86877L9.59647 4.83169Z"
          fill="url(#paint2_linear_1521_111105)"
        />
        <path
          data-name="automate-icon-dot"
          d="M29.8347 7.95778C29.3999 7.21124 28.4422 6.95854 27.6957 7.39335C26.9491 7.82817 26.6964 8.78584 27.1312 9.53238L27.1683 9.59647C27.5988 10.3455 28.555 10.6037 29.3041 10.1732C30.0531 9.7427 30.3113 8.7865 29.8808 8.03747C29.8655 8.01086 29.8501 7.98429 29.8347 7.95778Z"
          fill="url(#paint3_linear_1521_111105)"
        />
        <path
          data-name="automate-icon-dot"
          d="M4.86877 9.53238C5.30358 8.78584 5.05088 7.82817 4.30435 7.39335C3.55781 6.95854 2.60013 7.21124 2.16532 7.95778C2.14988 7.98429 2.13451 8.01086 2.11921 8.03747C1.6887 8.7865 1.94692 9.7427 2.69595 10.1732C3.44498 10.6037 4.40118 10.3455 4.83169 9.59647L4.86877 9.53238Z"
          fill="url(#paint4_linear_1521_111105)"
        />
        <path
          data-name="automate-icon-dot"
          d="M31.9999 15.9538C31.9975 15.0899 31.2952 14.3915 30.4312 14.394C29.5673 14.3964 28.8689 15.0987 28.8714 15.9627L28.8714 16L28.8714 16.0373C28.8689 16.9013 29.5673 17.6036 30.4312 17.606C31.2952 17.6085 31.9975 16.9101 31.9999 16.0462L32 16L31.9999 15.9538Z"
          fill="url(#paint5_linear_1521_111105)"
        />
        <path
          data-name="automate-icon-dot"
          d="M3.12863 15.9627C3.13108 15.0987 2.43271 14.3964 1.56878 14.394C0.704848 14.3915 0.00251176 15.0899 6.53605e-05 15.9538L0 16L6.53605e-05 16.0462C0.00251186 16.9101 0.704848 17.6085 1.56878 17.606C2.43271 17.6036 3.13108 16.9013 3.12863 16.0373L3.12858 16L3.12863 15.9627Z"
          fill="url(#paint6_linear_1521_111105)"
        />
        <path
          data-name="automate-icon-dot"
          d="M29.8808 23.9625C30.3113 23.2135 30.0531 22.2573 29.3041 21.8268C28.555 21.3963 27.5988 21.6545 27.1683 22.4035L27.1312 22.4676C26.6964 23.2142 26.9491 24.1718 27.6957 24.6066C28.4422 25.0415 29.3999 24.7888 29.8347 24.0422C29.8501 24.0157 29.8655 23.9891 29.8808 23.9625Z"
          fill="url(#paint7_linear_1521_111105)"
        />
        <path
          data-name="automate-icon-dot"
          d="M4.83169 22.4035C4.40118 21.6545 3.44498 21.3963 2.69595 21.8268C1.94692 22.2573 1.68871 23.2135 2.11921 23.9625C2.13451 23.9891 2.14988 24.0157 2.16532 24.0422C2.60014 24.7888 3.55781 25.0415 4.30435 24.6066C5.05088 24.1718 5.30358 23.2142 4.86877 22.4676L4.83169 22.4035Z"
          fill="url(#paint8_linear_1521_111105)"
        />
        <path
          data-name="automate-icon-dot"
          d="M9.53238 27.1312C8.78584 26.6964 7.82817 26.9491 7.39335 27.6957C6.95854 28.4422 7.21124 29.3999 7.95778 29.8347C7.98429 29.8501 8.01086 29.8655 8.03747 29.8808C8.7865 30.3113 9.7427 30.0531 10.1732 29.3041C10.6037 28.555 10.3455 27.5988 9.59647 27.1683L9.53238 27.1312Z"
          fill="url(#paint9_linear_1521_111105)"
        />
        <path
          data-name="automate-icon-dot"
          d="M24.0422 29.8347C24.7888 29.3999 25.0415 28.4422 24.6066 27.6957C24.1718 26.9491 23.2142 26.6964 22.4676 27.1312L22.4035 27.1683C21.6545 27.5988 21.3963 28.555 21.8268 29.3041C22.2573 30.0531 23.2135 30.3113 23.9625 29.8808C23.9891 29.8655 24.0157 29.8501 24.0422 29.8347Z"
          fill="url(#paint10_linear_1521_111105)"
        />
        <path
          data-name="automate-icon-dot"
          d="M15.9627 28.8714C15.0987 28.8689 14.3964 29.5673 14.394 30.4312C14.3915 31.2952 15.0899 31.9975 15.9538 31.9999L16 32L16.0462 31.9999C16.9101 31.9975 17.6085 31.2952 17.606 30.4312C17.6036 29.5673 16.9013 28.8689 16.0373 28.8714L16 28.8714L15.9627 28.8714Z"
          fill="url(#paint11_linear_1521_111105)"
        />
        <path
          d="M12.6606 15.9748V14.5367C12.6606 13.8226 13.2395 13.2436 13.9536 13.2436C14.6678 13.2436 15.2467 13.8226 15.2467 14.5367V15.9748H12.6606Z"
          fill="url(#paint12_linear_1521_111105)"
        />
        <path
          d="M16.6262 14.5367V15.9748H19.2123V14.5367C19.2123 13.8226 18.6333 13.2436 17.9192 13.2436C17.2051 13.2436 16.6262 13.8226 16.6262 14.5367Z"
          fill="url(#paint13_linear_1521_111105)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5335 15.2919C23.5335 19.4877 20.1322 22.8891 15.9365 22.8891V24.5108C15.9365 24.7702 15.6925 24.9607 15.4444 24.8851C11.5818 23.7092 8.70779 20.2645 8.37451 16.1031C8.37264 16.0798 8.37291 16.0568 8.37514 16.0345C8.35144 15.7901 8.33931 15.5424 8.33931 15.2919C8.33931 11.096 11.7406 7.69465 15.9364 7.69465C20.1322 7.69465 23.5335 11.096 23.5335 15.2919ZM15.9363 20.4862C18.8052 20.4862 21.1309 18.1605 21.1309 15.2916C21.1309 12.4227 18.8052 10.097 15.9363 10.097C13.0674 10.097 10.7417 12.4227 10.7417 15.2916C10.7417 18.1605 13.0674 20.4862 15.9363 20.4862Z"
          fill="url(#paint14_linear_1521_111105)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1521_111105"
            x1="-8.90493e-07"
            y1="32"
            x2="29.7739"
            y2="33.9521"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AA91F0" />
            <stop offset="1" stopColor="#B3C1F1" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1521_111105"
            x1="-8.90493e-07"
            y1="32"
            x2="29.7739"
            y2="33.9521"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AA91F0" />
            <stop offset="1" stopColor="#B3C1F1" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1521_111105"
            x1="-8.90493e-07"
            y1="32"
            x2="29.7739"
            y2="33.9521"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AA91F0" />
            <stop offset="1" stopColor="#B3C1F1" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1521_111105"
            x1="-8.90493e-07"
            y1="32"
            x2="29.7739"
            y2="33.9521"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AA91F0" />
            <stop offset="1" stopColor="#B3C1F1" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1521_111105"
            x1="-8.90493e-07"
            y1="32"
            x2="29.7739"
            y2="33.9521"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AA91F0" />
            <stop offset="1" stopColor="#B3C1F1" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1521_111105"
            x1="-8.90493e-07"
            y1="32"
            x2="29.7739"
            y2="33.9521"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AA91F0" />
            <stop offset="1" stopColor="#B3C1F1" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1521_111105"
            x1="-8.90493e-07"
            y1="32"
            x2="29.7739"
            y2="33.9521"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AA91F0" />
            <stop offset="1" stopColor="#B3C1F1" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1521_111105"
            x1="-8.90493e-07"
            y1="32"
            x2="29.7739"
            y2="33.9521"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AA91F0" />
            <stop offset="1" stopColor="#B3C1F1" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1521_111105"
            x1="-8.90493e-07"
            y1="32"
            x2="29.7739"
            y2="33.9521"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AA91F0" />
            <stop offset="1" stopColor="#B3C1F1" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_1521_111105"
            x1="-8.90493e-07"
            y1="32"
            x2="29.7739"
            y2="33.9521"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AA91F0" />
            <stop offset="1" stopColor="#B3C1F1" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_1521_111105"
            x1="-8.90493e-07"
            y1="32"
            x2="29.7739"
            y2="33.9521"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AA91F0" />
            <stop offset="1" stopColor="#B3C1F1" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_1521_111105"
            x1="-8.90493e-07"
            y1="32"
            x2="29.7739"
            y2="33.9521"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AA91F0" />
            <stop offset="1" stopColor="#B3C1F1" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_1521_111105"
            x1="-8.90493e-07"
            y1="32"
            x2="29.7739"
            y2="33.9521"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AA91F0" />
            <stop offset="1" stopColor="#B3C1F1" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_1521_111105"
            x1="-8.90493e-07"
            y1="32"
            x2="29.7739"
            y2="33.9521"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AA91F0" />
            <stop offset="1" stopColor="#B3C1F1" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_1521_111105"
            x1="-8.90493e-07"
            y1="32"
            x2="29.7739"
            y2="33.9521"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AA91F0" />
            <stop offset="1" stopColor="#B3C1F1" />
          </linearGradient>
        </defs>
      </svg>
    </S.AutomateIcon>
  )
}
