import { useEffect, useRef, useState } from 'react'
import { Input } from '../input'
import type { InputFieldType } from '../input'
import type { FieldCommonProps } from '../common'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Button } from 'src/components/primitives/button'
import { FieldLabel } from '../field-label'
import { Flex } from 'src/components/primitives/flex'
import * as S from './input-group.styled'
import { useKeyboardEvent } from 'src/hooks/use-keyboard-event'

export interface InputGroupItem {
  id: string
  value: string
}

interface InputGroupProps extends FieldCommonProps {
  initialValues?: InputGroupItem[]
  addValueFieldType?: InputFieldType
  addValuePlaceholder?: string
  onDataChange?: (values: InputGroupItem[]) => void
  itemActions?: (
    items: InputGroupItem[],
    index: number,
    cb?: (data: InputGroupItem[]) => void
  ) => MenuItemProps[]
  itemContext?: (item: InputGroupItem, index: number) => React.ReactNode
}

export const InputGroup = ({
  initialValues,
  addValueFieldType = 'text',
  addValuePlaceholder = 'Add',
  onDataChange,
  itemActions,
  itemContext,
  ...inputProps
}: InputGroupProps): JSX.Element => {
  const [groupValues, setGroupValues] = useState<InputGroupItem[]>(initialValues ?? [])
  const [newValue, setNewValue] = useState<string>()
  const { label } = inputProps

  useEffect(() => {
    if (initialValues) {
      setGroupValues(initialValues)
    }
  }, [initialValues])

  useEffect(() => {
    if (onDataChange) {
      onDataChange(groupValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupValues])

  const updateGroupValues = (newValues: InputGroupItem[]): void => {
    setGroupValues([...newValues])
  }

  const handleAddNewValue = (newItem: string): void => {
    const valueExists = groupValues.some(
      (item) => item.value.toLowerCase() === newItem?.toLowerCase()
    )
    if (valueExists) {
      setNewValue('')
      return
    }
    if (newItem && newItem.trim() !== '') {
      setGroupValues([...groupValues, { id: crypto.randomUUID(), value: newItem }])
      setNewValue('')
    }
  }

  const handleRemoveItem = (itemToRemove: InputGroupItem): void => {
    setGroupValues(groupValues.filter((item) => item.id !== itemToRemove.id))
  }

  const handleUpdateExistingValue = (itemToUpdate: InputGroupItem, newValue: string): void => {
    const updatedValues = groupValues.map((item) =>
      item.id === itemToUpdate.id ? { ...item, value: newValue } : item
    )
    setGroupValues(updatedValues)
  }

  const addValueInputRef = useRef<HTMLInputElement>(null)

  useKeyboardEvent(
    ['Enter'],
    () => {
      if (newValue) {
        handleAddNewValue(newValue)
      }
    },
    { isEnabled: true, ignoreInput: false, target: addValueInputRef }
  )

  return (
    <>
      <S.InputGroup>
        <FieldLabel label={label} />
        {groupValues?.map((item, index) => (
          <Flex $align="center" $gap={8} key={item.id}>
            <S.InputField>
              <input
                value={item.value}
                onChange={(event) => {
                  handleUpdateExistingValue(item, event.target.value)
                }}
              />
              {itemContext && itemContext(item, index)}
            </S.InputField>
            <Dropdown
              trigger={
                <Button
                  nested
                  leadingIcon="more-vertical"
                  $height={24}
                  $width={24}
                  $fontSize={12}
                  $variant="ghost"
                  $colorTheme="muted"
                />
              }
              items={
                itemActions
                  ? itemActions(groupValues, index, updateGroupValues).concat([
                    {
                      id: 'remove',
                      title: 'Remove',
                      variant: 'negative',
                      icon: 'trash',
                      onSelect: () => {
                        handleRemoveItem(item)
                      }
                    }
                  ])
                  : [
                      {
                        id: 'remove',
                        title: 'Remove',
                        variant: 'negative',
                        icon: 'trash',
                        onSelect: () => {
                          handleRemoveItem(item)
                        }
                      }
                    ]
              }
              menuPosition="end"
              size="small"
            />
          </Flex>
        ))}
        <Flex $align="center" $gap={8}>
          <S.AddField
            ref={addValueInputRef}
            type={addValueFieldType}
            placeholder={addValuePlaceholder}
            value={newValue}
            onBlur={(event) => {
              setNewValue(event.target.value)
              handleAddNewValue(event.target.value)
            }}
            onChange={(event) => {
              setNewValue(event.target.value)
            }}
          />
          {/*
          <Button
            leadingIcon="plus-circle"
            $height={24}
            $width={24}
            $fontSize={12}
            $variant="ghost"
            $colorTheme="muted"
            disabled={!newValue}
            // onClick={() => {handleAddNewValue}
          />
          */}
        </Flex>
      </S.InputGroup>
      <Input
        type="hidden"
        hiddenLabel
        {...inputProps}
      />
    </>
  )
}
