import * as S from './action.styled'

interface ActionProps {
  children: React.ReactNode
}

export const Action = ({ children }: ActionProps): JSX.Element => {
  return (
    <S.Action>
      <S.Content>{children}</S.Content>
    </S.Action>
  )
}
