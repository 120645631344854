import { useContext, createContext } from 'react'
import type { Dispatch } from 'react'
import type { User } from 'src/libs/api/backend/users'
import type { Org } from 'src/libs/api/backend/orgs'
import type { OnboardingStatus, EmailAccount } from 'src/libs/api/backend/session'

interface AuthContextProps {
  isLoading: boolean
  user?: User
  org?: Org
  onboardingStatus?: OnboardingStatus | null
  accessToken?: boolean | null
  emailAccountAccessTokens: EmailAccount[]
  orgLogoUrl?: string | null
  setIsLoading?: Dispatch<React.SetStateAction<boolean>> | null
  setUser?: Dispatch<React.SetStateAction<User>> | null
  setOrg?: Dispatch<React.SetStateAction<Org>> | null
  setOnboardingStatus?: Dispatch<React.SetStateAction<OnboardingStatus | null>> | null
  setAccessToken?: Dispatch<React.SetStateAction<boolean>> | null
  setEmailAccountAccessTokens?: Dispatch<React.SetStateAction<EmailAccount[]>> | null
  setOrgLogoUrl?: Dispatch<React.SetStateAction<string>> | null
}

export const AuthContext = createContext<AuthContextProps>({
  isLoading: true,
  user: {} as User,
  org: {} as Org,
  emailAccountAccessTokens: [],
  onboardingStatus: null,
  accessToken: false,
  orgLogoUrl: null,
  setIsLoading: null,
  setUser: null,
  setOrg: null,
  setOnboardingStatus: null,
  setAccessToken: null,
  setOrgLogoUrl: null
})

export const useAuthContext = () => {
  return useContext(AuthContext)
}
