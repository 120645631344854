import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import * as S from './table.styled'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useTheme } from 'styled-components'
import { isNil } from 'lodash'
import { COLUMN } from 'src/components/tables/candidate-table-cells'
import type { TableColumnDef } from 'src/components/tables/candidate-table-cells'
import { IndeterminateCheckbox } from './IndeterminateCheckbox'
import { TableHeader } from './table-header'
import type { TableProps } from './type'
import { addSelectedRowsAtom, selectedRowsAtom, tableStateAtom, toggleSelectedRowAtom } from 'src/stores/table'
import { useAtomValue, useSetAtom } from 'jotai'

export const Table = <T extends { id: string }>({
  tableData,
  columns,
  setColumns,
  onRowClick,
  onToggleExpandableColumn,
  selectionActions,
  emptyState
}: TableProps<T>): JSX.Element => {
  const [rangeSelectionStart, setRangeSelectionStart] = useState<number | null>(null)
  const theme = useTheme()
  const [tableRows, setTableRows] = useState<T[]>([])
  const setTableState = useSetAtom(tableStateAtom)
  const selectedRows = useAtomValue(selectedRowsAtom)
  const addSelectedRows = useSetAtom(addSelectedRowsAtom)
  const toggleSelectedRow = useSetAtom(toggleSelectedRowAtom)

  useEffect(() => {
    setTableRows(tableData)
  }, [tableData, tableRows.length])

  useEffect(() => {
    return () => {
      // Reset state when unmount
      setTableState({
        sort: undefined,
        selectedRows: new Set<string>()
      })
    }
  }, [setTableState])

  const tableContainerRef = useRef<HTMLDivElement>(null)

  // To avoid from accidentally selecting text on the site,
  // we want to disable user-select while there is a rowSelection active.
  useEffect(() => {
    const hasSelectedRows = selectedRows.size >= 1
    if (hasSelectedRows) {
      document.body.style.userSelect = 'none'
    } else {
      document.body.style.userSelect = ''
    }

    return () => {
      document.body.style.userSelect = ''
    }
  }, [selectedRows])

  const gridColumnSizes = useMemo((): string => {
    return columns.reduce((acc: string[], column: TableColumnDef<T>) => {
      if (column.visible) {
        return [...acc, column.size ?? 'minmax(min-content, 1fr)']
      }
      return acc
    }, []).join(' ')
  }, [columns])

  const rowVirtualizer = useVirtualizer({
    count: tableRows.length,
    estimateSize: () => 54,
    getScrollElement: () => tableContainerRef.current,
    // measureElement:
    //   typeof window !== 'undefined' &&
    //   navigator.userAgent.includes('Firefox')
    //     ? element => element?.getBoundingClientRect().height
    //     : undefined,
    overscan: 5
  })

  const items = rowVirtualizer.getVirtualItems()

  const [beforeBuffer, afterBuffer] = useMemo(() => {
    if (items.length === 0) {
      return [0, 0]
    }
    return [
      items[0].start - rowVirtualizer.options.scrollMargin,
      rowVirtualizer.getTotalSize() - items[items.length - 1].end
    ]
  }, [items, rowVirtualizer])

  const onRowSelectionClicked = useCallback((event: React.MouseEvent<HTMLInputElement>, rowIndex: number) => {
    const currentRowId = tableRows[rowIndex].id
    if (event.shiftKey) {
      if (!isNil(rangeSelectionStart)) {
        const start = Math.min(rangeSelectionStart, rowIndex)
        const end = Math.max(rangeSelectionStart, rowIndex)
        const newSelection: string[] = []
        for (let i = start; i <= end; i++) {
          newSelection.push(tableRows[i].id)
        }
        addSelectedRows(newSelection)
      }
    } else {
      toggleSelectedRow(currentRowId)
      setRangeSelectionStart(rowIndex)
    }
  }, [addSelectedRows, rangeSelectionStart, tableRows, toggleSelectedRow])

  if (tableRows.length === 0) {
    return <>{emptyState}</>
  }

  return (
    <div
      ref={tableContainerRef}
      style={{
        // position: 'relative',
        width: '100%'
      }}
    >
      <S.Table
        style={{
          minWidth: '100%',
          display: 'grid',
          position: 'relative',
          gridTemplateColumns: gridColumnSizes,
          paddingTop: beforeBuffer,
          paddingBottom: afterBuffer
        }}
      >
        <TableHeader
          columns={columns}
          setTableRows={setTableRows}
          tableRows={tableRows}
          originalTableData={tableData}
          setColumns={setColumns}
          selectionActions={selectionActions}
          onToggleExpandableColumn={onToggleExpandableColumn}
        />
        <S.Body
          style={{
            height: rowVirtualizer.getTotalSize()
          }}
        >
          {items.map((virtualRow) => {
            const row = tableRows[virtualRow.index]
            return (
              <S.TableRow
                data-index={virtualRow.index}
                // ref={(node) => { rowVirtualizer.measureElement(node) }}
                key={row.id}
                style={{
                  cursor: onRowClick ? 'pointer' : 'default',
                  backgroundColor:
                    virtualRow.index % 2 === 0
                      ? theme.colors.bgSecondary
                      : theme.colors.bgPrimary,
                  position: 'absolute',
                  top: 0,
                  height: virtualRow.size,
                  transform: `translateY(${virtualRow.start}px)`
                }}
                onClick={() => {
                  if (onRowClick) {
                    onRowClick(row)
                  }
                }}
                data-selection={selectedRows.has(row.id) ? 'active' : 'inactive'}
              >
                {columns.map((column) => {
                  if (!column.visible) return null
                  let cell = column.cell(row, virtualRow.index)
                  if (column.columnId === COLUMN.SELECTION_COLUMN_ID) {
                    cell = (
                      <IndeterminateCheckbox
                        name={row.id}
                        checked={selectedRows.has(row.id)}
                        onChange={() => {
                          // DO NOTHING
                        }}
                        onClick={(event) => {
                          event.stopPropagation()
                          event.preventDefault()
                          onRowSelectionClicked(event, virtualRow.index)
                        }}
                      />
                    )
                  }
                  return (
                    <S.TableCell
                      key={column.columnId}
                      $customizedCell={column.customizedCell}
                      $hasDependentColumn={!!column.dependentExpandableColumnId}
                      onClick={(e) => {
                        if (column.ignoredOnRowClick) {
                          e.stopPropagation()
                          e.preventDefault()
                        }
                      }}
                    >
                      {cell}
                    </S.TableCell>
                  )
                })}
              </S.TableRow>
            )
          })}
        </S.Body>
      </S.Table>
    </div>
  )
}
