import { Await, useLoaderData } from 'react-router-dom'
import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING } from 'src/styles/constants'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { Sourcing } from 'src/components/blocks/sourcing/sourcing'
import type { SourceRefinementLoaderData } from 'src/libs/loaders/sourcing'
import { Suspense } from 'react'

interface JobCandidatesSourcingPageProps {
  isManualSourceView?: boolean
}

const JobCandidatesSourcingPage = ({ isManualSourceView = false }: JobCandidatesSourcingPageProps): JSX.Element => {
  const { jobId, job, sequence, jobSearchRefinements, jobSearchRefinementId, candidateJobs } = useLoaderData() as SourceRefinementLoaderData

  // const jobSearchRefinementSuggestedAdditions = useJobSearchRefinementSuggestedAdditions({
  //   jobId,
  //   jobSearchRefinementId
  // })

  // const { searchHistory } = useJobSearchRefinementSearchHistory({
  //   jobId: jobSearchRefinement?.jobId,
  //   jobSearchRefinementId: jobSearchRefinement?.id
  // })

  return (
    <Suspense
      fallback={
        <div
          style={{
            width: '100%',
            height: '100%',
            maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
            padding: `${CONTENT_PADDING}`
          }}
        >
          <LoadingSkeleton $variant="CandidateDetailsCard" />
        </div>
      }
    >
      <Await resolve={Promise.all([job, sequence, jobSearchRefinements, candidateJobs])}>
        {() => {
          return (
            <Sourcing
              key={jobSearchRefinementId ?? 'manualSourceView'}
              jobId={jobId}
              jobSearchRefinementId={jobSearchRefinementId}
              isManualSourceView={isManualSourceView}
            />
          )
        }}
      </Await>
    </Suspense>
  )
}

export default JobCandidatesSourcingPage
