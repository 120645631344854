import styled from 'styled-components'

export interface CompanyLogoStyleProps {
  $shape?: 'sharp' | 'soft' | 'rounded' | 'circle'
}

export const getBorderRadius = (shape: CompanyLogoStyleProps['$shape']): string => {
  switch (shape) {
    case 'sharp': {
      return '0'
    }
    case 'soft': {
      return '4px'
    }
    case 'rounded': {
      return '6px'
    }
    case 'circle': {
      return '999px'
    }
    default: {
      return '4px'
    }
  }
}

export const CompanyLogo = styled.div<CompanyLogoStyleProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: ${({ $shape }) => getBorderRadius($shape)};
`

export const Image = styled.img``

export const Icon = styled.div`
  width: 100%;
  height: 100%;
  padding: 20%;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
`
