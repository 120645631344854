import { Flex } from 'src/components/primitives'
import type { CustomRequirementCriteria } from 'src/libs/api/backend/candidate_search'
import { CriteriaSelection } from './criteria-selection'
import * as S from './index.styled'
import { RemoveCriteria } from './remove-criteria'

interface CustomRequirementRowProps {
  criteriaValue: CustomRequirementCriteria
  onUpdate: (key: string, value: string | boolean) => void
  onRemove: () => void
  disabled: boolean
}

export const CustomRequirementRow = ({ criteriaValue, onUpdate, onRemove, disabled }: CustomRequirementRowProps): JSX.Element => {
  return (
    <Flex $gap={8} $align='center'>
      <CriteriaSelection
        value={criteriaValue.optional ?? true}
        onUpdate={onUpdate}
        disabled={disabled}
      />
      <S.CustomRequirementField $disabled={disabled}>
        <input
          placeholder='Custom requirement'
          value={criteriaValue.requirement}
          onChange={(e) => {
            onUpdate('requirement', e.target.value)
          }}
        />
      </S.CustomRequirementField>
      <RemoveCriteria
        onRemove={onRemove}
        disabled={disabled}
      />
    </Flex>
  )
}
