import styled from 'styled-components'
import type { ButtonGroupStyleProps } from './button-group'
import type { Theme } from 'src/styles/theme/types'
import type { Variant } from '../button'

export const getVariantStyles = (variant: Variant, theme: Theme) => {
  switch (variant) {
    case 'raised': {
      return `
        background-color: ${theme.colors.bgPrimary};
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
        @media (hover: hover) {
          &:hover {
            background-color: ${theme.colors.bgSecondary};
          }
        }
      `
    }
    default: {
      return null
    }
  }
}

export const ButtonGroup = styled.div<ButtonGroupStyleProps>`
  display: flex;
  flex-direction: ${({ $direction }) => ($direction === 'horizontal' ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  gap: ${({ $gap }) => `${$gap}px`};
  border-radius: ${({ theme, $groupBorderRadius }) => theme.spacing[$groupBorderRadius ?? 4]};
  overflow: hidden;
  padding: ${({ $padding }) => `${$padding ?? 0}px`};
  background-color: ${({ $backgroundColor }) => $backgroundColor ?? 'inherit'};
  ${({ theme, $variant }) => getVariantStyles($variant ?? 'raised', theme)}
  transition: all 0.3s ease;
  button {
    border-radius: ${({ theme, $borderRadius }) =>
      $borderRadius ? theme.spacing[$borderRadius] : '0 !important'};
    > span {
      border-radius: 0 !important;
    }
    transition: all 0.3s ease;
  }
`
