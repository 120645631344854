import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { fetchBillingCheckout } from 'src/libs/api/backend/billing'
import type { BillingCheckout } from 'src/libs/api/backend/billing'
import { queryKeys } from 'src/libs/query-keys'

export const useBillingCheckoutQuery = (): UseQueryResult<BillingCheckout> => {
  return useQuery({
    queryKey: [queryKeys.billingCheckout],
    queryFn: async () => await fetchBillingCheckout()
  })
}
