import styled from 'styled-components'

export const EmptyNotificationWrapper = styled.div`
  flex: 1;
  width: 100%;
  padding: 1rem;
`

export const NotificationContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[24]};
  padding: ${({ theme }) => `${theme.spacing[80]} 0 ${theme.spacing[160]} 0`};
  border: 1px dashed ${({ theme }) => theme.colors.fgTranslucent10};
  border-radius: ${({ theme }) => theme.spacing[8]};
`
