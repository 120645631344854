import { ChannelProvider } from 'ably/react'
import { useAtomValue } from 'jotai'
import { atsChannelAtom, candidateDetailsChannelAtom, candidateSearchChannelAtom, notificationsChannelAtom } from 'src/stores/websocket-channels'

interface ChannelsProviderProps {
  children: React.ReactNode
}

export const ChannelsProvider = ({ children }: ChannelsProviderProps): JSX.Element => {
  const notificationsChannel = useAtomValue(notificationsChannelAtom)
  const candidateSearchChannel = useAtomValue(candidateSearchChannelAtom)
  const candidateDetailsChannel = useAtomValue(candidateDetailsChannelAtom)
  const atsChannel = useAtomValue(atsChannelAtom)
  return (
    <ChannelProvider channelName={notificationsChannel}>
      <ChannelProvider channelName={candidateSearchChannel}>
        <ChannelProvider channelName={candidateDetailsChannel}>
          <ChannelProvider channelName={atsChannel}>
            {children}
          </ChannelProvider>
        </ChannelProvider>
      </ChannelProvider>
    </ChannelProvider>
  )
}
