import styled, { css, keyframes } from 'styled-components'

const rotate = keyframes`
  0% {
    transform:rotate(0deg);
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform:rotate(360deg);
    opacity: 0.5;
  }
`
// transform:rotate(720deg);

export const AutomateIcon = styled.div<{ $isAnimating: boolean, $size?: number }>`
  width: ${({ $size }) => `${$size ?? 32}px`};
  height: ${({ $size }) => `${$size ?? 32}px`};
  svg {
    filter: ${({ $isAnimating }) => $isAnimating ? 'none' : 'grayscale(1)'};
    transition: all 0.25s ease;
    path {
      &[data-name='automate-icon-dot'] {
        transform-origin: center;
        ${({ $isAnimating }) =>
          $isAnimating
            ? css`
                /* animation: ${rotate} 8s cubic-bezier(0.43, 0.84, 0.61, 0.14) infinite; */
                animation: ${rotate} 8s linear infinite;
              `
            : css`
                animation: none;
              `}
      }
    }
  }
`
