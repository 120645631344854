import { useMutation, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { updateOrg as updateOrgApi } from 'src/libs/api/backend/orgs'
import type { UpdateOrg } from 'src/libs/api/backend/orgs'
import type { Session } from 'src/libs/api/backend/session'
import { isNil } from 'lodash'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface Args {
  orgUpdate: Partial<UpdateOrg>
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  updateOrg: (args: Args) => void
}

export const useUpdateOrg = (): Res => {
  const queryClient = useQueryClient()

  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)

  const mutation = useMutation({
    mutationFn: async ({ orgUpdate }: Args) => {
      return await updateOrgApi(orgUpdate)
    },
    onError: (err) => {
      console.error(err)
      notifyError({
        message: `An error occurred when updating your organization: ${err.message}`,
        autoClose: false
      })
    },
    onSuccess: async (data, variables) => {
      queryClient.setQueryData<Session>([queryKeys.session], (oldSession) => {
        if (isNil(oldSession)) {
          return
        }

        return {
          ...oldSession,
          org: {
            ...oldSession?.org,
            ...data
          },
          logoUrl: data.logoUrl
        }
      })
      notifySuccess({
        message: 'Successfully updated company details'
      })
      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })

  const updateOrg = (args: Args): void => {
    mutation.mutate(args)
  }

  return { updateOrg }
}
