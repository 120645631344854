import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import type { Tab } from 'src/components/primitives/tabbar'

interface Args {
  tabs?: Tab[]
  initiallySelected?: Tab['value']
  trackInUrl?: boolean
  paramsKey?: string
}

export const useTab = ({
  tabs,
  initiallySelected,
  trackInUrl = false,
  paramsKey = 'tab'
}: Args = {}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedTab, setSelectedTab] = useState<Tab | null>(null)

  const setTab = (tab: Tab): void => {
    setSelectedTab(tab)
    if (trackInUrl) {
      setSearchParams({ [paramsKey]: tab.value })
    }
  }

  useEffect(() => {
    if (initiallySelected) {
      const selected = tabs?.find((tab) => tab.value === initiallySelected)
      if (selected) {
        setSelectedTab(selected)
      }
    }
  }, [initiallySelected, tabs])

  useEffect(() => {
    if (searchParams && trackInUrl) {
      const value = searchParams.get(paramsKey)
      const getTab = tabs?.find((tab) => tab.value === value)
      setSelectedTab(getTab ?? tabs?.[0] ?? null)
    }
  }, [searchParams, paramsKey, tabs, trackInUrl])

  const filter = searchParams.get(paramsKey)

  return {
    selectedTab,
    filter,
    setTab
  }
}
