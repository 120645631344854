import { Flex } from 'src/components/primitives'
import { Caption } from 'src/components/primitives/typography'
import { useSession } from 'src/hooks/use-session'
import * as S from './job-tile.styled'
import { Avatar } from 'src/components/primitives/avatar'
import { DepartmentLogo } from 'src/components/blocks/department-logo'
import type { JobWithDepartment } from 'src/libs/api/backend/jobs'

interface JobTileProps {
  id: string
  isActive: boolean
  title: string
  job: JobWithDepartment
  onClick: (id: string) => void
}

export const JobTile = ({
  id, isActive, title, job, onClick
}: JobTileProps): JSX.Element => {
  const { org, orgLogoUrl } = useSession()

  return (
    <S.JobTile
      $isActive={isActive}
      onClick={() => {
        onClick(id)
      }}
    >
      {/* <S.RadioSelection
              type="radio"
              checked={isActive}
              $isActive={isActive}
              onChange={() => {
                onClick(id)
              }}
            /> */}
      {job.departmentId
        ? <DepartmentLogo departmentId={job.departmentId} $size={32} />
        : <Avatar
          $size={32}
          initials={org?.name}
          photoUrl={orgLogoUrl}
          $border={false}
          $shape="soft"
          $type="logo" />}
      <Flex $direction="column" $gap={2}>
        <Caption size="MD">{job.department?.name ?? org?.name ?? ''} - {title}</Caption>
      </Flex>
    </S.JobTile>
  )
}
