import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { getSuggestedResponseForCandidateJob } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJob, SuggestedResponse } from 'src/libs/api/backend/candidate_jobs'
import { queryKeys } from 'src/libs/query-keys'

interface UseSuggestedResponseOpts {
  candidateJobId?: CandidateJob['id'] | null
}

export const useSuggestedResponse = (opts: UseSuggestedResponseOpts): UseQueryResult<SuggestedResponse> => {
  return useQuery({
    queryKey: [queryKeys.suggestedResponse, opts.candidateJobId],
    queryFn: async () => await getSuggestedResponseForCandidateJob({ candidateJobId: opts.candidateJobId ?? '' }),
    staleTime: 60 * 1000,
    enabled: !!opts.candidateJobId
  })
}
