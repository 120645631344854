import type { EditorSerializer } from '.'

// TipTap/ProseMirror includes <br> for empty div's automatically but doesn't
// include it in its `getHtml()` output. This serializer adds <br> to empty div's
// and removes them for the editor.
export class LinebreaksSerializer implements EditorSerializer {
  serialize (html: string): string {
    return html.replace(/<\/div><div><\/div><div>/g, '</div><div><br></div><div>')
  }

  deserialize (html: string): string {
    return html.replace(/<\/div><div><br\s?\/?><\/div><div>/g, '</div><div></div><div>').replace(/\n/g, '<br />')
  }
}
