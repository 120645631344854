import * as Dialog from 'src/components/primitives/dialog'
import { CreateJobForm } from 'src/components/blocks/create-job-form'
import * as S from './create-new-position-dialog.styled'
import { useMemo, useState } from 'react'
import { controlDialogAtom, DialogId, isDialogOpenAtom, openDialogAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'

export const CreateNewPositionDialog = (): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.CREATE_JOB_POSITION), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const openDialog = useSetAtom(openDialogAtom)
  const [isModal, setIsModal] = useState(true)

  return (
    <Dialog.Root
      id={DialogId.CREATE_JOB_POSITION}
      isOpen={isDialogOpen}
      modal={isModal}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.CREATE_JOB_POSITION, newState: value })
      }}
      $width="almostFull"
      $height="almostFull"
      $innerPadding={{ top: 0, right: 0, bottom: 0, left: 0 }}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="New Job Position"
          onClose={() => {
            controlDialog({ id: DialogId.CREATE_JOB_POSITION, newState: false })
          }}
        />
        <Dialog.Content>
          <S.ContentWrapper>
            <S.MediaColumn>
              <img src="/onboarding-company-profile.png" />
            </S.MediaColumn>
            <S.FormColumn>
              <CreateJobForm
                redirectType="jobs"
                onCreateJob={() => {
                  controlDialog({ id: DialogId.CREATE_JOB_POSITION, newState: false })
                  openDialog({ id: DialogId.CREATE_SEQUENCE, payload: 'OPTIONS' })
                }}
                onMergeLinkVisibility={(isVisible: boolean) => {
                  setIsModal(!isVisible)
                }}
              />
            </S.FormColumn>
          </S.ContentWrapper>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
