import styled from 'styled-components'

export const RatingsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing[40]};
  width: 100%;
`

export const RatingColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
  width: 100%;
  /* align-items: space-between; */
`

export const RatingLinkedInButton = styled.div`
  visibility: hidden;
`

export const RatingDeleteButton = styled.div`
  background: none;
  border: none;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing[6]};
  visibility: hidden;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CompanyRatingTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
  flex: 1 1 auto;
  margin-left: ${({ theme }) => theme.spacing[4]};
`

export const CompanyRatingTile = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[16]};
  padding: ${({ theme }) => theme.spacing[12]};
  border-radius: ${({ theme }) => theme.spacing[6]};
  border: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  justify-content: space-between;
  width: 100%;

  &:hover {
    ${RatingLinkedInButton} {
      visibility: visible;
    }
    ${RatingDeleteButton} {
      visibility: visible;
    }
  }
`

export const EmptyState = styled.div`
  height: 100%;
  padding: 2.5rem;
  border: dashed 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`
