import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
// import axios from 'axios'
import { isNil } from 'lodash'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  url?: string | null
  size?: number
}

// const blobToDataUrl = (blob: Blob): Promise<string> => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.onloadend = () => resolve(reader.result as string)
//     reader.onerror = reject
//     reader.readAsDataURL(blob)
//   })
// }

const checkImage = async (url: string, size?: number): Promise<string> => {
  return await new Promise((resolve, reject) => {
    const image = new Image()
    image.onerror = () => {
      reject(new Error())
    }
    image.onload = () => {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      let newWidth = image.width
      let newHeight = image.height
      if (size && newWidth > size * 2) { // * 2 because we want to make sure we have enough space for the border
        const aspectRatio = newWidth / newHeight
        // Calculate new dimensions
        newWidth = size * 2
        newHeight = size * 2 / aspectRatio
      }
      canvas.width = newWidth
      canvas.height = newHeight
      context?.drawImage(image, 0, 0, newWidth, newHeight)
      const dataUrl = canvas.toDataURL('image/png')

      // rejects profile photos from LinkedIn
      if (dataUrl.length === 21298 && dataUrl.startsWith('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAAAAXNSR0IArs4c6QAAIABJREFUeF7tfeeTJNdx569s27E76')) {
        reject(new Error())
        return
      }

      // rejects default company logo from LinkedIn
      if (dataUrl.length === 5302 && dataUrl.startsWith('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAAAAXNSR0IArs4c6QAADzBJREFUeF7tnc+u3EgVh8vum0RhBIt5GZRVNJNRVoM0WY8yGxBPgHiOeQ')) {
        reject(new Error())
        return
      }

      resolve(dataUrl)
    }
    image.setAttribute('crossOrigin', '')
    image.src = url
  })

  // const response = await axios.get(url, {
  //   responseType: 'blob'
  // })

  // const dataUrl = await blobToDataUrl(response.data)
  // return dataUrl
}

export const useAvatarQuery = ({ url, size }: Args): UseQueryResult<string> => {
  let queryKeyUrl = url
  if (!isNil(url) && url !== '') {
    const u = new URL(url)
    queryKeyUrl = u.toString().replace(u.search, '')
  }

  return useQuery({
    queryKey: [queryKeys.avatars, queryKeyUrl, size],
    queryFn: async () => {
      if (isNil(url)) {
        return null
      }

      try {
        return await checkImage(url, size)
      } catch (e) {
        return null
      }
    },
    staleTime: Infinity, // 1 day
    enabled: !!url,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    throwOnError: false
  })
}
