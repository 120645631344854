import styled from 'styled-components'

export const EmailSequenceSuggestion = styled.div.attrs<{ isCollapsed: boolean }>(() => ({
  isCollapsed: false
}))`
  width: 100%;
  min-height: ${(props) => props.isCollapsed ? '175px' : 'none'};
  padding: .75rem;
`

export const EmailSequenceSuggestionWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 2px dotted ${({ theme }) => theme.colors.tintTranslucent25};
`

export const Banner = styled.div`
  padding: 0.25rem .75rem;
  width: 100%;
  background: ${({ theme }) => theme.colors.tintTranslucent5};
`

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  width: 100%;
  max-height: 250px;
  overflow: auto;
  border-bottom: 1px solid ${({ theme }) => theme.colors.fgTranslucent5}
`

export const Footer = styled.div`
  padding: .75rem;
`
