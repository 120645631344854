import styled from 'styled-components'
import { SIZES } from './constants'

export const Toolbar = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: ${SIZES.FOOTER.LG}px;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.fgTranslucent10};
  z-index: 3;
`

export const SelectedEvent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`
