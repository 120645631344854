import { queryOptions, useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { fetchJob } from 'src/libs/api/backend/jobs'
import type { Job } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'

interface UseJobQueryOpts {
  enabled?: boolean
  refetchInterval?: number | false | ((query: unknown) => number | false | undefined)
}

export const jobQuery = (jobId: string, opts: UseJobQueryOpts = {}) => queryOptions({
  queryKey: [queryKeys.job, jobId],
  queryFn: async () => await fetchJob(jobId),
  staleTime: 60 * 1000,
  ...opts
})

export const useJobQuery = (opts: UseJobQueryOpts = {}): UseQueryResult<Job> => {
  const { jobId } = useParams()
  return useQuery(jobQuery(jobId ?? '', opts))
}
