import styled from 'styled-components'

export const StartSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.75rem;
  margin-bottom: ${({ theme }) => theme.spacing[24]};
`

export const SelectOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.75rem;
  button {
    padding-left: 1.5rem;
    svg {
      margin-right: 1rem;
    }
  }
`

export const ExistingJobsList = styled.ul`
  cursor: pointer;
  margin-top: 1.5rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  /* max-height: 40vh; */
  /* overflow-y: auto; */
`

export const ExistingJob = styled.div`
  width: 100%;
  // border-radius: 4px;
  // border: 1px solid;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  // background: ${({ theme }) => theme.colors.bgPrimary};
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // @media (hover: hover) {
  //   &:hover {
  //     background-color: ${({ theme }) => theme.colors.borderOpaque};
  //   }
  // }
`

export const ExistingJobSource = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-align: contain;
  }
`

export const ExistingJobsGroup = styled.div`
  margin-top: ${({ theme }) => theme.spacing[18]};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.bgTertiary};
`

export const ExistingJobsGroupHeader = styled.div`
  padding: ${({ theme }) => theme.spacing[12]} ${({ theme }) => theme.spacing[16]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgTertiary};
`

export const ExistingJobsGroupScrollContainer = styled.div`
  max-height: calc(65px * 3);
  overflow-y: auto;
  overflow-x: hidden;
`
