import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { isNil } from 'lodash'

import LoginBox from 'src/components/blocks/login-box'
import RouteBuilder from 'src/libs/route-builder'
import LoginError from 'src/components/blocks/login-error'

import { Spacer } from 'src/components/primitives'
import { AuthLayout } from 'src/components/layouts/auth'
import { Caption } from 'src/components/primitives/typography'
import { useSession } from 'src/hooks/use-session'
import { useLoginRedirectPath } from 'src/hooks/use-login-redirect-path'
import { SEO } from '../components/primitives/seo'
import { trackEvent } from 'src/libs/track'
import { TrackingEventType } from 'src/types/track'

const Login = (): JSX.Element => {
  const [authStateIsLoading, setAuthStateIsLoading] = useState(true)
  const navigate = useNavigate()
  const { isLoading, redirectPath: loginRedirectPath } = useLoginRedirectPath()
  const { user } = useSession()

  const { search } = useLocation()

  const params = new URLSearchParams(search)
  const error = params.get('error')

  // Redirect logged in users to dashboard
  useEffect(() => {
    if (!isLoading && !isNil(loginRedirectPath) && user?.id) {
      navigate(loginRedirectPath)
    }
    if (!isLoading && !user?.id) {
      setAuthStateIsLoading(false)
    }
  }, [user, isLoading, loginRedirectPath, navigate])

  if (authStateIsLoading) {
    return <></>
  }

  trackEvent(TrackingEventType.VIEWED_LOGIN_PAGE)

  return (
    <>
      <SEO title="Login" />

      <AuthLayout>
        <LoginBox redirectPath={RouteBuilder.build('LOGIN/REDIRECT')}>
          <Caption size='LG'>Continue with your work email</Caption>
          {error && (
            <>
              <Spacer $size={24} />
              <LoginError error={error} />
            </>
          )}
        </LoginBox>
      </AuthLayout>
    </>
  )
}

export default Login
