import styled from 'styled-components'

export const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  padding: 3.75rem 0;
  div {
    width: 38%;
  }
`

export const ResultList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[12]};
`

export const ResultGroup = styled.div`
  padding: 0.5rem;
  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
  width: 100%;
`

export const UserProfilePhoto = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.spacing[2]};
`

export const ResultTile = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: ${({ theme }) => theme.spacing[2]};
  overflow-x: hidden;
  padding: 0px 2px 2px 2px;
`

export const ResultGroupHeading = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.375rem;
  pointer-events: none;
  gap: ${({ theme }) => theme.spacing[6]};
`

export const ResultListItem = styled.div`
  width: 100%;
  padding: 0.25rem 0.375rem;
  min-height: 1.5rem;
  border-radius: 4px;
  user-select: none;
  &[data-selected='true'] {
    box-shadow: ${({ theme }) => theme.boxShadows['light-active']};
  }
  div[data-name='inner'] {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[6]};
    outline: none;
    &:focus {
      outline: none;
    }
  }
  button {
    align-self: center;
  }
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.bgTertiary};
    }
  }
`

export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[6]};
  width: fit-content;
  p {
    width: 120px;
  }
`

export const SearchBoxWrapper = styled.div`
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
`
