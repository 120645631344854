import * as Dialog from 'src/components/primitives/dialog'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import { Button } from 'src/components/primitives'
import { AutomateIcon } from 'src/components/primitives/automate-icon'
import { useCallback, useMemo } from 'react'
import { useQueryParams } from 'src/hooks/use-query-params'
import { useUpdateJobSearchRefinement } from 'src/hooks/mutations/use-update-job-search-refinement'
import { useParams } from 'react-router-dom'
import RouteBuilder from 'src/libs/route-builder'
import { closeDialogAtom, controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'

export const EnableAutoOutreachDialog = (): JSX.Element => {
  const { jobId } = useParams()
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.ENABLE_AUTO_OUTREACH), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const closeDialog = useSetAtom(closeDialogAtom)
  const { getParam } = useQueryParams()
  const jobSearchRefinementId = getParam('jobSearchRefinementId')
  const { updateJobSearchRefinement } = useUpdateJobSearchRefinement()

  const handleUpdateJobRefinement = useCallback(
    (isEnabled: boolean): void => {
      if (jobId && jobSearchRefinementId) {
        updateJobSearchRefinement({
          jobId,
          jobSearchRefinementId,
          automateAutoApproveCandidates: isEnabled,
          onSuccess: () => {
            closeDialog(DialogId.ENABLE_AUTO_OUTREACH)
          }
        })
      }
    },
    [updateJobSearchRefinement, jobId, jobSearchRefinementId, closeDialog]
  )

  return (
    <Dialog.Root
      id={DialogId.ENABLE_AUTO_OUTREACH}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.ENABLE_AUTO_OUTREACH, newState: value })
      }}
      $width="480px"
    >
      <Dialog.Portal>
        <Dialog.Content>
          <Flex $direction="column" $gap={24}>
            <AutomateIcon isAnimating $size={64} />
            <Flex $direction="column" $gap={16}>
              <Caption size="MD">Enable Auto-outreach</Caption>
              <Paragraph size="XS">
                Pin will automatically reach out to new candidates from this search daily.
              </Paragraph>
              <Paragraph size="XS">
                The number of candidates and time they&rsquo;re emailed will depend on your outreach
                preferences and limits.
              </Paragraph>
            </Flex>
            <Flex $align="center" $gap={16}>
              <Button
                $variant="fill"
                $colorTheme="tint"
                $height={32}
                onClick={() => {
                  handleUpdateJobRefinement(true)
                }}
              >
                Enable Auto-outreach
              </Button>
              <Button
                $variant="outline"
                $colorTheme="muted"
                $height={32}
                onClick={() => {
                  closeDialog(DialogId.ENABLE_AUTO_OUTREACH)
                }}
                href={RouteBuilder.build('JOBS_AUTOMATION', { jobId })}
              >
                Review outreach preferences
              </Button>
            </Flex>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
