import { fetchJobReport } from '../../libs/api/backend/reports'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

export const jobReportQuery = (jobId: string | undefined) => {
  return {
    queryKey: ['jobReport', jobId],
    queryFn: async () => await fetchJobReport(jobId ?? ''),
    staleTime: 60 * 1000 * 15, // 15 minutes
    enabled: !!jobId
  }
}

export const useJobReportQuery = () => {
  const { jobId } = useParams()
  return useQuery(jobReportQuery(jobId))
}
