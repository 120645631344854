import { BenefitsList } from 'src/components/blocks/benefits-list'
import { OnboardingHeader } from 'src/components/blocks/onboarding-header'
import { Button } from 'src/components/primitives/button'
import { Grid } from 'src/components/primitives/grid'
import { Spacer } from 'src/components/primitives/spacer'
import RouteBuilder from 'src/libs/route-builder'
import { PinExtensionChromeStoreUrl } from '../../constants'
import { usePinExtensionVersion } from '../../hooks/use-pin-extension'
import { Paragraph } from '../../components/primitives/typography'
import { useEffect, useState } from 'react'

const OnboardingExtension = (): JSX.Element => {
  const pinExtensionVersion = usePinExtensionVersion()
  const [didVisitChromeStore, setDidVisitChromeStore] = useState(false)

  useEffect(() => {
    if (!didVisitChromeStore) {
      return
    }

    // This uses focus instead of visibilitychange because if they genuinely interacted with the
    // webstore, we will loose focus. If they just open then close it, we never loose focus.

    const onWindowActive = (): void => {
      window.location.reload()
    }

    window.addEventListener('focus', onWindowActive)

    return () => {
      window.removeEventListener('focus', onWindowActive)
    }
  }, [didVisitChromeStore])

  return (
    <>
      <OnboardingHeader
        heading="Pin's Browser Extension"
        subheading="Add candidates from LinkedIn with one click."
      />

      <Grid $columns={2} $columnGap={16}>
        <Button
          $variant={pinExtensionVersion ? 'outline' : 'raised'}
          $colorTheme={pinExtensionVersion ? 'muted' : 'tint'}
          $height={40}
          $width="full"
          $align="center"
          leadingIcon="chrome"
          href={PinExtensionChromeStoreUrl}
          onClick={() => {
            setDidVisitChromeStore(true)
          }}
        >
          {pinExtensionVersion ? 'Chrome extension installed' : 'Install Chrome extension'}
        </Button>

        <Button
          $variant={pinExtensionVersion ? 'flat' : 'outline'}
          $colorTheme={pinExtensionVersion ? 'tint' : 'muted'}
          $height={40}
          $width="full"
          $align="center"
          href={RouteBuilder.build('ROOT')}
        >
          {pinExtensionVersion ? 'Continue' : 'Continue without extension'}
        </Button>
      </Grid>

      {didVisitChromeStore && (
        <div>
          <Paragraph>Once the extension is installed, reload this page to test it.</Paragraph>
        </div>
      )}

      <Spacer $size={40} />

      <BenefitsList
        $itemSize="small"
        benefits={[
          {
            icon: 'download',
            title: 'Add to Chrome',
            description: 'Click the “Add to Chrome” button on the Chrome Web Store.'
          },
          {
            icon: 'extensions',
            title: 'View extensions',
            description: 'Click the extensions icon to view all installed extensions.'
          },
          {
            icon: 'pin',
            title: 'Make it always visible',
            description:
              'Click the button to make the Pin extension always visible on your browser.'
          }
        ]}
      />
    </>
  )
}

export default OnboardingExtension
