import Api from '..'
import { z } from 'zod'
import { companyInformationParser } from './candidate_jobs'

export enum CompanyPreferenceType {
  LIKED = 'LIKED',
  DISLIKED = 'DISLIKED'
}

export const companyInformationWithImageParser = companyInformationParser.extend({
  logoUrl: z.string().nullish()
})

export type CompanyInformationWithImage = z.infer<typeof companyInformationWithImageParser>

export const companyPreferenceParser = z.object({
  id: z.string(),
  type: z.nativeEnum(CompanyPreferenceType),
  name: z.string(),
  linkedin: z.string(),
  logoUrl: z.string().nullish()
})

export type CompanyPreference = z.infer<typeof companyPreferenceParser>

export const addCompanyPreferenceParser = z.object({
  id: z.string().optional(),
  jobId: z.string(),
  departmentId: z.string().optional(),
  type: z.nativeEnum(CompanyPreferenceType),
  name: z.string(),
  linkedin: z.string()
})

export type AddCompanyPreference = z.infer<typeof addCompanyPreferenceParser>

export const companySuggestionParser = z.object({
  linkedin: z.string(),
  name: z.string(),
  website: z.string().nullish(),
  logoUrl: z.string().nullish()
})

export type CompanySuggestion = z.infer<typeof companySuggestionParser>

interface RateCompanyPreference {
  jobId: string
  departmentId?: string
  type: CompanyPreferenceType
  name: string
  linkedin: string
}

export async function rateCompanyPreference ({
  jobId,
  departmentId,
  type,
  name,
  linkedin
}: RateCompanyPreference): Promise<CompanyPreference> {
  const { data, status } = await Api.post('/company_preferences/', null, {
    jobId,
    departmentId,
    type,
    name,
    linkedin
  })

  if (status !== 200) {
    throw new Error('Failed to rate company preference')
  }

  const companyPreference = companyPreferenceParser.parse(data.companyPreference)

  return companyPreference
}

export async function deleteCompanyPreference (preferenceId: string): Promise<void> {
  const { status } = await Api.delete(`/company_preferences/${preferenceId}`)

  if (status !== 204) {
    throw new Error('Failed to delete company preference')
  }
}

export async function getCompanyPreferences (jobId: string): Promise<CompanyPreference[]> {
  const { data, status } = await Api.get(`/company_preferences/${jobId}`)

  if (status !== 200) {
    throw new Error('Failed to get company preferences')
  }

  const companyPreferences = z.array(companyPreferenceParser).parse(data)

  return companyPreferences
}

export async function addCompanyPreference (company: AddCompanyPreference): Promise<void> {
  const { status } = await Api.post('/company_preferences', null, company)

  if (status !== 200) {
    throw new Error('Failed to add company preference')
  }
}

export async function searchCompanies ({ query }: { query?: string | null }): Promise<CompanySuggestion[]> {
  const { status, data } = await Api.get(`/company_preferences/search?query=${query}`)

  if (status !== 200) {
    throw new Error('Failed to search companies')
  }

  const result = z.array(companySuggestionParser).parse(data)
  return result
}
