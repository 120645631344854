import { z } from 'zod'
import { useForm } from 'src/hooks/use-form'
import { Button } from 'src/components/primitives/button'
import { Form } from 'src/components/forms'
import { RadioGroup } from 'src/components/forms/radio-group/radio-group'
import { Card } from 'src/components/primitives/card'
import { VisualDivider } from 'src/components/primitives/visual-divider'
import * as S from './email-sequence-editor.styled'

const sequenceInstructionsSchema = z.object({
  contactAttempts: z.enum(['1', '2', '3', '4', '5', '6']),
  emailTone: z.enum(['Funny', 'Casual', 'Formal', 'Excited', 'Confident']),
  emailLength: z.enum(['Short', 'Medium', 'Long', 'Mix'])
})

export type SequenceInstructionsForm = z.infer<typeof sequenceInstructionsSchema>

export type SequenceEmailGenerationType = 'aiGenerated' | 'manually'

interface OnContinueArgs {
  emailType: SequenceEmailGenerationType
  instructions?: SequenceInstructionsForm
}

interface SequenceInstructionsProps {
  onContinue?: ({ emailType, instructions }: OnContinueArgs) => void
}

export const SequenceInstructions = ({ onContinue }: SequenceInstructionsProps): JSX.Element => {
  const { submit, register } = useForm<SequenceInstructionsForm>({
    schema: sequenceInstructionsSchema,
    initialValues: {
      contactAttempts: sequenceInstructionsSchema.shape.contactAttempts.options[2],
      emailTone: sequenceInstructionsSchema.shape.emailTone.options[1],
      emailLength: sequenceInstructionsSchema.shape.emailLength.options[0]
    }
  })

  const contactAttemptOptions = sequenceInstructionsSchema.shape.contactAttempts.options.map(
    (value) => ({
      title: value,
      value
    })
  )

  const emailToneOptions = sequenceInstructionsSchema.shape.emailTone.options.map((value) => ({
    title: value,
    value
  }))

  const emailLengthOptions = sequenceInstructionsSchema.shape.emailLength.options.map((value) => ({
    title: value,
    value
  }))

  const handleSubmit = async (formData: SequenceInstructionsForm) => {
    if (onContinue) {
      onContinue({ emailType: 'aiGenerated', instructions: formData })
    }
  }

  return (
    <>
      <Card $padding={{ top: 16, left: 16, right: 16, bottom: 16 }}>
        <S.SequenceInstructions>
          <Form onSubmit={submit(handleSubmit)}>
            <RadioGroup
              name="contactAttempts"
              label="How many contact attempts do you want to make?"
              register={register}
              options={contactAttemptOptions}
            />
            <RadioGroup
              name="emailTone"
              label="What tone should the emails have?"
              register={register}
              options={emailToneOptions}
            />
            <RadioGroup
              name="emailLength"
              label="How extensive should emails be?"
              register={register}
              options={emailLengthOptions}
            />
            <Button $variant="fill" $colorTheme="tint" type="submit">
              Generate outreach sequence
            </Button>
          </Form>
        </S.SequenceInstructions>
      </Card>
      <VisualDivider $spacingTop={24} $spacingBottom={24}>
        Or
      </VisualDivider>
      <Button
        $variant="raised"
        $colorTheme="tint"
        onClick={() => {
          if (onContinue) {
            onContinue({ emailType: 'manually' })
          }
        }}
      >
        Write emails manually
      </Button>
    </>
  )
}
