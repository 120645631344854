import { useState } from 'react'
import { Button } from 'src/components/primitives/button'
import { Flex } from 'src/components/primitives/flex'
import type { IconName } from 'src/components/primitives/icon'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'

interface DestinationStage {
  title: string
  value: CandidateJobStage
  icon: IconName
}

interface CandidateStageSelectionOptionsProps {
  onStageChange: (stage: CandidateJobStage) => void
}

export const CandidateStageSelectionOptions = ({ onStageChange }: CandidateStageSelectionOptionsProps): JSX.Element => {
  const [destinationStage, setDestinationStage] = useState<CandidateJobStage>(CandidateJobStage.SOURCED)

  const destinationStageOptions: DestinationStage[] = [
    {
      title: 'Sourcing',
      value: CandidateJobStage.SOURCED,
      icon: 'binoculars'
    },
    {
      title: 'Outreach',
      value: CandidateJobStage.PROSPECTING,
      icon: 'mails'
    },
    {
      title: 'Inbox',
      value: CandidateJobStage.COMMUNICATING,
      icon: 'inbox'
    }
  ]
  return (
    <Flex $gap={6} $justify="space-evenly">
      {destinationStageOptions.map((stage) => (
        <Button
          key={stage.title}
          $height={40}
          $width="full"
          leadingIcon={stage.icon}
          $variant="flat"
          $colorTheme={destinationStage === stage.value ? 'tint' : 'normal'}
          $align="center"
          onClick={() => {
            setDestinationStage(stage.value)
            onStageChange(stage.value)
          }}
        >
          {stage.title}
        </Button>
      ))}
    </Flex>
  )
}
