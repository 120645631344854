import Api from '..'
import { z } from 'zod'

interface SalaryRangePredictionInput {
  jobTitle: string
}

const salaryRangePredictionParser = z.object({
  idealMin: z.coerce.number().optional(),
  idealMax: z.coerce.number().optional(),
  salaryMin: z.coerce.number().optional(),
  salaryMax: z.coerce.number().optional()
})

export type SalaryRangePrediction = z.infer<typeof salaryRangePredictionParser>

export async function fetchSalaryRangePrediction (input: SalaryRangePredictionInput): Promise<SalaryRangePrediction> {
  const { data } = await Api.get('/jobs/salary_ranges', input)
  return salaryRangePredictionParser.parse(data)
}
