import { CompaniesPreferencesContext } from 'src/contexts/companies-preferences'
import { useGetCompaniesPreferences } from 'src/hooks/queries/use-get-companies-preferences'
import { keyBy } from 'lodash'
import { useMemo } from 'react'

interface CompaniesPreferencesProviderProps {
  children: React.ReactNode
}

export const CompaniesPreferencesProvider = ({ children }: CompaniesPreferencesProviderProps): JSX.Element => {
  const { data: companiesPreferences = [] } = useGetCompaniesPreferences()
  const companyPreferencesByLinkedin = useMemo(() => keyBy(companiesPreferences, 'linkedin'), [companiesPreferences])
  return (
    <CompaniesPreferencesContext.Provider value={{ companyPreferencesByLinkedin }}>
      {children}
    </CompaniesPreferencesContext.Provider>
  )
}
