import styled from 'styled-components'
import type { Step } from './create-job-form'

interface CreateJobFormProps {
  $step: Step
}

export const CreateJobForm = styled.div<CreateJobFormProps>`
  width: 100%;
  max-width: ${({ $step }) => ($step === 'generate' ? '100%' : '640px')};
`
