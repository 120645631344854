import { Button, Flex } from 'src/components/primitives'
import * as S from './email-disconnection.styled'
import { useCallback } from 'react'
import { getEmailAccountAuthUrl } from 'src/libs/auth-urls'
import { isNil } from 'lodash'
import { useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import type { Session } from 'src/libs/api/backend/session'
import { invalidateEmailAccounts } from 'src/hooks/invalidate-email-accounts'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'

interface EmailDisconnectionProps {
  payload: {
    disconnectedAccount: Session['emailAccountAccessTokens'][0]
  }
}

export const EmailDisconnection = ({ payload }: EmailDisconnectionProps): JSX.Element => {
  const queryClient = useQueryClient()
  const openDialog = useSetAtom(openDialogAtom)
  const { disconnectedAccount } = payload

  const invalidateSession = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [queryKeys.session]
    })
  }, [queryClient])

  const reconnect = useCallback((): void => {
    if (!isNil(disconnectedAccount)) {
      const redirectUrl = `${window.location.origin}/login/redirect/close`
      const authUrl = getEmailAccountAuthUrl(disconnectedAccount, redirectUrl)
      const loginWindow = window.open(authUrl, '_blank', 'popup=1,height=600,width=600')
      const timer = setInterval(() => {
        console.log(loginWindow)
        if (loginWindow?.closed) {
          void invalidateEmailAccounts()
          clearInterval(timer)
        }
      }, 500)
    }
  }, [invalidateSession, disconnectedAccount])

  return (
    <S.ErrorNotice>
      <Flex $direction="column" $gap={2}>
        <h2>We're unable to send emails through {disconnectedAccount?.email}</h2>
        <p>Outreach sequences that you're sending are paused until account is reconnected</p>
      </Flex>
      <Flex $direction="row" $gap={16} $align="center" $width="auto">
        <Button
          $height={24}
          $variant="raised"
          $colorTheme="tint"
          leadingIcon="mails"
          $fontSize={12}
          onClick={() => {
            openDialog({ id: DialogId.DISCONNECTED_EMAIL_ACCOUNT })
          }}
        >
          Review sequences
        </Button>
        <Button
          $height={24}
          $variant="fill"
          $colorTheme="tint"
          leadingIcon="refresh-cw"
          $fontSize={12}
          onClick={reconnect}
        >
          Reconnect account
        </Button>
      </Flex>
    </S.ErrorNotice>
  )
}
