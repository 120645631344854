import { useAtomValue, useSetAtom } from 'jotai'
import { useMemo } from 'react'
import { CreateCompanyForm } from 'src/components/blocks/create-company-form'
import * as Dialog from 'src/components/primitives/dialog'
import { controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'

export const CreateCompanyDialog = (): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.CREATE_DEPARTMENT), []))
  const controlDialog = useSetAtom(controlDialogAtom)

  return (
    <Dialog.Root
      id={DialogId.CREATE_DEPARTMENT}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.CREATE_DEPARTMENT, newState: value })
      }}
      $width="480px"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="New Company"
          onClose={() => {
            controlDialog({ id: DialogId.CREATE_DEPARTMENT, newState: false })
          }}
        />
        <Dialog.Content>
          <CreateCompanyForm />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
