import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Icon } from 'src/components/primitives/icon'
import * as Dialog from 'src/components/primitives/dialog'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import { debounce, isNil } from 'lodash'
import { When } from 'src/components/blocks/when'
import { searchCandidates } from 'src/libs/api/backend/candidate_jobs'
import type { CandidatesSearch } from 'src/libs/api/backend/candidate_jobs'
import { Avatar } from 'src/components/primitives/avatar'
import * as S from './search-candidates-dialog.styled'
import { ListItem } from './list-item'
import { Spacer } from 'src/components/primitives'
import { useSession } from 'src/hooks/queries/use-session'
import { SearchBox } from 'src/components/blocks/searchbox'
import { useAtomValue, useSetAtom } from 'jotai'
import { isDialogOpenAtom, DialogId, controlDialogAtom, closeDialogAtom, openDialogAtom } from 'src/stores/dialogs'

export const SearchCandidatesDialog = (): JSX.Element => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { jobId: currentJobId } = useParams()
  const { data: session } = useSession()
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.SEARCH_CANDIDATES), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const closeDialog = useSetAtom(closeDialogAtom)
  const openDialog = useSetAtom(openDialogAtom)
  const [result, setResult] = useState<CandidatesSearch[] | null>(null)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

  const debouncedInputChange = debounce((searchValue: string) => {
    const fetchCandidates = async (): Promise<void> => {
      if (searchValue.trim().length >= 2) {
        try {
          const result = await searchCandidates(searchValue)
          setResult(result)
        } catch (e) {
          // TODO: Handle error
          console.error('search error', e)
        }
      } else {
        setResult(null)
        setSelectedIndex(null)
      }
    }
    void fetchCandidates()
  }, 300)

  const handleInputChange = useCallback(
    (value: string) => {
      setSelectedIndex(null)
      const searchValue = value.toLowerCase()
      debouncedInputChange(searchValue)
    },
    [debouncedInputChange]
  )

  const flattenedCandidates = useMemo(() => {
    if (!result) return []

    return result.flatMap((job, jobIndex) =>
      job.candidateJobs.map((candidateJob, candidateIndex) => ({
        ...candidateJob,
        jobIndex,
        candidateIndex,
        jobId: job.jobId
      }))
    )
  }, [result])

  const onCandidateJobSelect = useCallback((candidateJobId: string, targetJobId: string) => {
    if (currentJobId === targetJobId) {
      openDialog({ id: DialogId.CANDIDATE_DETAILS, payload: candidateJobId })
    } else {
      let pathToNavigateTo: string = pathname
      if (pathname.startsWith('/jobs') && currentJobId) {
        pathToNavigateTo = pathname.replace(
          currentJobId,
          targetJobId
        )
      } else {
        pathToNavigateTo = `/jobs/${targetJobId}/candidates/sourcing`
      }
      navigate(pathToNavigateTo)
      openDialog({ id: DialogId.CANDIDATE_DETAILS, payload: candidateJobId })
    }
    closeDialog(DialogId.SEARCH_CANDIDATES)
  }, [closeDialog, currentJobId, navigate, openDialog, pathname])

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent): void => {
      if (event.key === 'Enter' && selectedIndex !== null) {
        const selectedCandidate = flattenedCandidates[selectedIndex]
        const selectedCandidateJobId = flattenedCandidates[selectedIndex].id
        const jobIdSelectedCandidateBelongsTo = selectedCandidate.jobId
        onCandidateJobSelect(selectedCandidateJobId, jobIdSelectedCandidateBelongsTo)
        return
      }

      if (!flattenedCandidates.length) return

      let newIndex = selectedIndex
      if (event.key === 'ArrowUp' || (event.ctrlKey && event.key === 'k')) {
        event.preventDefault()
        if (selectedIndex === 0 || selectedIndex === null) {
          newIndex = flattenedCandidates.length - 1
        } else {
          newIndex = selectedIndex - 1
        }
      } else if (event.key === 'ArrowDown' || (event.ctrlKey && event.key === 'j')) {
        event.preventDefault()
        if (selectedIndex === null || selectedIndex === flattenedCandidates.length - 1) {
          newIndex = 0
        } else {
          newIndex = selectedIndex + 1
        }
      }

      setSelectedIndex(newIndex)
    }

    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [flattenedCandidates, onCandidateJobSelect, selectedIndex])

  return (
    <Dialog.Root
      id={DialogId.SEARCH_CANDIDATES}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.SEARCH_CANDIDATES, newState: value })
      }}
      $width="half"
      $maxWidth="640px"
      $position="top"
      $innerPadding={{ top: 0, left: 0, right: 0, bottom: 0 }}
    >
      <Dialog.Portal>
        <Dialog.Content>
          <S.SearchBoxWrapper>
            <SearchBox
              placeholder="Search by name or email"
              onClose={() => {
                closeDialog(DialogId.SEARCH_CANDIDATES)
              }}
              onValueChange={handleInputChange}
            />
          </S.SearchBoxWrapper>
          <When condition={isNil(result)}>
            <S.EmptyState>
              <Flex $gap={8} $direction="column" $align="center">
                <Icon name="binoculars" size={40} color="fgTranslucent10" />
                <Paragraph size="SM" $color="fgSecondary" $align="center">
                  Find candidates by name or email&nbsp;address
                </Paragraph>
                <Spacer $size={16} />
              </Flex>
            </S.EmptyState>
          </When>
          {Array.isArray(result) && result?.length >= 1 && (
            <S.ResultList
              onMouseOver={() => {
                setSelectedIndex(null)
              }}
              onMouseLeave={() => {
                setSelectedIndex(null)
              }}
            >
              {result.map((candidate, jobIndex) => (
                <S.ResultGroup key={candidate.candidateId}>
                  <S.UserProfilePhoto>
                  <Avatar
                      $size={40}
                      $type="photo"
                      initials={candidate.candidateName}
                      photoUrl={candidate.candidateProfilePhotoUrl}
                      fallbackAvatar="random"
                    />
                  </S.UserProfilePhoto>
                  <S.ResultTile>
                    <S.ResultGroupHeading>
                      <Caption size='SM' $whiteSpace='nowrap'>{candidate.candidateName}</Caption>
                      <Paragraph size='SM'>·</Paragraph>
                      <Paragraph size='SM' $ellipsis $whiteSpace='nowrap'>{candidate.candidateCurrentJob}</Paragraph>
                    </S.ResultGroupHeading>
                    {candidate.candidateJobs.map((candidateJob, candidateIndex) => {
                      const isSelected =
                        flattenedCandidates.findIndex(
                          (c) => c.jobIndex === jobIndex && c.candidateIndex === candidateIndex
                        ) === selectedIndex
                      return (
                        <ListItem
                          candidateJob={candidateJob}
                          candidateIndex={candidateIndex}
                          isSelected={isSelected}
                          session={session}
                          onClick={onCandidateJobSelect}
                        />
                      )
                    })}
                  </S.ResultTile>
                </S.ResultGroup>
              ))}
            </S.ResultList>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
