import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { generateSearchCriteriaApi } from 'src/libs/api/backend/candidate_search'
import type { Criteria, GenerateSearchCriteriaParams } from 'src/libs/api/backend/candidate_search'
import { notifyErrorAtom } from 'src/stores/notifications'

interface Args extends GenerateSearchCriteriaParams {
  onSuccess?: (data: Criteria) => void
  onError?: () => void
}

interface Res {
  generateSearchCriteria: (args: Args) => void
}

export const useGenerateSearchCriteria = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)

  const mutation = useMutation({
    mutationFn: async ({ onSuccess: _onSuccess, onError: _onError, ...args }: Args) => await generateSearchCriteriaApi(args),
    onError: (err, variables) => {
      notifyError({
        message: `An error occurred when generating search criteria: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (data, variables) => {
      if (variables.onSuccess) {
        variables.onSuccess(data)
      }
    }
  })

  const generateSearchCriteria = (args: Args): void => {
    mutation.mutate(args)
  }

  return { generateSearchCriteria }
}
