import { useLayoutEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Navigation } from 'src/components/blocks/navigation'
import { useWindowSize } from 'usehooks-ts'
import * as S from './dashboard.styled'
import { GlobalErrorProvider } from 'src/providers/global-error'
import { useGlobalError } from 'src/hooks/use-global-error'
import { usePrintView } from 'src/hooks/use-print-view'

const DashboardContainer = (): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const { isGlobalErrorOpen = false } = useGlobalError()

  const { width } = useWindowSize()
  useLayoutEffect(() => {
    const shouldBeCollapsed = width <= 1170
    setIsCollapsed(shouldBeCollapsed)
  }, [width])

  return (
    <S.DashboardLayout $isErrored={isGlobalErrorOpen}>
      <S.NavigationWrapper $isCollapsed={isCollapsed} $isErrored={isGlobalErrorOpen}>
        <Navigation
          isCollapsed={isCollapsed}
          onCollapseChange={(value) => {
            setIsCollapsed(value)
          }}
        />
      </S.NavigationWrapper>
      <S.Content $isCollapsed={isCollapsed} $isErrored={isGlobalErrorOpen}>
        <Outlet />
      </S.Content>
    </S.DashboardLayout>
  )
}

export const DashboardLayout = (): JSX.Element => {
  const { pathname } = useLocation()
  const FULLSCREEN_ROUTES = ['onboarding', 'jobs/new']
  const IS_FULLSCREEN = FULLSCREEN_ROUTES.some((route) => pathname.includes(route))
  const isPrintView = usePrintView()
  if (IS_FULLSCREEN || isPrintView) {
    return <Outlet />
  }

  return (
    <S.Container>
      <GlobalErrorProvider>
        <DashboardContainer />
      </GlobalErrorProvider>
    </S.Container>
  )
}
