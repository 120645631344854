import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { fetchCandidateActivities } from 'src/libs/api/backend/candidate_activities'
import type { CandidateActivity } from 'src/libs/api/backend/candidate_activities'
import { queryKeys } from 'src/libs/query-keys'

interface CandidateActivitesQueryProps {
  candidateId?: string
}

export const useCandidateActivitiesQuery = ({ candidateId }: CandidateActivitesQueryProps = {}): UseQueryResult<CandidateActivity[]> => {
  return useQuery({
    queryKey: [queryKeys.candidateActivities, candidateId],
    queryFn: async () => await fetchCandidateActivities(candidateId ?? ''),
    staleTime: 60 * 1000,
    enabled: !!candidateId
  })
}
