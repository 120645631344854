import styled from 'styled-components'
import { CommonFieldStyles } from '../common'

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const InputField = styled.div`
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.25rem;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: 0.625rem;
  input {
    width: 100%;
    color: ${({ theme }) => theme.colors.fgPrimary};
    font-size: ${({ theme }) => theme.fontSizes[14]};
    line-height: 1;
    border: none;
    outline: none;
    background-color: transparent;
  }
`

export const AddField = styled(CommonFieldStyles)`
  width: calc(100% - 32px);
`
