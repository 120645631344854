import styled from 'styled-components'

export const ChartHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  h5 {
    font-weight: 700;
  }
`

export const Context = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 0.625rem;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.625rem;
  margin-left: 1rem;
`
