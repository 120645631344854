import { Flex } from 'src/components/primitives/flex'
import * as S from './candidates-page-header.styled'
import { Button } from 'src/components/primitives/button'
import type { ButtonProps } from 'src/components/primitives/button'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { When } from '../when'
import React, { useEffect, useState } from 'react'
import { Form, Input } from 'src/components/forms'
import { z } from 'zod'
import { useForm } from 'src/hooks/use-form'
import { LoadingSkeleton } from '../loading-skeleton'
import { Icons } from 'src/components/primitives/icon'

export interface CandidatesPageHeaderStyleProps {
  $padding?: {
    top?: number
    bottom?: number
  }
}

export interface CandidatesPageHeaderProps extends CandidatesPageHeaderStyleProps {
  heading?: string
  subHeading?: string | null
  headingLoading?: boolean
  isEditable?: boolean
  onEdit?: (updated: string) => void
  actions?: ButtonProps[]
  customActions?: React.ReactNode[]
  context?: React.ReactNode
  isPrintView?: boolean
}

const headingSchema = z.object({
  heading: z.string()
})

type HeadingSchema = z.infer<typeof headingSchema>

export const CandidatesPageHeader = ({
  heading = 'Candidates',
  headingLoading = false,
  isEditable = false,
  subHeading,
  onEdit,
  actions,
  customActions,
  context,
  $padding = { top: 18, bottom: 18 },
  isPrintView = false
}: CandidatesPageHeaderProps): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { submit, register, setValue, formData } = useForm<HeadingSchema>({
    schema: headingSchema
  })

  useEffect(() => {
    if (heading) {
      setValue('heading', heading)
      setIsEditing(false)
      setIsSubmitting(false)
    }
  }, [heading, setValue])

  const handleSubmit = (formData: HeadingSchema): void => {
    if (onEdit) {
      setIsSubmitting(true)
      onEdit(formData.heading)
    }
  }

  return (
    <S.CandidatesPageHeader $padding={isPrintView ? { top: 6, bottom: 6 } : $padding}>
      <Flex $align="center" $justify="space-between" $gap={8}>
        <Flex $direction="column" $flex='1 1 auto' $width='auto' $gap={2}>
          <S.HeadingGroup>
            <When condition={!isEditing}>
              {headingLoading
                ? <LoadingSkeleton $variant="Header" />
                : <Caption as="h1" size="MD" $whiteSpace="nowrap">
                    {heading}
                  </Caption>
              }
            </When>
            <When condition={isEditing && !isPrintView}>
              <Form onSubmit={submit(handleSubmit)}>
                <Input
                  name="heading"
                  register={register}
                  label="Edit title"
                  hiddenLabel
                  setFocus={isEditing}
                  autoGrow
                  $marginBottom={0}
                  isDisabled={isSubmitting}
                  maxLength={50}
                />
                <Button
                  leadingIcon="check"
                  ariaLabel="Save changes"
                  $variant="ghost"
                  $colorTheme="muted"
                  $width={18}
                  $height={18}
                  $fontSize={12}
                  disabled={isSubmitting || ((formData.heading ?? '') as string).trim() === heading.trim()}
                  type="submit"
                />
              </Form>
              <Button
                leadingIcon="x"
                ariaLabel="Cancel changes"
                $variant="ghost"
                $colorTheme="muted"
                $width={18}
                $height={18}
                $fontSize={12}
                disabled={isSubmitting}
                onClick={() => {
                  setIsEditing(false)
                  setValue('heading', heading)
                }}
              />
            </When>
            <When condition={isEditable && !isEditing && !isPrintView}>
              <Button
                leadingIcon={Icons.penLine}
                ariaLabel="Edit title"
                $variant="ghost"
                $colorTheme="muted"
                $width={18}
                $height={18}
                $fontSize={12}
                onClick={() => {
                  setIsEditing(true)
                }}
              />
            </When>
          </S.HeadingGroup>
          {subHeading &&
            <Paragraph size="XS" $whiteSpace="nowrap" $ellipsis>
              {subHeading}
            </Paragraph>
          }
        </Flex>
        <When condition={!isPrintView}>
          <Flex $gap={8} $justify="flex-end" $width="auto" $align="center">
            {actions?.map((action, index) => (
              <Button
                key={index}
                href={action.href}
                onClick={action.onClick}
                $variant={action.$variant ?? 'ghost'}
                $colorTheme={action.$colorTheme ?? 'muted'}
                leadingIcon={action.icon}
                trailingIcon={action.trailingIcon}
                $fontSize={12}
                $height={24}
              >
                {action.children}
              </Button>
            ))}
            {customActions?.map((action, index) => (
              <React.Fragment key={index}>
                {action}
              </React.Fragment>
            ))}
            {!isPrintView && context}
          </Flex>
        </When>
      </Flex>
    </S.CandidatesPageHeader>
  )
}
