import { useMutation, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { activateUser } from 'src/libs/api/backend/users'
import type { OrgUser } from 'src/libs/api/backend/users'
import { isNil } from 'lodash'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'
import { useSetAtom } from 'jotai'

interface Args {
  userId: string
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  restoreUser: ({ userId, onSuccess, onError }: Args) => void
}

export const useActivateUser = (): Res => {
  const queryClient = useQueryClient()
  const notifySuccess = useSetAtom(notifySuccessAtom)
  const notifyError = useSetAtom(notifyErrorAtom)

  const mutation = useMutation({
    mutationFn: async ({ userId }: Args) => {
      await activateUser(userId)
    },
    onError: (err, variables) => {
      notifyError({
        message: `An error occurred when activating the user: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (_, variables) => {
      queryClient.setQueryData<OrgUser[]>([queryKeys.orgUsers], (oldOrgUsers) => {
        if (isNil(oldOrgUsers)) {
          return
        }

        return oldOrgUsers.map((user) => {
          if (user.id === variables.userId) {
            return {
              ...user,
              active: true
            }
          }
          return user
        }).sort((a, b) => a.active === b.active ? 0 : a.active ? -1 : 1)
      })

      notifySuccess({
        message: 'User restored.'
      })

      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })

  const restoreUser = (args: Args): void => {
    mutation.mutate(args)
  }

  return { restoreUser }
}
