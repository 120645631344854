// import { useAuthContext } from 'src/contexts/auth'
import { useSession as useSessionQuery } from 'src/hooks/queries/use-session'
import { useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import type { Session } from 'src/libs/api/backend/session'
import { useGlobalError } from './use-global-error'
import { GlobalErrorId } from 'src/contexts/global-error'
import { useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'
import { isNil } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import RouteBuilder from 'src/libs/route-builder'
import { useSetAtom } from 'jotai'
import { setChannelsAtom } from 'src/stores/websocket-channels'

interface SessionHookProps {
  enabled?: boolean
}

interface SessionHook {
  user?: Session['user']
  org?: Session['org']
  onboardingStatus?: Session['onboarding']
  emailAccountAccessTokens?: Session['emailAccountAccessTokens']
  orgLogoUrl: Session['logoUrl']
  status: string
  featureFlags: Session['featureFlags']
  subscribed: Session['subscribed']
  trialActive: Session['trialActive']
  impersonating: Session['impersonating']
  subscriptionIssue: Session['subscriptionIssue']
  refetchSession: () => void
}

export const useSession = (opts?: SessionHookProps): SessionHook => {
  const { data, status, refetch } = useSessionQuery(opts)
  const [orgUserKey, setOrgUserKey] = useState<string | null>(null)
  const { openGlobalError, closeGlobalError } = useGlobalError()
  const location = useLocation()
  const navigate = useNavigate()
  const channelsAtom = useSetAtom(setChannelsAtom)
  const {
    user,
    org,
    onboarding: onboardingStatus,
    emailAccountAccessTokens,
    logoUrl: orgLogoUrl,
    featureFlags,
    subscribed,
    trialActive,
    impersonating,
    subscriptionIssue
  } = data ?? {}

  useEffect(() => {
    if (org?.id) {
      channelsAtom(org.id)
    }
  }, [channelsAtom, org?.id])

  const accountWithoutAccessToken = useMemo(
    () => data?.emailAccountAccessTokens?.find((account) => !account.hasAccessToken && account.usedInJobIds.length > 0),
    [data?.emailAccountAccessTokens]
  )

  useEffect(() => {
    if (accountWithoutAccessToken) {
      openGlobalError(GlobalErrorId.MISSING_ACCESS_TOKEN, {
        disconnectedAccount: accountWithoutAccessToken
      })
    } else {
      closeGlobalError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountWithoutAccessToken])

  useEffect(() => {
    const expiredTrialRoute = RouteBuilder.build('EXPIRED_TRIAL')
    if (
      !isNil(org) &&
      !subscribed &&
      !trialActive &&
      !location.pathname.includes(expiredTrialRoute)
    ) {
      navigate(expiredTrialRoute)
    }
  }, [data, location.pathname, navigate, org, subscribed, trialActive])

  useEffect(() => {
    if (!isNil(data)) {
      Sentry.setUser({
        id: data.user?.id,
        email: data.emailAccountAccessTokens?.[0]?.email
      })
    } else {
      Sentry.setUser(null)
    }
  }, [data])

  // This allows us to detect if the session has changed and redirect to the root page
  // This is useful for when impersonating the user or logged out on another page
  useEffect(() => {
    let newOrgUserKey = null
    if (!isNil(data) && !isNil(data.org) && !isNil(data.user)) {
      newOrgUserKey = `${data.org.id}-${data.user.id}`
    }

    if (newOrgUserKey && orgUserKey && newOrgUserKey !== orgUserKey) {
      window.location.href = '/'
    } else {
      setOrgUserKey(newOrgUserKey)
    }
  }, [data])

  return {
    user,
    org,
    impersonating,
    onboardingStatus,
    emailAccountAccessTokens,
    orgLogoUrl,
    status,
    featureFlags: featureFlags ?? [],
    subscribed: subscribed ?? false,
    trialActive: trialActive ?? false,
    subscriptionIssue,
    refetchSession: () => { void refetch() }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSetSessionQueryCache = () => {
  const queryClient = useQueryClient()

  return (fn: (old: Session) => Session) => {
    queryClient.setQueryData([queryKeys.session], fn)
  }
}
