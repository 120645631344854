import { CandidatesPageHeader } from 'src/components/blocks/candidates-page-header'
import { CandidatesTablePagesContentInner } from './candidates.styled'
import { When } from 'src/components/blocks/when'
import { EmptyStateArchivedJob } from 'src/components/blocks/empty-state-archived-job'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { CandidatesShortlistedTable } from 'src/components/tables/candidates-shortlisted-table'
import { SEO } from 'src/components/primitives/seo'
import { useSetAtom } from 'jotai'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'

interface Props {
  heading?: string
}

const JobCandidatesShortlistedTablePage = ({ heading = 'Shortlisted' }: Props): JSX.Element => {
  const openDialog = useSetAtom(openDialogAtom)
  const { data: job } = useJobQuery()

  return (
    <>
      <SEO title="Shortlisted Candidates" />

      <CandidatesTablePagesContentInner>
        <CandidatesPageHeader
          heading={heading}
          actions={[
            {
              children: 'Add Candidate',
              icon: 'plus',
              // $variant: 'outline',
              $colorTheme: 'tint',
              onClick: () => {
                openDialog({ id: DialogId.ADD_CANDIDATE })
              }
            }
          ]}
        />
        <When condition={Boolean(job?.deleted)}>
          <EmptyStateArchivedJob />
        </When>
        <When condition={!job?.deleted}>
          <CandidatesShortlistedTable />
        </When>
      </CandidatesTablePagesContentInner>
    </>
  )
}

export default JobCandidatesShortlistedTablePage
