import { useQuery } from '@tanstack/react-query'
import { fetchJobs } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'

interface Opts {
  enabled?: boolean
}

export const jobsQuery = ({ enabled = true }: Opts = {}) => ({
  queryKey: [queryKeys.jobs],
  queryFn: fetchJobs,
  staleTime: 60 * 1000,
  enabled
})

export const useJobsQuery = (opts = {}) => {
  return useQuery(jobsQuery(opts))
}
