import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { searchCompanies } from 'src/libs/api/backend/company_preferences'
import type { CompanySuggestion } from 'src/libs/api/backend/company_preferences'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  query?: string | null
}

export const useCompanySuggestion = ({ query }: Args): UseQueryResult<CompanySuggestion[]> => {
  return useQuery({
    queryKey: [queryKeys.companySuggestions, query],
    queryFn: async () => await searchCompanies({ query }),
    staleTime: 60 * 1000,
    enabled: !!query
  })
}
