import { Fragment, useEffect } from 'react'
import { AddCandidateDialog } from 'src/components/dialogs/add-candidate-dialog'
import { CandidateDetailsDialog } from 'src/components/dialogs/candidate-details-dialog'
import { CreateCompanyDialog } from 'src/components/dialogs/create-company-dialog'
import { CreateNewPositionDialog } from 'src/components/dialogs/create-new-position-dialog'
import { CreateSequenceDialog } from 'src/components/dialogs/create-sequence-dialog'
import { EditCandidateDialog } from 'src/components/dialogs/edit-candidate-dialog'
import { SearchCandidatesDialog } from 'src/components/dialogs/search-candidates-dialog'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { AlertDialog } from 'src/components/dialogs/alert-dialog'
import { JobSettingsDialog } from 'src/components/dialogs/job-settings-dialog'
import { DefaultSettingsDialog } from 'src/components/dialogs/default-settings-dialog'
import { useQueryParams } from 'src/hooks/use-query-params'
import { MoveToAnotherJobDialog } from 'src/components/dialogs/move-to-another-job-dialog'
import { NotificationsDialog } from 'src/components/dialogs/notifications-dialog'
import { DisconnectedEmailAccountDialog } from 'src/components/dialogs/disconnected-email-account-dialog'
import type { EmailAccount } from 'src/libs/api/backend/users'
import { SendFeedbackDialog } from 'src/components/dialogs/send-feedback-dialog'
import type { FeedbackDialogPayload } from 'src/components/dialogs/send-feedback-dialog'
import { WriteCustomOutreachDialog } from 'src/components/dialogs/write-custom-outreach-dialog'
import { BillingSubscribeDialog } from 'src/components/dialogs/billing-subscribe-dialog'
import { CalendarDialog } from 'src/components/dialogs/calendar-dialog'
import { EditCompanyDialog } from 'src/components/dialogs/edit-company-dialog/edit-company-dialog'
import { ArchiveJobDialog } from 'src/components/dialogs/archive-job-dialog'
import { RefinementFeedbackDialog } from 'src/components/dialogs/refinement-feedback-dialog'
import { ExportToAtsDialog } from 'src/components/dialogs/export-to-ats-dialog/export-to-ats-dialog'
import type { Job } from 'src/libs/api/backend/jobs'
import { EnableAutoOutreachDialog } from 'src/components/dialogs/enable-auto-outreach-dialog/enable-auto-outreach-dialog'
import type { CalendarProps } from 'src/components/blocks/calendar'
import { ConnectATSDialog } from 'src/components/dialogs/connect-ats-dialog'
import { CreateNewJobDialog } from 'src/components/dialogs/create-new-job-dialog'
import { CreateProjectDialog } from 'src/components/dialogs/create-project-dialog'
import type { Project } from 'src/libs/api/backend/projects'
import { EditProjectDialog } from 'src/components/dialogs/edit-project-dialog'
import { useAtomValue, useSetAtom } from 'jotai'
import { DialogId, dialogsAtom, openDialogAtom } from 'src/stores/dialogs'
import type { AlertArgs } from 'src/stores/dialogs'
import { WriteMessageDialog } from './write-message-dialog'

export const Dialogs = (): JSX.Element => {
  const { getParam } = useQueryParams()
  const candidateSearchParams = getParam('candidate')
  const settingsSearchParams = getParam('settings')
  const subscribeSearchParams = getParam('subscribe')
  const newJobParams = getParam('createNewJob')

  const dialogs = useAtomValue(dialogsAtom)
  const openDialog = useSetAtom(openDialogAtom)

  // Open candidate dialog when `?candidate=value` is detected in url
  useEffect(() => {
    if (candidateSearchParams) {
      // Avoid looping
      openDialog({ id: DialogId.CANDIDATE_DETAILS, payload: candidateSearchParams })
    }
  }, [candidateSearchParams, openDialog])

  // Open settings dialog if `?settings=value` is detected in the url
  useEffect(() => {
    if (settingsSearchParams) {
      openDialog({ id: DialogId.DEFAULT_SETTINGS })
    }
  }, [openDialog, settingsSearchParams])

  // Open subscribe dialog if `?subscribe=value` is detected in the url
  useEffect(() => {
    if (subscribeSearchParams) {
      openDialog({ id: DialogId.BILLING_SUBSCRIBE })
    }
  }, [openDialog, subscribeSearchParams])

  useEffect(() => {
    if (newJobParams) {
      openDialog({ id: DialogId.CREATE_NEW_JOB })
    }
  }, [newJobParams, openDialog])

  return (
    <>
      {dialogs.map((dialog) => {
        let component = null
        switch (dialog.id) {
          case DialogId.ADD_CANDIDATE:
            component = <AddCandidateDialog />
            break
          case DialogId.EDIT_CANDIDATE: {
            const candidateJob = dialog.payload as CandidateJobExpanded
            component = <EditCandidateDialog candidateJobId={candidateJob.id} />
            break
          }
          case DialogId.CREATE_SEQUENCE:
            component = <CreateSequenceDialog />
            break
          case DialogId.CREATE_JOB_POSITION:
            component = <CreateNewPositionDialog />
            break
          case DialogId.CREATE_DEPARTMENT:
            component = <CreateCompanyDialog />
            break
          case DialogId.EDIT_DEPARTMENT:
            component = <EditCompanyDialog />
            break
          case DialogId.WRITE_MESSAGE: {
            const payloads = dialog.payload as CandidateJobExpanded[]
            component = <WriteMessageDialog candidateJobs={payloads} />
            break
          }
          case DialogId.CANDIDATE_DETAILS: {
            const candidateJobId = dialog.payload as string
            component = <CandidateDetailsDialog candidateJobId={candidateJobId} />
            break
          }
          case DialogId.SEARCH_CANDIDATES:
            component = <SearchCandidatesDialog />
            break
          case DialogId.NOTIFICATIONS: {
            const payload = dialog.payload as { navigationIsCollapsed: boolean }
            component = <NotificationsDialog navigationIsCollapsed={payload.navigationIsCollapsed ?? false} />
            break
          }
          case DialogId.ALERT: {
            const alertPayload = dialog.payload as AlertArgs
            component = <AlertDialog {...alertPayload} />
            break
          }
          case DialogId.JOB_SETTINGS:
            component = <JobSettingsDialog />
            break
          case DialogId.DEFAULT_SETTINGS:
            component = <DefaultSettingsDialog />
            break
          case DialogId.MOVE_TO_ANOTHER_JOB: {
            const { candidateJobs, requiredReason } = dialog.payload as {
              candidateJobs: CandidateJobExpanded[]
              requiredReason?: boolean
            }
            component = (
              <MoveToAnotherJobDialog
                candidateJobs={candidateJobs}
                requiredReason={requiredReason}
              />
            )
            break
          }
          case DialogId.EXPORT_CANDIDATES: {
            const { candidateJobs, job } = dialog.payload as {
              job: Job
              candidateJobs: CandidateJobExpanded[]
            }
            component = (
              <ExportToAtsDialog
                job={job}
                candidateJobs={candidateJobs}
              />
            )
            break
          }
          case DialogId.DISCONNECTED_EMAIL_ACCOUNT: {
            const payload = dialog.payload as EmailAccount
            component = <DisconnectedEmailAccountDialog sequenceSenderEmailAccount={payload} />
            break
          }
          case DialogId.SEND_FEEDBACK: {
            const payload = (dialog.payload ?? {}) as FeedbackDialogPayload
            component = <SendFeedbackDialog type={payload.type} defaultContent={payload.defaultContent} />
            break
          }
          case DialogId.WRITE_CUSTOM_OUTREACH: {
            const payload = dialog.payload as CandidateJobExpanded
            component = <WriteCustomOutreachDialog candidateJob={payload} />
            break
          }
          case DialogId.BILLING_SUBSCRIBE:
            component = <BillingSubscribeDialog />
            break
          case DialogId.CALENDAR: {
            const { candidateJob, scheduleNewEvent } = dialog.payload as {
              candidateJob: CandidateJobExpanded
              scheduleNewEvent: CalendarProps['scheduleNewEvent']
            }
            component = <CalendarDialog candidateJob={candidateJob} scheduleNewEvent={scheduleNewEvent} />
            break
          }
          case DialogId.ARCHIVE_JOB:
            component = <ArchiveJobDialog />
            break
          case DialogId.REFINEMENT_FEEDBACK:
            component = <RefinementFeedbackDialog />
            break
          case DialogId.ENABLE_AUTO_OUTREACH:
            component = <EnableAutoOutreachDialog />
            break
          case DialogId.CONNECT_ATS:
            component = <ConnectATSDialog />
            break
          case DialogId.CREATE_NEW_JOB:
            component = <CreateNewJobDialog />
            break
          case DialogId.CREATE_PROJECT: {
            const departmentId = dialog.payload as string
            component = <CreateProjectDialog departmentId={departmentId} />
            break
          }
          case DialogId.EDIT_PROJECT: {
            const project = dialog.payload as Project
            component = <EditProjectDialog project={project} />
            break
          }
          default:
            break
        }
        return <Fragment key={dialog.id}>{component}</Fragment>
      })}
    </>
  )
}
