import { useMutation, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { disableUser } from 'src/libs/api/backend/users'
import type { OrgUser } from 'src/libs/api/backend/users'
import { isNil } from 'lodash'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

export interface DeleteUserArgs {
  userId: string
  updatedAccountId?: string
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  deleteUser: ({ userId, updatedAccountId, onSuccess, onError }: DeleteUserArgs) => void
}

export const useDeleteUser = (): Res => {
  const queryClient = useQueryClient()

  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)

  const mutation = useMutation({
    mutationFn: async ({ userId, updatedAccountId }: DeleteUserArgs) => {
      await disableUser(userId, updatedAccountId)
    },
    onError: (err, variables) => {
      notifyError({
        message: `An error occurred when disabling the user: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (_, variables) => {
      if (variables.updatedAccountId) {
        // Account transfer happened, so just refetch the org users
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.orgUsers]
        })
      } else {
        queryClient.setQueryData<OrgUser[]>([queryKeys.orgUsers], (oldOrgUsers) => {
          if (isNil(oldOrgUsers)) {
            return
          }

          return oldOrgUsers.map((user) => {
            if (user.id === variables.userId) {
              return {
                ...user,
                active: false
              }
            }
            return user
          }).sort((a, b) => a.active === b.active ? 0 : a.active ? -1 : 1)
        })
      }

      notifySuccess({
        message: 'User disabled.'
      })

      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })

  const deleteUser = (args: DeleteUserArgs): void => {
    mutation.mutate(args)
  }

  return { deleteUser }
}
