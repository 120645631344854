import { Button } from 'src/components/primitives/button'
import type { ButtonActionProps } from 'src/components/primitives/button'
import * as S from './back-button.styled'

export interface BackButtonStyleProps {
  $offset?: string
}

export interface BackButtonProps extends BackButtonStyleProps {
  onClick?: ButtonActionProps['onClick']
  href?: ButtonActionProps['href']
  children?: string
}

export const BackButton = ({
  onClick,
  href,
  children = 'Back',
  $offset = '0px'
}: BackButtonProps): JSX.Element => {
  return (
    <S.BackButton $offset={$offset}>
      <Button
        onClick={onClick}
        href={href}
        leadingIcon="chevron-left"
        $variant="ghost"
        $colorTheme="muted"
        $height={24}
      >
        {children}
      </Button>
    </S.BackButton>
  )
}
