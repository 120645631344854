import { useEffect, useState } from 'react'
import { FormElement } from '../form-element'
import { FieldError } from '../field-error'
import type { RegisterField } from 'src/hooks/use-form'
import { Button } from 'src/components/primitives/button'
import { Icon } from 'src/components/primitives/icon'
import * as S from './image-upload.styled'
import { FieldLabel } from '../field-label'
import { isNil } from 'lodash'
import type { FieldCommonProps } from '../common'
import type { Spacing } from 'src/styles/theme/types'

export interface ImageUploadStyleProps {
  $previewSize?: Spacing
  $previewShape?: 'rounded' | 'circle'
}

interface ImageUploadProps extends Pick<FieldCommonProps, '$marginBottom'>, ImageUploadStyleProps {
  name: string
  label: string
  inputTitle?: string
  register: (name: string) => RegisterField
  value?: File | string | null
}

async function valueToString (value: File | string | null | undefined): Promise<string | null> {
  if (isNil(value)) return null
  if (typeof value === 'string') return value
  if (value instanceof File) {
    return await new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(value)
      reader.onloadend = () => {
        resolve(reader.result as string)
      }
    })
  }
  return null
}

export const ImageUpload = ({
  label,
  inputTitle = 'Select image',
  name,
  register,
  value,
  $marginBottom = 16,
  $previewSize = 64,
  $previewShape = 'circle'
}: ImageUploadProps): JSX.Element => {
  const [preview, setPreview] = useState<string | null>()
  const [error, setError] = useState<string | null>(null)
  const { onSelect } = register(name)

  useEffect(() => {
    // can't async/await inside of useEffect
    valueToString(value).then((result) => {
      setPreview(result)
    }).catch((error) => {
      console.error(error)
    })
  }, [value])

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const megabyte = Math.pow(1024, 2)
    const MAX_FILE_SIZE = 15 * megabyte

    const file = event?.target?.files?.[0]
    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      if (file.size < MAX_FILE_SIZE) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
          setError('')
          setPreview(reader.result as string)
          // TODO: Might change, depends on what exactly we're sending
          // to the API
          onSelect(file)
        }
      } else {
        setError('Files need to be smaller than 15MB.')
      }
    } else {
      setError('There was an error. Please try again.')
    }
  }

  const handleRemove = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
    setPreview(null)
    setError(null)
    const fileInput = document.getElementById('image-upload') as HTMLInputElement
    if (fileInput) {
      fileInput.value = ''
    }
    onSelect(null)
  }

  return (
    <FormElement $marginBottom={$marginBottom}>
      <FieldLabel label={label} />
      <S.ImageUpload>
        <S.Preview $previewSize={$previewSize} $previewShape={$previewShape}>
          {preview ? <img src={preview} alt="Preview of the uploaded image" /> : null}
        </S.Preview>
        <S.UploadArea>
          <S.Inputs>
            <input
              id="image-upload"
              type="file"
              accept="image/jpg, image/png, image/jpeg"
              hidden
              onChange={handleUpload}
            />
            <S.UploadHandle htmlFor="image-upload">
              <Icon name="upload-cloud" color="tintBg" size={16} />
              {inputTitle}
            </S.UploadHandle>
            {preview && (
              <Button
                aria-label="Remove image"
                onClick={handleRemove}
                trailingIcon="x-circle"
                $height={32}
                // $width={32}
                $variant="ghost"
                $colorTheme="muted"
              >
                Remove current
              </Button>
            )}
          </S.Inputs>
          {error ? <FieldError>{error}</FieldError> : null}
        </S.UploadArea>
      </S.ImageUpload>
    </FormElement>
  )
}
