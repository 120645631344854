import { useSearchParams } from 'react-router-dom'
import type { URLSearchParamsInit } from 'react-router-dom'

interface ReturnType {
  searchParams: URLSearchParamsInit
  getParam: (key: string) => string
  setParam: (key: string, value: string) => void
  resetParam: (key: string) => void
}

export const useQueryParams = (): ReturnType => {
  const [searchParams, setSearchParams] = useSearchParams()
  const newSearchParams = new URLSearchParams(searchParams)

  const getParam = (key: string): string => {
    return newSearchParams.get(key) ?? ''
  }

  const setParam = (key: string, value: string): void => {
    newSearchParams.set(key, value)
    setSearchParams(newSearchParams)
  }

  const resetParam = (key: string): void => {
    newSearchParams.delete(key)
    setSearchParams(newSearchParams)
  }

  return {
    searchParams,
    getParam,
    setParam,
    resetParam
  }
}
