import { useLoaderData } from 'react-router-dom'
import { CandidatesPageHeader } from 'src/components/blocks/candidates-page-header'
import { CandidatesInSequenceTable } from 'src/components/tables/candidates-in-sequence-table'
import { EmptyState } from 'src/components/blocks/empty-state'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { isNil } from 'lodash'
import { useUpsertJobSequence } from 'src/hooks/mutations/use-upsert-job-sequence'
import RouteBuilder from 'src/libs/route-builder'
import { Spacer } from 'src/components/primitives/spacer'
import { useEffect, useMemo, useRef, useState } from 'react'
import { When } from 'src/components/blocks/when'
import { pluralize } from 'src/libs/pluralize'
import { Button } from 'src/components/primitives/button'
import type { ButtonProps } from 'src/components/primitives/button'
import { useCandidateJobCountsQuery } from 'src/hooks/queries/use-candidate-job-counts'
import { EmptyStateArchivedJob } from 'src/components/blocks/empty-state-archived-job'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { Banner } from 'src/components/blocks/banner'
// import { useGlobalError } from 'src/hooks/use-global-error'
// import { GlobalErrorId } from 'src/contexts/global-error'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { SEO } from '../../../components/primitives/seo'
import { Icons } from 'src/components/primitives/icon'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import type { EmailAccount } from 'src/libs/api/backend/users'
import { useExportCandidatesToCsv } from 'src/hooks/use-export-candidates-to-csv'
import { Dropdown } from 'src/components/primitives/dropdown'
import { CandidatesInSequenceStats } from 'src/components/blocks/candidates-in-sequence-stats'
import { useSetAtom } from 'jotai'
import { openDialogAtom, DialogId } from 'src/stores/dialogs'
import { useJobSequenceState } from 'src/hooks/queries/use-job-sequence-state'
import type { InOutreachLoaderData } from 'src/libs/loaders/in-outreach'
import * as S from './candidates.styled'
import { IfElse } from 'src/components/blocks/if-else'
import { CandidateJobSequenceFilterDisplay } from 'src/libs/api/backend/candidate_jobs'
import type { IN_OUTREACH_STEPS } from 'src/libs/api/backend/candidate_jobs'

const makeSequencePageTitle = (step: IN_OUTREACH_STEPS): string => {
  return `Outreach · ${CandidateJobSequenceFilterDisplay[step]}`
}

interface JobCandidatesInSequencePageInnerProps {
  step: IN_OUTREACH_STEPS
  jobId: string
}

const JobCandidatesInSequencePageInner = ({ step, jobId }: JobCandidatesInSequencePageInnerProps): JSX.Element => {
  const openDialog = useSetAtom(openDialogAtom)
  const { data: job } = useJobQuery()
  const { data: emailSequence } = useJobSequenceQuery(jobId)
  const { data: candidateJobCounts } = useCandidateJobCountsQuery()
  const { data: jobSequenceState } = useJobSequenceState({ jobId })
  const { data: orgUsers } = useOrgUsersQuery()
  // const { globalError } = useGlobalError()
  const [pageHeaderHeight, setPageHeaderHeight] = useState<number>(158)
  const { exportCandidates } = useExportCandidatesToCsv()
  const { upsertJobSequence } = useUpsertJobSequence()

  const sequenceSenderEmailAccounts = useMemo(() => {
    if (orgUsers) {
      const emailAccounts = orgUsers.reduce<EmailAccount[]>((acc, user) => {
        if (user.emailAccounts) {
          return [...acc, ...user.emailAccounts]
        }
        return acc
      }, [])
      const sendingEmailAccountIds = emailSequence?.sequenceSteps?.map(step => step.sendingEmailAccountId)
      return emailAccounts.filter(account => sendingEmailAccountIds?.includes(account.id))
    }
    return []
  }, [orgUsers, emailSequence?.sequenceSteps])

  // TODO: We'll eventually want to support multiple email accounts in the dialog but this will have to do for now
  const deactivatedSendingEmailAccount = useMemo(() => sequenceSenderEmailAccounts.find(account => !account.hasAccessToken), [sequenceSenderEmailAccounts])

  const statsCardRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!isNil(statsCardRef.current?.offsetHeight)) {
      setPageHeaderHeight(statsCardRef.current?.offsetHeight ?? 158)
    }
  }, [statsCardRef.current?.offsetHeight])

  const title = useMemo(() => makeSequencePageTitle(step), [step])

  const pageHeaderActions = useMemo(() => {
    const isErrored = candidateJobCounts?.prospectingErrors && candidateJobCounts.prospectingErrors > 0
    const actions = isErrored
      ? [{
          children: `${pluralize(candidateJobCounts.prospectingErrors, 'candidate')} with errors`,
          icon: Icons.alertTriangle,
          $variant: 'outline',
          $colorTheme: 'negative',
          href: RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE_ERRORS', { jobId })
        }]
      : []
    return [
      ...actions,
      {
        children: `${emailSequence?.active ? 'Pause' : 'Resume'} Outreach`,
        icon: emailSequence?.active ? Icons.pauseCircle : Icons.playCircle,
        onClick: () => {
          const newState = !emailSequence?.active
          upsertJobSequence({
            jobId,
            active: newState,
            toastMessage: newState ? 'Enabled sending outreach emails' : 'Paused sending outreach emails'
          })
        }
      },
      {
        children: 'Edit Outreach',
        icon: Icons.settings2,
        href: RouteBuilder.build('SETTINGS_JOB_EMAIL_SEQUENCE', { jobId })
      },
      {
        children: 'Add Candidate',
        icon: Icons.plus,
        // $variant: 'outline',
        $colorTheme: 'tint',
        onClick: () => {
          openDialog({ id: DialogId.ADD_CANDIDATE })
        }
      }
    ] as ButtonProps[]
  }, [
    emailSequence?.active,
    jobId,
    openDialog,
    upsertJobSequence,
    candidateJobCounts?.prospectingErrors
  ])

  if (isSequenceStepsEmpty(emailSequence)) {
    return (
      <>
        <SEO title={title} />
        <S.CandidatesTablePagesContentInner>
          <CandidatesPageHeader heading={title} />
          <EmptyState
            $padding={{ top: 0, bottom: 24, left: 0, right: 0 }}
            heading="Outreach sequence"
            description="Compose multiple outreach emails that will be send to the candidates automatically, to confirm their interest with the position."
            svg="emailSequence"
            actions={[
              {
                children: 'Create outreach sequence',
                onClick: () => {
                  openDialog({ id: DialogId.CREATE_SEQUENCE, payload: 'OPTIONS' })
                }
              }
            ]}
          />
        </S.CandidatesTablePagesContentInner>
      </>
    )
  }

  return (
    <>
      <SEO title={title} />
      <S.CandidatesTablePagesContentInner>
        <div ref={statsCardRef}>
          <CandidatesPageHeader
            heading={title}
            actions={pageHeaderActions}
            context={
              <Dropdown
                trigger={
                  <Button
                    nested
                    leadingIcon="more-vertical"
                    $height={24}
                    $width={24}
                    $fontSize={12}
                    $variant="ghost"
                    $colorTheme="muted"
                />
              }
              items={
                [
                  {
                    id: 'exportToCsv',
                    title: 'Export candidates to CSV',
                    variant: 'neutral',
                    icon: 'file-down',
                    onSelect: () => { void exportCandidates() }
                  }
                ]
              }
              menuPosition="end"
              size="small"
            />
          }
          />
          <When condition={
            !!emailSequence &&
            emailSequence.active &&
            (!!jobSequenceState?.isHoliday || !jobSequenceState?.inEmailWhenToSend)
          }>
            <Banner
              icon="pause-circle"
              $variant="warning"
              actions={
                <Button
                  $variant="raised"
                  $colorTheme="tint"
                  $height={24}
                  $fontSize={12}
                  leadingIcon="settings-2"
                  href={RouteBuilder.build('SETTINGS_JOB_EMAIL_PREFERENCES', { jobId })}
                >
                  Email Preferences
                </Button>
              }
            >
              {jobSequenceState?.isHoliday
                ? 'Today is a holiday. Outreach emails are paused according to your email preferences.'
                : 'Outreach emails are paused according to your email schedule.'}
            </Banner>
            <Spacer $size={16} />
          </When>
          <When condition={
            !!emailSequence &&
            emailSequence.active &&
            !isNil(deactivatedSendingEmailAccount)
          }>
            <>
              <Banner
                $variant="negative"
                icon="alert-triangle"
                actions={
                  <Button
                    $variant="raised"
                    $colorTheme="tint"
                    $height={24}
                    $fontSize={12}
                    onClick={() => {
                      openDialog({ id: DialogId.DISCONNECTED_EMAIL_ACCOUNT, payload: deactivatedSendingEmailAccount })
                    }}
                  >
                    Review
                  </Button>
                }
              >
                <p>Outreach automatically paused by system. Unable to send emails using {deactivatedSendingEmailAccount?.email}.</p>
              </Banner>
              <Spacer $size={16} />
            </>
          </When>
          <When condition={!!emailSequence && !emailSequence.active}>
            <Banner
              icon="pause-circle"
              $variant="muted"
              actions={
                <Button
                  $variant="fill"
                  $colorTheme="tint"
                  $height={24}
                  $fontSize={12}
                  leadingIcon="play-circle"
                  onClick={() => {
                    const newState = !emailSequence?.active
                    upsertJobSequence({
                      jobId,
                      active: newState,
                      toastMessage: newState ? 'Enabled sending outreach emails' : 'Paused sending outreach emails'
                    })
                  }}
                >
                  Enable outreach
                </Button>
              }
            >
              Outreach is paused. Enable it to start sending emails.
            </Banner>
            <Spacer $size={16} />
          </When>
          <CandidatesInSequenceStats jobId={jobId} currentStep={step} />
        </div>
        <IfElse
          condition={!!job?.deleted}
          ifNode={<EmptyStateArchivedJob />}
          elseNode={
            <CandidatesInSequenceTable
              pageHeaderHeight={pageHeaderHeight}
              jobId={jobId}
              job={job}
              step={step}
            />
          }
        />
      </S.CandidatesTablePagesContentInner>
    </>
  )
}

const JobCandidatesInSequencePage = (): JSX.Element => {
  const {
    step,
    jobId
  } = useLoaderData() as InOutreachLoaderData
  return <JobCandidatesInSequencePageInner step={step} jobId={jobId} />
}

export default JobCandidatesInSequencePage
