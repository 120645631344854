import type { Spacing } from 'src/styles/theme/types'
import styled from 'styled-components'

export const Timeline = styled.div`
  padding-bottom: 1.5rem;
  width: 100%;
`

export const Activities = styled.div<{ $gap?: Spacing }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme, $gap }) => theme.spacing[$gap ?? 32]};
`
