import { queryOptions, useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { fetchMergeIntegrationList } from 'src/libs/api/backend/merge'
import type { MergeIntegration } from 'src/libs/api/backend/merge'

export const mergeIntegrationsQuery = () => queryOptions({
  queryKey: [queryKeys.mergeIntegrations],
  queryFn: async () => await fetchMergeIntegrationList()
})

export const useMergeIntegrationsListQuery = (): UseQueryResult<MergeIntegration[]> => {
  return useQuery(mergeIntegrationsQuery())
}
