import styled, { keyframes } from 'styled-components'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import type { TooltipStyleProps } from './tooltip'

const showFromBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const showFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-2px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

const showFromTop = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const showFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(2px) scale(0.96);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
`

export const Trigger = styled(RadixTooltip.Trigger)<{ $triggerDisabled: boolean }>`
  /* border: none; */
  /* outline: none; */
  ${({ $triggerDisabled }) => !$triggerDisabled && `
    cursor: pointer !important;
    * {
      cursor: pointer !important;
    }
  `}
  ${({ $triggerDisabled }) => $triggerDisabled && `
    cursor: not-allowed !important;
    outline: none !important;
    * {
      cursor: not-allowed !important;
    }
  `}
`

export const Content = styled(RadixTooltip.Content)<TooltipStyleProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  max-width: ${({ $maxWidth }) => $maxWidth ? `${$maxWidth}px` : 'none'};
  border-radius: 4px;
  background-color: ${({ theme, $variant }) =>
    theme.colors[$variant === 'dark' ? 'fgPrimary' : 'bgPrimary']};
  padding: 0.25rem 0.375rem;
  font-size: ${({ theme }) => theme.fontSizes[10]};
  font-weight: 500;
  line-height: ${({ theme }) => theme.lineHeights[1.33]};
  letter-spacing: ${({ theme }) => theme.letterSpacings[0.12]};
  text-align: ${({ $textAlign }) => $textAlign};
  color: ${({ theme, $variant }) => theme.colors[$variant === 'dark' ? 'bgPrimary' : 'fgPrimary']};
  box-shadow: ${({ $variant }) =>
    $variant === 'light'
      ? '0px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 1px 0px 0px rgba(0, 0, 0, 0.02)'
      : 'none'};
  z-index: ${({ theme }) => theme.zindeces.outerspace};
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  pointer-events: none;
  &[data-state='delayed-open'] {
    &[data-side='top'] {
      animation-name: ${showFromTop};
    }
    &[data-side='right'] {
      transform-origin: left center;
      animation-name: ${showFromRight};
    }
    &[data-side='bottom'] {
      animation-name: ${showFromBottom};
    }
    &[data-side='left'] {
      animation-name: ${showFromLeft};
    }
  }
`

export const Keys = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: 0 4px;
  border-radius: 2px;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  span {
    font-size: 10px;
    color: ${({ theme }) => theme.colors.fgPrimary};
    text-transform: uppercase;
  }
`
