import type { FontSize, LetterSpacing, LineHeight } from '../theme/types'

export const fontSizes: Record<FontSize, string> = {
  10: '0.625rem', // 10px
  12: '0.75rem', // 12px
  14: '0.875rem', // 14px
  16: '1rem', // 16px
  18: '1.125rem', // 18px
  20: '1.25rem', // 20x
  24: '1.5rem', // 24px
  32: '2rem'
}

export const fontWeights = {
  400: 400,
  500: 500
}

export const lineHeights: Record<LineHeight, number> = {
  1: 1,
  1.2: 1.2,
  1.33: 1.33,
  1.43: 1.43,
  1.5: 1.5
}

export const letterSpacings: Record<LetterSpacing, string> = {
  0: '0rem',
  0.12: '0.0075rem',
  0.2: '0.0125rem'
}
