import { useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useLoginRedirectPath } from 'src/hooks/use-login-redirect-path'
import { useQueryParams } from 'src/hooks/use-query-params'
import RouteBuilder from 'src/libs/route-builder'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'

const OpenSettingsDialogPage = ({ settings: pageSettings }: { settings?: string }): null => {
  const navigate = useNavigate()
  const { jobId } = useLoginRedirectPath()
  const { settings } = useParams()
  const openDialog = useSetAtom(openDialogAtom)
  const { setParam } = useQueryParams()

  useEffect(() => {
    if (pageSettings && jobId) {
      navigate(RouteBuilder.build('SETTINGS_JOB_DETAILS', { jobId }))
      setParam('settings', pageSettings)
      openDialog({ id: DialogId.DEFAULT_SETTINGS })
    }
    if (settings && jobId) {
      navigate(RouteBuilder.build('SETTINGS_JOB_DETAILS', { jobId }))
      setParam('settings', settings)
      openDialog({ id: DialogId.DEFAULT_SETTINGS })
    }
  }, [jobId, navigate, openDialog, pageSettings, setParam, settings])

  return null
}

export default OpenSettingsDialogPage
