import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { fetchJobsGroupedByDepartment } from 'src/libs/api/backend/jobs'
import type { JobGroupedByDepartment } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'

export const useJobByDepartmentsQuery = (): UseQueryResult<JobGroupedByDepartment> => {
  return useQuery({
    queryKey: [queryKeys.jobByDepartments],
    queryFn: async () => await fetchJobsGroupedByDepartment(),
    staleTime: 60 * 1000
  })
}
