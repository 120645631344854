import { z } from 'zod'
import Api, { ApiError } from '..'

export enum NotificationType {
  EMAIL_ADDED_TO_SEQUENCE = 'EMAIL_ADDED_TO_SEQUENCE',
  USER_JOINED = 'USER_JOINED',
  USER_JOIN_REQUEST = 'USER_JOIN_REQUEST',
  CANDIDATE_NOTE = 'CANDIDATE_NOTE',
  NEW_FEATURES = 'NEW_FEATURES',
  MARKETING = 'MARKETING'
}

const notificationViewParser = z.object({
  id: z.string(),
  seenAt: z.coerce.date()
})

export type NotificationView = z.infer<typeof notificationViewParser>

const notificationSenderParser = z.object({
  userId: z.string(),
  name: z.string(),
  email: z.string(),
  active: z.boolean(),
  profilePhoto: z.string().nullish()
})

export type NotificationSender = z.infer<typeof notificationSenderParser>

const userNotificationParser = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  message: z.string(),
  type: z.nativeEnum(NotificationType),
  sendingUser: notificationSenderParser.nullish(),
  orgJoinRequest: z.object({
    id: z.string().uuid(),
    user: z.object({
      email: z.string(),
      name: z.string().nullable(),
      profilePhoto: z.string().nullable()
    })
  }).nullish(),
  sequence: z.object({
    jobId: z.string().uuid(),
    jobTitle: z.string(),
    departmentName: z.string().nullish()
  }).nullish(),
  notificationViews: z.array(notificationViewParser),
  candidateNote: z.object({
    id: z.string().uuid(),
    body: z.string(),
    candidate: z.object({
      name: z.string(),
      profilePhoto: z.string().nullish(),
      candidateJobId: z.string().uuid()
    })
  }).nullish()
})

export type UserNotification = z.infer<typeof userNotificationParser>

export async function fetchUserNotifications (): Promise<UserNotification[]> {
  const { data, status } = await Api.get('/notifications')
  if (status !== 200) {
    throw new ApiError('Failed to get notifications')
  }
  return z.array(userNotificationParser).parse(data)
}

const userNotificationCountParser = z.object({
  count: z.number()
})

export type UserNotificationCount = z.infer<typeof userNotificationCountParser>

export async function fetchUserNotificationsCount (): Promise<UserNotificationCount> {
  const response = await Api.get('/notifications/unseen_count')
  return userNotificationCountParser.parse(response.data)
}

export async function markNotificationsSeenApi (notificationIds: string[]): Promise<void> {
  await Api.post('/notifications/seen', null, { notificationIds })
}

export async function markNotificationsHidden (notificationIds: string[]): Promise<void> {
  await Api.post('/notifications/hide', null, { notificationIds })
}

const userNotificationPreferencesParser = z.record(z.nativeEnum(NotificationType), z.boolean())

export type UserNotificationPreferences = z.infer<typeof userNotificationPreferencesParser>

export async function fetchUserNotificationPreferences (): Promise<UserNotificationPreferences> {
  const response = await Api.get('/notifications/preferences')
  return userNotificationPreferencesParser.parse(response.data)
}

export async function updateUserNotificationPreference (notificationType: NotificationType, enabled: boolean): Promise<UserNotificationPreferences> {
  const response = await Api.post('/notifications/preferences', null, { notificationType, enabled })
  return userNotificationPreferencesParser.parse(response.data)
}

export async function unsubscribeUserFromAllNotifications (): Promise<void> {
  await Api.post('/notifications/unsubscribe')
}
