import { createContext } from 'react'
import type { CompanyPreference } from 'src/libs/api/backend/company_preferences'

export interface CompaniesPreferencesContextProps {
  companyPreferencesByLinkedin: Record<string, CompanyPreference>
}

export const CompaniesPreferencesContext = createContext<CompaniesPreferencesContextProps>({
  companyPreferencesByLinkedin: {}
})
