import { redirect } from 'react-router-dom'
import { jobsQuery } from 'src/hooks/queries/use-jobs'
import queryClient from 'src/hooks/query-client'
import RouteBuilder from '../route-builder'

export const emailPreferencesLoader = async (): Promise<Response | null> => {
  const jobs = await queryClient.ensureQueryData(jobsQuery())
  const firstUnArchivedJob = jobs.find((job) => !job.deleted)
  if (firstUnArchivedJob) {
    // redirect to automation page for the first unarchived job
    return redirect(RouteBuilder.build('SETTINGS_JOB_EMAIL_PREFERENCES', { jobId: firstUnArchivedJob.id }))
  }
  return null
}
