import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { CHANNEL_TYPE, getChannelName } from 'src/libs/api/backend/websockets'

export const notificationsChannelAtom = atomWithStorage('notificationsChannel', getChannelName(CHANNEL_TYPE.NOTIFICATIONS))

export const candidateSearchChannelAtom = atomWithStorage('candidateSearchChannel', getChannelName(CHANNEL_TYPE.CANDIDATE_SEARCH))

export const candidateDetailsChannelAtom = atomWithStorage('candidateDetailsChannel', getChannelName(CHANNEL_TYPE.CANDIDATE_DETAILS))

export const atsChannelAtom = atomWithStorage('atsChannel', getChannelName(CHANNEL_TYPE.ATS))

export const setChannelsAtom = atom(null, (_, set, orgId: string) => {
  set(notificationsChannelAtom, getChannelName(CHANNEL_TYPE.NOTIFICATIONS, [orgId]))
  set(candidateSearchChannelAtom, getChannelName(CHANNEL_TYPE.CANDIDATE_SEARCH, [orgId]))
  set(candidateDetailsChannelAtom, getChannelName(CHANNEL_TYPE.CANDIDATE_DETAILS, [orgId]))
  set(atsChannelAtom, getChannelName(CHANNEL_TYPE.ATS, [orgId]))
})
