import { queryOptions, useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { queryKeys } from 'src/libs/query-keys'
import type { JobSequenceState } from 'src/libs/api/backend/jobs'
import { isNil } from 'lodash'
import { fetchJobSequenceState } from 'src/libs/api/backend/jobs'

interface UseJobSequenceStateOptions {
  jobId?: string
  enabled?: boolean
}

export const jobSequenceStateQuery = (jobId?: string) => queryOptions({
  queryKey: [queryKeys.jobSequenceState, jobId],
  queryFn: async () => await fetchJobSequenceState(jobId ?? ''),
  staleTime: 60 * 1000,
  enabled: !isNil(jobId)
})

export const useJobSequenceState = ({
  jobId: providedJobId,
  enabled = true
}: UseJobSequenceStateOptions): UseQueryResult<JobSequenceState> => {
  const { jobId } = useParams()
  return useQuery({
    ...jobSequenceStateQuery(providedJobId ?? jobId),
    enabled: enabled && !isNil(providedJobId ?? jobId)
  })
}
