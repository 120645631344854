import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing[12]};
  padding: ${({ theme }) => theme.spacing[16]};
  border-top: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
`

export const ActionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
  padding-right: ${({ theme }) => theme.spacing[4]};
  width: 100%;
`
