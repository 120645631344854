import styled from 'styled-components'

export const ErrorNotice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[16]};
  justify-content: space-between;
  width: 100%;
  height: 62px;
  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[24]}`};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  h2 {
    font-size: ${({ theme }) => theme.fontSizes[14]};
    font-weight: ${({ theme }) => theme.fontWeights[500]};
    color: ${({ theme }) => theme.colors.fgPrimary};
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: ${({ theme }) => theme.fontWeights[400]};
    color: ${({ theme }) => theme.colors.negativeBg};
  }
`
