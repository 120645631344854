import { Icon, Icons } from 'src/components/primitives/icon'
import * as S from './empty.styled'
import { Caption } from 'src/components/primitives/typography'

export const EmptyNotification = (): JSX.Element => {
  return (
    <S.EmptyNotificationWrapper>
      <S.NotificationContent>
        <Icon name={Icons.bell} size={40} color='fgTranslucent10' />
        <Caption size='MD' $color='fgSecondary'>
          No notifications
        </Caption>
      </S.NotificationContent>
    </S.EmptyNotificationWrapper>
  )
}
