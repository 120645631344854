import { LinebreaksSerializer } from './linebreaks'

export interface EditorSerializer {
  serialize: (html: string) => string
  deserialize: (html: string) => string
}

const EditorSerializers: EditorSerializer[] = [
  new LinebreaksSerializer()
]

export function serializeEditorContent (html: string): string {
  let serializedContent = html
  for (const serializer of EditorSerializers) {
    serializedContent = serializer.serialize(serializedContent)
  }
  return serializedContent
}

export function deserializeEditorContent (html: string): string {
  let deserializedContent = html
  for (const serializer of EditorSerializers) {
    deserializedContent = serializer.deserialize(deserializedContent)
  }
  return deserializedContent
}
