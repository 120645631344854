import { Outlet } from 'react-router-dom'
import { InboxSidebar } from 'src/components/blocks/inbox-sidebar'
import * as PageWithSidebar from 'src/components/layouts/page-with-sidebar'
import { useInboxSidebar } from 'src/hooks/use-inbox-sidebar'

export const InboxLayout = (): JSX.Element => {
  const { sidebarLooksExpanded, isPinned, toggleSidebarPinned, setIsHovered, isHovered } =
    useInboxSidebar()

  return (
    <PageWithSidebar.Wrapper>
      <PageWithSidebar.Sidebar isExpanded={sidebarLooksExpanded}>
        <InboxSidebar
          isExpanded={sidebarLooksExpanded}
          isPinned={isPinned}
          toggleSidebarPinned={toggleSidebarPinned}
          setIsHovered={setIsHovered}
          isHovered={isHovered}
        />
      </PageWithSidebar.Sidebar>
      <PageWithSidebar.Content isExpanded={isPinned}>
        <Outlet />
      </PageWithSidebar.Content>
    </PageWithSidebar.Wrapper>
  )
}
