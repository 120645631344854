import { PinExtensionChromeStoreUrl } from '../../../constants'
import { Button, Flex } from '../../primitives'
import { BrandIcon } from '../../primitives/brand-icon'
import { Icons } from '../../primitives/icon'
import { Paragraph } from '../../primitives/typography'
import { Banner } from '../banner'
import { usePinExtensionVersion } from 'src/hooks/use-pin-extension'
import { useIsChrome } from '../../../hooks/use-is-browser'

export const DownloadChromeExtensionBanner: React.FC = () => {
  const extensionVersion = usePinExtensionVersion()
  const isChrome = useIsChrome()

  if (extensionVersion != null || !isChrome) {
    return null
  }

  return (
    <Banner $variant='tint'>
      <Flex $align='center' $justify='space-between'>
        <Flex $align='center'>
          <BrandIcon name='chrome' size={20} color='original' />

          <span style={{ marginLeft: '0.5rem' }}>
            <Paragraph size='XS'>Add LinkedIn candidates with one click</Paragraph>
          </span>
        </Flex>

        <Flex $flex="0">
          <Button
            $variant='flat'
            $colorTheme='tint'
            $fontSize={12}
            href={PinExtensionChromeStoreUrl}
            $width='auto'
            $height={24}
            trailingIcon={Icons.externalLink}
          >
            Install Chrome Extension
          </Button>
        </Flex>
      </Flex>
    </Banner>
  )
}
