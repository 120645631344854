import { useMutation } from '@tanstack/react-query'
import { sendManualEmail as sendManualEmailApi } from 'src/libs/api/backend/sequences'
import type { SendManualEmail } from 'src/libs/api/backend/sequences'
import { pluralize } from 'src/libs/pluralize'
import { closeDialogAtom, DialogId } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'
import { Icons } from 'src/components/primitives/icon'

interface Args {
  manualEmail: SendManualEmail
  onSuccess?: () => void
}

interface Res {
  sendManualEmail: ({ manualEmail, onSuccess }: Args) => void
}

export const useSendManualEmail = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)
  const closeDialog = useSetAtom(closeDialogAtom)

  const mutation = useMutation({
    mutationFn: async ({ manualEmail }: Args) => {
      return await sendManualEmailApi({ manualEmail })
    },
    onError: (err) => {
      notifyError({
        message: `An error occurred when sending email(s): ${err.message}`
      })
    },
    onSuccess: async (data, variables) => {
      if (variables.onSuccess) {
        variables.onSuccess()
      } else {
        closeDialog(DialogId.WRITE_MESSAGE)
        notifySuccess({
          icon: Icons.mails,
          message: `Successfully sent ${pluralize(data.length, 'email')}`
        })
      }
    }
  })

  const sendManualEmail = ({ manualEmail, onSuccess }: Args): void => {
    mutation.mutate({ manualEmail, onSuccess })
  }

  return { sendManualEmail }
}
