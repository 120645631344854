import * as Dialog from 'src/components/primitives/dialog'
import { AtsSettings } from 'src/components/blocks/ats-settings'
import * as S from './connect-ats-dialog.styled'
import { controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'
import { useMemo } from 'react'

export const ConnectATSDialog = (): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.CONNECT_ATS), []))
  const controlDialog = useSetAtom(controlDialogAtom)

  return (
    <Dialog.Root
      id={DialogId.CONNECT_ATS}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.CONNECT_ATS, newState: value })
      }}
      $width="640px"
      $maxWidth="640px"
      $height="large"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Connect your ATS"
          onClose={() => {
            controlDialog({ id: DialogId.CONNECT_ATS, newState: false })
          }}
        />
        <Dialog.Content>
          <S.Inner>
            <AtsSettings integrationsColumnsCount={2} />
          </S.Inner>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
