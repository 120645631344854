import { Text } from 'src/components/primitives/text'
import { Box } from 'src/components/primitives/box'

interface LoginErrorProps {
  error: string
}

const LoginError = ({
  error
}: LoginErrorProps): JSX.Element => {
  return (
    <Box
      $background="negativeTranslucent5"
      $padding={{ top: 12, right: 16, bottom: 12, left: 16 }}
      $borderRadius="6px"
      $width="100%"
    >
      <Text $size={14} $lineHeight={1.43} $weight={400} $color="negativeFg">
        {error}
      </Text>
    </Box>
  )
}

export default LoginError
