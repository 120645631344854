import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useLocation, useParams } from 'react-router-dom'
import { queryKeys } from 'src/libs/query-keys'
import { candidateJobStageDisplay, createCandidatesByLinkedInUrl, fetchCandidateJobs } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded, CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import { useSetAtom } from 'jotai'
import { notifyAtom, notifyErrorAtom } from 'src/stores/notifications'
import { Icons } from 'src/components/primitives/icon'

interface Args {
  linkedins: string[]
  stage: CandidateJobStage
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  createCandidatesByLinkedIn: ({ linkedins, stage, onSuccess, onError }: Args) => void
}

export const useCreateCandidateByLinkedIn = (): Res => {
  const queryClient = useQueryClient()
  const notifyError = useSetAtom(notifyErrorAtom)
  const notify = useSetAtom(notifyAtom)
  const { jobId } = useParams()
  const location = useLocation()

  const favorite = location.pathname.includes('/shortlisted')

  const mutation = useMutation({
    mutationFn: async ({ linkedins, stage }: Args) => await createCandidatesByLinkedInUrl(linkedins, stage, jobId, favorite),
    onError: (err, variables) => {
      console.error(err)
      notifyError({
        message: `An error occurred while creating your candidate: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs, jobId]
      })

      let candidateAlreadyExists = false

      // This is a bit hacky, but for now it should do it
      // We might wanna check on the backend though in the future
      // Also, it does not use a cached query on purpose, as we do not necessarily know all the query keys here.
      if (variables.linkedins.length === 1) {
        const currentCandidates = await fetchCandidateJobs(jobId ?? '')
        const linkedInUrl = variables.linkedins[0]
        candidateAlreadyExists = currentCandidates.some(
          (candidateJob: CandidateJobExpanded) => {
            return `https://www.linkedin.com${candidateJob.candidate.linkedin}` === linkedInUrl
          }
        )
      }

      notify({
        type: 'toast',
        variant: candidateAlreadyExists ? 'tint' : 'positive',
        position: 'bottom-right',
        icon: candidateAlreadyExists ? Icons.info : Icons.checkCheck,
        message: candidateAlreadyExists
          ? 'Candidate already exists in this stage'
          : `${variables.linkedins?.length === 1 ? 'Candidate' : 'Candidates'} added to ${candidateJobStageDisplay[variables.stage]}`
      })
      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })

  const createCandidatesByLinkedIn = ({ linkedins, stage, onSuccess, onError }: Args): void => {
    mutation.mutate({ linkedins, stage, onSuccess, onError })
  }

  return { createCandidatesByLinkedIn }
}
