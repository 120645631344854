import { Spacer } from 'src/components/primitives/spacer'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { When } from '../when'
import { isNil } from 'lodash'

interface OnboardingHeaderProps {
  heading: string
  subheading?: string
}

export const OnboardingHeader = ({ heading, subheading }: OnboardingHeaderProps): JSX.Element => {
  return (
    <>
      <Caption size="LG">{heading}</Caption>
      <When condition={!isNil(subheading)}>
        <>
          <Spacer $size={4} />
          <Paragraph>{subheading}</Paragraph>
        </>
      </When>
      <Spacer $size={40} />
    </>
  )
}
