import styled from 'styled-components'
import type { HTMLAttributes, ButtonHTMLAttributes } from 'react'
import type { AvatarStyleProps } from './avatar'
import type { Color } from 'src/styles/theme/types'

type AvatarProps = HTMLAttributes<HTMLDivElement> & ButtonHTMLAttributes<HTMLButtonElement> & AvatarStyleProps

const getBackgroundColor = ($type: AvatarStyleProps['$type']): Color | 'transparent' => {
  switch ($type) {
    case 'photo':
    case 'logo': {
      return 'transparent'
    }
    case 'icon': {
      return 'fgSecondary'
    }
    case 'initials': {
      return 'fgPrimary'
    }
    default: {
      return 'fgPrimary'
    }
  }
}

const getBorderColor = ($type: AvatarStyleProps['$type']): Color | 'transparent' => {
  switch ($type) {
    case 'logo': {
      return 'borderTranslucent'
    }
    case 'photo': {
      return 'transparent'
    }
    default: {
      return 'fgPrimary'
    }
  }
}

export const AvatarWrapper = styled.div`
  position: relative;
`

export const Avatar = styled.div.attrs<AvatarProps>(() => ({}))<AvatarProps>`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: ${({ $size, theme }) => theme.spacing[$size ?? 36]};
  height: ${({ $size, theme }) => theme.spacing[$size ?? 36]};
  background-color: ${({ theme, $type }) => {
    const color = getBackgroundColor($type)
    return color === 'transparent' ? color : theme.colors[color]
  }};
  border: ${({ $border }) => $border && 'solid 1px'};
  border-color: ${({ theme, $type }) => {
    const borderColor = getBorderColor($type)
    return borderColor === 'transparent' ? borderColor : theme.colors[borderColor]
  }};
  border-radius: ${({ $shape, $size }) => {
    switch ($shape) {
      case 'sharp': {
        return 0
      }
      case 'soft': {
        return '4px'
      }
      case 'rounded': {
        if ($size && $size >= 36) {
          return '16px'
        } else {
          return '6px'
        }
      }
      case 'circle': {
        return '999px'
      }
      default: {
        return '4px'
      }
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.bgPrimary};
  font-size: ${({ $size }) => {
    if (!$size) {
      return '10px'
    }

    if ($size > 36) {
      return '24px'
    } else if ($size > 24) {
      return '16px'
    } else if ($size > 12) {
      return '10px'
    } else {
      return '7px'
    }
  }};
  overflow: ${({ $overflow }) => $overflow ?? 'hidden'};
  user-select: none;
  ${({ $stacked, $size, theme }) =>
    $stacked &&
    `
    span {
      transform: translateY(-${$size && $size <= 12 ? '0.5px' : '1px'});
    }
    &:after {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: calc(100% + 0px);
    height: calc(100% + 0px);
    border: solid 3px;
    border-radius: 999px;
    border-color: ${theme.colors.bgPrimary};
    clip-path: inset(20% 50% 20% 0);
    z-index: 2;
  }
  `}
`

export const Status = styled.div<Pick<AvatarStyleProps, '$status'>>`
  position: absolute;
  top: -5%;
  left: -5%;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 99px;
  background-color: ${({ theme, $status }) => $status !== undefined && theme.colors[$status]};
  pointer-events: none;
  z-index: 2;
`

export const Logo = styled.div<Pick<AvatarStyleProps, '$innerPadding'>>`
  width: ${({ theme, $innerPadding }) => `calc(100% - ${theme.spacing[$innerPadding ?? '6']}px)`};
  height: ${({ theme, $innerPadding }) => `calc(100% - ${theme.spacing[$innerPadding ?? '6']}px)`};
  border-radius: 4px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
`

export const ProfilePhoto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Initials = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.fgPrimary};
`

export const ProfilePhotoAndLogo = styled.div`
  ${Logo} {
    position: absolute;
    // border: 2px solid ${({ theme }) => theme.colors.bgPrimary};
    // background-color: ${({ theme }) => theme.colors.bgPrimary};
    border-radius: 4px;
    bottom: -5%;
    right: -5%;
    width: 50%;
    height: 50%;
    z-index: 1;
  }
`

export const AvatarIcon = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`
