import type { Color } from 'src/styles/theme/types'
import styled from 'styled-components'

export const Card = styled.div`
  padding: 2.5rem;
  border-radius: 0.75rem;
  width: 400px;
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`

export const Icon = styled.div<{ $bgColor: Color }>`
  width: 3rem;
  height: 3rem;
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
`
