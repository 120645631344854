import { useMemo } from 'react'
import { useOrgUsersQuery } from './use-org-users'
import { useJobSequenceQuery } from './use-job-sequence'
import type { EmailAccount } from 'src/libs/api/backend/users'
import { useSession } from './use-session'

// User may send from:
// 1. Their own email accounts
// 2. Email accounts used in the job sequence
// 3. Email accounts with send as permission

export const useSendableAddresses = (): EmailAccount[] => {
  const { data: orgUsers } = useOrgUsersQuery()
  const { data: jobSequence } = useJobSequenceQuery()
  const { data: session } = useSession()

  const sendableAddresses = useMemo(() => {
    if (!orgUsers || !jobSequence || !session) {
      return []
    }

    const allAddressesByAddressId = orgUsers
      .filter((orgUser) => orgUser.active)
      .flatMap((orgUser) => orgUser.emailAccounts)
      .reduce<Record<string, EmailAccount>>((acc, emailAccount) => {
      acc[emailAccount.id] = emailAccount
      return acc
    }, {})

    const currentUserAccts = session
      .emailAccountAccessTokens
      .map((emailAccount) => emailAccount.emailAccountId)

    const acctsUsedInSequence = jobSequence
      .sequenceSteps
      ?.map((step) => step.sendingEmailAccountId) ?? []

    const acctsWithSendAsPermission = orgUsers
      .filter((orgUser) => orgUser.active)
      .flatMap((orgUser) => orgUser.emailAccounts)
      .filter((emailAccount) => emailAccount.currentUserHasSendAsPermission)
      .map((emailAccount) => emailAccount.id)

    const sendableAddresses = Array.from(new Set([
      ...currentUserAccts,
      ...acctsUsedInSequence,
      ...acctsWithSendAsPermission
    ])).map((emailAccountId) => allAddressesByAddressId[emailAccountId])

    return sendableAddresses
  }, [orgUsers, jobSequence, session])

  return sendableAddresses
}
