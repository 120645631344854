import styled, { keyframes } from 'styled-components'

const sadPinBody = keyframes`
10% {
  transform: scale(1.04, 0.94);
}
44% {
  transform: scale(1, 1);
}
`

const pinBodyShadow = keyframes`
10% {
  transform: scaleX(1.2);
}
44% {
  transform: scaleX(1);
}
`

const sadPinEye = keyframes`
0% {
  transform: scaleY(1);
}
20% {
  transform: scaleY(0.5);
}
30% {
  transform: scaleY(1);
}
`

export const SadPinAnimated = styled.div`
  svg {
    height: 53px;
    ellipse {
      &[data-name='SadPinBodyShadow'] {
        transform-origin: bottom center;
        animation: 2s ${pinBodyShadow} infinite linear;
      }
    }
    path {
      &[data-name='SadPinBody'] {
        transform-origin: bottom center;
        animation: 2s ${sadPinBody} infinite linear;
      }
      &[data-name='SadPinLeftEye'] {
        transform-origin: center;
        animation: 2s ${sadPinEye} infinite linear;
      }
      &[data-name='SadPinRightEye'] {
        transform-origin: center;
        animation: 2s ${sadPinEye} infinite linear;
      }
    }
  }
`

const happyPinBody = keyframes`
  10% {
    transform: scale(1.2,0.8);
  }
  24%, 26% {
    transform: translateY(-6px) scale(1,1.001);
  }
  40% {
    transform: translateY(0) scale(1,0.999);
  }
  44% {
    transform: translateY(0) scale(1.05,0.97);
  }
  56% {
    transform: translateY(-3px) scale(1.01,1);
  }
  70% {
    transform: translateY(0) scale(1,1);
  }
`

const happyPinShadow = keyframes`
30% {
  transform: scaleX(1.6);
}
50% {
  transform: scaleX(1);
}
`

const happyPinEye = keyframes`
0% {
  transform: scaleY(1);
}
20% {
  transform: scaleY(0.5);
}
30% {
  transform: scaleY(1);
}
`

export const HappyPinAnimated = styled.div`
  svg {
    height: 53px;
    ellipse {
      &[data-name='HappyPinShadow'] {
        transform-origin: bottom center;
        animation: 1.2s ${happyPinShadow} infinite linear;
      }
    }
    path {
      &[data-name='HappyPinBody'] {
        transform-origin: bottom center;
        animation: 1.2s ${happyPinBody} infinite linear;
      }
      &[data-name='HappyPinLeftEye'] {
        transform-origin: center;
        animation: 1.2s ${happyPinEye} infinite linear;
      }
      &[data-name='HappyPinRightEye'] {
        transform-origin: center;
        animation: 1.2s ${happyPinEye} infinite linear;
      }
    }
    rect {
      &[data-name='HappyPinBody'] {
        transform-origin: bottom center;
        animation: 1.2s ${happyPinBody} infinite linear;
      }
    }
  }
`

const searchingPinInner = keyframes`
0%, 15% {
transform: scale(1, 1) translate(0,0);
}
20%, 35% {
transform: scaleX(0.99) translate(-2px, 1px);
}
50%, 65% {
transform: scale(1, 1) translate(0,1px);
}
70%, 85% {
transform: scaleX(0.99) translate(2px, 1px);
}
100% {
transform: scale(1, 1) translate(0, 0);
}
`

const searchingPinEye = keyframes`
0%, 15% {
transform: scale(1, 1) translate(0,0);
}
20%, 35% {
transform: scaleY(1) translate(-2px, 1px);
}
50%, 65% {
transform: scale(1, 1) translate(0,0);
}
70%, 85% {
transform: scaleY(0.65) translate(2px, 1px);
}
100% {
transform: scale(1, 1) translate(0, 0);
}
`

export const SearchingPinAnimated = styled.div`
  svg {
    height: 53px;
    path {
      &[data-name='SearchingPinInner'] {
        animation: 3s ${searchingPinInner} infinite linear;
      }
      &[data-name="SearchingPinEye"] {
        transform-origin: center center;
        animation: 3s ${searchingPinEye} infinite linear;
        }
    }
  }
`
