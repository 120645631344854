import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import * as S from './subscription-status.styled'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import type { Color } from 'src/styles/theme/types'
import { Button } from 'src/components/primitives/button'
import { Flex } from 'src/components/primitives/flex'
import { SEO } from 'src/components/primitives/seo'
import { trackEvent } from 'src/libs/track'
import { TrackingEventType } from 'src/types/track'

type Variant = 'success' | 'cancel'

interface SubscriptionStatusProps {
  variant: Variant
}

interface GetContentReturnType {
  iconBg: Color
  iconColor: Color
  icon: IconName
  heading: string
  subheading: string
  href: string
}

export const SubscriptionStatus = ({ variant }: SubscriptionStatusProps): JSX.Element => {
  const getContent = (): GetContentReturnType => {
    if (variant === 'cancel') {
      return {
        iconBg: 'negativeFaded',
        iconColor: 'negativeBg',
        icon: 'x',
        heading: 'Cancelled!',
        subheading: 'The billing process was cancelled.',
        href: '/'
      }
    } else {
      trackEvent(TrackingEventType.SUBSCRIBED)
      return {
        iconBg: 'positiveFaded',
        iconColor: 'positiveBg',
        icon: 'check',
        heading: 'Subscribed!',
        subheading: 'You have successfully subscribed.',
        href: '/'
      }
    }
  }

  const { iconBg, iconColor, icon, heading, subheading, href } = getContent()

  return (
    <>
      <SEO title={variant === 'success' ? 'Subscribed' : 'Cancelled'} />
      <S.Card>
        <Flex $direction="column" $gap={24}>
          <S.Icon $bgColor={iconBg}>
            <Icon name={icon} size={24} color={iconColor} />
          </S.Icon>
          <Flex $direction="column" $gap={4}>
            <Caption size="MD">{heading}</Caption>
            <Paragraph size="XS" $color="fgSecondary">
              {subheading}
            </Paragraph>
          </Flex>
        </Flex>
        <Button
          $variant="fill"
          $colorTheme="tint"
          $height={40}
          $width="full"
          $align="center"
          href={href}
        >
          Back to Pin
        </Button>
      </S.Card>
    </>
  )
}
