import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteDepartment } from 'src/libs/api/backend/departments'
import type { Department } from 'src/libs/api/backend/departments'
import { queryKeys } from 'src/libs/query-keys'
import { isNil } from 'lodash'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface Args {
  id: string
  deleted: boolean
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  deleteDepartmentByOrg: (args: Args) => void
}

export const useDeleteDepartment = (): Res => {
  const queryClient = useQueryClient()

  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)

  const mutation = useMutation({
    mutationFn: async ({ id, deleted }: Args) => await deleteDepartment(id, deleted),
    onError: (err, variables) => {
      console.error(err)
      notifyError({
        message: `An error occurred while ${variables.deleted ? 'deleting' : 'restoring'} your department: ${err.message}`
      })

      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (deletedDepartment, variables) => {
      queryClient.setQueryData<Department[]>([queryKeys.departments], (oldDepartments) => {
        if (isNil(oldDepartments)) {
          return
        }

        return oldDepartments.map((dept) => {
          if (dept.id === deletedDepartment.id) {
            return deletedDepartment
          }
          return dept
        }).sort((a, b) => a.deleted === b.deleted ? 0 : a.deleted ? 1 : -1)
      })

      notifySuccess({
        message: `Department successfully ${variables.deleted ? 'deleted' : 'restored'}`
      })
      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })
  const deleteDepartmentByOrg = (args: Args): void => {
    mutation.mutate(args)
  }

  return {
    deleteDepartmentByOrg
  }
}
